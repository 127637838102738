import { CoverImage } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';

export type CoverImageByTemplateIdQueryParams = { templateId?: Template['id'] };

export type CoverImageByTemplateIdQueryResponse = CoverImage | undefined;

export const CoverImageByTemplateIdQuery = {
  key: ['template', 'cover-image'],
  getKey: (params: CoverImageByTemplateIdQueryParams): QueryKey => [
    ...CoverImageByTemplateIdQuery.key,
    params.templateId,
  ],
  queryFn: (params: CoverImageByTemplateIdQueryParams) =>
    axiosService
      .getAxios()
      .get<CoverImageByTemplateIdQueryResponse>(`/1/templates/${params.templateId}/cover-image`, {
        validateStatus: status => {
          return status === HttpStatus.OK || status === HttpStatus.NOT_FOUND;
        },
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      }),
};

export const useCoverImageByTemplateIdQuery = <Select = CoverImageByTemplateIdQueryResponse>(
  params: CoverImageByTemplateIdQueryParams,
  options: UseQueryOptions<CoverImageByTemplateIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(CoverImageByTemplateIdQuery.getKey(params), () => CoverImageByTemplateIdQuery.queryFn(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
    enabled: { ...params }.templateId !== undefined && options.enabled !== false,
  });
};

import { FormFieldWidget, TaskTemplate, HiddenFormFieldWidget, Template } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  DeleteWidgetByHeaderIdMutation,
  UpdateWidgetMutation,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, send, sendParent, spawn } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { FormFieldLabelActor, makeFormFieldLabelMachine } from '../common/form-field-label';
import { match } from 'ts-pattern';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';

type Context = {
  widget: HiddenFormFieldWidget;
  template: Template;
  initialWidget: HiddenFormFieldWidget;
  labelActor: FormFieldLabelActor<HiddenFormFieldWidget>;
  recentlyMovedFrom?: TaskTemplate;
  inputNode: HTMLElement | null;
};

type Event =
  | WidgetEvent<HiddenFormFieldWidget>
  | { type: 'AUTO_FOCUS' }
  // Internal type
  | { type: 'done.invoke.updateWidgetMutation'; data: HiddenFormFieldWidget };

export type HiddenFormFieldMachine = ReturnType<typeof makeHiddenFormFieldMachine>;
export type HiddenFormFieldActor = ActorRefFrom<HiddenFormFieldMachine>;

export const makeHiddenFormFieldMachine = ({
  widget,
  template,
  sharedContext,
}: {
  widget: HiddenFormFieldWidget;
  template: Template;
  sharedContext: SharedContext;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget as FormFieldWidget).templateRevision?.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: `hidden-form-field:${widget.id}`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./hidden-form-field-machine.typegen').Typegen0,
      context: () =>
        ({
          widget,
          template,
          initialWidget: widget,
          labelActor: spawn(makeFormFieldLabelMachine<HiddenFormFieldWidget>({ widget, queryClient })),
          recentlyMovedFrom: undefined,
          inputNode: null,
        } as Context),
      states: {
        idle: {
          on: {
            DELETE_WIDGET: { target: 'deleting' },
            MOVE_DOWN: { actions: ['sendMoveDown'] },
            MOVE_UP: { actions: ['sendMoveUp'] },
            DUPLICATE: { actions: ['sendDuplicate'] },
            MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
            MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
            UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
            SET_NODE: { actions: ['assignNode'] },
            SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
          },
        },
        editing: {},
        saving: {
          invoke: [
            {
              id: 'updateWidgetMutation',
              src: 'updateWidgetMutation',
              onDone: {
                target: 'idle',
                actions: ['assignWidget', 'sendUpdateMergeTags', 'assignInitialWidget', 'sendUpdateDone'],
              },
              onError: {
                target: 'error',
                actions: ['resetWidget', 'sendUpdateError'],
              },
            },
          ],
        },
        deleting: {
          invoke: [
            {
              id: 'deleteWidget',
              src: 'deleteWidget',
              onDone: { target: 'deleted' },
              onError: { target: 'idle' },
            },
          ],
        },
        deleted: {
          type: 'final',
        },
        error: {},
      },
      on: {
        AUTO_FOCUS: { actions: ['sendAutoFocus'] },
        UPDATE_WIDGET: { target: 'saving', actions: ['assignWidget'] },
      },
    },
    {
      actions: {
        assignWidget: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
              .with({ type: 'done.invoke.updateWidgetMutation' }, ({ data }) => data)
              .otherwise(() => ctx.widget),
        }),
        assignInitialWidget: assign({
          initialWidget: (_, e) => e.data,
        }),
        sendUpdateMergeTags: sendParent(ctx => ({
          type: 'UPDATE_MERGE_TAGS_REFERENCES',
          widget: ctx.widget,
          oldKey: ctx.initialWidget.key,
        })),
        resetWidget: assign({
          widget: context => context.initialWidget,
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        sendAutoFocus: send({ type: 'AUTO_FOCUS' }, { to: ctx => ctx.labelActor }),
        sendUpdateDone: send(ctx => ({ type: 'UPDATE_DONE', data: ctx.widget }), { to: ctx => ctx.labelActor }),
        sendUpdateError: send({ type: 'UPDATE_ERROR' }, { to: ctx => ctx.labelActor }),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget.header,
                  ...{ ...(header as HiddenFormFieldWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
      },
      services: {
        updateWidgetMutation: async (context, evt) => {
          const widget = match(evt)
            .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
            .otherwise(() => context.widget);
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<HiddenFormFieldWidget>(widget),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidget: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget.header.id),
            onSuccess: () => {
              cacheSetter.delete(context.widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the widget`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
    },
  );
};

import * as React from 'react';
import {
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MotionWrapper,
  StackProps,
  VStack,
} from 'components/design/next';
import { TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { Muid } from '@process-street/subgrade/core';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { AnimatePresence, MotionProps } from 'framer-motion';

export type TaskListNewButtonProps = {
  taskTemplates: TaskTemplate[];
  groupId?: Muid;
  onAddTask: (at?: number, taskTemplate?: Partial<TaskTemplate>) => void;
} & StackProps;

export const TaskListNewButton = React.forwardRef<HTMLDivElement, TaskListNewButtonProps>(
  ({ groupId, onAddTask, taskTemplates, ...props }, ref) => {
    // We need this debounced value to be able to implement the exit animation when the menu closes

    const getAt = () => {
      return groupId ? taskTemplates.findIndex(taskTemplate => taskTemplate.group.id === groupId) : undefined;
    };

    const handleAddStandardTask = () => {
      onAddTask(getAt(), {});
    };

    const handleAddHeadingTask = () => {
      onAddTask(getAt(), { name: 'Heading:' });
    };

    const handleAddApprovalTask = () => {
      onAddTask(getAt(), { name: 'Approval:', taskType: TaskTemplateTaskType.Approval });
    };

    const handleAddAiTask = () => {
      onAddTask(getAt(), { name: 'AI Task:', taskType: TaskTemplateTaskType.AI });
    };

    return (
      <VStack
        w="full"
        alignItems="center"
        justifyContent="center"
        spacing={0}
        position="relative"
        {...props}
        ref={ref}
        height="48px"
      >
        <Divider h={4} orientation="vertical" />

        <Menu placement="bottom">
          {({ isOpen }) => (
            <>
              <MenuButton
                as={IconButton}
                ml={'1px'}
                aria-label="Add new item to sidebar"
                variant="outline"
                icon={<Icon icon="plus" size="4" color="gray.600" />}
                borderWidth="thin"
                colorScheme="gray"
                size="sm"
                borderColor="gray.300"
                bgColor="white"
              />

              <MenuList borderColor="gray.200">
                <ThemeProvider2024>
                  <AnimatePresence>
                    {isOpen && (
                      <>
                        <MotionWrapper {...getMenuItemMotionProps(0)}>
                          <MenuItem
                            icon={<Icon icon="plus-square" size="4" color="gray.600" />}
                            onClick={handleAddStandardTask}
                            fontSize="md"
                          >
                            Task
                          </MenuItem>
                        </MotionWrapper>

                        <MotionWrapper {...getMenuItemMotionProps(1)}>
                          <MenuItem
                            icon={<Icon icon="process-pete" size="4" color="gray.600" />}
                            onClick={handleAddAiTask}
                            fontSize="md"
                          >
                            AI Task
                          </MenuItem>
                        </MotionWrapper>

                        <MotionWrapper {...getMenuItemMotionProps(2)}>
                          <MenuItem
                            icon={<Icon icon="heading" size="4" color="gray.600" />}
                            onClick={handleAddHeadingTask}
                            fontSize="md"
                          >
                            Heading
                          </MenuItem>
                        </MotionWrapper>

                        <MotionWrapper {...getMenuItemMotionProps(3)}>
                          <MenuItem
                            icon={<Icon icon="thumbs-up" size="4" color="gray.600" />}
                            onClick={handleAddApprovalTask}
                            fontSize="md"
                          >
                            Approval
                          </MenuItem>
                        </MotionWrapper>
                      </>
                    )}
                  </AnimatePresence>
                </ThemeProvider2024>
              </MenuList>
            </>
          )}
        </Menu>
      </VStack>
    );
  },
);

const getMenuItemMotionProps = (index: number): MotionProps => {
  return {
    initial: { opacity: 0, y: -5 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -5 },
    transition: { delay: 0.05 * index, ease: 'easeInOut' },
  };
};

import * as React from 'react';
import { RichTextEditor } from 'features/rich-text';
import { TextContentActor } from './text-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';

export interface TextContentProps {
  actor: TextContentActor;
  isFirst?: boolean;
  isLast?: boolean;
}

export const TextContent: React.FC<React.PropsWithChildren<TextContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, recentlyMovedFrom } = state.context;

  const initialValue = React.useRef(state.context.widget.content).current;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        <RichTextEditor
          editorProps={{
            initialValue,
            onInit: (_evt, editor) => send({ type: 'SET_EDITOR', editor }),
            onEditorChange: content => send({ type: 'CHANGE', content }),
            onBlur: (_event, _editor) => send({ type: 'BLUR' }),
            onFocus: (_event, _editor) => send({ type: 'FOCUS' }),
          }}
          editorWrapperProps={{
            'aria-label': 'text content editor container',
            'flex': '1',
          }}
        />

        <WidgetListItemDragIcon />
        <FormsWidgetMenu>
          <FormsWidgetMenuItems.Duplicate />
          <FormsWidgetMenuItems.MoveToStep widget={widget} />
          <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
          <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
          <FormsWidgetMenuItems.Delete />
        </FormsWidgetMenu>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

import * as React from 'react';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { TemplateConstants } from 'services/template-constants';
import { useTemplateId } from 'pages/templates/_id/automation/utils/use-template-id';
import {
  TemplateSettingsModalProvider,
  useTemplateSettingsModalContext,
} from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
  VStack,
} from 'components/design/next';
import { TaskTemplate } from '@process-street/subgrade/process';

interface AiGenerationTooltipProps {
  extraErrorItems?: React.ReactNode[];
  errorHeader?: React.ReactNode;
  setHasError?: (hasError: boolean) => void;
  taskTemplate: TaskTemplate;
}

export const AiGenerationTooltip: React.FC<React.PropsWithChildren<AiGenerationTooltipProps>> = props => {
  const templateId = useTemplateId();

  return templateId ? (
    <TemplateSettingsModalProvider templateId={templateId} templateRevisionId={props.taskTemplate.templateRevision.id}>
      <Component {...props} />
    </TemplateSettingsModalProvider>
  ) : (
    <Spinner />
  );
};

const Component: React.FC<React.PropsWithChildren<AiGenerationTooltipProps>> = ({
  children,
  errorHeader,
  extraErrorItems = [],
  setHasError,
  taskTemplate,
}) => {
  const templateId = useTemplateId();
  const templateQuery = useGetTemplateQuery({ templateId });
  const { editName: handleEditWorkflowName } = useTemplateSettingsModalContext();

  const taskTemplateName = taskTemplate.name;

  const handleEditTaskName = () => {
    const textarea = document.querySelector<HTMLTextAreaElement>('.step.selected textarea');
    textarea?.focus();
  };

  const errorItems = [
    !taskTemplateName && (
      <Text key="task-name-error" fontSize="xs">
        You need to{' '}
        <Button onClick={handleEditTaskName} variant="unstyled" p="0" m="0" textDecoration="underline" mt="-0.5">
          enter a task name
        </Button>
        .
      </Text>
    ),
    (!templateQuery.data?.name || templateQuery.data.name === TemplateConstants.BLANK_WORKFLOW_NAME) && (
      <Text key="blank-wf-name-error" fontSize="xs">
        The name of the workflow can't be{' '}
        <Button onClick={handleEditWorkflowName} variant="unstyled" p="0" m="0" textDecoration="underline" mt="-0.5">
          Blank Workflow
        </Button>
        .
      </Text>
    ),
    ...extraErrorItems,
  ].filter(item => Boolean(item));

  const hasError = errorItems.length > 0;
  React.useEffect(() => {
    setHasError?.(hasError);
  }, [hasError, setHasError]);

  return (
    <Popover trigger="hover" size="auto" variant="tooltip-dark">
      <PopoverTrigger>
        {/* wrap children in Box because popover hover doesn't work with disabled buttons */}
        <Box w="max-content">{children}</Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="auto" border="0">
          <PopoverArrow />
          <PopoverBody fontSize="xs">
            {hasError ? (
              <VStack alignItems="flex-start" color="whiteAlpha.800" fontSize="xs" m="0" spacing="1">
                {errorHeader}
                {errorItems}
              </VStack>
            ) : (
              'Generate content with AI'
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

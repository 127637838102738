import { Muid } from '@process-street/subgrade/core';
import { Template, TemplateRevision, TemplateType, Widget } from '@process-street/subgrade/process';
import {
  DeleteTaskTemplatesMutation,
  TaskTemplatesByTemplateRevisionIdQuery,
  TaskTemplatesByTemplateRevisionIdQueryResponse,
  UpdateTaskTemplateMutation,
} from 'features/task-templates/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { createMachine, sendParent } from 'xstate';
import { SharedContext } from '../../shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { match, P } from 'ts-pattern';
import { DeleteWidgetMutation, WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';

type ForwardableEvent = { type: 'CONFIRM_DELETE_TASK_TEMPLATE' } | { type: 'CANCEL_DELETE_TASK_TEMPLATE' };
export type { ForwardableEvent as DeleteTaskTemplateMachineForwardableEvent };

type ParentEvent = ForwardableEvent | { type: 'PROMPT_TASK_TEMPLATE_DELETE' } | { type: 'DELETING_TASK_TEMPLATE' };
export type { ParentEvent as DeleteTaskTemplateMachineParentEvent };

type Event = ForwardableEvent;

interface Context {}

export const makeDeleteTaskTemplateMachine = ({
  taskTemplateId,
  templateRevisionId,
  sharedContext,
  widgets,
}: {
  sharedContext: SharedContext;
  taskTemplateId: Muid;
  templateRevisionId: Muid;
  widgets: Widget[];
}) => {
  const { queryClient, taskTemplateService, templateId, $state } = sharedContext;

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: 'delete-task-template',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./delete-task-template-machine.typegen').Typegen0,

      initial: 'checkWidgets',
      states: {
        checkWidgets: {
          always: [{ cond: 'taskHasNoWidgets', target: 'deleting' }, { target: 'prompt' }],
        },
        checkTasks: {
          always: [{ cond: 'hasOnlyOneTask', target: 'cleanup' }, { target: 'checkWidgets' }],
        },
        prompt: {
          entry: ['sendParentPrompt'],
          on: {
            CONFIRM_DELETE_TASK_TEMPLATE: [{ cond: 'hasOnlyOneTask', target: 'cleanup' }, { target: 'deleting' }],
            CANCEL_DELETE_TASK_TEMPLATE: 'done',
          },
        },
        cleanup: {
          entry: ['sendParentDeleting'],
          invoke: [
            {
              id: 'cleanupTaskTemplate',
              src: 'cleanupTaskTemplateMutation',
              onDone: { target: 'done', actions: [] },
              onError: { target: 'done' },
            },
          ],
        },
        deleting: {
          entry: ['sendParentDeleting'],
          invoke: [
            {
              id: 'deleteTaskTemplate',
              src: 'deleteTaskTemplateMutation',
              onDone: { target: 'done', actions: [] },
              onError: { target: 'done' },
            },
          ],
        },
        done: { type: 'final' },
      },
    },
    {
      actions: {
        sendParentPrompt: sendParent('PROMPT_TASK_TEMPLATE_DELETE'),
        sendParentDeleting: sendParent('DELETING_TASK_TEMPLATE'),
      },
      services: {
        cleanupTaskTemplateMutation: async (_ctx, _evt) => {
          // if is the only step, delete all widgets and clear step name
          const taskTemplates =
            queryClient.getQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            ) ?? [];
          const taskTemplate = taskTemplates[0];

          const widgetHeaderIds = widgets
            .filter(widget => widget.header.taskTemplate.id === taskTemplate.id)
            .map(widget => widget.header.id);

          const deleteWidgetsPromise = widgetHeaderIds.map(headerId => DeleteWidgetMutation.mutationFn({ headerId }));
          const updateTaskNamePromise = UpdateTaskTemplateMutation.mutationFn({
            taskTemplateId: taskTemplate.id,
            taskTemplate: { name: undefined },
          });

          const promises = [...deleteWidgetsPromise, updateTaskNamePromise];

          try {
            await Promise.all(promises);
            await Promise.all([
              queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(templateRevisionId)),
              queryClient.invalidateQueries(TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId })),
            ]);
          } catch (e) {
            ToastServiceImpl.openToast({
              status: 'error',
              title: "We're having problems deleting that step",
            });
          }
        },
        deleteTaskTemplateMutation: async (_ctx, _evt) => {
          const tasksBefore = queryClient.getQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
            TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
          );

          const sortedTasks = taskTemplateService.getTaskTemplatesSorted(tasksBefore ?? []);

          const taskTemplateToNavigate = match(sortedTasks)
            .with(P.not(P.nullish), taskTemplates => {
              const index = taskTemplates.findIndex(taskTemplate => taskTemplate.id === taskTemplateId);
              // When deleting the first step, we navigate to the next step
              if (index === 0) return taskTemplates[index + 1];
              // When deleting any other step, we navigate to the previous step
              if (index > 0) return taskTemplates[index - 1];
              // The task was not found
              return null;
            })
            .otherwise(() => null);

          return makeMutation(queryClient, {
            mutationFn: () =>
              DeleteTaskTemplatesMutation.mutationFn({
                taskTemplatesIds: [taskTemplateId],
              }),
            onMutate: () => {
              queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                current => {
                  return current?.filter(taskTemplate => taskTemplate.id !== taskTemplateId) || [];
                },
              );
              return { tasksBefore };
            },
            onSuccess: () => {
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
              const latestRevisions = queryClient.getQueryData<TemplateRevision[]>(
                GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
              );
              let targetRoute = 'formStep'; // default route in case there is no data from the query
              if (latestRevisions && latestRevisions.length > 0 && latestRevisions[1]) {
                targetRoute =
                  (latestRevisions[1]?.template as Template).templateType === TemplateType.Playbook
                    ? 'templateV2.task'
                    : 'form.step';
              }

              if (taskTemplateToNavigate) {
                $state.go(targetRoute, { groupId: taskTemplateToNavigate.group.id }, { inherit: true });
              }
            },
            onError: (_err, _vars, mutationCtx) => {
              queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                () => mutationCtx?.tasksBefore ?? [],
              );
              ToastServiceImpl.openToast({
                status: 'error',
                title: "We're having problems deleting that step",
              });
            },
          }).execute();
        },
      },
      guards: {
        taskHasNoWidgets: () => widgets.length === 0,
        hasOnlyOneTask: () => {
          const taskTemplates =
            queryClient.getQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
              TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
            ) ?? [];

          return taskTemplates.length === 1;
        },
      },
    },
  );
};

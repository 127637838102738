import { Muid, MuidUtils, Option, OrganizationMembershipErrorCodes, UserType } from '@process-street/subgrade/core';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { TemplateOverview } from '@process-street/subgrade/process/template-overview-model';
import { dayjs, HttpStatus } from '@process-street/subgrade/util';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useToast,
  VStack,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { ScheduleAlert } from 'components/run-checklist/components/RunChecklist/components/ScheduleAlert';
import { CreateChecklistAssignmentMutation, GetChecklistRuleQuery } from 'components/run-checklist/query-builder';
import { DatePicker } from 'features/conditional-logic/components/date-picker';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { useGetFinishedTmplRevisionByTmplIdQuery } from 'features/template-revisions/query-builder/get-finished-template-revision-by-template-id';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import truncate from 'lodash/truncate';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { AssigneesSelector } from './components/AssigneesSelector';
import { useCanRunWorkflowCheck } from './use-can-run-workflow-check';
import { trace } from 'components/trace';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOrNonSystemGroupOm } from '@process-street/subgrade/util/membership-utils';
import { RunChecklistDueLabel } from 'features/dynamic-due-dates/components/due-date-picker';
import { AxiosError } from 'axios';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import { useFeatureFlag } from 'features/feature-flags';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { useTimezone } from 'app/hooks/use-timezone';

export type RunChecklistProps = {
  isOpen: boolean;
  onChecklistCreate: () => void;
  onClose: () => void;
  onCancel: () => void;
  templateId: Muid;
};

export const RunChecklist: React.FC<React.PropsWithChildren<RunChecklistProps>> = ({
  isOpen,
  onChecklistCreate,
  onClose,
  onCancel,
  templateId,
}) => {
  const logger = trace({ name: 'RunChecklist' });
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');

  useCanRunWorkflowCheck({ templateId });

  const [status, setStatus] = React.useState<'idle' | 'creating'>('idle');

  // Refetching to get the latest apropiate default name for a checklist run.
  const templateRevisionsQuery = useGetFinishedTmplRevisionByTmplIdQuery({ templateId }, { staleTime: 1000 });

  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const checklistRuleQuery = GetChecklistRuleQuery.useQuery({
    templateRevisionId: templateRevisionsQuery.data?.id,
  });

  const checklistRule = checklistRuleQuery.data;

  const { data: users } = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: selectedOrganizationId!,
    },
    {
      enabled: Boolean(selectedOrganizationId),
      select: oms => oms.filter(isStandardUserOrNonSystemGroupOm).map(om => om.user),
    },
  );

  const currentUser = useSelector(SessionSelector.getCurrentUser);
  const timezone = useTimezone({ fallback: 'UTC' });
  const isAnonymous = currentUser?.userType === UserType.Anonymous;
  const { ChecklistService } = useInjector('ChecklistService');

  const [checklistName, setChecklistName] = React.useState<string>('');
  const [assignedEmails, setAssignedEmails] = React.useState<string[]>([]);
  const [dueDate, setDueDate] = React.useState<Option<number>>();
  const defaultChecklistName = templateRevisionsQuery.data?.defaultChecklistName;

  const template = templateRevisionsQuery.data?.template;

  // only set the placeholder name once
  const placeholderIsSet = React.useRef(false);
  React.useEffect(() => {
    if (!placeholderIsSet.current && template?.name && checklistName === '') {
      const now = dayjs();
      const nowStr = now.format('h:mmA');
      const placeholderChecklistName = `${truncate(template.name, {
        length: 50,
        omission: '…',
      })} ${nowStr} workflow run`;
      setChecklistName(placeholderChecklistName);
      placeholderIsSet.current = true;
    }
  }, [template?.name, checklistName]);

  const templateOverview: TemplateOverview = {
    id: templateId,
    name: template?.name ?? '',
    description: template?.description ?? '',
    folderName: '',
  };

  React.useEffect(() => {
    if (defaultChecklistName) {
      setChecklistName(
        isMergeTagImprovementsEnabled
          ? MergeTagStringReplacementUtils.replaceUnknownTagsValues(defaultChecklistName)
          : defaultChecklistName,
      );
    }
  }, [defaultChecklistName, isMergeTagImprovementsEnabled]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecklistName(event.target.value);
  };
  const handleDateChange = (date: number | null) => {
    setDueDate(date || undefined);
  };

  const toast = useToast();
  const runChecklist: React.MouseEventHandler<HTMLButtonElement> = async event => {
    event.preventDefault();
    if (!currentUser) {
      return;
    }
    setStatus('creating');

    try {
      const checklist = await ChecklistService.createWithPaymentRequiredCheck({
        template: templateOverview,
        // send unevaluated default name so that backend knows it's the default
        name: defaultChecklistName ?? checklistName,
        user: currentUser,
        dueDate,
      });

      if (!checklist) {
        setStatus('idle');
        return;
      }

      // create assignments
      if (!isAnonymous) {
        await Promise.all(
          assignedEmails.map(email =>
            CreateChecklistAssignmentMutation.mutationFn({
              assignmentId: MuidUtils.randomMuid(),
              checklistId: checklist.id,
              email,
            }).catch((error: AxiosError) => {
              if (error.response?.data?.code === OrganizationMembershipErrorCodes.UserInactive) {
                toast({
                  status: 'warning',
                  title: 'The workflow run has been created, but some users were not assigned',
                  description: DefaultErrorMessages.getUserInactiveDescription(email, error.response),
                });
              } else if (error.response?.status === HttpStatus.CONFLICT) {
                // Skip this, it means the creator was already assigned automatically by the backend to give access
              } else {
                throw error;
              }
            }),
          ),
        );
      }
      onChecklistCreate();
      ChecklistService.clearAndRedirect(checklist);
    } catch (e) {
      logger.error(e);
      setStatus('idle');
      toast({
        status: 'error',
        title: "We're having problems running this workflow",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  };

  const isFormValid = Boolean(checklistName);
  const isNameDisabled = Boolean(defaultChecklistName);

  const { ref: checklistNameRef, insertMergeTag: insertVariable } = useMergeTaggableInput({
    get: () => checklistName,
    set: setChecklistName,
  });

  const hasAutoFocused = React.useRef(false);
  const readyForAutoFocus = !isNameDisabled && placeholderIsSet.current;

  const maskedInputParser = React.useMemo(() => MaskedInputParsers.makeParser('merge-tags'), []);

  const html = React.useMemo(() => maskedInputParser(String(checklistName)), [maskedInputParser, checklistName]);
  const input = (
    <Input
      {...{
        value: checklistName,
        onChange: handleNameChange,
        maxLength: 255,
        // standard useRef is undefined on first render so using a callback ref
        ref: node => {
          checklistNameRef.current = node;

          if (!hasAutoFocused.current && readyForAutoFocus && node) {
            hasAutoFocused.current = true;
            node.select();
          }
        },
        ...(isNameDisabled
          ? {
              backgroundColor: 'gray.200',
              borderColor: 'gray.300',
              color: 'gray.500',
              opacity: '1!important',
              disabled: true,
            }
          : {
              backgroundColor: 'inherit',
              borderColor: 'inherit',
              color: 'inherit',
              opacity: 'inherit',
            }),
      }}
    />
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent top="10" as="form">
        <ModalHeader display="flex" alignItems="center" py="6" px="8">
          <Icon variant="far" icon="play" color="gray.400" size="5" />
          <Text variant="2" color="gray.700" marginLeft={2}>
            Run Workflow
          </Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody pt="6" pb="8" px="8" borderTop="1px solid" borderColor="gray.200">
          <VStack alignItems="flex-start" spacing={6}>
            {!isAnonymous && <ScheduleAlert />}

            <HStack>
              <Text variant="1" color="gray.500" flexShrink={0}>
                Based on
              </Text>
              <Icon variant="fas" icon="workflow" color="brand.500" size="4" />
              <Tooltip label={templateOverview.name}>
                <Text variant="1" fontWeight="medium" color="gray.600" noOfLines={1}>
                  {templateOverview.name}
                </Text>
              </Tooltip>
            </HStack>

            <VStack alignItems="stretch" width="full">
              <FormControl isRequired>
                <FormLabel>Workflow Run Name</FormLabel>

                <Tooltip
                  label="This is the default Workflow Run name set by the Workflow editor."
                  hasArrow
                  closeDelay={500}
                  isDisabled={!isNameDisabled}
                >
                  <Box w="full">
                    <InputGroup w="full">
                      {isNameDisabled && isMergeTagImprovementsEnabled ? (
                        <MaskedInput html={html} w="full" maskProps={{ bgColor: 'transparent' }}>
                          {input}
                        </MaskedInput>
                      ) : (
                        input
                      )}
                      {templateRevisionsQuery.data?.id && !isNameDisabled && (
                        <InputRightElement _focusWithin={{ zIndex: 3 }}>
                          <MergeTagsMenu
                            {...{
                              templateRevisionId: templateRevisionsQuery.data.id,
                              onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
                              mergeTagTarget: MergeTagsConstants.Target.CHECKLIST,
                              menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
                            }}
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                  </Box>
                </Tooltip>
              </FormControl>
            </VStack>

            {!isAnonymous && (
              <VStack alignItems="flex-start" width="full">
                <HStack>
                  <Text variant="1" fontWeight="medium" color="gray.600">
                    Workflow Run Assignee
                  </Text>

                  <Box position="relative">
                    <Popover trigger="hover" placement="top" variant="tooltip-dark">
                      <PopoverTrigger>
                        <span>
                          <Icon icon="circle-info" variant="far" size="4" color="gray.500" ml={1} />
                        </span>
                      </PopoverTrigger>

                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody maxW="74">
                          <Text variant="-2">
                            Choose who to assign to this workflow run, or leave it unassigned. Learn more{' '}
                            <Link
                              variant="noline"
                              textDecoration="underline"
                              href="https://www.process.st/help/docs/assigning-workflow-runs/"
                              target="_blank"
                            >
                              here
                            </Link>
                            .
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                </HStack>

                {currentUser && users && (
                  <AssigneesSelector users={users} currentUser={currentUser} onChange={setAssignedEmails} />
                )}
              </VStack>
            )}

            {!isAnonymous && (
              <VStack alignItems="flex-start" width="full">
                <Text variant="1" fontWeight="medium" color="gray.600">
                  Due Date
                </Text>

                {checklistRule ? (
                  <RunChecklistDueLabel checklistRule={checklistRule} />
                ) : (
                  <DatePicker
                    onChange={handleDateChange}
                    timeZone={timezone}
                    placeholder={'Select due date'}
                    icon={<Icon variant="far" icon="clock" size="4" mr={1} color="gray.500" />}
                    value={dueDate}
                    pickerProps={{
                      disabled: [
                        {
                          before: dayjs().tz(timezone).toDate(),
                        },
                      ],
                    }}
                  />
                )}
              </VStack>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing={6}>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              aria-label={`run workflow modal ${template?.name}`}
              variant="primary"
              leftIcon={<Icon variant="far" icon="play" size="4" />}
              onClick={runChecklist}
              isDisabled={!isFormValid}
              isLoading={status === 'creating'}
              type="submit"
            >
              Run Workflow
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { useDisclosure } from '@chakra-ui/react';
import { AiGeneratedWorkflowSettingsModal } from './ai-generated-workflow-settings-modal';

type AiGeneratedWorkflowSettingsModalWrapperProps = {
  templateId: Muid;
  templateRevisionId: Muid;
};

export const SHOW_AI_WORKFLOW_SETUP_MODAL_FOR = 'showAIWorkflowSetupModalFor';
export const AI_IMPORT_WORKFLOW_S3_ID = 'aiImportWorkflowS3Id';
/** For use with static URLs containing WF names */
export const AI_WORKFLOW_INITIAL_CONFIG = 'aiWorkflowInitialConfig';
export const AI_WORKFLOW_PROMPT_OVERRIDE = 'aiWorkflowPromptOverride';

export function useAiGeneratedWorkflowSetupModalDisclosure({ templateId }: { templateId: Muid }) {
  const templateIdToShowAIWorkflowSetupModal = localStorage.getItem(SHOW_AI_WORKFLOW_SETUP_MODAL_FOR);
  const importWorkflowS3Id = localStorage.getItem(AI_IMPORT_WORKFLOW_S3_ID);

  const disclosure = useDisclosure();

  React.useEffect(() => {
    const isImporting = Boolean(importWorkflowS3Id);
    const isBadTemplate = templateIdToShowAIWorkflowSetupModal !== templateId;

    if (isBadTemplate || isImporting) {
      return;
    }

    disclosure.onOpen();
  }, [templateId, templateIdToShowAIWorkflowSetupModal, importWorkflowS3Id, disclosure]);

  return disclosure;
}

export const AiGeneratedWorkflowSettingsModalWrapper: React.FC<
  React.PropsWithChildren<AiGeneratedWorkflowSettingsModalWrapperProps>
> = ({ templateId, templateRevisionId }) => {
  const disclosure = useAiGeneratedWorkflowSetupModalDisclosure({ templateId });

  return (
    <AiGeneratedWorkflowSettingsModal templateId={templateId} templateRevisionId={templateRevisionId} {...disclosure} />
  );
};

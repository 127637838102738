import {
  ChecklistRuleDefinition,
  Condition,
  isConditionLogical,
  isRuleLogical,
} from '@process-street/subgrade/conditional-logic';
import { Muid } from '@process-street/subgrade/core';
import { match, P } from 'ts-pattern';
import { isFormFieldWidget, TaskTemplate, Widget } from '../process';

export interface ChecklistRuleDefinitionReferenceSets {
  // Rule action tasks
  taskTemplateGroupIds: Set<Muid>;
  // Condition widgets
  formFieldWidgetGroupIds: Set<Muid>;
  // Rule action widgets
  widgetGroupIds: Set<Muid>;
}

export namespace ConditionalLogicCommonUtils {
  /**
   * Reduces an array of {@link ChecklistRuleDefinition}s (CRDs) into an object of sets for participating:
   * * task template group IDs
   * * (form field) widget group ids
   */
  export const getChecklistRuleDefinitionReferenceSets = (
    rules: ChecklistRuleDefinition[],
  ): ChecklistRuleDefinitionReferenceSets => {
    return rules.reduce((acc, rule) => {
      // Rule action tasks
      rule.taskTemplateGroupIds.forEach(id => {
        acc.taskTemplateGroupIds.add(id);
      });
      // Rule action widgets
      rule.widgetGroupIds.forEach(id => {
        acc.widgetGroupIds.add(id);
      });
      // condition widgets
      getConditionFormFieldWidgetGroupIdsFromRule(rule).forEach(id => {
        acc.formFieldWidgetGroupIds.add(id);
      });

      return acc;
    }, makeChecklistRuleDefinitionReferenceSetsAcc());
  };

  /** Gets a set of widget group IDs, used in a rule's conditions. */
  export const getConditionFormFieldWidgetGroupIdsFromRule = (rule: ChecklistRuleDefinition): Set<Muid> => {
    if (isRuleLogical(rule)) {
      return getFormFieldWidgetGroupIdsFromCondition(rule.operand.data);
    } else {
      return new Set<Muid>([rule.formFieldWidgetGroupId]);
    }
  };

  /** Gets a set of widget group IDs, used in a rule's conditions or the rule action. */
  export const getFormFieldWidgetGroupIdsFromRule = (rule: ChecklistRuleDefinition): Set<Muid> => {
    const widgetGroupIds = getConditionFormFieldWidgetGroupIdsFromRule(rule);
    rule.widgetGroupIds.forEach(id => widgetGroupIds.add(id));
    return widgetGroupIds;
  };

  export const getFormFieldWidgetGroupIdsFromCondition = (condition: Condition): Set<Muid> => {
    if (isConditionLogical(condition)) {
      return condition.conditions.reduce((acc, condition) => {
        getFormFieldWidgetGroupIdsFromCondition(condition).forEach(id => {
          acc.add(id);
        });

        return acc;
      }, new Set<Muid>());
    } else {
      return match(condition)
        .with({ formFieldWidgetGroupId: P.string }, condition => new Set<Muid>([condition.formFieldWidgetGroupId]))
        .otherwise(() => new Set<Muid>());
    }
  };

  const makeChecklistRuleDefinitionReferenceSetsAcc = (): ChecklistRuleDefinitionReferenceSets => ({
    formFieldWidgetGroupIds: new Set(),
    taskTemplateGroupIds: new Set(),
    widgetGroupIds: new Set(),
  });

  /**
   * Returns a function that checks whether a task template or some widgets are participating in any rule definition.
   * @param ruleDefinitions list of rule definitions that the function checks the task & widgets for.
   * @return {taskTemplate, widget} => boolean function
   */
  export const makeTaskTemplateHasAssociatedRule = (ruleDefinitions: ChecklistRuleDefinition[]) => {
    const { taskTemplateGroupIds, widgetGroupIds, formFieldWidgetGroupIds } =
      getChecklistRuleDefinitionReferenceSets(ruleDefinitions);

    const formFieldMatches = (widget: Widget) =>
      (isFormFieldWidget(widget) && formFieldWidgetGroupIds.has(widget.header.group.id)) ||
      widgetGroupIds.has(widget.header.group.id);

    return ({ taskTemplate, widgets }: { widgets: Widget[]; taskTemplate: TaskTemplate }) => {
      return taskTemplateGroupIds.has(taskTemplate.group.id) || widgets.some(formFieldMatches);
    };
  };

  /**
   * Returns a function that checks whether a widget is participating in any rule definition.
   * @param ruleDefinitions list of rule definitions that the function checks the widget for.
   * @return {widget} => boolean function
   */
  export const makeWidgetHasAssociatedRule = (ruleDefinitions: ChecklistRuleDefinition[]) => {
    const { formFieldWidgetGroupIds, widgetGroupIds } = getChecklistRuleDefinitionReferenceSets(ruleDefinitions);

    const formFieldMatches = (widget: Widget) =>
      (isFormFieldWidget(widget) && formFieldWidgetGroupIds.has(widget.header.group.id)) ||
      widgetGroupIds.has(widget.header.group.id);

    return ({ widget }: { widget: Widget }) => formFieldMatches(widget);
  };
}

import { ActorRefFrom, assign, createMachine, send, spawn, StateFrom } from 'xstate';
import { Muid, User } from '@process-street/subgrade/core';
import {
  Checklist,
  ChecklistRevision,
  ChecklistStatus,
  FormFieldValueWithWidget,
  TaskWithTaskTemplate,
  TemplateRevision,
  Widget,
} from '@process-street/subgrade/process';
import { makeTaskMachine, TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { FormMachineUtils } from './form-response-machine-utils';
import { WithSharedContext } from '../../types';
import { makeRulesEngineMachine, RulesEngineMachineActorRef } from './rules-engine-machine/rules-engine-machine';
import {
  FormResponseMachineReceptionist,
  makeRulesEngineTargetTaskKey,
} from '../../form-response-machine-receptionist';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';

export type Context = {
  checklist: Checklist;
  templateRevision: TemplateRevision;
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>;
  currentTaskActor?: ActorRefFrom<TaskMachine>;
  invalidTaskMap: Record<Muid, boolean>;
  rulesActor: RulesEngineMachineActorRef;
};

export type Event =
  | { type: 'NEXT_TASK' | 'PREVIOUS_TASK' }
  | { type: 'TASK_STATUS_UPDATE_SUCCESS' | 'TASK_STATUS_UPDATE_FAILED' }
  | { type: `${'INVALID' | 'VALID'}_TASK`; taskId: Muid };

export type FormResponseMachineBuilderProps = {
  currentUser: User;
  checklist: Checklist;
  checklistRevision: ChecklistRevision;
  tasks: TaskWithTaskTemplate[];
  widgets: Widget[];
  formFieldValues: FormFieldValueWithWidget[];
};

export type FormResponseMachineBuilderInternalProps = WithSharedContext<FormResponseMachineBuilderProps>;

export const makeFormResponseMachine = (props: FormResponseMachineBuilderInternalProps) => {
  const { sharedContext, currentUser, checklist, checklistRevision, tasks, widgets, formFieldValues } = props;
  const { $state } = sharedContext;

  const isChecklistComplete = checklist.status === ChecklistStatus.Completed;
  const isAnonymous = isAnonymousUser(currentUser);
  const isEditable = !isChecklistComplete;

  // completed forms are not accessible anonymously when opening them
  const isInaccessible = isChecklistComplete && isAnonymous;
  const initialResponseState = isInaccessible ? 'inaccessible' : isChecklistComplete ? 'complete' : 'active';

  return createMachine(
    {
      context: () => {
        const rulesActor = spawn(
          makeRulesEngineMachine({ checklist, checklistRevision, tasks, formFieldValues, widgets, sharedContext }),
          { name: 'rules-machine', sync: true },
        );
        const taskActorsMap = Object.fromEntries(
          tasks.map(task => {
            const taskWidgets = widgets.filter(w => w.header.taskTemplate.id === task.taskTemplate.id);
            const taskWidgetIdsSet = new Set(taskWidgets.map(widget => widget.id));
            const taskFormFieldValues = formFieldValues.filter(ffv => taskWidgetIdsSet.has(ffv.formFieldWidget.id));

            const taskActor = spawn(
              makeTaskMachine({
                sharedContext,
                checklist,
                checklistRevision,
                task,
                widgets: taskWidgets,
                formFieldValues: taskFormFieldValues,
                isEditable,
              }),
              { name: `task-machine:${task.id}` },
            );
            FormResponseMachineReceptionist.register({
              name: makeRulesEngineTargetTaskKey(task.taskTemplate.group.id),
              actorRef: taskActor.getSnapshot()!.context.rulesEngineTargetActor,
            });

            return [task.id, taskActor];
          }),
        ) as Record<Muid, ActorRefFrom<TaskMachine>>;

        const currentTaskActor = Object.values(taskActorsMap)[0] ?? undefined;
        FormResponseMachineReceptionist.register({
          name: 'rules-engine-actor',
          actorRef: rulesActor,
        });
        return {
          checklist,
          templateRevision: checklistRevision.templateRevision as TemplateRevision,
          taskActorsMap,
          currentTaskActor,
          rulesActor,
          invalidTaskMap: {},
        } as Context;
      },
      tsTypes: {} as import('./form-response-machine.typegen').Typegen0,
      schema: { context: {} as Context, events: {} as Event },
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: `form-response-machine:${checklist.id}`,
      type: 'parallel',
      states: {
        response: {
          initial: initialResponseState,
          states: {
            active: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    NEXT_TASK: [
                      { cond: 'taskIsInvalid', actions: 'sendRevealInvalid', target: '#validation.invalid.visible' },
                      { target: 'completingCurrentTask' },
                    ],
                    PREVIOUS_TASK: {
                      target: 'uncompletingPreviousTask',
                    },
                  },
                },
                completingCurrentTask: {
                  entry: ['sendCompleteCurrentTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        actions: ['assignNextTaskAsCurrentTask'],
                        cond: 'hasMoreTasks',
                      },
                      {
                        target: '#complete',
                        actions: ['assignCurrentTaskToUndefined', 'sendFormCompleteEventToTasks'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                  },
                },
                uncompletingPreviousTask: {
                  entry: ['sendUncompletePreviousTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        actions: ['assignPreviousTaskAsCurrentTask'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                  },
                },
              },
            },
            complete: {
              id: 'complete',
              initial: 'idle',
              states: {
                idle: {
                  entry: ['goToFinishedPageIfNoMoreTasks'],
                  on: {
                    NEXT_TASK: [{ target: 'idle', actions: ['assignNextTaskAsCurrentTask'] }],
                    PREVIOUS_TASK: [{ target: 'idle', actions: ['assignPreviousTaskAsCurrentTask'] }],
                  },
                },
              },
            },
            inaccessible: {
              entry: () => $state.go('logout'),
            },
          },
        },

        validation: {
          id: 'validation',
          initial: 'valid',
          states: {
            valid: {
              on: {
                INVALID_TASK: { target: 'invalid', actions: 'addInvalidTask' },
              },
            },
            invalid: {
              on: {
                INVALID_TASK: { actions: 'addInvalidTask' },
                VALID_TASK: [
                  { cond: 'areAllTasksValid', target: 'valid', actions: 'removeInvalidTask' },
                  { actions: 'removeInvalidTask' },
                ],
              },
              initial: 'hidden',
              states: { hidden: {}, visible: {} },
            },
          },
        },
      },
    },
    {
      guards: {
        hasMoreTasks: (context, _) => {
          return FormMachineUtils.hasMoreTasks(context.taskActorsMap, context.currentTaskActor);
        },
        taskIsInvalid: (context, _) => {
          // Fallback to true because if any of these actors are undefined, we've got bigger problems 🤠
          return context.currentTaskActor?.getSnapshot()?.matches('validation.invalid') ?? true;
        },
        areAllTasksValid: (context, evt) => {
          const { [evt.taskId]: _, ...rest } = context.invalidTaskMap;
          return Object.keys(rest).length === 0;
        },
      },
      actions: {
        sendCompleteCurrentTask: send(
          { type: 'COMPLETE_TASK' },
          { to: (ctx, __) => ctx.currentTaskActor as ActorRefFrom<TaskMachine> },
        ),
        sendUncompletePreviousTask: send(
          { type: 'UNCOMPLETE_TASK' },
          {
            to: (ctx, __) => FormMachineUtils.getPreviousTaskActor(ctx.taskActorsMap, ctx.currentTaskActor),
          },
        ),
        sendFormCompleteEventToTasks: ctx => {
          return Object.values(ctx.taskActorsMap).map(taskActor => taskActor.send({ type: 'FORM_COMPLETE' }));
        },
        assignPreviousTaskAsCurrentTask: assign({
          currentTaskActor: (ctx, __) => FormMachineUtils.getPreviousTaskActor(ctx.taskActorsMap, ctx.currentTaskActor),
        }),
        assignNextTaskAsCurrentTask: assign({
          currentTaskActor: (ctx, __) => FormMachineUtils.getNextTaskActor(ctx.taskActorsMap, ctx.currentTaskActor),
        }),
        assignCurrentTaskToUndefined: assign({
          currentTaskActor: (_, __) => {
            return undefined;
          },
        }),
        goToFinishedPageIfNoMoreTasks: (ctx, __) => {
          if (ctx.currentTaskActor === undefined) {
            $state.go('formResponseFinish', {
              id: checklist.id,
            });
          }
        },
        addInvalidTask: assign({
          invalidTaskMap: (ctx, event) => {
            ctx.invalidTaskMap[event.taskId] = true;
            return ctx.invalidTaskMap;
          },
        }),
        removeInvalidTask: assign({
          invalidTaskMap: (ctx, event) => {
            delete ctx.invalidTaskMap[event.taskId];
            return ctx.invalidTaskMap;
          },
        }),
        sendRevealInvalid: ctx => {
          ctx.currentTaskActor?.send({ type: 'REVEAL_INVALID' });
        },
      },
    },
  );
};

export type State = StateFrom<FormResponseMachine>;

export const FormResponseMachineSelectors = {
  getTotalTaskCount: (state: State) =>
    Object.values(state.context.taskActorsMap).filter(actor => !FormMachineUtils.taskActorIsHiddenByRule(actor)).length,
  getCurrentTaskIndex: (state: State) =>
    FormMachineUtils.getCurrentTaskIndex(state.context.taskActorsMap, state.context.currentTaskActor),
};

export type FormResponseMachine = ReturnType<typeof makeFormResponseMachine>;
export type FormResponseActor = ActorRefFrom<FormResponseMachine>;

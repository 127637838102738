import {
  FieldType,
  FormFieldWidget,
  TaskTemplate,
  Widget,
  WidgetHeaderLike,
  WidgetType,
} from '@process-street/subgrade/process';

export type WidgetEvent<W extends Widget = Widget> =
  | { type: 'DELETE_WIDGET' }
  | { type: 'MOVE_DOWN' }
  | { type: 'MOVE_UP' }
  | { type: 'DUPLICATE' }
  | { type: 'UPDATE_WIDGET'; widget: W }
  | { type: 'MOVE_TO_STEP'; widget: W; from: TaskTemplate; to: TaskTemplate }
  | { type: 'MOVED_FROM_STEP'; from: TaskTemplate }
  | { type: 'DISMISS_RECENTLY_MOVED' }
  | { type: 'UPDATE_WIDGET_HEADER'; header: WidgetHeaderLike }
  | { type: 'SET_WIDGET_LABEL'; label: string }
  | { type: 'SET_WIDGET_CONFIG'; config: FormFieldWidget['config'] }
  | { type: 'SET_NODE'; node: HTMLElement | null }
  | { type: 'SCROLL_INTO_VIEW' };

export type NewContentWidgetItem = { widgetType: WidgetType };
export type NewFormFieldWidgetItem = { fieldType: FieldType; label?: string };
export type NewWidgetItem = NewContentWidgetItem | NewFormFieldWidgetItem;

// Using an enum here because the `type` property on useDrag can't be constrained via generics
export enum DraggableType {
  FormField = 'FormField',
  Content = 'Content',
}

import { createUsableContext } from '@process-street/subgrade/util';
import * as React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useReactQueryReduxSync } from './use-react-query-redux-sync';
import { IsEditableProvider } from 'features/rich-text';

const PageEditor = React.lazy(() => import('./component').then(({ PageEditor }) => ({ default: PageEditor })));

export type PageEditorWrapperProps = {
  editable: boolean;
};

type Context = { usingDragHandle: boolean };
export const [usePageContext, PageContext] = createUsableContext<Context>({
  hookName: 'usePageContext',
  providerName: 'PageContext.Provider',
});

type Effects = { setUsingDragHandle: React.Dispatch<React.SetStateAction<boolean>> };
export const [usePageEffects, PageEffects] = createUsableContext<Effects>({
  hookName: 'usePageEffects',
  providerName: 'PageEffects.Provider',
});

export const PageEditorWrapper: React.FC<React.PropsWithChildren<PageEditorWrapperProps>> = ({ editable }) => {
  const [usingDragHandle, setUsingDragHandle] = React.useState(false);

  const pageContext: Context = React.useMemo(
    () => ({
      usingDragHandle,
    }),
    [usingDragHandle],
  );

  const pageEffects: Effects = React.useMemo(
    () => ({
      setUsingDragHandle,
    }),
    [setUsingDragHandle],
  );

  useReactQueryReduxSync();

  return (
    <React.Suspense fallback="">
      <PageContext.Provider value={pageContext}>
        <PageEffects.Provider value={pageEffects}>
          <IsEditableProvider isEditable={editable}>
            <DndProvider backend={HTML5Backend}>
              <PageEditor />
            </DndProvider>
          </IsEditableProvider>
        </PageEffects.Provider>
      </PageContext.Provider>
    </React.Suspense>
  );
};

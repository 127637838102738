import React from 'react';
import { Template } from '@process-street/subgrade/process';
import { Flex, Icon, IconButton } from 'components/design/next';
import { useDeleteCoverIconMutationWithToast } from 'features/cover-icon/query-builder';

export const EditIconLayer = ({ templateId }: { templateId: Template['id'] }) => {
  const removeCoverIconMutation = useDeleteCoverIconMutationWithToast();
  const handleRemoveIcon = () => {
    removeCoverIconMutation.mutate({ templateId });
  };

  return (
    <Flex
      width="full"
      height="full"
      position="absolute"
      top="0"
      bgColor="transparent"
      _hover={{ backgroundColor: 'rgba(0,0,0,0.6)', transition: 'all 0.4s' }}
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
    >
      <IconButton
        aria-label="remove cover icon"
        size="sm"
        variant="danger"
        icon={<Icon size="4" variant="far" icon="trash" />}
        onClick={handleRemoveIcon}
      />
    </Flex>
  );
};

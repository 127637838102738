import { Muid } from '@process-street/subgrade/core';
import {
  OrderTreeUtils,
  TaskTemplate,
  TaskTemplateUpdateResponseStatus,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { queryString } from '@process-street/subgrade/util';
import {
  TaskTemplatesByTemplateRevisionIdQuery,
  TaskTemplatesByTemplateRevisionIdQueryResponse,
  UpdateAllStopTaskTemplatesMutation,
  UpdateTaskTemplateOrderTreesMutation,
} from 'app/features/task-templates/query-builder';
import { SharedContext } from 'app/pages/forms/_id/shared';
import { createOrderTreeBulkUpdater } from 'app/services/order-tree-bulk-updater.impl';
import { ToastServiceImpl } from 'app/services/toast-service.impl';
import { makeMutation } from 'app/utils/query-builder/make-mutation';
import { QueryClient } from 'react-query';
import { ActorRefFrom, assign, createMachine, StateFrom } from 'xstate';
import { setLastVisited } from '../../hooks';
import { BulkMoveTaskDirection } from './components/bulk-actions-drawer/bulk-move-task';

export type TaskTemplateListEvent =
  | {
      type: 'SELECT_TASK_TEMPLATE';
      taskTemplate: Pick<TaskTemplate, 'id' | 'group'>;
      metaKey: boolean;
      shiftKey: boolean;
      ctrlKey: boolean;
    }
  | { type: 'SET_TASK_TEMPLATES'; taskTemplates: Array<TaskTemplate> }
  | { type: 'BULK_UPDATE_STOP'; stop: boolean }
  | { type: 'BULK_MOVE'; direction: BulkMoveTaskDirection }
  | { type: 'DELETE' }
  | { type: 'CANCEL' };

export type SelectTaskTemplateEvent = Extract<TaskTemplateListEvent, { type: 'SELECT_TASK_TEMPLATE' }>;

export type TaskTemplateListContext = {
  taskTemplates: Array<TaskTemplate>;
  selectedTaskTemplates: Array<Pick<TaskTemplate, 'id' | 'group'>>;
};

const taskTemplateOrderTreeUpdater = createOrderTreeBulkUpdater<TaskTemplate>({
  getOrderTree: taskTemplate => taskTemplate.orderTree,
  setOrderTree: (taskTemplate, orderTree) => {
    return {
      ...taskTemplate,
      orderTree,
    };
  },
});

export const makeTaskTemplateListMachine = ({
  sharedContext,
  taskTemplates,
  initialTaskTemplateGroupId,
}: {
  sharedContext: SharedContext;
  taskTemplates: Array<TaskTemplate>;
  initialTaskTemplateGroupId?: Muid;
}) => {
  const { navigate, queryClient, templateId } = sharedContext;
  return createMachine(
    {
      id: 'taskTemplateList',
      initial: 'emptySelection',

      predictableActionArguments: true,
      schema: {
        events: {} as TaskTemplateListEvent,
        context: {} as TaskTemplateListContext,
      },
      tsTypes: {} as import('./task-template-list-machine.typegen').Typegen0,
      context: () => {
        const initialTaskTemplate = taskTemplates.find(tt => tt.group.id === initialTaskTemplateGroupId);

        return {
          taskTemplates,
          selectedTaskTemplates: initialTaskTemplate ? [initialTaskTemplate] : [],
        };
      },
      on: {
        SELECT_TASK_TEMPLATE: [
          {
            target: 'singleSelection',
            cond: 'isSingleSelection',
            actions: ['assignSingleTaskTemplate', 'navigate'],
          },
          {
            target: 'multiSelection',
            cond: 'isRangeSelection',
            actions: ['assignMultipleTaskTemplates'],
          },
          {
            target: 'multiSelection',
            cond: 'isAppendingSelection',
            actions: ['appendTaskTemplates'],
          },
        ],
        SET_TASK_TEMPLATES: {
          actions: ['assignTaskTemplates'],
        },
      },
      states: {
        emptySelection: {},
        singleSelection: {},
        multiSelection: {
          on: {
            CANCEL: {
              target: 'singleSelection',
              actions: ['clearMultiselection'],
            },
            BULK_UPDATE_STOP: 'bulkUpdatingStop',
            BULK_MOVE: 'bulkMoving',
          },
        },
        bulkUpdatingStop: {
          invoke: [
            {
              id: 'bulkUpdateStop',
              src: 'bulkUpdateStopMutation',
              onDone: { target: 'multiSelection' },
              onError: { target: 'multiSelection' },
            },
          ],
        },
        bulkMoving: {
          invoke: [
            {
              id: 'bulkMove',
              src: 'bulkMoveMutation',
              onDone: { target: 'multiSelection' },
              onError: { target: 'multiSelection' },
            },
          ],
        },
      },
    },
    {
      actions: {
        assignSingleTaskTemplate: assign({
          selectedTaskTemplates: (_ctx, evt) => [evt.taskTemplate],
        }),
        assignMultipleTaskTemplates: assign({
          selectedTaskTemplates: (ctx, evt) => {
            const lastTaskTemplateSelected = ctx.selectedTaskTemplates[ctx.selectedTaskTemplates.length - 1];
            const startIndex = ctx.taskTemplates.findIndex(tt => tt.id === lastTaskTemplateSelected?.id);
            const endIndex = ctx.taskTemplates.findIndex(tt => tt.id === evt.taskTemplate.id);

            return ctx.taskTemplates.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1);
          },
        }),
        appendTaskTemplates: assign({
          selectedTaskTemplates: (ctx, evt) => {
            const isAlreadySelected = ctx.selectedTaskTemplates.find(tt => tt.id === evt.taskTemplate.id);

            if (isAlreadySelected && ctx.selectedTaskTemplates.length > 1) {
              return ctx.selectedTaskTemplates.filter(tt => tt.id !== evt.taskTemplate.id);
            } else if (isAlreadySelected && ctx.selectedTaskTemplates.length === 1) {
              return ctx.selectedTaskTemplates;
            }

            return [...ctx.selectedTaskTemplates, evt.taskTemplate];
          },
        }),
        assignTaskTemplates: assign({
          taskTemplates: (_, evt) => evt.taskTemplates,
        }),
        clearMultiselection: assign({
          selectedTaskTemplates: ctx => [ctx.selectedTaskTemplates[0]],
        }),
        navigate: (_, evt) => {
          setLastVisited({ groupId: evt.taskTemplate.group.id, templateId });
          navigate({
            pathname: 'templateV2.task',
            search: queryString.stringify({
              groupId: evt.taskTemplate.group.id,
              id: templateId,
            }),
          });
        },
      },
      services: {
        bulkUpdateStopMutation: (ctx, evt) => {
          const taskTemplatesIds = ctx.selectedTaskTemplates.map(taskTemplate => taskTemplate.id);
          const templateRevisionId = ctx.taskTemplates[0]?.templateRevision.id;
          if (!templateRevisionId) throw new Error('template revision id not found - no tasks in context');
          return makeMutation<UpdateAllStopTaskTemplatesMutation.Response>(queryClient, {
            mutationFn: () => UpdateAllStopTaskTemplatesMutation.mutationFn({ taskTemplatesIds, stop: evt.stop }),
            onSuccess: data => {
              queryClient.setQueryData<TaskTemplatesByTemplateRevisionIdQueryResponse>(
                TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
                (current = []) =>
                  current.map(t => {
                    const matchingResponse = data.find(d => d.id === t.id);
                    if (matchingResponse?.response === TaskTemplateUpdateResponseStatus.Ok) {
                      return { ...t, stop: evt.stop };
                    }
                    return t;
                  }),
              );
              ToastServiceImpl.openToast({
                status: 'success',
                title: `Stop ${evt.stop ? 'added to the' : 'removed from'} task(s)`,
              });
            },
          }).execute();
        },
        bulkMoveMutation: (ctx, evt) => {
          const templateRevisionId = ctx.taskTemplates[0]?.templateRevision.id;
          if (!templateRevisionId) throw new Error('template revision id not found - no tasks in context');

          const { direction } = evt;
          const taskTemplates = ctx.taskTemplates;
          const selectedTaskTemplatesIdsSet = new Set(ctx.selectedTaskTemplates.map(taskTemplate => taskTemplate.id));
          const taskTemplatesToMove = taskTemplates.filter(taskTemplate =>
            selectedTaskTemplatesIdsSet.has(taskTemplate.id),
          );
          const indexes = taskTemplatesToMove.map(taskTemplate => taskTemplates.indexOf(taskTemplate)).sort();

          if (direction === 'up') {
            const topIndex = indexes.reduce((a, b) => Math.min(a, b));
            if (topIndex === 0) {
              // task already at top - should not occur since button should be disabled
              return Promise.resolve();
            }
            const newIndexes = indexes.map(index => index - 1);
            return moveTaskTemplatesAndUpdateOrderTree({
              templateRevisionId,
              indexes,
              newIndexes,
              taskTemplates,
              queryClient,
            });
          }

          if (direction === 'down') {
            const bottomIndex = indexes.reduce((a, b) => Math.max(a, b));
            if (bottomIndex === taskTemplates.length - 1) {
              // task already at bottom - should not occur since button should be disabled
              return Promise.resolve();
            }
            const newIndexes = indexes.map(index => index + 1);
            return moveTaskTemplatesAndUpdateOrderTree({
              templateRevisionId,
              indexes: indexes.reverse(),
              newIndexes: newIndexes.reverse(),
              taskTemplates,
              queryClient,
            });
          }

          return Promise.resolve();
        },
      },
      guards: {
        isSingleSelection: (_ctx, evt) => !evt.metaKey && !evt.shiftKey && !evt.ctrlKey,
        isRangeSelection: (_ctx, evt) => evt.shiftKey,
        isAppendingSelection: (_ctx, evt) => evt.metaKey || evt.ctrlKey,
      },
    },
  );
};

function moveTaskTemplatesAndUpdateOrderTree({
  templateRevisionId,
  indexes,
  newIndexes,
  taskTemplates,
  queryClient,
}: {
  templateRevisionId: TemplateRevision['id'];
  indexes: Array<number>;
  newIndexes: Array<number>;
  taskTemplates: Array<TaskTemplate>;
  queryClient: QueryClient;
}) {
  const moveAt = taskTemplateOrderTreeUpdater['moveAt'];
  const updatedTaskTemplates = moveAt(indexes, newIndexes, taskTemplates);

  const orderModels = updatedTaskTemplates.map(taskTemplate => ({
    taskTemplateId: taskTemplate.id,
    taskTemplateGroupId: taskTemplate.group.id,
    orderTree: taskTemplate.orderTree,
  }));

  return makeMutation(queryClient, {
    variables: {
      orderModels,
    },
    mutationFn: UpdateTaskTemplateOrderTreesMutation.mutationFn,
    mutationKey: UpdateTaskTemplateOrderTreesMutation.key,
    onMutate: () => {
      queryClient.setQueryData(
        TaskTemplatesByTemplateRevisionIdQuery.getKey({
          templateRevisionId,
        }),
        () => updatedTaskTemplates,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        TaskTemplatesByTemplateRevisionIdQuery.getKey({
          templateRevisionId,
        }),
      );
    },
    onError: () => {
      queryClient.setQueryData(
        TaskTemplatesByTemplateRevisionIdQuery.getKey({
          templateRevisionId,
        }),
        () => taskTemplates,
      );
    },
  }).execute();
}

export type TaskTemplateListMachine = ReturnType<typeof makeTaskTemplateListMachine>;
export type TaskTemplateListActorRef = ActorRefFrom<TaskTemplateListMachine>;

export const TaskTemplateListActorSelectors = {
  getSelectedTaskTemplatesIds(state: StateFrom<TaskTemplateListMachine>) {
    return state.context.selectedTaskTemplates;
  },
  getSelectedTaskTemplates(state: StateFrom<TaskTemplateListMachine>) {
    const selectedTaskTemplatesIdsSet = new Set(
      state.context.selectedTaskTemplates.map(taskTemplate => taskTemplate.id),
    );
    return state.context.taskTemplates.filter(taskTemplate => selectedTaskTemplatesIdsSet.has(taskTemplate.id));
  },
  isMultiSelecting(state: StateFrom<TaskTemplateListMachine>) {
    return state.context.selectedTaskTemplates.length > 1;
  },
  isSomeSelectedTaskStopped(state: StateFrom<TaskTemplateListMachine>) {
    const selectedTaskTemplatesIds = new Set(state.context.selectedTaskTemplates.map(taskTemplate => taskTemplate.id));

    return state.context.taskTemplates.some(
      taskTemplate => taskTemplate.stop && selectedTaskTemplatesIds.has(taskTemplate.id),
    );
  },
  isFirstTaskSelected(state: StateFrom<TaskTemplateListMachine>) {
    const selectedTaskTemplatesIdsSet = new Set(
      state.context.selectedTaskTemplates.map(taskTemplate => taskTemplate.id),
    );

    const sortedTaskTemplates = state.context.taskTemplates.sort((a, b) =>
      OrderTreeUtils.compare(a.orderTree, b.orderTree),
    );
    if (sortedTaskTemplates.length > 0) {
      const firstTaskTemplate = sortedTaskTemplates[0];
      return selectedTaskTemplatesIdsSet.has(firstTaskTemplate.id);
    }
    return false;
  },
  isLastTaskSelected(state: StateFrom<TaskTemplateListMachine>) {
    const selectedTaskTemplatesIdsSet = new Set(
      state.context.selectedTaskTemplates.map(taskTemplate => taskTemplate.id),
    );

    const sortedTaskTemplates = state.context.taskTemplates.sort((a, b) =>
      OrderTreeUtils.compare(a.orderTree, b.orderTree),
    );

    if (sortedTaskTemplates.length > 0) {
      const lastTaskTemplate = sortedTaskTemplates[sortedTaskTemplates.length - 1];
      return selectedTaskTemplatesIdsSet.has(lastTaskTemplate.id);
    }
    return false;
  },
};

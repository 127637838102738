import * as React from 'react';
import { useActor } from '@xstate/react';
import { NumberFormFieldActor } from './number-form-field-machine';
import { Input, InputLeftAddon, InputRightAddon, VStack, InputGroup } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { SettingsModalHeader } from '../common/settings/settings-modal-content';
import { numberSettingsSchema } from './number-form-field-schema';
import { StringUtils } from '@process-street/subgrade/util';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { NumberFormFieldSettingsFields } from './number-form-field-settings-fields';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { match, P } from 'ts-pattern';

export type NumberFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: NumberFormFieldActor;
};

export const NumberFormFieldWidget: React.FC<React.PropsWithChildren<NumberFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [current] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom, template } = current.context;

  const placeholder = match(widget)
    .with({ config: { defaultValue: P.when(Boolean) } }, ({ config: { defaultValue } }) => defaultValue)
    .with({ config: { placeholder: P.when(Boolean) } }, ({ config: { placeholder } }) => placeholder)
    .otherwise(() => 'Numbers will be typed here');

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack alignItems="flex-start" flex="1" maxW="88">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel actor={labelActor} />}

          <InputGroup>
            {widget.config.unitLocation === 'prefix' && widget.config.unit ? (
              <InputLeftAddon>{widget.config.unit}</InputLeftAddon>
            ) : null}

            <Input
              id={`form-field-widget-${current.context.widget.id}`}
              w="full"
              placeholder={placeholder}
              isDisabled
              borderColor="gray.200"
              _disabled={{ bgColor: 'gray.50', opacity: 1 }}
            />

            {widget.config.unitLocation === 'suffix' && widget.config.unit ? (
              <InputRightAddon>{widget.config.unit}</InputRightAddon>
            ) : null}

            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Required widget={widget} />
              <FormsWidgetMenuItems.Settings widget={widget} schema={numberSettingsSchema}>
                <SettingsModalHeader>
                  {StringUtils.getNonEmpty(widget.label, 'Untitled number field')}
                </SettingsModalHeader>

                <NumberFormFieldSettingsFields
                  templateRevisionId={widget.templateRevision.id}
                  templateType={template.templateType}
                />
              </FormsWidgetMenuItems.Settings>
              <FormsWidgetMenuItems.ConditionalLogic widget={widget} />

              <FormsWidgetMenuItems.Divider />

              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </InputGroup>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

import { FieldType, FormFieldWidget, Widget, WidgetType } from '@process-street/subgrade/process';
import { WidgetActorMap } from '../../form-editor-page-machine';
import { ContentActor } from '../content';
import { FormFieldActor } from '../form-fields';

type FormFieldActorLookup<F extends FieldType> = F extends FieldType.Text
  ? FormFieldActor['Text']
  : F extends FieldType.Email
  ? FormFieldActor['Email']
  : F extends FieldType.Textarea
  ? FormFieldActor['Textarea']
  : F extends FieldType.Url
  ? FormFieldActor['Url']
  : F extends FieldType.Select
  ? FormFieldActor['Select']
  : F extends FieldType.File
  ? FormFieldActor['File']
  : F extends FieldType.Number
  ? FormFieldActor['Number']
  : never;

type ContentActorLookup<T extends WidgetType> = T extends WidgetType.Text
  ? ContentActor['Text']
  : T extends WidgetType.Image
  ? ContentActor['Image']
  : T extends WidgetType.File
  ? ContentActor['File']
  : never;

type ActorLookup<W extends Widget> = W extends FormFieldWidget
  ? FormFieldActorLookup<W['fieldType']>
  : ContentActorLookup<W['header']['type']>;

export const makeGetActor =
  (actorMap: WidgetActorMap) =>
  <W extends Widget>(widget: W) => {
    return actorMap[widget.header.id] as ActorLookup<W>;
  };

export type GetActor = ReturnType<typeof makeGetActor>;

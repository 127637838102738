import * as React from 'react';
import { Box, BoxProps, Button, List, Text } from 'components/design/next';
import { PropsWithChildren } from 'react';

type RendererProps<T> = {
  item: T;
  onClick: (item: T) => void;
};

export type LimitedListProps<T> = {
  items: T[];
  onSelect: (item: T) => void;
  title: string;
  Item: React.FC<React.PropsWithChildren<RendererProps<T>>>;
  boxProps?: BoxProps;
};

const showMoreLimit = 5;
const showAllLimit = 10;

export function LimitedList<T>(props: PropsWithChildren<LimitedListProps<T>>) {
  const { items, onSelect, title, Item, children, boxProps } = props;

  const [visibleItems, setVisibleItems] = React.useState<T[]>([]);
  const [showMoreVisible, setShowMoreVisible] = React.useState(false);
  const [limitedLabelVisible, setLimitedLabelVisible] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if (!expanded) {
      const limitedItems = items.slice(0, showMoreLimit);
      setVisibleItems(limitedItems);
      setShowMoreVisible(items.length > showMoreLimit);
    } else {
      const allItems = items.slice(0, showAllLimit);
      setVisibleItems(allItems);
      setLimitedLabelVisible(items.length > showAllLimit);
    }
  }, [items, expanded]);

  const showMore = () => {
    setExpanded(true);
    setShowMoreVisible(false);
  };

  return (
    <Box {...boxProps}>
      <Text variant="-2u" color="gray.400" mb={1} px={4}>
        {title}
      </Text>

      {children}

      <List my={1}>
        {visibleItems.map((item, index) => (
          <Item key={index} item={item} onClick={onSelect} />
        ))}
      </List>

      {showMoreVisible && (
        <Box mx={4}>
          <Button variant="unstyled" display="contents" onClick={showMore}>
            <Text as="span" variant="-1" color="brand.500">
              Show more
            </Text>
          </Button>
        </Box>
      )}

      {limitedLabelVisible && (
        <Text variant="-2" color="gray.400" mx={4}>
          Limited to {showAllLimit} items
        </Text>
      )}
    </Box>
  );
}

import * as React from 'react';
import {
  Button,
  Divider,
  HStack,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Portal,
  Text,
  VStack,
} from 'components/design/next';
import { DateUtils, dayjs } from '@process-street/subgrade/util';
import { SnoozeService } from 'services/snooze-service';
import { SnoozeButtonDatePicker } from './snooze-button-date-picker';

type SnoozeItemProps = React.PropsWithChildren<{
  snoozeDate: number;
  onClick: (snoozeDate: number) => void;
}>;

const SnoozeItem = ({ snoozeDate, onClick, children }: SnoozeItemProps) => {
  const formattedSnoozeDate = DateUtils.formatSnoozeDate(dayjs(snoozeDate));
  return (
    <HStack
      as={Button}
      w="full"
      colorScheme="gray"
      size="sm"
      variant="ghost"
      justifyContent="space-between"
      color="gray.600"
      _hover={{ bgColor: 'gray.200' }}
      fontWeight="400"
      borderRadius="4px"
      onClick={() => onClick(snoozeDate)}
    >
      <Text fontSize="sm">{children}</Text>
      <Text fontSize="sm">{formattedSnoozeDate}</Text>
    </HStack>
  );
};

type SnoozeButtonPopoverContentProps = {
  isOpen: boolean;
  onSnooze: (untilDate: number) => void;
};

export const SnoozeButtonPopoverContent = ({ isOpen, onSnooze }: SnoozeButtonPopoverContentProps) => {
  const snoozeTargets = React.useMemo(() => {
    return SnoozeService.generateSnoozeTargets();
    // Added `popoverDisclosure.isOpen` to recalculate the snoozeTargets whenever the user opens the menu.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Portal>
      <PopoverContent borderColor="gray.200">
        <PopoverHeader px={4} py={2}>
          <Text fontSize="sm" fontWeight={500} color="gray.700" lineHeight="32px">
            Move to snoozed until...
          </Text>
        </PopoverHeader>

        <PopoverCloseButton top={3.5} color="gray.500" />

        <PopoverBody as={VStack} spacing={4} w="full" justifyContent="stretch" px={4} pb={6}>
          <VStack spacing={1} w="full">
            {Object.entries(snoozeTargets).map(([label, snoozeDate]) => (
              <SnoozeItem key={label} snoozeDate={snoozeDate.toDate().getTime()} onClick={onSnooze}>
                {label}
              </SnoozeItem>
            ))}
          </VStack>

          <Divider />

          <SnoozeButtonDatePicker onChange={onSnooze} />
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
};

import angular from 'angular';
import { TemplateTaskAssignmentSelector } from 'components/template-task-assignment/store/template-task-assignment.selector';
import { connectController } from 'reducers/util';
import { RoleAssignmentRuleSelector } from 'components/role-assignments/store/role-assignment-rules.selector';
import templateUrl from './task-template-list-item.component.html';
import './task-template-list-item.scss';
import { AutomationsModalEvent } from 'pages/templates/_id/automation/automations-modal-event';
import { TaskTemplateListMenuEvent } from '../task-template-list-menu-event';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match } from 'ts-pattern';
import { isApproval, isHeading, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { QueryObserver } from 'react-query';
import { SolutionInstancesByTemplateIdQuery } from 'features/automations/query-builder';
import { queryClient } from 'components/react-root';
import { noop } from '@process-street/subgrade/util';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';

angular.module('frontStreetApp.directives').component('psTaskTemplateListItem', {
  bindings: {
    editable: '<',
    index: '<', // Index of the item
    active: '<', // Enables placeholder for text area
    taskTemplate: '<',
    templateId: '<',
    templateRevision: '<',
    hasConditionalLogic: '<',
    isAiGeneratingWidgets: '<',

    assigneesMap: '<',
    userCanManageTasks: '<',

    onFocus: '&',
    onToggleWidgets: '&',

    // Template edit mode
    onCreateAt: '&',
    onUpdate: '&',
    onDelete: '&',
    onNameChanged: '&', // On task template's name changed
  },
  require: {
    taskTemplateListCtrl: '^psTaskTemplateList',
  },
  templateUrl,
  controller(
    $ngRedux,
    $timeout,
    RoleAssignmentRulesActions,
    SecurityService,
    TaskTemplateService,
    util,
    TemplateTaskAssignmentActions,
    $rootScope,
    StopTaskButtonService,
    MessageBox,
    ConditionalsButtonService,
    $scope,
  ) {
    const ctrl = this;
    ctrl.userCanUpdateTemplate = false;
    ctrl.menuIsOpen = false;
    ctrl.canShowDueDate = () => !isHeading(ctrl.taskTemplate);

    ctrl.taskMenuHeading = () =>
      match(ctrl.taskTemplate)
        .when(isApproval, () => 'Approval')
        .when(isHeading, () => 'Heading')
        .with({ taskType: TaskTemplateTaskType.AI }, () => 'AI Task')
        .otherwise(() => 'Task');

    ctrl.$onInit = function () {
      const mapStateToThis = () => state => {
        const taskAssignments = TemplateTaskAssignmentSelector.getAllByTemplateRevisionIdAndTaskTemplateId(
          ctrl.taskTemplate.templateRevision.id,
          ctrl.taskTemplate.id,
        )(state);

        const assignmentRules = RoleAssignmentRuleSelector.getByTaskTemplateGroupId(
          ctrl.taskTemplate.templateRevision.id,
          ctrl.taskTemplate.group.id,
        )(state);

        const user = SessionSelector.getCurrentUser(state);
        const organization = SessionSelector.getSelectedOrganization(state);
        const featureFlags = FeatureFlagSelector.getFeatureFlags(state);
        const isNewContextMenuEnabled = featureFlags.reactifiedTaskTemplateContextMenu;

        return {
          taskAssignments,
          assignmentRules,
          user,
          organization,
          isNewContextMenuEnabled,
        };
      };

      const mapDispatchToThis = () => ({
        getAllTemplateTaskAssignments: TemplateTaskAssignmentActions.getAllByTemplateRevisionId,
        geAllRulesByTemplateRevisionId: RoleAssignmentRulesActions.getAllByTemplateRevisionId,
      });

      connectController($ngRedux, mapStateToThis, mapDispatchToThis, ctrl.shouldChange)(ctrl);
      ctrl.actions.getAllTemplateTaskAssignments(ctrl.taskTemplate.templateRevision.id);
      ctrl.actions.geAllRulesByTemplateRevisionId(ctrl.taskTemplate.templateRevision.id);

      SecurityService.canUpdateTemplateByTemplate(ctrl.templateRevision.template).then(userCanUpdateTemplate => {
        ctrl.userCanUpdateTemplate = userCanUpdateTemplate;
      });

      if (ctrl.editable) {
        ConditionalsButtonService.initializeFeatures().then(result => {
          if (!result) return;

          const { userIsAdmin, rulesFeatureIsAvailable } = result;
          ctrl.userIsAdmin = userIsAdmin;
          ctrl.rulesFeatureIsAvailable = rulesFeatureIsAvailable;
        });
      }

      ctrl.subscribeToAutomations();
    };

    ctrl.$onDestroy = () => {
      ctrl.unsubscribeFromAutomations?.();
    };

    ctrl.shouldShowTaskAutomationIndicatorFlare = () => ctrl.taskIsAutomated && ctrl.userCanUpdateTemplate;

    ctrl.shouldShowFlare = () => !isHeading(ctrl.taskTemplate) && ctrl.shouldShowTaskAutomationIndicatorFlare();

    ctrl.shouldChange = changes => changes.taskTemplate && changes.taskTemplate.currentValue;

    ctrl.shouldShowStepAssignments = () =>
      (ctrl.state.taskAssignments && ctrl.state.taskAssignments.length) ||
      (ctrl.state.assignmentRules && ctrl.state.assignmentRules.length);

    // We want to be able to cancel a debounced update when task template is getting deleted
    let updateTimeout;

    ctrl.update = function () {
      $timeout.cancel(updateTimeout);
      updateTimeout = $timeout(() => {
        ctrl.onUpdate({ taskTemplate: ctrl.taskTemplate });
      }, 500);
    };

    ctrl.delete = function () {
      $timeout.cancel(updateTimeout);
      ctrl.onDelete({ taskTemplate: ctrl.taskTemplate });
    };

    ctrl.duplicateTask = function () {
      $rootScope.$broadcast(TaskTemplateListMenuEvent.DUPLICATE_REQUEST_FROM_MENU, ctrl.taskTemplate);
    };

    ctrl.isDuplicateButtonDisabled = () => !ctrl.taskTemplate || ctrl.duplicateTaskInProgress;

    ctrl.duplicateTaskInProgress = false;

    $rootScope.$on(TaskListEvent.DUPLICATE_WIDGETS_STARTED, () => {
      ctrl.duplicateTaskInProgress = true;
    });

    $rootScope.$on(TaskListEvent.DUPLICATE_WIDGETS_FINISHED, () => {
      ctrl.duplicateTaskInProgress = false;
    });

    $rootScope.$on(TaskListEvent.UPDATE_TASK_TEMPLATE_NAME, (_event, taskTemplate) => {
      if (taskTemplate.id === ctrl.taskTemplate.id) {
        const nameChanged = taskTemplate.name !== ctrl.taskTemplate.name;
        if (nameChanged) {
          $timeout(() => {
            ctrl.taskTemplate.name = taskTemplate.name;
          });
          ctrl.update();
          ctrl.onNameChanged({ name: ctrl.taskTemplate.name });
        }
      }
    });

    ctrl.toggleWidgets = function () {
      ctrl.onToggleWidgets();
    };

    ctrl.setFocus = function () {
      ctrl.onFocus({ taskTemplate: ctrl.taskTemplate });
    };

    const MAX_TASK_TEMPLATE_NAME_LENGTH = 255;
    ctrl.inputTaskTemplate = function () {
      ctrl.update();

      let lines;
      if (ctrl.taskTemplate.name) {
        lines = ctrl.taskTemplate.name.split('\n').map(l => l.substring(0, MAX_TASK_TEMPLATE_NAME_LENGTH));
      } else {
        lines = [];
      }

      [ctrl.taskTemplate.name] = lines;

      lines.slice(1).forEach((line, i) => {
        ctrl.onCreateAt({ index: ctrl.index + i, name: line });
      });

      ctrl.onNameChanged({ name: ctrl.taskTemplate.name });
    };

    ctrl.isHeading = isHeading;

    /**
     * Prevents the the task selection from changing when the assignment photo is clicked.
     *
     * @param event
     */
    ctrl.clickAssignment = function (event) {
      event.originalEvent.fromAssignmentClick = true;
    };

    ctrl.isApprovalTask = TaskTemplateService.isApproval;

    // Handling key events

    const KEY_ENTER = 13;
    const KEY_BACKSPACE = 8;

    ctrl.handleNameKeydown = function (event) {
      switch (event.keyCode) {
        case KEY_ENTER:
          event.preventDefault();
          break;
        case KEY_BACKSPACE:
          if (ctrl.taskTemplate && !ctrl.taskTemplate.name) {
            ctrl.delete();
            event.preventDefault();
          }
          event.stopPropagation();
          break;
        default: // We don't care about other keys
      }
    };

    ctrl.handleNameKeyup = function (event) {
      // This doesn't work well on mobile
      if (util.isMobile()) {
        return;
      }

      switch (event.keyCode) {
        case KEY_ENTER:
          ctrl.onCreateAt({ index: ctrl.index });
          event.preventDefault();
          break;
        default: // We don't care about other keys
      }
    };

    ctrl.openTaskAutomations = () => {
      $rootScope.$broadcast(AutomationsModalEvent.OPEN_TASK_AUTOMATIONS);
    };

    ctrl.handleStopClick = () => {
      StopTaskButtonService.addStop({
        taskTemplate: ctrl.taskTemplate,
        user: ctrl.state.user,
        organization: ctrl.state.organization,
      }).catch(messageBoxProps => {
        MessageBox.custom(messageBoxProps);
      });
    };

    let rulesModalIsOpen = false;

    function setRulesModalIsOpen(isOpen) {
      rulesModalIsOpen = isOpen;
    }

    ctrl.openConditionalLogic = _taskTemplateGroupId => {
      ConditionalsButtonService.openRulesManagerModal({
        organization: ctrl.state.organization,
        rulesFeatureIsAvailable: ctrl.rulesFeatureIsAvailable,
        rulesModalIsOpen,
        setRulesModalIsOpen,
        templateRevision: ctrl.templateRevision,
        user: ctrl.state.user,
        userIsAdmin: ctrl.userIsAdmin,
        taskTemplate: ctrl.taskTemplate,
      });
    };

    ctrl.moveTaskUp = () => {
      $rootScope.$broadcast(TaskTemplateListMenuEvent.MOVE_UP_REQUEST_FROM_MENU, ctrl.taskTemplate);
    };

    ctrl.moveTaskDown = () => {
      $rootScope.$broadcast(TaskTemplateListMenuEvent.MOVE_DOWN_REQUEST_FROM_MENU, ctrl.taskTemplate);
    };

    ctrl.taskIsAutomated = false;
    ctrl.templateSolutionInstances = [];

    ctrl.subscribeToAutomations = () => {
      if (ctrl.userAnonymous || !ctrl.templateId) return;

      const { templateId } = ctrl;
      const observer = new QueryObserver(queryClient, {
        queryKey: SolutionInstancesByTemplateIdQuery.getKey({ templateId }),
        queryFn: () => SolutionInstancesByTemplateIdQuery.queryFn({ templateId }),
        staleTime: 1000,
      });

      ctrl.unsubscribeFromAutomations = observer.subscribe(result => {
        match(result)
          .with({ status: 'success' }, ({ data }) => {
            $scope.$evalAsync(() => {
              const templateSolutionInstances = data ?? [];

              ctrl.templateSolutionInstances = templateSolutionInstances;

              ctrl.taskIsAutomated = templateSolutionInstances
                .filter(i => i.configured && i.enabled)
                .some(i => i.taskTemplateGroupId === ctrl.taskTemplate?.group.id);
            });
          })
          .otherwise(noop);
      });
    };

    ctrl.getAriaLabel = () => {
      return `task ${ctrl.index} ${ctrl.templateRevision.template.name}`;
    };

    ctrl.shouldShowMenuItem = menuItem => {
      return match({ taskTemplate: ctrl.taskTemplate, menuItem })
        .with({ taskTemplate: { taskType: TaskTemplateTaskType.AI }, menuItem: 'delete' }, () => true)
        .with({ taskTemplate: { taskType: TaskTemplateTaskType.AI } }, () => false)
        .with({ menuItem: 'duplicate' }, () => !ctrl.isApprovalTask(ctrl.taskTemplate))
        .with({ menuItem: 'automate' }, () => !isHeading(ctrl.taskTemplate))
        .with({ menuItem: 'stop' }, () => !isHeading(ctrl.taskTemplate))
        .otherwise(() => true);
    };
  },
});

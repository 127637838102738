import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { AssignmentPickerWrapper } from 'pages/templates/_id/components/assignment-picker/wrapper';

export const AssignmentPickerModule = angular
  .module('assignmentPicker', [])
  .component(
    'psAssignmentPicker',
    reactToAngular(AssignmentPickerWrapper, ['templateRevision', 'taskTemplates', 'disabled', 'hasAssignments']),
  ).name;

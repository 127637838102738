import { CreateTemplateSource } from 'services/template-service.interface';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedFolderPermissionsQuery } from 'features/permissions/query-builder';
import { AiLocalStorage } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/ai-local-storage';

export const AiGeneratePage = () => {
  const { data: currentFolder } = useCurrentOrPrivateRootFolderQuery();
  const { PremadeTemplateService, $stateParams, $state } = useInjector(
    'PremadeTemplateService',
    '$stateParams',
    '$state',
  );

  useGetConsolidatedFolderPermissionsQuery(currentFolder?.id ?? '', {
    enabled: !!currentFolder,
    onSuccess: ({ permissionMap, folderId }) => {
      const isAllowedToCreateWorkflow = Boolean(permissionMap?.templateCreate);
      const { name, description, retryCount } = $stateParams;

      if (name || description || retryCount) {
        AiLocalStorage.setInitialConfig({
          name,
          description,
          retryCount,
          autoStart: true,
        });
      }

      if (!isAllowedToCreateWorkflow) {
        // redirect to the library
        return $state.go('dashboard');
      }

      PremadeTemplateService.createBlankWorkflowInFolderAndRedirect({
        folderId,
        source: CreateTemplateSource.AI_STATIC_URL,
        isAiGenerated: true,
      });
    },
  });

  return null;
};

import React, { memo, useEffect, useState } from 'react';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form/merge-tags-constants';
import { ChecklistRevision, Task, TextWidget } from '@process-street/subgrade/process';
import { padTrailingBr } from '@process-street/subgrade/util';
import { useParseChecklistMergeTagsByTarget } from 'features/widgets/components/send-rich-email/checklist/use-parse-checklist-merge-tags-by-target';

export interface ChecklistTextWidgetProps {
  widget: TextWidget;
  checklistRevision: ChecklistRevision;
  task: Task;
}

export const ChecklistTextWidget: React.FunctionComponent<React.PropsWithChildren<ChecklistTextWidgetProps>> = memo(
  ({ widget, checklistRevision, task }) => {
    const [content, setContent] = useState('');
    const parseGeneral = useParseChecklistMergeTagsByTarget({
      checklistRevisionId: checklistRevision.id,
      taskId: task.id,
      mergeTagTarget: MergeTagTarget.GENERAL,
    });

    useEffect(() => {
      setContent(padTrailingBr(parseGeneral({ content: widget.content || '', mode: MergeTagMode.HTML })));
    }, [parseGeneral, widget.content]);

    return (
      <div className="widget-text">
        <div
          className="widget-content"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    );
  },
);

import React from 'react';
import { Image, ImageProps, Skeleton } from 'components/design/next';
import { useCDNImage, UseCDNImageProps } from './use-cdn-image';

type CDNImageProps = UseCDNImageProps & ImageProps;

const LowQualityImagePlaceholder = ({ ...props }: ImageProps) => (
  <Image position="absolute" top="0" width="full" fit="cover" align="center" {...props} />
);

export const CDNImage: React.FC<CDNImageProps> = props => {
  const { s3File, transformation, withLqip = true, isDisabled = false, ...imageProps } = props;
  const { width, height } = transformation;
  const { src, anySrcLoaded, lqipSrc, isGIF, status } = useCDNImage({
    s3File,
    transformation,
    withLqip,
    isDisabled,
  });

  if (!anySrcLoaded && !lqipSrc) {
    return <Skeleton {...{ width, height }} />;
  }

  return (
    <>
      {withLqip && !isGIF && (
        <LowQualityImagePlaceholder
          src={lqipSrc}
          sx={{
            opacity: status === 'loaded' ? 0 : props.opacity ?? 1,
            transition: 'opacity 0.6s',
            transitionDelay: '0.2s',
          }}
          borderRadius={props.borderRadius ?? 0}
          {...imageProps}
          alt={props.alt ?? `${props.alt} low quality`}
        />
      )}
      <Image
        width="full"
        fit="cover"
        align="center"
        src={src}
        sx={{
          opacity: status === 'loaded' ? props.opacity ?? 1 : 0,
          transition: 'opacity 0.6s',
          transitionDelay: '0.2s',
        }}
        {...imageProps}
      />
    </>
  );
};

import * as React from 'react';

import { Template, TemplateShareLevel, TemplateType } from '@process-street/subgrade/process';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Center,
  Icon,
  MenuItem,
  useBreakpointValue,
  Text,
} from 'components/design/next';
import { TemplateShareButton } from 'directives/template-share/template-share-button';
import { TemplateMenu } from 'features/template/components/template-menu';
import { EditButton } from 'pages/pages/_id/edit/page/components/edit-button';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { TemplateMemberList } from 'components/template/membership/components/TemplateMemberList/TemplateMemberList';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { useInjector } from 'components/injection-provider';

export type ViewProps = ButtonGroupProps & {
  templateId: Template['id'];
};

export const ViewButtonGroup: React.FC<React.PropsWithChildren<ViewProps>> = ({ templateId, ...props }) => {
  const isPrivate = useIsPrivateTemplate(templateId);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const anonymous = currentUserInfoQuery.data && isAnonymousUser(currentUserInfoQuery.data.user);
  const pagePubliclyShared =
    anonymous &&
    template &&
    template.templateType === TemplateType.Page &&
    template.shareLevel === TemplateShareLevel.View;

  const { data: templateRevision } = useNewestTemplateRevisionQuery({ templateId });
  const tmplRevId = templateRevision?.id;

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId);

  const { $state } = useInjector('$state');
  const { AnonymousAuthService } = useInjector('AnonymousAuthService');
  const handleImportClick = () => {
    AnonymousAuthService.logoutIfAuthedAnonymously();
    $state.go('signUp', { pageId: templateId }, { reload: true });
  };

  const sharingIsEnabled =
    !!permissionMap &&
    (permissionMap.templatePermitsManage ||
      permissionMap.templateShareLevelUpdate ||
      permissionMap.templateShareLinkUpdate ||
      permissionMap.pageUpdate);

  if (isSmallScreen) {
    return pagePubliclyShared ? (
      <Button leftIcon={<Icon icon="edit" variant="far" size="4" />} onClick={handleImportClick}>
        <Text fontWeight="normal">
          Edit in{' '}
          <Text as="span" fontWeight="bold">
            PS
          </Text>
        </Text>
      </Button>
    ) : (
      <ButtonGroup spacing="4" ml="auto" alignItems="center" {...props}>
        {!isPrivate && <TemplateMemberList templateId={templateId} />}
        <TemplateMenu autoSelect mode="view">
          <EditButton templateId={templateId}>
            <MenuItem aria-label="edit page" />
          </EditButton>

          {!isPrivate && (
            <TemplateShareButton templateId={templateId} isDisabled={!sharingIsEnabled || !tmplRevId || !templateId}>
              <MenuItem aria-label="share page" icon={<Icon icon="share" variant="far" size="4" />}>
                Share
              </MenuItem>
            </TemplateShareButton>
          )}
        </TemplateMenu>
      </ButtonGroup>
    );
  }

  return pagePubliclyShared ? (
    <Button leftIcon={<Icon icon="edit" variant="far" size="4" />} onClick={handleImportClick}>
      <Text fontWeight="normal">
        Edit in{' '}
        <Text as="span" fontWeight="bold">
          Process Street
        </Text>
      </Text>
    </Button>
  ) : (
    <ButtonGroup spacing="4" ml="auto" alignItems="center" {...props} paddingRight={pagePubliclyShared ? 0 : 6}>
      {!isPrivate && (
        <>
          <TemplateMemberList templateId={templateId} />
          <TemplateShareButton templateId={templateId} isDisabled={!sharingIsEnabled || !tmplRevId || !templateId}>
            <Button aria-label="share page" color="gray.500" variant="unstyled" display="inline-flex">
              Share
            </Button>
          </TemplateShareButton>
        </>
      )}

      <TemplateMenu autoSelect mode="view" />
      <Center display="inline-flex">
        <EditButton templateId={templateId}>
          <Button
            aria-label="edit page"
            color="gray.600"
            bgColor="gray.200"
            _hover={{ bgColor: 'gray.100' }}
            variant="secondary"
          >
            Edit
          </Button>
        </EditButton>
      </Center>
    </ButtonGroup>
  );
};

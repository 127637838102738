import { TaskTemplate, Template, TemplateRevision } from '@process-street/subgrade/process';
import * as React from 'react';
import { TaskContentRenderer } from '../task-content-renderer';

export type WidgetListSectionProps = {
  currentTaskTemplate: TaskTemplate;
  template: Template;
  templateRevision: TemplateRevision;
};

export const WidgetListSection = React.memo(
  ({ currentTaskTemplate, template, templateRevision }: WidgetListSectionProps) => {
    return (
      <TaskContentRenderer
        template={template}
        templateRevision={templateRevision}
        taskTemplate={currentTaskTemplate}
        taskTemplateGroupId={currentTaskTemplate.group.id}
      />
    );
  },
);

WidgetListSection.displayName = 'WidgetListSection';

import angular from 'angular';
import { InboxItemType, InboxItemUtils } from '@process-street/subgrade/inbox';
import { InboxConstants } from '@process-street/subgrade/inbox/inbox-constants';
import { TaskListConstants } from '@process-street/subgrade/process/task-list-constants';
import { connectController } from 'reducers/util';
import templateUrl from './checklist-details.component.html';
import './checklist-details.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { StopTaskEvent } from 'services/stop-task-event';

angular.module('frontStreetApp.directives').component('psChecklistInboxItemDetails', {
  bindings: {
    user: '<',
    item: '<',
    organization: '<',
    inboxListCtrl: '<',
    isTasksPageModal: '<',
    shouldScrollToComments: '<',
    onLoaded: '&',
    onDueDateUpdate: '&',
    onDueDateRemove: '&',
    onComplete: '&',
  },
  require: {
    inboxItemDetailsCtrl: '^psInboxItemDetails',
  },
  templateUrl,
  controller(
    $ngRedux,
    $rootScope,
    $scope,
    $state,
    $timeout,
    ApprovalActions,
    ChecklistRevisionService,
    FeatureFlagService,
    TaskActions,
    TaskStatsActions,
    TaskTemplateService,
    ToastService,
  ) {
    const ctrl = this;
    ctrl.loaded = false;
    ctrl.selectedTaskTemplate = undefined;
    ctrl.taskListMode = TaskListConstants.Mode.INBOX;

    ctrl.taskItemDetailsMode = InboxConstants.TaskItemDetailsMode.CHECKLIST_TASK;

    const mapDispatchToThis = () => ({
      getAllApprovalsByChecklistRevisionId: ApprovalActions.getAllByChecklistRevisionId,
      getAllTaskStatsByChecklistRevisionId: TaskStatsActions.getAllByChecklistRevisionId,
      getAllTasksByChecklistRevisionId: TaskActions.getAllByChecklistRevisionId,
    });

    ctrl.$onChanges = function (changes) {
      if (changes.item && changes.item.currentValue) {
        connectController($ngRedux, null, mapDispatchToThis)(ctrl);
        ctrl.item = changes.item.currentValue;
        resetSelectedTaskTemplate();
        init();
      }
    };

    ctrl.$onInit = function () {
      ctrl.inboxItemDetailsCtrl.registerChecklistItemDetailsController(ctrl);
    };

    function resetSelectedTaskTemplate() {
      ctrl.selectedTaskTemplate = undefined;
      constructSelectedTask();
    }

    let taskListCtrl;
    ctrl.registerTaskListCtrl = function (psTaskList) {
      taskListCtrl = psTaskList;
    };

    ctrl.isTaskTemplateDisabled = function (taskTemplate) {
      if (taskListCtrl) {
        return taskListCtrl.isTaskTemplateDisabled(taskTemplate);
      } else {
        return true;
      }
    };

    ctrl.canMoveToNextTaskTemplate = function (taskTemplate) {
      let canMoveToNext = false;
      if (taskListCtrl) {
        canMoveToNext = taskListCtrl.canMoveToNextTaskTemplate(taskTemplate);
      }

      return canMoveToNext;
    };

    ctrl.toggleSelectedChecklistTaskStatus = function () {
      if (taskListCtrl) {
        taskListCtrl.toggleTaskStatus(ctrl.selectedTaskTemplate);
      }
    };

    ctrl.navigateToTask = function (task) {
      $state.go('checklist.task', { id: ctrl.item.checklist.id, groupId: task.taskTemplate.group.id });
    };

    ctrl.onAssigneesUpdate = function () {
      ctrl.inboxListCtrl.onChecklistItemAssigneesUpdate(ctrl.item);
    };

    ctrl.onAutocomplete = function (allTasksCompleted, checklistHasTasks) {
      if (checklistHasTasks && allTasksCompleted) {
        ctrl.inboxListCtrl.completeItem(ctrl.item, true /*autocompleted*/);
      }
    };

    ctrl.updateFormFieldValueMap = function (formFieldValueMap) {
      if (taskListCtrl) {
        taskListCtrl.updateFormFieldValueMap(formFieldValueMap);
      }
    };

    ctrl.checklistTaskTemplates = [];
    ctrl.onTaskTemplatesLoaded = function (taskTemplates) {
      ctrl.checklistTaskTemplates = taskTemplates;
    };

    ctrl.selectedTaskItem = undefined;
    ctrl.onSelectTaskTemplate = function (taskTemplate) {
      ctrl.selectedTaskTemplate = taskTemplate;

      // Build selected task object for the task details component
      constructSelectedTask();
    };

    ctrl.selectNextTaskTemplate = function () {
      if (!ctrl.selectedTaskTemplate || !taskListCtrl) {
        return;
      }

      taskListCtrl.selectTaskTemplateBelow(ctrl.selectedTaskTemplate);
    };

    ctrl.unselectTaskItem = function () {
      resetSelectedTaskTemplate();
    };

    function constructSelectedTask() {
      if (ctrl.selectedTaskTemplate) {
        const task = taskListCtrl.getTaskByTaskTemplateGroupId(ctrl.selectedTaskTemplate.group.id);
        task.taskTemplate = ctrl.selectedTaskTemplate;

        ctrl.selectedTaskItem = task;

        const itemType = TaskTemplateService.isApproval(ctrl.selectedTaskTemplate)
          ? InboxItemType.ApprovalTask
          : InboxItemType.StandardTask;

        ctrl.selectedTaskItem = {
          itemType,
          task,
          assignees: taskListCtrl.getTaskAssignees(ctrl.selectedTaskTemplate),
          checklist: ctrl.item.checklist,
          template: ctrl.item.template,
        };
      } else {
        ctrl.selectedTaskItem = undefined;

        //to show all errors on the task list
        initChecklistData();
      }
    }

    ctrl.shouldShowChecklistDetails = function () {
      return ctrl.loaded && !ctrl.selectedTaskItem;
    };

    ctrl.shouldShowSelectedTaskDetails = function () {
      return ctrl.loaded && ctrl.selectedTaskItem;
    };

    ctrl.isTaskSelected = function () {
      return !!ctrl.selectedTaskItem;
    };

    ctrl.getDueDate = function () {
      return ctrl.item.checklist.dueDate;
    };

    ctrl.getHash = function () {
      return `${ctrl.item.itemType}|${ctrl.item.checklist.id}`;
    };

    ctrl.isCompleted = function () {
      return InboxItemUtils.isCompleted(ctrl.item);
    };

    ctrl.requiredTaskIds = []; // attached tasks blocking WFR completion
    $rootScope.$on(StopTaskEvent.CHECKLIST_HAS_NOT_COMPLETED_ATTACHED_TASKS, (_event, requiredOneOffTasks) => {
      ctrl.requiredTaskIds = requiredOneOffTasks.map(t => t.id);
    });

    ctrl.scrollToComments = () => {
      $timeout(() => {
        const commentsSection = document.querySelector('ps-task-comments');

        if (!commentsSection) return;

        commentsSection.scrollIntoView({ behavior: 'smooth' });
        ctrl.shouldScrollToComments = false;
      }, 1000);
    };

    ctrl.scrollToFirstTaskWithComments = ({ payload: taskStats }) => {
      if (!ctrl.shouldScrollToComments) return;

      const firstTaskStatWithComments = taskStats.find(taskStat => !taskStat.hidden && taskStat.commentsCount > 0);
      const firstNonCompletedTaskStatWithComments = taskStats.find(
        taskStat => !taskStat.hidden && !taskStat.completed && taskStat.commentsCount > 0,
      );

      // Select the first non completed task with comments, otherwise, the first task with comments
      const taskIdToSelect = firstNonCompletedTaskStatWithComments?.id ?? firstTaskStatWithComments?.id;

      if (!taskIdToSelect) return;

      // Fetch all the tasks to be able to get the taskTemplate
      ctrl.actions.getAllTasksByChecklistRevisionId(ctrl.checklistRevision.id).then(({ payload: tasks }) => {
        const taskTemplate = tasks.find(task => task.id === taskIdToSelect)?.taskTemplate;

        if (!taskTemplate) return;

        ctrl.onSelectTaskTemplate(taskTemplate);

        ctrl.scrollToComments();
      });
    };

    function init() {
      initChecklistData().then(() => {
        ctrl.loaded = true;
        ctrl.onLoaded();
      });
    }

    function initChecklistData() {
      // get active checklist revision
      return ChecklistRevisionService.getActiveByChecklistId(ctrl.item.checklist.id).then(
        revision => {
          ctrl.checklistRevision = revision;
          ctrl.templateRevision = revision.templateRevision;
          ctrl.actions.getAllApprovalsByChecklistRevisionId(ctrl.checklistRevision.id);
          ctrl.actions
            .getAllTaskStatsByChecklistRevisionId(ctrl.checklistRevision.id)
            .then(ctrl.scrollToFirstTaskWithComments);
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the workflow run`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });

          $state.go('inbox');
        },
      );
    }
  },
});

import { TaskTemplate, TextWidget as TW, WithTaskTemplate } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { TinyMCEEditor } from 'features/rich-text';
import {
  DeleteWidgetByHeaderIdMutation,
  UpdateWidgetMutation,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, sendParent } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';

type TextWidget = WithTaskTemplate<TW>;

type Context = {
  widget: TextWidget;
  initialWidget: TextWidget;
  editor?: TinyMCEEditor;
  // There is a race condition between the auto focus event from parent and the editor being ready.
  shouldAutoFocus: boolean;
  recentlyMovedFrom?: TaskTemplate;
};

type Event =
  | WidgetEvent<TextWidget>
  | { type: 'CHANGE'; content: string }
  | { type: 'BLUR' }
  | { type: 'FOCUS' }
  | { type: 'AUTO_FOCUS' }
  | { type: 'SET_EDITOR'; editor: TinyMCEEditor }
  // Internal type
  | { type: 'done.invoke.updateWidget'; data: TextWidget };

export type TextContentMachine = ReturnType<typeof makeTextContentMachine>;
export type TextContentActor = ActorRefFrom<TextContentMachine>;

export const makeTextContentMachine = ({
  widget,
  sharedContext,
}: {
  widget: TextWidget;
  sharedContext: SharedContext;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: widget.header.taskTemplate.templateRevision.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: `text-content:${widget.id}`,
      initial: 'blurred',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./text-content-machine.typegen').Typegen0,
      context: {
        widget,
        initialWidget: widget,
        shouldAutoFocus: false,
        recentlyMovedFrom: undefined,
      },
      states: {
        blurred: {
          on: {
            FOCUS: { target: 'focused' },
            // AUTO_FOCUS and SET_EDITOR have a race condition, combined they handle both cases.
            AUTO_FOCUS: [
              { cond: 'editorIsReady', target: 'focused', actions: ['focusEditor'] },
              { actions: ['setShouldAutoFocus'] },
            ],
            SET_EDITOR: [
              { cond: 'shouldAutoFocus', target: 'focused', actions: ['setEditor', 'focusEditor', 'disableAutoFocus'] },
              { actions: ['setEditor'] },
            ],
            DELETE_WIDGET: { target: 'deleting' },
            MOVE_DOWN: { actions: ['sendMoveDown'] },
            MOVE_UP: { actions: ['sendMoveUp'] },
            DUPLICATE: { actions: ['sendDuplicate'] },
            MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
            MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
            UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
          },
        },
        focused: {
          on: {
            BLUR: [
              {
                cond: 'contentDidChange',
                target: 'saving',
              },
              { target: 'blurred' },
            ],
            CHANGE: {
              target: '.typing',
              actions: ['setContent'],
            },
          },
          initial: 'idle',
          states: {
            idle: {},
            typing: {
              on: {
                CHANGE: {
                  target: 'typing',
                  internal: false,
                  actions: ['setContent'],
                },
              },
              after: {
                DEBOUNCE_DELAY: [
                  {
                    cond: 'contentDidChange',
                    target: 'saving',
                  },
                  {
                    target: 'idle',
                  },
                ],
              },
            },
            saving: {
              invoke: [
                {
                  id: 'updateWidget',
                  src: 'updateWidget',
                  onDone: {
                    target: 'idle',
                    actions: ['setWidget'],
                  },
                  onError: {
                    target: 'idle',
                    actions: ['resetWidget'],
                  },
                },
              ],
            },
          },
        },
        saving: {
          invoke: [
            {
              id: 'updateWidget',
              src: 'updateWidget',
              onDone: {
                target: 'blurred',
                actions: ['setWidget'],
              },
              onError: {
                target: 'blurred',
                actions: ['resetWidget'],
              },
            },
          ],
        },
        deleting: {
          invoke: [
            {
              id: 'deleteWidget',
              src: 'deleteWidget',
              onDone: {
                target: 'deleted',
              },
              onError: {
                target: 'blurred',
              },
            },
          ],
        },
        deleted: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        setContent: assign({
          widget: (context, e) => {
            return { ...context.widget, content: e.content };
          },
        }),
        setWidget: assign({
          widget: (_, e) => e.data,
          initialWidget: (_, e) => e.data,
        }),
        resetWidget: assign({
          widget: context => context.initialWidget,
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        setEditor: assign({
          editor: (_ctx, e) => {
            return e.editor;
          },
        }),
        focusEditor: (context, _) => {
          context.editor?.focus();
        },
        setShouldAutoFocus: assign({ shouldAutoFocus: (_ctx, _e) => true }),
        disableAutoFocus: assign({ shouldAutoFocus: (_ctx, _e) => false }),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget?.header,
                  ...{ ...(header as TextWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
      },
      services: {
        updateWidget: async context => {
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<TextWidget>(context.widget!),
            onSuccess: ({ content }) => {
              cacheSetter.update({ ...context.widget, content });
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidget: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget!.header.id),
            onSuccess: () => {
              cacheSetter.delete(context.widget!);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the widget`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
      delays: {
        DEBOUNCE_DELAY: 500,
      },
      guards: {
        contentDidChange: (ctx, _e) => ctx.initialWidget.content !== ctx.widget.content,
        editorIsReady: (ctx, _e) => Boolean(ctx.editor),
        shouldAutoFocus: (ctx, _e) => ctx.shouldAutoFocus,
      },
    },
  );
};

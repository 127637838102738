import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  Skeleton,
  Spacer,
  Text,
  useDisclosure,
} from 'components/design/next';
import { TemplateType } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useTemplateMenuContext } from '..';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useRedirectToFolderView } from '../delete-template-button/use-redirect-to-folder-view';
import { usePageDraftShouldAutoDelete } from 'pages/pages/_id/edit/page/use-page-draft-should-auto-delete';
import { match } from 'ts-pattern';
import { useDiscardDraftMutation, useGetTemplateQuery } from 'features/template/query-builder';
import { useDispatch } from 'react-redux';
import { TEMPLATE_REVISION_DISCARD_BY_ID_SUCCESS } from 'reducers/template-revision/template-revision.actions';

export interface DiscardTemplateButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const DiscardTemplateButton: React.FC<React.PropsWithChildren<DiscardTemplateButtonProps>> = React.memo(
  ({ children }) => {
    const { setCloseOnBlur, templateId } = useTemplateMenuContext();
    const disclosure = useDisclosure({
      id: 'discard',
      onOpen: () => {
        setCloseOnBlur(false);
      },
      onClose: () => {
        setCloseOnBlur(true);
      },
    });

    const templateQuery = useGetTemplateQuery({ templateId });
    const template = templateQuery.data;

    const templateRevisionQuery = useNewestTemplateRevisionQuery(
      {
        templateId,
        editable: true,
      },
      { enabled: !!templateId },
    );

    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('MoveTemplateButton child must be a single clickable element');
    }

    // UI
    const btn = React.cloneElement(children, {
      onClick: () => {
        disclosure.onToggle();
      },
    });

    const cancelRef = React.useRef<HTMLButtonElement>(null);
    const TemplateDiscard = template?.templateType === TemplateType.Page ? Page : Workflow;

    return match(templateRevisionQuery)
      .with({ status: 'loading' }, () => <Skeleton display="inline-block">{btn}</Skeleton>)
      .otherwise(() => (
        <TemplateDiscard>
          {({ discardDraftMutation }) => (
            <>
              {btn}
              <AlertDialog
                leastDestructiveRef={cancelRef}
                isOpen={disclosure.isOpen}
                onClose={disclosure.onClose}
                onEsc={disclosure.onClose}
                onOverlayClick={disclosure.onClose}
                size="xl"
                scrollBehavior="inside"
              >
                <AlertDialogOverlay />
                <AlertDialogContent pt="0">
                  <AlertDialogHeader py="4" px="8" borderBottom="1px" borderColor="gray.200" fontSize="lg">
                    Discard draft?
                  </AlertDialogHeader>
                  <AlertDialogCloseButton />
                  <AlertDialogBody pt="4" px="8" pb="8">
                    <Text>
                      All unpublished edits to this will be discarded and{' '}
                      <Text as="span" fontWeight="700">
                        can’t be recovered
                      </Text>
                      .
                    </Text>
                  </AlertDialogBody>
                  <AlertDialogFooter py="6" px="8" borderTop="1px" borderColor="gray.200">
                    <Spacer />
                    <Button ref={cancelRef} variant="ghost" mr="4" onClick={disclosure.onClose} colorScheme="gray">
                      Cancel
                    </Button>
                    <Button
                      isDisabled={discardDraftMutation.isLoading}
                      variant="danger"
                      onClick={() => {
                        if (templateRevisionQuery.data?.id) {
                          discardDraftMutation.mutate(
                            { tmplRevId: templateRevisionQuery.data?.id },
                            {
                              onSuccess: () => {
                                if (disclosure.isOpen) {
                                  disclosure.onClose();
                                }
                              },
                            },
                          );
                        }
                      }}
                    >
                      Discard
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
        </TemplateDiscard>
      ));
  },
);

type DiscardProps = {
  children: ({
    discardDraftMutation,
  }: {
    discardDraftMutation: ReturnType<typeof useDiscardDraftMutation>;
  }) => React.ReactElement;
};

const Workflow: React.FC<DiscardProps> = React.memo(({ children }) => {
  const { $state } = useInjector('$state');
  const { templateId } = useTemplateMenuContext();
  const dispatch = useDispatch();

  const discardDraftMutation = useDiscardDraftMutation({
    onSuccess: () => {
      dispatch({ type: TEMPLATE_REVISION_DISCARD_BY_ID_SUCCESS });
      if ($state.params.groupId) {
        $state.go('templateView.task', { id: templateId, groupId: $state.params.groupId });
      } else {
        $state.go('templateView', { id: templateId });
      }
    },
  });

  const permissionQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
  const canDiscardTemplate = permissionQuery.data?.permissionMap?.templateUpdate;
  return canDiscardTemplate ? children({ discardDraftMutation }) : null;
});

const Page: React.FC<DiscardProps> = React.memo(({ children }) => {
  const { $state } = useInjector('$state');
  const { templateId, view } = useTemplateMenuContext();

  const { redirectToFolderView } = useRedirectToFolderView({ templateId });
  const { shouldAutoDelete, deleteTemplate } = usePageDraftShouldAutoDelete(templateId);
  const discardDraftMutation = useDiscardDraftMutation({
    onSuccess: () => {
      if (shouldAutoDelete) {
        deleteTemplate.mutate(
          { templateId },
          {
            onSuccess: () => {
              if (view === 'show') {
                redirectToFolderView();
              }
            },
          },
        );
      } else {
        $state.go('pageView', { id: templateId });
      }
    },
  });

  const permissionQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
  const canDiscardTemplate = permissionQuery.data?.permissionMap?.pageUpdate;
  return canDiscardTemplate ? children({ discardDraftMutation }) : null;
});

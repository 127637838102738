import { useInjector } from 'components/injection-provider';

export const useWorkflowState = (): 'edit' | 'view' | 'run' | 'dashboard' | 'unknown' => {
  const { $state } = useInjector('$state');
  if ($state.includes('templateDashboard')) return 'dashboard';
  if ($state.includes('template') || $state.includes('form') || $state.includes('templateV2')) return 'edit';
  if ($state.includes('templateView')) return 'view';
  if ($state.includes('checklist')) return 'run';
  return 'unknown';
};

import { Widget } from '@process-street/subgrade/process';
import { MenuItem, Icon } from 'components/design/next';
import { useWidgetActorRef } from 'pages/forms/_id/shared/widget-context/widget-context';
import * as React from 'react';
import { useUIActorRef } from 'pages/forms/_id/shared';
import { useMoveToStepStore } from './move-to-step-store';

export interface MoveToStepMenuItemProps {
  widget: Widget;
}

export const MoveToStepMenuItem: React.FC<React.PropsWithChildren<MoveToStepMenuItemProps>> = ({
  widget,
  ...props
}) => {
  const { widgetActorRef } = useWidgetActorRef();
  const { uiActorRef } = useUIActorRef();
  const { dispatch } = useMoveToStepStore();

  return (
    <MenuItem
      iconSpacing="2"
      aria-label="Move to another step"
      icon={<Icon icon="arrow-right-from-line" size="4" />}
      onClick={() => {
        dispatch({ type: 'SET_SELECTED_WIDGET', payload: widget });
        dispatch({ type: 'SET_SELECTED_WIDGET_ACTOR_REF', payload: widgetActorRef });
        uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'moveToStep' });
      }}
      {...props}
    >
      Move to another step
    </MenuItem>
  );
};

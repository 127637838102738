import * as React from 'react';
import { HStack, Hide, Icon, IconButton, Spinner } from 'components/design/next';
import { AssignTaskButton } from '../assign-task-button';
import { useStateParam } from 'hooks/use-state-param';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { useGetNewestTemplateRevisionsByTemplateIdQuery, useGetTemplateQuery } from 'features/template/query-builder';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { match } from 'ts-pattern';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import {
  isHeading,
  TaskTemplate,
  TaskTemplateTaskType,
  TemplateRevisionStatus,
  TemplateStatus,
} from '@process-street/subgrade/process';
import { StopTaskButton } from '../stop-task-button';
import { TaskTemplateAutomationsButton } from '../task-template-automations-button';
import { TaskTemplateConditionalLogicButton } from '../task-template-conditional-logic-button';
import { useCurrentUser } from 'hooks/use-current-user';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { canAccess, Feature } from 'services/features/features';
import { DueDatePicker } from 'features/dynamic-due-dates';
import { TaskPermissionsPicker } from 'components/task-permission/components/task-permissions-picker';
import { Muid } from '@process-street/subgrade/core';

type Step = {
  id: Muid;
  name: string;
  taskType: TaskTemplateTaskType;
};

export interface TaskButtonStackProps {
  activeStep: Step;
  editTaskName: (taskTemplate: TaskTemplate, step: Step) => void;
  setHasAutomations?: (hasAutomations: boolean) => void;
  setHasConditionalLogic: (hasConditionalLogic: boolean) => void;
}

export const TaskButtonStack: React.FC<React.PropsWithChildren<TaskButtonStackProps>> = ({
  activeStep,
  editTaskName,
  setHasAutomations,
  setHasConditionalLogic,
}) => {
  const currentUser = useCurrentUser();
  const selectedOrganization = useSelectedOrganization();
  const planId = selectedOrganization?.subscription.plan.id;
  const canAccessDynamicDueDates = currentUser && planId && canAccess(Feature.DYNAMIC_DUE_DATES, planId);

  const templateId = useStateParam({ key: 'id' });
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;

  const templateRevisionsQuery = useGetNewestTemplateRevisionsByTemplateIdQuery({ templateId });
  const [publishedTmplRev, draftTmplRev] = templateRevisionsQuery.data ?? [];
  const workflowState = useWorkflowState();
  const templateRevision = match(workflowState)
    .with('edit', () => draftTmplRev)
    .with('view', () => publishedTmplRev)
    .otherwise(() => undefined);

  const taskTemplateQuery = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId: templateRevision?.id },
    {
      select: taskTemplates => taskTemplates.find(t => t.group.id === taskTemplateGroupId),
    },
  );
  const taskTemplate = taskTemplateQuery.data;

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId);

  const canManageTasks =
    templateRevision?.status === TemplateRevisionStatus.Draft &&
    template?.status === TemplateStatus.Active &&
    permissionsQuery.data?.permissionMap.templateUpdate;

  const isEditable = workflowState === 'edit';
  const isTaskHeading = taskTemplate ? isHeading(taskTemplate) : false;

  return (
    // unfortunately have to match the angular 30px value
    <HStack px="30px" spacing="3" mx="auto" maxW="700px">
      {taskTemplate && templateRevision ? (
        <>
          <Hide above="lg">
            <IconButton
              aria-label="Edit Task name"
              icon={<Icon icon="pencil" size="4" color="gray.400" variant="far" />}
              onClick={() => editTaskName(taskTemplate, activeStep)}
              variant="outline"
              borderColor="gray.300"
              borderWidth="px"
              size="sm"
              px={1}
            />
          </Hide>
          {!isTaskHeading && (
            <AssignTaskButton
              taskTemplate={taskTemplate}
              templateRevision={templateRevision}
              isDisabled={!canManageTasks}
              isEditable={isEditable}
            />
          )}
          {canAccessDynamicDueDates && !isTaskHeading && (
            <DueDatePicker
              selectedTaskTemplate={taskTemplate}
              selectedTaskTemplates={[]}
              editable={isEditable}
              templateRevision={templateRevision}
              disabled={!canManageTasks}
            />
          )}
          {isEditable && (
            <TaskPermissionsPicker
              taskTemplates={[taskTemplate]}
              templateRevisionId={templateRevision.id}
              isDisabled={!canManageTasks}
            />
          )}
          {isEditable && (
            <TaskTemplateConditionalLogicButton
              taskTemplate={taskTemplate}
              templateRevision={templateRevision}
              setHasConditionalLogic={setHasConditionalLogic}
            />
          )}
          {!isTaskHeading && (
            <TaskTemplateAutomationsButton
              taskTemplate={taskTemplate}
              templateRevision={templateRevision}
              setHasAutomations={setHasAutomations}
            />
          )}
          {!isTaskHeading && <StopTaskButton taskTemplate={taskTemplate} templateRevision={templateRevision} />}
        </>
      ) : (
        <Spinner />
      )}
    </HStack>
  );
};

import * as React from 'react';
import { NumberFormFieldValue, NumberFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  Spinner,
  Text,
  VStack,
} from 'components/design/next';
import { NumberFieldInputGroup } from '../../../../../components/widgets/form-field/number/common';
import { UnsavedChangesIcon } from 'components/widgets/form-field/common/unsaved-changes-icon/unsaved-changes-icons';
import { ChecklistFormFieldWidgetProps } from '../../../../../components/widgets/form-field/common/types';
import { useChecklistNumberFormField } from './use-checklist-number-form-field';
import { FormFieldValueHelperText } from 'pages/runs/_id/components/form-field-value-helper-text';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import identity from 'lodash/identity';
import { WidgetProvider } from '../../hooks/use-widget-context';
import { useWorkflowRunStore } from '../../workflow-run-store';
import { match } from 'ts-pattern';

export interface Props
  extends Omit<InputProps, 'isInvalid'>,
    ChecklistFormFieldWidgetProps<NumberFormFieldWidget, NumberFormFieldValue> {}

export const ChecklistNumberFormField: React.FC<React.PropsWithChildren<Props>> = ({
  widget,
  formFieldValue,
  editable,
  onUpdateValue,
  onInteractionEnd,
  isInvalid,
  readOnly,
  ...props
}) => {
  const { inputProps, hasValidationErrors, errorMessages, handleReset, aiAutomationStatus } =
    useChecklistNumberFormField({
      widget,
      formFieldValue,
      editable,
      onUpdateValue,
      onInteractionEnd,
      readOnly,
    });

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);

  const maskedInputParser = React.useMemo(
    () => (hasDefaultValue && hasVariables ? MaskedInputParsers.makeParser('merge-tags') : identity),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(() => maskedInputParser(String(inputProps.value)), [maskedInputParser, inputProps.value]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const showLeftAddon = widget.config.unit && widget.config.unitLocation !== 'suffix';
  const showRightAddon = widget.config.unit && widget.config.unitLocation === 'suffix';

  const addOutputWidgetClickedDuringAiTask = useWorkflowRunStore(state => state.addOutputWidgetClickedDuringAiTask);

  return (
    <WidgetProvider widget={widget}>
      <FormControl
        as={VStack}
        alignItems="stretch"
        isInvalid={hasValidationErrors || isInvalid}
        isRequired={widget.required}
        isDisabled={readOnly}
      >
        <HStack lineHeight="normal">
          {hasValidationErrors ? <UnsavedChangesIcon /> : null}
          <FormLabel m="0">{widget.label || 'Untitled Number'}</FormLabel>
          {aiAutomationStatus === 'AutomationRunning' && (
            <Spinner color="gray.300" data-testid="native-automation-running-spinner" />
          )}
        </HStack>

        <NumberFieldInputGroup>
          {/* HStack spacing not working well with flex-direction nor order props on parent/child respectively */}
          {showLeftAddon ? <InputLeftAddon>{widget.config.unit}</InputLeftAddon> : null}

          <MaskedInput
            onClick={() => {
              if (aiAutomationStatus === 'AutomationRunning') {
                addOutputWidgetClickedDuringAiTask(widget.id);
              }
            }}
            w="full"
            html={html}
            sx={{
              '& input': {
                ...(showLeftAddon ? { borderLeftRadius: 0 } : {}),
                ...(showRightAddon ? { borderRightRadius: 0 } : {}),
                borderColor: readOnly ? 'gray.600' : 'inherit',
              },
            }}
          >
            <Input
              {...{ ...props, ...inputProps, ref: inputRef }}
              bgColor={match({ hasDefaultValue, readOnly })
                .with({ readOnly: true }, () => 'gray.200')
                .with({ hasDefaultValue: true }, () => 'yellow.100')
                .otherwise(() => undefined)}
            />
          </MaskedInput>

          {showRightAddon ? <InputRightAddon>{widget.config.unit}</InputRightAddon> : null}
        </NumberFieldInputGroup>

        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={handleReset}
        />

        {hasValidationErrors && (
          <FormErrorMessage>
            <VStack alignItems="flex-start" spacing="px">
              {errorMessages.map((errorMessage, i) => (
                <Text variant="-2" key={i}>
                  {errorMessage}
                </Text>
              ))}
            </VStack>
          </FormErrorMessage>
        )}
      </FormControl>
    </WidgetProvider>
  );
};

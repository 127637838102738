import { Box, Grid, HStack, useToast, VStack } from 'components/design/next';
import * as React from 'react';

import { GetDataSetRowsQuery, useGetDataSetRowsQuery } from '../../query-builder/get-data-set-rows';
import { GetAllDataSetsQuery } from '../../query-builder/get-all-data-sets';
import {
  DataSet,
  DataSetColumnFilterForm,
  DataSetColumnState,
  DataSetColumnStateModel,
  SavedView,
  SavedViewType,
} from '@process-street/subgrade/process';
import { MainTabs } from 'pages/reports/components/main-tabs';
import { TableLoadingSkeleton } from '../table-loading-skeleton';

import { DataSetTableV1Wrapper } from './data-set-table-v1/data-set-table-v1-wrapper';
import { DataSetSavedViewsSidebar } from '../saved-views-sidebar';
import { SelectedDataSetContext, SelectedSavedViewContext } from '../../context';
import { DataSetDropdownOptionType, DataSetsDropdown } from '../data-sets-dropdown';
import { CreateDataSetButton } from '../create-data-set-button';
import { useInjector } from 'components/injection-provider';
import { match, P } from 'ts-pattern';
import { useUpdateEffect } from 'react-use';
import { useDataSetFilters } from '../../store';
import { useUpdateSavedViewMutation } from '../../query-builder';
import { ColumnResizedEvent, SortChangedEvent } from '@ag-grid-community/core';
import { Option } from 'space-monad';
import { UpdateDataSetButton } from '../update-data-set-button/update-data-set-button';
import _keyBy from 'lodash/keyBy';
import { Muid } from '@process-street/subgrade/core';
import { useDataSetsGuard } from '../../hooks/use-data-sets-guard';
import { DataSetPageSkeleton } from './data-set-table-v1/data-set-page-skeleton';
import { LinkedWorkflowsButton } from 'pages/reports/data-sets/components/linked-workflows-button';
import { ShareDataSetButtonV1 } from '../share/share-data-set-button-v1';
import { useFeatureFlag } from 'features/feature-flags';
import { DataSetUtils } from './data-set-utils';
import { ExportToCsvButton } from 'pages/reports/data-sets/components/export';
import { useQueryClient } from 'react-query';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { WebhooksButton } from '../webhooks-button/webhooks-button';
import { DataSetPageHelpers } from 'pages/reports/data-sets/components/data-set-page/data-set-page-helpers';
import { DataSetTableWrapper } from 'pages/reports/data-sets/components/data-set-page/data-set-table/data-set-table-wrapper';
import { DataSetFiltersBarV1 } from 'pages/reports/data-sets/components/data-set-page/data-set-filters-bar-v1';

export const DataSetPageV1 = () => {
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const { $stateParams, $state } = useInjector('$stateParams', '$state');
  const isWorkdayIntegrationEnabled = useFeatureFlag('workdayIntegration');
  const toast = useToast();
  useDataSetsGuard();

  const isFirstLoadRef = React.useRef(true);

  const selectedSavedViewState = React.useState<SavedView | null>(null);
  const selectedDataSetState = React.useState<DataSet | null>(null);

  const [selectedSavedView, setSelectedSavedView] = selectedSavedViewState;
  const [selectedDataSet, setSelectedDataSet] = selectedDataSetState;

  const [dropdownValue, setDropdownValue] = React.useState<DataSetDropdownOptionType | undefined>(undefined);

  const dataSetId = selectedDataSet?.id;
  const savedViewId = selectedSavedView?.id;
  const lastDataSetLoadedId = React.useRef<Muid | null>(null);

  const dataSetFilters = useDataSetFilters();

  const columnsWithFilters: DataSetColumnState[] = React.useMemo(() => {
    return DataSetColumnStateModel.combineColumnsWithFilters(dataSetFilters.columns, dataSetFilters.filters);
  }, [dataSetFilters.columns, dataSetFilters.filters]);

  const dataSetRowsQuery = useGetDataSetRowsQuery(
    { id: dataSetId ?? $stateParams.dataSetId, savedViewId, columns: columnsWithFilters },
    {
      enabled: Boolean(dataSetId ?? $stateParams.dataSetId) && dataSetFilters.columns.length > 0,
      // Reduce the server load
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      onSuccess: () => {
        if (dataSetId) lastDataSetLoadedId.current = dataSetId;
      },
    },
  );

  const [previousDataSets, setPreviousDataSets] = React.useState<GetAllDataSetsQuery.Response>();
  const onLoadDataSets = React.useCallback(
    (dataSets: GetAllDataSetsQuery.Response) => {
      const hasNewDataSets = previousDataSets && dataSets.length > previousDataSets.length;

      const { dataSet, savedView, columns, filters } = match({
        isFirstLoad: isFirstLoadRef.current,
        hasNewDataSets,
        selectedDataSet,
      })
        .with({ isFirstLoad: true }, () => DataSetPageHelpers.handleFirstLoad(dataSets, $stateParams))
        .with({ hasNewDataSets: true }, () => handleNewDataSetAdded(dataSets))
        .otherwise(() => handleDataSetsUpdate(dataSets, selectedDataSet, selectedSavedView));

      if (isFirstLoadRef.current) {
        isFirstLoadRef.current = false;
      }

      if (!dataSet) return;

      const dropdownValue: DataSetDropdownOptionType = {
        value: savedView?.id ?? dataSet.id,
        label: savedView?.name ?? dataSet.name,
        type: savedView ? 'SavedView' : 'DataSet',
        dataSet,
      };

      setDropdownValue(dropdownValue);
      setSelectedDataSet(dataSet);
      setSelectedSavedView(savedView ?? null);

      if (columns) dataSetFilters.setColumnsState({ columns });
      if (filters) dataSetFilters.setFilters({ filters });
    },
    [
      $stateParams,
      dataSetFilters,
      previousDataSets,
      selectedDataSet,
      selectedSavedView,
      setSelectedDataSet,
      setSelectedSavedView,
    ],
  );

  const dataSetsQuery = GetAllDataSetsQuery.useQuery({
    onSuccess: dataSets => {
      onLoadDataSets(dataSets);
      setPreviousDataSets(dataSets);
    },
  });

  React.useEffect(
    function setDefaultDataSet() {
      if (isFirstLoadRef.current && !selectedDataSet && dataSetsQuery.isSuccess) {
        onLoadDataSets(dataSetsQuery.data);
      }
    },
    [dataSetsQuery, onLoadDataSets, selectedDataSet],
  );

  const updateSavedViewMutation = useUpdateSavedViewMutation({
    onSuccess: updatedSavedView => {
      void dataSetsQuery.refetch();
      toast({
        status: 'success',
        title: 'Saved view successfully updated',
      });

      if (updatedSavedView.id === selectedSavedView?.id) {
        // Keep the selected saved view in sync after the update
        setSelectedSavedView(updatedSavedView);
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems updating the saved view",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const queryClient = useQueryClient();
  const handleDropdownChange = (value: DataSetDropdownOptionType | undefined | null) => {
    setDropdownValue(value ?? undefined);

    if (!value) {
      setDropdownValue(undefined);
      setSelectedSavedView(null);
      setSelectedDataSet(null);

      return;
    }

    setSelectedDataSet(value.dataSet);

    if (value?.type === 'DataSet') {
      const columns = value.dataSet.columnDefs.map(DataSetColumnStateModel.fromColumnDef);
      dataSetFilters.setColumnsState({ columns });
      dataSetFilters.setFilters({ filters: [] });

      void queryClient.invalidateQueries(GetDataSetRowsQuery.getKey({ id: value.dataSet.id, columns }));

      setSelectedSavedView(null);
    } else {
      const savedView = value.dataSet.savedViews.find(savedView => savedView.id === value.value);

      setSelectedSavedView({
        id: value.value,
        name: value.label,
        columns: [],
        canRead: savedView?.canRead ?? false,
        canUpdate: savedView?.canUpdate ?? false,
        savedViewType: savedView?.savedViewType ?? SavedViewType.Standard,
      });

      if (savedView) {
        dataSetFilters.setColumnsState({ columns: savedView.columns });
        dataSetFilters.setFilters({ filters: DataSetColumnStateModel.getFilters(savedView.columns) });
        void queryClient.invalidateQueries(
          GetDataSetRowsQuery.getKey({
            id: value.dataSet.id,
            columns: savedView.columns,
          }),
        );
      }
    }
  };

  const handleUpdateSavedView = () => {
    if (selectedSavedView && selectedDataSet) {
      updateSavedViewMutation.mutate({
        columns: DataSetColumnStateModel.combineColumnsWithFilters(dataSetFilters.columns, dataSetFilters.filters),
        name: selectedSavedView.name,
        dataSetId: selectedDataSet.id,
        savedViewId: selectedSavedView.id,
      });
    }
  };

  const handleColumnResized = React.useCallback(
    (event: ColumnResizedEvent) => {
      if (event.source !== 'uiColumnDragged') return;

      const affectedColumnId = event.column?.getColId();
      const originalColumn = dataSetFilters.columns.find(c => affectedColumnId === c.id);

      if (!originalColumn) return;

      dataSetFilters.updateColumnState({
        column: {
          ...originalColumn,
          width: event.column?.getActualWidth(),
        },
      });
    },
    [dataSetFilters],
  );

  const handleSidebarChange = (savedView: SavedView) => {
    if (selectedDataSet) {
      handleDropdownChange({
        type: 'SavedView',
        value: savedView.id,
        label: savedView.name,
        dataSet: selectedDataSet,
      });
    }
  };

  const handleSortChange = React.useCallback(
    (event: SortChangedEvent) => {
      const gridColumns = event.columnApi.getColumns()?.filter(DataSetUtils.isDataColumn);
      if (!gridColumns) return;

      const columnsStateMap = _keyBy(dataSetFilters.columns, 'id');

      const columns = gridColumns
        .map(gridColumn => {
          const columnState: DataSetColumnState | undefined = columnsStateMap[gridColumn.getId()];
          return { gridColumn, columnState };
        })
        .filter(({ columnState }) => Boolean(columnState))
        .map(({ gridColumn, columnState }) => ({
          ...columnState,
          sort: gridColumn.getSort() ?? undefined,
          sortIndex: gridColumn.getSortIndex() ?? undefined,
        }));

      dataSetFilters.setColumnsState({ columns });
    },
    [dataSetFilters],
  );

  // Updates the url query params whenever the dataSetId or savedViewId changes
  useUpdateEffect(() => {
    const { stateName, stateParams } = match({ dataSetId, savedViewId })
      .with({ dataSetId: P.not(P.nullish), savedViewId: P.not(P.nullish) }, ({ dataSetId, savedViewId }) => ({
        stateName: 'dataSets.savedView',
        stateParams: { dataSetId, savedViewId },
      }))
      .with({ dataSetId: P.not(P.nullish), savedViewId: P.nullish }, ({ dataSetId }) => ({
        stateName: 'dataSets.dataSet',
        stateParams: { dataSetId },
      }))
      .otherwise(() => ({
        stateName: 'dataSets',
        stateParams: {},
      }));

    $state.go(stateName, stateParams);
  }, [dataSetId, savedViewId]);

  const hideSavedViewControls = Boolean(selectedSavedView && selectedDataSet?.canAccessDataSet === false);

  const canUpdate = Boolean(selectedSavedView?.canUpdate) || Boolean(selectedDataSet?.canAccessDataSet);

  const [rows, setRows] = React.useState(dataSetRowsQuery.data ?? []);

  React.useEffect(
    function syncRowsOnFiltersChange() {
      setRows(dataSetRowsQuery.data ?? []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update rows on filters/saved view change, or when we actually fetch the query
    [dataSetFilters, dataSetId, savedViewId, dataSetRowsQuery.isFetching],
  );

  return (
    <SelectedDataSetContext.Provider value={selectedDataSetState}>
      <SelectedSavedViewContext.Provider value={selectedSavedViewState}>
        <VStack spacing="0" w="full" alignItems="flex-start">
          <MainTabs />

          <Grid templateColumns="270px 1fr" w="full" minH="calc(100vh - 120px)">
            <DataSetSavedViewsSidebar
              dataSetId={selectedDataSet?.id}
              savedViews={selectedDataSet?.savedViews ?? []}
              isLoading={dataSetsQuery.isLoading}
              onChange={handleSidebarChange}
            />

            {!isDataSetsV2Enabled && !dataSetsQuery.data?.length ? (
              <DataSetPageSkeleton isLoading={dataSetsQuery.isLoading} />
            ) : (
              <VStack w="full" as="main" mt="8" px="8" alignItems="stretch">
                <HStack
                  w="full"
                  justifyContent="space-between"
                  spacing="2"
                  borderBottomWidth="1px"
                  borderBottomStyle="solid"
                  borderBottomColor="gray.200"
                  pb="7"
                >
                  <Box
                    w="full"
                    maxW="480px"
                    sx={{
                      '.blvd-select': {
                        '.blvd-select__control': {
                          'minHeight': 12,
                          '.blvd-select__value-container': { pl: 3 },
                        },
                        '.blvd-select__indicator-separator': { display: 'none' },
                      },
                    }}
                  >
                    <DataSetsDropdown
                      value={dropdownValue}
                      onChange={handleDropdownChange}
                      dataSets={dataSetsQuery.data ?? []}
                    />
                  </Box>

                  <HStack spacing="4">
                    {selectedDataSet && (
                      <UpdateDataSetButton dataSet={selectedDataSet} isDisabled={hideSavedViewControls} />
                    )}
                    {selectedDataSet && (
                      <LinkedWorkflowsButton dataSetId={selectedDataSet.id} savedViewId={selectedSavedView?.id} />
                    )}

                    {selectedDataSet && isWorkdayIntegrationEnabled && (
                      <WebhooksButton dataSet={selectedDataSet} savedViewId={savedViewId} isDisabled={!canUpdate} />
                    )}

                    {selectedDataSet && (
                      <ShareDataSetButtonV1
                        dataSetId={selectedDataSet.id}
                        isDisabled={hideSavedViewControls}
                        savedViewId={selectedSavedView?.id}
                      />
                    )}
                    {selectedDataSet && <ExportToCsvButton dataSet={selectedDataSet} savedView={selectedSavedView} />}
                    <CreateDataSetButton />
                  </HStack>
                </HStack>

                <Box pt="4" pb="2">
                  {selectedDataSet?.columnDefs && (
                    <DataSetFiltersBarV1
                      allColumns={selectedDataSet.columnDefs}
                      onUpdateSavedView={handleUpdateSavedView}
                      isDisabled={hideSavedViewControls}
                    />
                  )}
                </Box>

                <Box w="full">
                  {((dataSetRowsQuery.isLoading && !dataSetsQuery.data) || dataSetsQuery.isLoading) && (
                    <TableLoadingSkeleton />
                  )}

                  {selectedDataSet &&
                    (isDataSetsV2Enabled ? (
                      <DataSetTableWrapper
                        dataSetId={selectedDataSet.id}
                        columnsState={dataSetFilters.columns}
                        columns={selectedDataSet.columnDefs}
                        rows={rows}
                        onColumnResized={handleColumnResized}
                        onSortChanged={handleSortChange}
                      />
                    ) : (
                      <DataSetTableV1Wrapper
                        dataSetId={selectedDataSet.id}
                        columnsState={dataSetFilters.columns}
                        columns={selectedDataSet.columnDefs}
                        rows={dataSetRowsQuery.data ?? []}
                        onColumnResized={handleColumnResized}
                        onSortChanged={handleSortChange}
                      />
                    ))}
                </Box>
              </VStack>
            )}
          </Grid>
        </VStack>
      </SelectedSavedViewContext.Provider>
    </SelectedDataSetContext.Provider>
  );
};

// When a new data set is added, we automatically select the last
// data set in the list sorted by created date.
export const handleNewDataSetAdded = (dataSets: DataSet[]) => {
  const dataSetsSortedByCreatedDate = dataSets.sort((d1, d2) => d1.audit.createdDate - d2.audit.createdDate);
  const dataSet = dataSetsSortedByCreatedDate[dataSets.length - 1];
  const savedView = null;

  const columns = dataSet?.columnDefs.map(DataSetColumnStateModel.fromColumnDef);
  const filters: DataSetColumnFilterForm[] = DataSetColumnStateModel.getFilters(columns);

  return {
    dataSet,
    savedView,
    columns,
    filters,
  };
};

export const handleDataSetsUpdate = (
  dataSets: DataSet[],
  selectedDataSet: DataSet | null,
  selectedSavedView: SavedView | null,
) => {
  const option = Option(selectedDataSet)
    .map(selectedDataSet => dataSets.find(d => d.id === selectedDataSet.id))
    .map(updatedDataSet => {
      const savedViews = updatedDataSet.savedViews ?? [];
      const { columnDefs } = updatedDataSet;
      const hasNewSavedViews = selectedDataSet ? savedViews.length > selectedDataSet.savedViews.length : false;
      const haveColumnsChanged = selectedDataSet ? columnDefs.length !== selectedDataSet.columnDefs.length : false;
      const selectedSavedViewFromList = savedViews.find(sv => sv.id === selectedSavedView?.id);
      const isSavedViewDeleted = !selectedSavedViewFromList && Boolean(selectedSavedView);
      const dataSetColumns = updatedDataSet.columnDefs.map(DataSetColumnStateModel.fromColumnDef);

      const { savedView, columns, filters } = match({ hasNewSavedViews, isSavedViewDeleted, haveColumnsChanged })
        .with({ hasNewSavedViews: true }, () => {
          const savedView = savedViews[savedViews.length - 1] ?? null;
          const columns = savedView?.columns ?? dataSetColumns;

          return {
            savedView,
            columns,
            filters: DataSetColumnStateModel.getFilters(columns),
          };
        })
        .with({ isSavedViewDeleted: true }, () => {
          const savedView = savedViews[0] ?? null;
          const columns = savedView?.columns ?? dataSetColumns;
          return {
            savedView,
            columns,
            filters: DataSetColumnStateModel.getFilters(columns),
          };
        })
        .with({ haveColumnsChanged: true }, () => {
          const savedView = selectedSavedViewFromList ?? null;
          const columns = dataSetColumns;
          return {
            savedView,
            columns,
            filters: DataSetColumnStateModel.getFilters(columns),
          };
        })
        .otherwise(() => ({
          savedView: selectedSavedViewFromList ?? null,
          columns: null,
          filters: null,
        }));

      return {
        dataSet: updatedDataSet,
        savedView,
        columns,
        filters,
      };
    })
    .get();

  if (option) {
    return option;
  } else {
    const dataSet = DataSetPageHelpers.findFirstAccessibleDataSet(dataSets);

    return {
      dataSet,
      savedView: null,
      columns: dataSet?.columnDefs.map(DataSetColumnStateModel.fromColumnDef),
      filters: [],
    };
  }
};

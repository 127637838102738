import uiRouter, { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import angular from 'angular';
import { ApprovalTaskActionMenu } from 'components/approvals/action-menu/action-menu.component';
import { ApprovalSubjectTaskModalController } from 'components/approvals/action-menu/approval-modal/approval-modal.component';
import { ApprovalsApiImpl } from 'components/approvals/api/approval.api';
import { Approvals } from 'components/approvals/api/approvals.resource';
import { TaskApprovalButton } from 'components/approvals/components/TaskApprovalButton';
import { TaskApprovalInfoReactWrapper } from 'components/approvals/components/TaskApprovalInfoReactWrapper';
import { ApprovalsContainer } from 'components/approvals/container/container.component';
import { ApprovalSubjectTasksGroup } from 'components/approvals/container/group/group.component';
import { ApprovalSubjectTasksListItem } from 'components/approvals/container/group/list/item/item.component';
import { ApprovalSubjectTasksList } from 'components/approvals/container/group/list/list.component';
import { EmailInitiatedApproval } from 'components/approvals/email-initiated/email-initiated.component';
import { EmailInitiatedApprovalContainerWrapper } from 'components/approvals/email-initiated/EmailInitiatedApprovalContainerWrapper';
import { ApprovalSubjectTaskRejectComment } from 'components/approvals/reject-comment/reject-comment.component';
import { ApprovalActionsImpl } from 'components/approvals/store/approval.actions';
import { ApprovalSubjectTaskWidgetDateRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/date-renderer.component';
import { ApprovalSubjectTaskWidgetEmailRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/email-renderer.component';
import { ApprovalSubjectTaskWidgetFileRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/file-renderer.component';
import { ApprovalSubjectTaskWidgetMembersRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/members-renderer.component';
import { ApprovalSubjectTaskWidgetMultiChoiceRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/multi-choice-renderer.component';
import { ApprovalSubjectTaskWidgetMultiSelectRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/multi-select-renderer.component';
import { ApprovalSubjectTaskWidgetTextRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/text-renderer.component';
import { ApprovalSubjectTaskWidgetUrlRenderer } from 'components/approvals/widgets-container/widget-renderer/value-renderers/url-renderer.component';
import { ApprovalSubjectTaskWidgetRenderer } from 'components/approvals/widgets-container/widget-renderer/widget-renderer.component';
import { ApprovalSubjectTaskWidgetsContainer } from 'components/approvals/widgets-container/widgets-container.component';
import { reactToAngular } from 'components/react-root';
import { ApprovalSubjectTaskApproveComment } from './approve-comment/approve-comment.component';

export const ApprovalsModule = angular
  .module('approvals', [uiRouter])
  .factory('Approvals', ['ResourceFactory', Approvals])
  .service('ApprovalsApi', ApprovalsApiImpl)
  .factory('ApprovalActions', ApprovalActionsImpl)
  .component('psApprovalsContainer', ApprovalsContainer)
  .component('psApprovalSubjectTasksGroup', ApprovalSubjectTasksGroup)
  .component('psApprovalSubjectTasksList', ApprovalSubjectTasksList)
  .component('psApprovalSubjectTasksListItem', ApprovalSubjectTasksListItem)
  .component('psApprovalTaskActionMenu', ApprovalTaskActionMenu)
  .component('psApprovalSubjectTaskRejectComment', ApprovalSubjectTaskRejectComment)
  .component('psApprovalSubjectTaskApproveComment', ApprovalSubjectTaskApproveComment)
  .controller('ApprovalSubjectTaskModalController', ApprovalSubjectTaskModalController)
  .component('psApprovalSubjectTaskWidgetsContainer', ApprovalSubjectTaskWidgetsContainer)
  .component('psApprovalSubjectTaskWidgetRenderer', ApprovalSubjectTaskWidgetRenderer)
  .component('psTaskApprovalInfo', reactToAngular(TaskApprovalInfoReactWrapper, ['taskId']))
  .component(
    'psTaskApprovalButton',
    reactToAngular(TaskApprovalButton, ['admin', 'featureAvailable', 'onAddClick', 'onUpgradeClick']),
  )
  // Email Initiated Approval
  .component('psEmailInitiatedApproval', EmailInitiatedApproval)
  .component(
    'psEmailInitiatedApprovalContainer',
    reactToAngular(EmailInitiatedApprovalContainerWrapper, [
      'taskId',
      'action',
      'token',
      'tokenPayload',
      'onLoginClicked',
    ]),
  )
  // Value Renderers
  .component('psApprovalSubjectTaskWidgetTextRenderer', ApprovalSubjectTaskWidgetTextRenderer)
  .component('psApprovalSubjectTaskWidgetEmailRenderer', ApprovalSubjectTaskWidgetEmailRenderer)
  .component('psApprovalSubjectTaskWidgetUrlRenderer', ApprovalSubjectTaskWidgetUrlRenderer)
  .component('psApprovalSubjectTaskWidgetDateRenderer', ApprovalSubjectTaskWidgetDateRenderer)
  .component('psApprovalSubjectTaskWidgetFileRenderer', ApprovalSubjectTaskWidgetFileRenderer)
  .component('psApprovalSubjectTaskWidgetMultiChoiceRenderer', ApprovalSubjectTaskWidgetMultiChoiceRenderer)
  .component('psApprovalSubjectTaskWidgetMultiSelectRenderer', ApprovalSubjectTaskWidgetMultiSelectRenderer)
  .component('psApprovalSubjectTaskWidgetMembersRenderer', ApprovalSubjectTaskWidgetMembersRenderer)
  // Routes
  .config(($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider) => {
    'ngInject';

    $stateProvider.state('emailInitiatedApproval', {
      parent: 'appLight',
      component: 'psEmailInitiatedApproval',
      data: {
        external: true,
        internal: true,
      },
      url: '/checklists/:checklistId/tasks/:taskId/:action/:token',
    });
    $urlRouterProvider.otherwise('/');
  }).name;

import { Option } from '@process-street/subgrade/core';
import { OrderTree, Task, TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { TaskStats } from '@process-street/subgrade/process/task-stats-model';
import { TaskMap, TaskStatsMap } from './types';
import { orderTreeService } from '../order-tree/order-tree.service.impl';
import { TaskStatsService } from './task-stats.service';

export class TaskStatsServiceImpl implements TaskStatsService {
  public isChecklistStoppedByNotPermittedTask(tasks: Task[], taskStatsMap: TaskStatsMap): boolean {
    const taskMapById = this.convertTasksToTaskMapById(tasks);

    const invisibleTaskStats = Object.values(taskStatsMap).filter((stats: TaskStats) => !taskMapById[stats.taskId]);

    return invisibleTaskStats.some(
      stats =>
        this.hasInvalidFields(stats) || this.isNotCompletedStopTask(stats) || this.isNotCompletedApprovalTask(stats),
    );
  }

  public doesChecklistHaveNotPermittedNotCompletedStopTasks(tasks: Task[], taskStatsMap: TaskStatsMap): boolean {
    const taskMapById = this.convertTasksToTaskMapById(tasks);

    const invisibleTaskStats = Object.values(taskStatsMap).filter((stats: TaskStats) => !taskMapById[stats.taskId]);

    return invisibleTaskStats.some((stats: TaskStats) => {
      return this.isNotCompletedTask(stats);
    });
  }

  public isTaskStoppedByNotPermittedTask(task: Task, tasks: Task[], taskStatsMap: TaskStatsMap): boolean {
    const taskMapById = this.convertTasksToTaskMapById(tasks);

    const taskTemplate = task.taskTemplate as TaskTemplate;
    const currentTaskOrderTree = taskTemplate.orderTree;
    const invisibleTaskStatsBeforeCurrentTask = this.filterInvisibleTaskStatsBeforeCurrentTask(
      currentTaskOrderTree,
      taskMapById,
      taskStatsMap,
    );

    if (taskTemplate.stop) {
      return invisibleTaskStatsBeforeCurrentTask.some(
        (stats: TaskStats) => this.hasInvalidFields(stats) || this.isNotCompletedStopTask(stats),
      );
    }

    return invisibleTaskStatsBeforeCurrentTask.some((stats: TaskStats) => this.isNotCompletedStopTask(stats));
  }

  public getStatsOfFirstNotPermittedNotCompletedStopTask(tasks: Task[], taskStatsMap: TaskStatsMap): Option<TaskStats> {
    const taskMapById = this.convertTasksToTaskMapById(tasks);
    let firstTask: Option<TaskStats>;

    Object.values(taskStatsMap).forEach((stats: TaskStats) => {
      const taskIsInvisible = !taskMapById[stats.taskId];
      if (taskIsInvisible && this.isNotCompletedStopTask(stats)) {
        if (!firstTask || orderTreeService.compare(firstTask.orderTree, stats.orderTree) > 0) {
          firstTask = stats;
        }
      }
    });

    return firstTask;
  }

  private convertTasksToTaskMapById(tasks: Task[]): TaskMap {
    const taskMapById = tasks.reduce((agg: TaskMap, task: Task) => {
      agg[task.id] = task;
      return agg;
    }, {});

    return taskMapById;
  }

  private filterInvisibleTaskStatsBeforeCurrentTask(
    currentTaskOrderTree: OrderTree,
    taskMapById: TaskMap,
    taskStatsMap: TaskStatsMap,
  ): TaskStats[] {
    return Object.values(taskStatsMap).filter((stats: TaskStats) => {
      const taskIsBefore = orderTreeService.compare(stats.orderTree, currentTaskOrderTree) < 0;
      const taskIsInvisible = !taskMapById[stats.taskId];
      return taskIsBefore && taskIsInvisible;
    });
  }

  private hasInvalidFields = (stats: TaskStats): boolean => !stats.hidden && stats.invalidFieldsCount > 0;

  private isNotCompletedTask = (stats: TaskStats): boolean => !stats.completed && !stats.hidden;

  private isNotCompletedStopTask = (stats: TaskStats): boolean => stats.stop && this.isNotCompletedTask(stats);

  private isNotCompletedApprovalTask = (stats: TaskStats): boolean =>
    stats.taskType === TaskTemplateTaskType.Approval && this.isNotCompletedTask(stats);
}

export const taskStatsService = new TaskStatsServiceImpl();

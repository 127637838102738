import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  TooltipWithTouch,
  useBoolean,
  useDisclosure,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';
import { CaptionProps, DayPicker, DayPickerProps, Matcher, useInput } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { DateFormat, dayjs } from '@process-street/subgrade/util';
import { useUpdateEffect } from 'react-use';
import { MonthPicker } from '../month-picker';
import { CalendarMode } from 'features/conditional-logic/utils/conditional-logic-utils';
import { YearPicker } from '../year-picker/component';
import { DatePickerContext } from './context';
import { DayPickerCaption } from '../day-picker-caption';
import { DatePickerHelpers } from './component.helpers';

export type DatePickerProps = {
  value?: number | null;
  onChange?: (date: number | null) => void;
  isDisabled?: boolean;
  timeZone?: string;
  placeholder?: string;
  icon?: JSX.Element;
  renderTrigger?: (value: number | undefined | null, timeZone: string) => React.ReactElement;
  renderContent?: (defaultContent: React.ReactElement) => React.ReactElement;
  pickerProps?: Partial<Pick<DayPickerProps, 'disabled' | 'styles'>>;
  popoverProps?: Partial<PopoverProps>;
  hideTime?: boolean;
  showWeekdayToggle?: boolean;
  title?: JSX.Element | string;
  PopoverContentWrapper?: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<{}>>>;
};

const DEFAULT_TIME = '8:00am';
const DEFAULT_TIMEZONE = 'UTC';

const weekendDisableConfig: Matcher = {
  dayOfWeek: [0, 6],
};

export const DatePicker: React.FC<React.PropsWithChildren<DatePickerProps>> = ({
  value,
  onChange,
  isDisabled,
  timeZone = DEFAULT_TIMEZONE,
  placeholder,
  icon,
  pickerProps,
  popoverProps,
  renderTrigger,
  renderContent,
  hideTime = false,
  showWeekdayToggle = false,
  title,
  PopoverContentWrapper = 'div',
}) => {
  const popoverDisclosure = useDisclosure();
  const initialDate = DatePickerHelpers.getInitialValue(value, timeZone);
  const [showWeekdaysOnly, setShowWeekdaysOnly] = useBoolean();

  const { inputProps, dayPickerProps, setSelected } = useInput({
    defaultSelected: initialDate,
    format: 'MM/dd/yyyy',
    required: true,
  });

  const dateInputRef = React.useRef<HTMLInputElement>(null);
  const timeInputRef = React.useRef<HTMLInputElement>(null);

  const [currentMonth, setCurrentMonth] = React.useState<Date>(() => {
    return dayjs(initialDate ?? new Date())
      .startOf('month')
      .toDate();
  });

  const [mode, setMode] = React.useState<CalendarMode>('day');
  const [time, setTime] = React.useState<string>(() => {
    if (!value) return DEFAULT_TIME;

    const time = dayjs(value).tz(timeZone).format('h:mmA');

    return time;
  });

  const [hasTimeError, setHasTimeError] = React.useState<boolean>(false);

  const providerValue = React.useMemo(
    () => ({
      setMode,
      mode,
    }),
    [setMode, mode],
  );

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    updateDateAndTime(value);
  };

  const handleSelectDate = (day: Date | undefined) => {
    setSelected(day);
  };

  const handleMonthChange = (date: Date) => {
    setCurrentMonth(date);
    setMode('day');
  };

  const handleSelectYear = (date: Date) => {
    setCurrentMonth(date);
    setMode('month');
  };

  const handleRangeChange = (date: Date) => {
    setCurrentMonth(date);
  };

  const handleTodayClick = () => {
    setSelected(dayjs().startOf('day').toDate());
    setCurrentMonth(dayjs().startOf('month').toDate());
  };

  const handleSave = () => {
    if (!inputProps.value || typeof inputProps.value !== 'string') return;

    const datePart = inputProps.value;
    const timePart = time;

    if (!dayjs(datePart, 'MM/DD/YYYY').isValid()) {
      setHasTimeError(true);
      return;
    }

    const loweredTimePart = timePart.toLowerCase();
    let timeFormat;

    if (loweredTimePart.includes('am') || loweredTimePart.includes('pm')) {
      timeFormat = 'h:mma';
    } else {
      timeFormat = 'HH:mm';
    }

    if (!dayjs(loweredTimePart, timeFormat).isValid()) {
      setHasTimeError(true);
      return;
    }

    const dateStr = `${datePart} ${loweredTimePart}`;
    const format = `MM/DD/YYYY ${timeFormat}`;
    const dateTimeDayJS = dayjs.tz(dateStr, format, timeZone);

    onChange?.(dateTimeDayJS.valueOf());
    popoverDisclosure.onClose();
  };

  const handleRemove = () => {
    onChange?.(null);
    setSelected(undefined);
    popoverDisclosure.onClose();
  };

  const updateDateAndTime = (time?: string) => {
    setTime(time ?? '08:00');

    if (!time && dayPickerProps.selected) {
      const newDate = dayjs(dayPickerProps.selected).startOf('day').toDate();

      setSelected(newDate);

      return;
    }

    if (time && DatePickerHelpers.isTimeStringValid(time)) {
      const date = dayjs(`${inputProps.value} ${time}`, 'MM/DD/YYYY h:mmA');

      if (!date.isValid()) {
        setHasTimeError(true);

        return;
      }

      setHasTimeError(false);
      setSelected(date.toDate());
    } else {
      setHasTimeError(true);
    }
  };

  // Sync the external value with internal value
  useUpdateEffect(() => {
    if (value !== dayPickerProps.selected?.getTime()) setSelected(value ? new Date(value) : undefined);
  }, [value]);

  React.useEffect(() => {
    if (popoverDisclosure.isOpen) {
      requestAnimationFrame(() => {
        // Set the focus into the date input when the popover opens
        dateInputRef.current?.focus();
      });

      dayPickerProps.selected && setCurrentMonth(dayPickerProps.selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- act on popover open
  }, [popoverDisclosure.isOpen]);

  const formattedDate = value ? dayjs(value).tz(timeZone).format(DateFormat.DateMonthDayYearAtTime) : undefined;

  const pickerComponentProps = showWeekdaysOnly
    ? {
        ...pickerProps,
        disabled: [...((pickerProps?.disabled as Matcher[]) || []), weekendDisableConfig],
      }
    : pickerProps;

  const isDateOnWeekend = React.useMemo(() => {
    if (!inputProps.value || typeof inputProps.value !== 'string') return false;
    const day = dayjs(inputProps.value, 'MM/DD/YYYY').day();
    return day === 0 || day === 6;
  }, [inputProps.value]);

  const disableSave = showWeekdaysOnly && isDateOnWeekend;

  const disabledReason = match({ disableSave })
    .with({ disableSave: true }, () => 'Weekends are disabled')
    .otherwise(() => null);

  const content = (
    <VStack pt="3" pb="4" px="2" w="full">
      {title && title}
      {showWeekdayToggle && (
        <FormControl as={HStack} fontSize="md" mb={4} p={2}>
          <Checkbox
            size="lg"
            onChange={setShowWeekdaysOnly.toggle}
            defaultChecked={showWeekdaysOnly}
            sx={{
              '.chakra-checkbox__control': {
                borderRadius: 'sm',
                borderWidth: '1px',
              },
            }}
          />
          <FormLabel fontSize="md" mb="1">
            Weekdays only
          </FormLabel>
        </FormControl>
      )}
      <HStack p="2" pt="0" spacing="2" w="full">
        <FormControl w="full">
          <FormLabel fontSize="sm" mb="1">
            Date
          </FormLabel>
          <Input {...inputProps} ref={dateInputRef} px="2" placeholder="MM/DD/YYYY" />
        </FormControl>

        {!hideTime && (
          <FormControl w="full">
            <FormLabel fontSize="sm" mb="1">
              Time
            </FormLabel>
            <Input
              autoFocus
              ref={timeInputRef}
              isInvalid={hasTimeError}
              value={time ?? ''}
              onChange={handleTimeChange}
              placeholder="Time"
              px="2"
            />
          </FormControl>
        )}
      </HStack>

      <Box w="full" px={2}>
        <Button mb={2} variant="solid" colorScheme="gray" w="full" onClick={handleTodayClick}>
          Today
        </Button>
      </Box>

      {match(mode)
        .with('day', () => (
          <DayPicker
            {...pickerComponentProps}
            {...dayPickerProps}
            onDayClick={handleSelectDate}
            onMonthChange={setCurrentMonth}
            month={currentMonth}
            mode="single"
            captionLayout="dropdown"
            components={{
              Caption: (props: CaptionProps): JSX.Element => <DayPickerCaption {...props} />,
            }}
          />
        ))
        .with('month', () => (
          <MonthPicker
            year={currentMonth.getFullYear()}
            selected={dayPickerProps.selected}
            onChange={handleMonthChange}
            onRangeChange={handleRangeChange}
            onModeChange={setMode}
          />
        ))
        .with('year', () => (
          <YearPicker
            year={currentMonth.getFullYear()}
            selected={dayPickerProps.selected}
            onSelectYear={handleSelectYear}
            onRangeChange={handleRangeChange}
          />
        ))
        .otherwise(() => null)}

      <HStack mt="2" w="full">
        <TooltipWithTouch label={disabledReason} placement="top" boxProps={{ w: 'full' }} hasArrow>
          <Button w="full" colorScheme="brand" onClick={handleSave} isDisabled={disableSave}>
            Save
          </Button>
        </TooltipWithTouch>

        <Button w="full" variant="outline" colorScheme="red" onClick={handleRemove}>
          Remove
        </Button>
      </HStack>
    </VStack>
  );

  return (
    <DatePickerContext.Provider value={providerValue}>
      <Popover placement="top-start" isLazy {...popoverDisclosure} {...popoverProps}>
        <PopoverTrigger>
          {renderTrigger ? (
            React.cloneElement(renderTrigger(value, timeZone), {
              disabled: isDisabled,
            })
          ) : (
            <Button
              variant="ghost"
              w="full"
              h="10"
              bgColor="white"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="gray.300"
              borderRadius="base"
              isDisabled={isDisabled}
              justifyContent="flex-start"
              fontWeight="normal"
              fontSize="sm"
              data-testid="date-picker-trigger"
              leftIcon={icon}
            >
              {value ? formattedDate : placeholder ?? 'Select a date'}
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContentWrapper>
          <PopoverContent
            sx={{
              'width': '336px',
              ".rdp-day_selected:not([aria-disabled='true']), .rdp-day_selected:focus:not([aria-disabled='true']), .rdp-day_selected:active:not([aria-disabled='true']), .rdp-day_selected:hover:not([aria-disabled='true'])":
                {
                  'bgColor': 'brand.300',
                  'color': 'black',

                  '&:active, &:focus': {
                    borderColor: 'brand.500',
                  },
                },
            }}
          >
            {renderContent ? renderContent(content) : content}
          </PopoverContent>
        </PopoverContentWrapper>
      </Popover>
    </DatePickerContext.Provider>
  );
};

import { MultiSelectFieldValue, MultiSelectItemValueStatus } from '@process-street/subgrade/process';
import * as yup from 'yup';

const selectFormFieldConfigSchema = yup.object({
  items: yup.array().of(
    yup
      .object()
      .shape<MultiSelectFieldValue>({
        itemType: yup.string().oneOf(['Static']).required().default('Static'),
        id: yup.string().required(),
        name: yup.string().trim(),
        status: yup.string().oneOf(Object.values(MultiSelectItemValueStatus)).required(),
      })
      .required(),
  ),
});

export const subtasksSchema = yup.object({
  config: selectFormFieldConfigSchema.required(),
});

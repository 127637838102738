import { useFeatureFlag } from 'app/features/feature-flags';
import { Icon, Text, VStack } from 'components/design/next';
import { UniversalNewMenu } from 'features/new-menu/component';
import * as React from 'react';
import { TasksTableEmptyStateNewButton } from './tasks-table-empty-state-new-button';

export const TasksTableEmptyState = () => {
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');
  const confettiTitle = isMyWorkGAEnabled ? 'Congratulations, all clear!' : 'All clear!';
  return (
    <VStack spacing={3}>
      <Icon
        variant="fad"
        icon="party-horn"
        size="14"
        mt={15}
        primaryLayer={{ color: 'blue.300', opacity: 1 }}
        secondaryLayer={{ color: 'blue.200', opacity: 1 }}
      />
      <VStack color="gray.500">
        <Text fontSize="xl">{confettiTitle}</Text>
        <Text>What do you want to do next?</Text>
      </VStack>

      {isMyWorkGAEnabled ? (
        <TasksTableEmptyStateNewButton />
      ) : (
        <UniversalNewMenu
          shouldHideRunWorkflow
          shouldHideFolder
          size="sm"
          variant="outline"
          w="auto"
          color="gray.600"
          borderColor="gray.300"
          borderWidth="1px"
          leftIcon={<Icon icon="plus" size="3" />}
        />
      )}
    </VStack>
  );
};

import { Muid } from '@process-street/subgrade/core';
import { MergeTagTarget } from '@process-street/subgrade/form';
import { FormFieldKeysContext } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { GetWidgetsByChecklistRevisionIdQuery } from 'features/widgets/query-builder';
import { QueryClient, QueryFunctionContext, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { MergeTagsServiceUtils, Tags } from 'services/merge-tags';
import { WidgetServiceUtils } from 'services/widget-service.utils';
import { queryClient as defaultQueryClient } from 'components/react-root';
import { GetDataSetMergeTagsByChecklistRevisionQuery } from 'pages/reports/data-sets/query-builder/get-data-set-merge-tags';

export type MergeTagsByChecklistRevisionIdQueryParams = {
  checklistRevisionId?: Muid;
  context: FormFieldKeysContext;
  mergeTagTarget: MergeTagTarget;
};

export type MergeTagsByChecklistRevisionIdQueryResponse<Target extends MergeTagTarget = MergeTagTarget> = Tags<Target>;

const keyFactory = {
  key: [{ static: ['merge-tags', 'checklist-revision-id'] }],
  getKey: (params: MergeTagsByChecklistRevisionIdQueryParams) => [{ ...keyFactory.key[0], ...params }],
};
type QueryKey = ReturnType<typeof keyFactory['getKey']>;

export const MergeTagsByChecklistRevisionIdQuery = {
  ...keyFactory,
  /**
   * This is somewhat of an experimental query. We're passing the query client to leverage the widgets request cache,
   * then caching the result of the widget and merge tag service transformations.
   * An alternative would be to just use the widgets query in angular and react, and use domain-specific strategies
   * for memoizing and/or caching there, but this is a nice way to make sure both "worlds" are leveraging the same
   * cache.
   */
  queryFn:
    (queryClient = defaultQueryClient) =>
    async <
      Context extends QueryFunctionContext<QueryKey>,
      Target extends MergeTagTarget = Context extends [{ queryKey: { mergeTagTarget: infer T } }] ? T : MergeTagTarget,
    >({
      queryKey: [{ checklistRevisionId, context, mergeTagTarget }],
    }: Context): Promise<MergeTagsByChecklistRevisionIdQueryResponse<Target>> => {
      const widgetsP = queryClient.fetchQuery(
        GetWidgetsByChecklistRevisionIdQuery.getKey({ checklistRevisionId }),
        () => GetWidgetsByChecklistRevisionIdQuery.queryFn({ checklistRevisionId }),
      );
      const dataSetMergeTagsResultP = queryClient.fetchQuery(
        GetDataSetMergeTagsByChecklistRevisionQuery.getKey({ checklistRevisionId }),
        () => GetDataSetMergeTagsByChecklistRevisionQuery.queryFn({ checklistRevisionId }),
      );

      const [widgets, dataSetMergeTags] = await Promise.all([widgetsP, dataSetMergeTagsResultP]);
      const keys = WidgetServiceUtils.getFormFieldWidgetKeyMap(widgets, context);
      const tags = MergeTagsServiceUtils.getTagsFromKeys(keys, mergeTagTarget as Target, {
        includeLegacyTags: true,
        includeInitialTags: true,
      });
      const dataSetTags = MergeTagsServiceUtils.getTagLabelsFromDataSetMergeTags(dataSetMergeTags);

      return {
        ...tags,
        ...dataSetTags,
      };
    },
  invalidate: async (queryClient: QueryClient, params?: MergeTagsByChecklistRevisionIdQueryParams) => {
    if (!params) {
      return Promise.all([
        queryClient.invalidateQueries(GetWidgetsByChecklistRevisionIdQuery.key),
        queryClient.invalidateQueries(GetDataSetMergeTagsByChecklistRevisionQuery.key),
      ]);
    } else {
      const { checklistRevisionId } = params;
      return Promise.all([
        queryClient.invalidateQueries(GetWidgetsByChecklistRevisionIdQuery.getKey({ checklistRevisionId })),
        queryClient.invalidateQueries(GetDataSetMergeTagsByChecklistRevisionQuery.getKey({ checklistRevisionId })),
      ]);
    }
  },
};

export const useMergeTagsByChecklistRevisionIdQuery = <
  Params extends MergeTagsByChecklistRevisionIdQueryParams,
  Target extends MergeTagTarget = Params extends { mergeTagTarget: infer T } ? T : MergeTagTarget,
  Select = MergeTagsByChecklistRevisionIdQueryResponse<Target>,
>(
  params: MergeTagsByChecklistRevisionIdQueryParams,
  options: UseQueryOptions<MergeTagsByChecklistRevisionIdQueryResponse, AxiosError, Select, QueryKey> = {},
) => {
  const queryClient = useQueryClient();
  return useQuery(
    MergeTagsByChecklistRevisionIdQuery.getKey(params),
    MergeTagsByChecklistRevisionIdQuery.queryFn(queryClient),
    {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    },
  );
};

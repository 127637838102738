import { Muid } from '@process-street/subgrade/core';
import {} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type DeleteTaskTemplatesMutationParams = {
  taskTemplatesIds: Muid[];
};

export type DeleteTaskTemplatesMutationResponse = {};

export const DeleteTaskTemplatesMutation = {
  key: ['post', 'delete-task-templates'],
  getKey() {
    return [...DeleteTaskTemplatesMutation.key];
  },
  mutationFn: (params: DeleteTaskTemplatesMutationParams) =>
    axiosService
      .getAxios()
      .post<DeleteTaskTemplatesMutationResponse>(`/1/task-templates/delete`, params)
      .then(res => res.data),
};

export const useDeleteTaskTemplatesMutation = (
  options: UseMutationOptions<DeleteTaskTemplatesMutationResponse, AxiosError, DeleteTaskTemplatesMutationParams> = {},
) => {
  return useMutation(DeleteTaskTemplatesMutation.mutationFn, options);
};

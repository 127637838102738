import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from 'components/design/next';
import { ColumnOptionType, DataSetColumnsVisibilityFilter } from '../columns-visibility-filter';
import {
  DataSetColumnDef,
  DataSetColumnState,
  DataSetColumnStateModel,
  SavedView,
  SavedViewType,
} from '@process-street/subgrade/process';
import { useDataSetFilters } from '../../store';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { CreateSavedViewButton } from '../create-saved-view-button';
import { DataSetColumnsFilter } from '../columns-filter';
import { DataSetSortButton } from '../sort-button/sort-button';
import { DataSetUtils } from 'pages/reports/data-sets/components/data-set-page/data-set-utils';
import { useSelectedSavedView } from 'pages/reports/data-sets/components/data-set-page/selected-saved-view-id-store';
import { NoAccessTooltip } from 'pages/reports/data-sets/components/data-set-page/no-access-tooltip';
import { useFeatureFlag } from 'features/feature-flags';
import { UpdateSavedViewFiltersDialog } from './update-saved-view-filters-dialog';
import { useGetSavedViewLinksQuery } from '../../query-builder/get-saved-view-links';
import { DataSetQuickFilter } from './data-set-quick-filter';

export type DataSetFiltersBarProps = {
  allColumns: DataSetColumnDef[];
  onUpdateSavedView: () => void;
  onUpdateQuickFilter?: (filter: string) => void;
  isDisabled: boolean;
  isLoading?: boolean;
  onSavedViewCreated?: (savedView: SavedView) => void;
};

export const DataSetFiltersBar: React.FC<React.PropsWithChildren<DataSetFiltersBarProps>> = ({
  allColumns,
  onUpdateSavedView,
  isDisabled,
  isLoading,
  onSavedViewCreated,
  onUpdateQuickFilter,
}) => {
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const dataSetFilters = useDataSetFilters();
  const { selectedDataSet, selectedSavedView } = useSelectedSavedView();
  const updateSavedViewFiltersDialogDisclosure = useDisclosure();

  const selectedColumns = dataSetFilters.columns.filter(column => !column.hide);
  const { hasUnsavedChanges, shouldShowWarning } = selectedSavedView
    ? DataSetUtils.hasUnsavedChanges(selectedSavedView, dataSetFilters)
    : { hasUnsavedChanges: false, shouldShowWarning: false };

  const selectedOptions: ColumnOptionType[] = selectedColumns
    .map(column => ({
      value: column.id,
      label: allColumns.find(c => c.id === column.id)?.name ?? '',
      column: allColumns.find(c => c.id === column.id),
    }))
    // column setting in saved view might have been deleted in data set - remove selection
    .filter((option): option is ColumnOptionType => Boolean(option.column));

  const columnsWithFilters: DataSetColumnState[] = DataSetColumnStateModel.combineColumnsWithFilters(
    dataSetFilters.columns,
    dataSetFilters.filters,
  );

  const updateColumnsState = (selectedColumnsDef: ColumnOptionType[]) => {
    const newColumnsStates: DataSetColumnState[] = allColumns.map(columnOption => {
      const isSelected = selectedColumnsDef.some(c => c.column?.id === columnOption.id);
      const columnState = dataSetFilters.columns.find(c => c.id === columnOption.id);

      if (!columnState) {
        return {
          id: columnOption.id,
          hide: !isSelected,
          filters: [],
        };
      }

      return {
        ...columnState,
        hide: !isSelected,
      };
    });

    dataSetFilters.setColumnsState({ columns: newColumnsStates });
  };

  const savedViewLinks = useGetSavedViewLinksQuery(
    { dataSetId: selectedDataSet?.id, savedViewId: selectedSavedView?.id },
    {
      enabled: Boolean(selectedDataSet) && Boolean(selectedSavedView),
    },
  );

  const sortColumnState = dataSetFilters.columns.find(column => column.sort);
  const sortColumnDef = selectedDataSet?.columnDefs.find(c => c.id === sortColumnState?.id);
  const isUpdateDisabled = !hasUnsavedChanges || !selectedSavedView || isDisabled || isLoading;

  const updateCreateButtonProps: Partial<ButtonProps> = {
    h: '8',
    variant: 'solid',
    colorScheme: 'gray',
    fontSize: 'sm',
    px: '4',
    bgColor: 'gray.200',
    color: 'gray.700',
    _hover: { bgColor: 'gray.300' },
    leftIcon: undefined,
  };

  const canCreateSavedView = Boolean(selectedDataSet?.canAccessDataSet);

  const onUpdateSavedViewInternal = () => {
    if (isDataSetsV2Enabled && (savedViewLinks.data?.length ?? 0 > 0)) {
      updateSavedViewFiltersDialogDisclosure.onOpen();
    } else {
      onUpdateSavedView();
    }
  };

  const isAllDataTab = selectedSavedView && selectedSavedView.savedViewType === SavedViewType.AllData;

  return (
    <>
      <Stack direction={{ base: 'column', xl: 'row' }} spacing="4">
        {onUpdateQuickFilter && <DataSetQuickFilter onChange={onUpdateQuickFilter} />}

        <Stack spacing="4" direction={{ base: 'column', sm: 'row' }}>
          {sortColumnState && sortColumnDef && (
            <DataSetSortButton columnState={sortColumnState} columnDef={sortColumnDef} />
          )}

          {selectedDataSet && (
            <NoAccessTooltip
              label="You don't have access to edit this saved view."
              dataSet={selectedDataSet}
              savedView={selectedSavedView}
              isDisabled={!isDisabled}
              contactAdmin={isDisabled}
            >
              <HStack spacing="4">
                <DataSetColumnsFilter isDisabled={isDisabled} />

                <DataSetColumnsVisibilityFilter
                  onChange={columns => {
                    if (BlvdSelectHelpers.isOptionsType(columns)) {
                      updateColumnsState(columns.map(c => c));
                    }
                  }}
                  columns={allColumns}
                  selected={selectedOptions}
                  isDisabled={isDisabled}
                />

                {selectedDataSet && selectedSavedView && selectedSavedView.savedViewType === SavedViewType.Standard && (
                  <ButtonGroup size="sm" isAttached variant="outline" isDisabled={isDisabled}>
                    <Button
                      onClick={onUpdateSavedViewInternal}
                      isDisabled={isUpdateDisabled}
                      {...updateCreateButtonProps}
                      pr={2}
                    >
                      Save
                    </Button>
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            as={IconButton}
                            isActive={isOpen}
                            variant="solid"
                            colorScheme="gray"
                            bgColor="gray.200"
                            color="gray.700"
                            opacity={isUpdateDisabled ? 0.4 : 1}
                            _hover={{ bgColor: 'gray.300' }}
                            h="8"
                            aria-label="Additional Options"
                            icon={<Icon variant="fas" icon={isOpen ? 'caret-up' : 'caret-down'} size="3" />}
                          />
                          <MenuList
                            mt={-1}
                            border="none"
                            bgColor="transparent"
                            minW="none"
                            minH="none"
                            py={0}
                            zIndex={10}
                          >
                            <CreateSavedViewButton
                              dataSet={selectedDataSet}
                              columns={columnsWithFilters}
                              isDisabled={!canCreateSavedView}
                              onSavedViewCreated={onSavedViewCreated}
                              buttonProps={{
                                variant: 'outlined',
                                colorScheme: 'brand',
                                fontSize: 'sm',
                                px: '4',
                                size: 'md',
                                bgColor: 'white',
                                color: 'gray.600',
                                _hover: { bgColor: 'gray.100' },
                                borderWidth: '1px',
                                borderColor: 'gray.300',
                                borderStyle: 'solid',
                                h: '12',
                              }}
                            >
                              Save as New View
                            </CreateSavedViewButton>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  </ButtonGroup>
                )}
              </HStack>
            </NoAccessTooltip>
          )}

          <HStack spacing="4">
            {selectedDataSet && isAllDataTab && (
              <CreateSavedViewButton
                dataSet={selectedDataSet}
                columns={columnsWithFilters}
                isDisabled={!canCreateSavedView}
                onSavedViewCreated={onSavedViewCreated}
                buttonProps={{
                  variant: 'outlined',
                  colorScheme: 'brand',
                  fontSize: 'sm',
                  fontWeight: '400',
                  px: '3',
                  size: 'md',
                  bgColor: 'white',
                  color: 'gray.600',
                  _hover: { bgColor: 'gray.100' },
                  borderWidth: '1px',
                  borderColor: 'gray.300',
                  borderStyle: 'solid',
                  h: 8,
                  mt: 0,
                  leftIcon: undefined,
                  mx: 0,
                }}
              >
                Save as New View
              </CreateSavedViewButton>
            )}

            {hasUnsavedChanges && shouldShowWarning && !isAllDataTab && (
              <HStack gap="2">
                <Icon icon="triangle-exclamation" color="yellow.500" size="4" variant="fas" />
                <Text color="gray.600">Unsaved changes</Text>
              </HStack>
            )}

            {isLoading && <Spinner size="sm" />}
          </HStack>
        </Stack>
      </Stack>

      <UpdateSavedViewFiltersDialog
        {...updateSavedViewFiltersDialogDisclosure}
        linkedWorkflowsCount={savedViewLinks.data?.length ?? 0}
        onUpdateView={onUpdateSavedView}
      />
    </>
  );
};

import * as React from 'react';
import { TemplateRevision, Widget } from '@process-street/subgrade/process';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { Box, Icon, IconButton, Tooltip } from 'components/design/next';
import { ConditionalLogicButtonWrapper } from '../conditional-logic-button-wrapper';

export interface WidgetConditionalLogicButtonProps {
  templateRevision: TemplateRevision;
  widget: Widget;
  widgetHasConstraintValidation?: boolean;
}

export const WidgetConditionalLogicButton: React.FC<React.PropsWithChildren<WidgetConditionalLogicButtonProps>> = ({
  templateRevision,
  widget,
  widgetHasConstraintValidation,
}) => {
  const isEditable = useWorkflowState() === 'edit';
  const [ruleChecker, setRuleChecker] = React.useState<(widget: Widget) => boolean>();

  GetAllRulesByTemplateRevisionIdQuery.useQuery(
    {
      templateRevisionId: templateRevision.id,
    },
    {
      onSuccess: ({ definitions: rules }) => {
        // The widget has no groupId when it's created
        if (!widget.header?.group?.id) return;

        const widgetHasAssociatedRule = ConditionalLogicCommonUtils.makeWidgetHasAssociatedRule(rules);

        setRuleChecker(() => (widget: Widget) => widgetHasAssociatedRule({ widget }));
      },
    },
  );

  const hasRules = ruleChecker?.(widget) ?? false;

  return (
    <Tooltip hasArrow label="Conditional logic">
      <Box
        w="21px"
        h="21px"
        position="absolute"
        right="5px"
        top={widgetHasConstraintValidation ? '76px' : 'calc(50% - 20px)'}
      >
        <ConditionalLogicButtonWrapper widget={widget} templateRevision={templateRevision}>
          <IconButton
            className="widget-menu-btn"
            {...{
              'size': 'sm',
              'aria-label': hasRules ? 'Conditional logic applied' : 'Conditional logic',
              'variant': 'ghost',
              'w': '21px',
              'h': '21px',
              'minWidth': 'unset',
              'sx': {
                '&.widget-menu-btn': {
                  background: hasRules ? 'purple.500' : '#8e9eac',
                },
              },

              ...(isEditable
                ? {
                    icon: <Icon icon="shuffle" variant="far" size="4" color="white" />,
                  }
                : {
                    isDisabled: true,
                    pointerEvents: 'none',
                    icon: <Icon icon="shuffle" variant="far" size="4" color="gray.600" />,
                  }),
            }}
          />
        </ConditionalLogicButtonWrapper>
      </Box>
    </Tooltip>
  );
};

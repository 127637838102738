import * as React from 'react';
import { EmbedContentActorRef } from './embed-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { TemplateEmbedWidget } from 'app/components/widgets/embed/template-embed-widget';

export interface EmbedContentProps {
  actor: EmbedContentActorRef;
  isFirst?: boolean;
  isLast?: boolean;
}

export const EmbedContent: React.FC<React.PropsWithChildren<EmbedContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, recentlyMovedFrom } = state.context;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        <TemplateEmbedWidget
          widget={widget}
          onFocus={() => send({ type: 'FOCUS' })}
          disabled={false}
          onUpdate={widget => send({ type: 'CHANGE', widget })}
        />
        <WidgetListItemDragIcon />
        <FormsWidgetMenu>
          <FormsWidgetMenuItems.Duplicate />
          <FormsWidgetMenuItems.MoveToStep widget={widget} />
          <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
          <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
          <FormsWidgetMenuItems.Delete />
        </FormsWidgetMenu>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

import React from 'react';
import { useCoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { useCoverIconByTemplateIdQuery } from 'features/cover-icon/query-builder';
import { Template } from '@process-street/subgrade/process';
import { Box } from 'components/design/next';
import { ChecklistCoverImage } from 'features/cover-image/components/checklist';
import { ChecklistCoverIcon } from 'features/cover-icon/components/checklist';
import { match, P } from 'ts-pattern';
import { UseQueryResult } from 'react-query';
import { CoverIcon, CoverImage } from '@process-street/subgrade/core';

export type ChecklistMediaProps = { templateId: Template['id'] };

export const ChecklistMedia = ({ templateId }: ChecklistMediaProps) => {
  const coverIconQuery = useCoverIconByTemplateIdQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );
  const coverImageQuery = useCoverImageByTemplateIdQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );

  return (
    match<[UseQueryResult<CoverIcon | undefined>, UseQueryResult<CoverImage | undefined>]>([
      coverIconQuery,
      coverImageQuery,
    ])
      //   icon / no cover image
      .with(
        [
          { status: 'success', data: P.not(P.nullish) },
          { status: 'success', data: P.nullish },
        ],
        ([{ data }, _]) => <ChecklistCoverIcon icon={data} mb={2} />,
      )
      // no icon / cover image
      .with(
        [
          { status: 'success', data: P.nullish },
          { status: 'success', data: P.not(P.nullish) },
        ],
        ([_, { data }]) => <ChecklistCoverImage coverImage={data} />,
      )
      // // icon / cover image
      .with(
        [
          { status: 'success', data: P.not(P.nullish) },
          { status: 'success', data: P.not(P.nullish) },
        ],
        ([icon, coverImage]) => (
          <Box pb="2">
            <ChecklistCoverImage coverImage={coverImage.data} mb={0} />
            <ChecklistCoverIcon icon={icon.data} mt="-8" />
          </Box>
        ),
      )
      .otherwise(() => null)
  );
};

import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import { FormEditorPageActorRef } from './form-editor-page-machine';

export const [useFormEditorPageActorRef, FormEditorPageActorRefContext] = createUsableContext<FormEditorPageActorRef>({
  hookName: 'useFormEditorPageActorRef',
  providerName: 'FormEditorPageActorRefProvider',
});

export const FormEditorPageActorRefProvider: React.FC<React.PropsWithChildren<{ actor: FormEditorPageActorRef }>> = ({
  actor,
  children,
}) => {
  return <FormEditorPageActorRefContext.Provider value={actor}>{children}</FormEditorPageActorRefContext.Provider>;
};

import * as React from 'react';
import { VStack, Text, Button, Skeleton, Divider, Icon, Square } from 'components/design/next';
import { useDrag } from 'react-dnd';
import { DraggableType, NewWidgetItem } from '../../types';
import {
  ContentFieldOption,
  FormFieldOption,
  CONTENT_FIELDS,
  FORM_FIELDS,
  WF_EDITOR_FORM_FIELDS,
  WF_EDITOR_CONTENT_FIELDS,
} from './fields';
import { useFeatureFlag } from 'features/feature-flags';
import { FieldType, TemplateType } from '@process-street/subgrade/process';

import { match } from 'ts-pattern';
import { FormEditorPageActorSelectors, useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { useSelector } from '@xstate/react';

export type InsertWidgetProps = {
  onWidgetClick: (data: NewWidgetItem) => void;
  isLoading?: boolean;
};
const filterTableFields = (fields: FormFieldOption[]) => fields.filter(ff => ff.fieldType !== FieldType.Table);

export const InsertWidget: React.FC<React.PropsWithChildren<InsertWidgetProps>> = ({ onWidgetClick, isLoading }) => {
  const actor = useFormEditorPageActorRef();
  const isTableFormFieldEnabled = useFeatureFlag('tableFormField');

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);

  const formFields = (() => {
    const selectedFields = match(template?.templateType)
      .with(TemplateType.Playbook, () => WF_EDITOR_FORM_FIELDS)
      .with(TemplateType.Form, () => FORM_FIELDS)
      .otherwise(() => FORM_FIELDS);
    if (!isTableFormFieldEnabled) {
      return filterTableFields(selectedFields);
    }
    return selectedFields;
  })();
  const contentFields = (() => {
    const selectedContent = match(template?.templateType)
      .with(TemplateType.Playbook, () => WF_EDITOR_CONTENT_FIELDS)
      .with(TemplateType.Form, () => CONTENT_FIELDS)
      .otherwise(() => CONTENT_FIELDS);
    return selectedContent;
  })();
  return (
    <VStack alignItems="flex-start" w="full" spacing="4">
      <VStack alignItems="flex-start" w="full">
        <Text variant="-2u" color="gray.400" pl="4">
          Form Fields
        </Text>

        {isLoading ? (
          <VStack w="full" px="4" spacing="2" alignItems="flex-start" pt="2">
            <Skeleton h="3" w="full" maxW="20" startColor="gray.100" endColor="gray.200" />
            <Skeleton h="3" w="full" maxW="30" startColor="gray.100" endColor="gray.200" />
          </VStack>
        ) : (
          <VStack w="full" alignItems="flex-start">
            {formFields.map(ff => (
              <FormField key={ff.fieldType} formField={ff} onWidgetClick={onWidgetClick} />
            ))}
          </VStack>
        )}
      </VStack>

      <Divider />

      <VStack alignItems="flex-start" w="full">
        <Text variant="-2u" color="gray.400" pl="4">
          Content
        </Text>

        {isLoading ? (
          <VStack w="full" px="4" spacing="2" alignItems="flex-start" pt="2">
            <Skeleton h="3" w="full" maxW="20" startColor="gray.100" endColor="gray.200" />
            <Skeleton h="3" w="full" maxW="30" startColor="gray.100" endColor="gray.200" />
          </VStack>
        ) : (
          <VStack w="full" alignItems="flex-start">
            {contentFields.map(cf => (
              <ContentField
                key={`${cf.widgetType}${cf.fieldType ? '-' + cf.fieldType : ''}`}
                contentField={cf}
                onWidgetClick={onWidgetClick}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

const FormField: React.FC<
  React.PropsWithChildren<{ formField: FormFieldOption } & Pick<InsertWidgetProps, 'onWidgetClick'>>
> = ({ formField, onWidgetClick }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DraggableType.FormField,
    item: formField,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Button
      ref={drag}
      opacity={isDragging ? 0.5 : 1}
      justifyContent="flex-start"
      leftIcon={
        <Square size="6" bg="orange.100" borderRadius="base">
          <Icon icon={formField.icon} size="4" color="orange.500" />
        </Square>
      }
      w="full"
      iconSpacing="3"
      variant="ghost"
      colorScheme="gray"
      fontWeight="400"
      fontSize="sm"
      color="gray.600"
      onClick={() => onWidgetClick({ fieldType: formField.fieldType })}
    >
      {formField.label}
    </Button>
  );
};

const ContentField: React.FC<
  React.PropsWithChildren<{ contentField: ContentFieldOption } & Pick<InsertWidgetProps, 'onWidgetClick'>>
> = ({ contentField, onWidgetClick }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DraggableType.Content,
    item: contentField,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  return (
    <Button
      ref={drag}
      opacity={isDragging ? 0.5 : 1}
      justifyContent="flex-start"
      leftIcon={
        <Square size="6" bgColor="gray.100" borderRadius="base">
          <Icon icon={contentField.icon} size="4" color="gray.500" />
        </Square>
      }
      w="full"
      iconSpacing="3"
      variant="ghost"
      colorScheme="gray"
      fontWeight="400"
      fontSize="sm"
      color="gray.600"
      onClick={() =>
        onWidgetClick({
          widgetType: contentField.widgetType,
          ...(contentField.fieldType && { fieldType: contentField.fieldType }),
        })
      }
    >
      {contentField.label}
    </Button>
  );
};

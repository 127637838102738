import { InboxItem } from '@process-street/subgrade/inbox';
import { trace } from 'app/components/trace';
import { GetChecklistQuery } from 'app/features/checklists/query-builder';
import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';
import { AblyEvent } from 'app/pusher/ably-event';
import { ablyService } from 'app/pusher/ably.service';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

const logger = trace({ name: 'useListenToWorkflowRunUpdated' });

export const useListenToWorkflowRunUpdated = (item: InboxItem) => {
  const queryClient = useQueryClient();

  useEffect(
    function listenToWorkflowRunUpdated() {
      const checklistId = item.checklist.id;

      if (!checklistId) return;

      const channelName = ablyService.getChannelNameForChecklist(checklistId);
      const channel = ablyService.getChannel(channelName);

      const listener = async (message: { data: string }) => {
        logger.info(`message from ${AblyEvent.EventType.WorkflowRunUpdated}`);
        const { checklistId: updatedChecklistId } = JSON.parse(message.data);
        if (checklistId === updatedChecklistId) {
          await queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId }));

          await queryClient
            .fetchQuery(GetChecklistQuery.getKey({ checklistId }), {
              queryFn: () => GetChecklistQuery.queryFn({ checklistId }),
            })
            .then(checklist => {
              GetInboxItemsQuery.updateItem(
                queryClient,
                currentItem => currentItem.checklist.id === item.checklist.id,
                itemToUpdate => ({ ...itemToUpdate, checklist }),
              );
            });
        }
      };

      logger.info(`subscribing to ${AblyEvent.EventType.WorkflowRunUpdated}`);
      channel.subscribe(AblyEvent.EventType.WorkflowRunUpdated, listener);

      return () => {
        logger.info(`unsubscribing from ${AblyEvent.EventType.WorkflowRunUpdated}`);

        channel.unsubscribe(AblyEvent.EventType.WorkflowRunUpdated);
      };
    },
    [item.assignmentId, item.checklist.id, queryClient],
  );
};

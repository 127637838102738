import { useQuery, UseQueryResult } from 'react-query';
import { AutomationApi } from 'features/automations/services';
import { AutomationLog, TemplateSolutionInstance } from '@process-street/subgrade/automation';

export function useAutomationLogsQuery({
  templateSolutionInstance,
}: {
  templateSolutionInstance: TemplateSolutionInstance;
}): UseQueryResult<AutomationLog[], Error> {
  const templateSolutionInstanceId = templateSolutionInstance?.id;
  const templateId = templateSolutionInstance?.templateId;

  return useQuery<AutomationLog[], Error>(
    ['run logs', { templateSolutionInstanceId, templateId }],
    () => AutomationApi.getAutomationLogsByTemplateSolutionInstanceId(templateId, templateSolutionInstanceId),
    {
      enabled: Boolean(templateSolutionInstanceId) && Boolean(templateId),
      refetchInterval: 5000,
    },
  );
}

import { ColDef, GridApi, IDatasource, IGetRowsParams } from '@ag-grid-community/core';
import { Muid } from '@process-street/subgrade/core';
import { ColumnType } from '@process-street/subgrade/dashboard';
import { GetDataRequest } from 'components/dashboard/models/filters';
import { isChecklistColDef, isColDef, isFormFieldColDef, RowValues } from 'components/dashboard/models/grid';
import { GridDataService } from 'components/dashboard/services/grid-data.service';
import { GridHelper } from 'components/dashboard/services/grid-helper';
import { queryClient } from 'components/react-root';
import { GetAllConsolidatedChecklistPermissions } from 'features/permissions/query-builder';
import { times } from 'lodash';
import { Option } from 'space-monad';

export type GridDataSourceExtraParams = {
  showRunWorkflow: boolean;
  isBulkDeleteArchiveEnabled?: boolean;
  showEnhancedEmptyState?: boolean;
};

export class GridDataSource implements IDatasource {
  public constructor(
    private gridDataService: GridDataService,
    private gridApi: GridApi,
    private extraParams: GridDataSourceExtraParams,
  ) {}

  private offsetId?: Muid;

  public getRows(params: IGetRowsParams): void {
    const refresh = params.startRow === 0;
    const pageSize = params.endRow - params.startRow;
    const { context } = params;

    if (refresh) {
      this.offsetId = undefined;
    }

    const request: GetDataRequest = {
      organizationId: context.organizationMembership.organization.id,
      filtering: context.savedView.filters,
      offsetId: this.offsetId,
      pageSize: pageSize + 1,
    };

    const columns = Option(this.gridApi.getColumnDefs()).getOrElse([]).filter(isColDef);

    const sortedColumn = columns.find(col => col.sort);

    if (sortedColumn) {
      if (isChecklistColDef(sortedColumn)) {
        request.sorting = {
          sortAsc: sortedColumn.sort === 'asc',
          sortColumn: sortedColumn.field,
        };
      } else if (isFormFieldColDef(sortedColumn)) {
        request.sorting = {
          sortColumn: 'FormFieldValue',
          sortAsc: sortedColumn.sort === 'asc',
          sortByWidgetGroupId: sortedColumn.field?.split('.')[1],
        };
      }
    }

    if (refresh) {
      this.gridDataService.getRowCount(request, context.organizationMembership.id).then(count => {
        if (count === 0) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      });
    }

    this.gridDataService.getData(request, context.organizationMembership.id).then(
      async returnedData => {
        const hasMore = returnedData.length > pageSize;
        const data = hasMore ? returnedData.slice(0, pageSize) : returnedData.slice();
        const templateId = GridHelper.getTemplateIdFromSavedView(context.savedView);

        if (hasMore) {
          this.offsetId = data[data.length - 1].id as Muid;
        }

        if (templateId && data.length > 0) {
          this.loadAdditionData(context.organizationMembership.organization.id, templateId, data);
        }
        if (data.length === 0 && this.extraParams.showEnhancedEmptyState) {
          data.push(...times(8, () => GridHelper.getEmptyStateRow()));
        }
        if (this.extraParams.showRunWorkflow) {
          data.push(GridHelper.getRunWorkflowRow());
        }
        if (data.length > 0 && this.extraParams.isBulkDeleteArchiveEnabled) {
          // We have to wait for the permissions to load, otherwise, we can't
          // evaluate the status of the checklist.
          await this.loadPermissions(data);
        }

        // This is very finicky, it must be exactly the last row index, no more, no less
        // We use returnedData because it won't contain any new rows injected by us
        // https://www.ag-grid.com/react-data-grid/infinite-scrolling/#setting-last-row-index
        const lastRow = hasMore ? undefined : params.startRow + returnedData.length;
        params.successCallback(data, lastRow);
      },
      () => params.failCallback(),
    );
  }

  private loadAdditionData = (organizationId: string, templateId: string, rowValues: RowValues[]) => {
    const colDefs = Option(this.gridApi.getColumnDefs()).getOrElse([]).filter(isColDef);
    const taskColDefs = colDefs.filter(col => col.type === ColumnType.Task);
    const formFieldValueColDefs = colDefs.filter(col => col.type === ColumnType.FormField);

    const rowsByChecklistRevisionId: { [index: string]: RowValues } = rowValues.reduce((acc, row: RowValues) => {
      acc[row.checklistRevisionId as string] = row;
      return acc;
    }, {} as { [index: string]: RowValues });

    const promises = [];

    if (taskColDefs.length > 0) {
      promises.push(this.loadTaskValues(organizationId, templateId, taskColDefs, rowsByChecklistRevisionId));
    }

    if (formFieldValueColDefs.length > 0) {
      promises.push(
        this.loadFormFieldValues(organizationId, templateId, formFieldValueColDefs, rowsByChecklistRevisionId),
      );
    }

    Promise.all(promises).then(() => this.gridApi.refreshCells());
  };

  private loadTaskValues = (
    organizationId: string,
    templateId: string,
    taskColDefs: ColDef[],
    rowsByChecklistRevisionId: { [index: string]: RowValues },
  ) => {
    const colDefsByGroupId = taskColDefs.reduce((acc, colDef: ColDef) => {
      acc[colDef.cellRendererParams.groupId as string] = colDef;
      return acc;
    }, {} as { [index: string]: ColDef });

    const checklistRevisionIds = Object.keys(rowsByChecklistRevisionId);
    const groupIds = Object.keys(colDefsByGroupId);

    return this.gridDataService
      .getTaskData(organizationId, templateId, checklistRevisionIds, groupIds)
      .then(taskDataRows => {
        taskDataRows.forEach(taskDataRow => {
          const row = rowsByChecklistRevisionId[taskDataRow.checklistRevisionId];
          taskDataRow.tasks.forEach(task => {
            const colDef = colDefsByGroupId[task.groupId];
            row[colDef.field as string] = task;
          });
        });
      });
  };

  private loadFormFieldValues = (
    organizationId: string,
    templateId: string,
    formFieldColDefs: ColDef[],
    rowsByChecklistRevisionId: { [index: string]: RowValues },
  ) => {
    const colDefsByWidgetGroupId = formFieldColDefs.reduce((acc, colDef: ColDef) => {
      const widgetGroupId = colDef.cellRendererParams.widgetGroupId as string;

      acc[widgetGroupId] = !acc[widgetGroupId] ? [colDef] : [colDef, ...acc[widgetGroupId]];
      return acc;
    }, {} as { [index: string]: ColDef[] });

    const checklistRevisionIds = Object.keys(rowsByChecklistRevisionId);
    const groupIds = Object.keys(colDefsByWidgetGroupId);

    return this.gridDataService
      .getFormFieldValuesData(organizationId, templateId, checklistRevisionIds, groupIds)
      .then(formFieldValueRows => {
        formFieldValueRows.forEach(formFieldValueRow => {
          const row = rowsByChecklistRevisionId[formFieldValueRow.checklistRevisionId];

          formFieldValueRow.formFieldValues.forEach(formFieldValues => {
            const colDefs = colDefsByWidgetGroupId[formFieldValues.widgetGroupId];
            colDefs.forEach(colDef => {
              row[colDef.field as string] = formFieldValues;
            });
          });
        });
      });
  };

  private loadPermissions = async (rowValues: RowValues[]) => {
    const checklistIds = rowValues.filter(row => Boolean(row.id)).map(row => row.id as string);

    const permissions = await queryClient.fetchQuery({
      queryFn: () =>
        GetAllConsolidatedChecklistPermissions.queryFn({
          checklistIds,
        }),
      queryKey: GetAllConsolidatedChecklistPermissions.getKey({ checklistIds }),
    });

    const permissionsByChecklistId = Object.fromEntries(
      permissions.map(permission => [permission.checklistId, permission]),
    );

    rowValues.forEach(row => {
      const permissionMap = permissionsByChecklistId[row.id]?.permissionMap;
      if (permissionMap) {
        row.permissionMap = { ...permissionMap };
      }
    });

    return;
  };
}

import { Muid } from '@process-street/subgrade/core';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryKey, QueryClient, QueryObserver, QueryObserverOptions, useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetAllTasksByChecklistRevisionIdQueryParams = {
  checklistRevisionId?: Muid;
};

export type GetAllTasksByChecklistRevisionIdQueryResponse = TaskWithTaskTemplate[];

export const GetAllTasksByChecklistRevisionIdQuery = {
  key: ['checklist-revisions', 'tasks'],
  getKey: (params: GetAllTasksByChecklistRevisionIdQueryParams): QueryKey => [
    ...GetAllTasksByChecklistRevisionIdQuery.key,
    params,
  ],
  queryFn: (params: GetAllTasksByChecklistRevisionIdQueryParams) =>
    axiosService
      .getAxios()
      .get<GetAllTasksByChecklistRevisionIdQueryResponse>(`/1/checklist-revisions/${params.checklistRevisionId}/tasks`)
      .then(res => res.data),
};

export const useGetAllTasksByChecklistRevisionIdQuery = <Select = GetAllTasksByChecklistRevisionIdQueryResponse>(
  params: GetAllTasksByChecklistRevisionIdQueryParams,
  options: UseQueryOptions<GetAllTasksByChecklistRevisionIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetAllTasksByChecklistRevisionIdQuery.getKey(params),
    () => GetAllTasksByChecklistRevisionIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    },
  );
};

export const makeGetAllTasksByChecklistRevisionIdQueryObserver = ({
  queryClient,
  options,
  ...params
}: GetAllTasksByChecklistRevisionIdQueryParams & {
  queryClient: QueryClient;
  options: QueryObserverOptions<GetAllTasksByChecklistRevisionIdQueryResponse, AxiosError>;
}) => {
  return new QueryObserver<GetAllTasksByChecklistRevisionIdQueryResponse, AxiosError>(queryClient, {
    queryKey: GetAllTasksByChecklistRevisionIdQuery.getKey(params),
    queryFn: () => GetAllTasksByChecklistRevisionIdQuery.queryFn(params),
    enabled: Boolean(params.checklistRevisionId) && options.enabled !== false,
    ...options,
  });
};

import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useActor } from '@xstate/react';
import { TableFormFieldActor } from './table-form-field-machine';
import { VStack } from 'components/design/next';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { ColDef, GetMainMenuItemsParams, MenuItemDef } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import 'app/styles/ag-grid/ag-theme-standard.scss';
import { Box } from '@chakra-ui/react';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { match } from 'ts-pattern';
import { WidgetListItemDragIcon } from 'pages/forms/_id/edit/components/widgets-list/widget-list-item-drag-icon';
import { FormsWidgetMenu, FormsWidgetMenuItems } from 'pages/forms/_id/edit/components';
import { useAgGridColDefs } from 'pages/forms/_id/shared/hooks/use-ag-grid-col-defs';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { MuidUtils } from '@process-street/subgrade/core';
import { TemplateType } from '@process-street/subgrade/process';
import { WorkflowFormFieldLabel } from 'pages/workflows/_id/edit/components/common/workflow-form-field-label';
import debounce from 'lodash/debounce';

const EXAMPLE_TEXT_CELLS = ['Apple', 'Banana', 'Carrot'];
const EXAMPLE_NUMBER_CELLS = [1, 42, 3.14];

export type TableFormFieldWidgetProps = {
  actor: TableFormFieldActor;
  isDisabled?: boolean;
  isFirst: boolean;
  isLast: boolean;
  templateType?: TemplateType;
};

export const TableFormFieldWidget: React.FC<React.PropsWithChildren<TableFormFieldWidgetProps>> = ({
  actor,
  isDisabled,
  isFirst,
  isLast,
  templateType,
}) => {
  const [state, send] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom } = state.context;
  const { columnDefs } = widget.config;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const colDefs = useAgGridColDefs<TableFieldValue.Row>(columnDefs, {
    editable: !isDisabled,
    suppressMenu: isDisabled,
  });

  const sampleRowData: TableFieldValue.Row[] = [];
  for (let i = 0; i < 3; i++) {
    const cells = columnDefs.reduce<TableFieldValue.Row['cells']>((cs, def) => {
      const cell: TableFieldValue.Cell = match(def.columnType)
        .with('Text', () => ({ value: EXAMPLE_TEXT_CELLS[i] }))
        .with('Number', () => ({ value: EXAMPLE_NUMBER_CELLS[i] }))
        .exhaustive();
      return { ...cs, [def.id]: cell };
    }, {});
    sampleRowData.push({ id: MuidUtils.randomMuid(), cells });
  }

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
      return [
        {
          icon: '',
          name: 'Rename',
          action: () => {
            const newColumnName = prompt('New column name:', params.column.getColDef().headerName);
            if (newColumnName) {
              send({ type: 'UPDATE_COLUMN_NAME', id: params.column.getColId(), columnName: newColumnName });
            }
          },
        },
        'separator',
        {
          icon: '←',
          name: 'Insert Left',
          action: () => {
            send({ type: 'INSERT_COLUMN_LEFT', id: params.column.getColId() });
          },
        },
        {
          icon: '→',
          name: 'Insert Right',
          action: () => {
            send({ type: 'INSERT_COLUMN_RIGHT', id: params.column.getColId() });
          },
        },
        'separator',
        {
          name: 'Delete',
          disabled: params.api.getColumnDefs()?.length === 1,
          action: () => {
            send({ type: 'DELETE_COLUMN', id: params.column.getColId() });
          },
        },
      ];
    },
    [send],
  );

  const reorderColumns = useMemo(
    () => debounce((colDefs: ColDef[]) => send({ type: 'REORDER_COLUMNS', colDefs }), 500),
    [send],
  );

  const grid = (
    <Box
      width="full"
      height="300"
      className="ag-theme-alpine ag-theme-standard"
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          send({ type: 'SET_NODE', node });
        }
      }}
    >
      <AgGridReact<TableFieldValue.Row>
        modules={[ClientSideRowModelModule, MenuModule]}
        overlayNoRowsTemplate={'No rows to display.'}
        rowData={sampleRowData}
        columnDefs={colDefs}
        getMainMenuItems={getMainMenuItems}
        onColumnMoved={e => {
          const colDefs = e.api.getColumnDefs();
          if (colDefs) {
            reorderColumns(colDefs);
          }
        }}
        enableCellTextSelection={true}
        suppressMovableColumns={isDisabled}
        suppressDragLeaveHidesColumns={true}
        suppressMenuHide={true}
        icons={{ menu: '⋯' }}
      />
    </Box>
  );

  switch (templateType) {
    case TemplateType.Playbook:
      return (
        <WidgetActorProvider widgetActorRef={actor}>
          <VStack alignItems="flex-start" flex="1" opacity={isDisabled ? 0.8 : undefined}>
            {labelActor && isDisabled ? (
              <WorkflowFormFieldLabel isRequired={widget.required}>
                {widget.label || 'Untitled Table'}
              </WorkflowFormFieldLabel>
            ) : (
              <FormFieldLabel actor={labelActor} />
            )}
            {grid}
          </VStack>
        </WidgetActorProvider>
      );
    case TemplateType.Form:
    default:
      return (
        <WidgetActorProvider widgetActorRef={actor}>
          <FormsWidgetMenuContainer>
            <VStack alignItems="flex-start" flex="1">
              {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
              {labelActor && <FormFieldLabel actor={labelActor} />}

              <WidgetListItemDragIcon />

              {grid}

              <FormsWidgetMenu>
                <FormsWidgetMenuItems.Required widget={widget} />
                <FormsWidgetMenuItems.ConditionalLogic widget={widget} />
                <FormsWidgetMenuItems.Divider />
                <FormsWidgetMenuItems.Duplicate />
                <FormsWidgetMenuItems.MoveToStep widget={widget} />
                <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                <FormsWidgetMenuItems.Delete />
              </FormsWidgetMenu>
            </VStack>
          </FormsWidgetMenuContainer>
        </WidgetActorProvider>
      );
  }
};

import * as React from 'react';
import { createUsableContext, queryString } from '@process-street/subgrade/util';
import { AppState } from 'app/app.states.typegen';
import { match, P } from 'ts-pattern';
import deepEqual from 'deep-equal';
import { useFoldersQuery } from 'pages/library/components/template-library/query';
import { useTemplateId } from 'pages/templates/_id/automation/utils/use-template-id';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { getAncestorFolders, toSlugPath } from 'features/folder/lib';
import { Muid } from '@process-street/subgrade/core';
import { useLocation, useNavigate, useOnRouteChange } from '@process-street/adapters/navigation';
import { Location } from 'react-router';

type StateParams = ReturnType<typeof queryString.parse>;
type StateHistoryItem = { name: AppState; params: StateParams };
export type StateHistory = StateHistoryItem[];

export type Context = {
  onGoBack: (() => void) | undefined;
  shouldShowBackButton: boolean;
};

export const [useBackButtonContext, BackButtonContext] = createUsableContext<Context>({
  hookName: 'useBackButtonContext',
  providerName: 'BackButtonProvider',
});

export const STATES_TO_KEEP_TRACK = new Set<AppState>([
  'templateDashboard',
  'templateView',
  'templateView.task',
  'template',
  'template.task',
  'checklist.task',
  'checklist',
  'checklistShared.task',
  'futureChecklist',
  'checklistAddMultiple',
  'dashboard.type',
]);

export const getNewHistory = (history: StateHistory, to: Partial<Location>): StateHistory => {
  if (!to.pathname) return history;
  const toParamsParsed = queryString.parse(to.search ?? '');

  const lastItem = history[history.length - 1];
  // Check if the user has navigated back using the browser's arrows by comparing
  // the penultimate item in the history with the current state.
  // If they match, it indicates a backward navigation in the browser history.
  const penultimateItem = history[history.length - 2];
  const isNavigatingBack = penultimateItem?.name === to.pathname && deepEqual(penultimateItem?.params, toParamsParsed);

  // When navigating back, we just remove the last item of the array.
  if (isNavigatingBack) return history.slice(0, -1);

  const lastVisitedState = history[history.length - 1];

  const [subPath] = to.pathname.split('.');
  const isSubPathOfLastVisited = subPath === lastVisitedState?.name;
  // Avoid duplicating the same route
  if (isSubPathOfLastVisited) return history;

  const isNavigatingToDashboard = to.pathname === 'templateDashboard';
  // The only state that should be pushed to the history when the `templateDashboard` wasn't visited yet is the `templateDashboard`
  const isDashboardInHistory = getTemplateDashboardHistoryItem(history) || isNavigatingToDashboard;
  if (!isDashboardInHistory) return history;

  const isEditing = lastItem?.name === 'template.task' || lastItem?.name === 'template';
  const targetHistoryItem: StateHistoryItem = { name: to.pathname as AppState, params: toParamsParsed };
  // Indicates that the user clicked on the "Publish" or "Publish and run" button
  // Which goes back to WF, WFR view or dashboard
  if (
    isEditing &&
    (to.pathname === 'checklist' || to.pathname === 'checklist.task' || to.pathname === 'templateDashboard')
  ) {
    // In this case we remove the "template.task" (editor) and the item preceding the editor
    // from the history so when the user clicks to go back, they
    // 1) don't go to the editor again and
    // 2) they won't have a duplicated "checklist/template" history item.
    const truncatedHistory = history.slice(0, -2);

    // This happens when: Dashboard -> Preview -> Edit (Publish) -> Dashboard
    const isHistoryDuplicated = targetHistoryItem.name === truncatedHistory[truncatedHistory.length - 1]?.name;
    if (isHistoryDuplicated) {
      // Cut one more history item, the Dashboard
      return [...truncatedHistory.slice(0, -1), targetHistoryItem];
    }

    return [...truncatedHistory, targetHistoryItem];
  }

  // Erase state history until the relevant dashboard item when going back to some template's dashboard
  const templateId = toParamsParsed.id as string;
  const historyItem = getTemplateDashboardHistoryItem(history, templateId);
  if (historyItem && Boolean(templateId) && isNavigatingToDashboard) {
    const index = history.findIndex(i => i === historyItem);
    if (index >= 0) {
      return history.slice(0, index + 1);
    }
  }

  // Don't duplicate the last visited state
  if (lastVisitedState && lastVisitedState.name === to.pathname) return history;

  // Only add the `dashboard.type` when the `type` param is `scheduled`
  if (to.pathname === 'dashboard.type' && toParamsParsed.type !== 'scheduled') return history;

  return [...history, targetHistoryItem];
};

export const getTemplateDashboardHistoryItem = (history: StateHistory, id?: Muid) =>
  history.find(({ name, params }) => name === 'templateDashboard' && (id ? params.id === id : true));

export const BackButtonProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const templateId = useTemplateId();
  const templateQuery = useGetTemplateQuery({ templateId });
  const location = useLocation();
  const navigate = useNavigate();

  // Store the state where the user was before going to the template dashboard, used for the back button.
  const redirectedFromRef = React.useRef<StateHistoryItem | null>(null);

  // Store the original state data, used for resetting mutated state.
  const originalStateDataMapRef = React.useRef<Partial<Record<AppState, StateParams>>>({});

  const [history, setHistory] = React.useState<StateHistory>(
    location.pathname === 'templateDashboard'
      ? [{ name: location.pathname, params: queryString.parse(location.search) }]
      : [],
  );

  const foldersQuery = useFoldersQuery({
    enabled: Boolean(templateQuery.data),
  });

  const dashboardFolderParams = React.useMemo(() => {
    const folders = foldersQuery.data ?? [];
    const folder = folders.find(folder => folder.id === templateQuery.data?.folder.id);

    if (!folder) return;

    return { type: 'folder', path: toSlugPath(folder, getAncestorFolders(folder, folders)) };
  }, [foldersQuery.data, templateQuery.data?.folder?.id]);

  const templateDashboardHasBeenVisited = React.useMemo(
    () => Boolean(getTemplateDashboardHistoryItem(history)),
    [history],
  );

  const handleStateChangeSuccess = React.useCallback(
    (to: Location, from: Location, updateState: (newState: Record<string, unknown>) => void) => {
      const toParamsParsed = queryString.parse(to.search ?? '');
      const fromParamsParsed = queryString.parse(from.search ?? '');

      const shouldKeepTrackOfState = match({ name: to.pathname, params: toParamsParsed })
        // Only handles `dashboard.type` when the `type` param is "scheduled", any other param will be ignored.
        .with({ name: 'dashboard.type' }, ({ params }) => params.type === 'scheduled')
        .with({ name: P.string }, ({ name }) => STATES_TO_KEEP_TRACK.has(name as AppState))
        .otherwise(() => false);

      if (to.pathname && !originalStateDataMapRef.current?.[to.pathname as AppState]) {
        originalStateDataMapRef.current = {
          ...originalStateDataMapRef.current,
          [to.pathname as AppState]: to.state,
        };
      }

      if (to.pathname && shouldKeepTrackOfState && !toParamsParsed.redirectedFromDashboard) {
        setHistory(history => {
          const newHistory = getNewHistory(history, to);

          if (history.length === 0 && to.pathname === 'templateDashboard' && from.pathname) {
            redirectedFromRef.current = {
              name: from.pathname as AppState,
              params: fromParamsParsed,
            };
          }

          const originalStateData = originalStateDataMapRef.current?.[to.pathname as AppState];

          if (originalStateData) {
            // Restore the original state data as Angular retains mutated state data.
            updateState(originalStateData);
          }

          return newHistory;
        });
      } else {
        redirectedFromRef.current = null;
        // reset the history if the state has transitioned to one that does not require a back button.
        setHistory([]);
      }
    },
    [],
  );

  const goBack = React.useCallback(() => {
    if (history.length === 0) return;

    const [previous, current] = history.slice(-2);

    if (previous && current) {
      const { name, params } = previous;
      navigate({ pathname: name, search: queryString.stringify(params) });

      // Remove the last state
      setHistory(history.slice(0, -1));
    } else if (history.length === 1 && previous.name === 'templateDashboard') {
      if (redirectedFromRef.current) {
        navigate({
          pathname: redirectedFromRef.current.name,
          search: queryString.stringify(redirectedFromRef.current.params),
        });
        redirectedFromRef.current = null;
      } else {
        navigate({ pathname: 'dashboard.type', search: queryString.stringify(dashboardFolderParams ?? {}) });
      }
    }
  }, [dashboardFolderParams, history, navigate]);

  useOnRouteChange(({ to, from, updateState }) => {
    handleStateChangeSuccess(to, from, updateState);
  });

  const value = React.useMemo(
    () => ({
      onGoBack: goBack,
      shouldShowBackButton: templateDashboardHasBeenVisited,
    }),
    [goBack, templateDashboardHasBeenVisited],
  );

  return <BackButtonContext.Provider value={value}>{children}</BackButtonContext.Provider>;
};

import * as React from 'react';
import { Grid, GridItem, HStack, Icon, IconButton, Link, MotionWrapper, Text } from 'components/design/next';
import {
  DueDateRuleDefinition,
  DueDateRuleSourceType,
  FormFieldWidget,
  TaskTemplate,
  TemplateRevision,
  TemplateTaskAssignment,
} from '@process-street/subgrade/process';
import { getNameForTask } from './utils';
import { TaskListItemDynamicDueDateIndicatorHelpers } from '../../task-list-item-dynamic-due-date-indicator/task-list-item-dynamic-due-date-indicator.helpers';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { ApprovalTaskListAssignees } from './approval-task-list-assignees';
import { useQueryClient } from 'react-query';
import {
  DeleteApprovalRuleMutation,
  GetApprovalRulesByTaskTemplateIdQuery,
} from 'app/features/approval-rules/query-builder';
import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';
import { AnimatePresence } from 'framer-motion';
import _keyBy from 'lodash/keyBy';

export type ApprovalTaskListItemProps = {
  approvalRuleId: ApprovalRuleSubject['id'];
  dueDateRules: DueDateRuleDefinition;
  task: TaskTemplate;
  templateRevisionId: TemplateRevision['id'];
  taskAssignments: TemplateTaskAssignment[];
};

export const ApprovalTaskListItem: React.FC<ApprovalTaskListItemProps> = ({
  approvalRuleId,
  dueDateRules,
  task,
  templateRevisionId,
  taskAssignments,
}) => {
  const actor = useFormEditorPageActorRef();
  const queryClient = useQueryClient();

  const deleteApprovalRuleMutation = DeleteApprovalRuleMutation.useMutation({
    onSuccess: () =>
      queryClient.invalidateQueries(GetApprovalRulesByTaskTemplateIdQuery.getKey({ templateRevisionId })),
  });

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);
  const widgetList = useSelector(actor, FormEditorPageActorSelectors.getWidgets);
  const widgetsMap = React.useMemo(() => _keyBy(widgetList, w => w.header.group.id), [widgetList]);

  const dueDateRuleLabel = React.useMemo(() => {
    if (!dueDateRules) return null;
    const widget =
      dueDateRules.sourceType === DueDateRuleSourceType.FormFieldValue
        ? widgetsMap[dueDateRules.formFieldWidgetGroup!.id]
        : undefined;
    return TaskListItemDynamicDueDateIndicatorHelpers.getFullLabelByRule(
      dueDateRules,
      task,
      widget as unknown as FormFieldWidget,
    );
  }, [dueDateRules, task, widgetsMap]);

  const taskName = getNameForTask(task);
  const onDelete = () =>
    deleteApprovalRuleMutation.mutate({
      templateRevisionId,
      ids: [approvalRuleId],
    });

  return (
    <AnimatePresence>
      <MotionWrapper
        key={approvalRuleId}
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <HStack
          as="li"
          _hover={{
            '.chakra-button': {
              opacity: 1,
            },
          }}
          _focusWithin={{
            '.chakra-button': {
              opacity: 1,
            },
          }}
        >
          <Grid
            borderRadius="md"
            backgroundColor="blue.50"
            gridTemplateColumns="1fr auto"
            px={{ base: 4, md: 8 }}
            py={8}
            w="full"
            _hover={{ bgColor: 'blue.100' }}
          >
            <GridItem gridColumn={1}>
              {template && (
                <Link
                  href={`/workflows/v2/${template.id}/edit/tasks/${task.group.id}`}
                  color="brand.500"
                  fontWeight="bold"
                  mb={4}
                >
                  <Text maxW={{ base: '171px', md: '490px' }} isTruncated>
                    {taskName}
                  </Text>
                </Link>
              )}
              {dueDateRules && (
                <HStack color="gray.500" gap={2} mt={2}>
                  <Icon icon="clock" size="3" />
                  <Text maxW={{ base: '148px', md: 'unset' }} isTruncated>
                    {dueDateRuleLabel}
                  </Text>
                </HStack>
              )}
            </GridItem>
            {taskAssignments && taskAssignments.length > 0 && (
              <GridItem gridColumn={2} display="flex" justifyContent="flex-end" alignItems="center">
                <ApprovalTaskListAssignees taskAssignments={taskAssignments} />
              </GridItem>
            )}
          </Grid>
          <IconButton
            onClick={onDelete}
            aria-label={`Delete approval rule ${taskName}`}
            icon={<Icon icon="xmark" size="4" color="gray.500" />}
            size="sm"
            variant="ghost"
            colorScheme="gray"
            opacity={{ base: 1, lg: 0 }}
          />
        </HStack>
      </MotionWrapper>
    </AnimatePresence>
  );
};

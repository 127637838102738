import { useStore } from 'zustand';
import { Checklist, Template } from '@process-street/subgrade/process';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import createStore from 'zustand/vanilla';

export type OneOffTaskDrawerMode = 'create' | 'edit' | 'view';

interface OneOffTaskDrawerState {
  hasUnsavedChanges: boolean;
  isOpen: boolean;
  preselectedTemplateId?: Template['id'];
  preselectedChecklistId?: Checklist['id'];
  task?: OneOffTask;
  taskId?: OneOffTask['id'];
  mode: OneOffTaskDrawerMode;
  options?: ViewOptions;
}

type ViewOptions = Partial<{
  shouldScrollToComments: boolean;
}>;

type CreateAttachedTaskParams = {
  preselectedTemplateId: Template['id'];
  preselectedChecklistId: Checklist['id'];
};

type ViewTaskParams = {
  task: OneOffTask;
};

type ViewTaskByIdParams = {
  taskId: OneOffTask['id'];
  options?: ViewOptions;
};

interface OneOffTaskDrawerActions {
  createProjectTask: () => void;
  createAttachedTask: (params: CreateAttachedTaskParams) => void;
  viewTask: (params: ViewTaskParams) => void;
  viewTaskById: (params: ViewTaskByIdParams) => void;
  onClose: () => void;
  setMode: (mode: OneOffTaskDrawerMode) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

type OneOffTaskDrawerStore = OneOffTaskDrawerState & OneOffTaskDrawerActions;

export const oneOffTaskDrawerStore = createStore<OneOffTaskDrawerStore>(set => ({
  hasUnsavedChanges: false,
  isOpen: false,
  preselectedTemplateId: undefined,
  preselectedChecklistId: undefined,
  task: undefined,
  taskId: undefined,
  linkedChecklist: undefined,
  mode: 'create',
  api: undefined,
  createProjectTask: () => set({ isOpen: true, mode: 'create' }),
  createAttachedTask: ({ preselectedTemplateId, preselectedChecklistId }) =>
    set({ isOpen: true, mode: 'create', preselectedTemplateId, preselectedChecklistId }),
  viewTask: ({ task }) => set({ isOpen: true, mode: 'view', task }),
  viewTaskById: ({ taskId, options }) => set({ isOpen: true, mode: 'view', taskId, options }),
  onClose: () =>
    set({
      hasUnsavedChanges: false,
      preselectedTemplateId: undefined,
      preselectedChecklistId: undefined,
      task: undefined,
      taskId: undefined,
      isOpen: false,
      options: undefined,
    }),
  setMode: (mode: OneOffTaskDrawerMode) => set({ mode }),
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => set({ hasUnsavedChanges }),
}));

export const useOneOffTaskDrawerStore = (): OneOffTaskDrawerStore => useStore(oneOffTaskDrawerStore);

export const useViewTaskById = () => useStore(oneOffTaskDrawerStore, state => state.viewTaskById);

export const useViewTask = () => useStore(oneOffTaskDrawerStore, state => state.viewTask);

export const useCloseOneOffTaskDrawer = () => useStore(oneOffTaskDrawerStore, state => state.onClose);

export const useCreateProjectTask = () => useStore(oneOffTaskDrawerStore, state => state.createProjectTask);

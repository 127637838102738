import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { ApprovalTaskGroupsMap } from '@process-street/subgrade/approval-rule/approval.types';
import { Muid, Option, User } from '@process-street/subgrade/core';
import { ChecklistRevision, Task, TaskStatus } from '@process-street/subgrade/process';
import { BaseApprovalSelector } from '@process-street/subgrade/redux/selector/approval.selectors';
import angular from 'angular';
import { ApprovalRulesActions } from 'components/approval-rules/store/approval-rules.actions';
import { approvalService } from 'components/approvals/approval-service';
import { ApprovalActions } from 'components/approvals/store/approval.actions';
import ngRedux from 'ng-redux';
import { ChecklistRevisionSelector } from 'reducers/checklist-revision/checklist-revision.selectors';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TaskTemplateActions } from 'reducers/task-template/task-template.actions';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { ReduxAppState } from 'reducers/types';
import { connectController } from 'reducers/util';
import { IModalInstanceService, IModalService } from 'services/message-box.interface';
import { SessionService } from 'services/session-service.interface';
import templateUrl from './action-menu.component.html';
import rejectModalTemplateUrl from './approval-modal/reject-modal.component.html';
import approveModalTemplateUrl from './approval-modal/approve-modal.component.html';
import { StopTaskEvent } from 'services/stop-task-event';
import './action-menu.component.scss';
import { FormFieldValueSelector } from 'components/form-field-value/store/form-field-value.selectors';
import { Unsubscribe } from 'redux';
import { TaskActionsType } from 'reducers/task/task.actions';
import { TaskSelector } from 'reducers/task/task.selectors';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ToastService } from 'services/toast-service.interface';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { queryClient } from 'components/react-root';
import { GetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';

interface InternalState {
  approvals: Approval[];
  approvedTasks: Task[];
  awaitingTasks: Task[];
  rejectedTasks: Task[];
  selectedOrganizationId: Muid;
  checklistRevision: ChecklistRevision;
}

export interface InternalActions {
  getAllApprovalRuleSubjectsByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
  getAllApprovalsByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
  getAllTaskTemplatesByTemplateRevisionId(templateRevisionId: Muid): Promise<void>;
  getAllTasksByChecklistRevisionId(checklistRevisionId: Muid): Promise<void>;
  upsertAllApprovals(checklistRevisionId: Muid, approvals: Approval[], approvalTaskId: Muid): Promise<void>;
}

export class ApprovalTaskActionMenuController {
  public checklistRevisionId: Option<Muid>;
  public task: Option<Task>;
  public user: Option<User>;
  public disabled = false;

  public state: Option<InternalState>;
  public actions: Option<InternalActions>;

  public approvalModalIsOpen = false;
  public approvalModalInstance: Option<IModalInstanceService>;
  public approvalModalStatus: ApprovalStatus = ApprovalStatus.Rejected;

  static $inject = [
    '$ngRedux',
    '$rootScope',
    '$timeout',
    'ApprovalActions',
    'ApprovalRulesActions',
    'SessionService',
    'TaskTemplateActions',
    'TaskActions',
    'ToastService',
    '$uibModal',
  ];
  constructor(
    private $ngRedux: ngRedux.INgRedux,
    private $rootScope: angular.IScope,
    private $timeout: angular.ITimeoutService,
    private approvalActions: ApprovalActions,
    private approvalRulesActions: ApprovalRulesActions,
    private sessionService: SessionService,
    private taskTemplateActions: TaskTemplateActions,
    private taskActions: TaskActionsType,
    private toastService: ToastService,
    private $uibModal: IModalService,
  ) {
    this.user = this.sessionService.getUser();

    const mapStateToThis = () => (state: ReduxAppState) => {
      if (this.checklistRevisionId) {
        const checklistRevision = ChecklistRevisionSelector.getById(this.checklistRevisionId)(state);
        const selectedOrganizationId = SessionSelector.getSelectedOrganizationId(state);

        let taskGroupsMap: ApprovalTaskGroupsMap = {
          approvedTasks: [],
          awaitingTasks: [],
          notSubmittedTasks: [],
          rejectedTasks: [],
        };
        let approvals: Approval[] = [];

        if (this.task && checklistRevision) {
          approvals = BaseApprovalSelector.getAllByTaskIds(this.checklistRevisionId, [this.task.id])(state);
          const taskTemplate = TaskTemplateSelector.getById(this.task.taskTemplate!.id)(state);

          if (taskTemplate) {
            taskGroupsMap = BaseApprovalSelector.getApprovalTaskGroupsMap(
              checklistRevision.id,
              this.task.id,
              checklistRevision.templateRevision.id,
              taskTemplate!.group.id,
            )(state);
          }
        }

        return { ...taskGroupsMap, selectedOrganizationId, checklistRevision, approvals };
      }
    };

    const mapDispatchToThis = () => ({
      getAllApprovalRuleSubjectsByTemplateRevisionId: this.approvalRulesActions.getAllByTemplateRevisionId,
      getAllApprovalsByChecklistRevisionId: this.approvalActions.getAllByChecklistRevisionId,
      getAllTaskTemplatesByTemplateRevisionId: this.taskTemplateActions.getAllByTemplateRevisionId,
      getAllTasksByChecklistRevisionId: this.taskActions.getAllByChecklistRevisionId,
      upsertAllApprovals: this.approvalActions.upsertAll,
    });

    connectController(this.$ngRedux, mapStateToThis, mapDispatchToThis, this.shouldChange)(this);
  }

  public shouldChange(changes: {
    checklistRevisionId: angular.IChangesObject<Muid>;
    task: angular.IChangesObject<Task>;
  }): boolean {
    return (
      !!(changes.checklistRevisionId && changes.checklistRevisionId.currentValue) ||
      !!(changes.task && changes.task.currentValue)
    );
  }

  public $onChanges(changes: {
    checklistRevisionId: angular.IChangesObject<Muid>;
    task: angular.IChangesObject<Task>;
    disabled: angular.IChangesObject<boolean>;
  }) {
    const { checklistRevisionId, disabled, task } = changes;
    if (checklistRevisionId && checklistRevisionId.currentValue) {
      this.checklistRevisionId = checklistRevisionId.currentValue;
    }
    if (task && task.currentValue) {
      this.task = task.currentValue;
    }
    if (disabled && disabled.currentValue) {
      this.disabled = disabled.currentValue;
    }

    if (this.shouldChange(changes) && this.state && this.state.checklistRevision) {
      this.actions!.getAllApprovalRuleSubjectsByTemplateRevisionId(
        this.state!.checklistRevision.templateRevision.id,
      ).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading the approval subject tasks`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
      this.actions!.getAllApprovalsByChecklistRevisionId(this.state!.checklistRevision.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading the approval subject tasks`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
      this.actions!.getAllTaskTemplatesByTemplateRevisionId(this.state!.checklistRevision.templateRevision.id).catch(
        () => {
          this.toastService.openToast({
            status: 'error',
            title: `We're having problems loading the approval subject tasks`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
      this.getAllTasksAfterFormFieldUpdates();
    }
  }

  public rejectAll(subjectTasks: Task[], comment?: string) {
    const approvals = subjectTasks.map(subjectTask =>
      approvalService.resolveUpdatedApproval(
        this.state!.selectedOrganizationId,
        this.state!.approvals,
        this.task!,
        subjectTask,
        ApprovalStatus.Rejected,
        this.user!,
        comment,
      ),
    );

    this.$rootScope.$broadcast(TaskListEvent.TASK_APPROVAL_STATUS_CHANGED, {
      ...this.task,
      status: this.getOptmisticTaskStatus(approvals),
    });

    subjectTasks.forEach(subjectTask => {
      this.$rootScope.$broadcast(TaskListEvent.SUBJECT_TASK_REJECTED, {
        ...subjectTask,
        status: TaskStatus.NotCompleted,
      });
    });

    queryClient.invalidateQueries(
      GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId: this.checklistRevisionId! }),
    );

    // Allow redux updates to happen before updating the approvals
    this.$timeout(() => {
      this.$rootScope.$broadcast(StopTaskEvent.RESET_NON_COMPLETED_STOP_TASKS);
      this.actions!.upsertAllApprovals(this.checklistRevisionId!, approvals, this.task!.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems rejecting the tasks`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
      // @ts-expect-error -- TODO
      this.onReject();
    });
  }

  public approveAll(subjectTasks: Task[], comment?: string) {
    const approvals = subjectTasks.map(subjectTask =>
      approvalService.resolveUpdatedApproval(
        this.state!.selectedOrganizationId,
        this.state!.approvals,
        this.task!,
        subjectTask,
        ApprovalStatus.Approved,
        this.user!,
        comment,
      ),
    );

    this.$rootScope.$broadcast(TaskListEvent.TASK_APPROVAL_STATUS_CHANGED, {
      ...this.task,
      status: this.getOptmisticTaskStatus(approvals),
    });

    queryClient.invalidateQueries(
      GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId: this.checklistRevisionId! }),
    );

    // Allow redux updates to happen before updating the approvals
    this.$timeout(() => {
      this.$rootScope.$broadcast(StopTaskEvent.RESET_NON_COMPLETED_STOP_TASKS);
      this.actions!.upsertAllApprovals(this.checklistRevisionId!, approvals, this.task!.id).catch(() => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems accepting the tasks`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });
      // @ts-expect-error -- TODO
      this.onApprove();
    });
  }

  public openApprovalModal(status: ApprovalStatus) {
    this.approvalModalStatus = status;

    if (this.approvalModalIsOpen) {
      return false;
    }

    this.approvalModalInstance = this.$uibModal.open({
      animation: true,
      backdrop: 'static',
      controller: 'ApprovalSubjectTaskModalController',
      size: 'md',
      templateUrl: status === ApprovalStatus.Rejected ? rejectModalTemplateUrl : approveModalTemplateUrl,
    });

    this.approvalModalInstance.opened.then(() => {
      this.approvalModalIsOpen = true;
    });

    this.approvalModalInstance.closed.then(() => {
      this.approvalModalIsOpen = false;
    });

    this.approvalModalInstance.result.then((comment?: Record<string, unknown>) => {
      if (this.approvalModalStatus === ApprovalStatus.Rejected) {
        this.rejectAll(this.state!.awaitingTasks, comment ? (comment as unknown as string) : undefined);
      } else if (this.approvalModalStatus === ApprovalStatus.Approved) {
        this.approveAll(this.state!.awaitingTasks, comment ? (comment as unknown as string) : undefined);
      }
    });
  }

  getAllTasksAfterFormFieldUpdates() {
    const store = this.$ngRedux;
    // eslint-disable-next-line prefer-const -- legacy code
    let unsubscribe: Unsubscribe;
    let timeoutHandle: Option<NodeJS.Timeout>;
    let previousState: Option<ReduxAppState>;
    const listener = () => {
      const state: ReduxAppState = store.getState();
      const formFieldUpdatePending = FormFieldValueSelector.getUpdatePending(state);
      const formFieldUpdateWasPending = previousState && FormFieldValueSelector.getUpdatePending(previousState);
      const taskUpdatePending = TaskSelector.anyTaskUpdateInProgress(state);

      if ((formFieldUpdatePending || taskUpdatePending) && timeoutHandle) {
        clearTimeout(timeoutHandle);
        timeoutHandle = undefined;
      }
      // prevent content flash
      // give a grace period to start task update after a form field value update
      if (!formFieldUpdatePending && !taskUpdatePending && !timeoutHandle) {
        const GRACE_PERIOD = formFieldUpdateWasPending ? 100 : 0;
        timeoutHandle = setTimeout(() => {
          this.dispatchGetAllTasks();
          unsubscribe();
        }, GRACE_PERIOD);
      }
      previousState = state;
    };
    unsubscribe = store.subscribe(listener);
  }

  dispatchGetAllTasks() {
    this.actions!.getAllTasksByChecklistRevisionId(this.state!.checklistRevision.id).catch(() => {
      this.toastService.openToast({
        status: 'error',
        title: `We're having problems loading the approval subject tasks`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    });
  }

  getOptmisticTaskStatus(approvals: Approval[]) {
    return approvalService.getOptmisticTaskStatus({
      task: this.task!,
      approvals,
      $ngRedux: this.$ngRedux,
    });
  }
}

export const ApprovalTaskActionMenu: angular.IComponentOptions = {
  bindings: {
    checklistRevisionId: '<',
    disabled: '<',
    onApprove: '&',
    onReject: '&',
    task: '<',
  },
  controller: ApprovalTaskActionMenuController,
  templateUrl,
};

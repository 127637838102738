import * as React from 'react';
import { Box, Button, HStack, Icon, IconButton, Image, Text, VStack } from 'components/design/next';
import { MainStat } from './main-stat';
import { SecondaryStat } from './secondary-stat';
import { match, P } from 'ts-pattern';

export type AiGenerationWorkflowSummaryProps = {
  workflowName?: string;
  title?: React.ReactNode;
  subject: 'workflow' | 'task';
  onPublishAndRun?: () => Promise<void>;
  onClose?: () => void;
  onRegenerate?: () => void;
  stats?: {
    formFieldsCount: number;
    assigneesCount: number;
    tasksCount: number;
    stopTasksCount: number;
    dynamicDueDatesCount: number;
    approvalsCount: number;
    variablesCount: number;
  };
};

export const AiGenerationWorkflowSummary: React.FC<React.PropsWithChildren<AiGenerationWorkflowSummaryProps>> = ({
  workflowName,
  subject,
  stats,
  title,
  onClose,
  onPublishAndRun,
  onRegenerate,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handlePublishAndRun = async () => {
    if (onPublishAndRun) {
      setIsLoading(true);
      try {
        await onPublishAndRun?.();
        onClose?.();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRegenerate = () => {
    onRegenerate?.();
    onClose?.();
  };

  return (
    <VStack
      bgColor="white"
      position="relative"
      borderRadius="lg"
      px={{ base: '4', md: '10' }}
      py="6"
      spacing="4"
      shadow="md"
      w={{ base: 'full', md: '514px' }}
      justifyContent="space-between"
      mx="auto"
      ml="-5"
      mt="16"
    >
      <IconButton
        position="absolute"
        right="0"
        top="0"
        aria-label="Close"
        variant="ghost"
        _hover={{ bgColor: 'transparent' }}
        icon={<Icon icon="times" size="4" color="gray.500" />}
        onClick={onClose}
      />

      <VStack w="full" alignItems="center" justifyContent="center" spacing={0}>
        <Box flexBasis="17" minW="17">
          <Image src={require('app/images/ai-workflow-generation/process-pete-purple.png')} />
        </Box>
        <Text fontWeight="400" color="gray.700" mt={2}>
          {match({ title, workflowName })
            .with({ title: P.not(P.nullish) }, ({ title }) => title)
            .with({ workflowName: P.string }, ({ workflowName }) => `${workflowName} is all done!`)
            .otherwise(() => `Your ${subject} is all done!`)}
        </Text>
        <Text color="gray.500" fontSize="sm">
          It's ready for you to start running now or to edit further.
        </Text>
      </VStack>

      {stats && (
        <VStack spacing="4">
          <HStack w="full" py="4" spacing={{ base: '2', md: '4' }}>
            <MainStat label={stats.tasksCount !== 1 ? 'Tasks' : 'Task'}>{stats.tasksCount}</MainStat>
            <MainStat label={stats.assigneesCount !== 1 ? 'Assignments' : 'Assignment'}>
              {stats.assigneesCount}
            </MainStat>
            <MainStat label={stats.formFieldsCount !== 1 ? 'Form fields' : 'Form field'}>
              {stats.formFieldsCount}
            </MainStat>
          </HStack>

          <SecondaryStat
            icon="hand-paper"
            value={stats.stopTasksCount}
            title={stats.stopTasksCount !== 1 ? 'Stop tasks' : 'Stop task'}
            description="Ensure completion of necessary items before moving to the next task."
            docsUrl="https://www.process.st/help/docs/stop-tasks/"
          />
          <SecondaryStat
            icon="clock"
            value={stats.dynamicDueDatesCount}
            title={stats.dynamicDueDatesCount !== 1 ? 'Dynamic Due Dates' : 'Dynamic Due Date'}
            description="Activate by completing tasks  or specific dates."
            docsUrl="https://www.process.st/help/docs/dynamic-due-dates"
          />
          <SecondaryStat
            icon="thumbs-up"
            value={stats.approvalsCount}
            title={stats.approvalsCount !== 1 ? 'Approvals' : 'Approval'}
            description="Validate key content and data in your workflow, individually or in sequence."
            docsUrl="https://www.process.st/help/docs/approvals/"
          />
          <SecondaryStat
            icon="magic-wand-sparkles"
            value={stats.variablesCount}
            title={stats.variablesCount !== 1 ? 'Variables' : 'Variable'}
            description="Capture, store, and reuse data in workflows."
            docsUrl="https://www.process.st/help/docs/variables/"
          />
        </VStack>
      )}

      <HStack spacing="5" w="full" alignItems="center" justifyContent="space-between">
        {onRegenerate && (
          <Button
            variant="ghost"
            onClick={handleRegenerate}
            isDisabled={isLoading}
            loadingText="Regenerating..."
            fontWeight="400"
            _hover={{ backgroundColor: 'transparent' }}
            leftIcon={<Icon variant="far" size="4" icon="rotate-right" />}
          >
            Regenerate
          </Button>
        )}

        <HStack spacing="5" w="full" justifyContent="flex-end">
          {onPublishAndRun && (
            <Button
              isDisabled={isLoading}
              onClick={handlePublishAndRun}
              isLoading={isLoading}
              loadingText="Publishing..."
              variant="outline"
              borderWidth="thin"
              colorScheme="gray"
              fontWeight="normal"
              color="gray.600"
              leftIcon={<Icon variant="far" size="4" icon="play" />}
            >
              Publish and Run
            </Button>
          )}

          <Button isDisabled={isLoading} onClick={onClose}>
            Edit
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};

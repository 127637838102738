import * as yup from 'yup';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { isRowEmpty } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';

type ValidationSchemaProps = {
  required: boolean;
};

export const makeTableValidationSchema = ({ required }: ValidationSchemaProps) => {
  return required
    ? yup
        .array<TableFieldValue.Row>()
        .min(1, 'The table must contain at least 1 row.')
        .test(
          'At least 1 non-empty row',
          'The table must contain at least 1 non-empty row.',
          rows => rows?.some(row => !isRowEmpty(row)) ?? false,
        )
    : yup.array<TableFieldValue.Row>();
};

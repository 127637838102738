import { DataSetColumnState, SavedView } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type CreateSavedViewParams = {
  dataSetId: string;
  name: string;
  columns: DataSetColumnState[];
};

type CreateSavedViewResponse = SavedView;

const CreateSavedViewMutation = {
  mutationFn: (params: CreateSavedViewParams) =>
    axiosService
      .getAxios()
      .post(`/1/data-sets/${params.dataSetId}/saved-views`, {
        name: params.name,
        columns: params.columns,
      })
      .then(res => res.data),
};

export const useCreateSavedViewMutation = (
  options?: UseMutationOptions<CreateSavedViewResponse, AxiosError, CreateSavedViewParams>,
) => {
  return useMutation(CreateSavedViewMutation.mutationFn, options);
};

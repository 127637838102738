import * as yup from 'yup';
import { FormFieldValueSchemaTests } from '../common/validation/form-field-value-schema-tests';

type ValidationSchemaProps = {
  required: boolean;
};

export const makeSelectValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.array(yup.string().required()).test(FormFieldValueSchemaTests.requiredArray(required));
};

import { isDefined, Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { axiosService } from 'services/axios-service';
import {
  FormFieldWidget,
  NativeAutomation,
  NativeAutomationWithLink,
  TaskTemplate,
  TemplateRevision,
} from '@process-street/subgrade/process';

export namespace GetAllNativeAutomationsQuery {
  export type Params = { dataSetId?: Muid; templateRevisionId?: Muid };

  export type Response = NativeAutomationWithLink[];

  export const key = ['native-automations'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/native-automations`, {
        params,
      })
      .then(res => res.data);

  // Queries
  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.dataSetId || params.templateRevisionId) && options.enabled !== false,
    });
  };

  export type TaskTemplateAutomationMap = {
    [key: TaskTemplate['id']]: NativeAutomation[];
  };

  export const useGetTaskTemplateAutomationsMapQuery = ({
    templateRevisionId,
  }: {
    templateRevisionId?: TemplateRevision['id'];
  }): UseQueryResult<TaskTemplateAutomationMap | undefined> => {
    return GetAllNativeAutomationsQuery.useQuery(
      { templateRevisionId },
      {
        select: automations => makeTaskTemplateAutomationMap(automations),
      },
    );
  };

  /**
   * Assumes that there is only one automation per task template.  If there are more then one it returns the first.
   */
  export const useGetTaskTemplateAutomationQuery = ({
    templateRevisionId,
    taskTemplateId,
  }: {
    templateRevisionId?: TemplateRevision['id'];
    taskTemplateId?: TaskTemplate['id'];
  }): UseQueryResult<NativeAutomation | undefined> => {
    return GetAllNativeAutomationsQuery.useQuery(
      { templateRevisionId },
      {
        select: automations =>
          taskTemplateId ? makeTaskTemplateAutomationMap(automations)[taskTemplateId]?.[0] : undefined,
      },
    );
  };

  export type FormFieldWidgetAutomationMap = {
    [key: FormFieldWidget['id']]: NativeAutomation[];
  };

  /**
   * Assumes that there is only one automation per widget.  If there are more then one it returns the first.
   */
  export const useFormFieldWidgetAutomationQuery = ({
    templateRevisionId,
    formFieldWidgetId,
  }: {
    templateRevisionId?: Muid;
    formFieldWidgetId?: FormFieldWidget['id'];
  }): UseQueryResult<NativeAutomation | undefined> => {
    return GetAllNativeAutomationsQuery.useQuery(
      { templateRevisionId },
      {
        select: automations =>
          formFieldWidgetId ? makeWidgetAutomationMap(automations)[formFieldWidgetId]?.[0] : undefined,
        enabled: Boolean(formFieldWidgetId),
      },
    );
  };

  // Selectors
  export const makeTaskTemplateAutomationMap = (
    automations: GetAllNativeAutomationsQuery.Response,
  ): TaskTemplateAutomationMap => {
    return automations.reduce((acc, { automation, link }) => {
      if (isDefined(link.taskTemplateId)) {
        if (!acc[link.taskTemplateId]) acc[link.taskTemplateId] = [];
        acc[link.taskTemplateId].push(automation);
        return acc;
      } else {
        return acc;
      }
    }, {} as TaskTemplateAutomationMap);
  };

  export const makeWidgetAutomationMap = (
    automations: GetAllNativeAutomationsQuery.Response,
  ): FormFieldWidgetAutomationMap => {
    return automations.reduce((acc, { automation, link }) => {
      if (isDefined(link.formFieldWidgetId)) {
        if (!acc[link.formFieldWidgetId]) acc[link.formFieldWidgetId] = [];
        acc[link.formFieldWidgetId].push(automation);
        return acc;
      } else {
        return acc;
      }
    }, {} as FormFieldWidgetAutomationMap);
  };

  // Optimistic updaters
  export const taskTemplateAutomationUpdater =
    (taskTemplateId: Muid, updatedAutomation: NativeAutomation) => (data?: GetAllNativeAutomationsQuery.Response) => {
      return (data ?? []).map(automationWithLink => {
        if (automationWithLink.link.taskTemplateId === taskTemplateId) {
          automationWithLink.automation = updatedAutomation;
        }
        return automationWithLink;
      });
    };
}

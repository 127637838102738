import * as React from 'react';
import { TemplateType } from '@process-street/subgrade/process';
import { PageContentActorRef } from './page-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { CrossLinkCardSearch } from 'app/components/widgets/cross-link/search';
import { TemplateTypeProvider } from 'app/utils/template/template-type-context';
import { Muid } from '@process-street/subgrade/core';
import { CrossLinkCardDisplay } from 'app/components/widgets/cross-link/display';

export interface PageContentProps {
  actor: PageContentActorRef;
  isFirst?: boolean;
  isLast?: boolean;
}

export const PageContent: React.FC<React.PropsWithChildren<PageContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, recentlyMovedFrom } = state.context;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        <TemplateTypeProvider templateType={TemplateType.Page}>
          {widget.templateId ? (
            <CrossLinkCardDisplay templateId={widget.templateId} />
          ) : (
            <CrossLinkCardSearch
              onSelect={(templateId: Muid) => send({ type: 'CHANGE', templateId })}
              onFocus={() => send('FOCUS')}
            />
          )}
        </TemplateTypeProvider>
        <WidgetListItemDragIcon />
        <FormsWidgetMenu>
          <FormsWidgetMenuItems.Duplicate />
          <FormsWidgetMenuItems.MoveToStep widget={widget} />
          <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
          <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
          <FormsWidgetMenuItems.Delete />
        </FormsWidgetMenu>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

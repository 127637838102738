import angular from 'angular';
import { WidgetMenuModule } from './components/widget-menu/widget-menu.module';
import { AssignmentPickerModule } from './components/assignment-picker/index.module';
import { CreateTaskMenuModule } from './components/create-task-menu/create-task-menu.module';
import { TaskTemplateConditionalLogicButtonModule } from './components/task-template-conditional-logic-button/task-template-conditional-logic-button.module';
import { TaskTemplateAutomationsButtonModule } from './components/task-template-automations-button/task-template-automations-button.module';
import { WidgetConditionalLogicButtonModule } from './components/widget-conditional-logic-button/widget-conditional-logic-button.module';
import { EmptyTaskTemplateModule } from './components/empty-task-template/empty-task-template.module';
import { TaskButtonStackModule } from './components/task-button-stack/task-button-stack.module';
import { AiTaskTemplateFormModule } from './components/ai-task-template-form/ai-task-template-form.module';
import { AiTaskTemplateWidgetIndicatorModule } from './components/ai-task-template-widget-indicator/ai-task-template-widget-indicator.module';
import { reactToAngular } from 'angulareact';
import { DefaultValueField } from './components/default-value-field';
import { TemplateDashboardModule } from './dashboard/index.module';

export const TemplatePageModule = angular
  .module('templatePage', [
    AssignmentPickerModule,
    EmptyTaskTemplateModule,
    CreateTaskMenuModule,
    TaskTemplateConditionalLogicButtonModule,
    TaskTemplateAutomationsButtonModule,
    WidgetConditionalLogicButtonModule,
    WidgetMenuModule,
    TaskButtonStackModule,
    AiTaskTemplateFormModule,
    AiTaskTemplateWidgetIndicatorModule,
    TemplateDashboardModule,
  ])
  .component('psTemplateWidgetDefaultValueField', reactToAngular(DefaultValueField, ['widget'])).name;

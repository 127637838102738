import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { FieldType, FormFieldWidgetOfType } from '@process-street/subgrade/process';
import { getWidgetModalsMachineService } from 'app/features/widgets/widget-modals-machine';
import { useDataSetButton } from 'app/pages/templates/_id/components/widget-menu/use-data-set-button';
import { match } from 'ts-pattern';

export type ConnectDataSetMenuItemProps<Type extends FieldType> = MenuItemProps & {
  widget: FormFieldWidgetOfType<Type>;
};

const widgetModalsActor = getWidgetModalsMachineService();

export const ConnectDataSetMenuItem: React.FC<ConnectDataSetMenuItemProps<FieldType>> = ({ widget }) => {
  const { dataSetButtonToShow } = useDataSetButton(widget);

  return (
    <>
      {match(dataSetButtonToShow)
        .with('link', () => (
          <MenuItem
            onClick={() => widgetModalsActor.send({ type: 'OPEN_CONNECT_DATA_SET', widget })}
            icon={<Icon icon="database" variant="far" size="4" />}
          >
            Connect Data Set
          </MenuItem>
        ))
        .with('unlink', () => (
          <MenuItem
            icon={
              <Icon
                aria-description="This widget is connected to a data set"
                color="purple.500"
                icon="database"
                size="4"
                variant="far"
              />
            }
            onClick={() => widgetModalsActor.send({ type: 'OPEN_UNLINK_DATA_SET', widget })}
          >
            Disconnect Data Set
          </MenuItem>
        ))
        .otherwise(() => null)}
    </>
  );
};

import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Text,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import * as React from 'react';
import { match } from 'ts-pattern';

export type NeedPaidFor = 'modifyPermission' | 'performAction';

export type PaidPlanRequiredPopoverProps = PopoverProps & {
  wrapContentWithPortal?: boolean;
  needPaidFor: NeedPaidFor;
};

export const PaidPlanRequiredPopover: React.FC<PaidPlanRequiredPopoverProps> = ({
  wrapContentWithPortal = false,
  needPaidFor,
  children,
  ...props
}) => {
  const text = match(needPaidFor)
    .with('modifyPermission', () => 'modify this permission')
    .with('performAction', () => 'perform this action')
    .otherwise(() => 'modify this permission');

  return (
    <Popover trigger="hover" size="auto" variant="tooltip-dark" {...props}>
      <PopoverTrigger>
        {/* wrap children in Box because popover hover doesn't work with disabled buttons */}
        {React.isValidElement(children) ? (
          <Box>{children}</Box>
        ) : (
          <Box w="full" top="0" left="0" h="full" position="absolute" />
        )}
      </PopoverTrigger>
      {wrapContentWithPortal ? (
        <Portal>
          <PaidPlanRequiredPopoverContent text={text} zIndex="popover" />
        </Portal>
      ) : (
        <PaidPlanRequiredPopoverContent text={text} />
      )}
    </Popover>
  );
};

const PaidPlanRequiredPopoverContent: React.FC<React.PropsWithChildren<PopoverContentProps & { text: string }>> = ({
  text,
  ...props
}) => {
  const { $state } = useInjector('$state');

  const handleUpgradeHere = () => {
    $state.go('organizationManage.tab', { tab: 'billing' });
  };

  return (
    <PopoverContent w="auto" border="0" {...props}>
      <PopoverArrow />
      <PopoverBody>
        You need a paid account to {text}, upgrade{' '}
        <Text as="u" _hover={{ cursor: 'pointer' }} onClick={handleUpgradeHere}>
          here.
        </Text>
      </PopoverBody>
    </PopoverContent>
  );
};

import * as React from 'react';
import {
  Grid,
  GridItem,
  VStack,
  Stack,
  Text,
  Button,
  Link,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Wrap,
  Tooltip,
  WrapItem,
  Box,
  IconButton,
  ButtonGroup,
} from 'components/design/next';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import {
  TrayAutomationAppName,
  IncomingWebhook,
  isTemplateSolutionInstance,
  SolutionTypeTag,
  isIncomingWebhookAppName,
  isTrayAutomationAppName,
  getAutomationAppNameLabel,
  AutomationAppName,
  isAutomationInstance,
  withTrayPrefix,
  withIncomingWebhookPrefix,
  stripAutomationAppNamePrefix,
} from '@process-street/subgrade/automation';
import {
  GetAllIncomingWebhooksByTemplateIdQuery,
  GetAllIncomingWebhooksByTemplateIdQueryResponse,
  useCreateIncomingWebhookMutation,
  useSolutionsByTemplateIdQuery,
} from 'features/automations/query-builder';
import { useTemplateId } from '../../automation/utils/use-template-id';
import { AutomationAppSquare } from '../automation-app-square';
import { useQueryClient } from 'react-query';
import { MuidUtils, UserDetailUseCase, USE_CASE_OPTIONS } from '@process-street/subgrade/core';
import { useAutomationsModalEvents } from 'features/automations/components/workflow-automations/components/common/context';
import { FeaturedIncomingWebhooks } from 'features/automations/featured-incoming-webhook-apps';
import { match } from 'ts-pattern';
import { useGetUserDetailsQuery } from 'features/user/query-builder/user-details';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import {
  GetTemplateQuery,
  GetTemplateQueryResponse,
  useGetTemplateQuery,
  useUpdateTemplateMutation,
} from 'features/template/query-builder';
import {
  NativeTemplateTrigger,
  NATIVE_TEMPLATE_TRIGGERS,
  Template,
  TraySolutionIdTrigger,
  TraySolutionTriggerUtils,
} from '@process-street/subgrade/process';
import produce from 'immer';
import { TriggerIcons, TriggerLabels } from './native-trigger-data';
import { useUnconfiguredInstances } from './use-unconfigured-instances';
import { noop } from '@process-street/subgrade/util';
import { sortAutomationAppNames } from './choose-template-triggers.utils';

export interface ChooseTemplateTriggersProps {
  onSelect: (
    selected:
      | { type: 'webhook'; webhook: IncomingWebhook }
      | { type: 'tray'; trayAutomationAppName: TrayAutomationAppName }
      | { type: 'native'; trigger: Template['preferredTriggers'][number] },
  ) => void;
  featuredAppCount?: number;
}

const NATIVE = 'native';
const WEBHOOKS = 'webhooks';
const FALLBACK = 'fallback';

export const ChooseTemplateTriggers: React.FC<React.PropsWithChildren<ChooseTemplateTriggersProps>> = ({
  onSelect,
  featuredAppCount,
}) => {
  const templateId = useTemplateId();

  const solutionsQuery = useSolutionsByTemplateIdQuery({ templateId });
  const unconfiguredInstances = useUnconfiguredInstances();

  const trayTriggers =
    solutionsQuery.data
      ?.filter(solution => solution.solutionTypeTag === SolutionTypeTag.CreateChecklistWhen)
      .map(solution => withTrayPrefix(solution.automationApp)) ?? [];

  const webhookTriggers = FeaturedIncomingWebhooks.enabledAppNames
    .slice(0, featuredAppCount)
    .map(withIncomingWebhookPrefix);

  const allTriggersAppNames = Array.from(new Set([...trayTriggers, ...webhookTriggers]));

  const userId = useSelector(SessionSelector.getCurrentUserId);
  const userDetailsQuery = useGetUserDetailsQuery(userId!, {
    // can 404
    retry: 0,
  });
  const userUseCase = userDetailsQuery.data?.useCase as UserDetailUseCase | undefined;

  const [useCase, setUseCase] = React.useState<UserDetailUseCase | ''>('');

  const sortedTriggers = sortAutomationAppNames({
    automationAppNames: allTriggersAppNames,
    hasUnconfiguredInstance: app => Boolean(unconfiguredInstances.getInstanceFromApp(app)),
    matchesUserUseCase: app => {
      return FeaturedIncomingWebhooks.getAutomationAppNameMatchesUseCase({
        automationAppName: app,
        useCase: userUseCase,
      });
    },
    matchesUseCaseFilter: app => {
      return (
        useCase === '' ||
        FeaturedIncomingWebhooks.getAutomationAppNameMatchesUseCase({ automationAppName: app, useCase })
      );
    },
  });

  const [appQuery, setAppQuery] = React.useState('');

  const visibleApps = sortedTriggers.filter(automationApp => {
    const label = getAutomationAppNameLabel(automationApp);
    const labelMatch = label.toLowerCase().includes(appQuery.toLowerCase());
    return labelMatch;
  });

  const queryClient = useQueryClient();
  const createWebhookMutation = useCreateIncomingWebhookMutation({
    onSuccess: webhook => {
      queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
        GetAllIncomingWebhooksByTemplateIdQuery.getKey({ templateId }),
        webhooks => [...(webhooks ?? []), webhook],
      );

      onSelect({ type: 'webhook', webhook });
    },
  });

  const { openAutomationInstance, openSolutionTypeTag } = useAutomationsModalEvents();
  const preselectTrigger = (automationApp: AutomationAppName) => {
    if (!templateId) return;

    const unconfiguredInstance = unconfiguredInstances.getInstanceFromApp(automationApp);

    if (isAutomationInstance(unconfiguredInstance)) {
      openAutomationInstance({
        id: unconfiguredInstance.id,
        automationType: isTemplateSolutionInstance(unconfiguredInstance) ? 'tray' : 'webhook',
        modalView: 'all',
        solutionTypeTag: SolutionTypeTag.CreateChecklistWhen,
      });
      return;
    }

    if (TraySolutionTriggerUtils.isTraySolutionIdTrigger(unconfiguredInstance)) {
      openSolutionTypeTag(SolutionTypeTag.CreateChecklistWhen, automationApp);
      return;
    }

    if (isTrayAutomationAppName(automationApp)) {
      const solutionId = solutionsQuery.data?.find(s => withTrayPrefix(s.automationApp) === automationApp)?.id;
      if (solutionId) {
        setTemplatePreferredTriggers({
          type: 'tray',
          trigger: TraySolutionTriggerUtils.idToTrigger(solutionId),
          action: 'add',
          trayAutomationAppName: automationApp,
        });
      }
    } else if (isIncomingWebhookAppName(automationApp)) {
      createWebhookMutation.mutate({
        id: MuidUtils.randomMuid(),
        automationApp: stripAutomationAppNamePrefix(automationApp),
        templateId,
        name: '',
        config: { checklistProperties: {}, fields: {} },
        status: 'Disabled',
        webhookType: 'Workflow',
      });
    }
  };

  const templateQuery = useGetTemplateQuery({ templateId });
  const preferredTriggers = new Set(templateQuery.data?.preferredTriggers ?? []);

  const updateTemplateMutation = useUpdateTemplateMutation<Template['preferredTriggers']>({
    onMutate: variables => {
      if (!templateQuery.data) return;
      queryClient.setQueryData<GetTemplateQueryResponse | undefined>(
        GetTemplateQuery.getKey({ templateId }),
        current => {
          if (!current) return;
          return {
            ...current,
            preferredTriggers: variables.preferredTriggers ?? [],
          };
        },
      );
      return templateQuery.data.preferredTriggers ?? [];
    },
    onError: (_err, _vars, ctx) => {
      queryClient.setQueryData<GetTemplateQueryResponse | undefined>(
        GetTemplateQuery.getKey({ templateId }),
        current => {
          if (!current) return;
          return {
            ...current,
            preferredTriggers: ctx ?? [],
          };
        },
      );
    },
  });

  const setTemplatePreferredTriggers = (
    data: { action: 'add' | 'remove' } & (
      | { trigger: NativeTemplateTrigger; type: 'native' }
      | { trigger: TraySolutionIdTrigger; trayAutomationAppName: TrayAutomationAppName; type: 'tray' }
    ),
  ) => {
    if (!templateQuery.data) return;

    const updated = produce(preferredTriggers, draft => {
      match(data.action)
        .with('add', () => draft.add(data.trigger))
        .with('remove', () => draft.delete(data.trigger))
        .run();
    });

    updateTemplateMutation.mutate(
      { templateId: templateQuery.data.id, name: templateQuery.data.name, preferredTriggers: [...updated] },
      {
        onSuccess: () => {
          match(data)
            .with({ type: 'native' }, ({ trigger }) => {
              onSelect({ type: 'native', trigger });
            })
            .with({ type: 'tray' }, ({ trayAutomationAppName }) => {
              onSelect({ type: 'tray', trayAutomationAppName });
            })
            .otherwise(noop);
        },
      },
    );
  };

  return (
    <Grid
      templateAreas={{
        base: `
        "${WEBHOOKS}"
        "${NATIVE}"
        "${FALLBACK}"
        `,
        md: `
        "${NATIVE} ${WEBHOOKS}"
        "${FALLBACK} ${FALLBACK}"
        `,
      }}
      templateColumns={{ base: '1fr', md: 'var(--ps-sizes-45) 1fr' }}
      templateRows={{ base: '338px auto auto', md: '338px var(--ps-space-30)' }}
      gridGap={{ base: '4', md: '0' }}
    >
      <GridItem
        area="native"
        borderRightWidth={{ base: '0', md: 'px' }}
        borderTopWidth={{ base: 'px', md: '0' }}
        borderLeftWidth="0"
        borderBottomWidth="0"
        borderStyle="solid"
        borderColor="gray.200"
        pt={{ base: '6', md: '0' }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing={{ base: '0', md: '3' }}
          pr={{ base: '0', md: '7' }}
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          gap={{ base: '1', md: '0' }}
        >
          {NATIVE_TEMPLATE_TRIGGERS.map(trigger => {
            const isSelected = preferredTriggers.has(trigger);
            return (
              <ButtonGroup
                {...{
                  key: trigger,
                  isAttached: true,
                  h: '38px',
                  width: { base: 'auto', md: 'full' },
                  flex: { base: '1 1 45%', md: 'inherit' },
                }}
              >
                <Button
                  {...{
                    ...(isSelected
                      ? { _hover: {}, _active: {}, cursor: 'default' }
                      : {
                          onClick: () => {
                            trigger === 'webhook'
                              ? preselectTrigger(withIncomingWebhookPrefix('Webhook'))
                              : setTemplatePreferredTriggers({ type: 'native', trigger, action: 'add' });
                          },
                        }),
                    flex: '1',
                    h: 'full',
                    justifyContent: 'flex-start',
                    variant: 'outline',
                    fontWeight: 'normal',
                    iconSpacing: '2',
                    leftIcon: (
                      <Icon icon={TriggerIcons[trigger]} size="4" color={isSelected ? 'purple.500' : 'gray.400'} />
                    ),
                    borderRadius: 'lg',
                    borderWidth: 'px',
                    fontSize: 'sm',
                    colorScheme: 'gray',
                  }}
                >
                  {TriggerLabels[trigger]}
                </Button>

                {isSelected ? (
                  <IconButton
                    {...{
                      'colorScheme': 'gray',
                      'borderRadius': 'lg',
                      'borderWidth': 'px',
                      'borderLeftWidth': '0',
                      'h': 'full',
                      'size': 'sm',
                      'variant': 'outline',
                      'bg': 'white',
                      'aria-label': 'remove preferred trigger',
                      'icon': <Icon icon="close" variant="far" size="3" />,
                      'onClick': () => setTemplatePreferredTriggers({ type: 'native', trigger, action: 'remove' }),
                      'minW': '5',
                    }}
                  />
                ) : null}
              </ButtonGroup>
            );
          })}
        </Stack>
      </GridItem>
      <GridItem
        as={VStack}
        alignItems="stretch"
        area="webhooks"
        pl={{ base: '0', md: '7' }}
        spacing={{ base: '4', md: '6' }}
      >
        {sortedTriggers.length > 8 ? (
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '2', md: '4' }}
            justifyContent={{ base: 'flex-start', md: 'space-between' }}
          >
            <InputGroup w={{ base: 'full', md: '55%' }}>
              <InputLeftElement>
                <Icon icon="search" size="4" variant="far" />
              </InputLeftElement>
              <Input placeholder="Search apps" value={appQuery} onChange={e => setAppQuery(e.target.value)} />
              {appQuery.length > 0 ? (
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    size="xs"
                    aria-label="clear search"
                    icon={<Icon icon="close" size="4" variant="far" />}
                    onClick={() => setAppQuery('')}
                  />
                </InputRightElement>
              ) : null}
            </InputGroup>

            <Select
              w={{ base: 'full', md: '45%' }}
              placeholder="All Departments"
              value={useCase}
              onChange={e => setUseCase(e.target.value as typeof USE_CASE_OPTIONS[number])}
              fontSize="sm"
            >
              {USE_CASE_OPTIONS.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </Stack>
        ) : null}

        {visibleApps.length === 0 && appQuery.length > 0 ? (
          <VStack>
            <AutomationAppSquare automationApp={withIncomingWebhookPrefix('Webhook')} />
            <VStack>
              <Text variant="-1">
                Looks like{' '}
                <Text as="b" variant="inherit">
                  {appQuery}
                </Text>{' '}
                is not available.
              </Text>
              <Text variant="-2" color="gray.500">
                Why don&apos;t you try our custom{' '}
                <Button
                  fontSize="xs"
                  fontWeight="normal"
                  variant="link"
                  onClick={() => preselectTrigger(withIncomingWebhookPrefix('Webhook'))}
                  color="brand.500"
                  colorScheme="gray"
                >
                  Webhook
                </Button>
                ?
              </Text>
            </VStack>
          </VStack>
        ) : null}

        <Wrap spacing={{ base: '2', md: '6' }} flex="1" overflowY="auto" overflowX="hidden">
          {visibleApps.map(automationApp => {
            const unconfiguredInstance = unconfiguredInstances.getInstanceFromApp(automationApp);
            const hasWarning = Boolean(unconfiguredInstance);
            const label = getAutomationAppNameLabel(automationApp);

            return (
              <WrapItem key={automationApp}>
                <VStack
                  maxW={{ base: '18', sm: '21' }}
                  as={Button}
                  variant="unstyled"
                  onClick={() => preselectTrigger(automationApp)}
                  _hover={{
                    transform: 'scale(1.05)',
                    transition: 'transform 0.2s',
                  }}
                >
                  <Tooltip
                    isDisabled={!hasWarning}
                    hasArrow
                    label={`Remember to setup your ${label} automation`}
                    w="full"
                  >
                    <Box as="span" w="full">
                      <AutomationAppSquare
                        outerSquareProps={{ size: { base: '18', sm: '21' } }}
                        innerSquareProps={{ size: { base: '10', sm: '12' } }}
                        automationApp={automationApp}
                        hasWarning={hasWarning}
                      />
                      <Text noOfLines={2} whiteSpace="normal" variant="-1" mt="2" fontWeight="medium">
                        {label}
                      </Text>
                    </Box>
                  </Tooltip>
                </VStack>
              </WrapItem>
            );
          })}
        </Wrap>
      </GridItem>
      <GridItem area="fallback" mt="6">
        <VStack spacing="4">
          <Text variant="-2u" color="gray.400" fontWeight="bold">
            don&apos;t see your app?
          </Text>

          <Stack
            direction={{ base: 'column', sm: 'row' }}
            w="full"
            spacing="3"
            justifyContent={{ base: 'center', sm: 'space-around' }}
          >
            <Button
              as={Link}
              fontWeight="normal"
              color="gray.600"
              variant="secondary"
              href="https://www.process.st/help/docs/make/"
              isExternal
              bg="brand.50"
              w={{ base: 'full', sm: '33%', md: '51' }}
              _hover={{ textDecor: 'none', bg: 'gray.200' }}
              leftIcon={<AutomationAppIcon w="4" automationApp={withTrayPrefix('Make')} />}
            >
              Make
            </Button>

            <Button
              as={Link}
              fontWeight="normal"
              color="gray.600"
              variant="secondary"
              href="https://www.process.st/help/docs/zapier/"
              isExternal
              bg="brand.50"
              w={{ base: 'full', sm: '33%', md: '51' }}
              _hover={{ textDecor: 'none', bg: 'gray.200' }}
              leftIcon={<AutomationAppIcon w="4" automationApp={withTrayPrefix('Zapier')} />}
            >
              Zapier
            </Button>

            <Button
              as={Link}
              fontWeight="normal"
              color="gray.600"
              variant="secondary"
              href="https://www.process.st/help/docs/microsoft-power-automate/"
              isExternal
              bg="brand.50"
              w={{ base: 'full', sm: '33%', md: '51' }}
              _hover={{ textDecor: 'none', bg: 'gray.200' }}
              leftIcon={<AutomationAppIcon w="4" automationApp={withTrayPrefix('PowerAutomate')} />}
            >
              Power Automate
            </Button>
          </Stack>
        </VStack>
      </GridItem>
    </Grid>
  );
};

import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { TaskStatus } from '@process-street/subgrade/process';
import { DateFormat } from '@process-street/subgrade/util';
import { getAvatar } from 'components/common/Avatar';
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
  VStack,
  useBreakpointValue,
} from 'components/design/next';
import dayjs from 'dayjs';
import { useUserByIdQuery } from 'features/user/query-builder';
import { useCurrentUser } from 'hooks/use-current-user';
import * as React from 'react';
import { useCanCompleteOneOffTask, useOneOffTaskTopbar, useCompleteTaskAndOpenNext } from '../shared/hooks';
import { AssigneeListButton } from './assignee-list-button';

import { AssignmentPicker } from '../shared/assignment-picker';
import { DueDatePicker } from '../shared/due-date-picker';

import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { TopbarMenu } from '../shared/topbar-menu';
import { Spacer } from '@chakra-ui/react';
import { AddAttachmentButton, AttachmentsList } from '../shared/attachments';
import { useOneOffTaskAttachments } from '../shared/hooks/use-one-off-task-attachments';
import { TaskComments } from 'features/comments/components/task';

import { useFeatureFlag } from 'features/feature-flags';
import DOMPurify from 'dompurify';
import { SubTasks } from '../sub-tasks';

export type ViewDrawerContentProps = {
  task: OneOffTask;
};

const ALLOWED_ATTR = ['style', 'href', 'target', 'mailto'];

const sanitizeHTML = (input: string) =>
  DOMPurify.sanitize(input, {
    ALLOWED_ATTR,
  });

export const ViewDrawerContent: React.FC<React.PropsWithChildren<ViewDrawerContentProps & BoxProps>> = ({
  task,
  ...props
}) => {
  const currentUser = useCurrentUser();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const isTasksGAEnabled = useFeatureFlag('tasksGA');
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');

  const canCompleteOneOffTask = useCanCompleteOneOffTask(task);
  const { canUploadAttachments, onAddAttachment, checklistRevision } = useOneOffTaskAttachments(task);

  const assignedByUserQuery = useUserByIdQuery({ id: task.audit.createdBy.id });
  const assignedByUserAvatar = getAvatar(assignedByUserQuery.data);

  const isCurrentUserTaskCreator = currentUser?.id === task.audit.createdBy.id;

  const {
    assignedMemberships,
    availableMemberships,
    handleMembershipAssignment,
    handleMembershipRemoval,
    handleDueDateUpdate,
    isDisabled: isTopbarDisabled,
  } = useOneOffTaskTopbar(task);

  const { onClose, setMode } = useOneOffTaskDrawerStore();

  const updateTaskMutation = useCompleteTaskAndOpenNext(task);

  const formattedDate =
    task.dueDate && dayjs(task.dueDate).tz(currentUser?.timeZone).format(DateFormat.DateTimeMergeTagLong);

  const handleOnComplete = () => {
    updateTaskMutation.mutate({ taskId: task.id, status: TaskStatus.Completed });
  };

  const handleOnEdit = () => {
    setMode('edit');
  };

  const isCompleteDisabled = !canCompleteOneOffTask || task.status === TaskStatus.Completed;

  const sanitizedDescription = sanitizeHTML(task.description);

  const createdByUsername = isMobile ? '' : assignedByUserQuery.data?.username;

  return (
    <Box {...props}>
      <DrawerCloseButton onClick={() => onClose()}>
        <Icon icon="times" size="4" color="gray.400" />
      </DrawerCloseButton>
      <DrawerHeader px="8" py="5">
        <Text variant="-1u" textTransform="uppercase" fontWeight="bold" color="gray.500">
          {task.name}
        </Text>
      </DrawerHeader>

      <Divider />

      <DrawerBody flex="unset" pt="5" px="8" overflow="unset">
        <HStack justifyContent="space-between" flexWrap="wrap">
          {isTopbarDisabled && (
            <HStack spacing="4">
              <HStack shouldWrapChildren spacing="0">
                <Icon icon="user" variant="far" size="4" mt="1" />
                <AssigneeListButton assignedMemberships={assignedMemberships} />
              </HStack>
              {task.dueDate && (
                <HStack>
                  <Icon icon="clock" size="4" color="purple.500" />
                  <Text color="gray.500" variant="outline">
                    {formattedDate}
                  </Text>
                </HStack>
              )}
            </HStack>
          )}
          {!isTopbarDisabled && (
            <HStack spacing="4" alignItems="flex-start">
              <AssignmentPicker
                onSelect={handleMembershipAssignment}
                onRemove={handleMembershipRemoval}
                assignedMemberships={assignedMemberships}
                availableMemberships={availableMemberships}
              />
              <DueDatePicker
                timeZone={currentUser?.timeZone}
                value={task.dueDate}
                onChange={handleDueDateUpdate}
                buttonProps={{ fontWeight: isMyWorkGAEnabled ? 'normal' : 'inherit' }}
              />
              <TopbarMenu task={task} />
            </HStack>
          )}

          {isTopbarDisabled && !isTasksGAEnabled && assignedByUserQuery.data && (
            <Text fontStyle="italic" color="gray.500">
              Assigned by <Avatar size="xs" src={assignedByUserAvatar.url} name={assignedByUserQuery.data.username} />{' '}
              {assignedByUserQuery.data.username}
            </Text>
          )}

          <HStack spacing="2">
            {isTasksGAEnabled && !isCurrentUserTaskCreator && assignedByUserQuery.data && (
              <Text fontStyle="italic" color="gray.500">
                Created by <Avatar size="xs" src={assignedByUserAvatar.url} name={assignedByUserQuery.data.username} />{' '}
                {createdByUsername}
              </Text>
            )}
            {!isTopbarDisabled && (
              <IconButton
                variant="outline"
                size="sm"
                bgColor="white"
                borderWidth="px"
                borderColor="gray.300"
                aria-label="Edit Task"
                onClick={handleOnEdit}
                icon={<Icon icon="edit" color="gray.600" variant="far" size="4" />}
              />
            )}
          </HStack>
        </HStack>

        <VStack spacing="6" alignItems="flex-start" pt="6">
          <VStack alignItems="flex-start" w="full">
            <Text fontWeight="500" color="gray.600">
              Description
            </Text>
            {isTasksGAEnabled ? (
              <Box
                fontSize="md"
                dangerouslySetInnerHTML={{
                  __html: sanitizedDescription,
                }}
                w="full"
              ></Box>
            ) : (
              <Text color="gray.600" whiteSpace="pre-line">
                {task.description}
              </Text>
            )}
          </VStack>
          <AttachmentsList task={task} />
          {canUploadAttachments && <AddAttachmentButton task={task} onAddAttachment={onAddAttachment} />}
        </VStack>

        <SubTasks task={task} />
      </DrawerBody>
      <DrawerFooter>
        <VStack w="full" spacing="6">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            w="full"
            justifyContent={{ base: 'flex-start', md: 'space-between' }}
            alignItems={{ base: 'inherit', md: 'center' }}
            spacing="2"
          >
            {task.linkedChecklist ? (
              <HStack>
                <Text color="gray.500" fontStyle="italic">
                  Attached&nbsp;to
                </Text>
                <Link color="gray.500" href={`/checklists/${task.linkedChecklist.id}`} isExternal>
                  <Icon variant="far" icon="play" size="4" color="purple.500" mr={2} />
                  {task.linkedChecklist.name}
                </Link>
              </HStack>
            ) : (
              <Spacer />
            )}

            <Tooltip
              hasArrow
              shouldWrapChildren
              isDisabled={canCompleteOneOffTask}
              label="Only the assignee can complete this task"
            >
              <Button variant="primary" onClick={handleOnComplete} disabled={isCompleteDisabled}>
                {task.status === TaskStatus.Completed ? 'Completed' : 'Complete'}
              </Button>
            </Tooltip>
          </Stack>
          {checklistRevision && (
            <TaskComments
              checklistRevision={checklistRevision}
              taskId={task.id}
              activeStepId={task.taskTemplateGroupId}
              fontSize="md"
            />
          )}
        </VStack>
      </DrawerFooter>
    </Box>
  );
};

import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { ConditionalLogicButtonWrapper } from 'app/pages/templates/_id/components/conditional-logic-button-wrapper';
import * as React from 'react';
import { Icon, IconButton, Tooltip } from 'components/design/next';

export type TaskActionsConditionalLogicButtonProps = {
  templateRevision: TemplateRevision;
  taskTemplate: TaskTemplate;
  hasConditionalLogic: boolean;
  isEditable: boolean;
};

export const TaskActionsConditionalLogicButton = ({
  templateRevision,
  taskTemplate,
  hasConditionalLogic,
  isEditable,
}: TaskActionsConditionalLogicButtonProps) => {
  return (
    <Tooltip hasArrow shouldWrapChildren label="Conditional logic">
      <ConditionalLogicButtonWrapper taskTemplate={taskTemplate} templateRevision={templateRevision}>
        <IconButton
          {...{
            'size': 'sm',
            'aria-label': hasConditionalLogic ? 'Conditional logic applied' : 'Conditional logic',
            'borderWidth': 'px',
            'borderColor': 'gray.300',

            ...(isEditable
              ? {
                  variant: 'outline',
                  icon: (
                    <Icon
                      icon="shuffle"
                      variant="far"
                      size="4"
                      color={hasConditionalLogic && isEditable ? 'purple.500' : 'gray.400'}
                    />
                  ),
                }
              : {
                  variant: 'ghost',
                  isDisabled: true,
                  pointerEvents: 'none',
                  icon: <Icon icon="shuffle" variant="far" size="4" color="gray.400" />,
                }),
          }}
        />
      </ConditionalLogicButtonWrapper>
    </Tooltip>
  );
};

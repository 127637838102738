import * as React from 'react';
import { Icon, Button } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { useCreateSandboxAndRedirect } from 'hooks/use-create-sandbox-and-redirect';
import { useMatch } from '@process-street/adapters/navigation';

export interface SandboxButtonProps {
  template: Template;
}

export const SandboxButton: React.FC<React.PropsWithChildren<SandboxButtonProps>> = ({ template }) => {
  const runChecklistAndRedirect = useCreateSandboxAndRedirect({
    templateName: template?.name,
    templateId: template?.id,
    openInNewTab: true,
  });
  const isEditorV2 = useMatch('templateV2');
  return (
    <Button
      mr={2}
      aria-label="Sandbox"
      onClick={runChecklistAndRedirect}
      borderColor="gray.600"
      variant="outline"
      colorScheme="gray"
      color="white"
      fontSize={isEditorV2 ? 'md' : 'sm'}
      px={4}
      _hover={{ bgColor: 'gray.500' }}
      _active={{ bgColor: 'gray.500' }}
      _focus={{ bgColor: 'gray.500' }}
    >
      <Icon icon="eye" variant="far" size="4" color="white" mr={2} />
      Preview
    </Button>
  );
};

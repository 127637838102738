import * as React from 'react';
import { useToast } from 'components/design/next';
import {
  useGetTemplateSubscriptionsQuery,
  useSubscribeToTemplateMutation,
  useUnsubscribeFromTemplateMutation,
} from 'features/subscription/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import { useTemplateMenuContext } from '..';
import { AnalyticsService } from 'components/analytics/analytics.service';

export type SubscribeButtonProps = {
  children: (props: {
    isSubscribed: boolean;
  }) => React.ReactElement<{ onClick: React.MouseEventHandler<HTMLButtonElement>; isLoading: boolean }> | null;
};
export const SubscribeButton: React.FC<SubscribeButtonProps> = React.memo(({ children }) => {
  const toast = useToast();
  const { templateId } = useTemplateMenuContext();
  const userId = useSelector(SessionSelector.getCurrentUserId);

  const [subscriptionId, setSubscriptionId] = React.useState<string | null>(null);
  const subscriptionsQuery = useGetTemplateSubscriptionsQuery(
    { templateId, userId: userId! },
    {
      enabled: !!templateId && !!userId,
      onSuccess: subs => {
        setSubscriptionId(subs.find(sub => sub.template.id === templateId)?.id ?? null);
      },
    },
  );
  const isSubscribed = !!subscriptionId;

  const subscribeMutation = useSubscribeToTemplateMutation({
    onSuccess: () => {
      AnalyticsService.trackEvent('template subscription created', { auto: false });
      toast({
        title: 'Subscribed',
        description: `You'll get email notifications for this workflow.`,

        status: 'success',
      });
      subscriptionsQuery.refetch();
    },
  });
  const unsubscribeMutation = useUnsubscribeFromTemplateMutation({
    onSuccess: () => {
      toast({
        title: 'Unsubscribed',
        description: `You'll no longer get email notifications for this workflow.`,

        status: 'success',
      });
      AnalyticsService.trackEvent('template subscription deleted', {});

      subscriptionsQuery.refetch();
    },
  });

  const handleSubscribe = () => {
    if (templateId && userId) {
      subscribeMutation.mutate({ templateId, userId });
    }
  };
  const handleUnsubscribe = () => {
    if (subscriptionId) {
      unsubscribeMutation.mutate({ templateSubscriptionId: subscriptionId });
    }
  };

  if (typeof children !== 'function') {
    throw new Error('`SubscribeButton` must be a render prop function');
  }
  const result = children({ isSubscribed });
  if (result === null || !React.isValidElement(result) || Array.isArray(result)) {
    throw new Error('The render prop function child must return a single clickable element  or a render prop function');
  }

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
  const isFree = useIsCurrentUserFreeMember();
  const canSubscribe = permissionsQuery.data?.permissionMap.templateSubscribe;

  if (isFree) return <ButtonWithUpgradeTooltip>{result}</ButtonWithUpgradeTooltip>;

  if (!canSubscribe) return null;

  const btn = React.cloneElement(result, {
    onClick: () => {
      if (!isSubscribed) {
        handleSubscribe();
      } else {
        handleUnsubscribe();
      }
    },
    isLoading: subscribeMutation.isLoading || unsubscribeMutation.isLoading || subscriptionsQuery.isLoading,
  });

  return btn;
});

import * as React from 'react';
import { Icon, IconButton, Tooltip } from 'components/design/next';
import { ConditionalLogicButtonWrapper } from '../conditional-logic-button-wrapper';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import groupBy from 'lodash/groupBy';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { useFunctionRef } from 'hooks/use-function-ref';
import { useDeepCompareEffect } from 'react-use';

export interface TaskTemplateConditionalLogicButtonProps {
  templateRevision: TemplateRevision;
  taskTemplate: TaskTemplate;
  setHasConditionalLogic: (hasConditionalLogic: boolean) => void;
}

export const TaskTemplateConditionalLogicButton: React.FC<
  React.PropsWithChildren<TaskTemplateConditionalLogicButtonProps>
> = ({ templateRevision, taskTemplate, setHasConditionalLogic }) => {
  const isEditable = useWorkflowState() === 'edit';
  const [ruleChecker, setRuleChecker] = React.useState<(taskTemplate: TaskTemplate) => boolean>();

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevision.id, {
    select: widgets => {
      return groupBy(widgets, w => w.header.taskTemplate.id);
    },
  });

  const rulesQuery = GetAllRulesByTemplateRevisionIdQuery.useQuery(
    { templateRevisionId: templateRevision.id },
    {
      enabled: widgetsQuery.isSuccess,
    },
  );

  useDeepCompareEffect(() => {
    if (!widgetsQuery.data || !rulesQuery.data) {
      return;
    }
    const rules = rulesQuery.data.definitions;
    const taskTemplateHasAssociatedRule = ConditionalLogicCommonUtils.makeTaskTemplateHasAssociatedRule(rules);
    setRuleChecker(
      () => (tt: TaskTemplate) =>
        taskTemplateHasAssociatedRule({ taskTemplate: tt, widgets: widgetsQuery.data?.[tt.id] ?? [] }),
    );
  }, [widgetsQuery.data, rulesQuery.data]);

  const hasRules = ruleChecker?.(taskTemplate) ?? false;
  const setterRef = useFunctionRef(setHasConditionalLogic);
  React.useEffect(() => {
    setterRef.current?.(hasRules);
  }, [hasRules, setterRef]);

  return (
    <Tooltip hasArrow shouldWrapChildren label="Conditional logic">
      <ConditionalLogicButtonWrapper taskTemplate={taskTemplate} templateRevision={templateRevision}>
        <IconButton
          {...{
            'size': 'sm',
            'aria-label': hasRules ? 'Conditional logic applied' : 'Conditional logic',
            'borderWidth': 'px',
            'borderColor': 'gray.300',

            ...(isEditable
              ? {
                  variant: 'outline',
                  icon: (
                    <Icon
                      icon="shuffle"
                      variant="far"
                      size="4"
                      color={hasRules && isEditable ? 'purple.500' : 'gray.400'}
                    />
                  ),
                }
              : {
                  variant: 'ghost',
                  isDisabled: true,
                  pointerEvents: 'none',
                  icon: <Icon icon="shuffle" variant="far" size="4" color="gray.400" />,
                }),
          }}
        />
      </ConditionalLogicButtonWrapper>
    </Tooltip>
  );
};

import * as React from 'react';

import { Template } from '@process-street/subgrade/process';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  useBreakpointValue,
  useDisclosure,
} from 'components/design/next';
import { TemplateShareButton } from 'directives/template-share/template-share-button';
import { TemplateMenu, TemplateMenuItem } from 'features/template/components/template-menu';
import { EditButton } from 'pages/pages/_id/edit/page/components/edit-button';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';

import { DisabledFormFieldClickTooltip } from '../../disabled-form-field-click-tooltip';
import { RunWorkflowButton } from '../../run-workflow-button';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';

export type ViewProps = ButtonGroupProps & {
  templateId: Template['id'];
};

const iconButtonProps: Partial<IconButtonProps> = {
  h: '10',
  minH: '10',
  w: '10',
  padding: '7px 12px',
  color: 'gray.600',
  bgColor: 'white',
  borderColor: 'gray.300',
  borderWidth: 'px',
  borderStyle: 'solid',
  borderRadius: 'base',
  position: 'relative',
  display: 'flex',
  variant: 'unstyled',
  _hover: { bgColor: 'gray.100' },
  _disabled: { bgColor: 'gray.200' },
};

export const ViewButtonGroup: React.FC<React.PropsWithChildren<ViewProps>> = ({ templateId, ...props }) => {
  const workflowState = useWorkflowState();
  const isDashboard = workflowState === 'dashboard';
  const templateMenuDisclosure = useDisclosure();

  const isPrivate = useIsPrivateTemplate(templateId);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId);

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;
  const runAriaLabel = `run workflow ${template ? template.name : ''}`;

  const sharingIsEnabled =
    !!permissionMap &&
    (permissionMap.templatePermitsManage ||
      permissionMap.templateShareLevelUpdate ||
      permissionMap.templateShareLinkUpdate);

  if (isSmallScreen) {
    return (
      <ButtonGroup spacing="2" {...props}>
        <TemplateMenu autoSelect mode="view" {...templateMenuDisclosure}>
          <DisabledFormFieldClickTooltip
            onOpen={() => {
              templateMenuDisclosure.onToggle();
            }}
          >
            <RunWorkflowButton>
              <TemplateMenuItem
                aria-label={runAriaLabel}
                w="full"
                icon={<Icon icon="play" variant="far" size="4" color="gray.600" />}
              >
                Run Workflow
              </TemplateMenuItem>
            </RunWorkflowButton>
          </DisabledFormFieldClickTooltip>
          <EditButton templateId={templateId}>
            <TemplateMenuItem aria-label="edit workflow" w="full">
              Edit Workflow
            </TemplateMenuItem>
          </EditButton>
          {!isPrivate && (
            <TemplateShareButton templateId={templateId} isDisabled={!sharingIsEnabled}>
              <TemplateMenuItem
                aria-label="share workflow"
                icon={<Icon icon="share" variant="far" size="4" color="gray.600" />}
              >
                Share
              </TemplateMenuItem>
            </TemplateShareButton>
          )}
        </TemplateMenu>
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup spacing="2" {...props}>
      {!isPrivate && !isDashboard && (
        <Flex alignItems="center" pe="4">
          <TemplateShareButton templateId={templateId} isDisabled={!sharingIsEnabled}>
            <Button aria-label="share workflow" color="gray.500" variant="unstyled" display="inline-flex">
              Share
            </Button>
          </TemplateShareButton>
        </Flex>
      )}

      {!isPrivate && isDashboard && (
        <HStack spacing="3" pr="1" pl="2">
          <TemplateShareButton templateId={templateId} isDisabled={!sharingIsEnabled} modalOptions={{ hideTabs: true }}>
            <IconButton
              {...iconButtonProps}
              aria-label="Permissions"
              icon={<Icon color="gray.500" icon="user-lock" variant="far" size="5" />}
            />
          </TemplateShareButton>

          <TemplateShareButton
            templateId={templateId}
            isDisabled={!sharingIsEnabled}
            modalOptions={{ hideTabs: true, tab: 'sharelink' }}
          >
            <IconButton
              {...iconButtonProps}
              aria-label="Share link"
              icon={<Icon color="gray.500" icon="share-alt" variant="far" size="4" />}
            />
          </TemplateShareButton>
        </HStack>
      )}

      <TemplateMenu autoSelect mode={isDashboard ? 'dashboard' : 'view'} {...templateMenuDisclosure} />
    </ButtonGroup>
  );
};

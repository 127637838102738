import { HStack, Spinner, Text } from '@chakra-ui/react';
import {
  CreateWidgetMutation,
  DeleteWidgetByHeaderIdMutation,
  UpdateWidgetMutation,
  UpdateWidgetOrderTreesMutation,
} from 'features/widgets/query-builder';
import * as React from 'react';
import { useIsMutating } from 'react-query';
import { CreateTaskTemplateMutation, DeleteTaskTemplatesMutation } from 'features/task-templates/query-builder';

export type SaveIndicatorProps = {};

export const SaveIndicator: React.FC<React.PropsWithChildren<SaveIndicatorProps>> = () => {
  const isCreatingWidget = useIsMutating({
    mutationKey: CreateWidgetMutation.getKey(),
  });

  const isUpdatingWidget = useIsMutating({
    mutationKey: UpdateWidgetMutation.getKey(),
  });

  const isUpdatingWidgetOrder = useIsMutating({
    mutationKey: UpdateWidgetOrderTreesMutation.getKey(),
  });

  const isDeletingWidget = useIsMutating({
    mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
  });

  const isCreatingTaskTemplate = useIsMutating({
    mutationKey: CreateTaskTemplateMutation.getKey(),
  });

  const isDeletingTaskTemplate = useIsMutating({
    mutationKey: DeleteTaskTemplatesMutation.getKey(),
  });

  const isSaving =
    isCreatingWidget ||
    isUpdatingWidget ||
    isUpdatingWidgetOrder ||
    isDeletingWidget ||
    isCreatingTaskTemplate ||
    isDeletingTaskTemplate;

  return (
    <HStack>
      {isSaving && <Spinner />}

      <Text fontWeight="400" variant="-1u" color="gray.500">
        {isSaving ? 'Saving...' : 'Saved'}
      </Text>
    </HStack>
  );
};

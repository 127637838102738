import * as React from 'react';
import {
  Menu,
  IconButton,
  Icon,
  Button,
  MenuItem,
  MenuList,
  MenuButton,
  MenuDivider,
  Portal,
  useDisclosure,
} from 'components/design/next';
import { FieldType, Widget, WidgetValidation } from '@process-street/subgrade/process';
import { ConditionalLogicButtonWrapper } from '../conditional-logic-button-wrapper';
import { useConditionalLogicButton } from './use-conditional-logic-button';
import { useDataSetButton } from './use-data-set-button';
import { useInjector } from 'components/injection-provider';
import { getWidgetModalsMachineService, Selectors } from 'features/widgets/widget-modals-machine';
import { useSelector } from '@xstate/react';
import { match, P } from 'ts-pattern';
import { useFeatureFlag } from 'features/feature-flags';

export interface WidgetMenuProps {
  canMoveDown: boolean;
  canMoveUp: boolean;
  onCopy: (widget: Widget) => void;
  onDelete: (widget: Widget) => void;
  onMoveDown: (widget: Widget) => void;
  onMoveUp: (widget: Widget) => void;
  widget: Widget;
  onOpen: (id: Widget['id']) => void;
  onClose: (id: Widget['id']) => void;
}

const actor = getWidgetModalsMachineService();

export const WidgetMenu: React.FC<React.PropsWithChildren<WidgetMenuProps>> = ({
  canMoveDown,
  canMoveUp,
  onCopy,
  onDelete,
  onMoveDown,
  onMoveUp,
  widget,
  onOpen: onOpenProp,
  onClose,
}) => {
  const disclosure = useDisclosure({
    onOpen: () => onOpenProp(widget.id),
    onClose: () => onClose(widget.id),
  });

  const widgetHasConstraintValidation = match(widget)
    .when(WidgetValidation.hasConstraintValidation, () => true)
    .with({ fieldType: P.union(FieldType.SendRichEmail, FieldType.Url, FieldType.Hidden) }, () => true)
    .otherwise(() => false);

  const { shouldShowConditionalLogicButton, widgetHasConditionalLogic, templateRevision } =
    useConditionalLogicButton(widget);

  const { dataSetButtonToShow } = useDataSetButton(widget);

  const { WidgetService } = useInjector('WidgetService');
  const handleDelete = () => {
    if (WidgetService.isEmpty(widget)) {
      onDelete(widget);
    } else {
      actor.send({ type: 'OPEN_DELETE', widget });
    }
  };

  const closeOnBlur = useSelector(actor, Selectors.noneAreOpen);

  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const connectDataSet = () => {
    if (isDataSetsV2Enabled) {
      actor.send({ type: 'OPEN_CONNECT_DATA_SET', widget });
    } else {
      actor.send({ type: 'OPEN_LINK_DATA_SET', widget });
    }
  };

  return (
    <Menu {...disclosure} isLazy closeOnBlur={closeOnBlur}>
      <MenuButton
        as={IconButton}
        aria-label="Widget menu"
        variant="secondary"
        size="xs"
        display="none"
        icon={<Icon icon="ellipsis-h" size="4" variant="far" color="gray.400" />}
        sx={{
          // Angluar interop
          '.widget-outer:hover &, .widget-outer.pseudo-hover &': {
            display: 'flex',
          },
        }}
      />

      <Portal>
        <MenuList>
          {widgetHasConstraintValidation && (
            <MenuItem
              icon={<Icon icon="cog" size="4" variant="far" />}
              onClick={() => actor.send({ type: 'OPEN_SETTINGS', widget })}
            >
              Settings
            </MenuItem>
          )}

          {shouldShowConditionalLogicButton && templateRevision && (
            <ConditionalLogicButtonWrapper widget={widget} templateRevision={templateRevision}>
              <MenuItem
                as={Button}
                variant="unstyled"
                aria-label={widgetHasConditionalLogic ? 'Conditional Logic (applied)' : 'Conditional Logic'}
                icon={
                  <Icon
                    display="flex"
                    icon="shuffle"
                    variant="far"
                    size="4"
                    color={widgetHasConditionalLogic ? 'purple.500' : undefined}
                  />
                }
                color={widgetHasConditionalLogic ? 'purple.500' : undefined}
              >
                Conditional Logic
              </MenuItem>
            </ConditionalLogicButtonWrapper>
          )}

          {(widgetHasConstraintValidation || shouldShowConditionalLogicButton) && <MenuDivider />}

          {match(dataSetButtonToShow)
            .with('link', () => (
              <MenuItem onClick={connectDataSet} icon={<Icon icon="database" variant="far" size="4" />}>
                Connect Data Set
              </MenuItem>
            ))
            .with('unlink', () => (
              <MenuItem
                closeOnSelect={false}
                icon={<Icon icon="database" variant="far" size="4" />}
                onClick={() => actor.send({ type: 'OPEN_UNLINK_DATA_SET', widget })}
              >
                Disconnect Data Set
              </MenuItem>
            ))
            .otherwise(() => null)}

          <MenuItem icon={<Icon icon="copy" size="4" variant="far" />} onClick={() => onCopy(widget)}>
            Duplicate
          </MenuItem>

          <MenuItem
            onClick={() => actor.send({ type: 'OPEN_MOVE', widget })}
            icon={<Icon icon="arrow-right-from-line" size="4" variant="far" />}
            closeOnSelect={false}
          >
            Move to Another Task
          </MenuItem>

          {canMoveUp && (
            <MenuItem onClick={() => onMoveUp(widget)} icon={<Icon icon="arrow-up" size="4" variant="far" />}>
              Move Up
            </MenuItem>
          )}

          {canMoveDown && (
            <MenuItem onClick={() => onMoveDown(widget)} icon={<Icon icon="arrow-down" size="4" variant="far" />}>
              Move Down
            </MenuItem>
          )}

          <MenuItem
            role="menuitem"
            onClick={handleDelete}
            icon={<Icon icon="trash-alt" size="4" variant="far" color="red.500" />}
            color="red.500"
            closeOnSelect={false}
          >
            Delete
          </MenuItem>

          <MenuItem
            display={{ base: 'none', md: 'flex' }}
            icon={<Icon icon="grip-vertical" size="4" variant="fas" />}
            isDisabled
            fontStyle="italic"
          >
            You can drag & drop
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};

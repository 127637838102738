import * as React from 'react';
import {
  Box,
  Button,
  ButtonGroupProps,
  ButtonProps,
  Divider,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useBreakpointValue,
} from 'components/design/next';
import { useTemplateId } from '../../automation/utils/use-template-id';
import {
  AutomationInstance,
  AutomationInstanceUtils,
  getAutomationAppNameLabel,
  getPrefixedAutomationAppName,
  getTrayAutomationAppDetail,
  isAutomationInstance,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
  SolutionTypeTag,
  withTrayPrefix,
} from '@process-street/subgrade/automation';
import { match } from 'ts-pattern';
import { useAutomationsModalEvents } from 'features/automations/components/workflow-automations/components/common/context';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { noop } from '@process-street/subgrade/util';
import { TriggerIcons, TriggerLabels } from '../choose-template-triggers/native-trigger-data';
import { isNativeTemplateTrigger } from '@process-street/subgrade/process';
import { RunMultipleButton, ScheduleWorkflowButton, WorkflowRunLinkButton } from 'components/template-menu-buttons';
import { useInjector } from 'components/injection-provider';
import { useUnconfiguredInstances } from '../choose-template-triggers/use-unconfigured-instances';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { NgLink } from 'features/app/components/ng-link';
import { ChooseTemplateTriggers } from '../choose-template-triggers';
import { useWatchPreferredTraySolution } from './use-watch-preferred-tray-solution';
import { useSelectedTemplateTriggers } from './use-selected-template-triggers';
import { NativeTriggerButton } from './native-trigger-button';
import { useTriggersModalStore } from 'features/automations/components/workflow-automations/components/template/triggers-modal-store';
import { DisplayableTriggerUtils } from './displayable-trigger';
import { useIsOverflowing } from 'hooks/use-is-overflowing';
import { TriggersStack } from './common';

const ChooseTemplateTriggersPopover = React.lazy(() =>
  import('./choose-template-triggers-popover').then(({ ChooseTemplateTriggersPopover }) => ({
    default: ChooseTemplateTriggersPopover,
  })),
);
const ChooseTemplateTriggersModal = React.lazy(() =>
  import('./choose-template-triggers-modal').then(({ ChooseTemplateTriggersModal }) => ({
    default: ChooseTemplateTriggersModal,
  })),
);

export type SelectedTemplateTriggersEditProps = {
  stackProps?: Partial<ButtonGroupProps>;
  automationItemProps?: Partial<ButtonProps>;
  plusButtonProps?: Partial<ButtonProps>;
  menuButtonProps?: Partial<MenuButtonProps>;
  emptyButtonProps?: Partial<ButtonProps>;
};

export const SelectedTemplateTriggersEdit = ({
  stackProps,
  automationItemProps,
  plusButtonProps,
  menuButtonProps,
  emptyButtonProps,
}: SelectedTemplateTriggersEditProps) => {
  useWatchPreferredTraySolution();

  const templateId = useTemplateId();
  const { configuredInstances, preferredNativeTriggers, preferredTrayTriggerAndAppNames } =
    useSelectedTemplateTriggers();
  const preferredTrayTriggers = preferredTrayTriggerAndAppNames.map(([, name]) => name);

  const maxIconButtons = useBreakpointValue({ base: 4, sm: 8, lg: 5, xl: 8 }) ?? 5;
  const collapseIconThreshold = useBreakpointValue({ base: 3, lg: 2, xl: 3 }) ?? 2;

  const combinedTriggers = [...preferredNativeTriggers, ...preferredTrayTriggers, ...configuredInstances];

  const unconfiguredInstances = useUnconfiguredInstances();
  const shown = combinedTriggers.slice(0, maxIconButtons);

  const hidden = combinedTriggers.slice(maxIconButtons);

  const { openAutomationInstance, openSolutionTypeTag } = useAutomationsModalEvents();

  const chooseTriggerSurface = useBreakpointValue<'modal' | 'popover'>({ base: 'modal', lg: 'popover' }) ?? 'modal';

  const chooseTemplateTriggersBtn = React.useMemo(
    () =>
      combinedTriggers.length > 0 ? (
        <IconButton
          aria-label="add automation"
          variant="secondary"
          // Upping from bg gray.100 because the card background is gray.100
          bgColor="gray.200"
          _hover={{ bgColor: 'gray.300', color: 'gray.700' }}
          _focus={{ bgColor: 'gray.300', color: 'gray.700' }}
          _active={{ bgColor: 'gray.300', color: 'gray.700' }}
          icon={<Icon icon="plus" size="4" variant="far" />}
          size="sm"
          {...plusButtonProps}
        />
      ) : (
        <Button
          size="sm"
          fontWeight="normal"
          variant="outline"
          bg="white"
          color="gray.600"
          colorScheme="gray"
          leftIcon={<Icon icon="bolt" variant="far" size="4" />}
          {...emptyButtonProps}
        >
          Triggers
        </Button>
      ),
    // We don't need to add the `plusButtonProps` to the list of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [combinedTriggers.length],
  );

  const chooseTemplateTriggers = React.useMemo(
    () => (
      <ChooseTemplateTriggers
        onSelect={selection => {
          match(selection)
            .with({ type: 'tray' }, ({ trayAutomationAppName }) => {
              openSolutionTypeTag(SolutionTypeTag.CreateChecklistWhen, trayAutomationAppName);
            })
            .with({ type: 'webhook' }, ({ webhook }) => {
              openAutomationInstance({
                id: webhook.id,
                automationType: isTemplateSolutionInstance(webhook) ? 'tray' : 'webhook',
                modalView: 'all',
                solutionTypeTag: SolutionTypeTag.CreateChecklistWhen,
              });
            })
            .otherwise(noop);
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
    [],
  );

  const shouldCollapseLabels = combinedTriggers.length > collapseIconThreshold;

  const triggersModalStore = useTriggersModalStore();

  const { $state } = useInjector('$state');

  const runUrlOptions = {
    to: $state.current,
    params: {
      ...$state.params,
      [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
      [AppModalQueryParam.ModalTemplateId]: templateId,
    },
    options: { inherit: false },
  };

  return (
    <Flex direction="column" w="full" alignItems="center" mt="7">
      <TriggersStack {...stackProps}>
        {shown.map(trigger =>
          match(trigger)
            .when(isNativeTemplateTrigger, trigger => (
              <NativeTriggerButton
                key={trigger}
                {...{ trigger, shouldCollapseLabels, templateId, ...automationItemProps }}
              />
            ))
            .when(DisplayableTriggerUtils.isTrayAutomationAppName, trayAutomationAppName => {
              const instance = unconfiguredInstances.traySolutionsByAppName[trayAutomationAppName];
              const isNotConfigured = Boolean(instance);
              const label = getAutomationAppNameLabel(trayAutomationAppName);
              const tooltip = isNotConfigured ? `Remember to set up your ${label} automation` : label;

              return (
                <Tooltip
                  key={trayAutomationAppName}
                  label={tooltip}
                  isDisabled={!shouldCollapseLabels}
                  shouldWrapChildren
                  hasArrow
                >
                  <Button
                    {...{
                      variant: 'outline',
                      bg: 'white',
                      size: 'sm',
                      fontWeight: 'normal',
                      colorScheme: 'gray',
                      color: 'gray.600',
                      leftIcon: <AutomationAppIcon automationApp={trayAutomationAppName} w="4" />,
                      onClick: () => openSolutionTypeTag(SolutionTypeTag.CreateChecklistWhen, trayAutomationAppName),
                      ...automationItemProps,
                      ...(shouldCollapseLabels
                        ? {
                            'iconSpacing': '0',
                            'aria-label': label,
                            'px': '2',
                          }
                        : {
                            children: label,
                          }),
                      ...(isNotConfigured
                        ? {
                            rightIcon: (
                              <Icon ml="1" icon="triangle-exclamation" color="yellow.500" size="3" variant="far" />
                            ),
                            borderColor: 'yellow.300',
                          }
                        : {}),
                    }}
                  />
                </Tooltip>
              );
            })
            .when(isAutomationInstance, i => (
              <AutomationInstanceButton
                key={AutomationInstanceUtils.getInstanceId(i)}
                automationInstance={i}
                shouldCollapseLabels={shouldCollapseLabels}
                {...automationItemProps}
              />
            ))
            .otherwise(() => null),
        )}
        {hidden.length > 0 ? (
          <Menu>
            <MenuButton
              as={Button}
              variant="outline"
              colorScheme="gray"
              bg="white"
              size="sm"
              px="2"
              iconSpacing="0"
              leftIcon={<Icon icon="plus" variant="far" size="2" />}
              fontSize="xs"
              {...menuButtonProps}
            >
              <Text fontSize="inherit">{hidden.length}</Text>
            </MenuButton>
            <MenuList>
              {hidden.map(trigger =>
                match(trigger)
                  .when(isNativeTemplateTrigger, trigger => {
                    const label = TriggerLabels[trigger];
                    return match(trigger)
                      .with('manual', () => (
                        <MenuItem
                          key={trigger}
                          as={NgLink}
                          {...runUrlOptions}
                          icon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                          children={label}
                          _hover={{ textDecoration: 'none' }}
                        />
                      ))
                      .with('email', () => (
                        <MenuItem
                          key={trigger}
                          icon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                          children={label}
                          onClick={triggersModalStore.openEmailTab}
                        />
                      ))
                      .with('runLink', () => (
                        <WorkflowRunLinkButton>
                          <MenuItem
                            key={trigger}
                            icon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                            children={label}
                            onClick={triggersModalStore.openRunLinkTab}
                          />
                        </WorkflowRunLinkButton>
                      ))
                      .with('runMultiple', () => (
                        <RunMultipleButton>
                          <MenuItem
                            key={trigger}
                            icon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                            children={label}
                          />
                        </RunMultipleButton>
                      ))
                      .with('schedule', () => (
                        <ScheduleWorkflowButton>
                          <MenuItem
                            key={trigger}
                            icon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                            children={label}
                          />
                        </ScheduleWorkflowButton>
                      ))
                      .with('api', () => (
                        <MenuItem
                          key={trigger}
                          as={Link}
                          href={$state.href('organizationManage.tab', { tab: 'integrations' }, { inherit: false })}
                          icon={<Icon icon={TriggerIcons[trigger]} size="4" variant="far" color="purple.500" />}
                          children={label}
                          onClick={() => {}}
                        />
                      ))
                      .otherwise(() => null);
                  })
                  .when(DisplayableTriggerUtils.isTrayAutomationAppName, trayAutomationAppName => {
                    const instance = unconfiguredInstances.traySolutionsByAppName[trayAutomationAppName];
                    const isNotConfigured = Boolean(instance);
                    const label = getAutomationAppNameLabel(trayAutomationAppName);
                    const tooltip = isNotConfigured ? `Remember to set up your ${label} automation` : undefined;

                    return (
                      <Tooltip label={tooltip} shouldWrapChildren hasArrow key={trayAutomationAppName}>
                        <MenuItem
                          as={Button}
                          variant="inherit"
                          {...automationItemProps}
                          {...{
                            icon: <AutomationAppIcon automationApp={trayAutomationAppName} w="4" />,
                            onClick: () =>
                              openSolutionTypeTag(SolutionTypeTag.CreateChecklistWhen, trayAutomationAppName),
                            children: label,
                            ...(isNotConfigured
                              ? {
                                  rightIcon: (
                                    <Icon
                                      ml="1"
                                      icon="triangle-exclamation"
                                      color="yellow.500"
                                      size="3"
                                      variant="far"
                                    />
                                  ),
                                  borderColor: 'yellow.300',
                                }
                              : {}),
                          }}
                        />
                      </Tooltip>
                    );
                  })
                  .when(isAutomationInstance, i => {
                    const safeAppName = getPrefixedAutomationAppName(i);
                    const label = AutomationInstanceUtils.getLabel(i);

                    const isNotConfigured = Boolean(unconfiguredInstances.instanceIsNotConfigured(i));

                    const tooltip = isNotConfigured
                      ? `Remember to set up your ${getAutomationAppNameLabel(safeAppName)} automation`
                      : null;

                    return (
                      <Tooltip
                        label={tooltip}
                        shouldWrapChildren
                        hasArrow
                        key={AutomationInstanceUtils.getInstanceId(i)}
                      >
                        <MenuItem
                          as={Button}
                          variant="inherit"
                          {...automationItemProps}
                          icon={<AutomationAppIcon automationApp={safeAppName} w="4" />}
                          children={label}
                          onClick={() =>
                            openAutomationInstance({
                              id: AutomationInstanceUtils.getInstanceId(i) ?? '',
                              modalView: 'all',
                              solutionTypeTag: SolutionTypeTag.CreateChecklistWhen,
                              automationType: isTemplateSolutionInstance(i) ? 'tray' : 'webhook',
                            })
                          }
                          {...{
                            ...(isNotConfigured
                              ? {
                                  rightIcon: (
                                    <Icon
                                      ml="1"
                                      icon="triangle-exclamation"
                                      color="yellow.500"
                                      size="3"
                                      variant="far"
                                    />
                                  ),
                                  borderColor: 'yellow.300',
                                }
                              : {}),
                          }}
                        />
                      </Tooltip>
                    );
                  })
                  .otherwise(() => null),
              )}
            </MenuList>
          </Menu>
        ) : null}

        <React.Suspense fallback={chooseTemplateTriggersBtn}>
          {match({ chooseTriggerSurface })
            .with({ chooseTriggerSurface: 'popover' }, () => (
              <ChooseTemplateTriggersPopover
                triggerButton={chooseTemplateTriggersBtn}
                chooseTemplateTriggers={chooseTemplateTriggers}
              />
            ))
            .with({ chooseTriggerSurface: 'modal' }, () => (
              <ChooseTemplateTriggersModal
                modalButton={chooseTemplateTriggersBtn}
                chooseTemplateTriggers={chooseTemplateTriggers}
              />
            ))
            .otherwise(() => null)}
        </React.Suspense>
      </TriggersStack>

      <Box height="6">
        <Divider orientation="vertical" borderStyle="solid" borderColor="gray.300" />
      </Box>
    </Flex>
  );
};

export type AutomationInstanceButtonProps = Partial<ButtonProps> & {
  automationInstance: AutomationInstance;
  shouldCollapseLabels: boolean;
};

const AutomationInstanceButton = ({
  automationInstance,
  shouldCollapseLabels,
  ...props
}: AutomationInstanceButtonProps) => {
  const label = AutomationInstanceUtils.getLabel(automationInstance);
  const unconfiguredInstances = useUnconfiguredInstances();

  const safeAppName = getPrefixedAutomationAppName(automationInstance);
  const isNotConfigured = Boolean(unconfiguredInstances.instanceIsNotConfigured(automationInstance));

  const tooltip = isNotConfigured
    ? `Remember to set up your ${getAutomationAppNameLabel(safeAppName)} automation`
    : match(automationInstance)
        .when(isTemplateSolutionInstance, i => {
          const { label } = getTrayAutomationAppDetail(withTrayPrefix(i.automationApp));
          const { description } = i;
          return [label, description].filter(Boolean).join(': when ');
        })
        .when(isIncomingWebhookInstance, i => i.name ?? i.automationApp)
        .otherwise(() => 'Automation');
  const { openAutomationInstance } = useAutomationsModalEvents();

  const [ui, isOverflowing] = useIsOverflowing(
    <Text variant="inherit" as="span" noOfLines={1} display="inline">
      {label}
    </Text>,
  );

  return (
    <Tooltip
      key={AutomationInstanceUtils.getInstanceId(automationInstance)}
      label={tooltip}
      isDisabled={!shouldCollapseLabels && !isNotConfigured && !isOverflowing}
      hasArrow
    >
      <Box as="span" noOfLines={1} flex="0 1 auto">
        <Button
          maxW="full"
          variant="outline"
          bg="white"
          size="sm"
          fontWeight="normal"
          color="gray.600"
          leftIcon={<AutomationAppIcon automationApp={safeAppName} w="4" />}
          onClick={() =>
            openAutomationInstance({
              id: AutomationInstanceUtils.getInstanceId(automationInstance) ?? '',
              modalView: 'all',
              solutionTypeTag: SolutionTypeTag.CreateChecklistWhen,
              automationType: isTemplateSolutionInstance(automationInstance) ? 'tray' : 'webhook',
            })
          }
          {...props}
          {...{
            ...(shouldCollapseLabels
              ? {
                  'iconSpacing': '0',
                  'aria-label': label,
                  'px': '2',
                }
              : {
                  children: ui,
                }),
            ...(isNotConfigured
              ? {
                  rightIcon: <Icon ml="1" icon="triangle-exclamation" color="yellow.500" size="3" variant="far" />,
                  borderColor: 'yellow.300',
                }
              : {}),
          }}
        />
      </Box>
    </Tooltip>
  );
};

import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { TaskTemplate } from '@process-street/subgrade/process';
import { SolutionTypeTag, TemplateSolutionInstance } from '@process-street/subgrade/automation';
import { useTemplateId } from '../../utils/use-template-id';

type TaskMap = {
  [taskGroupId: string]: TaskTemplate['name'];
};

type Props = {
  templateSolutionInstances: TemplateSolutionInstance[];
};

export const useTaskTemplateNamesMap = ({ templateSolutionInstances }: Props) => {
  const templateId = useTemplateId();
  const newestTemplateRevisionQuery = useNewestTemplateRevisionQuery({ templateId });

  const templateRevisionId = newestTemplateRevisionQuery.data?.id;
  const query = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId: templateRevisionId! },
    {
      enabled: Boolean(templateRevisionId),
      select: data => {
        return data.reduce((acc, taskTemplate) => {
          const tsi = templateSolutionInstances.find(tsi => tsi.taskTemplateGroupId === taskTemplate.group.id);
          if (tsi?.solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen) {
            acc[taskTemplate.group.id] = taskTemplate.name;
          }
          return acc;
        }, {} as TaskMap);
      },
    },
  );
  return query.data;
};

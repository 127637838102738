import { TypeOfEnumValue } from '@process-street/subgrade/core';
import {
  FieldType,
  Widget,
  WidgetConstants,
  isFormFieldWidget,
  FormFieldWidget,
} from '@process-street/subgrade/process';
import { isDataSetLinkedSelectWidget } from 'features/widgets/query-builder';
import { groupBy } from 'lodash';
import { match, P } from 'ts-pattern';

export const WidgetServiceUtils = {
  getFormFieldWidgetKeyMap,
  getFormFieldWidgetWithoutDatasetsGroupedByTaskKeyMap,
};

function getFormFieldWidgetKeyMap(
  widgets: Widget[],
  context: TypeOfEnumValue<typeof WidgetConstants.FormFieldKeysContext>,
) {
  return widgets.filter(isFormFieldWidget).reduce((keyMap, widget) => {
    if (!FieldTypesToContextMap[context][widget.fieldType]) return keyMap;

    // const { fieldType, key, label } = widget;
    match({ ...widget, context })
      .with({ fieldType: FieldType.Members }, ({ label, key }) => {
        keyMap[`${key}.name`] = label ? { ...widget, label: `${label} name` } : widget;
        keyMap[`${key}.email`] = label ? { ...widget, label: `${label} email` } : widget;
      })
      .with(
        {
          context: WidgetConstants.FormFieldKeysContext.RUN_LINK,
          config: { linkId: P.intersection(P.string, P.not('')) },
        },
        () => {
          // don't include data set connected fields in run link options as we can't know the record id
        },
      )
      .otherwise(({ key }) => {
        keyMap[key] = widget;
      });

    return keyMap;
  }, {} as Record<FormFieldWidget['key'], FormFieldWidget>);
}

function getFormFieldWidgetWithoutDatasetsGroupedByTaskKeyMap(
  widgets: Widget[],
  context: TypeOfEnumValue<typeof WidgetConstants.FormFieldKeysContext>,
) {
  const filteredWidgets = widgets.filter(widget => !isDataSetLinkedSelectWidget(widget));

  const widgetsGrouped = groupBy(filteredWidgets, widget => widget.header.taskTemplate.id);

  return Object.keys(widgetsGrouped).reduce((acc, group) => {
    const widgetsInGroup = widgetsGrouped[group];

    const widgetGroupMap = getFormFieldWidgetKeyMap(widgetsInGroup, context);

    acc[group] = widgetGroupMap;

    return acc;
  }, {} as Record<string, Record<string, FormFieldWidget>>);
}

const FieldTypesToContextMap: Record<
  TypeOfEnumValue<typeof WidgetConstants.FormFieldKeysContext>,
  Record<FieldType, boolean>
> = {
  [WidgetConstants.FormFieldKeysContext.RUN_LINK]: {
    [FieldType.Date]: false,
    [FieldType.Email]: true,
    [FieldType.File]: false,
    [FieldType.Hidden]: true,
    [FieldType.Members]: false,
    [FieldType.MultiChoice]: false,
    [FieldType.MultiSelect]: false,
    [FieldType.Number]: true,
    [FieldType.Select]: true,
    [FieldType.SendRichEmail]: false,
    [FieldType.Snippet]: false,
    [FieldType.Table]: false,
    [FieldType.Text]: true,
    [FieldType.Textarea]: true,
    [FieldType.Url]: true,
  },
  [WidgetConstants.FormFieldKeysContext.MERGE_TAG]: {
    [FieldType.Date]: true,
    [FieldType.Email]: true,
    [FieldType.File]: true,
    [FieldType.Hidden]: true,
    [FieldType.Members]: true,
    [FieldType.MultiChoice]: true,
    [FieldType.MultiSelect]: false,
    [FieldType.Number]: true,
    [FieldType.Select]: true,
    [FieldType.SendRichEmail]: false,
    [FieldType.Snippet]: true,
    [FieldType.Table]: false,
    [FieldType.Text]: true,
    [FieldType.Textarea]: true,
    [FieldType.Url]: true,
  },
};

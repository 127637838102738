import pluralize from 'pluralize';
import { AuditMetadata } from './user-model';

export type Muid = string;

export interface Identifiable {
  id: Muid;
  audit?: AuditMetadata;
}

export interface IdentifiableItem<Id extends string = string> {
  id: Id;
}

export interface IdRef {
  readonly id: string;
}

export type Ref<T> = IdRef | T;

export function isIdRef<Obj>(obj: Ref<Obj>): obj is IdRef {
  const props = Object.getOwnPropertyNames(obj);
  return props.length === 1 && props[0] === 'id';
}

export function isNotIdRef<Obj>(obj: Ref<Obj>): obj is Obj {
  return !isIdRef(obj);
}

export interface MediaFile {
  name: string;
  originalName?: string;
  mimeType?: string;
  extension?: string;
  url: string;
  size?: number;
}

export interface S3File extends MediaFile {
  readonly id: Muid;
  mimeType: string;
  audit: AuditMetadata;
  originalName?: string;
  key: string;
}

export interface Period {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  weeks?: number;
}

export namespace Period {
  export const format = (period: Partial<Period>): string => {
    return Object.entries(period).reduce((acc, [key, value]) => {
      if (!value) return acc;
      return `${acc} ${pluralize(key, value, true)}`;
    }, '');
  };
}

export interface CoverImage {
  readonly id: Muid;
  audit: AuditMetadata;
  s3File: S3File;
  templateId: Muid;
}
export interface CoverIcon {
  readonly id: Muid;
  audit: AuditMetadata;
  s3File?: S3File;
  templateId: Muid;
  emoji?: string;
}

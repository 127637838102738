import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import cloneDeep from 'lodash/cloneDeep';
import { Organization, User } from '@process-street/subgrade/core';
import { TaskTemplate, TemplateRevision, Widget } from '@process-street/subgrade/process';
import { IRootScopeService } from 'angular';
import { RuleService } from 'directives/rules/services/rule-service.interface';
import { CONDITIONAL_LOGIC_MODAL_EVENT } from 'features/conditional-logic/components/modal/conditional-logic-modal-event';
import { canAccess, Feature } from 'services/features/features';
import { MessageBox } from 'services/message-box.interface';
import { OrganizationMembershipService } from 'services/organization-membership-service.interface';
import { OrganizationService } from 'services/organization-service.interface';
import { SecurityService } from 'services/security/security-service.interface';
import { SessionService } from 'services/session-service.interface';
import { TaskTemplateService } from 'services/task-template-service.interface';
import upgradeModalTemplate from './upgrade-modal/upgrade-modal.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { ToastService } from 'services/toast-service.interface';

export class ConditionalsButtonService {
  public static $inject = [
    'MessageBox',
    'OrganizationMembershipService',
    'OrganizationService',
    'RuleService',
    'SecurityService',
    'SessionService',
    'TaskTemplateService',
    '$rootScope',
    'ToastService',
  ];

  constructor(
    private messageBox: MessageBox,
    private organizationMembershipService: OrganizationMembershipService,
    private organizationService: OrganizationService,
    private ruleService: RuleService,
    private securityService: SecurityService,
    private sessionService: SessionService,
    private taskTemplateService: TaskTemplateService,
    private $rootScope: IRootScopeService,
    private toastService: ToastService,
  ) {}

  public async openRulesManagerModal({
    organization,
    rulesFeatureIsAvailable,
    templateRevision,
    userIsAdmin,
    widget,
    taskTemplate,
  }: {
    organization: Organization;
    rulesFeatureIsAvailable: boolean;
    rulesModalIsOpen: boolean;
    setRulesModalIsOpen: (isOpen: boolean) => void;
    templateRevision: TemplateRevision;
    user: User;
    userIsAdmin: boolean;
    widget?: Widget;
    taskTemplate?: TaskTemplate;
  }) {
    // clone Angular objects before sending them to React/Zustand which will freeze them
    // some Angular controllers mutate these object references directly
    const openRulesManager = () =>
      this.$rootScope.$broadcast(CONDITIONAL_LOGIC_MODAL_EVENT.OPEN, {
        widget: cloneDeep(widget),
        taskTemplate: cloneDeep(taskTemplate),
      });

    const hiddenByDefaults = await this.taskTemplateService.getTaskTemplates(templateRevision).then(taskTemplates => {
      return taskTemplates.filter(tt => tt.hiddenByDefault);
    });
    // eslint-disable-next-line testing-library/no-await-sync-queries -- Angular promise
    const rules = await this.ruleService.getAllByTemplateRevisionId(templateRevision.id);

    const shouldShowUpgradeModal = this.shouldShowUpgradeModal({
      rulesFeatureIsAvailable,
      hiddenByDefaults,
      rules,
    });

    if (shouldShowUpgradeModal) {
      this.sessionService.setTemplateEditorProperty('addRulesModalSeen', true /* value */);

      this.messageBox.custom({
        templateUrl: upgradeModalTemplate,
        controller: 'RulesUpgradeModalCtrl',
        options: {
          organization,
          openRulesManager,
          canUpgrade: this.canUpgrade({ rulesFeatureIsAvailable, userIsAdmin }),
          rulesFeatureIsAvailable,
        },
      });
    } else {
      openRulesManager();
    }
  }

  private canUpgrade({
    rulesFeatureIsAvailable,
    userIsAdmin,
  }: {
    rulesFeatureIsAvailable: boolean;
    userIsAdmin: boolean;
  }) {
    return !rulesFeatureIsAvailable && userIsAdmin;
  }

  private shouldShowUpgradeModal({
    rules,
    hiddenByDefaults,
    rulesFeatureIsAvailable,
  }: {
    rules: ChecklistRuleDefinition[];
    hiddenByDefaults: TaskTemplate[];
    rulesFeatureIsAvailable: boolean;
  }) {
    const addRulesModalSeen = !this.sessionService.getTemplateEditorProperty('addRulesModalSeen');
    const noRules = rules && !rules.length;
    const noHidden = hiddenByDefaults && !hiddenByDefaults.length;
    return (!rulesFeatureIsAvailable || addRulesModalSeen) && !rulesFeatureIsAvailable && noRules && noHidden;
  }

  public async initializeFeatures() {
    const user = this.sessionService.getUser();

    if (!user) return;

    const selectedOrganizationId = this.securityService.getSelectedOrganizationIdByUser(user);

    const userIsAdminRequest = this.organizationMembershipService.isAdminByUserIdAndOrganizationId(
      user.id,
      selectedOrganizationId,
    );
    const organizationRequest = this.organizationService.getById(selectedOrganizationId);

    return Promise.all([userIsAdminRequest, organizationRequest]).then(
      ([userIsAdmin, organization]) => {
        const planId = organization?.subscription.plan.id;
        return {
          userIsAdmin,
          rulesFeatureIsAvailable: canAccess(Feature.WORKFLOW_RULES, planId),
          organization,
          user,
        };
      },
      () => {
        this.toastService.openToast({
          status: 'error',
          title: `We're having problems loading your details`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      },
    );
  }

  public shouldDisableFeature({
    initialized,
    templateRevision,
  }: {
    initialized: boolean;
    templateRevision?: TemplateRevision;
  }) {
    return !initialized || !templateRevision?.id;
  }
}

import * as React from 'react';
import { useNavigate } from '@process-street/adapters/navigation';
import qs from 'qs';
import { Muid } from '@process-street/subgrade/core';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';

export const useRedirectToFirstTaskOnLoad = ({
  isLoading,
  templateId,
  taskTemplateGroupId,
}: {
  isLoading: boolean;
  templateId: Muid;
  taskTemplateGroupId?: string;
}) => {
  const navigate = useNavigate();
  const firstTimeRedirected = React.useRef<boolean>(false);
  const actor = useFormEditorPageActorRef();
  const firstTaskTemplateGroupId = useSelector(actor, FormEditorPageActorSelectors.getFirstTaskTemplateGroupId);

  React.useEffect(() => {
    if (!taskTemplateGroupId && !isLoading && !firstTimeRedirected.current) {
      firstTimeRedirected.current = true;

      navigate({
        pathname: 'templateV2.task',
        search: qs.stringify({
          id: templateId,
          groupId: firstTaskTemplateGroupId,
        }),
      });
    }
  }, [isLoading, templateId, taskTemplateGroupId, navigate, firstTaskTemplateGroupId]);
};

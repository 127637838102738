// Make sure the rich text html string at least has font family set
import { toResult } from '@process-street/subgrade/util';
import { deserializeHtml, serialize } from 'pages/pages/_id/edit/page/utils/serialization';
import { trace } from 'components/trace';
import {
  SendRichEmailDescendant,
  SendRichEmailEditor,
  SendRichEmailElement,
  SendRichEmailValue,
} from './send-rich-email-plate-types';
import { getNodeChildren } from '@udecode/slate';

export const STYLE_STRING =
  '<style>*{font-family:Inter,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",system-ui,sans-serif}</style>';

const withStyleString = (html: string) => html.concat(STYLE_STRING);
const withoutStyleString = (html: string) => html.replace(STYLE_STRING, '');

const logger = trace({ name: 'EditorUtils' });

const convertBodyToElements = (body: string): SendRichEmailValue => {
  const empty = [{ type: 'p' as const, children: [{ text: '' }] }];
  // TODO add normalizer to editor
  if (body === '') {
    return empty;
  }
  return toResult(() => deserializeHtml(withoutStyleString(body))).fold(
    () => {
      logger.error('Unable to parse body', { body });
      return empty;
    },
    x => x as any as SendRichEmailValue,
  );
};

const serializeElements = (elements: SendRichEmailDescendant[]) => elements.map(serialize).join('');
const serializeElementsWithStyleString = (elements: SendRichEmailElement[]) =>
  withStyleString(serializeElements(elements));

const getEditorContent = (editor: SendRichEmailEditor): string => {
  const elements = Array.from(getNodeChildren(editor, [])).map(([e, _n]) => e);
  return serializeElements(elements);
};

const getEditorSelection = (editor: SendRichEmailEditor): string => {
  if (editor.selection) {
    const elements = editor.getFragment();
    return removeOuterTags(serializeElements(elements));
  } else {
    return '';
  }
};

const REMOVE_TAGS_REGEXP = /^(<[^>/]*>)*|(<\/[^>]*>)*$/g;

const removeOuterTags = (content: string) => content.replace(REMOVE_TAGS_REGEXP, '');

export const EditorUtils = {
  convertBodyToElements,
  getEditorContent,
  getEditorSelection,
  removeOuterTags,
  serializeElements,
  serializeElementsWithStyleString,
  withStyleString,
  withoutStyleString,
};

import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { ChecklistHeaderActions } from '.';

export const ChecklistHeaderActionsModule = angular
  .module('checklistHeaderActions', [])
  .component(
    'psChecklistHeaderActions',
    reactToAngular(ChecklistHeaderActions, ['hideCompletedTasks', 'toggleCompletedTasksVisibility']),
  ).name;

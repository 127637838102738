import { FormControl, FormErrorMessage, VStack } from 'components/design/next';
import React, { useCallback, useEffect, useState } from 'react';
import { useActor, useSelector } from '@xstate/react';
import { TableFormFieldActor } from './table-form-field-machine';
import { RulesEngineTargetSelectors } from 'pages/responses/_id/components/form-response-body/rules-engine-machine';
import { FormResponseLabel } from '../common';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { Box } from '@chakra-ui/react';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import 'app/styles/ag-grid/ag-theme-standard.scss';
import { useAgGridColDefs } from 'pages/forms/_id/shared/hooks/use-ag-grid-col-defs';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-community/core';
import { ValidationSelectors } from 'pages/responses/_id/components/form-fields/validation-machine';
import {
  addLastEmptyRow,
  addMissingRowCells,
  removeLastEmptyRows,
} from '@process-street/subgrade/process/widget-utils/table-form-field-utils';
import { TemplateType } from '@process-street/subgrade/process';

export interface TableFormFieldProps {
  actor: TableFormFieldActor;
  templateType: TemplateType;
}

export const TableFormField: React.FC<React.PropsWithChildren<TableFormFieldProps>> = ({ actor, templateType }) => {
  const [state, send] = useActor(actor);
  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);
  const { widget } = state.context;
  const { columnDefs } = widget.config;

  const colDefs = useAgGridColDefs<TableFieldValue.Row>(columnDefs, {
    editable: !state.matches('input.disabled'),
    suppressMenu: true,
  });
  const rows = state.context.value;
  const [rowData, setRowData] = useState<TableFieldValue.Row[]>(rows);
  useEffect(() => {
    // We add missing row cells because AG Grid needs them, and they might get added during a migration
    const newRowData = rows.map(row => addMissingRowCells(row, columnDefs));
    const newRowDataWithEmptyRow = addLastEmptyRow(newRowData, columnDefs);
    setRowData(newRowDataWithEmptyRow);
  }, [rows, columnDefs]);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      return [
        {
          name: 'Delete Row',
          disabled: rowData.length === 1,
          action: () => {
            const newRows = rowData.filter(row => row.id !== params.node?.data.id);
            const cleanedRows = removeLastEmptyRows(newRows);
            send({ type: 'CHANGE', value: cleanedRows });
            // Make sure we reveal any error message this might trigger
            send({ type: 'REVEAL_INVALID' });
          },
        },
      ];
    },
    [rowData, send],
  );

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={ValidationSelectors.isActorInvalidVisible(state.context.validationActor)}
    >
      <FormResponseLabel>{widget.label || 'Untitled Table'}</FormResponseLabel>
      <Box width="full" height="300" className="ag-theme-alpine ag-theme-standard">
        <AgGridReact<TableFieldValue.Row>
          getRowId={params => params.data.id}
          modules={[ClientSideRowModelModule, MenuModule]}
          overlayNoRowsTemplate={'No rows to display.'}
          columnDefs={colDefs}
          getContextMenuItems={getContextMenuItems}
          suppressContextMenu={state.matches('input.disabled')}
          rowData={rowData}
          singleClickEdit={true}
          editType={'fullRow'}
          stopEditingWhenCellsLoseFocus={true}
          suppressMovableColumns={true}
          suppressDragLeaveHidesColumns={true}
          onRowEditingStarted={() => {
            send({ type: 'FOCUS' });
          }}
          onRowEditingStopped={() => {
            send({ type: 'BLUR' });
          }}
          onCellValueChanged={e => {
            const rows: TableFieldValue.Row[] = [];
            e.api.forEachNode(node => {
              if (node.data) {
                rows.push(node.data);
              }
            });
            const cleanedRows = removeLastEmptyRows(rows);
            send({ type: 'CHANGE', value: cleanedRows });
          }}
        />
      </Box>
      {/* Workflow mode handles error messages outside the component */}
      {templateType === TemplateType.Form && (
        <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
      )}
    </FormControl>
  );
};

import * as React from 'react';
import { MenuItem, Icon } from 'components/design/next';
import { useAutomationSelector } from 'app/pages/templates/_id/automation/components/selector/context';
import { SolutionTypeTag } from '@process-street/subgrade/automation';

export const AutomationMenuItem = React.forwardRef((_, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const [, send] = useAutomationSelector();

  const handleClick = () => {
    send({
      type: 'SOLUTION_TYPE_SELECTED',
      payload: { solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen, modalView: 'task' },
    });
  };

  return (
    <MenuItem ref={ref} fontSize="md" color="gray.600" icon={<Icon icon="bolt" size="3.5" />} onClick={handleClick}>
      Automate
    </MenuItem>
  );
});

import * as React from 'react';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { Icon, IconButton, useDisclosure, useToast } from 'components/design/next';
import { UpdateTaskTemplateMutation } from 'features/task-templates/query-builder';

import { useInjector } from 'components/injection-provider';
import { EventName } from 'services/event-name';
import { createSuccessAction } from '@process-street/subgrade/redux';
import { TASK_TEMPLATE_UPDATE } from 'reducers/task-template/task-template.actions';
import { useDispatch } from 'react-redux';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';
import { canAccess, Feature } from 'app/services/features/features';
import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { StopTaskModal } from 'app/pages/workflows/_id/edit-v2/components/task-list/components/bulk-actions-drawer/bulk-stop-task/stop-task-modal';

export interface StopTaskButtonProps {
  taskTemplate: TaskTemplate;
  templateRevision?: TemplateRevision;
}

export const StopTaskButton: React.FC<React.PropsWithChildren<StopTaskButtonProps>> = ({ taskTemplate }) => {
  const { $rootScope, SessionService, UserSettingsService } = useInjector(
    '$rootScope',
    'SessionService',
    'UserSettingsService',
  );
  const dispatch = useDispatch();
  const toast = useToast();
  const hasStop = taskTemplate.stop;
  const organization = useSelectedOrganization();
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const disclosure = useDisclosure();

  const isFeatureAvailable = React.useMemo(() => {
    if (organization) {
      const planId = organization.subscription.plan.id;
      return canAccess(Feature.STOP_TASK, planId);
    }
  }, [organization]);

  const isAdmin = React.useMemo(() => {
    if (currentUserInfoQuery.data) {
      return currentUserInfoQuery.data?.organizationMembership.role === OrganizationMembershipRole.Admin;
    }
    return false;
  }, [currentUserInfoQuery.data]);

  const addStopTaskModalSeen = SessionService.getTemplateEditorProperty('addStopTaskModalSeen');

  const shouldShowUpgradeModal = React.useMemo(() => {
    return !hasStop && (!isFeatureAvailable || !addStopTaskModalSeen);
  }, [hasStop, addStopTaskModalSeen, isFeatureAvailable]);

  const updateTemplateMutation = UpdateTaskTemplateMutation.useMutation({
    onSuccess: data => {
      const { taskTemplate: updatedTaskTemplate, deletedRulesIds } = data;
      toast({
        status: 'success',
        title: 'Task updated',
        variant: 'subtle',
      });

      const successAction = createSuccessAction(TASK_TEMPLATE_UPDATE, {
        taskTemplate: updatedTaskTemplate,
        deletedRulesIds,
      });
      // TODO Verify why these dispatches are not triggering the update on the list item on the Angular side.
      dispatch({
        type: TASK_TEMPLATE_UPDATE,
        payload: { taskTemplate: updatedTaskTemplate, deletedRulesIds },
      });
      dispatch(successAction);
      $rootScope.$broadcast(EventName.TASK_TEMPLATE_UPDATE_OK, updatedTaskTemplate, deletedRulesIds);
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'We are having problems updating the Task',
      });
    },
  });

  const handleToggleStop = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();

    if (shouldShowUpgradeModal) {
      SessionService.setTemplateEditorProperty('addStopTaskModalSeen', true /* value */);
      disclosure.onOpen();
    } else {
      updateTemplateMutation.mutate({
        taskTemplateId: taskTemplate.id,
        taskTemplate: { stop: !hasStop },
      });
    }
  };

  const handleOnAddStopFromModal = () => {
    updateTemplateMutation.mutate({
      taskTemplateId: taskTemplate.id,
      taskTemplate: { stop: !hasStop },
    });
    UserSettingsService.updateTemplateEditor(SessionService.getTemplateEditorProperties());
    disclosure.onClose();
  };
  return (
    <>
      <IconButton
        marginRight={3}
        aria-label="Add Stop"
        icon={
          <>
            {hasStop && <Icon icon="slash" size="4" color="purple.500" variant="far" sx={{ position: 'absolute' }} />}
            <Icon icon="hand" size="4" color={hasStop ? 'purple.500' : 'gray.400'} variant="far" />
          </>
        }
        onClick={handleToggleStop}
        variant="outline"
        borderColor="gray.300"
        borderWidth="px"
        size="sm"
      />
      <StopTaskModal
        disclosure={disclosure}
        isAdmin={isAdmin}
        isFeatureAvailable={isFeatureAvailable}
        onAddStop={handleOnAddStopFromModal}
      />
    </>
  );
};

import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate } from '@process-street/subgrade/process';
import { LocalStorageService } from 'app/features/storage/local-storage-service';
import { useTaskTemplateListActorRef } from './use-task-templates-list-actor';
import _isEmpty from 'lodash/isEmpty';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { Option } from 'space-monad';
import { ActorRefFrom } from 'xstate';
import { TaskTemplateListMachine } from '../components/task-list/task-template-list-machine';

type LastViewedTasks = { [templateId: string]: string };

export const useRedirectToTaskOnLoad = ({
  isLoading,
  templateId,
  taskTemplateGroupId,
}: {
  isLoading: boolean;
  templateId: Muid;
  taskTemplateGroupId?: string;
}) => {
  const editorActor = useFormEditorPageActorRef();
  const taskTemplateListActor = useTaskTemplateListActorRef();
  const selectTask = useTaskSelection(taskTemplateListActor);
  const firstTimeRedirected = React.useRef<boolean>(false);

  const firstTaskTemplate = useSelector(editorActor, FormEditorPageActorSelectors.getFirstTaskTemplate);

  const lastViewedTaskSelector = React.useMemo(() => {
    const lastViewedTasks = getLastViewedTasks();
    const lastViewedTaskGroupId = lastViewedTasks[templateId];

    return FormEditorPageActorSelectors.getTaskTemplateByGroupId(lastViewedTaskGroupId);
  }, [templateId]);

  const navigationTaskTemplateSelector = React.useMemo(
    () => FormEditorPageActorSelectors.getTaskTemplateByGroupId(taskTemplateGroupId ?? ''),
    [taskTemplateGroupId],
  );

  const navigationTaskTemplate = useSelector(editorActor, navigationTaskTemplateSelector);
  const lastViewedTaskTemplate = useSelector(editorActor, lastViewedTaskSelector);

  React.useEffect(
    function selectTaskFromLocalStorage() {
      if (!taskTemplateGroupId && !isLoading && !firstTimeRedirected.current) {
        const taskToRedirect = Option(lastViewedTaskTemplate).fold(
          () => firstTaskTemplate,
          taskTemplate => taskTemplate,
        );

        if (taskToRedirect) {
          firstTimeRedirected.current = true;
          selectTask(taskToRedirect);
        }
      }
    },
    [isLoading, taskTemplateGroupId, selectTask, firstTaskTemplate, lastViewedTaskTemplate],
  );

  React.useEffect(
    function selectTaskOnFirstLoad() {
      if (navigationTaskTemplate && !firstTimeRedirected.current) {
        if (navigationTaskTemplate) {
          firstTimeRedirected.current = true;
          selectTask(navigationTaskTemplate);
        }
      }
    },
    [selectTask, navigationTaskTemplate],
  );
};

const getLastViewedTasks = (): LastViewedTasks => {
  try {
    const storedData = LocalStorageService.getItem('lastViewedTasks');
    return storedData ? JSON.parse(storedData) : {};
  } catch (error) {
    console.error('Error retrieving last viewed tasks:', error);
    return {};
  }
};

export const setLastVisited = ({ groupId, templateId }: { groupId: Muid; templateId: Muid }): void => {
  try {
    const lastViewedTasks = getLastViewedTasks();
    lastViewedTasks[templateId] = groupId;
    LocalStorageService.setItem('lastViewedTasks', JSON.stringify(lastViewedTasks));
  } catch (error) {
    console.error('Error setting last viewed task:', error);
  }
};

const useTaskSelection = (taskTemplateListActor: ActorRefFrom<TaskTemplateListMachine>) => {
  return React.useCallback(
    (taskTemplate: TaskTemplate) => {
      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate,
        metaKey: false,
        ctrlKey: false,
        shiftKey: false,
      });
    },
    [taskTemplateListActor],
  );
};

import { ColDef, Column, ColumnResizedEvent, DragStoppedEvent, SortChangedEvent } from '@ag-grid-community/core';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import {
  ChecklistColumn,
  ColumnDef,
  ColumnDto,
  ColumnType,
  defaultColumns,
  FormFieldValueColumnDef,
  FormFieldValueColumnField,
} from '@process-street/subgrade/dashboard';
import { UserUtils } from '@process-street/subgrade/util';
import { MemberItem } from 'components/common/MemberOption';
import { FormFieldValueRenderer } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/FormFieldValueRenderer';
import { TaskStatusRenderer } from 'components/dashboard/components/checklist/ChecklistDashboardGrid/renderers/TaskStatusRenderer';
import { alwaysVisibleColumns, checklistColumns } from 'components/dashboard/models/columns';
import { Sorting } from 'components/dashboard/models/filters';
import {
  ColumnOrderMap,
  ColumnSizeMap,
  isChecklistColDef,
  isColDef,
  isFormFieldColDef,
  RowValues,
} from 'components/dashboard/models/grid';
import { SavedView } from 'components/dashboard/models/saved-views';
import deepEqual from 'deep-equal';
import { FieldType } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';

const getCellRenderer = (column: ColumnDto) => {
  if (column.columnType === ColumnType.Task) {
    return TaskStatusRenderer;
  } else if (column.columnType === ColumnType.FormField) {
    return FormFieldValueRenderer;
  }
};

/**
 * We abuse the cellRendererParams slightly to pass some values (groupId and widgetGroupId)
 * through to the grid-data-source where they are used to query the backend and populate the
 * data for the form field value columns.
 */
const columnDtoToColDef = <F extends string>(column: ColumnDto<F>): Omit<ColDef, 'field'> & { field: F } => {
  return {
    cellRenderer: getCellRenderer(column),
    colId: column.field,
    cellRendererParams: {
      groupId: column.groupId,
      itemId: column.itemId,
      widgetGroupId: column.widgetGroupId,
    },
    field: column.field,
    headerName: column.name,
    headerTooltip: column.name,
    type: column.columnType,
    sortable: column.sortable,
  };
};

const SORTABLE_FIELD_TYPES = [
  FieldType.Text,
  FieldType.Date,
  FieldType.Number,
  FieldType.Select,
  FieldType.Hidden,
  FieldType.Url,
  FieldType.Email,
];

const formFieldColumnDefToColumnDto = (
  col: ColumnDef<FormFieldValueColumnDef>,
  isFormFieldSortingEnabled?: boolean,
): ColumnDto<FormFieldValueColumnField> => {
  const { widgetGroupId, itemId } = col.data;

  return {
    columnType: ColumnType.FormField,
    itemId,
    widgetGroupId,
    ...match(col.data)
      .with({ fieldType: FieldType.MultiSelect }, ({ widgetGroupId, itemLabel, itemId }) => ({
        field: `formFields.${widgetGroupId}_${itemId}` as const,
        name: itemLabel || 'Unknown subtask',
      }))
      .otherwise(({ fieldLabel }) => ({
        field: `formFields.${widgetGroupId}` as const,
        name: fieldLabel,
        sortable: isFormFieldSortingEnabled && SORTABLE_FIELD_TYPES.includes(col.data.fieldType),
      })),
  };
};

const getColumnDefs = (columns: ColumnDto[], currentSavedView: SavedView, isLabelColumnEnabled: boolean): ColDef[] => {
  const templateId = GridHelper.getTemplateIdFromSavedView(currentSavedView);

  const taskAndFormFieldColumns = templateId
    ? columns
        .filter(col => col.columnType === ColumnType.Task || col.columnType === ColumnType.FormField)
        .map(col => GridHelper.columnDtoToColDef(col))
    : [];

  const allColumns = [...checklistColumns, ...taskAndFormFieldColumns];
  const visibleIds: string[] = getVisibleColumnIds(currentSavedView, isLabelColumnEnabled);

  const cols = allColumns
    .map(col => GridHelper.pinColumn(col))
    .map(col => GridHelper.sortColumn(col, currentSavedView.columnsConfig.sorting))
    .map(col => GridHelper.sizeColumn(col, currentSavedView.columnsConfig.sizeMap))
    .filter(col => col.field && (visibleIds.length === 0 || visibleIds.includes(col.field)));

  return [
    ...alwaysVisibleColumns.map((col, index) => GridHelper.decorateSelectableColumn(col, index)),
    ...GridHelper.reorderColumns(cols, currentSavedView.columnsConfig.orderMap),
  ];
};

const getVisibleColumnIds = (savedView: SavedView | undefined, isLabelColumnEnabled: boolean) =>
  (savedView?.columnsConfig.visibleIds ?? defaultColumns).filter(
    id => isLabelColumnEnabled || id !== ChecklistColumn.ChecklistLabelId,
  );

const getOrderMap = (columns: ColDef[]): ColumnOrderMap => {
  const orderMap: ColumnOrderMap = {};
  columns.forEach((column: ColDef, index: number) => {
    orderMap[column.field as string] = index;
  });
  return orderMap;
};

const onDragStoppedHandler = (onOrderingChange: (map: ColumnOrderMap) => void) => (event: DragStoppedEvent) => {
  const columns = event.api.getAllGridColumns();
  const orderMap = columns.reduce((map: ColumnOrderMap, column: Column, index: number) => {
    map[column.getColId()] = index;
    return map;
  }, {} as ColumnOrderMap);

  onOrderingChange(orderMap);
};

const onSortChangedHandler = (onSortChanged: (sorting?: Sorting) => void) => (event: SortChangedEvent) => {
  const columns = event.api.getColumnDefs() ?? [];
  const sortedColDef = columns.filter(isColDef).find(col => col.sort);

  if (sortedColDef && isChecklistColDef(sortedColDef)) {
    onSortChanged({
      sortColumn: sortedColDef.field,
      sortAsc: sortedColDef.sort === 'asc',
    });
  } else if (sortedColDef && isFormFieldColDef(sortedColDef)) {
    onSortChanged({
      sortColumn: 'FormFieldValue',
      sortAsc: sortedColDef.sort === 'asc',
      sortByWidgetGroupId: sortedColDef.cellRendererParams?.widgetGroupId,
    });
  } else {
    onSortChanged(undefined);
  }
};

const onColumnResizedHandler = (onColumnResized: (map: ColumnSizeMap) => void) => (event: ColumnResizedEvent) => {
  const currentColumn = event.column;
  const columns = event.api.getColumns();
  if (!event.finished || event.source !== 'uiColumnResized' || !columns || !currentColumn) {
    return;
  }

  const sizeMap = columns.reduce((map: ColumnSizeMap, column: Column) => {
    map[column.getColId()] = column.getActualWidth();
    return map;
  }, {} as ColumnSizeMap);
  sizeMap[currentColumn.getColId()] = currentColumn.getActualWidth();
  onColumnResized(sizeMap);
};

const reorderColumns = (cols: ColDef[], orderMap?: ColumnOrderMap): ColDef[] => {
  if (!orderMap) {
    return cols;
  }

  if (Object.keys(orderMap).length === 0) {
    return cols;
  } else {
    return cols.sort((a, b) => {
      const indexA = orderMap[a.field as string] !== undefined ? orderMap[a.field as string] : 999;
      const indexB = orderMap[b.field as string] !== undefined ? orderMap[b.field as string] : 999;
      return indexA - indexB;
    });
  }
};

const pinColumn = (col: ColDef) => {
  if (col.pinned && GridHelper.isMobileView()) {
    return { ...col, width: 200, pinned: undefined };
  } else {
    return col;
  }
};

const sortColumn = (col: ColDef, sorting?: Sorting): ColDef => {
  if (!sorting) {
    return col;
  }

  if (col.field === sorting.sortColumn) {
    const sort = sorting.sortAsc ? 'asc' : 'desc';
    return { ...col, sort };
  } else {
    return { ...col, sort: null };
  }
};

const sizeColumn = (col: ColDef, sizeMap?: ColumnSizeMap): ColDef => {
  if (sizeMap && sizeMap[col.field as string]) {
    return {
      ...col,
      width: sizeMap[col.field as string],
    };
  } else {
    return col;
  }
};

const decorateSelectableColumn = <T extends ColDef>(col: T, index: number): T => {
  const isFirstColumn = index === 0;
  return {
    ...col,
    checkboxSelection: isFirstColumn,
  };
};

const getTemplateIdFromSavedView = (savedView: SavedView) =>
  savedView.filters.selectedTemplates.length === 1 ? savedView.filters.selectedTemplates[0] : undefined;

const convertOrganizationMembershipsWithUserToMemberItems = (oms: OrganizationMembershipWithUser[]): MemberItem[] =>
  oms.map(convertOrganizationMembershipWithUserToMemberItem);

const convertOrganizationMembershipWithUserToMemberItem = (om: OrganizationMembershipWithUser): MemberItem => ({
  avatarUrl: UserUtils.extractAvatarUrl(om.user, 64),
  email: om.user.email,
  id: om.user.id,
  initials: UserUtils.extractInitials(om.user),
  name: om.user.username,
  organizationMembershipId: om.id,
  userType: om.user.userType,
});

const orderViews = (savedViews: SavedView[]): SavedView[] =>
  savedViews.sort((a, b) => {
    if (a.predefined && !b.predefined) {
      return -1;
    } else if (!a.predefined && b.predefined) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

const isMobileView = () => window.innerWidth < 768;

/**
 * We should manually reload the grid if the tasks or form fields columns have changed as
 * we load the data for these columns on demand.
 */
const shouldManuallyUpdateFilters = (newColDefs: ColDef[], previousColDefs?: ColDef[]) => {
  const previousSortColDef = previousColDefs?.find(col => col.sort);
  const newSortColDef = newColDefs.find(col => col.sort);

  const alreadyReloading = previousSortColDef?.field !== newSortColDef?.field;

  if (alreadyReloading) {
    return false;
  }

  const previousTaskOrFormFieldIds = previousColDefs
    ?.filter(col => col.type === ColumnType.Task || col.type === ColumnType.FormField)
    .map(col => col.field);

  const newTaskOrFormFieldIds = newColDefs
    .filter(col => col.type === ColumnType.Task || col.type === ColumnType.FormField)
    .map(col => col.field);

  return !deepEqual(previousTaskOrFormFieldIds, newTaskOrFormFieldIds);
};

const getRunWorkflowRow = (): RowValues => {
  return {
    id: '',
    [ChecklistColumn.ChecklistName]: {
      checklistId: getRunWorkflowRowId(),
      checklistName: undefined,
    },
  };
};

const getRunWorkflowRowId = () => 'RUN_WORKFLOW_ROW_ID';

const isRunWorkflowRow = (rowValue: RowValues): boolean => {
  const cellValue = rowValue?.[ChecklistColumn.ChecklistName];

  return match(cellValue)
    .with({ checklistId: getRunWorkflowRowId() }, () => true)
    .otherwise(() => false);
};

const EMPTY_STATE_ROW_ID = 'EMPTY_STATE_ROW_ID';

const getEmptyStateRow = (): RowValues => ({
  id: EMPTY_STATE_ROW_ID,
});

const isEmptyStateRow = (row: RowValues): boolean => row?.id === EMPTY_STATE_ROW_ID;

export const GridHelper = {
  columnDtoToColDef,
  convertOrganizationMembershipWithUserToMemberItem,
  convertOrganizationMembershipsWithUserToMemberItems,
  decorateSelectableColumn,
  formFieldColumnDefToColumnDto,
  getCellRenderer,
  getColumnDefs,
  getEmptyStateRow,
  getOrderMap,
  getRunWorkflowRow,
  getRunWorkflowRowId,
  getTemplateIdFromSavedView,
  getVisibleColumnIds,
  isEmptyStateRow,
  isMobileView,
  isRunWorkflowRow,
  onColumnResizedHandler,
  onDragStoppedHandler,
  onSortChangedHandler,
  orderViews,
  pinColumn,
  reorderColumns,
  shouldManuallyUpdateFilters,
  sizeColumn,
  sortColumn,
};

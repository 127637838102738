import * as React from 'react';
import { HStack } from 'components/design/next';
import {
  isHeading,
  TaskTemplate,
  Template,
  TemplateRevision,
  TemplateRevisionStatus,
  TemplateStatus,
} from '@process-street/subgrade/process';

import { useWorkflowState } from 'app/components/focus-bar/workflow/use-workflow-state';
import { AssignTaskButton } from 'app/pages/templates/_id/components/assign-task-button';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';
import { canAccess, Feature } from 'app/services/features/features';
import { DueDatePicker } from 'app/features/dynamic-due-dates';
import { useSharedContext } from 'app/pages/forms/_id/shared';
import { TaskPermissionsPicker } from 'app/components/task-permission/components/task-permissions-picker';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { StopTaskButton } from 'app/pages/templates/_id/components/stop-task-button';
import { TaskListItemAutomationIndicator } from '../task-list-item-automation-indicator';
import { TaskActionsAutomationsButton } from './task-actions-automations-button';
import { useSolutionTypeTagInstances } from 'app/pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import _groupBy from 'lodash/groupBy';
import { TaskActionsConditionalLogicButton } from './task-actions-conditional-logic-button';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';

export type TaskActionsBarProps = {
  taskTemplate: TaskTemplate;
  template: Template;
  templateRevision: TemplateRevision;
};

export const TASK_ACTION_BAR_HEIGHT_OFFSET = '-42px';

export const TaskActionsBar = ({ taskTemplate, template, templateRevision }: TaskActionsBarProps) => {
  const { queryClient } = useSharedContext();
  const isEditable = useWorkflowState() === 'edit';
  const currentUser = useCurrentUser();

  const actor = useFormEditorPageActorRef();
  const checklistRules = useSelector(actor, FormEditorPageActorSelectors.getRules);

  const widgetsSelector = React.useMemo(() => {
    return FormEditorPageActorSelectors.getWidgetsForTaskTemplateId(taskTemplate.id);
  }, [taskTemplate.id]);

  const widgets = useSelector(actor, widgetsSelector);

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(template?.id);
  const selectedOrganization = useSelectedOrganization();
  const planId = selectedOrganization?.subscription.plan.id;
  const canAccessDynamicDueDates = currentUser && planId && canAccess(Feature.DYNAMIC_DUE_DATES, planId);
  const isTaskHeading = taskTemplate ? isHeading(taskTemplate) : false;

  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId: template.id });

  const taskSolutionInstances = ps_when_task_checked_then.filter(
    i => i.taskTemplateGroupId === taskTemplate.group.id && i.configured && i.enabled,
  );

  const hasAutomations = taskSolutionInstances.length > 0;

  const isTaskTemplateAssociatedWithRules = React.useMemo(
    () => ConditionalLogicCommonUtils.makeTaskTemplateHasAssociatedRule(checklistRules),
    [checklistRules],
  );
  const canManageTasks =
    templateRevision?.status === TemplateRevisionStatus.Draft &&
    template?.status === TemplateStatus.Active &&
    permissionsQuery.data?.permissionMap.templateUpdate;

  return (
    <HStack spacing="3" w="full" position="sticky" top={TASK_ACTION_BAR_HEIGHT_OFFSET} py={2} bg="white" zIndex="modal">
      {!isTaskHeading && (
        <AssignTaskButton
          taskTemplate={taskTemplate}
          templateRevision={templateRevision}
          isDisabled={!canManageTasks}
          isEditable={isEditable}
        />
      )}
      {canAccessDynamicDueDates && !isTaskHeading && (
        <DueDatePicker
          selectedTaskTemplate={taskTemplate}
          selectedTaskTemplates={[]}
          editable={isEditable}
          templateRevision={templateRevision}
          disabled={!canManageTasks}
          queryClient={queryClient}
        />
      )}
      {isEditable && (
        <TaskPermissionsPicker
          taskTemplates={[taskTemplate]}
          templateRevisionId={templateRevision.id}
          isDisabled={!canManageTasks}
        />
      )}
      {isEditable && (
        <TaskActionsConditionalLogicButton
          taskTemplate={taskTemplate}
          templateRevision={templateRevision}
          hasConditionalLogic={isTaskTemplateAssociatedWithRules({
            widgets,
            taskTemplate,
          })}
          isEditable={isEditable}
        />
      )}
      {!isTaskHeading && hasAutomations && (
        <TaskListItemAutomationIndicator
          isActive
          templateRevision={templateRevision}
          taskTemplate={taskTemplate}
          editable
          isDisabled={false}
          templateId={template.id}
        />
      )}
      {!isTaskHeading && !hasAutomations && <TaskActionsAutomationsButton isEditable={isEditable} />}
      {!isTaskHeading && <StopTaskButton taskTemplate={taskTemplate} templateRevision={templateRevision} />}
    </HStack>
  );
};

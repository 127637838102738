import { AnonymousUserInfo, User, UserType } from '@process-street/subgrade/core';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';
import { ReduxPersistorService } from 'components/persistor/services/redux-persistor.service';
import { AuthService } from './auth.interface';
import { AppBootService } from './boot/app-boot-service';
import { HttpStatus } from '@process-street/subgrade/util';
import { SecurityService } from './security/security-service.interface';
import { SessionService } from './session-service.interface';
import { ShareLevel } from './template-service.interface';
import { AnonAuthService } from 'services/anon-auth-service.interface';
import { AnonymousAuthenticationResourceType } from '@process-street/subgrade/auth';
import { Trace, trace } from 'components/trace';
import { CookieService } from 'features/cookies/cookie-service';
import { Template } from '@process-street/subgrade/process';
import { AuthInternalErrors } from 'components/utils/error-messages';

type LoginParams = {
  page: string;
  params?: {};
};

export interface AnonymousAuthService {
  templateViewAnonymousAuth(templateId: string): Promise<User | null | undefined>;

  templateRunAnonymousAuthSharedRunLink(
    templateId: string,
    loginParams?: LoginParams,
  ): Promise<User | null | undefined>;

  authenticateForFormResponse(templateId: string, loginParams?: LoginParams): Promise<User | null | undefined>;

  templateRunAnonymousAuth(templateId: string): Promise<User | null | undefined>;

  checklistViewAnonymousAuth(checklistId: string): Promise<User | null | undefined>;

  logoutIfAuthedAnonymously(): void;

  isAuthenticatedAnonymously(): boolean;
}

export class AnonymousAuthServiceImpl implements AnonymousAuthService {
  public static $inject = [
    'ReduxPersistorService',
    'SecurityService',
    'SessionService',
    'AnonAuthService',
    'appBootService',
    'auth',
    '$state',
  ];
  private logger: Trace;

  constructor(
    private readonly reduxPersistorService: ReduxPersistorService,
    private readonly securityService: SecurityService,
    private readonly sessionService: SessionService,
    private readonly anonAuthService: AnonAuthService,
    private readonly appBootService: AppBootService,
    private readonly authService: AuthService,
    private readonly $state: IStateService,
  ) {
    this.logger = trace({ name: 'AnonymousAuthService' });
  }

  private requestDefaultsState: {
    organizationIdHeader?: string;
    authorizationHeader?: string;
    token?: string;
    user?: User;
  } = {};

  async checklistViewAnonymousAuth(checklistId: string) {
    this.requestDefaultsState.user = this.sessionService.getUser();
    const action = this.securityService.whoCanReadChecklistByChecklistId(checklistId);
    const authorizationResult = await this.handleAuthorizationError(action);
    if (authorizationResult.userType === UserType.Standard) {
      return this.sessionService.getUser();
    } else if (authorizationResult.userType === UserType.Anonymous && authorizationResult.checklist.shared) {
      this.logger.info('Initiating anonymous authentication.');
      return this.authenticateChecklist(checklistId);
    } else {
      this.redirectToLogin();
      throw new Error('Unauthorized access.');
    }
  }

  async templateRunAnonymousAuthSharedRunLink(templateId: string, loginParams?: LoginParams) {
    this.requestDefaultsState.user = this.sessionService.getUser();
    const action = this.securityService.whoCanCreateChecklistByTemplateId(templateId);
    const authorizationResult: { userType: UserType; template: Template } = await this.handleAuthorizationError(
      action,
      loginParams,
    );
    if (authorizationResult.userType === UserType.Standard) {
      return this.sessionService.getUser();
    } else if (authorizationResult.userType === UserType.Anonymous && authorizationResult.template.sharedRunLink) {
      this.logger.info('Initiating anonymous authentication.');
      return this.authenticateTemplate(templateId);
    } else {
      this.redirectToLogin(loginParams);
      throw new Error('Unauthorized access.');
    }
  }

  async authenticateForFormResponse(templateId: string, loginParams?: LoginParams) {
    this.requestDefaultsState.user = this.sessionService.getUser();
    const action = this.securityService.whoCanCreateChecklistByTemplateId(templateId);
    const authorizationResult: { userType: UserType; template: Template } = await this.handleAuthorizationError(
      action,
      loginParams,
    );
    if (authorizationResult.userType === UserType.Standard) {
      return this.sessionService.getUser();
    } else if (authorizationResult.userType === UserType.Anonymous) {
      if (authorizationResult.template.sharedRunLink) {
        this.logger.info('Initiating anonymous authentication.');
        return this.authenticateTemplate(templateId);
      } else {
        this.logger.info('Initiating anonymous authentication.');
        await this.authenticateTemplate(templateId);
        this.redirectToLogin(loginParams);
        throw new Error(AuthInternalErrors.formResponseLoginRequired);
      }
    } else {
      this.redirectToLogin(loginParams);
      throw new Error('Unauthorized access.');
    }
  }

  async templateRunAnonymousAuth(templateId: string) {
    this.requestDefaultsState.user = this.sessionService.getUser();
    const action = this.securityService.whoCanCreateChecklistByTemplateId(templateId);
    const authorizationResult = await this.handleAuthorizationError(action);
    if (authorizationResult.userType === UserType.Standard) {
      return this.sessionService.getUser();
    } else if (
      authorizationResult.userType === UserType.Anonymous &&
      authorizationResult.template.shareLevel === ShareLevel.RUN
    ) {
      this.logger.info('Initiating anonymous authentication.');
      return this.authenticateTemplate(templateId);
    } else {
      this.redirectToLogin();
      throw new Error('Unauthorized access.');
    }
  }

  async templateViewAnonymousAuth(templateId: string) {
    this.requestDefaultsState.user = this.sessionService.getUser();
    const action = this.securityService.whoCanReadTemplateByTemplateId(templateId);
    const authorizationResult = await this.handleAuthorizationError(action);
    if (authorizationResult.userType === UserType.Standard) {
      return this.sessionService.getUser();
    } else if (
      authorizationResult.userType === UserType.Anonymous &&
      authorizationResult.template.shareLevel !== ShareLevel.NONE
    ) {
      this.logger.info('Initiating anonymous authentication.');
      return this.authenticateTemplate(templateId);
    } else {
      this.redirectToLogin();
      throw new Error('Unauthorized access.');
    }
  }

  isAuthenticatedAnonymously(): boolean {
    return this.anonAuthService.isAuthenticatedAnonymously();
  }

  logoutIfAuthedAnonymously() {
    if (!this.securityService.isAuthenticatedAnonymously()) {
      return;
    }
    this.anonAuthService.clearAnonymousAuthentication();

    this.reduxPersistorService.resumePersist();

    const { user } = this.requestDefaultsState;
    if (user) {
      this.sessionService.setUser(user);
      CookieService.setUserIdCookie(user.id);
    }
    this.requestDefaultsState.token && this.sessionService.setToken(this.requestDefaultsState.token);
    this.requestDefaultsState = {};
  }

  private async handleAuthorizationError(action: Promise<any>, loginParams?: LoginParams) {
    try {
      return await action;
    } catch (e) {
      this.redirectToLogin(loginParams);
      throw new Error(AuthInternalErrors.generalError);
    }
  }

  private DEFAULT_LOGIN_PAGE = 'login';

  private redirectToLogin(loginParams?: LoginParams) {
    if (!this.authService.isLoggedIn()) {
      const loginPage = loginParams?.page ?? this.DEFAULT_LOGIN_PAGE;

      this.$state.go(loginPage, {
        url: this.$state.href(this.$state.current.name as string, this.$state.params),
        ...loginParams?.params,
      });
    } else {
      this.$state.go('dashboard');
    }
  }

  private async authenticateTemplate(id: string) {
    const action = this.anonAuthService.authenticateAnonymously(AnonymousAuthenticationResourceType.Template, id);
    const result = await this.handleAuthenticationError(action);
    return this.persistAuthentication(result);
  }

  private async authenticateChecklist(id: string) {
    const action = this.anonAuthService.authenticateAnonymously(AnonymousAuthenticationResourceType.Checklist, id);
    const result = await this.handleAuthenticationError(action);
    return this.persistAuthentication(result);
  }

  private async handleAuthenticationError(action: Promise<AnonymousUserInfo>) {
    try {
      return action;
    } catch (response: any) {
      switch (response.status) {
        case HttpStatus.BAD_REQUEST:
          throw new Error(response.data.message);
        default:
          throw new Error('Internal server error occurred while initializing anonymous user.');
      }
    }
  }

  private persistAuthentication(authenticationResult: AnonymousUserInfo): User | null {
    this.reduxPersistorService.pausePersist();

    this.requestDefaultsState.token = this.sessionService.getToken();

    this.appBootService.setupSessionAndBootForAnonymousUser(authenticationResult);

    return this.anonAuthService.getAnonymousUser();
  }
}

angular.module('frontStreetApp.services').service('AnonymousAuthService', AnonymousAuthServiceImpl);

import * as React from 'react';
import {
  useDisclosure,
  useToast,
  Modal,
  Text,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ButtonGroup,
  Button,
} from 'components/design/next';
import { useTemplateMenuContext } from '..';
import { usePublicizeTemplateMutation, useGetTemplateQuery } from 'features/template/query-builder';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { PublicShareModal } from 'pages/library/components/template-library/template-row/public-share-modal';
import { match, P } from 'ts-pattern';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';

export type AddToPublicLibraryButtonProps = {
  children: (props: {
    isPublic: boolean;
  }) => React.ReactElement<{ onClick: React.MouseEventHandler<HTMLButtonElement>; isLoading: boolean }> | null;
};

export const AddToPublicLibraryButton: React.FC<AddToPublicLibraryButtonProps> = React.memo(({ children }) => {
  const { setCloseOnBlur, templateId } = useTemplateMenuContext();
  const disclosureOptions = {
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  };
  const disclosure = useDisclosure({
    ...disclosureOptions,
    id: 'publicize',
  });

  const publicShareDisclosure = useDisclosure({
    ...disclosureOptions,
    id: 'public share',
  });
  const templateQuery = useGetTemplateQuery({ templateId }, { enabled: Boolean(templateId) });
  const isPublic = !!templateQuery.data?.public;

  const toast = useToast();

  if (typeof children !== 'function') {
    throw new Error('`AddToPublicLibraryButton ` must be a render prop function');
  }
  const result = children({ isPublic });
  if (result === null || !React.isValidElement(result) || Array.isArray(result)) {
    throw new Error('The render prop function child must return a single clickable element  or a render prop function');
  }

  const publicizeMutation = usePublicizeTemplateMutation({
    onSuccess: () => {
      templateQuery.refetch();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Could not add workflow to the public library',
        description: 'Please verify your email address and try again.',
      });
    },
  });

  // One time effect to open the public share after the publicizing completes
  const publicShareOpenedOnSuccess = React.useRef(false);
  React.useEffect(() => {
    if (publicizeMutation.isSuccess && isPublic && !publicShareOpenedOnSuccess.current) {
      publicShareOpenedOnSuccess.current = true;
      publicShareDisclosure.onOpen();
    }
  }, [isPublic, publicShareDisclosure, publicizeMutation.isSuccess]);

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });

  const isPrivate = useIsPrivateTemplate(templateId);
  const canPublicize = permissionsQuery.data?.permissionMap.templateShare && !isPrivate;

  const publicize = () => {
    publicizeMutation.mutate({ templateId });
  };

  const btn = React.cloneElement(result, {
    onClick: () => {
      if (isPublic) {
        publicShareDisclosure.onToggle();
        return;
      }
      disclosure.onToggle();
    },
  });

  const isFree = useIsCurrentUserFreeMember();
  if (isFree) return <ButtonWithUpgradeTooltip>{result}</ButtonWithUpgradeTooltip>;

  if (!canPublicize) return null;

  return (
    <>
      {btn}

      {match(templateQuery)
        .with({ data: { public: true, publicUrl: P.not(undefined), publicId: P.not(undefined) } }, ({ data }) => (
          <PublicShareModal {...publicShareDisclosure} template={data} />
        ))
        .with({ data: { public: false } }, () => (
          <Modal {...disclosure} size="xl">
            <ModalOverlay />
            <ModalCloseButton />
            <ModalContent>
              <ModalHeader pt="8" pb="4">
                <Text variant="2" noOfLines={1}>
                  Publish to public library?
                </Text>
              </ModalHeader>
              <ModalBody pt="4" pb="8">
                <Text variant="1">
                  This workflow will be published to the public library.{' '}
                  <Text as="b" fontWeight="medium">
                    It will be accessible to the general public and indexed by search engines.
                  </Text>
                </Text>
                <Text variant="1" mt="4">
                  To reverse this action, contact support.
                </Text>
              </ModalBody>
              <ModalFooter borderTop="1px" borderColor="gray.200">
                <ButtonGroup>
                  <Button variant="ghost" onClick={disclosure.onClose}>
                    Cancel
                  </Button>
                  <Button onClick={publicize} isLoading={publicizeMutation.isLoading}>
                    Publish to Public Library
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ))
        .otherwise(() => null)}
    </>
  );
});

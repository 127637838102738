import {
  TemplateSolutionInstance,
  IncomingWebhook,
  IncomingWebhookOptimistic,
  SolutionTypeTag,
  IncomingWebhookAppName,
  TrayAutomationAppName,
  getTrayAutomationAppDetail,
  IncomingWebhookPrefix,
  TrayPrefix,
  WithIncomingWebhookPrefix,
  WithTrayPrefix,
  withTrayPrefix,
  withIncomingWebhookPrefix,
  TrayAutomationAppNames,
  INCOMING_WEBHOOK_APP_NAMES,
} from '@process-street/subgrade/automation';
import { match, P } from 'ts-pattern';
import { Muid } from '../core';
import { NativeAutomation, NativeAutomationWithLink } from '../process';

export type AutomationInstance =
  | TemplateSolutionInstance
  | IncomingWebhook
  | IncomingWebhookOptimistic
  | NativeAutomationWithLink;

export type AutomationAppName = TrayAutomationAppName | IncomingWebhookAppName;

export type AutomationAppType = TrayPrefix | IncomingWebhookPrefix | NativeAutomation.Prefix;

export function isAutomationAppName(name: unknown): name is AutomationAppName {
  return (
    typeof name === 'string' &&
    (TrayAutomationAppNames.includes(name as TrayAutomationAppName) ||
      INCOMING_WEBHOOK_APP_NAMES.includes(name as IncomingWebhookAppName))
  );
}

export function stripAutomationAppNamePrefix<PrefixedName extends AutomationAppName>(automationAppName: PrefixedName) {
  return automationAppName.split(':')[1] as PrefixedName extends WithTrayPrefix<infer AppName>
    ? AppName
    : PrefixedName extends WithIncomingWebhookPrefix<infer AppName>
    ? AppName
    : never;
}

export function isTrayAutomationAppName(name: AutomationAppName): name is TrayAutomationAppName {
  const prefix: TrayPrefix = 'tray';
  return name.startsWith(`${prefix}:`);
}

export function isIncomingWebhookAppName(name: AutomationAppName): name is IncomingWebhookAppName {
  const prefix: IncomingWebhookPrefix = 'webhook';
  return name.startsWith(`${prefix}:`);
}

export function getPrefixedAutomationAppName<Instance extends AutomationInstance>(automationInstance: Instance) {
  return match(automationInstance)
    .when(isTemplateSolutionInstance, obj => withTrayPrefix(obj.automationApp))
    .when(isIncomingWebhookInstance, obj => withIncomingWebhookPrefix(obj.automationApp))
    .run();
}

export function getAutomationAppNameLabel(appName: AutomationAppName): string {
  return match(appName)
    .when(isTrayAutomationAppName, name => getTrayAutomationAppDetail(name).label)
    .otherwise(() => stripAutomationAppNamePrefix(appName));
}

export function isTemplateSolutionInstance(obj: AutomationInstance | undefined): obj is TemplateSolutionInstance {
  return match(obj)
    .with({ solutionInstanceId: P.string }, () => true)
    .otherwise(() => false);
}

export function isNativeAutomationWithLink(obj: AutomationInstance | undefined): obj is NativeAutomationWithLink {
  return match(obj)
    .with({ automation: P.not(P.nullish), link: P.not(P.nullish) }, () => true)
    .otherwise(() => false);
}

export function isNullableTemplateSolutionInstance(
  obj: AutomationInstance | undefined,
): obj is TemplateSolutionInstance | undefined {
  return isTemplateSolutionInstance(obj) || obj === undefined;
}

export function isIncomingWebhookInstance(obj: AutomationInstance | undefined): obj is IncomingWebhook {
  return match(obj)
    .with({ templateId: P.string, action: 'CreateChecklist' }, () => true)
    .with({ dataSetId: P.string, action: 'UpdateDataSet' }, () => true)
    .otherwise(() => false);
}

export function isAutomationInstance(obj: any): obj is AutomationInstance {
  return isTemplateSolutionInstance(obj) || isIncomingWebhookInstance(obj);
}

const isEnabled = (obj: AutomationInstance): boolean => {
  return match(obj)
    .when(isTemplateSolutionInstance, obj => obj.configured && obj.enabled)
    .when(isIncomingWebhookInstance, obj => obj.status === 'Active')
    .when(isNativeAutomationWithLink, obj => obj.automation.status === 'Active')
    .otherwise(() => false);
};

const getSolutionTypeTag = (obj: AutomationInstance): SolutionTypeTag | undefined => {
  return match(obj)
    .when(isTemplateSolutionInstance, obj => obj.solutionTypeTag)
    .when(isIncomingWebhookInstance, () => SolutionTypeTag.CreateChecklistWhen)
    .when(isNativeAutomationWithLink, () => SolutionTypeTag.CreateChecklistWhen)
    .otherwise(() => undefined);
};

const getLabel = (obj: AutomationInstance): string => {
  return (
    match(obj)
      .when(isTemplateSolutionInstance, obj => obj.description)
      .when(isIncomingWebhookInstance, obj => obj.name || obj.automationApp)
      .otherwise(() => undefined) ?? '(untitled)'
  );
};

const getAutomationInstanceType = (obj: AutomationInstance): AutomationAppType => {
  return match<AutomationInstance, AutomationAppType>(obj)
    .when(isTemplateSolutionInstance, () => 'tray')
    .when(isIncomingWebhookInstance, () => 'webhook')
    .when(isNativeAutomationWithLink, () => 'native')
    .run();
};

const getInstanceId = (obj: AutomationInstance): Muid | undefined =>
  match(obj)
    .when(isNativeAutomationWithLink, i => i.automation.id)
    .when(isTemplateSolutionInstance, i => i.id)
    .when(isIncomingWebhookInstance, i => i.id)
    .otherwise(() => undefined);

export const AutomationInstanceUtils = {
  isEnabled,
  getSolutionTypeTag,
  getLabel,
  getAutomationInstanceType,
  getInstanceId,
};

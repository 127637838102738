import * as React from 'react';
import { useToast, Tooltip, Icon, Button, ButtonProps } from 'components/design/next';
import { useSendTestEmailMutation } from 'features/widgets/query-builder';
import { Muid } from '@process-street/subgrade/core';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { PaidPlanRequiredPopover } from 'features/ui/popovers/paid-plan-required/paid-plan-required-popover';
import { useGetIsPaidAccount } from 'utils/plans/get-is-paid-account/use-get-is-paid-account';

export const SendTestEmailButton: React.FC<
  React.PropsWithChildren<
    ButtonProps & {
      isRequired?: boolean;
      widgetHeaderId: Muid;
    }
  >
> = ({ widgetHeaderId, isDisabled, isRequired, ...props }) => {
  const toast = useToast();
  const user = useSelector(SessionSelector.getCurrentUser);

  const sendEmailMutation = useSendTestEmailMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: `Email sent to ${user?.email}`,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems sending the email",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const featureEnabled = useGetIsPaidAccount();

  const sendTestEmail = () => {
    sendEmailMutation.mutate({ widgetHeaderId });
  };

  const button = (
    <Button
      variant="tertiary"
      onClick={sendTestEmail}
      leftIcon={
        isDisabled || !featureEnabled ? (
          <Icon icon="exclamation-circle" size="4" variant="far" color="red.500" />
        ) : (
          <Icon icon="paper-plane" size="4" variant="far" />
        )
      }
      iconSpacing="2"
      isLoading={sendEmailMutation.isLoading}
      isDisabled={!featureEnabled || isDisabled}
      {...props}
      {...{
        ...(isRequired
          ? {
              'aria-required': true,
              '_after': {
                content: '"*"',
                color: 'red.500',
                position: 'absolute',
                top: '0',
                right: '-3',
                lineHeight: 'none',
              },
            }
          : {}),
      }}
    >
      Send Test
    </Button>
  );

  return featureEnabled ? (
    <Tooltip
      label={
        isDisabled ? 'Email is missing subject and body' : `Test email will be sent to ${user?.email ?? 'your email'}`
      }
      hasArrow
      shouldWrapChildren
    >
      {button}
    </Tooltip>
  ) : (
    <PaidPlanRequiredPopover needPaidFor="performAction">{button}</PaidPlanRequiredPopover>
  );
};

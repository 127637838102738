import { Muid, Option } from '@process-street/subgrade/core';
import {
  BulkApprovalStatuses,
  EmailInitiatedApprovalModuleState,
  EmailInitiatedApprovalState,
  ReduxAppState,
  Status,
} from 'reducers/types';
import { createSelector, Selector } from 'reselect';

const getBulkApprovalRequestStatuses = (state: ReduxAppState): BulkApprovalStatuses => state.statuses.bulkApprovals;

const getBulkApprovalRequestStatusByTaskId = (taskId: Muid): Selector<ReduxAppState, Option<Status>> =>
  createSelector(getBulkApprovalRequestStatuses, status => status.byTaskId[taskId]);

const getRequestCompleteStatusByTaskId = (taskId: Muid): Selector<ReduxAppState, boolean> =>
  createSelector(getBulkApprovalRequestStatusByTaskId(taskId), (status: Option<Status>) => {
    if (!status) {
      return false;
    }
    return status.loaded;
  });

const moduleState = (state: ReduxAppState): EmailInitiatedApprovalModuleState => state.modules.emailInitiatedApproval;

const emailInitiatedApprovalState = (
  taskId: Muid,
): ((appState: ReduxAppState) => Option<EmailInitiatedApprovalState>) =>
  createSelector(moduleState, (state: EmailInitiatedApprovalModuleState) => state[taskId]);

export const EmailInitiatedApprovalSelectors = {
  emailInitiatedApprovalState,
  getRequestCompleteStatusByTaskId,
};

import * as React from 'react';
import { useSelector } from '@xstate/react';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from 'components/design/next';
import { SelectItemActorRef, Selectors } from './select-item-machine';
import { useOS } from 'services/os-util';
import { ReorderUtils } from '../../widgets-list/reorder-utils';

type SelectItemProps = {
  actor: SelectItemActorRef;
  index: number;
};

export const SelectItem: React.FC<React.PropsWithChildren<SelectItemProps>> = ({ actor, index }) => {
  const { send } = actor;
  const item = useSelector(actor, Selectors.getItem);

  const { metaSymbol } = useOS();

  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    actor.send({ type: 'SET_INPUT_REF', ref });
  }, [actor]);

  return (
    <HStack spacing="3" position="relative" data-testid="select-item">
      <Box w="4">
        <Text
          w="full"
          fontSize="sm"
          color="gray.400"
          textAlign="right"
          display="inline-block"
          sx={{
            // the widget itself is an LI
            [`li#${item.id}:hover &`]: {
              display: 'none',
            },
          }}
        >
          {index + 1}
        </Text>
        <Icon
          aria-label={`Use ${metaSymbol} + up or down to re-arrange the items`}
          icon="grip-vertical"
          {...ReorderUtils.HANDLE_PROPS}
          variant="fas"
          w="full"
          size="3"
          color="gray.400"
          display="none"
          sx={{
            [`li#${item.id}:hover &`]: {
              display: 'inline-block',
            },
          }}
          cursor="grab"
        />
      </Box>

      <InputGroup onPointerDownCapture={e => e.stopPropagation()}>
        {/* // Prevent dragging  */}
        <Input
          ref={ref}
          bg="white"
          key={`option-input:${item.id}`}
          placeholder={`Option ${index + 1}`}
          value={item.name}
          onChange={e => send({ type: 'CHANGE', value: e.target.value })}
          onKeyDown={e => send({ type: 'KEY_DOWN', event: e })}
          onBlur={() => send({ type: 'BLUR' })}
          onFocus={() => send({ type: 'FOCUS' })}
        />
        <InputRightElement>
          <Tooltip label="Remove item">
            <IconButton
              aria-label="Delete option"
              variant="ghost"
              size="sm"
              borderRadius="full"
              opacity={0}
              sx={{
                '.chakra-input__group:hover > .chakra-input__right-element > &': { opacity: 1 },
                '.chakra-input__group:focus-within > .chakra-input__right-element > &': { opacity: 1 },
              }}
              onClick={() => send({ type: 'REMOVE' })}
            >
              <Icon size="4" color="gray.400" icon="trash-can" />
            </IconButton>
          </Tooltip>
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
};

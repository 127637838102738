import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormControl, FormErrorMessage, InputProps } from 'components/design/next';
import { formikMetaToFormControlProps } from '../common/utils';
import { useGetAllGroupsQuery } from 'app/features/group/query-builder/get-all-groups';
import { GroupType } from '@process-street/subgrade/core';
import { convertGroupToMemberItem, GroupItem, GroupPicker } from './group-picker';

export const MembersValueField: React.FC<React.PropsWithChildren<InputProps & { id: string }>> = ({ id }) => {
  const [field, meta] = useField<string>(MembersValueFieldName);
  const { handleSubmit, setFieldValue } = useFormikContext();
  const [value, setValue] = React.useState<GroupItem>();
  const groupsQuery = useGetAllGroupsQuery(
    { include: 'user' },
    {
      select: groups =>
        groups
          .filter(group => group.groupType === GroupType.AllMembers || group.groupType === GroupType.Standard)
          .map(convertGroupToMemberItem),
    },
  );

  const memberItems = React.useMemo(() => groupsQuery.data ?? [], [groupsQuery.data]);
  React.useEffect(
    function setDefaultValue() {
      const foundMember = memberItems.find(member => member.id === field.value);
      if (foundMember) {
        setValue(foundMember);
      }
    },
    [memberItems, field.value],
  );

  const handleChange = (member: GroupItem) => {
    if (member.id === value?.id) return;
    setValue(member);
    setFieldValue(MembersValueFieldName, member?.id);
    handleSubmit();
  };

  return (
    <FormControl {...formikMetaToFormControlProps(meta)}>
      <GroupPicker items={memberItems} id={id} value={value} onSelect={handleChange} />
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export const MembersValueFieldName = 'config.groupId';

import * as React from 'react';
import { DateFormat } from '@process-street/subgrade/util';
import { Button, Icon, IconButton } from 'components/design/next';
import dayjs from 'dayjs';
import { DatePicker } from 'features/conditional-logic/components/date-picker';
import { InboxItem, InboxItemUtils } from '@process-street/subgrade/inbox';
import { GetDueDateRulesByChecklistRevisionIdQuery } from 'features/dynamic-due-dates/query-builder';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useCurrentUser } from 'hooks/use-current-user';
import { DynamicDueDate } from './dynamic-due-date';
import { match, P } from 'ts-pattern';
import { GetAllTaskTemplatesByChecklistRevisionIdQuery } from 'features/task-templates/query-builder/task-templates-by-checklist-revision-id';
import { isDateFormFieldWidget } from '@process-street/subgrade/process';
import { WidgetsByChecklistRevisionIdQuery } from 'features/widgets/query-builder/widgets-by-checklist-revision-id';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { UpdateChecklistDueDateMutation } from 'features/checklists/query-builder';
import { UpdateTaskDueDateMutation } from 'features/one-off-tasks/query-builder';
import { useInboxItemsDataSource } from 'pages/tasks/hooks/use-inbox-items-data-source';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { useFeatureFlag } from 'app/features/feature-flags';

export type InboxItemDueDatePickerProps = {
  inboxItem: InboxItem;
};

export const InboxItemDueDatePicker: React.FC<React.PropsWithChildren<InboxItemDueDatePickerProps>> = ({
  inboxItem,
}) => {
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');
  const [innerValue, setInnerValue] = React.useState<number | undefined>(InboxItemUtils.getDueDate(inboxItem));

  const { invalidate: invalidateInboxData } = useInboxItemsDataSource();
  const toast = useNextThemeToast();

  const currentUser = useCurrentUser();
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: inboxItem.checklist.id,
  });
  const [shouldOverrideDynamicDueDate, setShouldOverrideDynamicDueDate] = React.useState(false);

  const checklistRevisionId = checklistRevisionQuery.data?.id;

  const dynamicDueDatesQuery = GetDueDateRulesByChecklistRevisionIdQuery.useQuery(
    {
      checklistRevisionId: checklistRevisionId ?? '',
    },
    {
      enabled: Boolean(checklistRevisionId),
    },
  );

  const dynamicDueDateForItem = match(inboxItem)
    .with({ task: { taskTemplate: { group: { id: P.string } } } }, item =>
      dynamicDueDatesQuery.data?.find(rule => rule.targetTaskTemplateGroup?.id === item.task.taskTemplate.group.id),
    )
    .otherwise(() => undefined);

  const taskTemplatesQuery = GetAllTaskTemplatesByChecklistRevisionIdQuery.useQuery(
    { checklistRevisionId },
    { enabled: Boolean(checklistRevisionId) },
  );

  const widgetsQuery = WidgetsByChecklistRevisionIdQuery.useQuery(
    { checklistRevisionId },
    { enabled: Boolean(checklistRevisionId) },
  );

  const handleDueDateUpdateError = () => {
    toast({
      status: 'error',
      title: `We're having problems updating the due date`,
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  };

  const handleDueDateUpdateSuccess = () => {
    invalidateInboxData();
  };

  const updateChecklistDueDateMutation = UpdateChecklistDueDateMutation.useMutation({
    onSuccess: handleDueDateUpdateSuccess,
    onError: handleDueDateUpdateError,
  });

  const updateTaskDueDateMutation = UpdateTaskDueDateMutation.useMutation({
    onSuccess: handleDueDateUpdateSuccess,
    onError: handleDueDateUpdateError,
  });

  const dateWidgets = widgetsQuery.data?.filter(isDateFormFieldWidget) ?? [];

  const renderTrigger = (value: number | undefined | null, timeZone: string) => {
    if (!value) {
      return (
        <IconButton
          justifyContent="center"
          aria-label="Due date picker"
          size="sm"
          variant="outline"
          borderWidth="px"
          borderColor="gray.300"
          color="gray.500"
          icon={<Icon icon="clock" size="4" variant="far" />}
        />
      );
    }
    const formattedDate = dayjs(value).tz(timeZone).format(DateFormat.DateTimeMergeTagLong);

    return (
      <Button
        aria-label="Due date picker"
        size="sm"
        variant="outline"
        borderWidth="px"
        borderColor="gray.300"
        color="gray.500"
        fontWeight={isMyWorkGAEnabled ? 'normal' : 'inherit'}
      >
        {isMyWorkGAEnabled ? <Icon icon="clock" size="4" variant="far" color="purple.500" mr="2" /> : null}
        {formattedDate}
      </Button>
    );
  };

  const renderContent = (content: React.ReactElement) => {
    if (dynamicDueDateForItem && !innerValue && !shouldOverrideDynamicDueDate)
      return (
        <DynamicDueDate
          rule={dynamicDueDateForItem}
          dateWidgets={dateWidgets}
          taskTemplates={taskTemplatesQuery.data ?? []}
          onOverride={() => setShouldOverrideDynamicDueDate(true)}
        />
      );

    return <ThemeProvider2024>{content}</ThemeProvider2024>;
  };

  const handleChange = (newDueDate: number | null) => {
    setInnerValue(newDueDate ?? undefined);

    if (InboxItemUtils.isChecklist(inboxItem)) {
      updateChecklistDueDateMutation.mutate({
        id: inboxItem.checklist.id,
        dueDate: newDueDate,
      });
    }

    if (InboxItemUtils.isTask(inboxItem)) {
      updateTaskDueDateMutation.mutate({
        id: inboxItem.task.id,
        dueDate: newDueDate,
      });
    }
  };

  return (
    <DatePicker
      onChange={handleChange}
      value={innerValue}
      timeZone={currentUser?.timeZone}
      renderTrigger={renderTrigger}
      renderContent={renderContent}
      pickerProps={{
        disabled: [
          {
            before: dayjs()
              .tz(currentUser?.timeZone ?? 'UTC')
              .toDate(),
          },
        ],
        styles: {
          cell: {
            fontSize: 'var(--ps-fontSizes-sm)',
          },
        },
      }}
      popoverProps={{ placement: 'bottom' }}
    />
  );
};

import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { DropzoneState, useDropzone } from 'react-dropzone';

import { Template } from '@process-street/subgrade/process';
import {
  useFinishUploadCoverImageMutation,
  useUploadToS3Mutation,
  useUploadUrlCoverImageMutation,
} from 'features/cover-image/query-builder';

export const SIZE_TOO_LARGE = 'size-too-large';
export const MAX_FILE_SIZE = 250 * 1024 * 1024;

type UploadCoverImageReturn = {
  dropzoneState: DropzoneState;
  progress: number | undefined;
  uploadError: AxiosError | null;
  upload: (acceptedFiles: File[]) => void;
};

type UploadCoverImageProps = {
  templateId: Template['id'];
  onFinish: (templateId: Template['id']) => void;
  accept: string | undefined;
};

export const useUploadCoverImage = ({
  templateId,
  onFinish,
  accept,
}: UploadCoverImageProps): UploadCoverImageReturn => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const uploadUrlMutation = useUploadUrlCoverImageMutation();

  const uploadToS3Mutation = useUploadToS3Mutation();

  const finishUploadCoverImageMutation = useFinishUploadCoverImageMutation({
    onSuccess: data => {
      onFinish(data.templateId);
    },
  });

  const uploadError = uploadUrlMutation.error ?? uploadToS3Mutation.error ?? finishUploadCoverImageMutation.error;

  useEffect(() => {
    if (uploadError) {
      setProgress(undefined);
    }
  }, [uploadError]);

  const upload = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const [file] = acceptedFiles;

    const reader = new FileReader();

    const { url, key } = await uploadUrlMutation.mutateAsync(
      {
        templateId,
        fileName: file.name,
        mimeType: file.type,
      },
      {
        onSuccess: () => {
          setProgress(1);

          reader.readAsArrayBuffer(file);
        },
      },
    );

    reader.onload = async () => {
      if (reader.readyState === 2 && reader.result !== null) {
        uploadToS3Mutation.mutateAsync(
          {
            file,
            url,
            data: reader.result,
            onProgress: (progress: number) => setProgress(progress),
          },
          {
            onSuccess: () => {
              finishUploadCoverImageMutation.mutateAsync({
                templateId,
                contentType: file.type,
                originalFilename: file.name,
                key,
              });
            },
          },
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
  }, []);

  const dropzoneState = useDropzone({
    onDrop: upload,
    accept,
    validator: file => {
      if (file.size > MAX_FILE_SIZE) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must be under ${MAX_FILE_SIZE} MB.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
    progress,
    uploadError,
    upload,
  };
};

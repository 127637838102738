import angular from 'angular';
import {
  FieldType,
  isFormFieldWidget,
  TaskConstants,
  WidgetType,
  WidgetUtils,
  WidgetValidation,
} from '@process-street/subgrade/process';
import Muid from 'node-muid';
import { uuid } from 'services/uuid';
import { canAccess, Feature } from 'services/features/features';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { WidgetConstants } from '@process-street/subgrade/process/widget-constants';
import { RoleAssignmentRuleSelector } from 'components/role-assignments/store/role-assignment-rules.selector';
import { FormFieldDefaultKey } from 'services/form-field-service-constants';
import { MoveWidgetMutation } from 'features/widgets/query-builder/move-widget-mutation';
import { noop } from '@process-street/subgrade/util';
import { bindActionCreatorsToActions } from 'reducers/util';
import { queryClient } from 'components/react-root';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { QueryObserver } from 'react-query';
import { match } from 'ts-pattern';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { ArrayService } from 'services/array-service';
import './template-widgets-container.component.html';
import './template-widgets-container.scss';
import './task-bar-container--editor-ux.scss';
import './task-content-ux.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { ablyService } from 'app/pusher/ably.service';
import { AblyEvent } from 'app/pusher/ably-event';
import { GetWidgetByTaskTemplateIdQuery } from './query-builder';
import { trace } from 'components/trace';
import { AiGeneratorAnimationService } from 'services/ai-generator-animation-service';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { MergeTagReferenceUpdateService } from './merge-tag-reference-update-service';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { MuidUtils } from '@process-street/subgrade/core';
import { AnalyticsService } from 'components/analytics/analytics.service';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplateWidgetsCtrl',
    function (
      $anchorScroll,
      $ngRedux,
      $q,
      $rootScope,
      $scope,
      $timeout,
      ConditionalsButtonService,
      FeatureFlagService,
      FileUploadService,
      focusById,
      FormFieldService,
      SessionService,
      Subject,
      OrderTreeBulkUpdater,
      OrderTreeService,
      OrganizationService,
      RoleAssignmentRulesActions,
      SecurityService,
      TaskTemplateService,
      util,
      WidgetActions,
      WidgetService,
      ToastService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'WidgetsCtrl' });
      $scope.maxFileSize = WidgetConstants.VIDEO_MAX_FILE_SIZE;

      $scope.rulesModalIsOpen = false;
      $scope.rulesFeatureIsAvailable = false;
      $scope.userIsAdmin = false;
      $scope.initialized = false;

      const orderTreesBulkUpdater = new OrderTreeBulkUpdater(WidgetService.updateOrderTrees, {
        onSuccess: WidgetService.onOrderTreesBulkUpdateSuccess,
        onFailure: WidgetService.onOrderTreesBulkUpdateFailure,
        setOrderTree(orderTree, widget) {
          widget.header.orderTree = orderTree;
        },
        getOrderTree(widget) {
          return widget.header.orderTree;
        },
      });

      ctrl.$onInit = () => {
        $scope.sidebarHidden = SessionService.getTemplateEditorProperty('sidebarHidden');

        const deregisterWatch = $scope.$watch('templateRevision', templateRevision => {
          if (templateRevision) {
            deregisterWatch();

            OrganizationService.getById(templateRevision.organization.id).then(
              organization => {
                const planId = organization && organization.subscription.plan.id;
                $scope.dynamicDueDateEnabled = canAccess(Feature.DYNAMIC_DUE_DATES, planId);
                $scope.stopTaskEnabled = canAccess(Feature.STOP_TASK, planId);
                $scope.mergeTagsEnabled = canAccess(Feature.MERGE_TAGS, planId);

                $scope.organization = organization;

                initializeSortable();
                retrieveWidgets(templateRevision);
                reinitializeState(templateRevision, $scope.activeStepTaskTemplate);

                if ($scope.editable) {
                  $scope.listenToTaskTemplateWidgetsGeneration();
                  $scope.subscribeToTemplateRevisionUpdates();
                }
              },
              () => {
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems loading the organization`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });
              },
            );
          }

          $scope.timeZone = $scope.user.timeZone;
        });

        $scope.subscribeToGetAllRulesByTemplateRevisionIdQuery();
        ConditionalsButtonService.initializeFeatures().then(result => {
          if (!result) return;
          const { userIsAdmin, rulesFeatureIsAvailable } = result;
          $scope.initialized = true;
          $scope.userIsAdmin = userIsAdmin;
          $scope.rulesFeatureIsAvailable = rulesFeatureIsAvailable;
        });
      };

      $scope.unsubscribe = () => {
        /* do nothing */
      };

      function reinitializeState(templateRevision, activeTaskTemplate) {
        $scope.unsubscribe();

        const mapStateToThis = () => state => {
          const assignmentRules = RoleAssignmentRuleSelector.getAllByTemplateRevisionId(
            templateRevision.id,
            activeTaskTemplate.group.id,
          )(state);

          const featureFlags = FeatureFlagSelector.getFeatureFlags(state);
          const showLegacyEmailWidgetEnabled = featureFlags.showLegacyEmailWidget;
          const emailWidgetImprovementsEnabled = featureFlags.emailWidgetImprovements;
          const reactifiedTaskButtonStackEnabled = featureFlags.reactifiedTaskButtonStack;
          const dynamicDueDatesWfrEnabled = featureFlags.dynamicDueDatesWfr;
          const { conditionalLogicMembersField, tableFormField } = featureFlags;

          return {
            assignmentRules,
            showLegacyEmailWidgetEnabled,
            emailWidgetImprovementsEnabled,
            reactifiedTaskButtonStackEnabled,
            dynamicDueDatesWfrEnabled,
            conditionalLogicMembersField,
            tableFormField,
            featureFlags,
          };
        };

        const mapDispatchToThis = bindActionCreatorsToActions({
          getAllRulesByTemplateRevisionId: RoleAssignmentRulesActions.getAllByTemplateRevisionId,
          moveWidget: WidgetActions.move,
        });

        $scope.unsubscribe = $ngRedux.connect(mapStateToThis, mapDispatchToThis)($scope);

        $scope.actions.getAllRulesByTemplateRevisionId(templateRevision.id);
      }

      $scope.shouldShowTemplateTaskAssigner = function () {
        return (
          $scope.editable ||
          ($scope.activeStep && $scope.activeStepAssignees.length) ||
          ($scope.assignmentRules && $scope.assignmentRules.length)
        );
      };

      $scope.widgetInViewMap = {};

      $scope.isWidgetRenderable = widget => ($scope.widgetInViewMap[widget.id] ? true : undefined);

      // Sortable

      $scope.dropToIndex = null;

      function initializeSortable() {
        $scope.widgetsSortableOptions = {
          out() {
            $scope.dropToIndex = null;
          },
          start(__event, ui) {
            angular.element('[data-ui-sortable=widgetsSortableOptions]').sortable('refreshPositions');
            ui.placeholder.height(2);
            $scope.widgetDragging = true;
          },
          stop(__event, ui) {
            if (ui.item[0].getAttribute('data-type')) {
              ui.item.remove();
            }
            $scope.widgetDragging = false;
          },
          update(__event, ui) {
            const type = ui.item[0].getAttribute('data-type');
            if (!type) {
              const indexes = [ui.item.sortable.index];
              const newIndexes = [ui.item.sortable.dropindex];
              const widgets = $scope.widgetsMap[$scope.activeStep.id];

              orderTreesBulkUpdater.moveAt(indexes, newIndexes, widgets);
            }
          },
          beforeStop(__event, ui) {
            const type = ui.item[0].getAttribute('data-type');
            if ($scope.dropToIndex !== null && type) {
              ui.item.remove();
              $scope.addWidgetByType(type);
              ui.item.sortable._connectedSortables = [
                {
                  element: [undefined],
                  scope: { ngModel: ui.item.sortable.droptargetModel },
                },
              ];
            }
            $scope.dropToIndex = null;
          },
          sort(__event, ui) {
            const index = ui.placeholder.index();
            const widgets = $scope.widgetsMap[$scope.activeStep.id];
            const referenceTree = widgets[index] ? widgets[index].header.orderTree : null;
            const orderTrees = util.toOrderTrees(widgets, toHeader);
            [$scope.dropToIndex] = OrderTreeService.before(orderTrees, referenceTree);
          },
        };

        $scope.widgetsSortableOptions = $scope.widgetsSortableOptions || {};
        $scope.widgetsSortableOptions.disabled = !$scope.editable;
        $scope.widgetsSortableOptions.revert = true;
        $scope.widgetsSortableOptions.scrollSensitivity = 70;
        $scope.widgetsSortableOptions.axis = 'y';
        $scope.widgetsSortableOptions.cursor = 'move';
        $scope.widgetsSortableOptions.opacity = 0.5;
        $scope.widgetsSortableOptions.placeholder = 'sortable-placeholder';
        $scope.widgetsSortableOptions.cursorAt = { top: 50 };
        $scope.widgetsSortableOptions.zIndex = 9;
        $scope.widgetsSortableOptions.handle = '.widget-container__drag-handle';
      }

      $scope.addWidgetByType = function (type) {
        switch (type) {
          case WidgetConstants.WidgetBarType.FORM_MULTI_SELECT:
            $scope.createMultiSelectFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_MEMBERS:
            $scope.createMembersFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_MULTI_CHOICE:
            $scope.createMultiChoiceFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_TEXT:
            $scope.createTextFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_TEXTAREA:
            $scope.createTextAreaFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_EMAIL:
            $scope.createEmailFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_URL:
            $scope.createUrlFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_FILE:
            $scope.createFileFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_DATE:
            $scope.createDateFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_SELECT:
            $scope.createSelectFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_SNIPPET:
            $scope.createSnippetFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_HIDDEN:
            $scope.createHiddenFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_SEND_RICH_EMAIL:
            $scope.createSendRichEmailWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_NUMBER:
            $scope.createNumberFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.FORM_TABLE:
            $scope.createTableFieldWidget();
            break;
          case WidgetConstants.WidgetBarType.TEXT:
          case WidgetConstants.WidgetBarType.IMAGE:
          case WidgetConstants.WidgetBarType.VIDEO:
          case WidgetConstants.WidgetBarType.FILE:
          case WidgetConstants.WidgetBarType.EMAIL:
          case WidgetConstants.WidgetBarType.EMBED:
          case WidgetConstants.WidgetBarType.CROSS_LINK:
            $scope.createSimpleWidget($scope.activeStep, type);
            break;
          default:
            logger.error('unexpected widget type: %s', type);
        }
      };

      $scope.handleWidgetsScroll = function () {
        if ($scope.widgetDragging) {
          angular.element('[data-ui-sortable=widgetsSortableOptions]').sortable('refreshPositions');
        }
      };

      $scope.groupWidgetsByStepId = (templateRevision, widgets) => {
        const widgetsForSteps = {};
        widgets.forEach(widget => {
          if (templateRevision.status !== 'Draft' && WidgetService.isEmpty(widget)) {
            widget._hidden = true;
          }

          const stepId = widget.header.taskTemplate.group.id;
          const stepWidgets = widgetsForSteps[stepId] || [];
          stepWidgets.push(widget);

          widgetsForSteps[stepId] = stepWidgets;
        });

        return widgetsForSteps;
      };

      $scope.updateExistingWidgetsMap = (newWidgetsMap, options = { animate: false }) => {
        // When not animating, we assign all the widgets to the scope instantly
        if (!options.animate) {
          Object.keys(newWidgetsMap).forEach(stepId => {
            $scope.widgetsMap[stepId] = newWidgetsMap[stepId];
          });
        }
        angular.forEach(newWidgetsMap, WidgetService.sortWidgets);

        if (options.animate) {
          animateWidgetsCreation(newWidgetsMap);
        }
      };

      // REST
      function retrieveWidgets(templateRevision) {
        WidgetService.getAllByTemplateRevisionId(templateRevision.id, true /* flushCache */).then(widgets => {
          $scope.widgetsLoaded = true;

          const widgetsForSteps = {};
          widgets.forEach(widget => {
            if (templateRevision.status !== 'Draft' && WidgetService.isEmpty(widget)) {
              widget._hidden = true;
            }

            const stepId = widget.header.taskTemplate.group.id;
            const stepWidgets = widgetsForSteps[stepId] || [];
            stepWidgets.push(widget);

            widgetsForSteps[stepId] = stepWidgets;
          });

          $scope.updateExistingWidgetsMap(widgetsForSteps);
        });
      }

      // Tasks

      $scope.isLastTask = function (step) {
        return $scope.steps.indexOf(step) === $scope.steps.length - 1;
      };

      // Widget create

      $scope.createSimpleWidget = function (step, type, method) {
        const taskTemplate = $scope.activeStepTaskTemplate;
        const widgets = $scope.widgetsMap[step.id];

        const widget = {
          header: {
            id: Muid.fromUuid(uuid()),
            taskTemplate,
            type,
            orderTree: $scope.getOrderTreeValue(widgets),
          },
        };

        return $scope.createWidget(widget, method);
      };

      $scope.getOrderTreeValue = function (widgets) {
        return $scope.dropToIndex
          ? $scope.dropToIndex.toString()
          : OrderTreeService.before(util.toOrderTrees(widgets, toHeader), null /* orderTree */)[0];
      };

      $scope.createWidget = function (widget, method) {
        return WidgetService.create(widget, {
          taskTemplate: widget.header.taskTemplate,
          method,
        });
      };

      $scope.copyWidget = function (widget) {
        const taskTemplate = $scope.activeStepTaskTemplate;
        const widgets = $scope.widgetsMap[$scope.activeStep.id];
        const [newOrderTree] = OrderTreeService.after(util.toOrderTrees(widgets, toHeader), widget.header.orderTree);

        const widgetType = widget.header.type;

        AnalyticsService.trackEvent('widget copied', {
          'widget id': widget.id,
          'widget type': widgetType,
          'field type': widget.fieldType,
          'step name': $scope.activeStep.name,
        });

        switch (widgetType) {
          case WidgetType.FormField:
            return copyFormFieldWidget(widget, taskTemplate, newOrderTree);
          default:
            return copySimpleWidget(widget, taskTemplate, newOrderTree);
        }
      };

      $scope.moveWidget = (widget, targetTaskTemplate) => {
        const targetTaskWidgets = $scope.widgetsMap[targetTaskTemplate.group.id];
        let newOrderTree;
        if (targetTaskWidgets.length === 0) {
          newOrderTree = '1';
        } else {
          [newOrderTree] = OrderTreeService.before(
            util.toOrderTrees(targetTaskWidgets, toHeader),
            targetTaskWidgets[0].header.orderTree,
          );
        }

        const widgetType = widget.header.type;
        const targetTaskName = targetTaskTemplate.name ?? TaskConstants.DefaultTaskName;

        AnalyticsService.trackEvent('widget moved', {
          'widget id': widget.id,
          'widget type': widgetType,
          'field type': widget.fieldType,
          'target step name': targetTaskName,
        });

        MoveWidgetMutation.mutationFn({
          headerId: widget.header.id,
          templateRevisionId: $scope.templateRevision.id,
          targetTaskTemplateId: targetTaskTemplate.id,
          targetWidgetOrderTree: newOrderTree,
        }).then(widgetHeader => {
          const targetHeader = { ...widgetHeader, taskTemplate: targetTaskTemplate };
          $scope.syncMovedWidget(widget, targetHeader);

          const href = `/workflows/${$scope.templateRevision.template.id}/edit/tasks/${targetTaskTemplate.group.id}`;

          ToastService.openToast({
            status: 'success',
            title: `Widget successfully moved to <a href='${href}' >${targetTaskName}</a>`,
          });
        });
      };

      $scope.movedWidgetsMap = {};
      $scope.syncMovedWidget = (srcWidget, dstWidgetHeader) => {
        const dstWidget = { ...srcWidget, header: dstWidgetHeader };

        $scope.movedWidgetsMap[srcWidget.id] = srcWidget.header.taskTemplate.name ?? TaskConstants.DefaultTaskName;
        $scope.removeWidgetFromMap(srcWidget);
        $scope.addWidgetToMap(dstWidget);

        $scope.actions.moveWidget(dstWidget, srcWidget.header.taskTemplate.id);
      };

      // move widget dropdowns
      $scope.widgetMenuOpenMap = {};
      $scope.setPseudoHoverOn = widgetId => {
        $rootScope.$evalAsync(() => {
          $scope.widgetMenuOpenMap[widgetId] = true;
        });
      };
      $scope.setPseudoHoverOff = widgetId => {
        $rootScope.$evalAsync(() => {
          $scope.widgetMenuOpenMap[widgetId] = false;
        });
      };

      function copyFormFieldWidget(srcWidget, taskTemplate, newOrderTree) {
        const keys = FormFieldService.toKeys($scope.widgetsMap);
        const key = FormFieldService.generateKeyForCopy(keys, srcWidget.key);

        let { label } = srcWidget;
        if (srcWidget.label) {
          const labels = FormFieldService.toLabels($scope.widgetsMap);
          label = FormFieldService.generateLabelForCopy(labels, srcWidget.label);
        }

        const dstWidget = {
          header: {
            id: Muid.fromUuid(uuid()),
            taskTemplate,
            type: srcWidget.header.type,
            orderTree: newOrderTree,
          },
          fieldType: srcWidget.fieldType,
          config: srcWidget.config,
          key,
          label,
          helpText: srcWidget.helpText,
          constraints: srcWidget.constraints,
        };

        return WidgetService.copy(srcWidget, dstWidget);
      }

      function copySimpleWidget(srcWidget, taskTemplate, newOrderTree) {
        const dstWidget = {
          header: {
            id: Muid.fromUuid(uuid()),
            taskTemplate,
            type: srcWidget.header.type,
            orderTree: newOrderTree,
          },
        };

        return WidgetService.copy(srcWidget, dstWidget);
      }

      $scope.addWidgetToMap = widget => {
        const groupId = widget.header.taskTemplate.group.id;
        // For some reason first dragged widget always added to bottom. Copy of the array fixed this problem.
        const widgets = $scope.widgetsMap[groupId].slice();
        widgets.push(widget);
        WidgetService.sortWidgets(widgets);
        $scope.widgetsMap[groupId] = widgets;
        return widgets;
      };

      $scope.$on(EventName.WIDGET_CREATE_STARTED, (__event, widget) => {
        widget._creating = true;

        const widgets = $scope.addWidgetToMap(widget);

        // If the widget is the last widget in the list, then we'll scroll to it
        if (widgets[widgets.length - 1] === widget) {
          $timeout(() => {
            // This scrolls us to the new widget
            $anchorScroll(`widget-${widget.header.id}`);
          });
        }

        focusById(`widget-${widget.header.id}`);
      });

      $scope.$on(EventName.WIDGET_CREATE_FAILED, (__event, widget) => {
        ToastService.openToast({
          status: 'error',
          title: `We're having problems creating the widget`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });

        $scope.removeWidgetFromMap(widget);
      });

      $scope.$on(EventName.WIDGET_CREATE_FINISHED, (__event, widget) => {
        delete widget._creating;
      });

      // Widget update
      $scope.updateWidget = function (widget) {
        const label = widget.label ? widget.label.trim() : FormFieldDefaultKey[widget.fieldType];
        const keys = FormFieldService.toKeys($scope.widgetsMap, widget);
        const key = FormFieldService.generateKeyFromLabel(label);
        const oldKey = widget.key;
        widget.key = FormFieldService.generateUniqueKey(keys, key);

        const data = {
          taskTemplate: widget.header.taskTemplate,
        };

        if (widget._creating) {
          const deferred = $q.defer();
          widget._onCreated = function () {
            util.pipe(deferred, WidgetService.update(widget, data));
          };
          return deferred.promise;
        } else {
          return WidgetService.update(widget, data).then(async () => {
            const widgets = Object.values($scope.widgetsMap).flat();

            await MergeTagReferenceUpdateService.persist({
              widgets,
              newKey: widget.key,
              oldKey,
              queryClient,
              templateRevision: $scope.templateRevision,
              updateWidget: WidgetService.update,
              $rootScope,
            });

            return widget;
          });
        }
      };

      $scope.$on(EventName.WIDGET_UPDATE_OK, (__event, widget) => {
        // We don't want to override the widget in the widgetsMap if
        // it is a regular 'Text' widget (not a form field).
        // By doing so, we avoid the widgets model to refresh and cause a
        // re-render in the text editor, causing issues with the typing cursor
        if (widget.header.type !== WidgetType.Text) {
          $timeout(() => {
            // We need to manually update the widgetsMap with the new widget to force the re-render
            // otherwise, react component was getting unupdated information
            const index = $scope.widgetsMap[$scope.activeStep.id].findIndex(w => w.id === widget.id);
            $scope.widgetsMap[$scope.activeStep.id][index] = {
              ...widget,
            };
          });
        }
      });

      $scope.$on(EventName.TEXT_WIDGET_CONTENT_GENERATION_OK, (__event, widget) => {
        $timeout(() => {
          // We need to manually update the widgetsMap with the new widget to force the re-render
          // otherwise, react component was getting unupdated information
          const index = $scope.widgetsMap[$scope.activeStep.id].findIndex(w => w.id === widget.id);
          $scope.widgetsMap[$scope.activeStep.id][index] = {
            ...widget,
          };
        });
      });

      $scope.$on(EventName.WIDGET_UPDATE_FAILED, (__event, widget) => {
        // Mark it is failed so we can show it in the HTML
        widget._updateFailed = true;

        ToastService.openToast({
          status: 'error',
          title: `We're having problems updating the widget`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });

      $scope.moveWidgetUp = function (widget) {
        const widgets = $scope.widgetsMap[$scope.activeStep.id];
        const index = widgets.indexOf(widget);

        if (index !== 0) {
          const newIndex = index - 1;
          orderTreesBulkUpdater.moveAt([index], [newIndex], widgets);
          WidgetService.sortWidgets(widgets);

          $timeout(() => {
            $anchorScroll(`widget-${widget.header.id}`);
          });
        }
      };

      $scope.moveWidgetDown = function (widget) {
        const widgets = $scope.widgetsMap[$scope.activeStep.id];
        const index = widgets.indexOf(widget);
        if (index !== widgets.length - 1) {
          orderTreesBulkUpdater.moveAt([index], [index + 1], widgets);
          WidgetService.sortWidgets(widgets);

          $timeout(() => {
            $anchorScroll(`widget-${widget.header.id}`);
          });
        }
      };

      // Widget delete

      function deleteWidget(widget) {
        // This is done here instead of the event handler because we need the widget to disappear immediately
        widget._deleting = true;

        // If the widget is uploading, abort the upload
        FileUploadService.abortUpload(widget);

        const data = {
          taskTemplate: widget.header.taskTemplate,
        };

        if (widget._creating) {
          const deferred = $q.defer();
          widget._onCreated = function () {
            util.pipe(deferred, WidgetService.delete(widget, data));
          };
          return deferred.promise;
        } else {
          return WidgetService.delete(widget, data);
        }
      }

      $scope.deleteWidget = deleteWidget;

      $scope.removeWidgetFromMap = widget => {
        const groupId = widget.header.taskTemplate.group.id;
        ArrayService.desplice($scope.widgetsMap[groupId], widget);
      };

      $scope.$on(EventName.WIDGET_DELETE_OK, (__event, widget) => {
        $scope.removeWidgetFromMap(widget);
      });

      $scope.$on(EventName.WIDGET_DELETE_FAILED, (__event, widget) => {
        // Mark it is failed so we can show it in the HTML
        widget._deleteFailed = true;

        ToastService.openToast({
          status: 'error',
          title: `We're having problems deleting the widget`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });

      $scope.$on(EventName.WIDGET_DELETE_FINISHED, (__event, widget) => {
        delete widget._deleting;
      });

      // Widget read

      function toHeader(widget) {
        return widget.header;
      }

      // Helpers

      $scope.$on('init', () => {
        $scope.subscribeToGetAllRulesByTemplateRevisionIdQuery();
      });

      $scope.$on('$destroy', () => {
        // Cancel all uploading widgets
        FileUploadService.abortTemplateUploads();

        $scope.unsubscribe();
        $scope.unsubscribeFromGetAllRulesByTemplateRevisionIdQuery?.();
        $scope.unsubscribeFromTemplateRevisionUpdates?.();
        $scope.unsubscribeFromTaskTemplateWidgetsGeneration?.();
      });

      $scope.getFormFieldOption = function (type, step) {
        const headerId = Muid.fromUuid(uuid());
        const taskTemplate = $scope.activeStepTaskTemplate;
        const widgets = $scope.widgetsMap[step ? step.id : $scope.activeStep.id];
        const orderTree = $scope.getOrderTreeValue(widgets);

        const keys = FormFieldService.toKeys($scope.widgetsMap);
        const key = FormFieldService.generateUniqueKey(keys, FormFieldDefaultKey[type]);

        return {
          headerId,
          taskTemplate,
          widgets,
          orderTree,
          keys,
          key,
        };
      };

      $scope.createTextFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Text);
        FormFieldService.createTextField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createNumberFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Number);
        FormFieldService.createNumberField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createTextAreaFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Textarea);
        FormFieldService.createTextareaField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createEmailFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Email);
        FormFieldService.createEmailField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createUrlFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Url);
        FormFieldService.createUrlField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createFileFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.File);
        FormFieldService.createFileField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createDateFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Date);
        FormFieldService.createDateField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createTableFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Table);
        FormFieldService.createTableField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.getItems = function () {
        return [
          { id: Muid.fromUuid(uuid()), name: '' },
          { id: Muid.fromUuid(uuid()), name: '' },
          { id: Muid.fromUuid(uuid()), name: '' },
        ];
      };

      $scope.createSelectFieldWidget = function () {
        const opts = $scope.getFormFieldOption(FieldType.Select);
        const items = $scope.getItems();
        FormFieldService.createSelectField(opts.headerId, opts.taskTemplate, opts.orderTree, opts.key, items);
      };

      $scope.createSendRichEmailWidget = function () {
        const opts = $scope.getFormFieldOption(FieldType.SendRichEmail);
        FormFieldService.createSendRichEmailField(opts.headerId, opts.taskTemplate, opts.orderTree, opts.key);
      };

      $scope.createHiddenFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Hidden);
        FormFieldService.createHiddenField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      $scope.createMultiChoiceFieldWidget = function () {
        const opts = $scope.getFormFieldOption(FieldType.MultiChoice);
        const items = $scope.getItems();
        FormFieldService.createMultiChoiceField(opts.headerId, opts.taskTemplate, opts.orderTree, opts.key, items);
      };

      $scope.createMultiSelectFieldWidget = function () {
        const opts = $scope.getFormFieldOption(FieldType.MultiSelect);
        const items = $scope.getItems();
        FormFieldService.createMultiSelectField(opts.headerId, opts.taskTemplate, opts.orderTree, opts.key, items);
      };

      $scope.createMembersFieldWidget = function () {
        const opts = $scope.getFormFieldOption(FieldType.Members);
        FormFieldService.createMembersField(opts.headerId, opts.taskTemplate, opts.orderTree, opts.key);
      };

      $scope.createSnippetFieldWidget = function () {
        const option = $scope.getFormFieldOption(FieldType.Snippet);
        FormFieldService.createSnippetField(option.headerId, option.taskTemplate, option.orderTree, option.key);
      };

      // File upload

      $scope.drop = function (__event, data) {
        const [file] = data.files;

        if (!FileUploadService.isValidFileSize(file)) {
          const message = FileUploadService.getFileSizeLimitMessage(file);

          ToastService.openToast({
            status: 'warning',
            title: `We couldn't upload the file`,
            description: message,
          });
          __event.preventDefault();
        }
      };

      $scope.add = function (__event, data) {
        // We want to control when to upload
        data.autoUpload = false;

        const [file] = data.files;
        let type;
        if (WidgetConstants.IMAGE_MIME_TYPES.test(file.type)) {
          type = WidgetType.Image;
        } else if (WidgetConstants.VIDEO_MIME_TYPES.test(file.type)) {
          type = WidgetType.Video;
        } else {
          type = WidgetType.File;
        }

        $scope.createSimpleWidget($scope.activeStep, type, 'file drag and drop').then(widget => {
          data._widget = widget;
          $rootScope.$broadcast(EventName.WIDGET_DROP_ADD, data);
        });
      };

      $scope.processDone = function (__event, data) {
        $rootScope.$broadcast(EventName.WIDGET_DROP_PROCESS_DONE, data);
      };

      $scope.processFail = function (__event, data) {
        $rootScope.$broadcast(EventName.WIDGET_DROP_PROCESS_FAIL, data);
      };

      $scope.done = function (__event, data) {
        $rootScope.$broadcast(EventName.WIDGET_DROP_DONE, data);
      };

      $scope.fail = function (__event, data) {
        $rootScope.$broadcast(EventName.WIDGET_DROP_FAIL, data);
      };

      $scope.progress = function (__event, data) {
        $rootScope.$broadcast(EventName.WIDGET_DROP_PROGRESS, data);
      };

      $scope.$on(EventName.WIDGET_UPLOAD_INVALID_FILE_TYPE, (__event, message) => {
        ToastService.openToast({
          status: 'warning',
          title: `We couldn't upload the file`,
          description: message,
        });
      });

      $scope.$on(EventName.WIDGET_UPLOAD_FAILED, () => {
        ToastService.openToast({
          status: 'error',
          title: `We're having problems uploading the file`,
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      });

      // UI

      $scope.showEditBar = function () {
        $scope.editBarHidden = false;
      };

      $scope.hideEditBar = function () {
        $scope.editBarHidden = true;
      };

      $scope.status = {
        isContentWidgetsOpen: true,
        isFormWidgetsOpen: true,
      };
      $scope.oneByOne = false;

      $scope.$on(EventName.TEMPLATE_SIDEBAR_TOGGLED, (__event, sidebarHidden) => {
        $scope.sidebarHidden = sidebarHidden;
      });

      $scope.isHeading = TaskTemplateService.isHeading;

      $scope.uploadImageByUrl = function (url) {
        $scope.createWidgetAndUploadByUrl(WidgetType.Image, url);
      };

      $scope.uploadFileByUrl = function (url) {
        $scope.createWidgetAndUploadByUrl(WidgetType.File, url);
      };

      $scope.createWidgetAndUploadByUrl = function (type, url) {
        $scope.createSimpleWidget($scope.activeStep, type, 'file drag and drop').then(widget => {
          const data = { _widget: widget, total: 100, loaded: 98 };
          $rootScope.$broadcast(EventName.WIDGET_DROP_PROGRESS, data);

          return WidgetService.createFile(widget.header.id, url).then(
            response => {
              widget.file = response.file;
              widget.description = response.description;
            },
            result => {
              const msg = result.status < 500 ? result.data : 'Whoops! Failed to upload the pasted file.';
              ToastService.openToast(msg);
              deleteWidget(widget);
            },
          );
        });
      };

      $scope.dropWidget = function (event, ui) {
        const type = ui.draggable[0].getAttribute('data-type');
        if (type && event.originalEvent.type === 'mouseup') {
          $scope.addWidgetByType(type);
        }
      };

      const subject = new Subject($scope.user.id, SecurityService.getSelectedOrganizationIdByUser($scope.user));
      $scope.userIsDeveloper = subject.isDeveloper();

      $scope.isApprovalTask = () => TaskTemplateService.isApproval($scope.activeStepTaskTemplate);

      $scope.widgetHasModifiedSettings = widget =>
        WidgetValidation.hasConstraints(widget) || WidgetUtils.hasConfigurableConfig(widget);

      $scope.widgetIsAllowedToHaveConditionalLogic = widget => {
        const allowedFormFieldTypes = ConditionalLogicUtils.getAllowedFormFieldTypes($scope.featureFlags);

        return isFormFieldWidget(widget) && allowedFormFieldTypes.has(widget.fieldType);
      };

      $scope.hasAssignments = false;
      $scope.setHasAssignments = hasAssignments => {
        $scope.hasAssignments = hasAssignments;
      };

      $scope.hasDynamicDueDate = false;
      $scope.setHasDynamicDueDate = hasDynamicDueDate => {
        $scope.hasDynamicDueDate = hasDynamicDueDate;
      };

      $scope.hasStopTask = () => $scope.activeStepTaskTemplate.stop;

      $scope.hasCustomPermissions = false;
      $scope.setHasCustomPermissions = hasCustomPermissions => {
        $scope.hasCustomPermissions = hasCustomPermissions;
      };

      $scope.hasConditionalLogic = false;
      $scope.setHasConditionalLogic = hasConditionalLogic => {
        $scope.hasConditionalLogic = hasConditionalLogic;
      };

      $scope.hasAutomations = false;
      $scope.setHasAutomations = hasAutomations => {
        $scope.hasAutomations = hasAutomations;
      };

      $scope.activeStepHasWidgets = () => !!$scope.widgetsMap[$scope.activeStep.id]?.length;

      $scope.subscribeToGetAllRulesByTemplateRevisionIdQuery = () => {
        const templateRevisionId = $scope.templateRevision?.id;

        if (!templateRevisionId) return;

        const observer = new QueryObserver(queryClient, {
          queryKey: GetAllRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId }),
          queryFn: () => GetAllRulesByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
        });
        $scope.unsubscribeFromGetAllRulesByTemplateRevisionIdQuery = observer.subscribe(result => {
          match(result)
            .with({ status: 'success' }, ({ data }) => {
              $scope.$evalAsync(() => {
                const { definitions: rules } = data;

                const widgetHasAssociatedRule = ConditionalLogicCommonUtils.makeWidgetHasAssociatedRule(rules);

                $scope.ruleChecker = widget => {
                  return Boolean(widget.header?.group?.id) && widgetHasAssociatedRule({ widget });
                };
              });
            })
            .otherwise(noop);
        });
      };

      $scope.setRulesModalIsOpen = isOpen => {
        $scope.rulesModalIsOpen = isOpen;
      };

      $scope.openConditionalLogicModal = widget => {
        ConditionalsButtonService.openRulesManagerModal({
          organization: $scope.organization,
          rulesFeatureIsAvailable: $scope.rulesFeatureIsAvailable,
          rulesModalIsOpen: $scope.rulesModalIsOpen,
          setRulesModalIsOpen: $scope.setRulesModalIsOpen,
          templateRevision: $scope.templateRevision,
          user: $scope.user,
          userIsAdmin: $scope.userIsAdmin,
          widget,
        });
      };

      $scope.shouldDisableConditionalLogicMenuButton = () => {
        return ConditionalsButtonService.shouldDisableFeature({
          initialized: $scope.initialized,
          templateRevision: $scope.templateRevision,
        });
      };

      function hasLinkedDataset(widget) {
        return widget.fieldType === 'Select' && !!widget.config?.linkedDataSetId;
      }

      $scope.getWidgetInnerClass = widget => {
        return [
          `widget-inner--${widget.header.type}`,
          widget.fieldType ? `widget-inner--field-type-${widget.fieldType}` : false,
          hasLinkedDataset(widget) ? `widget-inner--field-type-${widget.fieldType}--dataset` : false,
        ]
          .filter(Boolean)
          .join(' ');
      };

      $scope.getWidgetsByTaskTemplateId = taskTemplateId => {
        return WidgetService.getAllByTaskTemplateId(taskTemplateId).then(widgets => {
          queryClient.setQueryData(GetWidgetByTaskTemplateIdQuery.getKey({ taskTemplateId }), () => widgets);

          return widgets;
        });
      };

      $scope.taskTemplateUpdatedListener = message => {
        const { taskTemplateId } = JSON.parse(message.data);

        logger.info(`message from ${AblyEvent.EventType.TaskTemplateUpdated}`, taskTemplateId);

        $q((resolve, reject) => $scope.getWidgetsByTaskTemplateId(taskTemplateId).then(resolve).catch(reject)).then(
          widgets => {
            const newWidgetsMap = $scope.groupWidgetsByStepId($scope.templateRevision, widgets);
            $scope.updateExistingWidgetsMap(newWidgetsMap, {
              animate: true,
            });
          },
        );
      };

      $scope.listenToTaskTemplateWidgetsGeneration = () => {
        const unsubscribe = $rootScope.$on(TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_DONE, (_, widgets) => {
          const newWidgetsMap = $scope.groupWidgetsByStepId($scope.templateRevision, widgets);
          $scope.updateExistingWidgetsMap(newWidgetsMap, { animate: true });
          WidgetService.cacheAll(widgets);

          $scope.unsubscribeFromTaskTemplateWidgetsGeneration = unsubscribe;
        });
      };

      $scope.subscribeToTemplateRevisionUpdates = () => {
        if ($scope.templateRevision) {
          const channelName = ablyService.getChannelNameForTemplateRevision($scope.templateRevision.id);
          const channel = ablyService.getChannel(channelName);

          logger.info(`subscribing to ${AblyEvent.EventType.TaskTemplateUpdated}`);
          channel.subscribe(AblyEvent.EventType.TaskTemplateUpdated, $scope.taskTemplateUpdatedListener);

          $scope.unsubscribeFromTemplateRevisionUpdates = () => {
            logger.info(`unsubscribing from ${AblyEvent.EventType.TaskTemplateUpdated}`);

            channel.unsubscribe(AblyEvent.EventType.TaskTemplateUpdated, $scope.taskTemplateUpdatedListener);
          };
        }
      };

      async function animateWidgetsCreation(widgetsForSteps) {
        const runAnimation = AiGeneratorAnimationService.createWidgetsAnimator(widgetsForSteps, {
          timeout: $timeout,
          update: (widget, index) => {
            const AVG_WIDGET_HEIGHT = 400;
            const stepId = widget.header.taskTemplate.group.id;

            if (!$scope.widgetsMap[stepId]) {
              $scope.widgetsMap[stepId] = [];
            }

            const isNewWidget = !$scope.widgetsMap[stepId][index];
            const isWidgetForCurrentTask = $scope.activeStepTaskTemplate?.group.id === stepId;

            if (isNewWidget) {
              $rootScope.$broadcast(TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_STARTED, widget.header.taskTemplate);
            }

            // Scroll down when a new widget is added for the active task so we have it on the screen
            if (isNewWidget && isWidgetForCurrentTask) {
              const scroller = document.querySelector('.widgets-scroller');

              scroller?.scrollBy({ top: AVG_WIDGET_HEIGHT, behavior: 'smooth' });
            }

            $scope.widgetsMap[stepId][index] = {
              ...widget,
              _inView: true,
            };
          },
        });

        const widget = await runAnimation();

        $rootScope.$broadcast(TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_ANIMATION_DONE, widget.header.taskTemplate);
      }

      $scope.shouldShowTemplateWidgets = () => !$scope.isApprovalTask() || $scope.activeStepHasWidgets();

      $scope.shouldShowAddApprovalNoteButton = () =>
        $scope.editable &&
        $scope.isApprovalTask() &&
        !$scope.activeStepHasWidgets() &&
        FeatureFlagService.getFeatureFlags().approvalNotes;

      $scope.createApprovalNote = () => {
        const taskTemplate = $scope.activeStepTaskTemplate;
        const widget = {
          header: {
            id: MuidUtils.randomMuid(),
            taskTemplate,
            type: WidgetType.Text,
            orderTree: '1', // first and only widget in the Approval task
          },
        };
        return $scope.createWidget(widget, { taskTemplate });
      };
    },
  );

import { escapeHtml } from '@process-street/subgrade/util';
import { Box, BoxProps, InputProps, StyleProps, Textarea } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import * as React from 'react';
import { match } from 'ts-pattern';

const positioningProps: StyleProps = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  top: '0',
};

interface InputLike extends InputProps {
  // make these more generic for better input + textarea compatibility
  onBlur: React.FocusEventHandler;
  onFocus: React.FocusEventHandler;
  value: string | number;
}

export interface MaskedInputProps<I extends React.ReactElement<InputLike>> extends BoxProps {
  children: I;
  html?: string;
  maskProps?: BoxProps;
}

export function MaskedInput<I extends React.ReactElement<InputLike>>({
  children,
  html,
  maskProps,
  ...boxProps
}: MaskedInputProps<I>): React.ReactElement {
  const [inputState, setInputState] = React.useState<'focus' | 'blur'>('blur');

  const maskText = html || escapeHtml(children.props.placeholder) || '';
  const childType = children.type;
  const isTextarea =
    childType === Textarea ||
    (typeof childType !== 'string' &&
      'displayName' in childType &&
      typeof childType.displayName === 'string' &&
      childType.displayName.includes('Textarea'));

  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;
  const shouldShowMask = !isPrintMode && (inputState === 'blur' || children.props.isReadOnly);

  return (
    <Box position="relative" {...boxProps}>
      <Box
        {...{
          // extra padding to mimic border offset
          ...(children.props.isDisabled ? { opacity: 0.4 } : {}),
          px: 'calc(var(--ps-sizes-4) + 1px)',
          py: 'calc(var(--ps-sizes-2) + 1px)',
          h: 'auto',
          minH:
            boxProps.minH ??
            match({ isTextarea, isPrintMode })
              .with({ isTextarea: true, isPrintMode: true }, () => '10')
              .with({ isTextarea: true }, () => '20')
              .otherwise(() => '10'),
        }}
      >
        {/* This Box increases the height of the parent so the input auto expands */}
        {isTextarea ? (
          <Box
            {...{
              'lineHeight': 'short',
              'role': 'none',
              'visibility': 'hidden',
              'aria-hidden': true,
              'whiteSpace': 'pre-wrap',
              'sx': { wordWrap: 'break-word' },
              'dangerouslySetInnerHTML': { __html: maskText },
            }}
          />
        ) : null}

        {React.cloneElement(children, {
          onBlur: (event: React.FocusEvent) => {
            setInputState('blur');
            children.props.onBlur?.(event);
          },
          onFocus: (event: React.FocusEvent) => {
            setInputState('focus');
            children.props.onFocus?.(event);
          },
          ...positioningProps,
          height: 'full',
          w: 'full',
          fontSize: isPrintMode ? 'xs' : children.props.fontSize ?? 'base',
          ...(isTextarea ? { resize: 'none', overflowY: 'hidden' } : {}),
          minH:
            boxProps.minH ??
            match({ isTextarea, isPrintMode })
              .with({ isTextarea: true, isPrintMode: true }, () => '10')
              .with({ isTextarea: true }, () => '20')
              .otherwise(() => undefined),
        })}

        {/* this "mask" allows links in textarea fields to be clickable while the input is blurred */}
        {shouldShowMask ? (
          <Box
            {...{
              ...positioningProps,
              // "insetting" the div so it doesn't cover the input's border.
              'top': '1px',
              'left': '1px',
              // -2px to account for the left and right border at 1px
              'w': 'calc(100% - 2px)',
              // -2px to account for the top and bottom border at 1px
              'h': 'calc(100% - 2px)',
              ...(isTextarea
                ? {
                    lineHeight: 'short',
                    whiteSpace: 'pre-wrap',
                    height: 'auto',
                    // adjusted padding for inset position
                    py: 'calc(var(--ps-sizes-2) - 1px)',
                    px: 'calc(var(--ps-sizes-4) - 1px)',
                    bgColor: children.props.isReadOnly ? 'gray.100' : 'transparent',
                  }
                : {
                    lineHeight: 'base',
                    whiteSpace: 'pre',
                    // adjusted padding for inset position
                    py: 'calc(var(--ps-sizes-2) - 2px)', // 2px here because it "just works" for input specically
                    px: 'calc(var(--ps-sizes-4) - 1px)',
                    bgColor: children.props.isReadOnly ? 'gray.100' : children.props.bgColor ?? 'white',
                  }),
              'data-testid': 'mask',
              'borderWidth': 'px',
              'borderStyle': 'solid',
              'borderColor': 'transparent',
              'borderRadius': 'base',
              'overflow': 'hidden',
              'sx': {
                'wordWrap': 'break-word',
                '& a': { pointerEvents: 'auto' },
              },
              'pointerEvents': 'none',
              'color': html ? undefined : 'gray.400',
              'dangerouslySetInnerHTML': { __html: maskText },
            }}
            {...maskProps}
          />
        ) : null}
      </Box>
    </Box>
  );
}

import * as React from 'react';
import { Button, ButtonProps } from 'components/design/next';

const buttonStyles = { bgColor: 'gray.500', textDecor: 'none', border: '1px solid white' };

export const MiddleGroupButton: React.FC<ButtonProps> = ({ isActive = false, ...props }) => (
  <Button
    variant="link"
    color="white"
    sx={{
      border: isActive ? '1px solid white' : '1px solid transparent',
      borderRadius: '15px',
      px: 3,
      py: 1,
    }}
    _active={buttonStyles}
    _focus={buttonStyles}
    _hover={buttonStyles}
    {...props}
  >
    {props.children}
  </Button>
);

import { ChecklistMigrationModalOptions } from 'components/migration/checklist/checklist-migration-modal-options';
import { ChecklistRevisionService } from 'services/checklist-revision-service.interface';
import templateUrl from './checklist-migration-modal.component.html';
import './checklist-migration-modal.scss';
import angular from 'angular';

export class ChecklistMigrationModalController {
  public static $inject = ['$scope', 'ChecklistRevisionService'];

  public buttonDisabled = false;

  public options: ChecklistMigrationModalOptions | undefined = undefined;

  constructor(private $scope: angular.IScope, private checklistRevisionService: ChecklistRevisionService) {}

  public $onInit = () => {
    // @ts-expect-error -- TODO
    this.options = this.resolve.modalOptions;

    this.$scope.$on('$stateChangeSuccess', (_event, toState) => {
      if (!['checklist', 'checklist.task'].includes(toState.name)) {
        if (this.options) {
          this.options.interrupted = true; // stop polling
        }
      }
    });
  };

  public onButtonClick = (): void => {
    this.buttonDisabled = true;
    if (this.options) {
      this.checklistRevisionService
        .migrateOneWithPriorityByChecklistRevisionId(this.options.checklistRevision.id)
        .then(() => {
          // @ts-expect-error -- - part of bindings
          this.close();
        });
    }
  };
}

export const ChecklistMigrationModalComponent: angular.IComponentOptions = {
  bindings: {
    close: '&',
    resolve: '<',
  },
  controller: ChecklistMigrationModalController,
  templateUrl,
};

import * as React from 'react';

import { TaskTemplate, Template, TemplateRevision } from '@process-street/subgrade/process';
import { useSolutionTypeTagInstances } from 'pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import { AutomationAppIcon, AutomationAppIconProps } from '../automation-app-icon';
import { Button, HStack, ListItem, StackProps, Text, Tooltip, UnorderedList } from 'components/design/next';

import { SolutionTypeTag, TemplateSolutionInstance, withTrayPrefix } from '@process-street/subgrade/automation';
import { MAX_TASK_SOLUTION_INSTANCES_VISIBLE } from '../workflow-automations/constants';
import { useAutomationsModalEvents } from '../workflow-automations/components/common/context';
import { TaskSolutionInstanceLabel } from 'features/automations/components/task-automation-indicator/task-solution-instance-label';

export type TaskAutomationIndicatorProps = StackProps & {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  templateId: Template['id'];
  editable: boolean;
  isActive: boolean;
  isDisabled: boolean;
  iconVariant?: AutomationAppIconProps['variant'];
  maxItems?: number;
};

export const TaskAutomationIndicator: React.FC<React.PropsWithChildren<TaskAutomationIndicatorProps>> = ({
  taskTemplate,
  templateRevision,
  templateId,
  isActive,
  editable,
  iconVariant,
  isDisabled,
  maxItems = MAX_TASK_SOLUTION_INSTANCES_VISIBLE,
  ...stackProps
}) => {
  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId });

  const taskSolutionInstances = ps_when_task_checked_then.filter(
    i => i.taskTemplateGroupId === taskTemplate.group.id && i.configured && i.enabled,
  );

  if (!taskSolutionInstances.length) return null;

  return (
    <HStack spacing="2" {...stackProps}>
      {taskSolutionInstances.slice(0, maxItems).map((templateSolutionInstance, index) => {
        return (
          <TaskSolutionInstanceItem
            {...{
              key: templateSolutionInstance.id,
              templateSolutionInstance,
              templateRevision,
              taskTemplate,
              editable,
              isLast: index === maxItems - 1,
              notVisibleCount: taskSolutionInstances.length - maxItems,
              isSelected: isActive && editable,
              iconVariant,
              isDisabled,
            }}
          />
        );
      })}
    </HStack>
  );
};

export type TaskSolutionInstanceItemProps = StackProps & {
  templateRevision: TemplateRevision;
  editable: boolean;
  taskTemplate: TaskTemplate;
  templateSolutionInstance: TemplateSolutionInstance;
  isLast: boolean;
  isSelected: boolean;
  notVisibleCount: number;
  isDisabled: boolean;
  iconVariant?: AutomationAppIconProps['variant'];
  isTooltipDisabled?: boolean;
};

export const TaskSolutionInstanceItem: React.FC<React.PropsWithChildren<TaskSolutionInstanceItemProps>> = ({
  templateRevision,
  editable = false,
  taskTemplate,
  templateSolutionInstance,
  iconVariant,
  isLast,
  notVisibleCount,
  isSelected,
  isDisabled,
  isTooltipDisabled,
  ...stackProps
}) => {
  const templateSolutionInstanceId = templateSolutionInstance.id;
  const { openAutomationInstance } = useAutomationsModalEvents();
  const disableButton = !editable || isDisabled;
  return (
    <HStack w="8" h="8" justifyContent="center" spacing="2" {...stackProps}>
      <Tooltip
        aria-label={`when ${taskTemplate.name} is checked, then...`}
        label={
          <TaskSolutionInstanceLabel templateSolutionInstance={templateSolutionInstance} taskTemplate={taskTemplate} />
        }
        hasArrow
        placement="bottom"
        shouldWrapChildren
        isDisabled={isTooltipDisabled}
      >
        <AutomationAppIcon
          {...{
            role: 'button',
            automationApp: withTrayPrefix(templateSolutionInstance.automationApp),
            variant: iconVariant ?? (isSelected ? 'white' : undefined),
            w: 5,
            ...(!disableButton && {
              onClick: () =>
                openAutomationInstance({
                  id: templateSolutionInstanceId,
                  automationType: 'tray',
                  modalView: 'task',
                  solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen,
                }),
            }),
          }}
        />
      </Tooltip>
      {isLast && notVisibleCount && (
        <LastSolutionInstanceItem
          {...{
            templateRevision,
            taskTemplate,
            editable,
            notVisibleCount,
            isSelected,
            isDisabled,
            iconVariant,
          }}
        />
      )}
    </HStack>
  );
};

const LastSolutionInstanceItem: React.FC<
  React.PropsWithChildren<Omit<TaskSolutionInstanceItemProps, 'isLast' | 'templateSolutionInstance'>>
> = ({ templateRevision, taskTemplate, notVisibleCount, isSelected, isDisabled, iconVariant }) => {
  const templateId = templateRevision?.template?.id;

  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId });

  const { openAutomationInstance } = useAutomationsModalEvents();

  const taskSolutionInstances = ps_when_task_checked_then.filter(
    i => i.taskTemplateGroupId === taskTemplate.group.id && i.configured && i.enabled,
  );

  return (
    <Tooltip
      aria-label={`when ${taskTemplate.name} is checked, then...`}
      label={
        <>
          <Text variant="-2" mb="2">
            When
            <Text as="span" fontWeight="bold" variant="inherit">
              {' '}
              {taskTemplate.name}
            </Text>{' '}
            is checked ...
          </Text>
          <AutomationDescription
            {...{ taskSolutionInstances: taskSolutionInstances.slice(-notVisibleCount), iconVariant }}
          />
        </>
      }
      hasArrow
      placement="bottom"
      shouldWrapChildren
    >
      <Button
        aria-label="show more automations"
        variant="unstyled"
        cursor="default"
        {...(!isDisabled && {
          onClick: () => {
            const id = taskSolutionInstances.slice(-notVisibleCount)[0]?.id;
            openAutomationInstance({
              id,
              automationType: 'tray',
              modalView: 'task',
              solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen,
            });
          },
          cursor: 'pointer',
        })}
      >
        <Text color={(iconVariant === 'brand' ? 'gray.600' : undefined) ?? (isSelected ? 'white' : 'gray.600')}>
          +{notVisibleCount}
        </Text>
      </Button>
    </Tooltip>
  );
};

export type SolutionInstancesTooltipProps = React.PropsWithChildren<{
  templateRevision: TemplateRevision;
  taskTemplate: TaskTemplate;
  notVisibleCount: number;
  iconVariant?: AutomationAppIconProps['variant'];
}>;

export const SolutionInstancesTooltip = ({
  templateRevision,
  taskTemplate,
  notVisibleCount,
  iconVariant,
  children,
}: SolutionInstancesTooltipProps) => {
  const templateId = templateRevision?.template?.id;

  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId });

  const taskSolutionInstances = ps_when_task_checked_then.filter(
    i => i.taskTemplateGroupId === taskTemplate.group.id && i.configured && i.enabled,
  );

  return (
    <Tooltip
      aria-label={`when ${taskTemplate.name} is checked, then...`}
      label={
        <>
          <Text variant="-2" mb="1">
            When
            <Text as="span" fontWeight="bold" variant="inherit">
              {' '}
              {taskTemplate.name}
            </Text>{' '}
            is checked ...
          </Text>
          <AutomationDescription
            {...{ taskSolutionInstances: taskSolutionInstances.slice(-notVisibleCount), iconVariant }}
          />
        </>
      }
      hasArrow
      placement="bottom"
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  );
};

const AutomationDescription: React.FC<
  React.PropsWithChildren<{
    taskSolutionInstances: TemplateSolutionInstance[];
    iconVariant?: AutomationAppIconProps['variant'];
  }>
> = ({ taskSolutionInstances, iconVariant }) => {
  return (
    <UnorderedList spacing="0" mb="0" ml={5}>
      {taskSolutionInstances.map(tsi => (
        <ListItem key={tsi.id}>
          <HStack ml={-1.5}>
            <Text as="span" fontWeight="bold" display="inline" variant="inherit">
              {tsi.description}
            </Text>

            <Text display="flex" as="span" variant="inherit">
              {'('}
              <HStack as="span">
                <AutomationAppIcon
                  as="span"
                  w="3"
                  automationApp={withTrayPrefix(tsi.automationApp)}
                  display="inline-flex"
                  variant={iconVariant}
                />{' '}
                <Text as="span" variant="inherit">
                  {tsi.automationApp}
                </Text>
              </HStack>
              {')'}
            </Text>
          </HStack>
        </ListItem>
      ))}
    </UnorderedList>
  );
};

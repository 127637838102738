import {
  Box,
  BoxProps,
  Button,
  Center,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Stack,
  Text,
  Textarea,
  VStack,
} from 'components/design/next';
import { useCurrentUser } from 'hooks/use-current-user';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import * as React from 'react';
import { Muid, Option } from '@process-street/subgrade/core';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOrNonSystemGroupOm } from '@process-street/subgrade/util/membership-utils';
import { CreateOneOffTaskMutation, GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { CreateOneOffTaskSettingsSchema, getCreateOneOffTaskSettingsSchema } from './schema';
import { OneOffTaskVisibility } from '@process-street/subgrade/one-off-task';
import { AssignmentPicker } from '../shared/assignment-picker';
import { DueDatePicker } from '../shared/due-date-picker';
import { useQueryClient } from 'react-query';
import { WorkflowRunLink } from '../shared/workflow-run-link';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { useBeforeUnload } from 'react-use';
import { useCreateOneOffTaskUploadAttachments } from './use-create-one-off-task-upload-attachments';
import { last } from 'lodash';
import { SIZE_TOO_LARGE } from 'features/comments/components/common/attachment/use-upload-attachment-comment';
import { UploadProgress } from 'features/upload/components';
import { OneOffTaskAttachmentList } from './one-off-task-attachment-list';
import { useInboxItemsDataSource } from 'pages/tasks/hooks/use-inbox-items-data-source';
import { useFeatureFlag } from 'features/feature-flags';
import { useSubtasksOnBlur, UseSubtasksOnBlurParams } from '../shared/hooks/use-subtasks-on-blur';
import DescriptionEditor from '../description-editor/description-editor';
import { TaskNameInput } from '../task-name-input';
import { useTaskEditorFields } from 'features/one-off-tasks/hooks/use-task-editor-fields';
import { OneOffTaskFormFields } from '../form-fields';

const FORM_ID = 'create-one-off-task';

export const CreateDrawerContent: React.FC<React.PropsWithChildren<BoxProps>> = ({ ...props }) => {
  const organization = useSelectedOrganization();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const toast = useNextThemeToast();
  const isSubtasksEnabled = useFeatureFlag('tasksGA');

  const { preselectedTemplateId, preselectedChecklistId, onClose, setHasUnsavedChanges } = useOneOffTaskDrawerStore();
  const disableWfWfrLink = Boolean(preselectedTemplateId) && Boolean(preselectedChecklistId);

  const [selectedWorkflowId, setSelectedWorkflowId] = React.useState<Option<Muid>>(preselectedTemplateId);

  const { data: allMemberships } = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: organization?.id,
    },
    {
      enabled: Boolean(organization),
      select: oms => oms.filter(isStandardUserOrNonSystemGroupOm),
    },
  );

  const currentUserOrganizationMembership = React.useMemo(() => {
    if (currentUser && allMemberships) {
      return allMemberships.find(om => om.user.id === currentUser.id);
    }
  }, [allMemberships, currentUser]);

  const organizationMembershipMapByEmail = React.useMemo(() => {
    return Object.fromEntries(allMemberships?.map(om => [om.user.email, om]) ?? []);
  }, [allMemberships]);

  const { invalidate: invalidateInboxData } = useInboxItemsDataSource();

  const createMutation = CreateOneOffTaskMutation.useMutation({
    onSuccess: () => {
      // invalidate query
      if (values.wfrLinkEnabled && values.linkedChecklistId) {
        queryClient.invalidateQueries(GetOneOffTasksByChecklistQuery.getKey({ checklistId: values.linkedChecklistId }));
      }
      invalidateInboxData({ shouldPurge: true });

      toast({
        status: 'success',
        title: 'Task created successfully',
      });
    },
    onError: _ => {
      toast({
        status: 'error',
        title: "We're having problems creating the Task.",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleOnSubmit = async (values: CreateOneOffTaskSettingsSchema) => {
    const task = await createMutation.mutateAsync({
      name: values.name,
      description: values.description,
      dueDate: values.dueDate,
      subtasks: (values.subtasks ?? []).map(v => v.name ?? ''),
      visibility: OneOffTaskVisibility.Public,
      assigneeEmails: values.assigneeEmails,
      required: values.required,
      linkedChecklistId: values.wfrLinkEnabled ? values.linkedChecklistId : undefined,
    });
    await createAttachments(task.id);
    onClose();
  };

  const validationSchema = getCreateOneOffTaskSettingsSchema({ timeZone: currentUser?.timeZone });

  const initialValues = {
    name: '',
    description: '',
    subtasks: [],
    assigneeEmails: currentUserOrganizationMembership ? [currentUserOrganizationMembership?.user.email] : [],
    wfrLinkEnabled: Boolean(preselectedTemplateId) && Boolean(preselectedChecklistId),
    required: false,
    linkedChecklistId: preselectedChecklistId,
  };

  const {
    dirty,
    handleSubmit,
    handleReset,
    handleChange,
    handleBlur,
    setTouched,
    setFieldValue,
    values,
    isSubmitting,
    errors,
    touched,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: handleOnSubmit,
  });

  const { handleDescriptionChange, handleNameChange, editorContent } = useTaskEditorFields(
    setTouched,
    setFieldValue,
    touched,
    values,
  );

  const availableMemberships = React.useMemo(() => {
    if (!allMemberships) {
      return [];
    }
    const assignedMembershipEmailSet = new Set(values.assigneeEmails);
    return allMemberships?.filter(om => !assignedMembershipEmailSet.has(om.user.email));
  }, [allMemberships, values.assigneeEmails]);

  const assignedMemberships = React.useMemo(() => {
    return values.assigneeEmails.map(email => organizationMembershipMapByEmail[email]);
  }, [organizationMembershipMapByEmail, values.assigneeEmails]);

  React.useEffect(() => {
    setHasUnsavedChanges(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dirty
  }, [dirty]);

  useBeforeUnload(dirty, 'You have unsaved changes. Are you sure you want to leave?');
  // attachment upload
  const [progress, setProgress] = React.useState<number | undefined>(undefined);
  const { attachments, createAttachments, removeAttachment, dropzoneState } = useCreateOneOffTaskUploadAttachments({
    setProgress,
  });
  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  const handleSubtasksBlur = useSubtasksOnBlur({
    touched,
    setTouched,
    subtasks: values.subtasks ?? [],
  } as UseSubtasksOnBlurParams);

  return (
    <Box {...props}>
      <DrawerCloseButton onClick={() => onClose()}>
        <Icon icon="times" size="4" color="gray.400" />
      </DrawerCloseButton>

      <DrawerHeader px="8" py="5">
        <Text variant="-1u" textTransform="uppercase" fontWeight="bold" color="gray.500">
          Task
        </Text>
      </DrawerHeader>

      <Divider />

      {currentUserOrganizationMembership && (
        <form id={FORM_ID} onSubmit={handleSubmit} onReset={handleReset}>
          <DrawerBody flex="unset" pt="5" px="8" overflow="unset">
            <HStack spacing="4" alignItems="flex-start" pt="0" pb="3">
              <AssignmentPicker
                onSelect={om => {
                  setFieldValue('assigneeEmails', [...values.assigneeEmails, om.user.email]);
                }}
                onRemove={om => {
                  const filtered = values.assigneeEmails.filter(email => email !== om.user.email);
                  if (filtered.length === 0) {
                    // Default to current user
                    setFieldValue('assigneeEmails', [currentUserOrganizationMembership.user.email]);
                  } else {
                    setFieldValue(
                      'assigneeEmails',
                      values.assigneeEmails.filter(email => email !== om.user.email),
                    );
                  }
                }}
                availableMemberships={availableMemberships}
                assignedMemberships={assignedMemberships}
              />
              <FormControl isInvalid={Boolean(errors.dueDate)}>
                <DueDatePicker
                  timeZone={currentUser?.timeZone}
                  value={values.dueDate}
                  onChange={d => {
                    setFieldValue('dueDate', d || undefined);
                  }}
                  buttonProps={{
                    borderColor: errors.dueDate ? 'red' : 'inherit',
                  }}
                />
                <FormErrorMessage>{errors.dueDate}</FormErrorMessage>
              </FormControl>
            </HStack>

            <VStack pt="3" spacing="6" alignItems="start">
              <FormControl isInvalid={Boolean(errors.name) && touched.name}>
                <FormLabel>
                  <Text>Name</Text>
                </FormLabel>
                <TaskNameInput initialValue={values.name} onChange={handleNameChange} />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.description) && touched.description}>
                <FormLabel>
                  <Text>Description</Text>
                </FormLabel>
                {isSubtasksEnabled ? (
                  <DescriptionEditor value={editorContent} onChange={handleDescriptionChange} />
                ) : (
                  <Textarea
                    bgColor="white"
                    name="description"
                    placeholder="What is this task about?"
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                )}
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              </FormControl>

              {isSubtasksEnabled && (
                <FormControl>
                  <OneOffTaskFormFields.Subtasks
                    value={values.subtasks ?? []}
                    onChange={subtasks => {
                      setFieldValue('subtasks', subtasks);
                    }}
                    onBlur={index => {
                      handleSubtasksBlur(index);
                    }}
                    errors={errors.subtasks as unknown as FormikErrors<{ name: string }>[]}
                    touched={touched.subtasks as unknown as FormikTouched<{ name: boolean }>[]}
                  />
                </FormControl>
              )}

              <Stack w="full">
                <OneOffTaskAttachmentList
                  attachments={attachments}
                  onRemoveAttachment={removeAttachment}
                  isEnhancedFileSecurityEnabled={organization?.enhancedFileSecurity ?? false}
                />
                <div {...dropzoneState.getRootProps()}>
                  {progress === undefined && (
                    <Center w="100%">
                      <input aria-label="file-upload" {...dropzoneState.getInputProps()} />
                      <Button
                        aria-label="add attachment"
                        variant="unstyled"
                        justifyContent="flex-start"
                        iconSpacing="2"
                        display="flex"
                        color="gray.500"
                        fontWeight="500"
                        fontSize="md"
                        leftIcon={<Icon size="4" color="gray.500" variant="far" icon="paperclip" />}
                      >
                        Add attachment
                      </Button>
                    </Center>
                  )}
                  {progress !== undefined && (
                    <UploadProgress progress={progress as number} message="Uploading attachment..." />
                  )}
                </div>
                {fileTooLarge && (
                  <Text color="red.500" align="center" fontWeight="medium">
                    {last(dropzoneState.fileRejections[0].errors)?.message}
                  </Text>
                )}
              </Stack>
              <Divider />
              <WorkflowRunLink
                wfrLinkEnabled={values.wfrLinkEnabled}
                isRequired={values.required}
                onWfrLinkEnabledChange={enabled => {
                  setFieldValue('wfrLinkEnabled', enabled);
                }}
                selectedWorkflowId={selectedWorkflowId}
                selectedWorkflowRunId={values.linkedChecklistId}
                onWorkflowIdChange={setSelectedWorkflowId}
                onWorkflowRunIdChange={checklistId => setFieldValue('linkedChecklistId', checklistId)}
                onRequiredChange={required => {
                  setFieldValue('required', required);
                }}
                autoFocus={Boolean(values.name)}
                disableWfWfrLink={disableWfWfrLink}
              />
            </VStack>
          </DrawerBody>
          <DrawerFooter px={8}>
            <Button variant="ghost" fontWeight="normal" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" form={FORM_ID} type="submit" isLoading={isSubmitting} isDisabled={!isValid}>
              Create
            </Button>
          </DrawerFooter>
        </form>
      )}
    </Box>
  );
};

import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { Option } from 'space-monad';
import { useTemplateMenuContext } from '..';
import { ButtonProps } from 'components/design/next';

export type PrintButtonProps = { children: React.ReactElement<ButtonProps> };

export const PrintButton: React.FC<PrintButtonProps> = React.memo(({ children }) => {
  const { $state } = useInjector('$state');
  const { templateId } = useTemplateMenuContext();

  const go = React.useCallback(() => {
    // HACK to open the url in a new tab
    const url = $state.href(`templatePrint`, { id: templateId });
    window.open(url, '_blank');
  }, [$state, templateId]);

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('PrintButton child must be a single clickable element');
  }

  const isFree = useIsCurrentUserFreeMember();
  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
  const hasPermission = Option(permissionsQuery.data)
    .map(({ permissionMap: pm }) => pm.checklistCreate || pm.checklistSchedule || (isFree && pm.templateRead))
    .getOrElse(false);

  if (!hasPermission) return null;

  const btn = React.cloneElement(children, { onClick: go });

  return btn;
});

import * as React from 'react';
import {
  DueDateRuleSourceType,
  FormFieldWidget,
  TaskTemplate,
  TaskTemplateGroup,
  TemplateRevision,
  WidgetGroup,
} from '@process-street/subgrade/process';
import { Option } from '@process-street/subgrade/core';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { ActionMeta, OnChangeValue } from 'react-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { RuleItems, RuleOption } from 'features/dynamic-due-dates/models/items';
import { DueDateHelper } from 'features/dynamic-due-dates/services/due-date-helper';
import { Box, Text, useDisclosure } from 'components/design/next';
import { PartialDueDateRule } from 'features/dynamic-due-dates/models/models';
import { match } from 'ts-pattern';
import { canAccess, Feature } from 'services/features/features';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { DueDateUpgradeModal } from 'features/dynamic-due-dates/components/due-date-upgrade-modal';

export type DueDateRulePickerProps = {
  onSelect: (rule: PartialDueDateRule) => void;
  dateWidgets: FormFieldWidget[];
  rule: PartialDueDateRule;
  selectedTaskTemplate: Option<TaskTemplate>;
  selectedTaskTemplates: TaskTemplate[];
  templateRevision: TemplateRevision;
  taskTemplates: TaskTemplate[];
  widgetsLoaded: boolean;
};

const UpgradeRequiredBadge = (
  <Text
    as="span"
    backgroundColor="green.500"
    variant="-2"
    padding="4px"
    marginLeft={2}
    fontWeight="bold"
    borderRadius="3px"
    color="white"
  >
    S
  </Text>
);

export const DueDateSourceSelector: React.FC<React.PropsWithChildren<DueDateRulePickerProps>> = ({
  selectedTaskTemplate,
  selectedTaskTemplates,
  taskTemplates,
  dateWidgets,
  onSelect,
  rule,
}) => {
  const [selectedValue, setSelectedValue] = React.useState<Option<RuleOption>>(undefined);

  const selectedOrLastApplicableTask = DueDateHelper.getSelectedOrLastApplicableTask(
    rule.ruleType,
    selectedTaskTemplate,
    taskTemplates,
  );

  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);
  const dynamicDueDatesFeatureIsAvailable = canAccess(
    Feature.DYNAMIC_DUE_DATES,
    selectedOrganization?.subscription.plan.id,
  );

  const addUpgradeRequiredBadge = (item: RuleOption): RuleOption => {
    const allowedItems: string[] = [DueDateRuleSourceType.ChecklistStartDate, DueDateRuleSourceType.ChecklistDueDate];
    const featureAvailable = dynamicDueDatesFeatureIsAvailable || allowedItems.includes(item.value);

    const suffix = featureAvailable ? undefined : UpgradeRequiredBadge;
    return { ...item, suffix, upgradeRequired: !featureAvailable };
  };

  const popularItems = RuleItems.getPopularItems(rule, selectedOrLastApplicableTask, taskTemplates ?? [])
    .map(RuleItems.convertPopularItem)
    .map(addUpgradeRequiredBadge);

  const dateItems = dateWidgets.map(RuleItems.convertDateWidget).map(addUpgradeRequiredBadge);

  const taskItems = RuleItems.getTaskItems(
    rule.offsetDirection,
    taskTemplates,
    selectedOrLastApplicableTask,
    selectedTaskTemplates,
  ).map(addUpgradeRequiredBadge);

  React.useEffect(() => {
    match(rule.sourceType)
      .with(DueDateRuleSourceType.TaskCompletedDate, () => {
        const taskOption = taskItems.find(i => i.value === rule.taskTemplateGroup?.id);
        setSelectedValue(taskOption);
      })
      .with(DueDateRuleSourceType.FormFieldValue, () => {
        const dateOption = dateItems.find(i => i.value === rule.formFieldWidgetGroup?.id);
        setSelectedValue(dateOption);
      })
      .otherwise(() => {
        const popularOption = popularItems.find(i => i.value === rule.sourceType);
        setSelectedValue(popularOption);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- rule change
  }, [rule]);

  const options = [
    {
      label: 'Popular',
      options: popularItems,
    },
    {
      label: 'Date fields',
      options: dateItems,
    },
    {
      label: 'Task checked',
      options: taskItems,
    },
  ];

  const upgradeDisclosure = useDisclosure();

  const handleChange = (ruleOption: OnChangeValue<RuleOption, false>, actionMeta: ActionMeta<RuleOption>) => {
    if (actionMeta.action === 'select-option') {
      if (BlvdSelectHelpers.isOptionType<RuleOption>(ruleOption)) {
        if (ruleOption.upgradeRequired) {
          upgradeDisclosure.onOpen();
          return;
        }

        setSelectedValue(ruleOption);

        const sourceType = ruleOption.type;
        const formFieldWidgetGroup: Option<WidgetGroup> =
          sourceType === DueDateRuleSourceType.FormFieldValue ? { id: ruleOption.value } : undefined;
        const taskTemplateGroup: Option<TaskTemplateGroup> =
          sourceType === DueDateRuleSourceType.TaskCompletedDate ? { id: ruleOption.value } : undefined;

        const updatedRule = {
          ...rule,
          sourceType,
          formFieldWidgetGroup,
          taskTemplateGroup,
        };

        onSelect(updatedRule);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          '.blvd-select__menu': {
            minW: '300px',
          },
          '.blvd-select__control': {
            width: '240px',
            maxW: '240px',
          },
        }}
      >
        <BlvdSelect
          options={options}
          value={selectedValue ?? null}
          isSearchable={true}
          placeholder={'Choose rule...'}
          searchPlaceholder={'Search form fields...'}
          onChange={handleChange}
        />
      </Box>
      <DueDateUpgradeModal isOpen={upgradeDisclosure.isOpen} onClose={upgradeDisclosure.onClose} />
    </>
  );
};

import { Button, Icon, PopoverTrigger, Text } from 'components/design/next';
import * as React from 'react';

export type SingleTriggerButtonProps = {
  permitsAreDefault: boolean;
  isDisabled: boolean;
};

export const BulkTriggerButton = ({ isDisabled: _isDisabled, permitsAreDefault }: SingleTriggerButtonProps) => (
  <PopoverTrigger>
    <Button
      w="163px"
      display="flex"
      color="gray.500"
      justifyContent="flex-start"
      variant="tertiary"
      iconSpacing="3"
      leftIcon={
        <Icon icon="lock-keyhole" variant="far" size="4" color={permitsAreDefault ? 'gray.400' : 'purple.500'} />
      }
      aria-label="Task permissions"
    >
      <Text
        color={permitsAreDefault ? 'gray.600' : 'purple.500'}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        fontWeight="normal"
      >
        Permissions
      </Text>
    </Button>
  </PopoverTrigger>
);

import * as React from 'react';
import { Folder, isFolder, isTemplate, Template, TemplateType } from '@process-street/subgrade/process';
import { Box, Show } from 'components/design/next';
import { FixedSizeList, ListChildComponentProps, VariableSizeList } from 'react-window';
import { FolderRow } from './folder-row';
import { TemplateRow } from './template-row';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ActionsRow } from './actions-row';
import {
  GetConsolidatedFolderPermissionsResult,
  useGetAllConsolidatedFolderPermissionsQuery,
  useGetAllConsolidatedTemplatePermissionsQuery,
} from 'features/permissions/query-builder';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { GetConsolidatedTemplatePermissionsResult } from '@process-street/subgrade/api/template.api';
import { useFeatureFlag } from 'features/feature-flags';
import { useWriteableFoldersQuery } from 'features/folder/query-builder';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { match } from 'ts-pattern';
import { useOnRouteChange } from '@process-street/adapters/navigation';

export type RowRecord = Template | Folder | 'rowsWithActions';

const ITEM_HEIGHT_IN_PX = 64;
const SUB_ITEM_HEIGHT_IN_PX = 48;

export const Row: React.FC<
  Omit<ListChildComponentProps, 'data'> & {
    data: {
      rowsToDisplay: RowRecord[];
      folderPermissionsById: Record<Muid, GetConsolidatedFolderPermissionsResult>;
      templatePermissionsById: Record<Muid, GetConsolidatedTemplatePermissionsResult>;
      writeableFoldersSet: Set<Muid>;
      toggleItemDisclosure: (id: Muid) => void;
      itemDisclosureById: Record<Muid, boolean>;
    };
  }
> = React.memo(({ data, index, style }) => {
  const row = data.rowsToDisplay[index];

  if (row === 'rowsWithActions') {
    return (
      <Box _hover={{ bg: 'gray.50' }} style={style}>
        <ActionsRow>New</ActionsRow>
      </Box>
    );
  }

  return (
    <Box
      _hover={{ bg: 'gray.50' }}
      style={style}
      paddingStart={{ base: undefined, md: '2' }}
      paddingEnd={{ base: undefined, md: '4' }}
      borderBottomStyle="solid"
      borderBottomColor="gray.100"
      borderBottomWidth="thin"
    >
      {'folder' in row ? (
        <TemplateRow
          template={row}
          itemDisclosureById={data.itemDisclosureById}
          toggleItemDisclosure={data.toggleItemDisclosure}
        />
      ) : (
        <FolderRow
          templatePermissionsById={data.templatePermissionsById}
          folderPermissions={data.folderPermissionsById?.[row.id]?.permissionMap}
          folder={row}
          writeableFoldersSet={data.writeableFoldersSet}
          itemDisclosureById={data.itemDisclosureById}
          toggleItemDisclosure={data.toggleItemDisclosure}
        />
      )}
    </Box>
  );
});

export const Rows: React.FC<{ rows: RowRecord[] }> = ({ rows }) => {
  const rowsToDisplay = React.useMemo(() => [...rows, 'rowsWithActions'], [rows]);
  const isDragAndDropEnabled = useFeatureFlag('dragAndDropLibrary');
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const [itemDisclosureById, setItemDisclosureById] = React.useState<Record<string, boolean>>({});
  // We need this hacky solution to re-render the windowed list whenever we expand an item.
  const [variableSizeListId, setVariableSizeListId] = React.useState<Muid>(MuidUtils.randomMuid());
  const listScrollOffsetRef = React.useRef<number>(0);

  const { data: folderPermissionsById } = useGetAllConsolidatedFolderPermissionsQuery({
    enabled: isDragAndDropEnabled,
    select: allFolderPermits =>
      Object.fromEntries(allFolderPermits.map(permission => [permission.folderId, permission])),
  });

  const { data: templatePermissionsById } = useGetAllConsolidatedTemplatePermissionsQuery({
    enabled: isDragAndDropEnabled,
    select: allTemplatePermits =>
      Object.fromEntries(allTemplatePermits.map(permission => [permission.templateId, permission])),
  });

  const writeableFoldersQuery = useWriteableFoldersQuery(organizationId ?? '', {
    enabled: isDragAndDropEnabled && Boolean(organizationId),
  });

  const writeableFoldersSet = React.useMemo(() => {
    return new Set(writeableFoldersQuery.data?.map(folder => folder.id) ?? []);
  }, [writeableFoldersQuery.data]);

  const toggleItemDisclosure = (id: Muid) => {
    setVariableSizeListId(MuidUtils.randomMuid());
    setItemDisclosureById(previousValue => ({
      ...previousValue,
      [id]: !previousValue[id],
    }));
  };

  const handleScroll = (e: { scrollOffset: number }) => {
    listScrollOffsetRef.current = e.scrollOffset;
  };

  /*
   * Calculates the row height based on:
   * - Wether or not the row is expanded
   * - How many sub items are shown when the row is expanded
   */
  const getItemSize = React.useCallback(
    (index: number) => {
      const item = rowsToDisplay[index];

      if (!item) return ITEM_HEIGHT_IN_PX;

      return match(item)
        .when(isFolder, folder => {
          const isExpanded = itemDisclosureById[folder.id];

          if (!isExpanded) return ITEM_HEIGHT_IN_PX;

          const subMenuItemsCount = 2;
          return ITEM_HEIGHT_IN_PX + subMenuItemsCount * SUB_ITEM_HEIGHT_IN_PX;
        })
        .when(isTemplate, template => {
          const isExpanded = itemDisclosureById[template.id];

          if (!isExpanded) return ITEM_HEIGHT_IN_PX;

          if (template.templateType === TemplateType.Playbook) {
            const subMenuItemsCount = 5;
            return ITEM_HEIGHT_IN_PX + subMenuItemsCount * SUB_ITEM_HEIGHT_IN_PX;
          }
          if (template.templateType === TemplateType.Form) {
            const subMenuItemsCount = 2;
            return ITEM_HEIGHT_IN_PX + subMenuItemsCount * SUB_ITEM_HEIGHT_IN_PX;
          }
          if (template.templateType === TemplateType.Page) {
            const subMenuItemsCount = 2;
            return ITEM_HEIGHT_IN_PX + subMenuItemsCount * SUB_ITEM_HEIGHT_IN_PX;
          }

          return ITEM_HEIGHT_IN_PX;
        })
        .otherwise(() => ITEM_HEIGHT_IN_PX);
    },
    [itemDisclosureById, rowsToDisplay],
  );

  useOnRouteChange(() => {
    listScrollOffsetRef.current = 0;
  });

  const itemData = React.useMemo(() => {
    return {
      folderPermissionsById,
      templatePermissionsById,
      rowsToDisplay,
      writeableFoldersSet,
      toggleItemDisclosure,
      itemDisclosureById,
    };
  }, [folderPermissionsById, templatePermissionsById, rowsToDisplay, writeableFoldersSet, itemDisclosureById]);

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <>
          <Show below="md">
            <VariableSizeList
              // Re-render the list once we have data. This will prevent miscalculation of the item size.
              key={`variable-size-list-${variableSizeListId}-${rowsToDisplay.length}-items`}
              height={height}
              width={width}
              itemData={itemData}
              itemCount={rowsToDisplay.length}
              estimatedItemSize={ITEM_HEIGHT_IN_PX}
              onScroll={handleScroll}
              initialScrollOffset={listScrollOffsetRef.current}
              itemSize={getItemSize}
            >
              {/* @ts-expect-error library contains wrong typing */}
              {Row}
            </VariableSizeList>
          </Show>

          <Show above="md">
            <FixedSizeList
              height={height}
              width={width}
              itemData={itemData}
              itemCount={rowsToDisplay.length}
              itemSize={ITEM_HEIGHT_IN_PX}
            >
              {/* @ts-expect-error library contains wrong typing */}
              {Row}
            </FixedSizeList>
          </Show>
        </>
      )}
    </AutoSizer>
  );
};

import * as React from 'react';
import { HStack, Text, Icon, Alert, Tooltip, Flex } from 'components/design/next';
import { DraftStatus, useDraftStatus } from './use-draft-status';
import { useGetFocusBarColor } from '../use-get-focus-bar-color';
import { match } from 'ts-pattern';
import { useMatch } from '@process-street/adapters/navigation';

export type UIProps = { status: DraftStatus };

export const DraftStatusIndicatorUI: React.FC<React.PropsWithChildren<UIProps>> = ({ status }) => {
  const getFocusBarColor = useGetFocusBarColor();
  const isEditorV2 = useMatch('templateV2');
  return (
    <HStack spacing="2" color={getFocusBarColor({ light: 'gray.600', dark: 'white' })} display="inline-flex">
      {/* this should be shown in addition to the other states */}
      {/* NOTE only ai tasks are checked but we could extend this to other tasks and widgets */}
      {status === 'invalid' ? (
        <Tooltip label="Incomplete AI Tasks" shouldWrapChildren border="1px solid var(--ps-colors-gray-100)">
          <Icon icon="triangle-exclamation" variant="far" color="red.500" size="4" />
        </Tooltip>
      ) : null}

      {match(status)
        .with('saving', () => (
          <>
            <Icon
              icon="sync-alt"
              variant="far"
              color={getFocusBarColor({ light: 'gray.600', dark: 'white' })}
              size="3"
              animation="spin"
            />
            <Text variant="-1">Saving…</Text>
          </>
        ))
        .with('saved', 'invalid', () => {
          return isEditorV2 ? (
            <Text color="white" fontStyle="italic">
              Draft saved
            </Text>
          ) : (
            <Text variant="-1">Draft auto-saved</Text>
          );
        })
        .with('offline', () => (
          <Alert status="error" py="0.5" px="1">
            <Tooltip label="You are offline. Changes to the page will not be saved.">
              <Flex>
                <Icon icon="wifi-slash" variant="fas" color="red.500" size="3" mr="1" />
              </Flex>
            </Tooltip>
            <Text color="gray.600" variant="-1" fontWeight="medium">
              Offline
            </Text>
          </Alert>
        ))
        .with('error', () => (
          <Alert status="error" py="0.5" px="1">
            <Icon icon="exclamation-circle" variant="far" color="red.500" size="3" mr="1" />
            <Text color="gray.600" variant="-1" fontWeight="medium">
              Error saving changes
            </Text>
          </Alert>
        ))
        .otherwise(() => null)}
    </HStack>
  );
};

export const DraftStatusIndicator: React.FC<React.PropsWithChildren<unknown>> = () => {
  const status = useDraftStatus();
  return <DraftStatusIndicatorUI status={status} />;
};

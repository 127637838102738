import { HStack, StackProps } from 'components/design/next';
import * as React from 'react';
import { useGetFocusBarColor } from './use-get-focus-bar-color';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';

export const WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT = 18;
export const WORKFLOW_DASHBOARD_FOCUS_BAR_HEIGHT = 16;

export const FocusBarHStack: React.FC<React.PropsWithChildren<StackProps>> = props => {
  const getFocusBarColor = useGetFocusBarColor();
  const workflowState = useWorkflowState();
  const isDashboard = workflowState === 'dashboard';

  return (
    <HStack
      data-hide-on-print
      h={isDashboard ? WORKFLOW_DASHBOARD_FOCUS_BAR_HEIGHT : WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}
      w="full"
      py="2"
      px={['4', null, '6']}
      borderBottomStyle="solid"
      borderBottomWidth={isDashboard ? '1px' : '2px'}
      borderBottomColor="gray.200"
      position="fixed"
      background={getFocusBarColor({ light: 'gray.50', dark: 'gray.700', dashboard: 'white' })}
      zIndex="docked"
      justify="space-between"
      spacing="4"
      {...props}
    />
  );
};

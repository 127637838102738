import * as React from 'react';
import { MouseEventHandler } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from 'components/design/next';
import { useAiGenerationTaskTemplatesMutation } from 'features/ai-generation/query-builder/task-templates';
import { useMount, useUnmount } from 'react-use';
import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { AI_IMPORT_WORKFLOW_S3_ID, AI_WORKFLOW_INITIAL_CONFIG, SHOW_AI_WORKFLOW_SETUP_MODAL_FOR } from './wrapper';
import {
  GetTemplateQuery,
  GetTemplateQueryResponse,
  useDeleteTemplate,
  useUpdateTemplateMutation,
} from 'features/template/query-builder';
import { useQueryClient } from 'react-query';
import { useUploadCoverImage } from 'features/cover-image/use-upload-cover-image';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { CoverIconByTemplateIdQuery } from 'features/cover-icon/query-builder';
import { AiGeneratedWorkflowSettingsModalHelpers } from './helpers';
import { useSubscribeToAbly } from './use-subscribe-to-ably';
import { useTaskTemplateStatusMap } from './use-task-template-status-map';
import { AiGeneratingWorkflowSuccessToast } from './ai-generation-workflow-success';
import { AiGenerationType, useAiGenerationSlice } from './store';
import { TaskTemplate } from '@process-street/subgrade/process';
import { TemplateConstants } from 'services/template-constants';
import { ImportWorkflowMutation, useAiGenerationCoverIconMutation } from 'features/ai-generation/query-builder';
import { usePublishAndRun } from './use-publish-and-run';
import {
  TaskTemplatesByTemplateRevisionIdQuery,
  useDeleteTaskTemplatesMutation,
} from 'features/task-templates/query-builder';
import { AxiosError } from 'axios';
import { HttpStatus } from '@process-street/subgrade/util';
import { AiGeneratingWorkflowErrorToast } from './ai-generation-workflow-error-toast';
import { AiGenerationLoadingState } from './ai-generation-loading-state';
import { AiGenerationWorkflowSummary } from './ai-generation-workflow-summary';
import { useGetAiGenerationWorkflowSummaryStats } from './use-get-ai-generation-workflow-summary-stats';
import { ablyService } from 'app/pusher/ably.service';
import { trace } from 'components/trace';
import { AblyEvent } from 'app/pusher/ably-event';
import { WorkflowNameField } from './workflow-name-field';
import { AiGeneratedModalSettingsContext } from './context';
import { WorkflowOption } from './predefined-workflows-options';
import { PublishDraftMutation, useTemplateRevisionQuery } from 'features/template-revisions/query-builder';
import { RunChecklist } from 'components/run-checklist/components/RunChecklist/RunChecklist';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { AiLocalStorage } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/ai-local-storage';
import { useFeatureFlag } from 'app/features/feature-flags';

export type AiGeneratedWorkflowSettingsModalProps = {
  templateId: Muid;
  templateRevisionId: Muid;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const logger = trace({ name: 'AiGeneratedWorkflowSettingsModal' });

export const AiGeneratedWorkflowSettingsModal: React.FC<
  React.PropsWithChildren<AiGeneratedWorkflowSettingsModalProps>
> = ({ templateId, templateRevisionId, isOpen, onClose, onOpen: openModal }) => {
  const { $rootScope, $state } = useInjector('$rootScope', '$state');
  const isReactWorkflowEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  const taskTemplatesByTemplateRevisionIdQueryKey = isReactWorkflowEditorEnabled
    ? ['generated', TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId })]
    : [TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId })];

  const toast = useToast();

  const initialConfig = AiLocalStorage.getInitialConfigFromLocalStorage();

  const [name, setName] = React.useState(initialConfig?.name);
  const [description, setDescription] = React.useState<string>(initialConfig?.description);
  const [hasModerationError, setHasModerationError] = React.useState(false);
  const [hasInternalError, setHasInternalError] = React.useState(false);
  const retryCount = initialConfig?.retryCount ?? 0;
  const aiGenerationSlice = useAiGenerationSlice();
  const maxNameLength = 255;
  const [isPredefinedDescription, setIsPredefinedDescription] = React.useState(false);
  const descriptionRef = React.useRef<HTMLTextAreaElement>(null);
  const importWorkflowMutation = ImportWorkflowMutation.useMutation();
  const newestTemplateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId });
  const runChecklistDisclosure = useDisclosure();

  const [isTaskPropertiesGenerated, setIsTaskPropertiesGenerated] = React.useState(false);

  const queryClient = useQueryClient();
  const getSummaryStats = useGetAiGenerationWorkflowSummaryStats({ templateRevisionId });

  const isRetrying = retryCount > 0;

  const {
    taskTemplateStatusMap,
    update: updateTaskTemplateStatusMap,
    reset: resetTaskTemplateStatusMap,
  } = useTaskTemplateStatusMap();
  const publishAndRunMutation = usePublishAndRun(
    {
      templateRevisionId,
      templateId,
      templateName: name,
    },
    {
      onSuccess: () => {
        toast.closeAll();
      },
    },
  );
  const publishDraftMutation = PublishDraftMutation.useMutation();

  useSubscribeToAbly({
    templateRevisionId,
    templateName: name,
    tasksCount:
      // We filter the 'ignored' out because they are approval tasks, and approval tasks
      // doesn't have any content being generated.
      Object.values(taskTemplateStatusMap).filter(v => v !== 'ignored').length,
    openModal,
    onError: () => {
      aiGenerationSlice.stopGeneration();
    },
  });

  const uploadCoverImage = useUploadCoverImage({
    templateId,
    onFinish: () => {
      void queryClient.refetchQueries(CoverImageByTemplateIdQuery.getKey({ templateId }));
    },
    accept: 'image/*',
  });

  const generateCoverIconMutation = useAiGenerationCoverIconMutation({
    onSuccess: () => {
      void queryClient.refetchQueries(CoverIconByTemplateIdQuery.getKey({ templateId }));
    },
  });

  const uploadCoverImageAndIcon = async (nameOverride = name) => {
    const coverImageFile = await AiGeneratedWorkflowSettingsModalHelpers.getCoverImageFile();

    uploadCoverImage.upload([coverImageFile]);
    generateCoverIconMutation.mutate({ name: nameOverride, templateRevisionId, retryCount });
  };

  const showInternalErrorToast = React.useCallback(() => {
    toast({
      render: () => {
        return <AiGeneratingWorkflowErrorToast />;
      },
    });
  }, [toast]);

  const aiGenerationTaskTemplatesMutation = useAiGenerationTaskTemplatesMutation({
    onMutate: () => {
      handleClose();
      aiGenerationSlice.startWorkflowTasksGeneration();
    },
    onError: (err: AxiosError) => {
      openModal();
      aiGenerationSlice.stopGeneration();

      if (err.response?.status === HttpStatus.BAD_REQUEST) {
        setHasModerationError(true);
      } else {
        setHasInternalError(true);
        showInternalErrorToast();
      }
    },
    onSuccess: taskTemplates => {
      updateTaskTemplateStatusMap(taskTemplates);

      LocalStorageService.removeItem(SHOW_AI_WORKFLOW_SETUP_MODAL_FOR);

      aiGenerationSlice.startWorkflowGeneration();

      // Caching task templates because workflow generation removes the 3 empty tasks and creates new ones, so to be on sync.
      queryClient.setQueryData(taskTemplatesByTemplateRevisionIdQueryKey, () => taskTemplates);
      if (isReactWorkflowEditorEnabled) {
        queryClient.setQueryData(TaskTemplatesByTemplateRevisionIdQuery.getKey({ templateRevisionId }), () => []);
      }

      void uploadCoverImageAndIcon();
      $rootScope.$broadcast(TaskListEvent.AI_GENERATED_TASK_TEMPLATES, taskTemplates);
    },
  });

  const updateTemplateMutation = useUpdateTemplateMutation({
    onMutate: variables => {
      queryClient.setQueryData<GetTemplateQueryResponse | undefined>(
        GetTemplateQuery.getKey({ templateId }),
        current => {
          if (!current) return;
          const title = variables.name ?? TemplateConstants.BLANK_WORKFLOW_NAME;
          window.document.title = `${title} | Process Street`;
          return {
            ...current,
            name: variables.name ?? '',
          };
        },
      );
    },
  });

  const deleteTaskTemplatesMutation = useDeleteTaskTemplatesMutation();
  const deleteTemplateMutation = useDeleteTemplate();

  const generateWorkflow = async () => {
    // prevent quick double clicks
    if (aiGenerationTaskTemplatesMutation.isLoading) return;

    // don't close the modal if there is an internal error
    if (hasInternalError) {
      setHasInternalError(false);
      return;
    }

    // update WF name
    updateTemplateMutation.mutate({
      templateId,
      name,
    });
    aiGenerationTaskTemplatesMutation.mutate({
      templateRevisionId,
      name,
      description,
      retryCount,
      promptOverride: initialConfig.promptOverride,
    });
  };

  const isLoading =
    aiGenerationTaskTemplatesMutation.isLoading ||
    importWorkflowMutation.isLoading ||
    deleteTaskTemplatesMutation.isLoading;

  const importWorkflowS3Id = LocalStorageService.getItem(AI_IMPORT_WORKFLOW_S3_ID);
  React.useEffect(
    function importWorkflow() {
      if (!importWorkflowS3Id) return;

      LocalStorageService.removeItem(AI_IMPORT_WORKFLOW_S3_ID);
      LocalStorageService.removeItem(SHOW_AI_WORKFLOW_SETUP_MODAL_FOR);
      aiGenerationSlice.startWorkflowImport();

      importWorkflowMutation.mutate({ templateRevisionId, s3FileId: importWorkflowS3Id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only once when finding local storage item
    [importWorkflowS3Id],
  );

  const handleGenerate: MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();

    if (isRetrying) {
      const taskTemplatesIds = Object.keys(taskTemplateStatusMap);

      await deleteTaskTemplatesMutation.mutateAsync({ taskTemplatesIds });

      resetTaskTemplateStatusMap();
    }

    void generateWorkflow();
  };

  const handleRegenerate = async () => {
    deleteTemplateMutation.mutate(
      { templateId },
      {
        onSettled: () => {
          void $state.go('templateAiGenerate', { name, description, retryCount: retryCount + 1 });
        },
      },
    );
  };

  const handlePublishAndRun = async () => {
    if (!newestTemplateRevisionQuery.data?.defaultChecklistName) {
      await publishDraftMutation.mutateAsync({
        tmplRevId: templateRevisionId,
      });
      runChecklistDisclosure.onOpen();
    } else {
      return publishAndRunMutation.mutateAsync({});
    }
  };

  useMount(() => {
    if (initialConfig.autoStart && initialConfig.name && !isRetrying) {
      void generateWorkflow();
    }

    LocalStorageService.removeItem(AI_WORKFLOW_INITIAL_CONFIG);
  });

  useUnmount(() => {
    aiGenerationSlice.stopGeneration();
    toast.closeAll();
  });

  React.useEffect(() => {
    const channelName = ablyService.getChannelNameForTemplateRevision(templateRevisionId);
    const channel = ablyService.getChannel(channelName);

    const taskTemplatesUpdatedListener = () => {
      logger.info(`message from ${AblyEvent.EventType.TaskTemplatesUpdated}`);

      setIsTaskPropertiesGenerated(true);
    };

    const workflowImportSuccessListener = async () => {
      logger.info(`message from ${AblyEvent.EventType.AiWorkflowImportSuccess}`);
      // During import, the BE has filled the template name
      const newTemplateData = await newestTemplateRevisionQuery.refetch();
      if (newTemplateData.isSuccess) setName(newTemplateData.data.template.name);
      const taskTemplates = await queryClient.fetchQuery(taskTemplatesByTemplateRevisionIdQueryKey, () =>
        TaskTemplatesByTemplateRevisionIdQuery.queryFn({ templateRevisionId }),
      );
      updateTaskTemplateStatusMap(taskTemplates.filter(taskTemplate => Boolean(taskTemplate.name)));
      $rootScope.$broadcast(TaskListEvent.AI_GENERATED_TASK_TEMPLATES, taskTemplates);
      aiGenerationSlice.finishWorkflowImport();
      void uploadCoverImageAndIcon(newTemplateData.data?.template.name);
    };

    logger.info(`subscribing to ${AblyEvent.EventType.TaskTemplatesUpdated}`);
    channel.subscribe(AblyEvent.EventType.TaskTemplatesUpdated, taskTemplatesUpdatedListener);
    logger.info(`subscribing to ${AblyEvent.EventType.AiWorkflowImportSuccess}`);
    channel.subscribe(AblyEvent.EventType.AiWorkflowImportSuccess, workflowImportSuccessListener);

    return () => {
      logger.info(`unsubscribing from ${AblyEvent.EventType.TaskTemplatesUpdated}`);
      channel.unsubscribe(AblyEvent.EventType.TaskTemplatesUpdated);
      logger.info(`unsubscribing from ${AblyEvent.EventType.AiWorkflowImportSuccess}`);
      channel.unsubscribe(AblyEvent.EventType.AiWorkflowImportSuccess);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only when template revision changes
  }, [templateRevisionId]);

  const handleClose = () => {
    LocalStorageService.removeItem(SHOW_AI_WORKFLOW_SETUP_MODAL_FOR);
    onClose();
  };

  React.useEffect(() => {
    // The success toast on this effect are only for task generation.
    if (aiGenerationSlice.generationType === AiGenerationType.SingleTask) return;

    const tryShowingToast = async () => {
      const taskTemplatesIds = Object.keys(taskTemplateStatusMap);
      const isEveryTaskTemplateLoaded =
        taskTemplatesIds.length > 0
          ? Object.entries(taskTemplateStatusMap).every(([_, status]) => status === 'loaded' || status === 'ignored')
          : false;
      const isEverythingLoaded = isEveryTaskTemplateLoaded && isTaskPropertiesGenerated;

      // Close the modal and remove the backdrop once everything is loaded
      const isGenerationComplete = isEverythingLoaded && aiGenerationSlice.isGenerating;
      if (!isGenerationComplete) {
        return;
      }

      const { generationType } = aiGenerationSlice;
      aiGenerationSlice.stopGeneration();
      updateTaskTemplateStatusMap([]);
      const stats = await getSummaryStats();
      LocalStorageService.setItem(`ai-generated-workflow-${templateId}`, 'true');
      const newestTemplateName = (await newestTemplateRevisionQuery.refetch()).data?.template.name;
      const workflowName = name ? name : newestTemplateName;
      if (generationType === AiGenerationType.ImportWorkflowTasks) {
        toast({
          duration: null,
          render: ({ onClose }) => (
            <AiGenerationWorkflowSummary
              onPublishAndRun={handlePublishAndRun}
              onClose={onClose}
              subject="workflow"
              workflowName={workflowName}
              title="Your workflow has been imported!"
              stats={stats}
            />
          ),
        });
      } else {
        toast({
          duration: null,
          render: ({ onClose }) => (
            <AiGenerationWorkflowSummary
              onPublishAndRun={handlePublishAndRun}
              onClose={onClose}
              onRegenerate={handleRegenerate}
              subject="workflow"
              workflowName={workflowName}
              stats={stats}
            />
          ),
        });
      }
    };

    void tryShowingToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- show toast in these circumstances
  }, [
    taskTemplateStatusMap,
    isTaskPropertiesGenerated,
    aiGenerationSlice.isGenerating,
    toast,
    aiGenerationSlice,
    templateRevisionId,
    publishAndRunMutation,
    name,
  ]);

  React.useEffect(
    function showTaskContentGenerationSuccessToast() {
      const unsubscribe = $rootScope.$on(
        TaskListEvent.AI_WIDGET_GENERATION_FOR_TASK_ANIMATION_DONE,
        (_: angular.IAngularEvent, taskTemplate: TaskTemplate) => {
          if (aiGenerationSlice.isGenerating && aiGenerationSlice.taskTemplateId === taskTemplate.id) {
            aiGenerationSlice.stopGeneration();

            toast({
              containerStyle: { marginTop: '60px' },
              render: () => <AiGeneratingWorkflowSuccessToast subject="task" />,
            });
          }
        },
      );

      return () => {
        unsubscribe();
      };
    },
    [$rootScope, toast, $state, aiGenerationSlice],
  );

  const handleInputChange = React.useCallback(
    (name: string) => {
      setName(name);
      setHasModerationError(false);

      if (isPredefinedDescription) {
        setDescription('');
      }
    },
    [isPredefinedDescription],
  );

  const handleNameSelect = (option: WorkflowOption) => {
    // filling predefined description has been turned off while we are testing the feature
    // setIsPredefinedDescription(true);
    // setDescription(option.description);
    setHasModerationError(false);
    setName(option.name);
    descriptionRef.current?.focus();
  };

  const contextValue = React.useMemo(
    () => ({
      name,
      description: description ?? '',
      onInputChange: handleInputChange,
    }),
    [name, description, handleInputChange],
  );

  return (
    <>
      {aiGenerationSlice.isGenerating && (
        <AiGenerationLoadingState
          generationType={aiGenerationSlice.generationType}
          taskTemplateStatusMap={taskTemplateStatusMap}
        />
      )}

      <RunChecklist
        {...runChecklistDisclosure}
        onCancel={runChecklistDisclosure.onClose}
        onChecklistCreate={runChecklistDisclosure.onClose}
        templateId={templateId}
      />

      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <AiGeneratedModalSettingsContext.Provider value={contextValue}>
            <ModalHeader position="relative" fontSize="base" fontWeight="bold" p="0">
              <Box
                position="absolute"
                top="0"
                left="0"
                h="full"
                w="full"
                bg="linear-gradient(103.79deg, #8D77D7 29.15%, #5238AF 92.35%)"
                opacity="0.9"
              />

              <VStack
                bgImg={require('app/images/create-workflow-modal/ai-modal-cover.png')}
                h="52"
                justifyContent="flex-start"
                alignItems="flex-start"
                px="5"
                py="4"
                spacing="0"
              >
                <Text fontSize="lg" color="white" zIndex="1">
                  Process AI
                </Text>

                <Flex zIndex="1" justifyContent="center" alignItems="center" w="full">
                  <Image
                    w="full"
                    maxW="60"
                    src={require('app/images/create-workflow-modal/ai-modal-cover-v2.png')}
                    alt="Process Pete"
                  />
                </Flex>
              </VStack>
            </ModalHeader>
            <ModalCloseButton color="white" />
            <form>
              <ModalBody p="0">
                <VStack spacing="6" p="6">
                  <WorkflowNameField
                    maxLength={maxNameLength}
                    hasModerationError={hasModerationError}
                    description={description ?? ''}
                    value={name}
                    onSelect={handleNameSelect}
                    isLoading={isLoading}
                  />

                  {
                    <FormControl>
                      <FormLabel>Additional instructions</FormLabel>
                      <Text mb="2" variant="-1" color="gray.500">
                        This can help fine tune your workflow.
                      </Text>
                      <Textarea
                        w="full"
                        value={description}
                        ref={descriptionRef}
                        onChange={e => {
                          setDescription(e.target.value);
                          setIsPredefinedDescription(false);
                        }}
                        placeholder={
                          'e.g., “Give me a maximum of 15 steps, the last step needs to be approved by the HR manager.”'
                        }
                        isRequired
                        isDisabled={aiGenerationTaskTemplatesMutation.isLoading}
                        maxLength={200}
                      />
                    </FormControl>
                  }
                </VStack>
              </ModalBody>

              <ModalFooter>
                <HStack spacing={4}>
                  <Button
                    colorScheme="brand"
                    isDisabled={!name || isLoading || name.length > maxNameLength}
                    onClick={handleGenerate}
                    type="submit"
                    loadingText="Generating..."
                    isLoading={aiGenerationTaskTemplatesMutation.isLoading || deleteTaskTemplatesMutation.isLoading}
                  >
                    Generate with AI
                  </Button>
                </HStack>
              </ModalFooter>
            </form>
          </AiGeneratedModalSettingsContext.Provider>
        </ModalContent>
      </Modal>
    </>
  );
};

import { Text, Box, HStack } from 'components/design/next';
import { AutomationToggle } from '../toggle';
import React from 'react';
import {
  SolutionTypeTag,
  SolutionTypeTagDescriptionMap,
  isTemplateSolutionInstance,
  isIncomingWebhookInstance,
  FormSolutionTypeTagDescriptionMap,
  DataSetSolutionTypeTagDescription,
  isNativeAutomationWithLink,
} from '@process-street/subgrade/automation';
import { AutomationDeleteButton } from '../delete-button';
import { useTaskTemplateName } from '../../use-task-template-name';
import { match } from 'ts-pattern';
import { useAutomationSelector } from '../selector/context';
import { useGetAutomationInstanceQuery } from '../../utils/use-get-automation-instance';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { TemplateType } from '@process-street/subgrade/process';
import { useTemplateId } from '../../utils/use-template-id';
import { useDataSetId } from '../../utils/use-data-set-id';

type AutomationTopBarProps = {
  onDelete?: () => void;
};

export const AutomationTopBar: React.VFC<AutomationTopBarProps> = ({ onDelete }) => {
  const [state] = useAutomationSelector();
  const templateId = useTemplateId();
  const dataSetId = useDataSetId();
  const {
    automationInstanceId: selectedAutomationInstanceId,
    automationType: selectedAutomationType,
    solutionTypeTag: selectedSolutionType,
  } = state.context;
  const automationInstanceQuery = useGetAutomationInstanceQuery({
    automationInstanceId: selectedAutomationInstanceId,
    automationInstanceType: selectedAutomationType,
  });
  const automationInstance = automationInstanceQuery.data;

  const templateQuery = useGetTemplateQuery({ templateId });

  const taskName = useTaskTemplateName();

  const solutionTypeTag = match(automationInstance)
    .when(isTemplateSolutionInstance, ({ solutionTypeTag }) => solutionTypeTag)
    .when(isIncomingWebhookInstance, () => SolutionTypeTag.WhenChecklistCompletedThen)
    .when(isNativeAutomationWithLink, () => SolutionTypeTag.CreateChecklistWhen)
    .otherwise(() => selectedSolutionType);

  const name = React.useMemo(() => {
    if (dataSetId) {
      return solutionTypeTag ? DataSetSolutionTypeTagDescription[solutionTypeTag] : 'Update Data Set when...';
    }

    const descriptionMap =
      templateQuery.data?.templateType === TemplateType.Playbook
        ? SolutionTypeTagDescriptionMap
        : FormSolutionTypeTagDescriptionMap;

    if (solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen && taskName) {
      return `When ${taskName} is checked ...`;
    }

    if (solutionTypeTag === SolutionTypeTag.WhenChecklistCompletedThen && selectedAutomationType === 'webhook') {
      return SolutionTypeTagDescriptionMap[SolutionTypeTag.CreateChecklistWhen];
    }
    return solutionTypeTag ? descriptionMap[solutionTypeTag] : 'Automation';
  }, [solutionTypeTag, taskName, templateQuery.data?.templateType, dataSetId, selectedAutomationType]);

  return (
    <HStack as="header" justifyContent="space-between" minH="10">
      <Box w="100%" maxW="lg">
        <Text variant="2" fontWeight="700">
          {name}
        </Text>
      </Box>

      {automationInstance ? (
        <HStack>
          <AutomationToggle automationInstance={automationInstance} />
          <AutomationDeleteButton automationInstance={automationInstance} onDelete={onDelete} />
        </HStack>
      ) : null}
    </HStack>
  );
};

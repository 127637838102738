import { Template, TemplateType } from '@process-street/subgrade/process';
import * as React from 'react';
import {
  forwardRef,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  MenuProps,
  Portal,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { GetTemplateQuery, useGetTemplateQuery } from 'features/template/query-builder';
import { createUsableContext } from '@process-street/subgrade/util';
import { DiscardTemplateButton } from './discard-template-button';
import { match, P } from 'ts-pattern';
import { PageMenu } from 'features/template/components/template-menu/page';
import { WorkflowMenu } from 'features/template/components/template-menu/workflow';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { FormMenu } from './form';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { useMatch } from '@process-street/adapters/navigation';

export type Context = {
  setCloseOnBlur: (t: boolean) => void;
  closeOnBlur: boolean;
  templateId: Template['id'];
  /** Some effects should depend on the context of being in a list or a page itself */
  view: 'index' | 'show';
};
export const [useTemplateMenuContext, TemplateMenuContext] = createUsableContext<Context>({
  hookName: 'useTemplateMenuContext',
  providerName: 'TemplateMenuContext.Provider',
});

const TemplateMenuList: React.FC<React.PropsWithChildren<MenuListProps>> = props => {
  return <MenuList maxH="70vh" overflowY="auto" {...props} />;
};

export const TemplateMenuItem = forwardRef<MenuItemProps, 'button'>((props, ref) => {
  return <MenuItem ref={ref} iconSpacing="2" color="gray.600" {...props} />;
});

export type MenuViewModes = 'view' | 'dashboard';

export type Props = Omit<MenuProps, 'children'> & {
  mode: MenuViewModes | 'edit';
  templateId?: Template['id'];
  menuButton?: React.ReactElement;
  // TODO a better pattern will be to deconstruct template menu to more composable parts so we don't have to do these kinds of namespaced props
  menuListProps?: Omit<MenuListProps, 'children'>;
  onClose?: () => void;
};

const Edit: React.FC<React.PropsWithChildren<Omit<Props, 'mode' | 'templateId'>>> = ({
  children,
  menuButton,
  menuListProps,
  ...props
}) => {
  const { templateId, closeOnBlur } = useTemplateMenuContext();
  const { data: templateRevision } = useNewestTemplateRevisionQuery(
    {
      templateId,
      editable: true,
    },
    { enabled: !!templateId },
  );

  const tmplRevId = templateRevision?.id;
  const isEditorV2 = useMatch('templateV2');

  return (
    <Menu closeOnBlur={closeOnBlur} autoSelect={false} closeOnSelect={false} isLazy={true} {...props}>
      {menuButton ?? (
        <MenuButton
          as={IconButton}
          icon={<Icon size="4" variant="far" icon="ellipsis-h" color="white" />}
          _hover={{ bgColor: 'gray.500' }}
          _active={{ bgColor: 'gray.500' }}
          _focus={{ bgColor: 'gray.500' }}
          variant="outline"
          colorScheme="gray"
          isDisabled={!tmplRevId}
          aria-label="actions"
        />
      )}
      <Portal>
        <ThemeProvider2024>
          <TemplateMenuList {...menuListProps} fontSize={isEditorV2 ? 'md' : 'lg'}>
            {children}
            <DiscardTemplateButton>
              <MenuItem
                aria-label="discard changes"
                color="red.500"
                icon={<Icon icon="trash-alt" color="red.500" size="4" />}
              >
                Discard Changes
              </MenuItem>
            </DiscardTemplateButton>
          </TemplateMenuList>
        </ThemeProvider2024>
      </Portal>
    </Menu>
  );
};

type ViewProps = Omit<Props, 'templateId' | 'mode'> & {
  menuButton?: React.ReactElement;
  children?: React.ReactNode;
  mode: MenuViewModes;
};

const View = ({ children, menuButton, menuListProps, ...props }: ViewProps) => {
  const { templateId, closeOnBlur } = useTemplateMenuContext();
  const template = useGetTemplateQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
      staleTime: GetTemplateQuery.staleTime,
    },
  );

  return (
    <Menu closeOnBlur={closeOnBlur} autoSelect={false} closeOnSelect={false} {...props}>
      <TemplateMenuList {...menuListProps}>
        <MenuGroup title="options">
          {match(template)
            .with({ status: 'success', data: { templateType: TemplateType.Page } }, () => (
              <PageMenu>{children}</PageMenu>
            ))
            .with({ status: 'success', data: { templateType: TemplateType.Form } }, () => (
              <FormMenu>{children}</FormMenu>
            ))
            .with(
              { status: 'success', data: { templateType: P.union(TemplateType.Playbook, TemplateType.Form) } },
              () => <WorkflowMenu mode={props.mode}>{children}</WorkflowMenu>,
            )
            .otherwise(() => null)}
        </MenuGroup>
      </TemplateMenuList>

      {menuButton ?? (
        <MenuButton
          as={IconButton}
          icon={<Icon aria-label="actions" icon="ellipsis-h" variant="far" size="4" />}
          variant="tertiary"
        />
      )}
    </Menu>
  );
};

export const TemplateMenu: React.FC<React.PropsWithChildren<Props>> = props => {
  const { $stateParams } = useInjector('$stateParams');
  const templateId = props.templateId ?? $stateParams.id;
  const [closeOnBlur, setCloseOnBlur] = React.useState(true);
  const view: Context['view'] = props.templateId ? 'index' : 'show';

  const context = React.useMemo<Context>(
    () => ({ setCloseOnBlur, closeOnBlur, templateId, view }),
    [closeOnBlur, view, templateId],
  );

  React.useEffect(() => {
    setCloseOnBlur(true);
  }, [props.mode]);

  return (
    <TemplateMenuContext.Provider value={context}>
      {match(props.mode)
        .with('edit', () => {
          const { mode: _, ...rest } = props;
          return <Edit {...rest} />;
        })
        .with(P.union('view', 'dashboard'), () => {
          const { mode, ...rest } = props;
          return <View mode={mode as MenuViewModes} {...rest} />;
        })
        .exhaustive()}
    </TemplateMenuContext.Provider>
  );
};

import angular from 'angular';
import { dayjs as moment } from '@process-street/subgrade/util';
import { ArrayService } from 'services/array-service';
import { isT5KPlan } from 'services/plans';
import { trace } from 'components/trace';
import './form.component.html';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'FutureChecklistFormCtrl',
    function ($scope, FeatureFlagService, FutureChecklistService, SessionService, ToastService) {
      const ctrl = this;
      const logger = trace({ name: 'FutureChecklistFormCtrl' });
      logger.info('loading ctrl');

      const { Frequency, MonthBy } = FutureChecklistService;

      ctrl.$onInit = () => {
        // This should be set by the parent, but might not be
        $scope.info = $scope.info || {};
        $scope.info.assignees = $scope.info.assignees || [];
        $scope.info.name = $scope.info.name || '';

        if (!$scope.info.frequency && $scope.info.frequency !== Frequency.ONCE) {
          $scope.info.frequency = Frequency.WEEKLY;
          $scope.changeFrequency($scope.info.frequency);
        }

        $scope.frequencies = [Frequency.YEARLY, Frequency.MONTHLY, Frequency.WEEKLY, Frequency.DAILY];
        $scope.monthBys = [
          { value: MonthBy.DAY_OF_MONTH, label: 'Day of month' },
          { value: MonthBy.DAY_OF_WEEK, label: 'Day of week' },
        ];
        $scope.weekDays = [
          { label: 'S', value: 7 },
          { label: 'M', value: 1 },
          { label: 'T', value: 2 },
          { label: 'W', value: 3 },
          { label: 'T', value: 4 },
          { label: 'F', value: 5 },
          { label: 'S', value: 6 },
        ];
      };

      $scope.assignUsersHelpText = 'Search for a person in your organization by name or email address.';

      $scope.assignUser = function (user) {
        if (user && user.id) {
          $scope.info.assignees.push(user);
          $scope.formFutureChecklist.$setDirty(true);
        }
      };

      $scope.unassignUser = function (user) {
        ArrayService.desplice($scope.info.assignees, user);
        $scope.formFutureChecklist.$setDirty(true);
      };

      // Dates

      $scope.changeFrequency = function (frequency) {
        switch (frequency) {
          case Frequency.YEARLY:
            $scope.info.repeat = { yearInterval: 1 };
            break;
          case Frequency.MONTHLY:
            $scope.info.repeat = { monthInterval: 1, monthBy: MonthBy.DAY_OF_MONTH };
            break;
          case Frequency.WEEKLY:
            $scope.info.repeat = { weekInterval: 1, weekDays: { 1: true } };
            break;
          case Frequency.DAILY:
            $scope.info.repeat = { dayInterval: 1 };
            break;
          default:
            $scope.info.repeat = {};
            break;
        }
      };

      $scope.saveStartDate = function (startDate) {
        if (startDate > Date.now()) {
          $scope.info.startDate = startDate;

          if ($scope.info.dueDate && startDate >= $scope.info.dueDate) {
            ToastService.openToast({
              status: 'warning',
              title: `The due data has been removed as it was before the start date`,
            });

            $scope.info.dueDate = null;
          }
        } else {
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't update the start date`,
            description: 'The start date must be in the future.',
          });

          $scope.info.startDate = null;
        }
      };

      $scope.removeStartDate = function () {
        $scope.info.startDate = null;
        $scope.info.dueDate = null;
      };

      $scope.saveDueDate = function (dueDate) {
        if (dueDate > $scope.info.startDate) {
          $scope.info.dueDate = dueDate;
        } else {
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't update the due date`,
            description: 'The due date cannot be before the start date.',
          });

          $scope.info.dueDate = null;
        }
      };

      $scope.removeDueDate = function () {
        $scope.info.dueDate = null;
      };

      $scope.addDuePeriod = function (info) {
        info.duePeriod = {
          years: 0,
          months: 0,
          days: 0,
          hours: 0,
          minutes: 0,
        };
      };

      $scope.removeDuePeriod = function (info) {
        delete info.duePeriod;
      };

      $scope.formatInTimeZone = function (tz, timestamp) {
        return moment.tz(timestamp, tz).format('ddd MMM D YYYY [at] h:mmA z');
      };

      // Events

      function updateSummary() {
        if ($scope.info.startDate) {
          const jobs = FutureChecklistService.generateJobs($scope.info);
          $scope.summary = FutureChecklistService.generateSummary($scope.info.startDate, jobs);
        } else {
          delete $scope.summary;
        }
      }

      $scope.$watch('info', updateSummary, true);

      $scope.changeToWeekly = function () {
        $scope.info.frequency = Frequency.WEEKLY;
        $scope.changeFrequency(Frequency.WEEKLY);
      };

      $scope.$watch('info.frequency', frequency => {
        if (angular.isDefined(frequency)) {
          const { info } = $scope;
          if (frequency === Frequency.ONCE) {
            if (info.startDate && FutureChecklistService.isPositivePeriod(info.duePeriod)) {
              info.dueDate = FutureChecklistService.calculateDueDate(info.startDate, info.duePeriod);
            }
            delete info.duePeriod;
          } else {
            if (info.startDate && info.dueDate) {
              info.duePeriod = FutureChecklistService.calculateDuePeriod(info.startDate, info.dueDate);
            }
            delete info.dueDate;
          }
        }
      });

      $scope.$watch('info.template', (newTemplate, oldTemplate) => {
        if (oldTemplate && newTemplate?.id !== oldTemplate?.id) {
          $scope.info.name = '';
        }
      });

      $scope.futureChecklistNameChanged = newValue => {
        $scope.info.name = newValue;
        $scope.$digest();
      };

      $scope.isFreeMemberPreAssignmentsEnabled = () => {
        const freeMemberPreAssignmentsEnabled = FeatureFlagService.getFeatureFlags().freeMemberPreAssignments;
        const plan = SessionService.getCurrentPlan();
        const planT5K = plan && isT5KPlan(plan.id);

        return planT5K && freeMemberPreAssignmentsEnabled;
      };
    },
  );

import { Box, Button, Grid, GridItem, HStack, Spacer, Stack, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react';
import { ChecklistRuleDefinition, RuleConstants, TaskVisibility } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import * as React from 'react';
import { useEffect } from 'react';
import { FormFieldOrTaskOption } from '../form-fields-select';
import { match, P } from 'ts-pattern';
import { OptionDropdown } from '../option-dropdown';
import { TaskTemplatesSelector } from '../task-templates-selector';
import { useDebounce, useDebouncedCallback, useThrottledCallback } from 'use-debounce';
import { Icon } from 'components/design/next';
import { useEffectOnce } from 'react-use';
import {
  getChecklistRuleDefinitionActionChange,
  GetChecklistRuleDefinitionActionChangeParams,
  useRuleDefinitionValidation,
} from './use-rule-definition-validation';
import { MeatballMenu } from './meatball-menu';
import { Condition } from 'features/conditional-logic/components/rule-definition/condition';
import {
  ConditionWithDisplayProps,
  useLogicalRule,
} from 'features/conditional-logic/components/rule-definition/use-logical-rule';
import { Muid } from '@process-street/subgrade/core';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { useConditionalLogicModalStore } from '../modal/store';

export type RuleDefinitionProps = {
  selectedTask?: TaskTemplate;
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
  onChange: (ruleDefinition: Partial<ChecklistRuleDefinition>) => void;
  onDelete?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onAddRuleBelow?: () => void;
  onDuplicate?: () => void;
  onSelect?: () => void;
  rule: ChecklistRuleDefinition;
  showErrors?: boolean;
  initialError?: boolean;
  isSelected?: boolean;
  formFieldSelectOptions: FormFieldOrTaskOption[];
  formFieldSelectOptionsSelectedTask: FormFieldOrTaskOption[];
  isNew?: boolean;
  hasRuleSelected?: boolean;
  isScrolling?: boolean;
  selectedWidgetGroupId?: Muid;
  selectedTaskGroupId?: Muid;
};

const effects = [RuleConstants.TaskVisibility.SHOW, RuleConstants.TaskVisibility.HIDE];

const resolveEffectName = (effect: 'show' | 'hide') => {
  return RuleConstants.TaskVisibilityNameMap[effect];
};

export const RuleDefinition: React.VFC<RuleDefinitionProps> = ({
  rule,
  widgets,
  taskTemplates,
  showErrors,
  isSelected,
  initialError = false,
  onChange,
  onDelete,
  onMoveUp,
  onMoveDown,
  onAddRuleBelow,
  onDuplicate,
  onSelect,
  formFieldSelectOptions,
  formFieldSelectOptionsSelectedTask,
  isNew,
  hasRuleSelected,
  isScrolling = false,
  selectedWidgetGroupId,
  selectedTaskGroupId,
}) => {
  const [showNewIndicator, setShowNewIndicator] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { normalizedData } = useConditionalLogicModalStore();

  const { conditions, onConditionChange } = useLogicalRule(rule, onChange);

  const [effect, setEffect] = React.useState<TaskVisibility | null>(() => {
    if (rule?.hidden === undefined) return null;

    return rule.hidden ? RuleConstants.TaskVisibility.HIDE : RuleConstants.TaskVisibility.SHOW;
  });

  const [selectedWidgets, setSelectedWidgets] = React.useState<Widget[]>(() => {
    if (!rule?.widgetGroupIds) return [];

    return rule.widgetGroupIds.reduce<Widget[]>((acc, groupId) => {
      const widget = normalizedData.widgets.byGroupId[groupId];

      if (widget) acc.push(widget);

      return acc;
    }, []);
  });

  const [selectedTasks, setSelectedTasks] = React.useState<TaskTemplate[]>(() => {
    if (!rule?.taskTemplateGroupIds) return [];

    return rule.taskTemplateGroupIds.reduce<TaskTemplate[]>((tasks, groupId) => {
      const task = normalizedData.tasks.byGroupId[groupId];

      if (task) tasks.push(task);

      return tasks;
    }, []);
  });

  // When the user selects a form field in the sidebar, the first rule associated with the form field
  // is selected by default, so, if the user needs to edit another rule, he needs to select it before.
  // To achieve this in the TaskTemplateSelector component, we need to disable the value removal and only
  // enable it when the rule is selected.
  const canRemoveTaskTemplatesOrWidgets = hasRuleSelected ? isSelected : true;

  const handleDuplicate = () => {
    onDuplicate?.();
  };

  const handleDelete = () => {
    onDelete?.();
  };

  const handleEffectChange = ({ value }: { value: string }) => {
    if (value && value !== 'show' && value !== 'hide') {
      console.error(`effect ${value} not supported`);

      return;
    }

    setEffect(value as TaskVisibility);

    handleActionChangeDebounced({
      selectedTasks,
      selectedWidgets,
      rule,
      effect: value as TaskVisibility,
    });
  };

  const handleActionChangeDebounced = useDebouncedCallback((params: GetChecklistRuleDefinitionActionChangeParams) => {
    const ruleDefinition = getChecklistRuleDefinitionActionChange(params);
    onChange?.(ruleDefinition);
  }, 200);
  const handleActionChangeThrottled = useThrottledCallback((params: GetChecklistRuleDefinitionActionChangeParams) => {
    const ruleDefinition = getChecklistRuleDefinitionActionChange(params);
    onChange?.(ruleDefinition);
  }, 200);

  const [debouncedIsScrolling] = useDebounce(isScrolling, 1000);

  const ruleIsValid = useRuleDefinitionValidation({
    isScrolling: debouncedIsScrolling,
    initialValue: !initialError,
    ruleDeps: {
      selectedTasks,
      selectedWidgets,
      rule,
      effect,
    },
  });

  const conditionsAreValid = conditions.every(isConditionValid);
  const containsTimeBasedCondition = conditions.some(c => 'dateType' in c);

  useEffect(
    function timeBasedRuleShowOnly() {
      if (containsTimeBasedCondition && effect === TaskVisibility.HIDE) {
        setEffect(TaskVisibility.SHOW);
      }
    },
    [containsTimeBasedCondition, effect],
  );

  const isEffectChangeEnabled = conditionsAreValid && !containsTimeBasedCondition;

  useEffectOnce(() => {
    if (!isNew) return;

    setShowNewIndicator(true);
    const timeout = setTimeout(() => setShowNewIndicator(false), 5000);

    return () => clearTimeout(timeout);
  });

  const [description, setDescription] = React.useState(rule.description);
  const descriptionRowCount = React.useMemo(
    () => Math.min([...(description ?? '').matchAll(/\n/gm)].length + 1, 3),
    [description],
  );

  // for description changes, we use throttling instead of debouncing to control performance
  // because description field changes could change the height of the rule list
  // this way, changes are propagated "sooner than later"
  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    const params = {
      selectedTasks,
      selectedWidgets,
      effect,
      rule: { ...rule, description: value },
    };
    handleActionChangeThrottled(params);
  };

  const widgetParticipatingInRuleAction =
    selectedWidgetGroupId && selectedWidgets.map(w => w.header.group.id).includes(selectedWidgetGroupId);
  const taskParticipatingInRuleAction =
    selectedTaskGroupId && selectedTasks.map(t => t.group.id).includes(selectedTaskGroupId);
  const thenHighlighted = widgetParticipatingInRuleAction || taskParticipatingInRuleAction;

  const { borderColor, boxShadow } = match({
    hasErrors: !ruleIsValid,
    showErrors,
    showNewIndicator,
    isSelected,
    isScrolling,
  })
    .with({ isSelected: true }, () => ({ borderColor: 'brand.300', boxShadow: 'unset' }))
    .with({ hasErrors: true, showErrors: true }, () => ({ borderColor: 'red.500', boxShadow: 'unset' }))
    .with({ showNewIndicator: true }, () => ({ borderColor: 'brand.200', boxShadow: '0px 0px 6px 0px #0067be40' }))
    .otherwise(() => ({ borderColor: 'gray.200', boxShadow: 'unset' }));

  return (
    <HStack w="full" data-group spacing="2" pl="8">
      <Grid
        ref={containerRef}
        data-testid="rule-definition"
        aria-selected={isSelected}
        gridTemplateColumns={{ base: '50px 30px 1fr', md: 'minmax(5%, 50px) minmax(3%, 30px) 1fr' }}
        py="5"
        pr={{ base: '4', md: '6' }}
        bgColor={isSelected ? 'brand.50' : undefined}
        borderRadius="12px"
        borderWidth="1px"
        borderColor={borderColor}
        boxShadow={boxShadow}
        borderStyle="solid"
        transition="1s ease box-shadow"
        gap="2.5"
        w="full"
        sx={{
          '.blvd-select': {
            'width': '100%',
            '.blvd-select__indicator-separator': {
              display: 'none',
            },

            '.blvd-select__menu': {
              zIndex: 99,
            },

            '.blvd-select__control': {
              'borderColor': 'gray.300',

              '.blvd-select__ellipsis, .blvd-select__placeholder': {
                fontSize: 'sm',
              },

              '.blvd-select__indicator svg': {
                transform: 'rotate(0deg)',
                transition: '200ms ease transform',
              },

              '&.blvd-select__control--menu-is-open': {
                '.blvd-select__indicator svg': {
                  transform: 'rotate(180deg)',
                },
              },
            },
          },
          '.blvd-select .blvd-select__menu .blvd-select__option': {
            fontSize: 'sm',
          },
        }}
        onClick={onSelect}
      >
        {conditions.map((c, idx) => {
          const isFirstCondition = idx === 0;
          return (
            <Condition
              key={c.id}
              condition={c}
              // for the "if" condition, we only allow widget selections for the current task
              // for the rest "and/or" conditions, we allow selecting any widget
              options={isFirstCondition ? formFieldSelectOptionsSelectedTask : formFieldSelectOptions}
              widgets={widgets}
              taskTemplates={taskTemplates}
              onChange={data => onConditionChange(c.id, data)}
              meatballMenu={
                // first condition receives meatball menu for rule actions
                // (rest have delete buttons displayed)
                isFirstCondition
                  ? () => (
                      <MeatballMenu
                        onDuplicate={handleDuplicate}
                        onDelete={handleDelete}
                        onMoveUp={onMoveUp}
                        onMoveDown={onMoveDown}
                      />
                    )
                  : undefined
              }
              displayType={c.displayType}
              onAddAnd={c.onAddAnd}
              onAddOr={c.onAddOr}
              onDelete={c.onDelete}
              selectedWidgetGroupId={selectedWidgetGroupId}
            />
          );
        })}

        <GridItem
          paddingTop={{ base: '3', md: 0 }}
          display="flex"
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="flex-end"
        >
          <Text
            color={thenHighlighted ? 'purple.500' : 'gray.500'}
            fontWeight={thenHighlighted ? '700' : 'semibold'}
            variant="-2u"
          >
            Then
          </Text>
        </GridItem>

        <GridItem colSpan={2} as={Stack} direction={{ base: 'column', md: 'row' }} spacing="2.5">
          <Box w="full" maxWidth={{ base: 'column', md: '130px' }}>
            <OptionDropdown
              disabled={!isEffectChangeEnabled}
              displayValue={effect ? resolveEffectName(effect) : undefined}
              label="Effect"
              value={effect ?? undefined}
              onChange={handleEffectChange}
              options={effects.map(effect => ({
                value: effect,
                label: effect,
              }))}
            />
          </Box>

          <TaskTemplatesSelector
            onChange={(widgets, tasks) => {
              setSelectedWidgets(widgets);
              setSelectedTasks(tasks);

              handleActionChangeDebounced({
                selectedTasks: tasks,
                rule,
                effect,
                selectedWidgets: widgets,
              });
            }}
            selectedWidgets={selectedWidgets}
            selectedTaskTemplates={selectedTasks}
            disabled={!effect}
            canRemoveValues={canRemoveTaskTemplatesOrWidgets}
          />
        </GridItem>

        <GridItem />
        <GridItem colSpan={2}>
          <Text color="gray.500" fontWeight="semibold" variant="-1" mb={2} mt={4}>
            Note
          </Text>
          <Textarea
            backgroundColor="white"
            fontSize="sm"
            placeholder="Add a note about this rule (optional)"
            maxHeight={descriptionRowCount * 6 + 4}
            minHeight={10}
            value={description}
            rows={descriptionRowCount}
            resize="none"
            maxLength={255}
            onChange={event => handleDescriptionChange(event.target.value)}
          ></Textarea>
        </GridItem>
      </Grid>

      <VStack spacing="4">
        {onMoveUp ? (
          <Tooltip openDelay={500} label="Move up">
            <Button
              w="8"
              h="8"
              minW="0"
              variant="ghost"
              bgColor="gray.100"
              aria-label="Move up"
              onClick={onMoveUp}
              opacity="0"
              transition="200ms ease opacity"
              _groupHover={{ opacity: 1 }}
            >
              <Icon icon="arrow-up" size="4" color="gray.700" />
            </Button>
          </Tooltip>
        ) : (
          <Spacer minH="8" />
        )}

        <Tooltip openDelay={500} label="Add a new rule below">
          <Button
            w="8"
            h="8"
            minW="0"
            variant="ghost"
            bgColor="gray.100"
            aria-label="Add a new rule below"
            onClick={onAddRuleBelow}
            opacity="0"
            transition="200ms ease opacity"
            _groupHover={{ opacity: 1 }}
          >
            <Icon icon="plus" size="4" color="gray.700" />
          </Button>
        </Tooltip>

        {onMoveDown ? (
          <Tooltip openDelay={500} label="Move down">
            <Button
              w="8"
              h="8"
              minW="0"
              variant="ghost"
              bgColor="gray.100"
              aria-label="Move down"
              onClick={onMoveDown}
              opacity="0"
              transition="200ms ease opacity"
              _groupHover={{ opacity: 1 }}
            >
              <Icon icon="arrow-down" size="4" color="gray.700" />
            </Button>
          </Tooltip>
        ) : (
          <Spacer minH="8" />
        )}
      </VStack>
    </HStack>
  );
};

function isConditionValid(c: ConditionWithDisplayProps): boolean {
  const { id, operandValue } = match(c)
    .with({ formFieldWidgetGroupId: P.string }, condition => ({
      id: condition.formFieldWidgetGroupId,
      operandValue: condition.operandValue,
    }))
    .with({ taskTemplateGroupId: P.string }, condition => ({
      id: condition.taskTemplateGroupId,
      operandValue: condition.operand.value,
    }))
    .with({ dateType: P.any }, condition => ({
      id: condition.id,
      operandValue: condition.operand.value,
    }))
    .otherwise(() => ({ id: null, operandValue: null }));

  return Boolean(id && c.operator && (operandValue || !ConditionalLogicUtils.isOperandValueRequired(c.operator)));
}

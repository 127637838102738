import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { AuditMetadata, Muid, MuidUtils, orderTreeService } from '@process-street/subgrade/core';
import { OrderTree, TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { toOrderTrees } from 'pages/forms/_id/edit/helpers/form-field';
import { generateAudit } from '@process-street/subgrade/test';

export type OptimisticTaskTemplate = {
  id: Muid;
  templateRevisionId: Muid;
  templateRevision: { id: Muid };
  groupId: Muid;
  group: { id: Muid };
  orderTree: OrderTree;
  name?: string;
  taskType: TaskTemplateTaskType;
  audit: AuditMetadata;
};

export type CreateTaskTemplateMutationParams = OptimisticTaskTemplate;

export type CreateTaskTemplateMutationResponse = TaskTemplate;

export const CreateTaskTemplateMutation = {
  key: ['put', 'task-templates'],
  getKey() {
    return [...CreateTaskTemplateMutation.key];
  },
  mutationFn: (params: CreateTaskTemplateMutationParams) =>
    axiosService
      .getAxios()
      .put<CreateTaskTemplateMutationResponse>(`/1/task-templates/${params.id}`, params)
      .then(res => res.data),
};

export const useCreateTaskTemplateMutation = (
  options: UseMutationOptions<CreateTaskTemplateMutationResponse, AxiosError, CreateTaskTemplateMutationParams> = {},
) => {
  return useMutation(CreateTaskTemplateMutation.mutationFn, options);
};

export function makeOptimisticTaskTemplate({
  id,
  group,
  name,
  templateRevisionId,
  taskType,
  atIndex,
  taskTemplates,
}: {
  id?: Muid;
  group?: { id: Muid };
  name?: string;
  templateRevisionId: Muid;
  taskType: TaskTemplateTaskType;
  atIndex: number;
  taskTemplates: TaskTemplate[];
}): OptimisticTaskTemplate {
  const referenceTree = taskTemplates[atIndex] ? taskTemplates[atIndex].orderTree : null;
  const truncatedName = name && name.slice(0, 255);
  const groupId = group?.id ?? MuidUtils.randomMuid();
  const orderTrees = toOrderTrees(taskTemplates);
  const [orderTree] = orderTreeService.after(orderTrees, referenceTree);

  return {
    id: id ?? MuidUtils.randomMuid(),
    templateRevisionId,
    templateRevision: { id: templateRevisionId },
    groupId,
    group: { id: groupId },
    orderTree,
    name: truncatedName,
    taskType,
    audit: generateAudit(),
  };
}

import { FormControl, FormErrorMessage, Textarea as ChakraTextarea, VStack } from 'components/design/next';
import Textarea from 'react-autosize-textarea';
import React from 'react';
import { useActor, useSelector } from '@xstate/react';
import { TextareaFormFieldActor } from './textarea-form-field-machine';
import { ValidationSelectors } from '../validation-machine';
import { RulesEngineTargetSelectors } from '../../form-response-body/rules-engine-machine';
import { StringUtils } from '@process-street/subgrade/util';
import { FormResponseLabel } from '../common';

export interface TextareaFormFieldProps {
  actor: TextareaFormFieldActor;
}

export const TextareaFormField: React.FC<React.PropsWithChildren<TextareaFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const { widget } = state.context;
  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const setValue: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    send({ type: 'CHANGE', value: e.target.value });
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  const isInvalid = ValidationSelectors.isActorInvalidVisible(state.context.validationActor);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
    >
      <FormResponseLabel>{widget.label || 'Untitled Long Text'}</FormResponseLabel>
      <ChakraTextarea
        as={Textarea}
        w="full"
        maxW="175"
        maxH="80"
        borderColor="gray.300"
        value={state.context.value}
        placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={setValue}
        isDisabled={state.matches('input.disabled')}
        autoFocus={state.matches('autoFocus.enabled')}
      />

      <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
    </FormControl>
  );
};

import { Box } from '@chakra-ui/react';
import { Muid } from '@process-street/subgrade/core';
import { CDNImage } from 'components/cdn-image';
import { useBreakpointPx } from 'components/design/next';
import { useCoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { TOP_BAR_HEIGHT_TOKEN } from 'pages/forms/_id/shared';

export type BackgroundImageProps = { templateId: Muid };

export const BackgroundImage = ({ templateId }: BackgroundImageProps) => {
  const breakpointPx = useBreakpointPx();
  const coverImageQuery = useCoverImageByTemplateIdQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
      select: data => data?.s3File,
    },
  );

  return (
    <Box position="sticky" top={TOP_BAR_HEIGHT_TOKEN}>
      {match(coverImageQuery)
        .with({ status: 'success', data: P.not(P.nullish) }, ({ data }) => (
          <Box w="full" overflow="hidden" position="absolute">
            <CDNImage
              alt={data.originalName}
              s3File={data}
              transformation={{
                width: breakpointPx,
              }}
              opacity="0.7"
              maxHeight="60vh"
            />

            <Box position="absolute" top="0" w="full" h="full" bgGradient="linear(transparent 50%, gray.50)" />
          </Box>
        ))
        .otherwise(() => (
          <Box w="full" h="50vh" overflow="hidden" position="absolute" bgGradient="linear(gray.100, gray.50)" />
        ))}
    </Box>
  );
};

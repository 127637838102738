import { QueryKey } from 'react-query';
import { Muid } from '@process-street/subgrade/core';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type GetTasksAssignmentsByChecklistRevisionQueryParams = { checklistRevisionId: Muid };

export type GetTasksAssignmentsByChecklistRevisionQueryResponse = TaskAssignment[];

export const GetTasksAssignmentsByChecklistRevisionQuery = {
  key: ['checklist-revisions-task'],
  getKey: (params: GetTasksAssignmentsByChecklistRevisionQueryParams): QueryKey => [
    ...GetTasksAssignmentsByChecklistRevisionQuery.key,
    params,
  ],
  queryFn: ({ checklistRevisionId }: GetTasksAssignmentsByChecklistRevisionQueryParams) =>
    axiosService
      .getAxios()
      .get<GetTasksAssignmentsByChecklistRevisionQueryResponse>(
        `/1/checklist-revisions/${checklistRevisionId}/tasks/assignments`,
      )
      .then(res => res.data),
};

export const useGetTasksAssignmentsByChecklistRevisionQuery = <
  Select = GetTasksAssignmentsByChecklistRevisionQueryResponse,
>(
  params: GetTasksAssignmentsByChecklistRevisionQueryParams,
  options: UseQueryOptions<GetTasksAssignmentsByChecklistRevisionQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    GetTasksAssignmentsByChecklistRevisionQuery.getKey(params),
    () => GetTasksAssignmentsByChecklistRevisionQuery.queryFn(params),
    options,
  );
};

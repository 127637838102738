import React from 'react';
import { Progress } from 'components/design/next';
import {
  FormResponseActor,
  FormResponseMachineSelectors,
} from 'pages/responses/_id/components/form-response-body/form-response-machine';
import { useSelector } from '@xstate/react';

export type ProgressBarProps = {
  actor: FormResponseActor;
};

export const ProgressBar: React.FC<React.PropsWithChildren<ProgressBarProps>> = ({ actor }) => {
  const totalTaskCount = useSelector(actor, FormResponseMachineSelectors.getTotalTaskCount);
  const currentTaskIndex = useSelector(actor, FormResponseMachineSelectors.getCurrentTaskIndex);

  const progress = ((currentTaskIndex + 1) / totalTaskCount) * 100;

  return <Progress w="full" size="sm" value={progress} isAnimated={true} aria-label="step progress bar" />;
};

import { Muid } from '@process-street/subgrade/core';
import { ApprovalBulkAction, TokenPayload } from 'components/approvals/email-initiated/email-initiated.component';
import { EmailInitiatedApprovalContainer } from 'components/approvals/email-initiated/EmailInitiatedApprovalContainer';
import * as React from 'react';

interface EmailInitiatedApprovalReactWrapperProps {
  taskId: Muid;
  action: ApprovalBulkAction;
  token: string;
  tokenPayload: TokenPayload;
  onLoginClicked: () => void;
}

export class EmailInitiatedApprovalContainerWrapper extends React.PureComponent<
  EmailInitiatedApprovalReactWrapperProps,
  Record<string, unknown>
> {
  public render() {
    return (
      <EmailInitiatedApprovalContainer
        taskId={this.props.taskId}
        action={this.props.action}
        token={this.props.token}
        tokenPayload={this.props.tokenPayload}
        onLoginClicked={this.props.onLoginClicked}
      />
    );
  }
}

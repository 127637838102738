import { Muid, Option } from '@process-street/subgrade/core';
import { OneOffTask, OneOffTaskVisibility } from '@process-street/subgrade/one-off-task';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Stack,
  Text,
  Textarea,
  VStack,
} from 'components/design/next';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  GetOneOffTaskQuery,
  GetOneOffTasksByChecklistQuery,
  UpdateSubtasksMutation,
} from 'features/one-off-tasks/query-builder';
import { UpdateOneOffTaskMutation } from 'features/one-off-tasks/query-builder/update-one-off-task-mutation';
import { FormikTouched, useFormik } from 'formik';
import { useCurrentUser } from 'hooks/use-current-user';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { AssignmentPicker } from '../shared/assignment-picker';
import { DueDatePicker } from '../shared/due-date-picker';
import { useOneOffTaskTopbar } from '../shared/hooks';
import { WorkflowRunLink } from '../shared/workflow-run-link';
import { editOneOffTaskSettingsSchema, EditOneOffTaskSettingsSchema } from './schema';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { TopbarMenu } from '../shared/topbar-menu';
import { AddAttachmentButton, AttachmentsList } from '../shared/attachments';
import { useOneOffTaskAttachments } from '../shared/hooks/use-one-off-task-attachments';
import { useBeforeUnload } from 'react-use';
import { TaskComments } from 'features/comments/components/task';
import { useInboxItemsDataSource } from 'pages/tasks/hooks/use-inbox-items-data-source';
import { OneOffTaskFormFields } from '../form-fields';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import {
  GetFormFieldValuesByChecklistRevisionIdQuery,
  useGetFormFieldValuesByChecklistRevisionIdQuery,
  useGetWidgetsByChecklistRevisionIdQuery,
} from 'features/widgets/query-builder';
import { FormFieldValueWithWidget, MultiSelectFormFieldValue } from '@process-street/subgrade/process';
import { isFunction } from 'lodash';
import { useSubtasksOnBlur, UseSubtasksOnBlurParams } from '../shared/hooks/use-subtasks-on-blur';
import { TaskNameInput } from '../task-name-input';
import DescriptionEditor from '../description-editor/description-editor';
import { useFeatureFlag } from 'features/feature-flags';
import { useTaskEditorFields } from 'features/one-off-tasks/hooks/use-task-editor-fields';
import { CreateOneOffTaskSettingsSchema } from '../create/schema';
import { OneOffTaskUtils } from 'features/one-off-tasks/shared';

const FORM_ID = 'edit-one-off-task';

export type EditDrawerContentProps = {
  task: OneOffTask;
};

export const EditDrawerContent: React.FC<React.PropsWithChildren<EditDrawerContentProps & BoxProps>> = ({
  task,
  ...props
}) => {
  const currentUser = useCurrentUser();
  const toast = useNextThemeToast();
  const queryClient = useQueryClient();
  const { onClose, setHasUnsavedChanges } = useOneOffTaskDrawerStore();
  const { canUploadAttachments, onAddAttachment, checklistRevision } = useOneOffTaskAttachments(task);
  const [selectedWorkflowId, setSelectedWorkflowId] = React.useState<Option<Muid>>(task.linkedChecklist?.templateId);
  const {
    assignedMemberships,
    availableMemberships,
    handleMembershipAssignment,
    handleMembershipRemoval,
    handleDueDateUpdate,
  } = useOneOffTaskTopbar(task);
  const isTasksGAEnabled = useFeatureFlag('tasksGA');
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');

  const { invalidate: invalidateInboxData } = useInboxItemsDataSource();

  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: task.internalChecklistId,
  });
  const subtasksWidgetQuery = useGetWidgetsByChecklistRevisionIdQuery(
    {
      checklistRevisionId: checklistRevisionQuery.data?.id,
    },
    {
      select: widgets => widgets.find(OneOffTaskUtils.isSubtasksFormFieldWidget),
    },
  );

  const subtasksQuery = useGetFormFieldValuesByChecklistRevisionIdQuery(
    { checklistRevisionId: checklistRevisionQuery.data?.id },
    {
      select: ffvs =>
        ffvs.find(ffv => OneOffTaskUtils.isSubtasksFormFieldWidget(ffv.formFieldWidget)) as MultiSelectFormFieldValue,
    },
  );

  const updateOneOffTaskMutation = UpdateOneOffTaskMutation.useMutation({
    onSuccess: () => {
      // Update is fired only if there are changes, so we know we need to invalidate.
      if (values.linkedChecklistId) {
        queryClient.invalidateQueries(GetOneOffTasksByChecklistQuery.getKey({ checklistId: values.linkedChecklistId }));
      }
      // If the task changed the linked checklist, then invalidate the old one
      if (task.linkedChecklist && task.linkedChecklist.id !== values.linkedChecklistId) {
        queryClient.invalidateQueries(GetOneOffTasksByChecklistQuery.getKey({ checklistId: task.linkedChecklist.id }));
      }

      queryClient.invalidateQueries(GetOneOffTaskQuery.getKey({ id: task.id }));

      toast({
        status: 'success',
        title: 'Task updated successfully',
      });
      invalidateInboxData();

      setHasUnsavedChanges(false);
    },
    onError: _ => {
      toast({
        status: 'error',
        title: "We're having problems creating the Task.",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const updateSubtasksMutation = UpdateSubtasksMutation.useMutation({
    onMutate: variables => {
      const queryKey = GetFormFieldValuesByChecklistRevisionIdQuery.getKey({
        checklistRevisionId: checklistRevisionQuery.data?.id,
      });
      const cachedData = queryClient.getQueryData<FormFieldValueWithWidget[]>(queryKey);

      queryClient.setQueryData<FormFieldValueWithWidget[]>(queryKey, (formFieldValues = []) => {
        return (formFieldValues ?? []).map(ffv => {
          if (ffv.id === subtasksQuery.data?.id) {
            return {
              ...ffv,
              fieldValue: {
                itemValues: variables.subtasks,
              },
            };
          }

          return ffv;
        });
      });

      return () => {
        queryClient.setQueryData(queryKey, cachedData);
      };
    },
    onSuccess: async () => {
      const queryKey = GetFormFieldValuesByChecklistRevisionIdQuery.getKey({
        checklistRevisionId: checklistRevisionQuery.data?.id,
      });

      await queryClient.invalidateQueries(queryKey);
    },
    onError: (_, __, context) => {
      if (isFunction(context)) {
        // revert cache on error
        context();
      }
    },
  });

  const handleOnSubmit = (values: EditOneOffTaskSettingsSchema) => {
    updateOneOffTaskMutation.mutate({
      id: task.id,
      name: values.name,
      description: values.description,
      required: values.required,
      visibility: OneOffTaskVisibility.Public,
      linkedChecklistId: values.wfrLinkEnabled ? values.linkedChecklistId : undefined,
    });

    if (!checklistRevisionQuery.data) return;

    updateSubtasksMutation.mutate({
      id: task.id,
      subtasks: values.subtasks ?? [],
      checklistRevisionId: checklistRevisionQuery.data.id,
      subtasksFormFieldWidgetId: subtasksWidgetQuery.data?.id,
    });
  };

  const {
    handleSubmit,
    handleReset,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    isSubmitting,
    errors,
    touched,
    setTouched,
    isValid,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: task.name,
      description: task.description,
      wfrLinkEnabled: Boolean(task.linkedChecklist),
      linkedChecklistId: task.linkedChecklist?.id,
      required: task.required,
      subtasks: subtasksQuery.data?.fieldValue.itemValues ?? [],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: editOneOffTaskSettingsSchema,
    onSubmit: handleOnSubmit,
  });

  const handleSubtasksBlur = useSubtasksOnBlur({
    touched: touched as UseSubtasksOnBlurParams['touched'],
    setTouched: setTouched as UseSubtasksOnBlurParams['setTouched'],
    subtasks: values.subtasks ?? [],
  });
  const { handleDescriptionChange, handleNameChange, editorContent, setFieldTouched } = useTaskEditorFields(
    setTouched as (touched: FormikTouched<CreateOneOffTaskSettingsSchema>) => void,
    setFieldValue,
    touched as FormikTouched<CreateOneOffTaskSettingsSchema>,
    values as CreateOneOffTaskSettingsSchema,
  );

  React.useEffect(() => {
    setHasUnsavedChanges(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore prop setHasUnsavedChanges
  }, [dirty]);

  useBeforeUnload(dirty, 'You have unsaved changes. Are you sure you want to leave?');

  return (
    <Box {...props}>
      <DrawerCloseButton onClick={() => onClose()}>
        <Icon icon="times" size="4" color="gray.400" />
      </DrawerCloseButton>

      <DrawerHeader px="8" py="5">
        <Text variant="-1u" textTransform="uppercase" fontWeight="bold" color="gray.500">
          {values.name}
        </Text>
      </DrawerHeader>

      <Divider />

      <form id={FORM_ID} onSubmit={handleSubmit} onReset={handleReset}>
        <DrawerBody flex="unset" pt="5" px="8" overflow="unset">
          <HStack spacing="4" alignItems="flex-start" pt="0" pb="3" flexWrap="wrap">
            <AssignmentPicker
              onSelect={handleMembershipAssignment}
              onRemove={handleMembershipRemoval}
              assignedMemberships={assignedMemberships}
              availableMemberships={availableMemberships}
            />
            <DueDatePicker
              timeZone={currentUser?.timeZone}
              value={task.dueDate}
              onChange={handleDueDateUpdate}
              buttonProps={{ fontWeight: isMyWorkGAEnabled ? 'normal' : 'inherit' }}
            />
            <TopbarMenu task={task} />
          </HStack>
          <VStack pt="3" spacing="6" alignItems="start">
            <FormControl isInvalid={Boolean(errors.name)}>
              <FormLabel>
                <Text>Name</Text>
              </FormLabel>
              <TaskNameInput
                initialValue={values.name}
                onChange={handleNameChange}
                onBlur={handleBlur}
                onFocus={setFieldTouched}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.description) && touched.description}>
              <FormLabel>
                <Text>Description</Text>
              </FormLabel>
              {isTasksGAEnabled ? (
                <DescriptionEditor value={editorContent} onChange={handleDescriptionChange} />
              ) : (
                <Textarea
                  bgColor="white"
                  name="description"
                  placeholder="What is this task about?"
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              )}
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl>

            {!subtasksQuery.isLoading && isTasksGAEnabled && (
              <FormControl>
                <OneOffTaskFormFields.Subtasks
                  value={values.subtasks ?? []}
                  onChange={subtasks => {
                    setFieldValue('subtasks', subtasks);
                  }}
                  onBlur={index => handleSubtasksBlur(index)}
                />
              </FormControl>
            )}

            <AttachmentsList task={task} />
            {canUploadAttachments && <AddAttachmentButton task={task} onAddAttachment={onAddAttachment} />}
            <Divider />
            <WorkflowRunLink
              wfrLinkEnabled={values.wfrLinkEnabled}
              isRequired={values.required}
              onWfrLinkEnabledChange={enabled => {
                setFieldValue('wfrLinkEnabled', enabled);
              }}
              selectedWorkflowId={selectedWorkflowId}
              selectedWorkflowRunId={values.linkedChecklistId}
              onWorkflowIdChange={setSelectedWorkflowId}
              onWorkflowRunIdChange={checklistId => setFieldValue('linkedChecklistId', checklistId)}
              onRequiredChange={required => {
                setFieldValue('required', required);
              }}
            />
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <VStack w="full" spacing="6">
            <Stack
              direction={{ base: 'column', md: 'row' }}
              w="full"
              justifyContent="flex-end"
              alignItems={{ base: 'inherit', md: 'center' }}
              spacing="2"
            >
              <Button variant="ghost" fontWeight="normal" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                form={FORM_ID}
                type="submit"
                isLoading={isSubmitting}
                isDisabled={!isValid || !dirty}
              >
                Update
              </Button>
            </Stack>
            {checklistRevision && (
              <TaskComments
                checklistRevision={checklistRevision}
                taskId={task.id}
                activeStepId={task.taskTemplateGroupId}
                fontSize="md"
              />
            )}
          </VStack>
        </DrawerFooter>
      </form>
    </Box>
  );
};

import React from 'react';
import { match, P } from 'ts-pattern';

import { Template } from '@process-street/subgrade/process';
import { Box } from 'components/design/next';
import { useCoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { CDNImage } from 'components/cdn-image';

type CoverImageProps = { templateId: Template['id'] };

export const InboxCoverImage: React.FC<React.PropsWithChildren<CoverImageProps>> = ({ templateId }) => {
  const coverImageQuery = useCoverImageByTemplateIdQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
      select: data => data?.s3File,
    },
  );

  return match(coverImageQuery)
    .with({ status: 'success', data: P.not(P.nullish) }, ({ data }) => (
      <Box
        data-testid="cover-image-container"
        height={{ base: 'auto', lg: '150px' }}
        width={{ base: 'full', lg: 'auto' }}
        mb="5"
        position="relative"
      >
        <CDNImage
          alt={data.originalName}
          s3File={data}
          transformation={{
            height: 150,
            width: 758,
          }}
          height="150px"
        />
      </Box>
    ))
    .otherwise(() => null);
};

import * as React from 'react';
import { FileWidget, ImageWidget, VideoWidget, WidgetConstants } from '@process-street/subgrade/process';
import { Text, Box, useToast, Tooltip, BoxProps } from 'components/design/next';
import { SIZE_TOO_LARGE, useUploadQuery, useVideoUploadQuery } from './query';
import last from 'lodash/last';
import { UploadArea, UploadProgress } from 'features/upload/components';
import { DefaultErrorMessages } from 'components/utils/error-messages';
interface UploadElementProps {
  widget: FileWidget | ImageWidget;
  uploadingMessage: string;
  onFinish: (widget: FileWidget | ImageWidget) => void;
  acceptMimeTypes?: string;
}

interface UploadVideoElementProps {
  widget: VideoWidget;
  uploadingMessage: string;
  onFinish: (widget: VideoWidget) => void;
  onUploadStarted: () => void;
  acceptMimeTypes?: string;
  dropZoneProps?: BoxProps;
  onDrop?: () => void;
}

export const UploadElement: React.FC<React.PropsWithChildren<UploadElementProps>> = ({
  widget,
  uploadingMessage,
  onFinish,
  acceptMimeTypes,
  children,
}) => {
  const toast = useToast();

  const { dropzoneState, progress, uploadError, clearUploadError } = useUploadQuery(widget, onFinish, acceptMimeTypes);

  const getHighlightColor = () => {
    if (dropzoneState.isDragAccept) {
      return 'brand.100';
    }
    if (dropzoneState.isDragReject || dropzoneState.fileRejections.length > 0) {
      return 'red.100';
    }
    if (dropzoneState.isDragActive) {
      return 'brand.100';
    }
  };

  if (uploadError) {
    toast({
      status: 'error',
      title: "We're having problems uploading the video",
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
    clearUploadError();
  }

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <div {...dropzoneState.getRootProps({ className: 'dropzone' })}>
      <Box bg="gray.100" p={4} borderRadius="md" cursor="pointer" bgColor={getHighlightColor()}>
        {progress === undefined ? (
          <UploadArea dropzoneInputProps={dropzoneState.getInputProps()} children={children} />
        ) : (
          <UploadProgress progress={progress} message={uploadingMessage} />
        )}
      </Box>
      {fileTooLarge ? <Text color="red.500">{last(dropzoneState.fileRejections[0].errors)?.message}</Text> : undefined}
    </div>
  );
};

export const UploadVideoElement: React.FC<React.PropsWithChildren<UploadVideoElementProps>> = ({
  widget,
  uploadingMessage,
  onFinish,
  onUploadStarted,
  acceptMimeTypes,
  children,
  dropZoneProps,
  onDrop,
  ...props
}) => {
  const toast = useToast();

  const { dropzoneState, progress, uploadError, clearUploadError } = useVideoUploadQuery({
    widget,
    onFinish,
    onUploadStarted,
    accept: acceptMimeTypes,
    onDropFile: onDrop,
  });

  const getHighlightColor = () => {
    if (dropzoneState.isDragAccept) {
      return 'brand.100';
    }
    if (dropzoneState.isDragReject || dropzoneState.fileRejections.length > 0) {
      return 'red.100';
    }
    if (dropzoneState.isDragActive) {
      return 'brand.100';
    }
    return 'brand.100';
  };

  if (uploadError) {
    toast({
      status: 'error',
      title: "We're having problems uploading the video",
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
    clearUploadError();
  }

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  return (
    <Box {...dropzoneState.getRootProps({ className: 'dropzone' })} {...dropZoneProps}>
      <Tooltip
        aria-label="max upload size"
        label={`Up to ${WidgetConstants.VIDEO_MAX_FILE_SIZE / 1024 / 1024 / 1024} GB`}
        placement="bottom"
        hasArrow
      >
        <Box bg="gray.100" p={4} borderRadius="md" cursor="pointer" bgColor={getHighlightColor()}>
          {progress === undefined ? (
            <UploadArea dropzoneInputProps={dropzoneState.getInputProps()} boxProps={props}>
              {children}
            </UploadArea>
          ) : (
            <UploadProgress progress={progress} message={uploadingMessage} />
          )}
        </Box>
      </Tooltip>
      {fileTooLarge ? <Text color="red.500">{last(dropzoneState.fileRejections[0].errors)?.message}</Text> : undefined}
    </Box>
  );
};

import * as React from 'react';
import { Icon, Input, InputGroup, InputLeftElement, VStack } from 'components/design/next';
import { EmailFormFieldActor } from './email-form-field-machine';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { useActor } from '@xstate/react';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { SettingsModalHeader } from 'pages/forms/_id/edit/components/form-fields/common/settings/settings-modal-content';
import { SettingsModalFields } from 'pages/forms/_id/edit/components/form-fields/common/settings/fields';
import { emailSettingsSchema } from 'pages/forms/_id/edit/components/form-fields/email-form-field/email-form-field-schema';
import { StringUtils } from '@process-street/subgrade/util';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { match, P } from 'ts-pattern';
import { TemplateType } from '@process-street/subgrade/process';

export interface EmailFormFieldWidgetProps {
  actor: EmailFormFieldActor;
  isFirst?: boolean;
  isLast?: boolean;
}

export const EmailFormFieldWidget: React.FC<React.PropsWithChildren<EmailFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom, template } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const placeholder = match(widget)
    .with({ config: { defaultValue: P.when(Boolean) } }, ({ config: { defaultValue } }) => defaultValue)
    .with({ config: { placeholder: P.when(Boolean) } }, ({ config: { placeholder } }) => placeholder)
    .otherwise(() => 'email@address.com');

  const isWorkflow = template.templateType === TemplateType.Playbook;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack maxW="88" alignItems="flex-start" flex="1">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel actor={labelActor} />}

          <InputGroup
            ref={node => {
              ref.current = node;
              if (node && !state.context.inputNode) {
                send({ type: 'SET_NODE', node });
              }
            }}
          >
            <InputLeftElement px="3">
              {/* mt because it looks slightly off center to input */}
              <Icon mt="px" icon="envelope" size="4" color="gray.400" />
            </InputLeftElement>
            <Input
              id={`form-field-widget-${widget.id}`}
              w="full"
              placeholder={placeholder}
              isDisabled
              borderColor="gray.200"
              _disabled={{ bgColor: 'gray.50', opacity: 1 }}
            />

            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Required widget={widget} />
              <FormsWidgetMenuItems.Settings widget={widget} schema={emailSettingsSchema}>
                <SettingsModalHeader>
                  {StringUtils.getNonEmpty(widget.label, 'Untitled email field')}
                </SettingsModalHeader>
                <SettingsModalFields.EmailDomains />
                <SettingsModalFields.Placeholder label="Email placeholder" />
                {isWorkflow && <SettingsModalFields.DefaultValue templateRevisionId={widget.templateRevision.id} />}
              </FormsWidgetMenuItems.Settings>
              <FormsWidgetMenuItems.ConditionalLogic widget={widget} />

              <FormsWidgetMenuItems.Divider />

              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </InputGroup>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

import * as React from 'react';
import { TemplateType } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useTemplateMenuContext } from '..';
import { ButtonProps } from 'components/design/next';

export interface TemplateActivityButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const TemplateActivityButton: React.FC<TemplateActivityButtonProps> = React.memo(({ children }) => {
  const { $state } = useInjector('$state');
  const { templateId } = useTemplateMenuContext();
  const { data: template } = useGetTemplateQuery({ templateId });
  const templateType = template?.templateType ?? TemplateType.Page;

  const manageState = templateType === TemplateType.Page ? 'pageManage' : 'workflowManage';

  const redirectToActivity = React.useCallback(() => {
    $state.go(`${manageState}.tab`, { id: templateId, tab: 'activity' });
  }, [$state, manageState, templateId]);

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('TemplateActivityButton child must be a single clickable element');
  }

  const btn = React.cloneElement(children, {
    onClick: redirectToActivity,
  });

  return btn;
});

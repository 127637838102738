import { CoverIcon } from '@process-street/subgrade/core';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient, QueryKey, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';
import { GetAllCoverIconResponse, GetAllCoverIconsQuery } from 'features/cover-icon/query-builder/all-cover-icons';

export type CoverIconByTemplateIdQueryParams = { templateId: Template['id'] };

export type CoverIconByTemplateIdQueryResponse = CoverIcon | undefined;

export const CoverIconByTemplateIdQuery = {
  key: ['template', 'icon'],
  getKey: (params: CoverIconByTemplateIdQueryParams): QueryKey => [
    ...CoverIconByTemplateIdQuery.key,
    params.templateId,
  ],
  queryFn: (params: CoverIconByTemplateIdQueryParams) =>
    axiosService
      .getAxios()
      .get<CoverIconByTemplateIdQueryResponse>(`/1/templates/${params.templateId}/cover-icon`, {
        validateStatus: status => {
          return status === HttpStatus.OK || status === HttpStatus.NOT_FOUND;
        },
      })
      .then(res => {
        if (res.status === HttpStatus.NOT_FOUND) {
          return undefined;
        } else {
          return res.data;
        }
      }),
};

export const useCoverIconByTemplateIdQuery = <Select = CoverIconByTemplateIdQueryResponse>(
  params: CoverIconByTemplateIdQueryParams,
  options: UseQueryOptions<CoverIconByTemplateIdQueryResponse, AxiosError, Select> = {},
) => {
  const queryClient = useQueryClient();

  return useQuery(CoverIconByTemplateIdQuery.getKey(params), () => CoverIconByTemplateIdQuery.queryFn(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: () => {
      return queryClient
        .getQueryData<GetAllCoverIconResponse>(GetAllCoverIconsQuery.getKey())
        ?.find(ci => ci.templateId === params.templateId);
    },
    initialDataUpdatedAt: () => {
      return queryClient.getQueryState(GetAllCoverIconsQuery.getKey())?.dataUpdatedAt;
    },
    ...options,
  });
};

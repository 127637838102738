import { Box } from 'components/design/next';
import { FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import * as React from 'react';
import { ChecklistRuleDefinition, ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { useRuleOperations, UseRuleOperationsReturn } from './rule-operations';
import { FormFieldOrTaskOption } from '../form-fields-select';
import { RuleDefinition } from 'features/conditional-logic/components/rule-definition';
import { useConditionalLogicModalStore } from '../modal/store';

export type RuleListProps = {
  arrayHelpers: FieldArrayRenderProps;
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
  formFieldSelectOptions: FormFieldOrTaskOption[];
  formFieldSelectOptionsSelectedTask: FormFieldOrTaskOption[];
  selectedTaskWidgetGroupIds: Set<string>;
  initialRuleIds: Set<string>;
  showErrors: boolean;
};

export const RuleList: React.FC<React.PropsWithChildren<RuleListProps>> = ({
  arrayHelpers,
  widgets,
  taskTemplates,
  formFieldSelectOptions,
  formFieldSelectOptionsSelectedTask,
  selectedTaskWidgetGroupIds,
  initialRuleIds,
  showErrors,
}) => {
  const [{ value: rules }] = useField<ChecklistRuleDefinition[]>('rules');
  const ruleOperations = useRuleOperations(arrayHelpers);

  return (
    <>
      {rules.map((rule, index) => (
        <Row
          key={rule.id}
          rule={rule}
          index={index}
          widgets={widgets}
          taskTemplates={taskTemplates}
          formFieldSelectOptions={formFieldSelectOptions}
          formFieldSelectOptionsSelectedTask={formFieldSelectOptionsSelectedTask}
          selectedTaskWidgetGroupIds={selectedTaskWidgetGroupIds}
          initialRuleIds={initialRuleIds}
          showErrors={showErrors}
          {...ruleOperations}
        />
      ))}
    </>
  );
};

type RowProps = UseRuleOperationsReturn &
  Omit<RuleListProps, 'arrayHelpers'> & {
    rule: ChecklistRuleDefinition;
    index: number;
  };

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({
  index,
  initialRuleIds,
  rule,
  widgets,
  taskTemplates,
  formFieldSelectOptions,
  formFieldSelectOptionsSelectedTask,
  selectedTaskWidgetGroupIds,
  handleChange,
  handleDelete,
  handleDuplicate,
  handleAddRuleBelow,
  handleMoveUp,
  handleMoveDown,
  showErrors,
}) => {
  const { selectedWidget, selectedTask, selectedRule, dispatch } = useConditionalLogicModalStore();
  const { values } = useFormikContext<{
    rules: ChecklistRuleDefinition[];
  }>();

  const participatingFormFieldWidgetGroupIds = Array.from(
    ConditionalLogicCommonUtils.getFormFieldWidgetGroupIdsFromRule(rule),
  );

  // https://processstreet.atlassian.net/browse/PS-11701
  const canMoveRule = !selectedTask;

  const shouldShowRuleWhenWidgetSelected =
    selectedWidget && participatingFormFieldWidgetGroupIds.includes(selectedWidget.header.group.id);
  const isRuleHavingParticipatingTaskWidget = participatingFormFieldWidgetGroupIds.some(groupId =>
    selectedTaskWidgetGroupIds.has(groupId),
  );
  const isTaskParticipatingInRule = selectedTask && rule.taskTemplateGroupIds.includes(selectedTask.group.id);
  const shouldShowRuleWhenTaskSelected =
    selectedTask && (isRuleHavingParticipatingTaskWidget || isTaskParticipatingInRule);
  const isNewRule = ConditionalLogicCommonUtils.getConditionFormFieldWidgetGroupIdsFromRule(rule).size === 0;

  if (
    !(isNewRule || shouldShowRuleWhenWidgetSelected || shouldShowRuleWhenTaskSelected) ||
    // New rules (aka, rules with no form field selected) must not be visible when a widget is selected
    (isNewRule && selectedWidget)
  ) {
    return null;
  }

  const handleOnMoveUp = values.rules[index - 1] && canMoveRule ? () => handleMoveUp(index) : undefined;
  const handleOnMoveDown = values.rules[index + 1] && canMoveRule ? () => handleMoveDown(index) : undefined;

  return (
    <Box w="full" key={rule.id}>
      <RuleDefinition
        widgets={widgets}
        taskTemplates={taskTemplates}
        formFieldSelectOptions={formFieldSelectOptions}
        formFieldSelectOptionsSelectedTask={formFieldSelectOptionsSelectedTask}
        rule={rule}
        onChange={rule => handleChange(index, rule as ChecklistRuleDefinition)}
        onDelete={() => handleDelete(index)}
        onDuplicate={() => handleDuplicate(index)}
        onAddRuleBelow={() => handleAddRuleBelow(index)}
        onMoveUp={handleOnMoveUp}
        onMoveDown={handleOnMoveDown}
        showErrors={showErrors}
        isNew={!initialRuleIds.has(rule.id)}
        isSelected={selectedRule?.id === rule.id}
        hasRuleSelected={Boolean(selectedRule)}
        onSelect={() => dispatch({ type: 'SET_SELECTED_RULE', payload: rule })}
        selectedWidgetGroupId={selectedWidget?.header.group.id}
        selectedTaskGroupId={selectedTask?.group.id}
      />
    </Box>
  );
};

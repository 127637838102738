import * as React from 'react';
import { Icon, IconButton, PopoverTrigger } from 'components/design/next';
import { useFeatureFlag } from 'app/features/feature-flags';

export type SingleTriggerButtonProps = {
  permitsAreDefault: boolean;
  isDisabled: boolean;
};

export const SingleTriggerButton = ({ isDisabled, permitsAreDefault }: SingleTriggerButtonProps) => {
  const isReactWFEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  return (
    <PopoverTrigger>
      <IconButton
        display="flex"
        color="gray.500"
        justifyContent="center"
        variant={isDisabled ? 'ghost' : 'outline'}
        size="sm"
        borderWidth="px"
        borderColor="gray.300"
        px="6px"
        icon={
          <Icon
            icon={isReactWFEditorEnabled ? 'lock-keyhole' : 'lock'}
            variant="far"
            size="4"
            color={permitsAreDefault ? 'gray.400' : 'purple.500'}
          />
        }
        aria-label="Task permissions"
      ></IconButton>
    </PopoverTrigger>
  );
};

import * as React from 'react';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { ConditionalLogicModal } from 'features/conditional-logic/components/modal';
import { useDisclosure } from 'components/design/next';
import { CONDITIONAL_LOGIC_MODAL_EVENT } from 'features/conditional-logic/components/modal/conditional-logic-modal-event';

export interface ConditionalLogicModalProps {
  templateRevision: TemplateRevision;
}

export const ConditionalLogicModalWrapper: React.VFC<ConditionalLogicModalProps> = ({ templateRevision }) => {
  const { $rootScope } = useInjector('$rootScope');

  const disclosure = useDisclosure();

  React.useEffect(() => {
    const unsubs = [
      $rootScope.$on(CONDITIONAL_LOGIC_MODAL_EVENT.OPEN, () => {
        disclosure.onOpen();
      }),
      $rootScope.$on(CONDITIONAL_LOGIC_MODAL_EVENT.TOGGLE, () => {
        disclosure.onToggle();
      }),
      $rootScope.$on(CONDITIONAL_LOGIC_MODAL_EVENT.CLOSE, () => {
        disclosure.onClose();
      }),
    ];

    return () => {
      unsubs.forEach(unsub => unsub());
    };
  }, [$rootScope, disclosure]);

  return (
    <ConditionalLogicModal
      template={templateRevision.template as Template}
      templateRevisionId={templateRevision.id}
      {...disclosure}
    />
  );
};

import { MenuDivider } from 'components/design/next';
import { DeleteMenuItem } from './delete-menu-item';
import { DuplicateMenuItem } from './duplicate-menu-item';
import { MoveDownMenuItem } from './move-down-menu-item';
import { MoveUpMenuItem } from './move-up-menu-item';
import { RequiredMenuItem } from './required-menu-item';
import { SettingsMenuItem } from 'pages/forms/_id/edit/components/forms-widget-menu/settings-menu-item';
import { ConditionalLogicMenuItem } from './conditional-logic-menu-item';
import { MoveToStepMenuItem } from './move-to-step/move-to-step-menu-item';
import { ConnectDataSetMenuItem } from './connect-data-set-menu-item';

export * from './forms-widget-menu';

export const FormsWidgetMenuItems = {
  Delete: DeleteMenuItem,
  Duplicate: DuplicateMenuItem,
  MoveUp: MoveUpMenuItem,
  MoveDown: MoveDownMenuItem,
  MoveToStep: MoveToStepMenuItem,
  Required: RequiredMenuItem,
  Settings: SettingsMenuItem,
  Divider: MenuDivider,
  ConditionalLogic: ConditionalLogicMenuItem,
  ConnectDataSet: ConnectDataSetMenuItem,
};

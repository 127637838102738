import * as React from 'react';
import { HStack, Icon, SimpleGrid, Text, Tooltip, VStack } from 'components/design/next';
import { useSolutionTypeTagInstances } from 'pages/templates/_id/automation/components/selector/use-solution-type-tag-instances';
import { Template } from '@process-street/subgrade/process';
import {
  AutomationInstanceUtils,
  getTrayAutomationAppDetail,
  isIncomingWebhookInstance,
  isTemplateSolutionInstance,
  SolutionTypeTag,
  TemplateSolutionInstance,
  withTrayPrefix,
} from '@process-street/subgrade/automation';
import { match } from 'ts-pattern';
import { DashboardAutomationInstanceBadge } from 'pages/templates/_id/dashboard/components/sidebar/dashboard-automations/dashboard-automation-instance-badge';
import { DelayedSpinner } from 'components/design/next/delayed-spinner';
import { Option } from 'space-monad';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { useGetFinishedTmplRevisionByTmplIdQuery } from 'features/template-revisions/query-builder/get-finished-template-revision-by-template-id';
import { TaskSolutionInstanceLabel } from 'features/automations/components/task-automation-indicator/task-solution-instance-label';

export interface DashboardAutomationsProps {
  templateId?: Template['id'];
}

export const DashboardAutomations: React.FC<React.PropsWithChildren<DashboardAutomationsProps>> = ({ templateId }) => {
  const { isLoading: isLoadingAutomations, instances: allAutomations } = useSolutionTypeTagInstances(
    { templateId },
    { enabled: Boolean(templateId) },
  );

  const displayedAutomations = Object.values(allAutomations)
    .flat()
    .filter(automation => {
      return (
        (isTemplateSolutionInstance(automation) && automation.configured && automation.enabled) ||
        (isIncomingWebhookInstance(automation) && automation.status === 'Active')
      );
    });
  const isEmpty = displayedAutomations.length === 0;

  const hasTaskTemplateAutomations = displayedAutomations.some(
    a => isTemplateSolutionInstance(a) && Boolean(a.taskTemplateGroupId),
  );
  const finishedTemplateRevisionQuery = useGetFinishedTmplRevisionByTmplIdQuery(
    { templateId: templateId! },
    { enabled: Boolean(templateId) && hasTaskTemplateAutomations },
  );
  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId: finishedTemplateRevisionQuery.data?.id },
    { enabled: finishedTemplateRevisionQuery.isSuccess && hasTaskTemplateAutomations },
  );

  const isLoading = isLoadingAutomations || finishedTemplateRevisionQuery.isLoading || taskTemplatesQuery.isLoading;

  return (
    <VStack spacing={3} alignItems="flex-start">
      <HStack spacing={2}>
        <Icon icon="bolt" variant="far" size="3" />
        <Text fontWeight="bold" fontSize="sm" color="gray.600">
          Automations
        </Text>
      </HStack>
      <DelayedSpinner isLoaded={Boolean(templateId) && !isLoading} isCentered={true}>
        {isEmpty && (
          <Text color="gray.500" fontSize="sm">
            There aren’t any automations for this workflow.
          </Text>
        )}
        {!isEmpty && (
          <SimpleGrid columns={5} spacing={3}>
            {displayedAutomations.map(automationInstance => {
              const tooltipLabel = match(automationInstance)
                .when(
                  i => isTemplateSolutionInstance(i) && i.solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen,
                  (solutionInstance: TemplateSolutionInstance) => (
                    <TaskSolutionInstanceLabel
                      templateSolutionInstance={solutionInstance}
                      taskTemplate={taskTemplatesQuery.data?.find(
                        tt => tt.group.id === solutionInstance.taskTemplateGroupId,
                      )}
                    />
                  ),
                )
                .when(
                  i =>
                    isTemplateSolutionInstance(i) && i.solutionTypeTag === SolutionTypeTag.WhenChecklistCompletedThen,
                  (solutionInstance: TemplateSolutionInstance) => {
                    const prefix = 'When a workflow run is completed then';
                    const { label } = getTrayAutomationAppDetail(withTrayPrefix(solutionInstance.automationApp));
                    const { description } = solutionInstance;

                    const copyParts = description
                      ? [prefix, description, withParentheses(label)]
                      : [prefix, 'trigger', label];

                    return copyParts.join(' ');
                  },
                )
                .when(
                  i => isTemplateSolutionInstance(i),
                  (solutionInstance: TemplateSolutionInstance) => {
                    const { label } = getTrayAutomationAppDetail(withTrayPrefix(solutionInstance.automationApp));
                    const { description } = solutionInstance;
                    return [label, description].filter(Boolean).join(': ');
                  },
                )
                .when(isIncomingWebhookInstance, solutionInstance => {
                  const webhookName = withParentheses(solutionInstance.name || solutionInstance.automationApp);
                  return ['Run the workflow when the webhook', webhookName, 'is triggered'].join(' ');
                })
                .otherwise(() => 'Automation');

              return (
                <Tooltip
                  key={AutomationInstanceUtils.getInstanceId(automationInstance)}
                  label={tooltipLabel}
                  hasArrow
                  shouldWrapChildren
                >
                  <DashboardAutomationInstanceBadge automationInstance={automationInstance} />
                </Tooltip>
              );
            })}
          </SimpleGrid>
        )}
      </DelayedSpinner>
    </VStack>
  );
};

function withParentheses(item?: string) {
  return Option(item)
    .map(i => `(${i})`)
    .getOrElse('');
}

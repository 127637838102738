import React from 'react';
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from 'components/design/next';
import { UploadTemplateCoverIcon } from 'features/cover-icon/components/common/upload';
import { EmojiPicker } from 'features/cover-icon/components/common/emoji-picker';
import { Template } from '@process-street/subgrade/process';
import { useQueryClient } from 'react-query';
import { CoverIconByTemplateIdQuery } from 'features/cover-icon/query-builder';
import { PopoverTrigger } from 'components/design/next';

type CoverIconUploadPopoverProps = {
  templateId: Template['id'];
  onFinish?: () => void;
} & PopoverProps;

export const CoverIconUploadPopover: React.FC<React.PropsWithChildren<CoverIconUploadPopoverProps>> = ({
  templateId,
  onFinish,
  children,
  ...props
}) => {
  const queryClient = useQueryClient();

  const onEmojiSelectSuccess = (templateId: Template['id']) => {
    queryClient.invalidateQueries(CoverIconByTemplateIdQuery.getKey({ templateId }));
    onFinish && onFinish();
  };

  return (
    <Popover isLazy placement="bottom-start" {...props}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent minW={{ sm: 'full', md: 'md', lg: 'sm', xl: 'md' }} zIndex="popover">
          <PopoverBody>
            <Tabs variant="default" isLazy>
              <TabList>
                <Tab aria-label="upload image">Upload Image</Tab>
                <Tab aria-label="add emoji">Emoji</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <UploadTemplateCoverIcon
                    templateId={templateId}
                    uploadingMessage="Uploading icon..."
                    acceptMimeTypes="image/*"
                    onFinish={onEmojiSelectSuccess}
                  >
                    <VStack align="center" spacing={3}>
                      <Button size="sm">Choose image</Button>
                      <Text align="center" variant="-2" color="gray.500" fontWeight="normal">
                        Recommended size 75x75px
                      </Text>
                    </VStack>
                  </UploadTemplateCoverIcon>
                </TabPanel>
                <TabPanel p="0">
                  <EmojiPicker {...{ templateId, onFinish: onEmojiSelectSuccess }} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

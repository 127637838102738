import * as React from 'react';
import { InboxItem, InboxItemType, InboxItemUtils } from '@process-street/subgrade/inbox';
import { match } from 'ts-pattern';
import { Box, Button, HStack, Icon, Skeleton, Text, TextProps, Tooltip } from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { oneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { GridApi } from '@ag-grid-community/core';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { SnoozeButton } from '../snooze-button';
import { useIsTextTruncated } from 'app/hooks/use-is-text-truncated';
import { isNotIdRef } from '@process-street/subgrade/core';
import { GetInboxItemsQuery } from 'app/features/microsoft-teams/query-builder';
import { useIsCellHovered } from '../../use-inbox-items-grid-context-store';
import { MyWorkGridContext } from './use-grid-context';
import { InboxItemDetailsEvents } from 'app/directives/inbox/item/details/inbox-item-details.events';

export type NameRendererProps = {
  data: TasksTableUtils.TasksTableItem;
  api?: GridApi;
  context: MyWorkGridContext;
};

export const NameRenderer = React.memo(function ({ data, context }: NameRendererProps) {
  const isHovered = useIsCellHovered(data, GetInboxItemsQuery.SortBy.TaskTemplateName);

  const textRef = React.useRef<HTMLParagraphElement>(null);
  const { isTextTruncated } = useIsTextTruncated({ textRef });

  if (!TasksTableUtils.isInboxItemRow(data))
    return (
      <Box p={2}>
        <Skeleton h={4} w="200px" />
      </Box>
    );

  const { name, icon, textProps, isRequired } = getInboxItemDisplayProps(data);
  const isOneOffTask = data.itemType === InboxItemType.OneOffTask;
  const isCompleted = TasksTableUtils.isInboxItemCompleted(data);

  const handleClick = async () => {
    if (data.itemType === InboxItemType.OneOffTask) {
      context.$rootScope.$broadcast(InboxItemDetailsEvents.INBOX_ITEM_DETAILS_CLOSED);

      // When the drawer is open, we will close and then open the new item
      // so it matches the behavior of the angular drawer.
      if (oneOffTaskDrawerStore.getState().isOpen) {
        context.closeTaskDrawer();
        context.focusManagerActions.setItem({ item: data });

        setTimeout(() => {
          context.viewTaskById({ taskId: data.task.id });
        }, 300);
      } else {
        context.viewTaskById({ taskId: data.task.id });
        context.focusManagerActions.setItem({ item: data });
      }
    } else {
      context.closeTaskDrawer();
      await context.openDrawer(data);
    }
  };

  const content = (
    <HStack spacing={isRequired ? '1' : '0'} noOfLines={1}>
      <Text ref={textRef} fontWeight={500} {...textProps} noOfLines={1}>
        {name}
      </Text>
      {isRequired && (
        <Text as="span" color="red">
          *
        </Text>
      )}
    </HStack>
  );

  return (
    <HStack
      h="full"
      alignItems="center"
      w="full"
      justifyContent="space-between"
      role="group"
      spacing={0}
      position="relative"
      pr={1.5}
    >
      <HStack
        w="full"
        maxW={{ base: 'calc(100% - 20px)', md: 'full' }}
        justifyContent="flex-start"
        as={Button}
        variant="unstyled"
        onClick={handleClick}
        spacing={2}
        p={1.5}
        _hover={{
          color: 'brand.500',
        }}
        _groupHover={{
          maxW: 'calc(100% - 20px)',
        }}
      >
        <Icon
          icon={icon}
          variant="fad"
          primaryLayer={
            isOneOffTask
              ? {
                  opacity: 1,
                  color: 'teal.500',
                }
              : { color: 'purple.500' }
          }
          secondaryLayer={
            isOneOffTask
              ? {
                  opacity: 0.4,
                  color: 'teal.500',
                }
              : { color: 'purple.300' }
          }
          size="4"
        />

        {isTextTruncated && isHovered ? (
          <Tooltip label={name} hasArrow>
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </HStack>

      {context.isMyWorkGAEnabled && (context.isMobile || isHovered) && !isCompleted && (
        <SnoozeButton inboxItem={data} />
      )}
    </HStack>
  );
});

NameRenderer.displayName = 'NameRenderer';

const readOnlyItemTextProps: Partial<TextProps> = { color: 'gray.400' };
const completedItemTextProps: Partial<TextProps> = { textDecoration: 'line-through', color: 'gray.400' };

export function getInboxItemDisplayProps(data: InboxItem): {
  name?: string | undefined;
  icon: CustomIconName;
  textProps?: Partial<TextProps>;
  isRequired?: boolean;
} {
  const isReadOnly = TasksTableUtils.isReadOnly(data);
  const isCompleted = TasksTableUtils.isInboxItemCompleted(data);
  return match<InboxItem, { name?: string; icon: CustomIconName; textProps?: Partial<TextProps> }>(data)
    .with({ itemType: InboxItemType.Checklist }, item => ({
      name: InboxItemUtils.getChecklistName(item),
      icon: 'circle-play',
      textProps: isCompleted ? completedItemTextProps : {},
    }))
    .with({ itemType: InboxItemType.StandardTask }, item => ({
      name: InboxItemUtils.getTaskTemplateName(item),
      icon: 'check-square',
      textProps: isCompleted ? completedItemTextProps : isReadOnly ? readOnlyItemTextProps : {},
    }))
    .with({ itemType: InboxItemType.ApprovalTask }, item => ({
      name: InboxItemUtils.getTaskTemplateName(item),
      icon: 'thumbs-up',
      textProps: isCompleted ? completedItemTextProps : isReadOnly ? readOnlyItemTextProps : {},
    }))
    .with({ itemType: InboxItemType.OneOffTask }, item => ({
      name: InboxItemUtils.getTaskTemplateName(item),
      icon: 'circle-check',
      isRequired: isNotIdRef(item.task.taskTemplate) ? item.task.taskTemplate.stop : false,
      textProps: isCompleted ? completedItemTextProps : {},
    }))
    .run();
}

import { ComponentStyleConfig, FunctionCSSInterpolation, theme as defaultTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { SystemStyleObject } from '@chakra-ui/system';

function assertFunctionCSSInterpolation(fn: unknown): asserts fn is FunctionCSSInterpolation {
  if (typeof fn !== 'function') {
    throw new Error('must be a function');
  }
}

const extendButtonVariant = (variant: 'solid' | 'outline') => {
  const extenders = defaultTheme.components.Button.variants;
  if (!extenders) {
    throw new Error('no button variants');
  }
  if (variant in extenders) {
    const extender = extenders[variant as keyof typeof extenders];
    assertFunctionCSSInterpolation(extender);
    return extender;
  } else {
    throw new Error('no button variants');
  }
};

const variantLink = (props: SystemStyleObject) => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    color: mode(`${c}.400`, `${c}.200`)(props),
    _hover: {
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'none',
      },
    },
    _active: {
      color: mode(`${c}.700`, `${c}.400`)(props),
    },
  };
};

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'base',
    lineHeight: 'taller',
    fontWeight: 'bold',
  },
  defaultProps: {
    variant: 'primary',
  },
  sizes: {
    lg: {
      fontSize: 'md',
    },
    xl: {
      fontSize: 'xl',
      padding: 4,
      borderRadius: 'lg',
    },
  },
  variants: {
    link: variantLink,
    primary: {
      ...extendButtonVariant('solid')({
        colorScheme: 'brand',
      }),
    },
    outline: {
      borderWidth: 0.5,
    },
    unstyled: {
      border: 'none',
      backgroundColor: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      padding: 'initial',
      margin: 'initial',
      height: 'inherit',
      width: 'inherit',
      minWidth: 'auto',
    },
    tertiary: {
      ...extendButtonVariant('outline')({
        colorScheme: 'gray',
      }),
      'borderColor': 'gray.300',
      'bgColor': 'white',
      '&:disabled:hover': {
        bgColor: 'white',
      },
      'color': 'gray.600',
      'borderWidth': 'px',
      'borderStyle': 'solid',
      'fontWeight': 'normal',
    },
    secondary: {
      ...extendButtonVariant('solid')({
        colorScheme: 'gray',
      }),
      color: 'gray.700',
    },
    cta: {
      ...extendButtonVariant('solid')({
        colorScheme: 'green',
      }),
    },
    danger: {
      ...extendButtonVariant('solid')({
        colorScheme: 'red',
      }),
    },
  },
};

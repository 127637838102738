import { useLocation } from '@process-street/adapters/navigation';
import { EmbedUtils } from '@process-street/subgrade/util';
import {
  Icon,
  IconButton,
  Modal,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Box,
  Link,
  Text,
  HStack,
  Tooltip,
} from 'components/design/next';
import * as React from 'react';

export type FullScreenViewerProps = {
  url: string;
};

export const FullScreenViewer = ({ url }: FullScreenViewerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const isEditingTemplate = location.pathname === 'template' || location.pathname === 'template.task';

  if (isEditingTemplate) return null;

  return (
    <>
      <Tooltip label="View in fullscreen">
        <IconButton
          onClick={onOpen}
          icon={<Icon icon="up-right-and-down-left-from-center" size="4" variant="fas" />}
          position="absolute"
          colorScheme="brand"
          right="0"
          aria-label="View in fullscreen"
          variant="ghost"
          size="sm"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent p="0" w={{ base: 'calc(100% - 20px)', md: 'calc(100% - 80px)' }} mt="10" mb={{ base: 2, md: 10 }}>
          <ModalCloseButton mt="-10" mr="-4" color="white" />
          <ModalBody display="grid" gridTemplateRows="1fr auto" p="0">
            {isOpen && (
              <Box
                as="iframe"
                w="full"
                h="full"
                title={`${EmbedUtils.extractDomain(url)} iframe`}
                data-testid="sandboxed-fullscreen-iframe"
                src={url}
                frameBorder="0"
                allowFullScreen={true}
                sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-presentation allow-downloads allow-storage-access-by-user-activation"
              />
            )}
            <HStack p="1" bgColor="gray.100" alignItems="center" justifyContent="center" position="relative">
              <Text display={{ base: 'none', md: 'block' }}>
                Content loaded from{' '}
                <Link fontWeight="bold" href={url} target="_blank" rel="noopener noreferrer">
                  {EmbedUtils.extractDomain(url)}
                </Link>
              </Text>

              <Tooltip label="Close">
                <IconButton
                  onClick={onClose}
                  icon={<Icon icon="down-left-and-up-right-to-center" size="4" variant="fas" />}
                  position="absolute"
                  colorScheme="brand"
                  right="0"
                  top="0"
                  bottom="0"
                  aria-label="Close fullscreen"
                  variant="ghost"
                  size="sm"
                />
              </Tooltip>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

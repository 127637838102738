import * as React from 'react';
import {
  SolutionTypeTag,
  SolutionTypeTagDescriptionMap,
  isIncomingWebhookInstance,
  isNullableTemplateSolutionInstance,
  isTrayAutomationAppName,
  getPrefixedAutomationAppName,
} from '@process-street/subgrade/automation';
import { SolutionsSelector } from 'pages/templates/_id/automation/components/solutions-selector';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  Box,
  Text,
  VStack,
  Divider,
  Button,
  Tooltip,
} from 'components/design/next';
import { AppSelector } from 'pages/templates/_id/automation/components/app-selector';
import { AutomationEditorResult, getMatchingTemplateSolutionInstances } from './query';
import { AutomationTopBar } from 'pages/templates/_id/automation/components/top-bar';
import { AutomationToggle } from 'pages/templates/_id/automation/components/toggle';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutomationLogListWrapper } from '../log-list';
import { match } from 'ts-pattern';
import { IncomingWebhookEditor } from './incoming-webhook-editor';
import { useGetAutomationInstanceQuery } from '../../utils/use-get-automation-instance';
import { useAutomationSelector } from '../selector/context';
import { AppSelectorV2 } from './app-selector-v2';
import { Option } from 'space-monad';

export const AutomationEditor: React.VFC<{ query: AutomationEditorResult }> = ({ query }) => {
  const [state] = useAutomationSelector();
  const {
    automationAppName: selectedAutomationAppName,
    automationInstanceId: selectedAutomationInstanceId,
    automationType: selectedAutomationType,
    solutionTypeTag: selectedSolutionType,
  } = state.context;

  const automationInstanceQuery = useGetAutomationInstanceQuery({
    automationInstanceId: selectedAutomationInstanceId,
    automationInstanceType: selectedAutomationType,
  });
  const automationInstance = automationInstanceQuery.data;

  if (!query.data.template) {
    return null;
  }

  const automationApp = Option(automationInstance).fold(() => selectedAutomationAppName, getPrefixedAutomationAppName);

  return (
    <VStack color="gray.500" spacing="10" align="stretch" pb="8">
      <Box
        borderBottomColor="gray.100"
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        paddingBottom="2"
        position="sticky"
        top="0"
        pt="5"
        zIndex="1401"
        backgroundColor="white"
      >
        <AutomationTopBar />
      </Box>
      {match(automationInstance)
        .when(isNullableTemplateSolutionInstance, instance => (
          <>
            <VStack align="stretch" spacing="10">
              <Tooltip
                placement="top-start"
                shouldWrapChildren={true}
                isDisabled={!instance}
                label="You can’t change which app your automation uses after it’s been created. You have to delete this automation and create a new one."
              >
                {selectedSolutionType === SolutionTypeTag.CreateChecklistWhen ? (
                  <AppSelectorV2 />
                ) : (
                  <AppSelector label="1. Choose an app" availableAutomationApps={query.data.availableAutomationApps} />
                )}
              </Tooltip>
              <Divider />
            </VStack>

            <VStack as={FormControl} spacing="4" align="stretch" id="app">
              <VStack spacing="1" align="stretch">
                <FormLabel marginBottom="0">
                  <Text fontWeight="bold">2. Choose an action</Text>
                </FormLabel>
                {!!instance?.solutionTypeTag && (
                  <FormHelperText fontSize="1">
                    {SolutionTypeTagDescriptionMap[instance.solutionTypeTag]}
                  </FormHelperText>
                )}
              </VStack>
              <Box maxW="sm">
                {automationApp && isTrayAutomationAppName(automationApp) ? (
                  <SolutionsSelector
                    solutions={getMatchingTemplateSolutionInstances({
                      automationApp,
                      solutions: query.data.solutions ?? [],
                      solutionTypeTag:
                        instance?.solutionTypeTag ?? selectedSolutionType ?? SolutionTypeTag.CreateChecklistWhen,
                    })}
                    templateSolutionInstance={instance}
                  />
                ) : (
                  <Tooltip
                    shouldWrapChildren={true}
                    hasArrow={true}
                    placement="bottom"
                    label="Select an app to see the available actions"
                  >
                    <Button
                      w="full"
                      variant="tertiary"
                      isDisabled={true}
                      loadingText="Creating Automation"
                      rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                      justifyContent="space-between"
                    >
                      <Text fontWeight="normal" as="span" noOfLines={1}>
                        <Text>…</Text>
                      </Text>
                    </Button>
                  </Tooltip>
                )}
              </Box>
              <Divider />
            </VStack>

            <VStack align="stretch" spacing="4">
              <VStack spacing="6" w="100%" align="stretch">
                <FormLabel marginBottom="0">
                  <Text fontWeight="bold">3. Activate this automation</Text>
                </FormLabel>
                <AutomationToggle automationInstance={instance} />
                <Divider />
              </VStack>
            </VStack>
            {instance && <AutomationLogListWrapper templateSolutionInstance={instance} />}
          </>
        ))
        .when(isIncomingWebhookInstance, ({ id }) => (
          <Box pb="32">
            <IncomingWebhookEditor webhookId={id} />
          </Box>
        ))
        .otherwise(() => null)}
    </VStack>
  );
};

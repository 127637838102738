import { Box, Button, Flex, useBreakpointValue, VStack, Text, useToast } from 'components/design/next';
import React from 'react';
import { Task } from 'pages/responses/_id/components/task';
import {
  FormResponseActor,
  FormResponseMachineSelectors,
} from 'pages/responses/_id/components/form-response-body/form-response-machine';
import { useActor, useSelector } from '@xstate/react';
import { FormMachineUtils } from './form-response-machine-utils';
import { match } from 'ts-pattern';
import { useBackgroundCdnImage } from 'features/forms/hooks/use-background-cdn-image';
import { ProgressBar } from 'pages/responses/_id/components/form-response-body/progress-bar';
import { OrganizationLogo } from 'pages/responses/_id/components/organization-logo/organization-logo';

export type FormResponseBodyProps = {
  actor: FormResponseActor;
};

export const FormResponseBody: React.FC<React.PropsWithChildren<FormResponseBodyProps>> = ({ actor }) => {
  const toast = useToast();
  const [state, send] = useActor(actor);

  const onPreviousClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    send({ type: 'PREVIOUS_TASK' });
  };

  const onNextClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (state.context.currentTaskActor?.getSnapshot()?.matches('validation.invalid.hidden')) {
      toast({
        title: `We couldn't complete the step`,
        description: `Oops! Some form fields still need to be completed.`,
        status: 'warning',
        isClosable: true,
      });
    }
    send({ type: 'NEXT_TASK' });
  };

  const { taskActorsMap, currentTaskActor, templateRevision } = state.context;

  const totalTaskCount = useSelector(actor, FormResponseMachineSelectors.getTotalTaskCount);
  const currentTaskIndex = FormMachineUtils.getCurrentTaskIndex(taskActorsMap, currentTaskActor);

  const isComplete = state.matches('response.complete');
  const isInaccessible = state.matches('response.inaccessible');

  const hasMoreTasks = FormMachineUtils.hasMoreTasks(taskActorsMap, currentTaskActor);
  const hasPreviousTasks = currentTaskIndex > 0;

  const isPreviousButtonVisible = hasPreviousTasks;
  const isNextButtonVisible = hasMoreTasks || !isComplete;
  const nextButtonText = hasMoreTasks ? 'Next' : 'Complete';

  const taskIsInvalid = currentTaskActor?.getSnapshot()?.matches('validation.invalid.visible');

  const templateId = state.context.checklist.template.id;
  const cdnImage = useBackgroundCdnImage({ templateId });
  const backgroundSize = useBreakpointValue({ base: 'contain', lg: 'cover' });
  const backgroundPosition = useBreakpointValue({ base: 'center top', lg: 'center center' });

  if (isInaccessible) {
    return null;
  }

  return (
    <>
      <Flex
        h="100vh"
        overflowY="auto"
        pb={{ base: '0', lg: '12' }}
        pt={{ base: '32', lg: 0 }}
        {...{
          ...match(cdnImage)
            .with({ status: 'loaded' }, ({ src }) => ({
              backgroundImage: `url(${src})`,
            }))
            .with({ lqipStatus: 'loaded' }, ({ lqipSrc }) => ({
              backgroundImage: `url(${lqipSrc}) `,
            }))
            .otherwise(() => {}),
          backgroundRepeat: 'no-repeat',
          backgroundPosition,
          backgroundAttachment: 'fixed',
          backgroundSize,
        }}
        bgColor="brand.100"
        justifyContent="center"
      >
        {currentTaskActor && (
          <Box
            w={{ base: 'full', lg: '3xl' }}
            h="fit-content"
            mt={{ base: '0', lg: '8' }}
            bgColor="white"
            borderRadius={{ base: '0', lg: 'lg' }}
            p="8"
          >
            <VStack
              spacing="8"
              mt="2"
              px="1"
              sx={{
                '.chakra-form-control label': {
                  marginBottom: '0px',
                },
              }}
            >
              <Task taskMachine={currentTaskActor} />
            </VStack>

            <Box mt="15">
              <Text fontWeight="700" color="gray.600" mb="5">
                Step {currentTaskIndex + 1}/{totalTaskCount}
              </Text>

              <Flex direction="row" w="full" justifyContent="space-between" pt="6">
                {isPreviousButtonVisible ? (
                  <Button
                    variant="secondary"
                    isLoading={state.matches('response.active.uncompletingPreviousTask')}
                    loadingText="Previous"
                    onClick={onPreviousClick}
                    isDisabled={taskIsInvalid}
                  >
                    Previous
                  </Button>
                ) : (
                  <Box />
                )}
                {isNextButtonVisible ? (
                  <Button
                    isLoading={state.matches('response.active.completingCurrentTask')}
                    loadingText={nextButtonText}
                    onClick={onNextClick}
                    isDisabled={taskIsInvalid}
                  >
                    {nextButtonText}
                  </Button>
                ) : (
                  <Box />
                )}
              </Flex>
              {totalTaskCount > 1 && (
                <Flex direction="column" w="full" alignItems="center" mt="8">
                  <ProgressBar actor={actor} />
                </Flex>
              )}
              {templateRevision && (
                <Flex direction="column" w="full" alignItems="center" mt="8">
                  <OrganizationLogo hasProcessStreetBranding={templateRevision.hasProcessStreetBranding} />
                </Flex>
              )}
            </Box>
          </Box>
        )}
      </Flex>
    </>
  );
};

import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  ButtonGroup,
  Divider,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { useIsMutating, useQueryClient } from 'react-query';
import {
  GetAllTemplatesQuery,
  GetTemplateQuery,
  UpdateTemplateStatusMutation,
  useGetTemplateQuery,
  useUpdateTemplateStatusMutation,
} from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useTemplateMenuContext } from '..';
import { TemplateStatus, TemplateType, tmplTypeName, tmplTypeNameLower } from '@process-street/subgrade/process';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  GetNewestTemplateRevisionsByTemplateIdQueryResponse,
} from 'features/template-revisions/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { match } from 'ts-pattern';
import { AnalyticsService } from 'components/analytics/analytics.service';

export type ArchiveTemplateAlertProps = Omit<AlertDialogProps, 'leastDestructiveRef' | 'children'> & {
  onArchived?: () => void;
};
export const ArchiveTemplateAlert: React.FC<React.PropsWithChildren<ArchiveTemplateAlertProps>> = ({
  onClose,
  isOpen,
  onArchived,
}) => {
  const { templateId } = useTemplateMenuContext();

  const { data: template } = useGetTemplateQuery({ templateId }, { enabled: Boolean(templateId) });
  const toast = useToast();

  const queryClient = useQueryClient();

  const archiveTemplateMutation = useUpdateTemplateStatusMutation({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Archived' }),
    onSuccess: res => {
      AnalyticsService.trackEvent('template archived', {});
      void queryClient.invalidateQueries(GetAllTemplatesQuery.getKey({ organizationId: res.organization.id }));
      queryClient.setQueryData(GetTemplateQuery.getKey({ templateId }), res);
      queryClient.setQueryData<GetNewestTemplateRevisionsByTemplateIdQueryResponse | undefined>(
        GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }),
        revisions => {
          return revisions?.map(revision => {
            return { ...revision, template };
          }) as GetNewestTemplateRevisionsByTemplateIdQueryResponse | undefined;
        },
      );
      onArchived?.();
      onClose?.();
      toast({
        status: 'success',
        title: `${tmplTypeName(template)} archived`,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems archiving the ${tmplTypeNameLower(template)}`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const whatWillBeArchived = match(template?.templateType)
    .with(undefined, () => '')
    .with(TemplateType.Playbook, () => 'workflow and all of its runs')
    .with(TemplateType.Form, () => 'form and all of its responses')
    .with(TemplateType.Page, () => 'page')
    .run();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      scrollBehavior="inside"
      size="xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader py="4" px="8" fontSize="lg">
          Archive this {tmplTypeNameLower(template)}?
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody pt="4" px="8" pb="8">
          <Text>Your {whatWillBeArchived} will be archived.</Text>
        </AlertDialogBody>

        <Divider />

        <AlertDialogFooter py="6" px="8">
          <Spacer />
          <ButtonGroup>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={archiveTemplateMutation.isLoading}
              isDisabled={archiveTemplateMutation.isLoading}
              variant="primary"
              onClick={() => archiveTemplateMutation.mutate({ templateId, status: 'Archived' })}
            >
              Archive
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export type ArchiveTemplateButtonProps = {
  children: (props: { templateStatus: TemplateStatus; isMutating: boolean }) => React.ReactElement<{
    icon?: React.ReactElement;
    isDisabled: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }> | null;
  onArchived?: () => void;
};

export const ArchiveTemplateButton: React.FC<ArchiveTemplateButtonProps> = React.memo(({ onArchived, children }) => {
  const { setCloseOnBlur, templateId } = useTemplateMenuContext();

  const disclosure = useDisclosure({
    id: 'archive',
    onOpen: () => {
      setCloseOnBlur(false);
    },
    onClose: () => {
      setCloseOnBlur(true);
    },
  });

  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: template } = useGetTemplateQuery({ templateId });
  const templateStatus = template?.status ?? TemplateStatus.Active;

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
    enabled: !!templateId,
  });

  const archiveTemplateMutation = useUpdateTemplateStatusMutation({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Active' }),
    onSuccess: res => {
      onArchived?.();
      AnalyticsService.trackEvent('template unarchived', {});
      queryClient.setQueryData(GetTemplateQuery.getKey({ templateId }), res);
      void queryClient.invalidateQueries(GetAllTemplatesQuery.getKey({ organizationId: res.organization.id }));
      toast({
        status: 'success',
        title: `${tmplTypeName(template)} restored`,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems restoring this ${tmplTypeNameLower(template)}`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const archiveMutations = useIsMutating({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Archived' }),
  });

  const unarchiveMutations = useIsMutating({
    mutationKey: UpdateTemplateStatusMutation.getKey({ templateId, status: 'Active' }),
  });

  const isMutating = archiveMutations > 0 || unarchiveMutations > 0;

  const canArchiveTemplate = permissionMap?.templateArchive;

  if (typeof children !== 'function') {
    throw new Error('`ArchiveTemplateButton` children must be a render prop function');
  }

  const child = children({ templateStatus, isMutating });
  if (child === null || !React.isValidElement(child) || Array.isArray(child)) {
    throw new Error('The render prop function child must return a single clickable element or a render prop function');
  }

  const isFree = useIsCurrentUserFreeMember();

  if (isFree) {
    return <ButtonWithUpgradeTooltip>{child}</ButtonWithUpgradeTooltip>;
  } else if (!canArchiveTemplate) {
    return null;
  }

  const btn = React.cloneElement(child, {
    onClick: () => {
      if (templateStatus === TemplateStatus.Active) {
        if (canArchiveTemplate && !isMutating) {
          disclosure.onToggle();
        }
      }
      if (templateStatus === TemplateStatus.Archived) {
        if (canArchiveTemplate && !isMutating) {
          archiveTemplateMutation.mutate({ templateId, status: 'Active' });
        }
      }
    },
  });

  return (
    <>
      {btn}
      <ArchiveTemplateAlert
        onArchived={onArchived}
        onClose={disclosure.onClose}
        isOpen={disclosure.isOpen}
      ></ArchiveTemplateAlert>
    </>
  );
});

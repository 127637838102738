import * as React from 'react';
import { useActor } from '@xstate/react';
import { SelectFormFieldActor } from './select-form-field-machine';
import { Box, HStack, IconButton, Link, Text, VStack } from 'components/design/next';
import { Icon } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { Reorder } from 'framer-motion';
import {
  isMultiChoiceFormFieldWidget,
  MultiChoiceFormFieldWidget,
  SelectFormFieldConfigItem,
  SelectFormFieldWidget as SelectFormFieldWidgetModel,
  Widget,
} from '@process-street/subgrade/process';
import { SelectItem } from './select-item';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { useFeatureFlag } from 'app/features/feature-flags';
import { LinkDataSetModal } from 'app/pages/templates/_id/components/link-data-set-modal';
import { ConnectDataSetModal } from 'app/pages/templates/_id/components/connect-data-set-modal';
import { DisconnectDataSetConfirmationModal } from 'app/pages/reports/data-sets/components/disconnect-data-set-confirmation-modal';
import { DatasetCreateFromCsvModal } from 'app/pages/reports/data-sets/components/data-set-creation-modal';
import { LinkedDataDisplay } from './linked-data-display';
import { useInjector } from 'app/components/injection-provider';

export type SelectFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: SelectFormFieldActor;
};

export const SelectFormFieldWidget: React.FC<React.PropsWithChildren<SelectFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, labelActor, itemActorRefs, recentlyMovedFrom } = state.context;
  const { $state } = useInjector('$state');
  const isMulti = isMultiChoiceFormFieldWidget(widget);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  if (!widget) return null;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack
          alignItems="flex-start"
          flex="1"
          ref={node => {
            containerRef.current = node;
            if (node && !state.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
        >
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel actor={labelActor} />}

          <VStack
            shouldWrapChildren
            bg="gray.50"
            position="relative"
            spacing="0"
            w="full"
            alignItems="stretch"
            sx={{
              '.blvd-select__control--is-disabled': {
                background: 'var(--ps-colors-gray-50)',

                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderColor: 'gray.200',
              },
              '.blvd-select__indicator-separator': {
                display: 'none',
              },
              '.blvd-select__indicator': {
                transform: 'rotate(180deg)',
              },
              '.blvd-select__placeholder': {
                color: 'var(--ps-colors-gray-400) !important',
              },
            }}
          >
            <BlvdSelect placeholder={isMulti ? 'Multi Choice' : 'Dropdown'} isDisabled />
            {widget.config.linkId ? (
              <VStack
                spacing="3"
                w="full"
                alignItems="stretch"
                border="1px solid"
                borderColor="gray.200"
                borderTopWidth="0"
                borderBottomLeftRadius="lg"
                borderBottomRightRadius="lg"
                px="3"
                py="4"
                sx={{ listStyle: 'none' }}
                maxH="248px" // 4 1/2 items
                overflowY="auto"
              >
                <LinkedDataDisplay widget={widget as SelectFormFieldWidgetModel} />
              </VStack>
            ) : (
              <VStack
                as={Reorder.Group}
                axis="y"
                spacing="3"
                w="full"
                alignItems="stretch"
                border="1px solid"
                borderColor="gray.200"
                borderTopWidth="0"
                borderBottomLeftRadius="lg"
                borderBottomRightRadius="lg"
                px="3"
                py="4"
                sx={{ listStyle: 'none' }}
                values={widget?.config.items ?? []}
                onReorder={(items: SelectFormFieldConfigItem[]) => {
                  send({ type: 'ITEM_REORDER', items });
                }}
                maxH="248px" // 4 1/2 items
                layoutScroll
                overflowY="auto"
              >
                {widget?.config.items.map((item, index) => (
                  <Box as={Reorder.Item} key={item.id} id={item.id} value={item}>
                    <SelectItem index={index} actor={itemActorRefs[item.id]} />
                  </Box>
                ))}

                <HStack justifyContent="center">
                  <IconButton
                    mx="auto"
                    variant="outline"
                    borderWidth="thin"
                    borderColor="gray.300"
                    aria-label="Add"
                    size="sm"
                    onClick={() => send({ type: 'ITEM_APPEND' })}
                    bg="white"
                  >
                    <Icon icon="plus" size="3" color="gray.600" />
                  </IconButton>
                </HStack>
              </VStack>
            )}

            <WidgetListItemDragIcon />

            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Required widget={widget} />
              <FormsWidgetMenuItems.ConditionalLogic widget={widget} />
              {isDataSetsV2Enabled && <FormsWidgetMenuItems.ConnectDataSet widget={widget} />}

              <FormsWidgetMenuItems.Divider />

              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </VStack>
          {widget.config.linkId && (
            <HStack spacing={4} mt={2}>
              <Box borderColor="gray.500" borderStyle="solid" borderWidth="1px" borderRadius="md" p={3}>
                <Icon icon="arrows-rotate" size="4" color="gray.500" />
              </Box>
              <VStack alignItems="flex-start">
                <Link
                  href={$state.href('dataSets.dataSet', { dataSetId: widget.config.linkedDataSetId })}
                  color="brand.500"
                  isExternal
                >
                  {widget.config.linkedSavedViewName}
                </Link>
                <HStack spacing={1} alignItems="flex-start" justifyContent="center" color="gray.500">
                  <Icon icon="database" size="4" mt={1} />
                  <Text as="span" fontSize="md">
                    {widget.config.linkedDataSetName}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          )}
        </VStack>
        <LinkDataSetModal
          templateRevisionId={widget.templateRevision.id}
          taskTemplateId={widget.header.taskTemplate.id}
          onUpdate={async (widget: Widget) => {
            await actor.send({
              type: 'LINK_UNLINK_DATA_SET',
              widget: widget as SelectFormFieldWidgetModel | MultiChoiceFormFieldWidget,
            });
            return widget; // Callback expects a widget to be returned
          }}
        />
        <ConnectDataSetModal />
        <DisconnectDataSetConfirmationModal
          onUpdate={(widget: Widget) =>
            actor.send({
              type: 'LINK_UNLINK_DATA_SET',
              widget: widget as SelectFormFieldWidgetModel | MultiChoiceFormFieldWidget,
            })
          }
        />
        <DatasetCreateFromCsvModal />
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

import React from 'react';
import { TemplateSolutionInstance, TraySolution } from '@process-street/subgrade/automation';
import { Button, Text, Box, VStack } from 'components/design/next';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { usePromise } from 'react-use';
import { useConfigWizardDisclosure } from '../../_id/config-wizard/context';
import * as AutomationSelectorMachine from 'pages/templates/_id/automation/components/selector/context';
import { useCreateTemplateSolutionInstanceMutation } from 'features/automations/query-builder/create-template-solution-instance-mutation';
import { useInjector } from 'components/injection-provider';
import { useTaskTemplateGroupId } from '../../utils/use-task-template-group-id';
import { useQueryClient } from 'react-query';
import {
  SolutionInstancesByTemplateIdQuery,
  SolutionInstancesByTemplateIdQueryResponse,
} from 'features/automations/query-builder';

export interface SolutionsSelectorProps {
  solutions: TraySolution[];
  templateSolutionInstance: TemplateSolutionInstance | undefined;
}

export const SolutionsSelector: React.FC<React.PropsWithChildren<SolutionsSelectorProps>> = ({
  solutions,
  templateSolutionInstance,
}) => {
  const [loadingSolutionId, setLoading] = React.useState<TraySolution['id']>();

  const taskTemplateGroupId = useTaskTemplateGroupId();
  const configWizardModalDisclosure = useConfigWizardDisclosure();
  const mounted = usePromise();
  const [, send] = AutomationSelectorMachine.useAutomationSelector();

  const queryClient = useQueryClient();
  const createTemplateSolutionInstanceMutation = useCreateTemplateSolutionInstanceMutation({
    onSuccess: templateSolutionInstance => {
      if (!templateSolutionInstance) return;

      queryClient.setQueryData<SolutionInstancesByTemplateIdQueryResponse>(
        SolutionInstancesByTemplateIdQuery.getKey({ templateId: templateSolutionInstance.templateId }),
        current => [...(current ?? []), templateSolutionInstance],
      );
    },
  });

  const templateId = useInjector('$stateParams').$stateParams.id;

  const createAutomation = async (solutionId: Muid) => {
    if (!templateId) return;

    const id = MuidUtils.randomMuid();

    await createTemplateSolutionInstanceMutation.mutateAsync({
      id,
      templateId,
      solutionId,
      taskTemplateGroupId,
    });

    setLoading(undefined);

    send({
      type: 'AUTOMATION_CONFIGURED',
      payload: { id, automationType: 'tray' },
    });
    configWizardModalDisclosure.onOpen();
  };

  const handleSelectSolution = (solution: TraySolution) => () => {
    setLoading(solution.id);
    if (templateSolutionInstance) {
      configWizardModalDisclosure.onOpen();
      setLoading(undefined);
    } else {
      mounted(createAutomation(solution.id));
    }
  };

  const isActive = templateSolutionInstance?.configured && templateSolutionInstance?.enabled;
  const isPristine = !templateSolutionInstance;

  return (
    <VStack align="stretch" spacing="4">
      {solutions.map(s => {
        const solutionSelected = templateSolutionInstance?.solutionId === s.id;
        return (
          <Box key={s.id}>
            <Button
              w="full"
              variant={solutionSelected ? 'secondary' : 'tertiary'}
              onClick={handleSelectSolution(s)}
              isLoading={loadingSolutionId === s.id}
              isDisabled={isActive || !!loadingSolutionId || (!solutionSelected && !isPristine)}
              isActive={solutionSelected}
              loadingText="Creating Automation"
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              justifyContent="space-between"
            >
              <Text fontWeight="normal" as="span" noOfLines={1}>
                …{s.description}
              </Text>
            </Button>
          </Box>
        );
      })}
    </VStack>
  );
};

import { FormFieldWidget, TableFormFieldWidget, TaskTemplate, Template } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  DeleteWidgetByHeaderIdMutation,
  UpdateWidgetMutation,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, send, sendParent, spawn } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { FormFieldLabelActor, makeFormFieldLabelMachine } from '../common/form-field-label';
import { match } from 'ts-pattern';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { ColDef } from '@ag-grid-community/core';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { MuidUtils } from '@process-street/subgrade/core';

type Context = {
  widget: TableFormFieldWidget;
  initialWidget: TableFormFieldWidget;
  template: Template;
  labelActor: FormFieldLabelActor<TableFormFieldWidget>;
  recentlyMovedFrom?: TaskTemplate;
  inputNode: HTMLElement | null;
};

type Event =
  | WidgetEvent<TableFormFieldWidget>
  | { type: 'SET_WIDGET'; widget: TableFormFieldWidget } // Allows widget to be updated without mutation
  | { type: 'REORDER_COLUMNS'; colDefs: ColDef[] }
  | { type: 'INSERT_COLUMN_LEFT'; id: string }
  | { type: 'INSERT_COLUMN_RIGHT'; id: string }
  | { type: 'DELETE_COLUMN'; id: string }
  | { type: 'UPDATE_COLUMN_TYPE'; id: string; columnType: TableFormFieldConfig.ColumnDef['columnType'] }
  | { type: 'UPDATE_COLUMN_NAME'; id: string; columnName: TableFormFieldConfig.ColumnDef['name'] }
  // Internal type
  | { type: 'done.invoke.updateWidgetMutation'; data: TableFormFieldWidget };

export type TableFormFieldMachine = ReturnType<typeof makeTableFormFieldMachine>;
export type TableFormFieldActor = ActorRefFrom<TableFormFieldMachine>;

export const makeTableFormFieldMachine = ({
  widget,
  template,
  sharedContext,
}: {
  widget: TableFormFieldWidget;
  template: Template;
  sharedContext: SharedContext;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget as FormFieldWidget).templateRevision?.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: `table-form-field:${widget.id}`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./table-form-field-machine.typegen').Typegen0,
      context: () =>
        ({
          widget,
          initialWidget: widget,
          template,
          labelActor: spawn(makeFormFieldLabelMachine<TableFormFieldWidget>({ widget, queryClient })),
          recentlyMovedFrom: undefined,
          inputNode: null,
        } as Context),
      states: {
        idle: {
          on: {
            DELETE_WIDGET: { target: 'deleting' },
            MOVE_DOWN: { actions: ['sendMoveDown'] },
            MOVE_UP: { actions: ['sendMoveUp'] },
            DUPLICATE: { actions: ['sendDuplicate'] },
            MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
            MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
            UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
            SET_NODE: { actions: ['assignNode'] },
            SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
          },
        },
        editing: {},
        saving: {
          invoke: [
            {
              id: 'updateWidgetMutation',
              src: 'updateWidgetMutation',
              onDone: {
                target: 'idle',
                actions: ['assignWidget', 'assignInitialWidget', 'sendUpdateDone'],
              },
              onError: {
                target: 'error',
                actions: ['resetWidget', 'sendUpdateError'],
              },
            },
          ],
        },
        deleting: {
          invoke: [
            {
              id: 'deleteWidget',
              src: 'deleteWidget',
              onDone: { target: 'deleted' },
              onError: { target: 'idle' },
            },
          ],
        },
        deleted: {
          type: 'final',
        },
        error: {},
      },
      on: {
        REORDER_COLUMNS: { target: 'saving', actions: ['reorderColumns'] },
        DELETE_COLUMN: { target: 'saving', actions: ['deleteColumn'] },
        INSERT_COLUMN_LEFT: { target: 'saving', actions: ['insertColumnLeft'] },
        INSERT_COLUMN_RIGHT: { target: 'saving', actions: ['insertColumnRight'] },
        UPDATE_COLUMN_TYPE: { target: 'saving', actions: ['setColumnType'] },
        UPDATE_COLUMN_NAME: { target: 'saving', actions: ['setColumnName'] },
        UPDATE_WIDGET: { target: 'saving', actions: ['assignWidget'] },
        SET_WIDGET: { actions: ['assignWidget', 'sendUpdateDone'] },
      },
    },
    {
      actions: {
        assignWidget: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
              .with({ type: 'SET_WIDGET' }, ({ widget }) => widget)
              .with({ type: 'done.invoke.updateWidgetMutation' }, ({ data }) => data)
              .otherwise(() => ctx.widget),
        }),
        assignInitialWidget: assign({
          initialWidget: (_, e) => e.data,
        }),
        resetWidget: assign({
          widget: context => context.initialWidget,
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        sendUpdateDone: send(ctx => ({ type: 'UPDATE_DONE', data: ctx.widget }), { to: ctx => ctx.labelActor }),
        sendUpdateError: send({ type: 'UPDATE_ERROR' }, { to: ctx => ctx.labelActor }),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget.header,
                  ...{ ...(header as TableFormFieldWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
        reorderColumns: assign({
          widget: (context, e) => {
            const { widget } = context;
            const newColumnDefs = e.colDefs.map(colDef => {
              return widget.config.columnDefs.find(def => def.id === colDef.colId)!;
            });
            return { ...widget, config: { ...widget.config, columnDefs: newColumnDefs } };
          },
        }),
        insertColumnLeft: assign({
          widget: (context, e) => {
            const { widget } = context;
            const index = widget.config.columnDefs.findIndex(def => def.id === e.id);
            const id = MuidUtils.randomMuid();
            const newColumnDefs = widget.config.columnDefs.slice();
            newColumnDefs.splice(index, 0, { id, name: 'New Column', columnType: 'Text' });
            return { ...widget, config: { ...widget.config, columnDefs: newColumnDefs } };
          },
        }),
        insertColumnRight: assign({
          widget: (context, e) => {
            const { widget } = context;
            const index = widget.config.columnDefs.findIndex(def => def.id === e.id);
            const id = MuidUtils.randomMuid();
            const newColumnDefs = widget.config.columnDefs.slice();
            newColumnDefs.splice(index + 1, 0, { id, name: 'New Column', columnType: 'Text' });
            return { ...widget, config: { ...widget.config, columnDefs: newColumnDefs } };
          },
        }),
        deleteColumn: assign({
          widget: (context, e) => {
            const { widget } = context;
            const newColumnDefs = widget.config.columnDefs.filter(def => def.id !== e.id);
            return { ...widget, config: { ...widget.config, columnDefs: newColumnDefs } };
          },
        }),
        setColumnType: assign({
          widget: (context, e) => {
            const { widget } = context;
            const newColumnDefs = widget.config.columnDefs.map(def => {
              if (def.id === e.id) {
                return { ...def, columnType: e.columnType };
              }
              return def;
            });
            return { ...widget, config: { ...widget.config, columnDefs: newColumnDefs } };
          },
        }),
        setColumnName: assign({
          widget: (context, e) => {
            const { widget } = context;
            const newColumnDefs = widget.config.columnDefs.map(def => {
              if (def.id === e.id) {
                return { ...def, name: e.columnName };
              }
              return def;
            });
            return { ...widget, config: { ...widget.config, columnDefs: newColumnDefs } };
          },
        }),
      },
      services: {
        updateWidgetMutation: async (context, e) => {
          const widget = match(e)
            .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
            .otherwise(() => context.widget);

          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<TableFormFieldWidget>(widget),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems updating the table`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
        deleteWidget: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget.header.id),
            onSuccess: () => {
              cacheSetter?.delete(context.widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the table`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
    },
  );
};

import { FormFieldOrTaskOption, FormFieldSelect } from 'features/conditional-logic/components/form-fields-select';
import {
  FieldType,
  FormFieldWidget,
  isSelectFormFieldConfig,
  MembersFormFieldConfig,
  TaskStatus,
  TaskTemplate,
  Widget,
  WidgetType,
  WidgetUtils,
} from '@process-street/subgrade/process';
import {
  ChecklistRuleDefinitionOperator,
  FormFieldCondition,
  RuleConstants,
  TaskCondition,
  TimeBasedCondition,
} from '@process-street/subgrade/conditional-logic';
import * as React from 'react';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { match, P } from 'ts-pattern';
import { Box, ButtonProps, Grid, GridItem, HStack, Input, Stack, Text } from '@chakra-ui/react';
import {
  NodeType,
  SelectorHelper,
  TimeSource,
  TimeSourceLabels,
} from 'directives/rules/template/task-templates-selector/selector-helper';
import { ConditionDropdown } from 'features/conditional-logic/components/condition-dropdown';
import { OperandValueText } from 'features/conditional-logic/components/operand-value-text';
import { OperandValueSelect } from 'features/conditional-logic/components/operand-value-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { OptionType } from 'features/conditional-logic/components/task-templates-selector/types';
import { OperandValueDate } from 'features/conditional-logic/components/operand-value-date/component';
import keyBy from 'lodash/keyBy';
import {
  getFormFieldCondition,
  RuleConditionParams,
} from 'features/conditional-logic/components/rule-definition/use-rule-definition-validation';
import { useDebouncedCallback } from 'use-debounce';
import { Button, Icon, IconButton } from 'components/design/next';
import { Muid, OrganizationMembership, User } from '@process-street/subgrade/core';
import { useConditionalLogicModalStore } from '../modal/store';
import { useGroupMemberships } from 'features/group-memberships/query-builder';
import { SimpleOption } from 'components/design/BlvdSelect/types';
import { OperandValuePeriod } from 'features/conditional-logic/components/operand-value-period';
import { GetAllLinkedDataSetRowsQuery } from 'pages/reports/data-sets/query-builder';
import { ChecklistSelectFormFieldWidgetHelper } from 'pages/runs/_id/components/checklist-select-form-field-widget/helper';

export interface ConditionDisplayProps {
  displayType?: 'if' | 'and' | 'or';
  /** Show "AND" button if present. */
  onAddAnd?: () => void;
  onAddOr?: () => void;
  onDelete: () => void;
}

interface ConditionProps extends ConditionDisplayProps {
  options: FormFieldOrTaskOption[];
  widgets: Widget[];
  taskTemplates: TaskTemplate[];
  onChange: (condition: FormFieldCondition | TaskCondition | TimeBasedCondition) => void;
  meatballMenu?: () => JSX.Element;
  condition: FormFieldCondition | TaskCondition | TimeBasedCondition;
  selectedWidgetGroupId?: Muid;
}

export function Condition({
  meatballMenu,
  onChange,
  options,
  widgets,
  taskTemplates,
  condition,
  displayType,
  onAddAnd,
  onAddOr,
  onDelete,
  selectedWidgetGroupId,
}: ConditionProps) {
  const { normalizedData } = useConditionalLogicModalStore();

  const handleConditionChange = useDebouncedCallback((params: RuleConditionParams) => {
    onChange(getFormFieldCondition(params));
  }, 200);

  const [selectedWidget, setSelectedWidget] = React.useState<FormFieldWidget | null>(() => {
    return match(condition)
      .with(
        { formFieldWidgetGroupId: P.not(P.nullish) },
        condition => (normalizedData.widgets.byGroupId[condition.formFieldWidgetGroupId] as FormFieldWidget) ?? null,
      )
      .otherwise(() => null);
  });

  const [selectedTask, setSelectedTask] = React.useState<TaskTemplate | null>(() => {
    const result = match(condition)
      .with(
        { taskTemplateGroupId: P.not(P.nullish) },
        condition => (normalizedData.tasks.byGroupId[condition.taskTemplateGroupId] as TaskTemplate) ?? null,
      )
      .otherwise(() => null);

    return result;
  });

  const [selectedTimeSource, setSelectedTimeSource] = React.useState<TimeSource | null>(() => {
    const result = match(condition)
      .with({ operator: ChecklistRuleDefinitionOperator.PeriodAfter }, _condition => TimeSource.ChecklistStartDate)
      .otherwise(() => null);

    return result;
  });

  const [operator, setOperator] = React.useState<ChecklistRuleDefinitionOperator>(() => condition.operator);

  const [operandValue, setOperandValue] = React.useState<any>(() => {
    return match(condition)
      .with({ taskTemplateGroupId: P.not(P.nullish), operand: { value: P.nullish } }, () => null)
      .with(
        { taskTemplateGroupId: P.not(P.nullish), operand: { value: P.not(P.nullish) } },
        condition => condition.operand.value,
      )
      .with({ formFieldWidgetGroupId: P.not(P.nullish), operandValue: P.nullish }, () => null)
      .with({ dateType: P.not(P.nullish) }, ({ operand: { value } }) => value)
      .with(
        { formFieldWidgetGroupId: P.not(P.nullish), operandValue: { value: P.not(P.nullish) } },
        ({ operandValue: { value } }) => value,
      )
      .otherwise(() => null);
  });

  const groupMembershipsQuery = useGroupMemberships();

  const getItemsForMembersField = (groupId: string) =>
    groupMembershipsQuery.data
      ? groupMembershipsQuery.data
          .filter(gm => gm.group.id === groupId)
          .map(gm => {
            const om = gm.organizationMembership as OrganizationMembership;
            const user = om.user as User;
            return { value: om.id, label: `${user.username} (${user.email})` };
          })
          .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB))
      : [];

  const [operandValueOptions, setOperandValueOptions] = React.useState<SimpleOption[]>([]);

  React.useEffect(
    function getOperandValueOptions() {
      match<{ selectedWidget: Widget | null; selectedTask: TaskTemplate | null }, void>({
        selectedWidget,
        selectedTask,
      })
        .with(
          { selectedWidget: { header: { type: WidgetType.FormField }, fieldType: FieldType.Members } },
          ({ selectedWidget: formField }) => {
            const { groupId } = formField.config as MembersFormFieldConfig;
            setOperandValueOptions(getItemsForMembersField(groupId));
          },
        )
        .with({ selectedWidget: { header: { type: WidgetType.FormField } } }, ({ selectedWidget: formField }) => {
          if (isSelectFormFieldConfig(formField.config)) {
            const { linkedColumnData, linkedDataSetId, linkId, linkedSavedViewId, linkedColumnId } = formField.config;

            if (linkedColumnData) {
              setOperandValueOptions(linkedColumnData.map(data => ({ value: data, label: data })));
            }

            // Render data set options
            if (linkedDataSetId && linkId && linkedSavedViewId && linkedColumnId) {
              GetAllLinkedDataSetRowsQuery.queryFn({
                dataSetId: linkedDataSetId,
                savedViewId: linkedSavedViewId,
                linkId,
              }).then(rows => {
                const [options] = ChecklistSelectFormFieldWidgetHelper.getLinkedOptions(
                  rows,
                  linkedColumnId,
                  undefined,
                );
                setOperandValueOptions(options.map(item => ({ value: item.label, label: item.label })));
              });
            }
            setOperandValueOptions(formField.config.items.map(item => ({ value: item.name, label: item.name })));
          } else {
            setOperandValueOptions([]);
          }
        })
        .with({ selectedTask: P.not(P.nullish) }, () =>
          setOperandValueOptions([
            {
              value: TaskStatus.Completed,
              label: 'Checked',
            },
            {
              value: TaskStatus.NotCompleted,
              label: 'Unchecked',
            },
          ]),
        )
        .otherwise(() => setOperandValueOptions([]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getItemsForMembersField
    [selectedWidget, selectedTask],
  );

  const operandValueOptionsMap = keyBy(operandValueOptions, o => o.value);

  const isOperandValueDisabled = operator ? !ConditionalLogicUtils.isOperandValueRequired(operator) : true;

  const handleSourceChange = (value: Widget | TaskTemplate | TimeSource | null) => {
    match(value)
      .with({ taskType: P.not(P.nullish) }, (taskTemplate: TaskTemplate) => {
        const wasTaskSelectedBefore = Boolean(selectedTask);
        const operatorsForTask = RuleConstants.FieldTypeToOperatorListMap.task;
        const newOperator = operatorsForTask[0] ?? null;
        const newOperandValue = wasTaskSelectedBefore ? operandValue : TaskStatus.Completed;

        setSelectedWidget(null);
        setSelectedTimeSource(null);
        setSelectedTask(taskTemplate);
        setOperator(newOperator);
        setOperandValue(newOperandValue);

        handleConditionChange({
          selectedWidget: null,
          selectedTask: taskTemplate,
          selectedTimeSource: null,
          operator: newOperator,
          operandValue: { value: newOperandValue },
        });
      })
      .with({ header: P.not(P.nullish) }, widget => {
        if (!WidgetUtils.isFormFieldWidget(widget)) throw new Error('Selected Widget is not a FormField');

        const operatorsForWidget = widget ? ConditionalLogicUtils.getAvailableOperatorListByWidget(widget) : [];
        const newOperator = operatorsForWidget[0] ?? null;
        const wasWidgetSelectedBefore = Boolean(selectedWidget);
        const newOperandValue = wasWidgetSelectedBefore ? operandValue : null;

        setSelectedWidget(widget);
        setSelectedTask(null);
        setSelectedTimeSource(null);
        setOperator(newOperator);
        setOperandValue(newOperandValue);

        handleConditionChange({
          selectedWidget: widget,
          selectedTask: null,
          selectedTimeSource: null,
          operator: newOperator,
          operandValue: null,
        });
      })
      .with(P.union(TimeSource.ChecklistStartDate), timeSource => {
        const wasTimeSourceSelectedBefore = Boolean(selectedTimeSource);
        const availableOperators = RuleConstants.FieldTypeToOperatorListMap.timeSource;
        const newOperator = availableOperators[0] ?? null;
        const newOperandValue = wasTimeSourceSelectedBefore ? operandValue : { days: 1 };

        setSelectedWidget(null);
        setSelectedTask(null);
        setSelectedTimeSource(timeSource);
        setOperator(newOperator);
        setOperandValue(newOperandValue);

        handleConditionChange({
          selectedWidget: null,
          selectedTask: null,
          selectedTimeSource: timeSource,
          operator: newOperator,
          operandValue: { value: newOperandValue },
        });
      })
      .otherwise(() => {
        setSelectedTask(null);
        setSelectedWidget(null);
        setSelectedTimeSource(null);
      });
  };

  const handleOperandValueChange = (newValue: any) => {
    setOperandValue(newValue);

    handleConditionChange({
      selectedWidget,
      selectedTask,
      selectedTimeSource,
      operator,
      operandValue: { value: newValue },
    });
  };

  const handleOperatorChange = (value: ChecklistRuleDefinitionOperator) => {
    const isOperandValueRequired = value && ConditionalLogicUtils.isOperandValueRequired(value);
    const newOperandValue = isOperandValueRequired ? operandValue : null;

    setOperandValue(newOperandValue);
    setOperator(value);
    handleConditionChange({
      selectedWidget,
      selectedTask,
      selectedTimeSource,
      operator: value,
      operandValue: { value: newOperandValue },
    });
  };

  const isAndRule = displayType === 'and';
  const highlighted = selectedWidgetGroupId
    ? match(condition)
        .with(
          { formFieldWidgetGroupId: P.not(P.nullish) },
          condition => selectedWidgetGroupId === condition.formFieldWidgetGroupId,
        )
        .otherwise(() => false)
    : false;

  const sourceValue = match({ selectedWidget, selectedTask, selectedTimeSource })
    .with({ selectedWidget: P.not(P.nullish) }, ({ selectedWidget }) => ({
      value: selectedWidget.header.id,
      icon: SelectorHelper.getIconForWidget(selectedWidget),
      label: SelectorHelper.getLabelForWidget(selectedWidget),
      type: NodeType.Widget,
    }))
    .with({ selectedTask: P.not(P.nullish) }, ({ selectedTask }) => ({
      value: selectedTask.group.id,
      icon: '',
      label: selectedTask.name || 'unnamed task',
      type: NodeType.Task,
    }))
    .with({ selectedTimeSource: P.not(P.nullish) }, ({ selectedTimeSource }) => ({
      value: selectedTimeSource,
      icon: '',
      label: TimeSourceLabels[selectedTimeSource],
      type: NodeType.TimeSource,
    }))
    .otherwise(() => undefined);

  return (
    <>
      {isAndRule && <GridItem />}

      <GridItem display="flex" alignItems="center" justifyContent="flex-end">
        <Text
          color={highlighted ? 'purple.500' : 'gray.500'}
          fontWeight={highlighted ? '700' : 'semibold'}
          variant="-2u"
        >
          {displayType}
        </Text>
      </GridItem>

      <GridItem minW={0} colSpan={isAndRule ? 1 : 2}>
        <Grid gridTemplateColumns={{ base: '1fr 40px', md: '1fr 40px' }} gap="2.5">
          <div style={{ minWidth: 0 }}>
            <FormFieldSelect
              options={options}
              widgets={widgets}
              taskTemplates={taskTemplates}
              value={sourceValue}
              onChange={handleSourceChange}
            />
          </div>

          {meatballMenu?.()}
          {!meatballMenu && onDelete && <DeleteButton onClick={onDelete} />}
        </Grid>
      </GridItem>

      {isAndRule && <GridItem />}
      <GridItem />

      <GridItem colSpan={isAndRule ? 1 : 2} as={Stack} direction={{ base: 'column', md: 'row' }} spacing="2.5">
        <Box w="full" maxWidth={{ base: 'unset', md: '180px' }}>
          <ConditionDropdown
            disabled={!selectedWidget && !selectedTask && !selectedTimeSource}
            value={operator}
            onChange={v => !!v && handleOperatorChange(v)}
            widget={selectedWidget}
            task={selectedTask}
            timeSource={selectedTimeSource}
          />
        </Box>

        <HStack w="full">
          <Box
            w="full"
            sx={{
              '.blvd-select .blvd-select__control .blvd-select__multi-value': {
                'borderRadius': '15px',
                'backgroundColor': 'brand.100',

                '.blvd-select__multi-value__label': {
                  px: 2,
                },

                '.blvd-select__ellipsis': {
                  fontSize: 'sm',
                  color: 'gray.700',
                  fontWeight: 'semibold',
                },

                '.blvd-select__multi-value__remove:hover': {
                  bgColor: 'brand.200',
                  borderRadius: 'full',
                },
              },

              '.date-picker > input': {
                maxWidth: 'unset',
              },
            }}
          >
            {!selectedTask && !selectedWidget && !selectedTimeSource && <DisabledInput />}

            {selectedTask && (
              <OperandValueSelect
                options={operandValueOptions}
                value={operandValueOptionsMap[operandValue] ?? null}
                onChange={value => {
                  if (BlvdSelectHelpers.isOptionType<OptionType>(value)) {
                    handleOperandValueChange(value.value);
                  }
                }}
              />
            )}
            {selectedTimeSource && <OperandValuePeriod value={operandValue} onChange={handleOperandValueChange} />}
            {selectedWidget &&
              match(selectedWidget)
                .with({ fieldType: FieldType.Number }, () => (
                  <OperandValueText
                    value={operandValue ?? ''}
                    onChange={e => handleOperandValueChange(e.target.value)}
                    type="number"
                    isDisabled={isOperandValueDisabled}
                  />
                ))
                .with({ fieldType: FieldType.Select }, () =>
                  !isOperandValueDisabled ? (
                    <OperandValueSelect
                      options={operandValueOptions}
                      value={operandValueOptionsMap[operandValue] ?? null}
                      onChange={value => {
                        if (BlvdSelectHelpers.isOptionType<OptionType>(value)) {
                          handleOperandValueChange(value.value);
                        }
                      }}
                    />
                  ) : (
                    <DisabledInput />
                  ),
                )
                .with({ fieldType: FieldType.MultiChoice }, () =>
                  !isOperandValueDisabled ? (
                    <OperandValueSelect
                      value={operandValueOptionsMap[operandValue] ?? null}
                      options={operandValueOptions}
                      onChange={value => {
                        if (BlvdSelectHelpers.isOptionType<OptionType>(value)) {
                          handleOperandValueChange(value.value);
                        }
                      }}
                    />
                  ) : (
                    <DisabledInput />
                  ),
                )
                .with({ fieldType: FieldType.Members }, () =>
                  !isOperandValueDisabled ? (
                    <OperandValueSelect
                      value={operandValueOptionsMap[operandValue] ?? null}
                      options={operandValueOptions}
                      onChange={value => {
                        if (BlvdSelectHelpers.isOptionType<OptionType>(value)) {
                          handleOperandValueChange(value.value);
                        }
                      }}
                    />
                  ) : (
                    <DisabledInput />
                  ),
                )
                .with({ fieldType: FieldType.Date }, () => (
                  <OperandValueDate
                    isDisabled={isOperandValueDisabled}
                    value={operandValue}
                    onChange={value => handleOperandValueChange(value)}
                  />
                ))
                .otherwise(() => (
                  <OperandValueText
                    value={operandValue ?? ''}
                    onChange={e => handleOperandValueChange(e.target.value)}
                    isDisabled={isOperandValueDisabled}
                  />
                ))}
          </Box>
        </HStack>
      </GridItem>

      {(onAddAnd || onAddOr) && (
        <>
          <GridItem />
          {isAndRule && <GridItem />}
          <GridItem colSpan={isAndRule ? 1 : 2}>
            <HStack>
              {onAddAnd && <AddButton onClick={onAddAnd}>And</AddButton>}
              {onAddOr && <AddButton onClick={onAddOr}>Or</AddButton>}
            </HStack>
          </GridItem>
        </>
      )}
    </>
  );
}

const AddButton: React.FC<React.PropsWithChildren<ButtonProps>> = props => (
  <Button
    leftIcon={<Icon icon="plus" size="3" variant="far" color="initial" />}
    onClick={props.onClick}
    variant="outline"
    colorScheme="gray"
    borderColor="gray.300"
    borderWidth="1px"
    backgroundColor="white"
    h={8}
    px={3}
    {...props}
  >
    <Text variant="-2u" fontWeight="normal">
      {props.children}
    </Text>
  </Button>
);

const DeleteButton: React.FC<React.PropsWithChildren<ButtonProps>> = props => (
  <IconButton
    variant="ghost"
    aria-label="close"
    icon={<Icon icon="close" variant="far" size="4" color="gray.500" />}
    onClick={props.onClick}
  />
);

const DisabledInput = () => <Input isDisabled borderColor="gray.300" />;

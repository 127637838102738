import { TemplateStatus } from '@process-street/subgrade/process';
import { Icon, MenuDivider, useMenuContext } from 'components/design/next';
import { ArchiveTemplateButton } from 'features/template/components/template-menu/archive-template-button';
import { CopyShareLinkButton } from 'features/template/components/template-menu/copy-share-link-button';
import { DeleteTemplateButton } from 'features/template/components/template-menu/delete-template-button';
import { DuplicateTemplateButton } from 'features/template/components/template-menu/duplicate-template-button';
import { ManageTemplateTagsButton } from 'features/template/components/template-menu/manage-template-tags-button';
import { MoveTemplateButton } from 'features/template/components/template-menu/move-template-button';
import { TemplateActivityButton } from 'features/template/components/template-menu/template-activity-button';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import * as React from 'react';
import { TemplateMenuItem, useTemplateMenuContext } from '.';

export const PageMenu: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { templateId, view, setCloseOnBlur } = useTemplateMenuContext();
  const pageIsPrivate = useIsPrivateTemplate(templateId);
  const menuContext = useMenuContext();

  return (
    <>
      {children}
      {!pageIsPrivate && (
        <CopyShareLinkButton templateId={templateId}>
          {({ copiedValue }) => (
            <TemplateMenuItem
              icon={
                <Icon
                  icon={copiedValue ? 'check' : 'link'}
                  size="4"
                  variant="far"
                  color={copiedValue ? 'green.500' : 'gray.500'}
                />
              }
            >
              Copy share link
            </TemplateMenuItem>
          )}
        </CopyShareLinkButton>
      )}

      <TemplateActivityButton>
        <TemplateMenuItem icon={<Icon icon="history" size="4" variant="far" color="gray.500" />}>
          Activity
        </TemplateMenuItem>
      </TemplateActivityButton>

      <ManageTemplateTagsButton>
        <TemplateMenuItem icon={<Icon icon="tag" color="gray.500" variant="far" size="4" />}>
          Manage tags
        </TemplateMenuItem>
      </ManageTemplateTagsButton>

      <MenuDivider />

      <MoveTemplateButton onMoved={() => menuContext.onClose()}>
        <TemplateMenuItem icon={<Icon icon="arrow-right" size="4" variant="far" color="gray.500" />}>
          Move
        </TemplateMenuItem>
      </MoveTemplateButton>

      <DuplicateTemplateButton>
        <TemplateMenuItem icon={<Icon icon="copy" size="4" variant="far" color="gray.500" />}>
          Duplicate
        </TemplateMenuItem>
      </DuplicateTemplateButton>

      <ArchiveTemplateButton onArchived={() => menuContext.onClose()}>
        {({ templateStatus, isMutating }) => {
          const icon = isMutating ? (
            <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
          ) : (
            <Icon icon="archive" size="4" variant="far" color="gray.500" />
          );
          return (
            <TemplateMenuItem isDisabled={isMutating} icon={icon}>
              {templateStatus === TemplateStatus.Active ? 'Archive' : 'Unarchive'}
            </TemplateMenuItem>
          );
        }}
      </ArchiveTemplateButton>
      <MenuDivider />

      <DeleteTemplateButton
        onDeleted={() => menuContext.onClose()}
        templateId={templateId}
        view={view}
        disclosure={{ onOpen: () => setCloseOnBlur(false), onClose: () => setCloseOnBlur(true) }}
      >
        {({ isMutating }) => {
          const icon = isMutating ? (
            <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
          ) : (
            <Icon icon="trash-alt" size="4" variant="far" color="red.500" />
          );

          return (
            <TemplateMenuItem isDisabled={isMutating} color="red.500" icon={icon}>
              Delete
            </TemplateMenuItem>
          );
        }}
      </DeleteTemplateButton>
    </>
  );
};

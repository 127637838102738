import React from 'react';
import { useCoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { useCoverIconByTemplateIdQuery } from 'features/cover-icon/query-builder';
import { Template } from '@process-street/subgrade/process';
import { Box, HStack, VStack } from 'components/design/next';
import { TemplateCoverImage } from 'features/cover-image/components/template';
import { TemplateCoverIcon } from 'features/cover-icon/components/template';
import { match, P } from 'ts-pattern';
import { UseQueryResult } from 'react-query';
import { CoverIcon, CoverImage } from '@process-street/subgrade/core';

export type EditProps = { templateId: Template['id'] };

export const Edit = ({ templateId }: EditProps) => {
  const coverIconQuery = useCoverIconByTemplateIdQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );
  const coverImageQuery = useCoverImageByTemplateIdQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );

  return (
    match<[UseQueryResult<CoverIcon | undefined>, UseQueryResult<CoverImage | undefined>]>([
      coverIconQuery,
      coverImageQuery,
    ])
      //   no icon / no cover image
      .with(
        [
          { status: 'success', data: P.nullish },
          { status: 'success', data: P.nullish },
        ],
        () => (
          <HStack spacing="4" py="4" pl="6">
            <TemplateCoverIcon editable templateId={templateId} />
            <TemplateCoverImage editable templateId={templateId} />
          </HStack>
        ),
      )
      //   icon / no cover image
      .with(
        [
          { status: 'success', data: P.not(P.nullish) },
          { status: 'success', data: P.nullish },
        ],
        ([{ data: coverIcon }, _]) => (
          <HStack spacing="5" py="4">
            <TemplateCoverIcon editable icon={coverIcon} templateId={templateId} />
            <TemplateCoverImage editable templateId={templateId} />
          </HStack>
        ),
      )
      // no icon / cover image
      .with(
        [
          { status: 'success', data: P.nullish },
          { status: 'success', data: P.not(P.nullish) },
        ],
        ([_, { data: coverImage }]) => (
          <VStack pl="6" mb="3" spacing="3" align="left" borderColor="transparent">
            <TemplateCoverImage editable coverImage={coverImage} templateId={templateId} ml="-6" />
            <TemplateCoverIcon editable templateId={templateId} />
          </VStack>
        ),
      )
      // icon / cover image
      .with(
        [
          { status: 'success', data: P.not(P.nullish) },
          { status: 'success', data: P.not(P.nullish) },
        ],
        ([{ data: coverIcon }, { data: coverImage }]) => (
          <Box mb="-7" borderColor="transparent" width="100%">
            <TemplateCoverImage editable coverImage={coverImage} templateId={templateId} mb={0} />
            <TemplateCoverIcon editable icon={coverIcon} templateId={templateId} mt="-8" />
          </Box>
        ),
      )
      .otherwise(() => null)
  );
};

import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { MoveToFolderModal } from './modal';

export const MoveToFolderModule = angular
  .module('moveToFolder', [])
  .component(
    'psMoveToFolderModal',
    reactToAngular(MoveToFolderModal, [
      'candidateId',
      'folderId',
      'isOpen',
      'onTemplateMove',
      'onFolderMove',
      'onClose',
      'candidateType',
    ]),
  ).name;

import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { GetWidgetByTaskTemplateIdQuery } from 'app/features/widgets/query-builder';
import { AiGeneratorAnimationService } from 'app/services/ai-generator-animation-service';
import { makeQueryMachine, QueryActor, QueryActorSelectors, RefetchOnMountOnly } from 'app/utils/query-builder';
import { ActorRefFrom, assign, createMachine, sendParent, spawn } from 'xstate';
import { SharedContext } from '../../../shared';

type Context = {
  sharedContext: SharedContext;
  taskTemplate: TaskTemplate;
  widgetsDone: boolean;
  taskTemplateDone: boolean;
  // queries
  widgetsQuery?: QueryActor<GetWidgetByTaskTemplateIdQuery.Response>;
};

type Event =
  | { type: 'ANIMATE' }
  | { type: 'INTERNAL_UPDATE_WIDGET'; widget: Widget }
  | { type: 'INTERNAL_UPDATE_TASK_TEMPLATE'; taskTemplate: TaskTemplate };

export type TaskTemplateGenerationMachine = ReturnType<typeof makeTaskTemplateGenerationMachine>;
export type TaskTemplateGenerationActor = ActorRefFrom<TaskTemplateGenerationMachine>;

export const makeTaskTemplateGenerationMachine = ({
  sharedContext,
  taskTemplate,
}: {
  sharedContext: SharedContext;
  taskTemplate: TaskTemplate;
}) => {
  const { queryClient } = sharedContext;

  return createMachine(
    {
      id: `task-template-generation:${taskTemplate.group.id}`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./task-template-generation-machine.typegen').Typegen0,
      context: () =>
        ({
          sharedContext,
          taskTemplate,
          widgetsQuery: undefined,
          widgetsDone: false,
          taskTemplateDone: false,
        } as Context),
      states: {
        idle: {
          on: {
            ANIMATE: { target: 'fetchingWidgets', actions: ['assignWidgetsQuery'] },
          },
        },
        fetchingWidgets: {
          invoke: {
            id: 'fetchingWidgets',
            src: 'fetchingWidgetsService',
            onDone: {
              target: 'animating',
            },
            onError: {
              target: 'idle',
            },
          },
        },
        animating: {
          invoke: [
            {
              id: 'animateWidgets',
              src: 'animateWidgetsService',
              onDone: { actions: 'assignWidgetsDone' },
              onError: { actions: 'assignWidgetsDone' },
            },
            {
              id: 'animateTaskTemplate',
              src: 'animateTaskTemplateService',
              onDone: { actions: 'assignTaskTemplateDone' },
              onError: { actions: 'assignTaskTemplateDone' },
            },
          ],
          always: {
            target: 'done',
            cond: 'bothAnimationsDone',
          },
          on: {
            INTERNAL_UPDATE_WIDGET: { actions: 'sendParentUpdateWidget' },
            INTERNAL_UPDATE_TASK_TEMPLATE: { actions: 'sendParentUpdateTaskTemplate' },
          },
        },
        done: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        assignWidgetsQuery: assign({
          widgetsQuery: (ctx, _evt) =>
            spawn(
              makeQueryMachine({
                observer: GetWidgetByTaskTemplateIdQuery.makeQueryObserver({
                  queryClient,
                  options: { ...RefetchOnMountOnly, keepPreviousData: true },
                  taskTemplateId: ctx.taskTemplate.id,
                }),
              }),
              { sync: true, name: `widgets-query-${ctx.taskTemplate.id}` },
            ),
        }),
        sendParentUpdateWidget: sendParent((ctx, evt) => ({
          type: 'UPDATE_WIDGET',
          taskTemplateGroupId: ctx.taskTemplate.group.id,
          widget: evt.widget,
        })),
        sendParentUpdateTaskTemplate: sendParent((_ctx, evt) => ({
          type: 'UPDATE_TASK_TEMPLATE',
          taskTemplate: evt.taskTemplate,
        })),
        assignTaskTemplateDone: assign({
          taskTemplateDone: true,
        }),
        assignWidgetsDone: assign({
          widgetsDone: true,
        }),
      },
      services: {
        fetchingWidgetsService: (ctx, _evt) => async () => {
          await queryClient.refetchQueries(
            GetWidgetByTaskTemplateIdQuery.getKey({ taskTemplateId: ctx.taskTemplate.id }),
          );
        },
        animateWidgetsService: ctx => async callback => {
          const widgets = QueryActorSelectors.getQueryData(ctx.widgetsQuery) ?? [];
          const animator = AiGeneratorAnimationService.createWidgetsAnimator(
            { [ctx.taskTemplate.group.id]: widgets },
            {
              timeout: ((callback: (args: void) => void, ms: number) => {
                return setTimeout(callback, ms);
              }) as typeof setTimeout,
              addMissingItems: true,
              update: (widget, _) => {
                callback({ type: 'INTERNAL_UPDATE_WIDGET', widget });
              },
            },
          );

          await animator();
        },
        animateTaskTemplateService: ctx => async send => {
          const animator = AiGeneratorAnimationService.createTaskTemplatesAnimator([ctx.taskTemplate], {
            timeout: ((callback: (args: void) => void, ms: number) => {
              return setTimeout(callback, ms);
            }) as typeof setTimeout,
            update: (taskTemplate, _) => {
              send({ type: 'INTERNAL_UPDATE_TASK_TEMPLATE', taskTemplate });
            },
          });

          await animator();
        },
      },
      guards: {
        bothAnimationsDone: ctx => ctx.taskTemplateDone && ctx.widgetsDone,
      },
    },
  );
};

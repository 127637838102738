import React from 'react';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useMenuContext,
} from 'components/design/next';
import { NewOrganizationModal } from 'features/create-organization/components/new-organization-modal';
import { OrganizationLogo } from 'components/organization-logo/component';
import { isAdmin, isGuest, Organization, User } from '@process-street/subgrade/core';
import { MenuItemNgLink } from '../common';
import { useGetCurrentUserInfoQuery, useGetUserOrganizationsMembershipsQuery } from 'features/user/query-builder';
import { useCanCreateOrganization } from 'hooks/security';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { NgLink } from 'features/app/components/ng-link';
import { GoNativeService } from 'features/go-native/go-native-service';
import { useTopbarContext } from 'directives/ui/topbar/components/context';
import { ElevioUtils } from 'services/interop/elevio-utils';
import { IntercomService } from 'services/interop/intercom-service';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { trace } from 'components/trace';
import { Event } from '@process-street/subgrade/analytics';
import { useSelectedOrganization } from 'hooks/use-selected-organization';

type OrganizationAvatarProps = {
  organization?: Organization;
};

const OrganizationAvatar = ({ organization }: OrganizationAvatarProps) => {
  return (
    <OrganizationLogo organization={organization}>
      <Avatar border="none" name={organization?.name} size="md" />
    </OrganizationLogo>
  );
};

const SelectedOrganizationItem = () => {
  const lastSelectedOrganization = useSelectedOrganization();

  const userQuery = useGetCurrentUserInfoQuery({
    select: data => {
      return {
        organizationMembership: data?.organizationMembership,
        userIsGuest: isGuest(data?.organizationMembership?.role),
        userIsAdmin: isAdmin(data?.organizationMembership?.role),
      };
    },
  });
  const { selectedOrganization, userIsGuest, userIsAdmin } = {
    ...userQuery.data,
    selectedOrganization: lastSelectedOrganization,
  };

  const menuContext = useMenuContext();

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      w="full"
      bgColor="brand.100"
      px="4"
      py="3"
      sx={{
        '&:focus': {
          '& a': { bgColor: 'brand.100' },
        },
      }}
    >
      <Grid w="full" templateColumns="min-content 1fr" columnGap="3" templateRows="repeat(2, 1fr)" alignItems="center">
        <OrganizationAvatar organization={selectedOrganization} />
        <Text textAlign="left" color="brand.400" mb="0" variant="1" fontWeight="medium" noOfLines={1}>
          {selectedOrganization?.name}
        </Text>

        <GridItem rowStart={2} colStart={2} justifySelf="flex-start">
          {!userIsGuest && (
            <ButtonGroup spacing="2.5" variant="tertiary" size="sm">
              <Button
                role="menuitem"
                tabIndex={0}
                as={NgLink}
                onClick={menuContext.onClose}
                leftIcon={<Icon icon="cog" variant="far" size="3" color="gray.600" />}
                _hover={{ textDecor: 'none', bgColor: 'gray.100' }}
                fontSize="xs"
                to="organizationManage.tab"
                params={{ tab: 'settings' }}
                options={{ inherit: false }}
              >
                Settings
              </Button>
              {userIsAdmin && (
                <Button
                  role="menuitem"
                  as={NgLink}
                  onClick={menuContext.onClose}
                  tabIndex={1}
                  leftIcon={<Icon icon="user" variant="far" size="3" color="gray.600" />}
                  _hover={{ textDecor: 'none', bgColor: 'gray.100' }}
                  fontSize="xs"
                  to="organizationManage"
                  options={{ inherit: false }}
                >
                  Invite
                </Button>
              )}
            </ButtonGroup>
          )}
        </GridItem>
      </Grid>
    </Flex>
  );
};
export const AccountMenu = () => {
  const [newOrganizationModalOpen, setNewOrganizationModalOpen] = React.useState(false);

  const ctx = useTopbarContext();
  const lastSelectedOrganization = useSelectedOrganization();

  const userQuery = useGetCurrentUserInfoQuery({
    select: data => {
      return {
        currentUser: data?.user,
        userIsGuest: isGuest(data?.organizationMembership?.role),
        userIsAnonymous: Boolean(isAnonymousUser(data?.user)),
        userIsDeveloper: data?.user.developer,
      };
    },
  });

  const currentUserRQ = userQuery.data?.currentUser;
  const organizationMembershipQuery = useGetUserOrganizationsMembershipsQuery(currentUserRQ?.id!, {
    enabled: Boolean(currentUserRQ?.id),
    select: data =>
      data?.map(membership => membership.organization).filter(org => org.id !== lastSelectedOrganization?.id),
  });

  const userCanCreateOrganizationRQ = useCanCreateOrganization();

  const {
    selectedOrganization,
    currentUser,
    organizations,
    showOrganizations,
    showNewOrganizationButton,
    switchOrganization,
  } = {
    selectedOrganization: lastSelectedOrganization,
    currentUser: currentUserRQ,
    organizations: (organizationMembershipQuery.data || []) as Organization[],
    showOrganizations: !GoNativeService.isGoNativeApp(),
    showNewOrganizationButton: !GoNativeService.isGoNativeApp() && userCanCreateOrganizationRQ.data,
    switchOrganization: ctx.switchOrganization,
  };

  const getUserProfilePicUrl = (user?: User) => {
    if (user?.avatarUrl) {
      return user.avatarUrl;
    }
    if (user?.avatarFile) {
      return `https://aang.s3.amazonaws.com/${user.avatarFile.id}-64.jpg`;
    }
    return '';
  };

  const listRef = React.useRef<HTMLDivElement>(null);

  const resetScrollOnList = () => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  };

  const logger = trace({ name: 'account-menu' });
  const onShowSupport = () => {
    AnalyticsService.trackEvent(Event.SUPPORT_LINK_CLICKED, { location: 'top bar menu' });
    logger.info('support link clicked');
    IntercomService.show();
  };

  return (
    <>
      <Menu autoSelect={false} onClose={resetScrollOnList}>
        <MenuButton
          aria-label="open user settings"
          as={Button}
          variant="ghost"
          color="gray.500"
          _active={{ boxShadow: 'none', bgColor: 'gray.100' }}
          _hover={{ color: 'brand.400' }}
          borderRadius="0"
          p="0"
          justifyContent="center"
        >
          <Avatar name={currentUser?.username} src={getUserProfilePicUrl(currentUser)} size="sm" border="none" />
        </MenuButton>

        <Portal>
          <MenuList color="gray.600" maxW="64" right={0} aria-label="user settings" zIndex="popover">
            <Text color="gray.500" letterSpacing="normal" fontSize="xs" pb="2.5" noOfLines={1} align="center">
              {currentUser?.email}
            </Text>
            <Box ref={listRef} overflowY="scroll" maxH="75">
              {selectedOrganization ? <SelectedOrganizationItem /> : null}

              {showOrganizations &&
                organizations?.map((organization: Organization) => (
                  <MenuItem
                    key={organization.id}
                    onClick={() => switchOrganization(organization.id)}
                    icon={<OrganizationAvatar organization={organization} />}
                    iconSpacing={2.5}
                  >
                    {organization.name}
                  </MenuItem>
                ))}
              {showNewOrganizationButton && (
                <MenuItem
                  fontSize="sm"
                  _hover={{
                    '& svg': {
                      color: 'brand.400',
                    },
                  }}
                  icon={
                    <Avatar
                      icon={<Icon icon="plus" variant="far" size="5" color="gray.400" />}
                      size="md"
                      border="none"
                      bg="gray.100"
                    />
                  }
                  iconSpacing={3}
                  onClick={() => setNewOrganizationModalOpen(true)}
                >
                  Add new organization
                </MenuItem>
              )}
            </Box>
            <MenuItem
              fontSize="sm"
              as={NgLink}
              variant="noline"
              _hover={{
                '& svg': {
                  color: 'brand.400',
                },
              }}
              iconSpacing={3}
              icon={
                <Avatar
                  icon={<Icon icon="sitemap" variant="fas" size="5" color="gray.400" />}
                  size="md"
                  border="none"
                  bg="gray.100"
                />
              }
              to="userManage.tab"
              params={{ id: 'me', tab: 'organizations' }}
              options={{ inherit: false }}
            >
              <Text fontSize="sm">
                Manage organizations
                <Text as="span" display="inline-flex" pl={0.5} fontSize="inherited" color="gray.400">
                  {` (${organizations.length + 1})`}
                </Text>
              </Text>
            </MenuItem>

            <MenuDivider />

            {currentUser?.developer ? (
              <MenuItemNgLink
                to="developer"
                options={{ inherit: false }}
                icon={<Icon icon="code" variant="far" size="4" color="gray.600" />}
              >
                Developer Console
              </MenuItemNgLink>
            ) : null}

            <MenuItemNgLink
              to="userManage"
              params={{ id: 'me' }}
              options={{ inherit: false }}
              icon={<Icon icon="user" variant="far" size="4" color="gray.600" />}
            >
              My Profile
            </MenuItemNgLink>
            <MenuItem
              onClick={ElevioUtils.openElevioArticles}
              icon={<Icon icon="info-circle" variant="far" size="4" color="gray.600" />}
            >
              User Guide
            </MenuItem>
            <MenuItem
              onClick={onShowSupport}
              icon={<Icon icon="comment-alt" variant="far" size="4" color="gray.600" />}
            >
              Contact Support
            </MenuItem>

            <MenuItemNgLink to="logout" icon={<Icon icon="sign-out" variant="far" size="4" color="gray.600" />}>
              Logout
            </MenuItemNgLink>
          </MenuList>
        </Portal>
      </Menu>
      <NewOrganizationModal isOpen={newOrganizationModalOpen} onClose={() => setNewOrganizationModalOpen(false)} />
    </>
  );
};

import { isNotIdRef, Organization, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { TaskTemplatePermit } from '@process-street/subgrade/permission';
import {
  FieldType,
  FormFieldWidget,
  isFormFieldWidget,
  TaskTemplate,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { StringUtils, UserUtils } from '@process-street/subgrade/util';
import {
  isSystemGroupUserOm,
  isTaskPermitDisplayableSystemGroupUserOm,
  sortByVisibility,
} from '@process-street/subgrade/util/membership-utils';
import { useInjector } from 'components/injection-provider';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { GetTaskTemplatePermitsQuery } from 'features/template-revisions/query-builder';
import { GetTaskTemplateRulesQuery } from 'features/template-revisions/query-builder/get-task-template-rules-query';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { isEqual } from 'lodash';
import _groupBy from 'lodash/groupBy';
import React from 'react';

const DEFAULT_SYSTEM_PERMIT_COUNT = 4;

export function useGetPermissionPickerLabel({
  taskTemplates,
  templateRevisionId,
  organizationId,
}: {
  taskTemplates: TaskTemplate[];
  templateRevisionId: TemplateRevision['id'];
  organizationId: Organization['id'];
}) {
  const { TaskPermissionRuleService } = useInjector('TaskPermissionRuleService');

  const rulesQuery = GetTaskTemplateRulesQuery.useQuery({ templateRevisionId });

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId, {
    select: widgets =>
      widgets.filter(
        (widget): widget is FormFieldWidget =>
          isFormFieldWidget(widget) && (widget.fieldType === FieldType.Email || widget.fieldType === FieldType.Members),
      ),
  });

  const allOrganizationMembershipsQuery = useGetAllOrganizationMembershipsQuery({
    organizationId,
  });

  const taskTemplatesIdsSet = new Set(taskTemplates.map(t => t.id));

  const { data: permits } = GetTaskTemplatePermitsQuery.useQuery(
    {
      templateRevisionId,
    },
    {
      select: data => data.permits.filter(permit => taskTemplatesIdsSet.has(permit.taskTemplate.id)),
    },
  );

  const permitsAreEqual = React.useMemo(() => {
    const firstPermitSet = new Set(permits?.filter(p => p.taskRead).map(p => p.organizationMembership.id) || []);
    const firstRuleSet = new Set(
      rulesQuery.data
        ?.filter(
          rule => rule.taskRead && taskTemplates[0] && rule.targetTaskTemplateGroup.id === taskTemplates[0].group.id,
        )
        .map(rule => (rule.sourceFormFieldWidgetGroup ? rule.sourceFormFieldWidgetGroup.id : rule.sourceType)),
    );
    return taskTemplates.every(taskTemplate => {
      const taskTemplatePermitSet = new Set(
        permits
          ?.filter(permit => permit.taskTemplate.id === taskTemplate.id && permit.taskRead)
          .map(p => p.organizationMembership.id) || [],
      );
      const taskTemplateRuleSet = new Set(
        rulesQuery.data
          ?.filter(rule => rule.taskRead && rule.targetTaskTemplateGroup.id === taskTemplate.group.id)
          .map(rule => (rule.sourceFormFieldWidgetGroup ? rule.sourceFormFieldWidgetGroup.id : rule.sourceType)),
      );
      return isEqual(firstPermitSet, taskTemplatePermitSet) && isEqual(firstRuleSet, taskTemplateRuleSet);
    });
  }, [permits, rulesQuery.data, taskTemplates]);

  const allGroupUsers = React.useMemo(() => {
    if (allOrganizationMembershipsQuery.data) {
      return allOrganizationMembershipsQuery.data.filter(isSystemGroupUserOm);
    }
    return [];
  }, [allOrganizationMembershipsQuery.data]);

  const permitSet = React.useMemo(() => {
    if (permits) {
      return new Set(permits.filter(permit => permit.taskRead).map(permit => permit.organizationMembership.id));
    }
  }, [permits]);

  const visibleGroupUsers = React.useMemo(() => {
    return allGroupUsers.filter(isTaskPermitDisplayableSystemGroupUserOm).sort(sortByVisibility);
  }, [allGroupUsers]);

  const visibleGroupUsersWithPermits = React.useMemo(() => {
    if (permitSet) {
      return visibleGroupUsers.filter(user => permitSet.has(user.id));
    }
    return [];
  }, [permitSet, visibleGroupUsers]);

  const nonPredefinedGroupMembershipsWithPermits = React.useMemo(() => {
    if (permits && allGroupUsers && allOrganizationMembershipsQuery.data) {
      const allGroupUserIdsSet = new Set(allGroupUsers.map(user => user.id));
      const nonGroupMemberships = allOrganizationMembershipsQuery.data.filter(
        membership => !allGroupUserIdsSet.has(membership.id),
      );
      const filteredPermits = permits.filter(permit => permit.taskRead || permit.taskUpdate);
      const nonGroupMembershipsWithPermitsIdsSet = new Set(
        filteredPermits.map(permit => permit.organizationMembership.id),
      );
      const membershipsWithPermissions = nonGroupMemberships.filter(membership =>
        nonGroupMembershipsWithPermitsIdsSet.has(membership.id),
      );

      return membershipsWithPermissions;
    }
    return [];
  }, [permits, allGroupUsers, allOrganizationMembershipsQuery.data]);

  const predefinedGroupMembershipsWithPermits = React.useMemo(() => {
    if (permits && visibleGroupUsersWithPermits) {
      const membershipsWithPermissions = visibleGroupUsersWithPermits.filter(membership => {
        const filteredPermits = permits.filter(permit => permit.organizationMembership.id === membership.id);
        return filteredPermits.every(permit => permit.taskRead);
      });

      return membershipsWithPermissions;
    }
    return [];
  }, [permits, visibleGroupUsersWithPermits]);

  const membershipNames = React.useMemo(
    () => nonPredefinedGroupMembershipsWithPermits.map(x => x.user.username),
    [nonPredefinedGroupMembershipsWithPermits],
  );

  const taskPermissionRulesByTaskTemplateGroupId = React.useMemo(() => {
    if (rulesQuery.data) {
      return _groupBy(rulesQuery.data, rule => rule.targetTaskTemplateGroup.id);
    }
    return {};
  }, [rulesQuery.data]);

  const activePredefinedGroups = React.useMemo(
    () => predefinedGroupMembershipsWithPermits.map(x => UserUtils.getLabel(x.user, false)),
    [predefinedGroupMembershipsWithPermits],
  );

  return React.useMemo(() => {
    if (taskTemplates?.length === 1 || permitsAreEqual) {
      const taskTemplate = taskTemplates[0];

      const { systemPermitsCount, nonSystemPermitsCount, totalSystemPermitsCount } = getTaskTemplatePermitsCount({
        permits,
        permitSet,
        allGroupUsers,
      });

      const taskPermissionRules = taskPermissionRulesByTaskTemplateGroupId[taskTemplate.group.id];
      const uniqueRules = TaskPermissionRuleService.extractUniqueTypedRules(taskPermissionRules);

      const hasTaskPermissionRules = uniqueRules?.length > 0;

      const widgets = widgetsQuery.data ?? [];
      const ruleUsernames = uniqueRules?.map(rule => TaskPermissionRuleService.getRuleTitleLike(rule, widgets)) ?? [];

      const hasDefaultSystemPermits =
        (systemPermitsCount === DEFAULT_SYSTEM_PERMIT_COUNT && totalSystemPermitsCount === 0) ||
        systemPermitsCount === totalSystemPermitsCount;

      const hasDirectPermits = nonSystemPermitsCount !== 0;

      const permitsAreDefault = hasDefaultSystemPermits && !hasDirectPermits && !hasTaskPermissionRules;

      const usernames = [...membershipNames, ...ruleUsernames, ...activePredefinedGroups];
      const predefinedGroupNames = visibleGroupUsers.map(x => UserUtils.getLabel(x.user, false));

      const label = permitsAreDefault
        ? [
            StringUtils.getSentenceList({
              items: predefinedGroupNames,
              prefix: 'Visible to ',
              max: 3,
            }),
            '(default)',
          ].join(' ')
        : StringUtils.getSentenceList({
            items: usernames,
            prefix: 'Visible to ',
            max: 3,
          });

      return {
        label,
        permitsAreDefault,
      };
    } else {
      // Different permits among taskTemplates
      const allDefaultSystemPermits = permits
        ? taskTemplates.every(taskTemplate => {
            const systemPermitsCount = permits.filter(
              permit =>
                permit.taskTemplate.id === taskTemplate.id &&
                isNotIdRef(permit.organizationMembership) &&
                isSystemGroupUserOm(permit.organizationMembership),
            ).length;
            const totalSystemPermitsCount = permits.filter(p => p.taskTemplate.id === taskTemplate.id).length;
            return (
              (systemPermitsCount === DEFAULT_SYSTEM_PERMIT_COUNT && totalSystemPermitsCount === 0) ||
              systemPermitsCount === totalSystemPermitsCount
            );
          })
        : false;

      return {
        label: undefined,
        permitsAreDefault: allDefaultSystemPermits,
      };
    }
  }, [
    taskTemplates,
    permitsAreEqual,
    permits,
    permitSet,
    allGroupUsers,
    widgetsQuery.data,
    taskPermissionRulesByTaskTemplateGroupId,
    TaskPermissionRuleService,
    activePredefinedGroups,
    membershipNames,
    visibleGroupUsers,
  ]);
}

function getTaskTemplatePermitsCount({
  permits,
  permitSet,
  allGroupUsers,
}: {
  permits?: TaskTemplatePermit[];
  permitSet: Set<string> | undefined;
  allGroupUsers: OrganizationMembershipWithUser[];
}) {
  if (permits && permitSet && allGroupUsers) {
    const allGroupUserIdsSet = new Set(allGroupUsers.map(user => user.id));

    const systemGroupPermits = permits.filter(permit => allGroupUserIdsSet.has(permit.organizationMembership.id));

    const noSystemGroupPermits = permits.filter(permit => !allGroupUserIdsSet.has(permit.organizationMembership.id));

    const systemPermitsCount = systemGroupPermits.length
      ? systemGroupPermits.filter(permit => permit.taskRead).length
      : DEFAULT_SYSTEM_PERMIT_COUNT;

    const nonSystemPermitsCount = noSystemGroupPermits.filter(permit => permit.taskRead).length;

    return {
      systemPermitsCount,
      nonSystemPermitsCount,
      totalSystemPermitsCount: systemGroupPermits.length,
    };
  }

  return { systemPermitsCount: 0, nonSystemPermitsCount: 0, totalSystemPermitsCount: 0 };
}

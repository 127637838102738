import angular from 'angular';
import { connectController } from 'reducers/util';
import { SessionSelector } from 'reducers/session/session.selectors';
import { ChecklistTaskAssignmentSelector } from 'components/checklist-task-assignment/store/checklist-task-assignment.selectors';
import { createSelector } from 'reselect';
import { ApprovalSelector } from 'components/approvals/store/approval.selectors';
import { TaskStatusToggleSource } from '../TaskStatusToggleSource';
import templateUrl from './task-list-item.component.html';
import './task-list-item.scss';
import { DateFormat, DateUtils, noop } from '@process-street/subgrade/util';
import { match } from 'ts-pattern';
import { QueryObserver } from 'react-query';
import { queryClient } from 'components/react-root';
import { SolutionInstancesByTemplateIdQuery } from 'features/automations/query-builder';
import { GetConsolidatedTaskPermitsQuery } from 'features/checklist-revisions/query-builder';
import { DEFAULT_TIME_ZONE } from '@process-street/subgrade/core/date-context';

angular.module('frontStreetApp.directives').component('psTaskListItem', {
  bindings: {
    index: '<', // Index of the item
    taskTemplate: '<',
    templateId: '<',
    templateRevision: '<',
    actionable: '<',
    task: '<',
    progress: '<',
    taskStats: '<',

    assigneesMap: '<',
    userCanManageTasks: '<',
    userCanUpdateTemplate: '<',

    onToggleWidgets: '&',
    onToggleStatus: '&',
    userAnonymous: '<',
  },
  require: {
    taskListCtrl: '^psTaskList',
  },
  templateUrl,
  controller(
    $element,
    $ngRedux,
    $q,
    ChecklistTaskAssignmentActions,
    OrganizationMembershipActions,
    TaskListService,
    TaskTemplateService,
    InboxService,
    UserService,
    $scope,
  ) {
    const ctrl = this;

    ctrl.readOnly = false;
    ctrl.getOverdueClassName = InboxService.getOverdueClassName;

    ctrl.$onInit = function () {
      $element.addClass('task-list-item--wrapper');
      const mapStateToThis = () => state => {
        return createSelector(
          [
            SessionSelector.getSelectedOrganizationId,
            ChecklistTaskAssignmentSelector.getAllByTaskIdWithOrganizationMembershipAndUser(this.task.id),
            ApprovalSelector.isTaskLocked(this.task.checklistRevision.id, this.task.id),
          ],
          (selectedOrganizationId, assignments, taskLocked) => ({
            selectedOrganizationId,
            assignments,
            taskLocked,
          }),
        )(state);
      };

      const mapDispatchToThis = () => ({
        getAllOrgMembershipByOrgId: OrganizationMembershipActions.getAllOrgMembershipByOrgId,
        getAllAssignmentsByChecklistRevisionId: ChecklistTaskAssignmentActions.getAllByChecklistRevisionId,
      });

      connectController($ngRedux, mapStateToThis, mapDispatchToThis, ctrl.shouldChange)(ctrl);

      this.actions.getAllOrgMembershipByOrgId(this.state.selectedOrganizationId);
      this.actions.getAllAssignmentsByChecklistRevisionId(this.task.checklistRevision.id);

      ctrl.subscribeToAutomations();

      ctrl.setIsReadOnly();
    };

    ctrl.$onDestroy = () => {
      ctrl.unsubscribeFromAutomations?.();
    };

    ctrl.shouldChange = changes => changes.task && changes.task.currentValue;

    ctrl.shouldShowTaskAutomationsIndicator = () => ctrl.taskIsAutomated && ctrl.userCanUpdateTemplate;

    ctrl.shouldShowAutomationsFlare = () => !ctrl.userAnonymous && ctrl.shouldShowTaskAutomationsIndicator();

    ctrl.shouldShowDueDate = () => !ctrl.isHeading(ctrl.taskTemplate) && ctrl.task?.dueDate;

    ctrl.shouldShowAssignments = () => !ctrl.isHeading(ctrl.taskTemplate) && ctrl.state.assignments.length;

    ctrl.toggleStatus = function (event) {
      event.stopPropagation();
      ctrl.onToggleStatus({ taskTemplate: ctrl.taskTemplate, source: TaskStatusToggleSource.TaskListItem });
    };

    ctrl.shouldShowInvalidFieldsCount = function () {
      return ctrl.task && ctrl.task._statusToggleRequested && ctrl.taskStats && ctrl.taskStats.invalidFieldsCount > 0;
    };

    ctrl.toggleWidgets = function () {
      ctrl.onToggleWidgets({ task: ctrl.task });
    };

    ctrl.isHeading = TaskTemplateService.isHeading;

    ctrl.isTaskDue = TaskListService.isTaskDue;

    ctrl.getDoodadsCount = function () {
      return ctrl.taskStats && (ctrl.taskStats.commentsCount || 0) + (ctrl.taskStats.attachmentsCount || 0);
    };

    ctrl.shouldShowDoodadsCount = function () {
      return !!ctrl.getDoodadsCount();
    };

    /**
     * Prevents the the task selection from changing when the assignment photo is clicked.
     *
     * @param event
     */
    ctrl.clickAssignment = function (event) {
      event.originalEvent.fromAssignmentClick = true;
    };

    ctrl.isApprovalTaskTemplate = TaskTemplateService.isApproval;

    ctrl.currentUser = UserService.getCurrentUser();

    // $onChanges wouldn't detect task due date field change
    // we're caching the formatted date here
    let lastTaskDueDate = undefined;

    ctrl.$doCheck = function setFormattedDueDateIfTaskDueDateChanged() {
      const dueDate = ctrl.task.dueDate ? ctrl.task.dueDate : undefined;

      if (lastTaskDueDate === dueDate) {
        return;
      }

      lastTaskDueDate = dueDate;
      const timeZone = ctrl.currentUser.timeZone || DEFAULT_TIME_ZONE;
      ctrl.taskDueDate = DateUtils.formatDateTime(dueDate, DateFormat.DateMonthDay, timeZone);
    };

    ctrl.taskIsAutomated = false;
    ctrl.templateSolutionInstances = [];

    ctrl.subscribeToAutomations = () => {
      if (ctrl.userAnonymous || !ctrl.templateId) return;

      const { templateId } = ctrl;
      const observer = new QueryObserver(queryClient, {
        queryKey: SolutionInstancesByTemplateIdQuery.getKey({ templateId }),
        queryFn: () => SolutionInstancesByTemplateIdQuery.queryFn({ templateId }),
        staleTime: 1000,
      });

      ctrl.unsubscribeFromAutomations = observer.subscribe(result => {
        match(result)
          .with({ status: 'success' }, () => {
            $scope.$evalAsync(() => {
              const templateSolutionInstances = result.data ?? [];

              ctrl.templateSolutionInstances = templateSolutionInstances;

              ctrl.taskIsAutomated = templateSolutionInstances
                .filter(i => i.configured && i.enabled)
                .some(i => i.taskTemplateGroupId && i.taskTemplateGroupId === ctrl.taskTemplate?.group.id);
            });
          })
          .otherwise(noop);
      });
    };

    ctrl.setIsReadOnly = () => {
      const params = {
        checklistRevisionId: this.task.checklistRevision.id,
      };

      return $q((resolve, reject) =>
        queryClient
          .fetchQuery(GetConsolidatedTaskPermitsQuery.getKey(params), () =>
            GetConsolidatedTaskPermitsQuery.queryFn(params),
          )
          .then(tasksPermits => resolve(tasksPermits))
          .catch(e => reject(e)),
      ).then(tasksPermits => {
        const taskPermits = tasksPermits.find(t => t.taskId === this.task.id);
        ctrl.readOnly = !taskPermits?.taskPermissionMap.canUpdate;
      });
    };
  },
});

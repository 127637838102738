import * as React from 'react';

import { Reorder } from 'framer-motion';
import { Box, VStack } from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import { useStateParam } from 'app/hooks/use-state-param';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { TaskListItem, TaskListItemProps } from '../task-list-item';
import { DueDateRuleDefinition, TaskTemplate, TemplateTaskAssignment } from '@process-street/subgrade/process';
import { useDebouncedCallback } from 'use-debounce';
import { TaskListNewButton } from '../task-list-new-button';
import { useEvent } from 'react-use';
import { MotionWrapper } from 'app/components/design/next';
import { ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { SelectedTemplateTriggersEdit } from 'app/pages/templates/_id/components/selected-template-triggers/selected-template-triggers-edit';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _isEqual from 'lodash/isEqual';
import { TaskListCoverImage } from '../task-list-cover-image';
import { useAiGenerationSlice } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/store';
import { AiWorkflowGenerationActorSelector } from 'app/pages/forms/_id/edit/ai-workflow-generation-machine';
import { TaskTemplateListActorSelectors } from './task-template-list-machine';
import { Option } from 'space-monad';
import _map from 'lodash/fp/map';
import { MuidUtils, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { BulkActionsDrawer } from './components/bulk-actions-drawer';
import { useTaskTemplateListActorRef } from '../../hooks/use-task-templates-list-actor';
import { isTemplateRevisionEqual } from '../../helpers/is-template-revision-equal';
import { Dictionary } from 'lodash';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'app/pages/forms/_id/shared';
import { useAddTaskVisibility } from '../../hooks';

export const TaskList = () => {
  const groupId = useStateParam({ key: 'groupId' });
  const templateId = useStateParam({ key: 'id' });

  const editorActor = useFormEditorPageActorRef();
  const taskTemplateListActor = useTaskTemplateListActorRef();

  const aiWorkflowGenerationActor = useSelector(
    editorActor,
    FormEditorPageActorSelectors.getAiWorkflowGeneratorActorRef,
  );
  const templateRevision = useSelector(
    editorActor,
    FormEditorPageActorSelectors.getTemplateRevision,
    isTemplateRevisionEqual,
  );
  const template = useSelector(editorActor, FormEditorPageActorSelectors.getTemplate);
  const isReordering = useSelector(editorActor, FormEditorPageActorSelectors.isReordering);
  const isCreatingTaskTemplate = useSelector(editorActor, FormEditorPageActorSelectors.isCreatingTaskTemplate);
  const { handleOnScroll, addTaskButtonStyles, menuButtonRef, scrollContainerRef } = useAddTaskVisibility();
  const isTaskAnimatingMap = useSelector(
    aiWorkflowGenerationActor,
    AiWorkflowGenerationActorSelector.getIsTaskAnimatingMap,
    _isEqual,
  );

  const taskTemplates = useSelector(editorActor, FormEditorPageActorSelectors.getTaskTemplates);

  const taskAssignments = useSelector(editorActor, FormEditorPageActorSelectors.getTaskAssignments);
  const taskAssignmentsMap = React.useMemo(
    () => _groupBy(taskAssignments, assignment => assignment.taskTemplate.id),
    [taskAssignments],
  );

  const organizationMemberships = useSelector(
    editorActor,
    FormEditorPageActorSelectors.getOrganizationMemberships,
    _isEqual,
  );
  const organizationMembershipsMap = React.useMemo(
    () => _keyBy(organizationMemberships, om => om.id),
    [organizationMemberships],
  );

  const taskAssignmentRules = useSelector(editorActor, FormEditorPageActorSelectors.getTaskAssignmentRules, _isEqual);
  const taskAssignmentRulesMap = React.useMemo(
    () => _groupBy(taskAssignmentRules, assignment => assignment.targetTaskTemplateGroup.id),
    [taskAssignmentRules],
  );

  const dueDateRules = useSelector(editorActor, FormEditorPageActorSelectors.getDueDateRules, _isEqual);
  const dueDateRulesMap = React.useMemo(() => {
    return _keyBy(dueDateRules, ruleDefinition => ruleDefinition.targetTaskTemplateGroup?.id ?? '');
  }, [dueDateRules]);
  const checklistRules = useSelector(editorActor, FormEditorPageActorSelectors.getRules, _isEqual);

  const isTaskTemplateAssociatedWithRules = React.useMemo(
    () => ConditionalLogicCommonUtils.makeTaskTemplateHasAssociatedRule(checklistRules),
    [checklistRules],
  );

  const lastTaskIndex = taskTemplates.length - 1;
  const { isGenerating } = useAiGenerationSlice();

  const handleTaskTemplateChange = useDebouncedCallback(
    (taskTemplateId: TaskTemplate['id'], taskTemplate: Partial<TaskTemplate>) => {
      editorActor.send({ type: 'UPDATE_TASK_TEMPLATE', taskTemplate, taskTemplateId });
    },
    500,
  );

  const handleTaskDelete = React.useCallback(
    (taskTemplateId: TaskTemplate['id']) => {
      editorActor.send({ type: 'DELETE_TASK_TEMPLATE', taskTemplateId });
    },
    [editorActor],
  );

  const handleAddTask = React.useCallback(
    (at?: number, taskTemplate?: Partial<TaskTemplate>) => {
      if (isCreatingTaskTemplate) return;

      const newTaskTemplate = { ...taskTemplate, id: MuidUtils.randomMuid(), group: { id: MuidUtils.randomMuid() } };

      editorActor.send({ type: 'CREATE_TASK_TEMPLATE', at, taskTemplate: newTaskTemplate });
      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate: newTaskTemplate,
        metaKey: false,
        ctrlKey: false,
        shiftKey: false,
      });
    },
    [editorActor, taskTemplateListActor, isCreatingTaskTemplate],
  );

  const handleTaskTemplateSelect = React.useCallback(
    (taskTemplate: TaskTemplate, event: React.MouseEvent) => {
      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate,
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
      });
    },
    [taskTemplateListActor],
  );

  const handleNavigateBetweenTasks = React.useCallback(
    (toStepNumber: number, event: React.KeyboardEvent<HTMLInputElement>) => {
      if (toStepNumber > taskTemplates.length || toStepNumber < 0) {
        return;
      }
      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate: taskTemplates[toStepNumber - 1],
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
      });
    },
    [taskTemplateListActor, taskTemplates],
  );
  // we send MOUSE_UP on these two window events because you can move the mouse off the drag icon while the mouse is clicked
  // and still be dragging. The machine will only respond to this event while in the dragging state.
  useEvent('mouseup', () => {
    if (isReordering) {
      editorActor.send('MOUSE_UP');
    }
  });

  useEvent('touchend', () => {
    if (isReordering) {
      editorActor.send('MOUSE_UP');
    }
  });

  if (!templateId || !templateRevision || !template) return null;

  return (
    <VStack
      w="full"
      position="relative"
      overflow="auto"
      maxH={`calc(100vh - (${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT})))`}
      onScroll={handleOnScroll}
      ref={scrollContainerRef}
    >
      <TaskListCoverImage templateId={templateId} />
      <VStack
        w="full"
        spacing={0}
        px={6}
        py={4}
        as={Reorder.Group}
        axis="y"
        values={taskTemplates}
        onReorder={(reorderedTaskTemplates: TaskTemplate[]) => {
          editorActor.send({ type: 'REORDER_TASK_TEMPLATES', taskTemplates: reorderedTaskTemplates });
        }}
      >
        <SelectedTemplateTriggersEdit
          stackProps={{
            bg: 'white',
            borderColor: 'gray.200',
            borderStyle: 'solid',
            borderWidth: 'thin',
            borderRadius: 'full',
            variant: 'ghost',
            py: 0.5,
            px: 0.5,
            h: 'auto',
            spacing: 1,
          }}
          automationItemProps={{
            border: 'none',
            borderRadius: 'full',
            variant: 'ghost',
          }}
          plusButtonProps={{
            variant: 'ghost',
            borderRadius: 'full',
            bgColor: 'white',
            colorScheme: 'gray',
          }}
          menuButtonProps={{
            border: 'none',
            fontSize: 'md',
            borderRadius: 'full',
            _before: {
              content: '"("',
              mt: '-1px',
            },
            _after: {
              content: '")"',
              mt: '-1px',
            },
            color: 'gray.600',
            fontWeight: 'semibold',
            minW: '11',
            letterSpacing: '0.1px',
          }}
          emptyButtonProps={{
            variant: 'ghost',
            borderRadius: 'full',
          }}
        />

        {taskTemplates.map((taskTemplate, index) => {
          return (
            <TaskListItemWrapper
              key={taskTemplate.id}
              dueDateRulesMap={dueDateRulesMap}
              taskAssignmentsMap={taskAssignmentsMap}
              organizationMembershipsMap={organizationMembershipsMap}
              taskAssignmentRulesMap={taskAssignmentRulesMap}
              stepNumber={index + 1}
              taskTemplate={taskTemplate}
              templateId={templateId}
              templateRevision={templateRevision}
              isGenerating={isGenerating}
              isAnimating={isTaskAnimatingMap[taskTemplate.group.id]?.isAnimating}
              isLast={index === lastTaskIndex}
              isTaskTemplateAssociatedWithRules={isTaskTemplateAssociatedWithRules}
              onChange={handleTaskTemplateChange}
              onDelete={handleTaskDelete}
              onAddNewTask={handleAddTask}
              onSelect={handleTaskTemplateSelect}
              onNavigateBetweenTasks={handleNavigateBetweenTasks}
            />
          );
        })}

        <MotionWrapper
          initial={{ opacity: 0, y: 10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              // Animate once all the task list items have completed their animation
              delay: 0.08 * (Math.min(taskTemplates.length, 10) + 1),
            },
          }}
        >
          <Box w="full">
            <TaskListNewButton
              taskTemplates={taskTemplates}
              groupId={groupId}
              onAddTask={handleAddTask}
              ref={menuButtonRef}
              sx={addTaskButtonStyles}
            />
          </Box>
        </MotionWrapper>
      </VStack>

      <BulkActionsDrawer templateRevision={templateRevision} template={template} />
    </VStack>
  );
};

type TaskListItemWrapperProps = Omit<
  TaskListItemProps,
  'assignmentRules' | 'organizationMemberships' | 'isSelected' | 'isMultiSelecting'
> & {
  dueDateRulesMap: Dictionary<DueDateRuleDefinition>;
  taskAssignmentsMap: Dictionary<TemplateTaskAssignment[]>;
  organizationMembershipsMap: Dictionary<OrganizationMembershipWithUser>;
  taskAssignmentRulesMap: Dictionary<TaskAssignmentRule[]>;
};

const TaskListItemWrapper = React.memo(function TaskListItemWrapper({
  dueDateRulesMap,
  taskAssignmentsMap,
  organizationMembershipsMap,
  taskAssignmentRulesMap,
  ...props
}: TaskListItemWrapperProps) {
  const taskTemplateListActor = useTaskTemplateListActorRef();
  const selectedTaskTemplates = useSelector(
    taskTemplateListActor,
    TaskTemplateListActorSelectors.getSelectedTaskTemplates,
  );

  const dueDateRuleDefinition = React.useMemo(
    () => dueDateRulesMap[props.taskTemplate.group.id],
    [dueDateRulesMap, props.taskTemplate.group.id],
  );

  const organizationMemberships = React.useMemo(
    () =>
      Option(taskAssignmentsMap[props.taskTemplate.id])
        .map(_map(taskAssignment => organizationMembershipsMap[taskAssignment.organizationMembership.id]))
        .getOrElse([]),
    [organizationMembershipsMap, props.taskTemplate.id, taskAssignmentsMap],
  );

  const assignmentRules = React.useMemo(() => {
    return taskAssignmentRulesMap[props.taskTemplate.group.id] ?? [];
  }, [props.taskTemplate.group.id, taskAssignmentRulesMap]);

  const isMultiSelecting = useSelector(taskTemplateListActor, TaskTemplateListActorSelectors.isMultiSelecting);

  const isSelected = React.useMemo(
    () => selectedTaskTemplates.some(tt => tt.id === props.taskTemplate.id),
    [props.taskTemplate.id, selectedTaskTemplates],
  );

  return (
    <TaskListItem
      assignmentRules={assignmentRules}
      dueDateRuleDefinition={dueDateRuleDefinition}
      organizationMemberships={organizationMemberships}
      isSelected={isSelected}
      isMultiSelecting={isMultiSelecting}
      {...props}
    />
  );
});

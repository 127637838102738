import { Muid } from '@process-street/subgrade/core';
import { isApproval, TaskTemplate, Widget } from '@process-street/subgrade/process';
import { ActorRefFrom, assign, createMachine, sendParent, spawn, StateFrom } from 'xstate';
import { SharedContext } from '../../shared';
import { makeTaskTemplateGenerationMachine, TaskTemplateGenerationActor } from './task-template-generation-machine';
import _keyBy from 'lodash/keyBy';

export type TaskTemplateGenerationActorMap = Record<Muid, TaskTemplateGenerationActor>;

type Context = {
  sharedContext: SharedContext;
  taskTemplateGenerationActorMap: TaskTemplateGenerationActorMap;
};

export type AiWorkflowGenerationEvent =
  | { type: 'ANIMATE_TASK_TEMPLATE'; taskTemplate: TaskTemplate }
  | { type: 'UPDATE_WIDGET'; taskTemplateGroupId: Muid; widget: Widget }
  | { type: 'UPDATE_TASK_TEMPLATE'; taskTemplate: TaskTemplate };

export type AiWorkflowGenerationMachine = ReturnType<typeof makeAiWorkflowGenerationMachine>;
export type AiWorkflowGenerationMachineActorRef = ActorRefFrom<AiWorkflowGenerationMachine>;

export const makeAiWorkflowGenerationMachine = ({ sharedContext }: { sharedContext: SharedContext }) => {
  return createMachine(
    {
      id: `ai-workflow-generation-machine`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as AiWorkflowGenerationEvent,
        context: {} as Context,
      },
      tsTypes: {} as import('./ai-workflow-generation-machine.typegen').Typegen0,
      context: () =>
        ({
          sharedContext,
          taskTemplateGenerationActorMap: {},
        } as Context),
      states: {
        idle: {
          on: {
            ANIMATE_TASK_TEMPLATE: [
              {
                cond: 'isNotApproval',
                target: 'animating',
                actions: ['sendParentUpdateTaskTemplates', 'animateTaskTemplate'],
              },
              {
                cond: 'isApproval',
                actions: 'sentParentTaskTemplate',
              },
            ],
          },
        },
        animating: {
          on: {
            UPDATE_WIDGET: { actions: 'sendWidgetUpdated' },
            UPDATE_TASK_TEMPLATE: { actions: 'sendTaskTemplateUpdated' },
            ANIMATE_TASK_TEMPLATE: [
              {
                cond: 'isNotApproval',
                actions: ['sendParentUpdateTaskTemplates', 'animateTaskTemplate'],
              },
            ],
          },
        },
        done: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        sendParentUpdateTaskTemplates: sendParent((_ctx, evt) => ({
          type: 'UPDATE_GENERATED_TASK_TEMPLATE',
          taskTemplate: { ...evt.taskTemplate, name: '' },
        })),
        sentParentTaskTemplate: sendParent((_ctx, evt) => ({
          type: 'UPDATE_GENERATED_TASK_TEMPLATE',
          taskTemplate: evt.taskTemplate,
        })),
        animateTaskTemplate: assign((ctx, evt) => {
          const actor = spawn(makeTaskTemplateGenerationMachine({ taskTemplate: evt.taskTemplate, sharedContext }));
          actor.send({ type: 'ANIMATE' });

          return {
            taskTemplateGenerationActorMap: {
              ...ctx.taskTemplateGenerationActorMap,
              [evt.taskTemplate.group.id]: actor,
            },
          };
        }),
        sendWidgetUpdated: sendParent((_ctx, evt) => ({ type: 'UPDATE_GENERATED_WIDGET', widget: evt.widget })),
        sendTaskTemplateUpdated: sendParent((_ctx, evt) => ({
          type: 'UPDATE_GENERATED_TASK_TEMPLATE',
          taskTemplate: evt.taskTemplate,
        })),
      },
      guards: {
        isApproval: (_ctx, evt) => isApproval(evt.taskTemplate),
        isNotApproval: (_ctx, evt) => !isApproval(evt.taskTemplate),
      },
    },
  );
};

export const AiWorkflowGenerationActorSelector = {
  isGenerating(state: StateFrom<AiWorkflowGenerationMachine>) {
    return (
      state.matches('animating') ||
      Object.values(state.context.taskTemplateGenerationActorMap).some(
        actor => actor.getSnapshot()?.matches('animating') || actor.getSnapshot(),
      )
    );
  },

  getIsTaskAnimatingMap(state: StateFrom<AiWorkflowGenerationMachine>) {
    return _keyBy(
      Object.entries(state.context.taskTemplateGenerationActorMap).map(([taskTemplateGroupId, actor]) => ({
        taskTemplateGroupId,
        isAnimating: actor.getSnapshot()?.matches('animating') || false,
      })),
      'taskTemplateGroupId',
    );
  },

  areAllAnimationsDone(state: StateFrom<AiWorkflowGenerationMachine>) {
    return Object.values(state.context.taskTemplateGenerationActorMap).every(actor =>
      actor.getSnapshot()?.matches('done'),
    );
  },

  getFirstGeneratingTaskTemplate(state: StateFrom<AiWorkflowGenerationMachine>) {
    const animatingActorEntry = Object.entries(state.context.taskTemplateGenerationActorMap).find(([_, actor]) =>
      actor.getSnapshot()?.matches('animating'),
    );
    return animatingActorEntry ? animatingActorEntry[1].state.context.taskTemplate : undefined;
  },
};

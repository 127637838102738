import * as React from 'react';
import { Box, FormControl, FormErrorMessage, VStack } from 'components/design/next';
import { useActor } from '@xstate/react';
import { SelectFormFieldActor } from './select-form-field-machine';
import { BlvdSelect, BlvdSelectProps } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import _keyBy from 'lodash/keyBy';
import { ValidationSelectors } from '../validation-machine';
import { useSelector } from '@xstate/react';
import { RulesEngineTargetSelectors } from '../../form-response-body/rules-engine-machine';
import {
  isMultiChoiceFormFieldWidget,
  MultiChoiceFormFieldWidget,
  SelectFormFieldWidget,
} from '@process-street/subgrade/process';
import { FormResponseLabel } from '../common';

export interface SelectFormFieldProps {
  actor: SelectFormFieldActor;
}

type OptionType = {
  label: string;
  value: string;
};

export const SelectFormField: React.FC<React.PropsWithChildren<SelectFormFieldProps>> = ({ actor }) => {
  const [state, send] = useActor(actor);
  const { widget } = state.context;
  const isHiddenByRule = useSelector(actor, RulesEngineTargetSelectors.getIsHiddenByRule);

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const isMulti = isMultiChoiceFormFieldWidget(widget);
  type IsMulti = typeof isMulti;
  const setValue: BlvdSelectProps<OptionType, IsMulti>['onChange'] = value => {
    if (BlvdSelectHelpers.isOptionsType<OptionType>(value)) {
      send({ type: 'CHANGE', value: value.map(option => option.value) });
      return;
    }
    if (BlvdSelectHelpers.isOptionType<OptionType>(value)) {
      send({ type: 'CHANGE', value: [value.value] });
      return;
    }

    send({ type: 'CHANGE', value: [] });
  };

  const getOptions = (widget: SelectFormFieldWidget | MultiChoiceFormFieldWidget) => {
    if (widget.config.linkId) {
      return widget.config.linkedColumnData?.filter(item => item !== '').map(item => ({ value: item, label: item }));
    }
    return widget.config.items
      .filter(item => item.name !== '')
      .map(item => ({
        value: item.id,
        label: item.name,
      }));
  };

  const [options, optionsMap]: [OptionType[], Record<string, OptionType>] = React.useMemo(() => {
    const options = getOptions(widget) ?? [];

    const optionsMap = _keyBy(options, 'value');

    return [options, optionsMap];
  }, [widget]);

  const selectedOption = React.useMemo(
    () => state.context.value.map(id => optionsMap[id]),
    [optionsMap, state.context.value],
  );

  const isInvalid = ValidationSelectors.isActorInvalidVisible(state.context.validationActor);

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !state.context.inputNode) {
          actor.send({ type: 'SET_NODE', node });
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
    >
      <FormResponseLabel htmlFor={`form-field-widget-${widget.id}`}>
        {widget.label || 'Untitled Dropdown'}
      </FormResponseLabel>

      <Box maxW="175" w="full">
        <BlvdSelect
          inputId={`form-field-widget-${widget.id}`}
          placeholder="Select"
          isMulti={isMulti}
          value={selectedOption}
          options={options}
          onChange={setValue}
          isDisabled={state.matches('input.disabled')}
          autoFocus={state.matches('autoFocus.enabled')}
          onFocus={onFocus}
          onBlur={onBlur}
          isInvalid={isInvalid}
          isClearable
        />

        <FormErrorMessage>{ValidationSelectors.errorMessage(state.context.validationActor)}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};

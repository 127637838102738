import angular from 'angular';
import templateUrl from './data-set-link-details.component.html';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import './data-set-link-details.scss';

angular.module('frontStreetApp.directives').component('psDataSetLinkDetails', {
  bindings: { widget: '<' },
  templateUrl,
  controller(FeatureFlagService) {
    const ctrl = this;

    ctrl.isDataSetsV2Enabled = FeatureFlagService.getFeatureFlags().dataSetsV2;

    ctrl.previewValues = [];

    ctrl.$onInit = function () {
      ctrl.previewValues = ctrl.getPreviewValues();
    };

    ctrl.getPreviewValues = () => {
      function parseValue(value) {
        if (typeof value === 'number' && !isNaN(value)) {
          const date = new Date(value);
          return date.getTime() > 0 ? DateUtils.formatDateTime(date, DateFormat.DefaultDateOnly) : value;
        }
        return value;
      }
      const linkedColumnData = ctrl.widget.config.linkedColumnData || [];
      const processedData = [];
      for (let item of linkedColumnData) {
        if (processedData.length >= 3) break;
        const parsedValue = parseValue(item);
        if (parsedValue !== null) {
          processedData.push(parsedValue);
        }
      }

      return processedData;
    };
  },
});

import { FieldValueJson, SimpleFieldValue } from '@process-street/subgrade/process';
import angular from 'angular';
import templateUrl from './text-renderer.component.html';
import './text-renderer.component.scss';

export class ApprovalSubjectTaskWidgetTextRendererController {
  public getValue(fieldValue: FieldValueJson) {
    return (fieldValue && (fieldValue as SimpleFieldValue).value) || '-';
  }
}

export const ApprovalSubjectTaskWidgetTextRenderer: angular.IComponentOptions = {
  bindings: {
    formFieldValue: '<',
  },
  controller: ApprovalSubjectTaskWidgetTextRendererController,
  templateUrl,
};

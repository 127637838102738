import * as React from 'react';
import { useDisclosure, useToast, Text, Link, ButtonProps } from 'components/design/next';
import { useQueryClient } from 'react-query';
import { Template, TemplateType, tmplTypeName } from '@process-street/subgrade/process';
import { GetTemplateQuery, GetAllTemplatesQuery, useGetTemplateQuery } from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { GetAllFoldersByOrganizationId } from 'features/folder/query-builder';
import { useTemplateMenuContext } from '..';
import { MoveToFolderModal } from 'components/move-to-folder/modal';
import { useDispatch } from 'react-redux';
import { TEMPLATE_SET } from 'reducers/template/template.actions';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { ButtonWithUpgradeTooltip } from 'components/button-with-upgrade-tooltip';
import { GetTemplatesPermissionStatsByOrganization } from 'components/permissions/services/query';
import { useInjector } from 'components/injection-provider';
import { getAncestorFolders, toSlugPath } from 'features/folder/lib';
import { useFoldersQuery } from 'pages/library/components/template-library/query';
import { useFunctionRef } from 'hooks/use-function-ref';
import { match, P } from 'ts-pattern';

export interface MoveTemplateButtonProps {
  onMoved?: (template?: Template) => void;
  children: React.ReactElement<ButtonProps>;
}

export const MoveTemplateButton: React.FC<React.PropsWithChildren<MoveTemplateButtonProps>> = React.memo(
  ({ onMoved, children }) => {
    const onMovedRef = useFunctionRef(onMoved);
    const { setCloseOnBlur, templateId, view } = useTemplateMenuContext();
    const { $state } = useInjector('$state');

    const toast = useToast();
    const toastRef = React.useRef<ReturnType<typeof toast>>();

    const disclosure = useDisclosure({
      id: 'move',
      onOpen: () => {
        setCloseOnBlur(false);
      },
      onClose: () => {
        setCloseOnBlur(true);
      },
    });

    const { data: template } = useGetTemplateQuery({ templateId });

    const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
      enabled: !!templateId,
    });
    const { data: folders = [] } = useFoldersQuery();

    const canMoveTemplate = match({ template, permissionMap })
      .with(
        {
          template: { templateType: P.union(TemplateType.Playbook, TemplateType.Form) },
          permissionMap: { templateUpdate: true },
        },
        () => true,
      )
      .with(
        {
          template: { templateType: TemplateType.Page },
          permissionMap: { pageUpdate: true },
        },
        () => true,
      )
      .otherwise(() => false);

    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('MoveTemplateButton child must be a single clickable element');
    }

    const dispatch = useDispatch();

    const queryClient = useQueryClient();
    const handleMove = React.useCallback(
      (template?: Template) => {
        onMovedRef.current?.(template);

        if (canMoveTemplate) {
          if (template) {
            dispatch({ type: TEMPLATE_SET, payload: template });
            const {
              id: templateId,
              organization: { id: organizationId },
              folder: { id: parentFolderId },
            } = template;

            queryClient.invalidateQueries(GetTemplateQuery.getKey({ templateId }));
            queryClient.invalidateQueries(GetAllFoldersByOrganizationId.getKey(organizationId));
            queryClient.invalidateQueries(
              GetTemplatesPermissionStatsByOrganization.getKey({ organizationId, parentFolderId }),
            );

            if (view === 'index') {
              const folder = folders.find(f => f.id === template.folder.id);
              if (!folder) {
                throw new Error('Folder not found');
              }
              const ancestors = getAncestorFolders(folder, folders);
              queryClient.invalidateQueries(GetAllTemplatesQuery.getKey({ organizationId }));
              const href = $state.href('dashboard.type', { type: 'folder', path: toSlugPath(folder, ancestors) });
              toastRef.current = toast({
                status: 'success',
                title: (
                  <Text>
                    {tmplTypeName(template)} moved to{' '}
                    <Link
                      {...{
                        href,
                        textDecor: 'underline',
                        _hover: { cursor: 'pointer', color: 'white', textDecor: 'underline' },
                        onClick: () => toastRef.current && toast.close(toastRef.current),
                      }}
                    >
                      {folder.name}
                    </Link>
                  </Text>
                ),
              });
            } else {
              toast({
                status: 'success',
                title: `${tmplTypeName(template)} moved`,
              });
            }
          } else {
            queryClient.invalidateQueries(GetTemplatesPermissionStatsByOrganization.key);
          }
          disclosure.onClose();
        }
      },
      [onMovedRef, canMoveTemplate, disclosure, dispatch, queryClient, view, folders, $state, toast],
    );

    const isFree = useIsCurrentUserFreeMember();
    if (isFree) return <ButtonWithUpgradeTooltip>{children}</ButtonWithUpgradeTooltip>;

    const btn = React.cloneElement(children, {
      onClick: () => {
        disclosure.onToggle();
      },
    });

    return canMoveTemplate ? (
      <>
        {btn}
        {template?.folder.id && (
          <MoveToFolderModal
            candidateId={templateId}
            folderId={template?.folder.id}
            isOpen={disclosure.isOpen}
            onTemplateMove={handleMove}
            onClose={disclosure.onClose}
          />
        )}
      </>
    ) : null;
  },
);

import * as React from 'react';
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { useInjector } from 'components/injection-provider';
import { match, P } from 'ts-pattern';
import { useOnStateChangeSuccess } from 'hooks/use-on-state-change-success';
import qs, { ParsedQs } from 'qs';
import ReactRouter from 'react-router';
import { queryString } from '@process-street/subgrade/util';

export const useNavigate: typeof ReactRouter.useNavigate = () => {
  const { $state } = useInjector('$state');

  return React.useCallback(
    function (to: ReactRouter.To | number, { replace = false, state }: ReactRouter.NavigateOptions = {}) {
      return match(to)
        .with(P.number, () => {
          throw new Error('Number history navigation not implemented');
        })
        .with(P.string, to => {
          $state.go(to, state ?? {}, { reload: replace, inherit: false });
        })
        .when(
          (to): to is ReactRouter.Location => typeof to !== 'string',
          to => {
            $state.go(
              to.pathname ?? $state.current,
              {
                ...qs.parse(to.search ?? ''),
                '#': to.hash,
                ...state,
              },
              // if to.pathname is not specified, we assume we want to navigate to the same state
              // inheriting current Angular state params
              { reload: replace, inherit: !to.pathname },
            );
          },
        )
        .run();
    },
    [$state],
  );
};

export const useHref: typeof ReactRouter.useHref = (to: ReactRouter.To, relative) => {
  const absolute = relative?.relative === 'path';

  const { $state } = useInjector('$state');

  return match(to)
    .with(P.string, to => $state.href(to, {}, { inherit: !to, absolute }))
    .when(
      (to): to is ReactRouter.Location => typeof to !== 'string',
      // if to.pathname is not specified, we assume we want to navigate to the same state
      // inheriting current Angular state params
      to => $state.href(to.pathname ?? $state.current, qs.parse(to.search ?? ''), { inherit: !to.pathname, absolute }),
    )
    .run();
};

export const useLocation = () => {
  const { $state, $stateParams } = useInjector('$state', '$stateParams');
  const { current } = $state;
  const { '#': hash, ...params } = $stateParams;

  return {
    hash,
    pathname: current.name!,
    search: qs.stringify(params),
    state: current.data,
    // key not implemented
  } as Omit<ReturnType<typeof ReactRouter.useLocation>, 'key'>;
};

/**
 * Not a React Router hook, but we can use it as a drop-in replacement for $stateParams.
 * When migrating to React Select, we can use qs.parse for the query string.
 */
export function useStateParams(): ParsedQs {
  const { $stateParams } = useInjector('$stateParams');
  return $stateParams;
}

export const useMatch = (path: string) => {
  const { $state } = useInjector('$state');
  const { current } = $state;

  if (!$state.includes(path)) return null;

  return {
    pathname: current.name!,
    // params, pattern not implemented
  } as Partial<ReturnType<typeof ReactRouter.useMatch>>;
};

export function useOnRouteChange(
  cb: (params: {
    from: ReactRouter.Location;
    to: ReactRouter.Location;
    updateState: (newState: Record<string, unknown>) => void;
  }) => void,
) {
  useOnStateChangeSuccess(({ toState = {}, toParams, fromState = {}, fromParams = {} }) => {
    const updateState = (newState: Record<string, unknown>) => {
      toState.data = newState;
    };

    cb({
      to: {
        pathname: toState?.name ?? 'unknown',
        search: queryString.stringify(toParams),
        hash: '',
        state: toState?.data,
        key: '[not implemented]',
      },
      from: {
        pathname: fromState?.name ?? 'unknown',
        search: queryString.stringify(fromParams),
        hash: '',
        state: fromState?.data,
        key: '[not implemented]',
      },
      updateState,
    });
  });
}

type LinkProps = ChakraLinkProps & {
  to?: ReactRouter.To;
};

export const Link = ({ to, ...props }: LinkProps) => {
  const href = useHref(to ?? props.href ?? '');

  return <ChakraLink {...props} href={href} />;
};

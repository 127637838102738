import * as React from 'react';
import { TemplateRevision } from '@process-street/subgrade/process';
import { ButtonGroup, ButtonGroupProps, Icon } from 'components/design/next';
import { MiddleGroupButton } from './middle-group-button';
import { ConditionalLogicButtonWrapper } from 'app/pages/templates/_id/components/conditional-logic-button-wrapper';
import { useAutomationSelector } from 'app/pages/templates/_id/automation/components/selector/context';
import { SolutionTypeTag } from '@process-street/subgrade/automation';

export type EditMiddleButtonGroupProps = ButtonGroupProps & {
  templateRevision: TemplateRevision;
};

export enum MiddleGroupItems {
  Build = 'Build',
  ConditionalLogic = 'Logic',
  Automations = 'Automations',
}

export const EditMiddleButtonGroup: React.FC<EditMiddleButtonGroupProps> = ({ templateRevision, ...props }) => {
  const [_, send] = useAutomationSelector();

  const openAutomationsModal = () => {
    send({
      type: 'SOLUTION_TYPE_SELECTED',
      payload: { solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen, modalView: 'all' },
    });
  };

  return (
    <ButtonGroup spacing="2" {...props} alignItems="center">
      <MiddleGroupButton>{MiddleGroupItems.Build}</MiddleGroupButton>
      <Icon icon="chevron-right" size="4" color="gray.500" />
      <ConditionalLogicButtonWrapper templateRevision={templateRevision}>
        <MiddleGroupButton>{MiddleGroupItems.ConditionalLogic}</MiddleGroupButton>
      </ConditionalLogicButtonWrapper>
      <Icon icon="chevron-right" size="4" color="gray.500" />
      <MiddleGroupButton onClick={openAutomationsModal}>{MiddleGroupItems.Automations}</MiddleGroupButton>
    </ButtonGroup>
  );
};

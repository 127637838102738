import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  ButtonGroup,
  ButtonProps,
  Divider,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from 'components/design/next';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { TemplateRevisionStatus } from '@process-street/subgrade/process';
import { useIsMutating, useQueryClient } from 'react-query';
import { match } from 'ts-pattern';
import { Muid } from '@process-street/subgrade/core';
import { useTemplateMenuContext } from 'features/template/components/template-menu';
import { Tooltip } from '@chakra-ui/react';
import { Link, useNavigate } from 'app/adapters/navigation';
import qs from 'qs';
import {
  CreateTemplateRevisionMutation,
  GetNewestTemplateRevisionsByTemplateIdQuery,
  useCreateTemplateRevisionMutation,
  useGetNewestTemplateRevisionsByTemplateIdQuery,
} from 'features/template-revisions/query-builder';
import { useFeatureFlag } from 'features/feature-flags';

export type SyncChangesAlertProps = Omit<AlertDialogProps, 'leastDestructiveRef' | 'children'> & {
  onSync?: () => void;
  templateId: Muid;
};
export const SyncChangesAlert: React.FC<React.PropsWithChildren<SyncChangesAlertProps>> = ({
  onClose,
  isOpen,
  onSync,
  templateId,
}) => {
  const { data: template } = useGetTemplateQuery({ templateId }, { enabled: Boolean(templateId) });
  const { data: sourceTemplate } = useGetTemplateQuery(
    { templateId: template?.copiedFromTemplateId },
    { enabled: Boolean(template?.copiedFromTemplateId) },
  );
  const toast = useToast();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const createTemplateRevisionMutation = useCreateTemplateRevisionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        GetNewestTemplateRevisionsByTemplateIdQuery.getKey({
          templateId: sourceTemplate?.id,
        }),
      );
      toast({
        status: 'success',
        title: 'Changes synced to source workflow.',
      });
      navigate({
        pathname: 'template',
        search: qs.stringify({
          id: sourceTemplate?.id,
        }),
      });

      onSync?.();
      onClose?.();
    },
  });

  const { data: templateRevisions } = useGetNewestTemplateRevisionsByTemplateIdQuery(
    { templateId },
    { enabled: Boolean(templateId) },
  );
  const baseTemplateRevision = templateRevisions?.[0];

  const { data: sourceTemplateRevisions } = useGetNewestTemplateRevisionsByTemplateIdQuery(
    { templateId: template?.copiedFromTemplateId },
    { enabled: Boolean(template?.copiedFromTemplateId) },
  );
  const canSyncChanges = !sourceTemplateRevisions?.some(revision => revision?.status === TemplateRevisionStatus.Draft);

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const sourceWorkflowLink = (
    <Link
      to={{
        pathname: 'templateDashboard',
        search: qs.stringify({
          id: sourceTemplate?.id,
        }),
      }}
    >
      <Text as="span" color="brand.500" fontWeight="medium" noOfLines={1}>
        {sourceTemplate?.name}
      </Text>
    </Link>
  );

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      scrollBehavior="inside"
      size="xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader py="4" px="8" fontSize="lg">
          Sync changes with source workflow?
        </AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody pt="4" px="8" pb="8">
          <Text mb="2">
            This will create a draft in the source workflow (i.e. the workflow this workflow was copied from) with all
            the published changes made in this workflow.
          </Text>
          <Text>The source workflow is {sourceWorkflowLink}.</Text>
        </AlertDialogBody>

        <Divider />

        <AlertDialogFooter py="6" px="8">
          <Spacer />
          <ButtonGroup>
            <Button ref={cancelRef} variant="ghost" onClick={onClose} colorScheme="gray">
              Cancel
            </Button>
            <Tooltip
              isDisabled={canSyncChanges}
              label={
                <Text>
                  The source workflow has a draft. You cannot sync changes until the draft is discarded or published.
                </Text>
              }
              shouldWrapChildren
            >
              <Button
                isLoading={createTemplateRevisionMutation.isLoading}
                isDisabled={!canSyncChanges || createTemplateRevisionMutation.isLoading}
                variant="primary"
                onClick={() =>
                  sourceTemplate &&
                  baseTemplateRevision &&
                  createTemplateRevisionMutation.mutate({
                    templateId: sourceTemplate.id,
                    baseTemplateRevisionId: baseTemplateRevision.id,
                  })
                }
              >
                Sync
              </Button>
            </Tooltip>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export interface SyncChangesButtonProps {
  onSync?: () => void;
  children: React.ReactElement | ((props: { isMutating: boolean }) => React.ReactElement<ButtonProps>);
  disclosure?: Partial<ReturnType<typeof useDisclosure>>;
}

export const SyncChangesButton: React.FC<SyncChangesButtonProps> = React.memo(
  ({ onSync, children, disclosure: disclosureProp }) => {
    const isFeatureEnabled = useFeatureFlag('branchAndMergeWorkflows');
    const { templateId } = useTemplateMenuContext();
    const disclosure = useDisclosure({
      id: 'sync-changes',
      ...disclosureProp,
    });

    const createMutations = useIsMutating({
      mutationKey: CreateTemplateRevisionMutation.key,
    });

    const isMutating = createMutations > 0;
    const { data: template } = useGetTemplateQuery({ templateId });
    const { data: sourceTemplate } = useGetTemplateQuery(
      { templateId: template?.copiedFromTemplateId },
      { enabled: Boolean(template?.copiedFromTemplateId) },
    );
    const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
      enabled: !!templateId,
    });
    const { data: { permissionMap: sourcePermissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(
      templateId,
      { enabled: !!template?.copiedFromTemplateId },
    );
    const isSameOrganization = template?.organization.id === sourceTemplate?.organization.id;
    const hasEditPermissions = permissionMap?.templateUpdate && sourcePermissionMap?.templateUpdate;
    // If this field exists, the template has the correct format to merge
    const isTemplateCompatible = !!template?.copiedFromTemplateRevisionId;
    const isDisabled = !isSameOrganization || !hasEditPermissions || !isTemplateCompatible;

    const disabledReason = match({ isSameOrganization, hasEditPermissions, isTemplateCompatible })
      .with({ isSameOrganization: false }, () => 'This workflow was not copied from a workflow in this organization.')
      .with(
        { hasEditPermissions: false },
        () => 'You do not have permission to edit this workflow and the source workflow.',
      )
      .with(
        { isTemplateCompatible: false },
        () => 'This workflow is not compatible with this feature, make a new duplicate of the source workflow.',
      )
      .otherwise(() => undefined);

    const child =
      typeof children === 'function' ? children({ isMutating }) : (children as React.ReactElement<ButtonProps>);
    const btn = React.cloneElement(child, {
      isDisabled,
      onClick: () => {
        disclosure.onToggle();
      },
    });

    return isFeatureEnabled ? (
      <>
        <Tooltip isDisabled={!isDisabled} label={<Text>{disabledReason}</Text>} shouldWrapChildren>
          {btn}
        </Tooltip>
        <SyncChangesAlert
          onSync={onSync}
          onClose={disclosure.onClose}
          isOpen={disclosure.isOpen}
          templateId={templateId}
        />
      </>
    ) : null;
  },
);

import { useSelector } from '@xstate/react';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { InsertWidget, InsertWidgetDrawer, InsertWidgetDrawerButton } from 'app/pages/forms/_id/edit/components';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { NewWidgetItem } from 'app/pages/forms/_id/edit/types';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'app/pages/forms/_id/shared';
import * as React from 'react';

export const InsertWidgetsSection = () => {
  const actor = useFormEditorPageActorRef();
  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading);

  const handleWidgetClick = (payload: NewWidgetItem) => {
    actor.send({ type: 'CREATE_WIDGET', payload });
  };

  return (
    <>
      <InsertWidgetDrawerButton top={undefined} mt={1} right={-1} zIndex={1} />

      <InsertWidgetDrawer
        contentProps={{
          h: `calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} - var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}))`,
          top: `calc(${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT})) !important`,
          bottom: 0,
          maxW: { base: 'full', md: '156px' },
          bgColor: 'white',
          borderLeftWidth: 'thin',
          borderLeftColor: 'gray.100',
          borderLeftStyle: 'solid',
          boxShadow: '0px 4px 6px -1px #0000001A',
        }}
        body={
          <ThemeProvider2024>
            <InsertWidget isLoading={isLoading} onWidgetClick={handleWidgetClick} />
          </ThemeProvider2024>
        }
      />
    </>
  );
};

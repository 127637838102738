import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid, Option } from '@process-street/subgrade/core';
import { ApprovalBulkAction, TokenPayload } from 'components/approvals/email-initiated/email-initiated.component';
import { EmailInitiatedApprovalActions } from 'components/approvals/email-initiated/store/email-initiated.actions';
import { EmailInitiatedApprovalSelectors } from 'components/approvals/email-initiated/store/email-initiated.selectors';
import React from 'react';
import { connect } from 'react-redux';
import { ReduxAppState } from 'reducers/types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import './EmailInitiatedApprovalContainer.scss';

interface StateToProps {
  approvals: Option<Approval[]>;
  requestCompleted: boolean;
}

interface DispatchFromProps {
  upsertAllByToken: (taskId: Muid, action: ApprovalStatus, token: string) => void;
}

interface PureEmailInitiatedApprovalContainerProps {
  taskId: Muid;
  action: ApprovalBulkAction;
  token: string;
  tokenPayload: TokenPayload;
  onLoginClicked: () => void;
}

interface EmailInitiatedApprovalContainerProps
  extends StateToProps,
    PureEmailInitiatedApprovalContainerProps,
    DispatchFromProps {}

interface EmailInitiatedApprovalContainerState {
  requestStarted: boolean;
}

export class PureEmailInitiatedApprovalContainer extends React.PureComponent<
  EmailInitiatedApprovalContainerProps,
  EmailInitiatedApprovalContainerState
> {
  constructor(props: EmailInitiatedApprovalContainerProps) {
    super(props);

    this.state = {
      requestStarted: false,
    };
  }

  public componentDidMount() {
    const { tokenPayload } = this.props;
    const issuedAtInSeconds = tokenPayload.iat; // When the token was issued, which is when the email is sent
    const autoReviewThresholdInSeconds = 5 * 60;
    const autoReviewAtInMillis = (issuedAtInSeconds + autoReviewThresholdInSeconds) * 1000;
    // This code is necessary because certain link scanners (like Microsoft ATP) will open all links in an email
    // to scan for threats (see PS-10961). Opening the Approve All or Reject All will cause them to happen.
    // Since ATP only scans for threats once, and generally right after the email arrives, if we have a threshold
    // of 5 minutes we can keep the auto-review behavior without tripping up MS ATP.
    if (Date.now() > autoReviewAtInMillis) {
      this.review();
    }
  }

  public review = () => {
    const { taskId, action, token } = this.props;

    this.setState({
      ...this.state,
      requestStarted: true,
    });
    const approvalStatus = this.asApprovalStatus(action);
    this.props.upsertAllByToken(taskId, approvalStatus, token);
  };

  public render() {
    return (
      <div className={`email-initiated-approval-container`}>
        <img src="../../../../images/task-template/approval-rules-empty-img.svg" alt="A thumbs up and a thumbs down" />
        {this.getInfoContent()}
        {this.getLoginButton()}
      </div>
    );
  }

  public getInfoContent() {
    return <p className={`info-content`}>{this.getApprovalText()}</p>;
  }

  public getApprovalText() {
    const { action, approvals, tokenPayload, requestCompleted } = this.props;
    const status = this.asApprovalStatus(action).toLowerCase();

    if (requestCompleted && approvals && approvals.length >= 0) {
      return (
        <span>
          You <span className={`${status}-action`}>{status}</span> {approvals.length} task(s) in{' '}
          <b>{tokenPayload.checklistName}.</b>
        </span>
      );
    } else if (!requestCompleted) {
      let reviewText;
      switch (action) {
        case 'approve-all':
          reviewText = 'Approve All';
          break;
        case 'reject-all':
          reviewText = 'Reject All';
          break;
        default:
          reviewText = 'Review All';
      }

      return this.state.requestStarted ? (
        <span className={`spinner-container spinner-container-sm`}>
          <span className="ps-spinner" />
        </span>
      ) : (
        <button type="button" onClick={this.review} className="btn btn-primary navbar-btn">
          {reviewText}
        </button>
      );
    }
    return null;
  }

  public handleLogin = () => {
    this.props.onLoginClicked();
  };

  public getLoginButton() {
    return (
      <button type="button" onClick={this.handleLogin} className="btn btn-default navbar-btn">
        Login to Process Street
      </button>
    );
  }

  private asApprovalStatus(action: ApprovalBulkAction) {
    return action === ApprovalBulkAction.ApproveAll ? ApprovalStatus.Approved : ApprovalStatus.Rejected;
  }
}

const mapStateToProps = (state: ReduxAppState, props: PureEmailInitiatedApprovalContainerProps): StateToProps => {
  const approvals = EmailInitiatedApprovalSelectors.emailInitiatedApprovalState(props.taskId)(state);
  const requestCompleted = EmailInitiatedApprovalSelectors.getRequestCompleteStatusByTaskId(props.taskId)(state);
  return {
    approvals: approvals && approvals.approvals,
    requestCompleted,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>,
): DispatchFromProps => ({
  upsertAllByToken: (taskId, approvalCommand, token) =>
    dispatch(EmailInitiatedApprovalActions.upsertAllByToken(taskId, approvalCommand, token)),
});

export const EmailInitiatedApprovalContainer = connect<
  StateToProps,
  DispatchFromProps,
  PureEmailInitiatedApprovalContainerProps,
  ReduxAppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(PureEmailInitiatedApprovalContainer);

import * as React from 'react';
import { TaskTemplate, TextWidget, Widget, WidgetLike } from '@process-street/subgrade/process';
import { TinyMCEEditor } from 'features/rich-text';
import { MutationStatus } from 'react-query';
import { useInjector } from 'components/injection-provider';
import { EventName } from 'services/event-name';
import { AiGenerateWidgetContentMenu } from 'features/widgets/components/ai/ai-generate-widget-content-menu/ai-generate-widget-content-menu';
import { AiGenerationTooltip } from 'features/widgets/components/ai/ai-generation-tooltip';

export type AiGenerateTextWidgetContentMenuProps = {
  editor?: TinyMCEEditor;
  widget: Widget;
  onContentGenerated: (widget: WidgetLike) => void;
  onGenerationStatusChange?: (status: MutationStatus) => void;
};

export const AiGenerateTextWidgetContentMenu: React.FC<
  React.PropsWithChildren<AiGenerateTextWidgetContentMenuProps>
> = ({ widget, editor, onContentGenerated, onGenerationStatusChange }) => {
  const { $rootScope } = useInjector('$rootScope');
  const handleContentGenerated = (res: WidgetLike) => {
    onContentGenerated(res);

    $rootScope.$broadcast(EventName.TEXT_WIDGET_CONTENT_GENERATION_OK, {
      ...widget,
      content: (res as TextWidget).content ?? (widget as TextWidget).content,
    });
  };

  const [hasTaskError, setHasTaskError] = React.useState(false);

  if (!editor) return null;

  return (
    <AiGenerationTooltip setHasError={setHasTaskError} taskTemplate={widget.header.taskTemplate as TaskTemplate}>
      <AiGenerateWidgetContentMenu
        headerId={widget.header.id}
        getWidgetContent={() => editor.getContent({ format: 'html' })}
        getWidgetSelection={() => editor.selection.getContent({ format: 'html' })}
        onContentGenerated={handleContentGenerated}
        onGenerationStatusChange={onGenerationStatusChange}
        isDisabled={hasTaskError}
        buttonSize="md"
      />
    </AiGenerationTooltip>
  );
};

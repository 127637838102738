import * as React from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  OrderedList,
  Text,
  Textarea,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  bulkAddTasksSchema,
  BulkAddTasksSchema,
} from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/schema';
import { CreateFirstTasksAlert } from 'pages/templates/_id/components/template-settings-modal/onboarding-steps/alerts';
import { useTemplateSettingsModalContext } from '../template-settings-modal-context';
import { useTemplateSettingsContext } from '../context';
import { useActor } from '@xstate/react';

const INPUT_PLACEHOLDER = 'You can type or paste your task names line separated';

const FORM_ID = 'bulk-add-tasks';

export const AddTasksStep: React.FC<
  React.PropsWithChildren<{
    onNext: () => void;
  }>
> = ({ onNext }) => {
  const templateSettingsContext = useTemplateSettingsContext();

  const [, send] = useActor(templateSettingsContext.machineService);

  const { templateRevisionId } = useTemplateSettingsModalContext();

  if (!templateRevisionId) {
    throw new Error('Expecting a template revision for add tasks setup step.');
  }

  const { TaskTemplateService, TaskTemplateListService } = useInjector(
    'TaskTemplateService',
    'TaskTemplateListService',
  );
  const taskTemplatesByTemplateRevisionIdQuery = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId });
  const { isLoading } = taskTemplatesByTemplateRevisionIdQuery;

  const taskTemplates = taskTemplatesByTemplateRevisionIdQuery.data ?? [];

  const removeAllTasks = () => {
    assertTemplateIsBlank(taskTemplates);
    return TaskTemplateService.deleteAll(taskTemplates);
  };

  const addTasks = async (tasksListRaw: string) => {
    const names = getNonEmptyTaskNames(tasksListRaw);

    if (names?.length > 0) {
      send({ type: 'SET_CONTEXT', context: { needsToReload: true } });
    }

    // we pass the already created task templates as an argument
    // so that the service can create proper order tree values
    const newTaskTemplates: TaskTemplate[] = names.reduce<TaskTemplate[]>(
      (alreadyCreated, name, currentIndex) => [
        ...alreadyCreated,
        TaskTemplateListService.generateTaskTemplate(
          name,
          templateRevisionId,
          TaskTemplateTaskType.Standard,
          currentIndex - 1,
          alreadyCreated,
        ),
      ],
      [],
    );
    // create new task templates in order, awaiting the previous call
    // otherwise backend throws 500 error
    await newTaskTemplates.reduce<Promise<any>>(
      (previous, current) => previous.then(() => TaskTemplateService.create(current)),
      Promise.resolve(),
    );
  };

  const onSubmit = async (values: BulkAddTasksSchema, { setSubmitting }: FormikHelpers<BulkAddTasksSchema>) => {
    if (values.tasks) {
      setSubmitting(true);
      await removeAllTasks();
      await addTasks(values.tasks);
      onNext();
    }
  };

  return (
    <>
      <ModalHeader>
        <HStack>
          <Icon icon="gear" variant="far" size="4" color="gray.400" />
          <Text>Bulk Create Tasks</Text>
        </HStack>
      </ModalHeader>
      <ModalCloseButton />
      <Divider />
      <Formik<BulkAddTasksSchema>
        initialValues={{
          tasks: '',
        }}
        onSubmit={onSubmit}
        validationSchema={bulkAddTasksSchema}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched, isValid }) => (
          <Box alignItems="stretch">
            <Form onSubmit={handleSubmit} id={FORM_ID}>
              <ModalBody>
                <CreateFirstTasksAlert my={4} />
                <HStack alignItems="flexStart" spacing={9}>
                  <FormControl flexGrow={1} flexBasis="50%" isInvalid={Boolean(errors.tasks) && touched.tasks}>
                    <FormLabel>Add your tasks here:</FormLabel>
                    <Textarea
                      h="calc(100% - 2em)"
                      minHeight={36}
                      name="tasks"
                      lineHeight={values.tasks ? '2em' : 'normal'}
                      autoFocus={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tasks}
                      placeholder={INPUT_PLACEHOLDER}
                    />
                    <FormErrorMessage>{errors.tasks}</FormErrorMessage>
                  </FormControl>
                  <TaskList flexGrow={1} flexBasis="50%" maxW="45%" tasks={values.tasks} />
                </HStack>
              </ModalBody>
              <Divider />
              <ModalFooter>
                <ButtonGroup>
                  <Button variant="primary" type="submit" isLoading={isLoading || isSubmitting} isDisabled={!isValid}>
                    Next
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          </Box>
        )}
      </Formik>
    </>
  );
};

const TaskList: React.FC<React.PropsWithChildren<{ tasks?: string } & BoxProps>> = ({ tasks, ...props }) => {
  const taskNames = getNonEmptyTaskNames(tasks);

  return (
    <Box {...props}>
      <FormLabel mb={2}>Preview tasks:</FormLabel>
      <OrderedList maxHeight="50vh" spacing={2} overflowX="hidden" overflowY="auto">
        {taskNames.map((taskName, index) => (
          <ListItem listStyleType="none" key={index}>
            <HStack alignItems="baseline">
              <Text variant="-1" color="gray.500" fontWeight="bold">
                {index + 1}
              </Text>
              <TaskPreview taskName={taskName} />
            </HStack>
          </ListItem>
        ))}
      </OrderedList>
    </Box>
  );
};

const TaskPreview: React.FC<React.PropsWithChildren<{ taskName: string }>> = ({ taskName }) => {
  return (
    <Box
      maxWidth="100%"
      px={3}
      py={2}
      backgroundColor="brand.50"
      borderRadius={2}
      borderWidth={0}
      overflow="clip"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {taskName}
    </Box>
  );
};

function assertTemplateIsBlank(taskTemplates: TaskTemplate[]) {
  const threeTasksPresent = taskTemplates.length === 3;
  const emptyTaskNames = taskTemplates.every(tt => !tt.name);
  if (!(threeTasksPresent && emptyTaskNames)) {
    throw new Error('A new blank template must have exactly 3 blank tasks.');
  }
}

function getNonEmptyTaskNames(tasks?: string): string[] {
  return (tasks ?? '')
    .split(/\n/)
    .map(line => line.trim())
    .filter(line => Boolean(line));
}

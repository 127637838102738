import { Drawer, DrawerContent, Spinner, useToast, Box, Center } from 'components/design/next';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'pages/forms/_id/shared';
import * as React from 'react';
import { useEffect } from 'react';
import { match } from 'ts-pattern';
import { CreateDrawerContent } from '../create';
import { EditDrawerContent } from '../edit';
import { ViewDrawerContent } from '../view';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { GetOneOffTaskQuery } from 'features/one-off-tasks/query-builder';
import { useMoveElevioLauncher } from './hooks';
import { useTableFocusManager } from 'pages/tasks/hooks/use-table-focus-manager';
import { useInboxItemsGridApi } from 'app/pages/tasks/use-inbox-items-grid-context-store';

export const OneOffTaskDrawer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const gridApi = useInboxItemsGridApi();
  const { hasUnsavedChanges, isOpen, mode, task, taskId, viewTask, onClose } = useOneOffTaskDrawerStore();
  const focusManager = useTableFocusManager(gridApi);
  const oneOffTaskQuery = GetOneOffTaskQuery.useQuery({ id: taskId }, {});
  const toast = useToast();

  const handleCloseDrawer = () => {
    if (hasUnsavedChanges) {
      const answer = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (answer) {
        onClose();
      }
    } else {
      onClose();
    }
    focusManager?.blur();
  };

  useEffect(() => {
    if (oneOffTaskQuery.data) {
      viewTask({ task: oneOffTaskQuery.data });
    } else if (oneOffTaskQuery.isError) {
      onClose();

      toast({
        status: 'error',
        title: `There was a problem loading your task. Please try again or contact support.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query result
  }, [oneOffTaskQuery.data, oneOffTaskQuery.isError]);

  useMoveElevioLauncher(isOpen);

  return (
    <Drawer isOpen={isOpen} size="xl" onClose={handleCloseDrawer} closeOnOverlayClick={false} trapFocus={false}>
      <ThemeProvider2024>
        <Box
          sx={{
            '.chakra-modal__content-container': {
              w: 'unset',
              h: 'unset',
            },
          }}
        >
          <DrawerContent
            h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR}) !important`}
            top={`${TOP_BAR_HEIGHT_CSS_VAR} !important`}
            bottom="0"
            borderLeftWidth="1px"
            borderLeftStyle="solid"
            borderLeftColor="gray.200"
            boxShadow="xl"
            overflow="auto"
            maxW="772px"
          >
            {oneOffTaskQuery.isLoading ? (
              <Center height="full">
                <Spinner />
              </Center>
            ) : (
              <>
                {match(mode)
                  .with('create', () => <CreateDrawerContent />)
                  .with('edit', () => task && <EditDrawerContent task={task} />)
                  .with('view', () => task && <ViewDrawerContent task={task} />)
                  .exhaustive()}
              </>
            )}
          </DrawerContent>
        </Box>
      </ThemeProvider2024>
    </Drawer>
  );
};

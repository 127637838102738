import { approvalRuleSubjectStatusesReducer } from 'components/approval-rules/store/approval-rules.reducers';
import { bulkApprovalStatusesReducer } from 'components/approvals/email-initiated/store/email-initiated.reducers';
import { templatePermitsStatusesReducer } from 'components/template/membership/store/template-membership.reducer';
import { widgetStatusesReducer } from 'components/widgets/store/widget.reducer';
import { organizationMembershipStatusesReducer } from 'reducers/organization-membership/organization-membership.statuses-reducer';
import { taskTemplateStatusesReducer } from 'reducers/task-template/task-template.reducers';
import { taskStatusUpdatesReducer } from 'reducers/task/task.actions';
import { templateStatusesReducer } from 'reducers/template/template.reducer';
import { Statuses } from 'reducers/types';
import { combineReducers } from 'redux';
import { getStatusByActions } from './statuses-utils';
import {
  TASK_TEMPLATE_CREATE,
  TASK_TEMPLATE_DELETE_ALL,
  TASK_TEMPLATE_UPDATE,
  TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES,
} from 'reducers/task-template/task-template.actions';
import {
  WIDGET_CREATE_AT,
  WIDGET_DELETE_BY_HEADER_ID,
  WIDGET_UPDATE,
  WIDGET_UPDATE_ALL_ORDER_TREES,
} from 'components/widgets/store/widget.actions';
import { featureFlagsStatusReducer } from 'services/features/feature-flags/store/feature-flags.reducer';
import { TEMPLATE_REVISION_DISCARD_BY_ID } from 'reducers/template-revision/template-revision.actions';

export const statusesReducer = combineReducers<Statuses>({
  approvalRuleSubject: approvalRuleSubjectStatusesReducer,
  bulkApprovals: bulkApprovalStatusesReducer,
  organizationMemberships: organizationMembershipStatusesReducer,
  taskTemplates: taskTemplateStatusesReducer,
  templatePermits: templatePermitsStatusesReducer,
  templates: templateStatusesReducer,
  widgets: widgetStatusesReducer,
  taskUpdates: taskStatusUpdatesReducer,
  featureFlags: featureFlagsStatusReducer,
  currentTemplateRevision: getStatusByActions(
    TASK_TEMPLATE_CREATE,
    TASK_TEMPLATE_DELETE_ALL,
    TASK_TEMPLATE_UPDATE,
    TASK_TEMPLATE_UPDATE_ALL_ORDER_TREES,
    WIDGET_CREATE_AT,
    WIDGET_DELETE_BY_HEADER_ID,
    WIDGET_UPDATE,
    WIDGET_UPDATE_ALL_ORDER_TREES,
    TEMPLATE_REVISION_DISCARD_BY_ID,
  ),
});

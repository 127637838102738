import { TemplateStatus } from '@process-street/subgrade/process';
import { Icon, MenuDivider, useMenuContext } from 'components/design/next';
import { ArchiveTemplateButton } from './archive-template-button';
import { CopyShareLinkButton } from './copy-share-link-button';
import { DeleteTemplateButton } from './delete-template-button';
import { DuplicateTemplateButton } from './duplicate-template-button';
import { MoveTemplateButton } from './move-template-button';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import * as React from 'react';
import { TemplateMenuItem, useTemplateMenuContext } from '.';
import { NgLink } from 'features/app/components/ng-link';

export const FormMenu: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { templateId, view, setCloseOnBlur } = useTemplateMenuContext();
  const formIsPrivate = useIsPrivateTemplate(templateId);
  const menuContext = useMenuContext();

  return (
    <>
      <TemplateMenuItem
        as={NgLink}
        to="addFormResponse"
        params={{ id: templateId }}
        icon={<Icon icon="play" size="4" variant="far" color="gray.500" />}
      >
        Respond Form
      </TemplateMenuItem>
      {children}

      <MenuDivider />

      {!formIsPrivate && (
        <CopyShareLinkButton templateId={templateId}>
          {({ copiedValue }) => (
            <TemplateMenuItem
              icon={
                <Icon
                  icon={copiedValue ? 'check' : 'link'}
                  size="4"
                  variant="far"
                  color={copiedValue ? 'green.500' : 'gray.500'}
                />
              }
            >
              Copy response link
            </TemplateMenuItem>
          )}
        </CopyShareLinkButton>
      )}

      <MenuDivider />

      <MoveTemplateButton onMoved={() => menuContext.onClose()}>
        <TemplateMenuItem icon={<Icon icon="arrow-right" size="4" variant="far" color="gray.500" />}>
          Move
        </TemplateMenuItem>
      </MoveTemplateButton>

      <DuplicateTemplateButton>
        <TemplateMenuItem icon={<Icon icon="copy" size="4" variant="far" color="gray.500" />}>
          Duplicate
        </TemplateMenuItem>
      </DuplicateTemplateButton>

      <ArchiveTemplateButton onArchived={() => menuContext.onClose()}>
        {({ templateStatus, isMutating }) => {
          const icon = isMutating ? (
            <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
          ) : (
            <Icon icon="archive" size="4" variant="far" color="gray.500" />
          );
          return (
            <TemplateMenuItem isDisabled={isMutating} icon={icon}>
              {templateStatus === TemplateStatus.Active ? 'Archive' : 'Unarchive'}
            </TemplateMenuItem>
          );
        }}
      </ArchiveTemplateButton>

      <MenuDivider />

      <DeleteTemplateButton
        onDeleted={() => menuContext.onClose()}
        templateId={templateId}
        view={view}
        disclosure={{ onOpen: () => setCloseOnBlur(false), onClose: () => setCloseOnBlur(true) }}
      >
        {({ isMutating }) => {
          const icon = isMutating ? (
            <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
          ) : (
            <Icon icon="trash-alt" size="4" variant="far" color="red.500" />
          );
          return (
            <TemplateMenuItem isDisabled={isMutating} color="red.500" icon={icon}>
              Delete
            </TemplateMenuItem>
          );
        }}
      </DeleteTemplateButton>
    </>
  );
};

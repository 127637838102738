import * as React from 'react';

import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Flex,
  MenuItem,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';

import { TemplateMenu } from 'features/template/components/template-menu';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';

import { TemplateType } from '@process-street/subgrade/process';
import { useDraftStatus } from '../../../draft-status-indicator/use-draft-status';
import { queryClient } from 'components/react-root';
import { DraftStatusIndicator } from '../../../draft-status-indicator';
import { EnterTitleLabel } from '../../../../constants';
import { WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  PublishDraftMutation,
} from 'features/template-revisions/query-builder';
import { useGetTemplateQuery } from 'features/template/query-builder';

export type EditButtonGroupProps = ButtonGroupProps & {
  nameInvalid: boolean;
};
export const EditButtonGroup: React.FC<React.PropsWithChildren<EditButtonGroupProps>> = ({ nameInvalid, ...props }) => {
  const { $state } = useInjector('$state');
  const {
    params: { id: templateId },
  } = $state;

  const templateMenuDisclosure = useDisclosure();

  const [draftStatus] = useDraftStatus();

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;

  const { data: templateRevision } = useNewestTemplateRevisionQuery({ templateId, editable: true });

  const tmplRevId = templateRevision?.id;

  const publishDraft = PublishDraftMutation.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }));
      await queryClient.invalidateQueries(WidgetsByTemplateRevisionIdQuery.getKey(tmplRevId));
      redirectToTemplateView();
    },
  });

  const canPublish = !nameInvalid && !!template && draftStatus === 'saved' && publishDraft.isIdle;

  const redirectToTemplateView = React.useCallback(() => {
    if (!template?.id || !template?.templateType) return;

    if (template.templateType === TemplateType.Page) {
      $state.go('pageView', { id: template.id });
    } else if ($state.params.groupId) {
      $state.go('templateView.task', { id: template.id, groupId: $state.params.groupId });
    } else {
      $state.go('templateView', { id: template.id });
    }
  }, [$state, template?.id, template?.templateType]);

  const handlePublishChanges = React.useCallback(() => {
    if (tmplRevId) {
      publishDraft.mutate({ tmplRevId });
    }
  }, [tmplRevId, publishDraft]);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <ButtonGroup spacing="4" ml="auto" alignItems="center" {...props}>
      <DraftStatusIndicator />
      {isSmallScreen ? (
        <TemplateMenu mode="edit" {...templateMenuDisclosure}>
          <MenuItem
            aria-label="publish changes"
            onClick={handlePublishChanges}
            isDisabled={!tmplRevId || !canPublish}
            color="gray.700"
          >
            Publish
          </MenuItem>
        </TemplateMenu>
      ) : (
        <>
          <TemplateMenu mode="edit" {...templateMenuDisclosure} />
          <Tooltip label={EnterTitleLabel} isDisabled={canPublish} hasArrow={true} placement="left">
            <Flex>
              <Button
                variant="cta"
                onClick={handlePublishChanges}
                isDisabled={!tmplRevId || !canPublish}
                color="gray.700"
                aria-label="publish changes"
              >
                Publish
              </Button>
            </Flex>
          </Tooltip>
        </>
      )}
    </ButtonGroup>
  );
};

import { Approval, ApprovalStatus } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid } from '@process-street/subgrade/core';
import { AxiosError, AxiosResponse } from 'axios';
import { FlashActions } from 'reducers/flash/flash.actions';
import { ReduxAppState } from 'reducers/types';
import { toFailure, toSuccess } from 'reducers/util/handlers';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { axiosService } from 'services/axios-service';
import { HttpStatus } from '@process-street/subgrade/util';

// Rules
export const APPROVAL_UPSERT_ALL_BY_TOKEN = 'frontStreet/approvals/UPSERT_ALL_BY_TOKEN';

export const EmailInitiatedApprovalActions = {
  upsertAllByToken:
    (
      taskId: Muid,
      action: ApprovalStatus,
      token: string,
    ): ThunkAction<void, ReduxAppState, Record<string, unknown>, AnyAction> =>
    (dispatch: ThunkDispatch<ReduxAppState, Record<string, unknown>, AnyAction>, _getState: () => ReduxAppState) => {
      axiosService
        .getAxios()
        .post(`/1/tasks/approvals/${action.toLowerCase()}/${token}`)
        .then(
          (response: AxiosResponse) => {
            const approvals = response.data as Approval[];
            dispatch({
              meta: { taskId },
              payload: approvals,
              type: toSuccess(APPROVAL_UPSERT_ALL_BY_TOKEN),
            });
          },
          (error: AxiosError) => {
            dispatch({
              meta: { taskId },
              type: toFailure(APPROVAL_UPSERT_ALL_BY_TOKEN),
            });

            let message = `Oops, the task(s) were not ${action.toLowerCase()}. Please contact support.`;
            if (error.response && error.response.status === HttpStatus.CONFLICT) {
              message = `You cannot ${action.toLowerCase()} tasks when checklist is not active.`;
            }
            dispatch(FlashActions.showDangerNotice(message));
          },
        );
    },
};

import { TaskTemplate, VideoWidget } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  DeleteWidgetByHeaderIdMutation,
  DeleteWidgetByHeaderIdMutationResponse,
  UpdateFormFieldValueMutationResponse,
  UpdateWidgetMutation,
  UpdateWidgetMutationResponse,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, send, sendParent } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { KeyboardEvent } from 'react';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { match } from 'ts-pattern';

type Context = {
  widget: VideoWidget;
  initialWidget?: VideoWidget;
  key?: string;
  uploadUrl?: string;
  isVideoUploading: boolean;
  recentlyMovedFrom?: TaskTemplate;
  errorMessage?: string;
};

type Event =
  | WidgetEvent<VideoWidget>
  | { type: 'CHANGE'; widget: VideoWidget }
  | { type: 'FOCUS' }
  | { type: 'BLUR' }
  | { type: 'DESCRIPTION_BLUR' }
  | { type: 'DESCRIPTION_CHANGE'; value: string }
  | { type: 'DESCRIPTION_KEY_DOWN'; event: KeyboardEvent<HTMLInputElement> }
  | { type: 'INVALID_URL'; errorMessage: string }
  | { type: 'TOGGLE_DESCRIPTION_EDIT' }
  | { type: 'VIDEO_LOADED' }
  | { type: 'VIDEO_UPLOADING' }
  | { type: 'VIDEO_UPLOAD_COMPLETE' }
  // Internal type
  | { type: 'done.invoke.updateWidget'; data: VideoWidget | undefined };

export type VideoContentMachine = ReturnType<typeof makeVideoContentMachine>;
export type VideoContentActorRef = ActorRefFrom<VideoContentMachine>;

export const makeVideoContentMachine = ({
  widget,
  sharedContext,
}: {
  widget: VideoWidget;
  sharedContext: SharedContext;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget.header.taskTemplate as TaskTemplate).templateRevision.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: `video-content-widget:${widget.id}`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
          deleteWidgetMutation: {
            data: DeleteWidgetByHeaderIdMutationResponse;
          };
          updateWidgetMutation: {
            data: UpdateWidgetMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./video-content-machine.typegen').Typegen0,
      context: {
        widget,
        initialWidget: widget,
        isVideoUploading: false,
        recentlyMovedFrom: undefined,
        errorMessage: undefined,
      } as Context,
      type: 'parallel',
      states: {
        input: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                MOVE_UP: {
                  actions: ['sendMoveUp'],
                },
                MOVE_DOWN: {
                  actions: ['sendMoveDown'],
                },
                DUPLICATE: {
                  actions: ['sendDuplicate'],
                },
                FOCUS: {
                  target: 'active',
                },
                VIDEO_LOADED: {
                  actions: ['assignIsVideoUploadingFalse'],
                },
                MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
                MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
                UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
              },
            },
            active: {
              on: {
                BLUR: {
                  target: 'idle',
                },
                CHANGE: {
                  target: 'idle',
                  actions: ['assignValue', 'sendUpdateWidgetMutationEvent'],
                },
                INVALID_URL: {
                  target: 'error',
                  actions: ['assignErrorMessage'],
                },
                VIDEO_UPLOADING: {
                  actions: ['assignIsVideoUploadingTrue'],
                },
                VIDEO_UPLOAD_COMPLETE: {
                  actions: ['assignIsVideoUploadingFalse'],
                },
              },
            },
            error: {
              on: {
                FOCUS: {
                  target: 'active',
                },
                CHANGE: {
                  target: 'idle',
                  actions: ['assignValue', 'sendUpdateWidgetMutationEvent'],
                },
              },
            },
          },
        },
        description: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                TOGGLE_DESCRIPTION_EDIT: {
                  target: 'editing',
                },
              },
            },
            editing: {},
            debouncing: {
              after: {
                DEBOUNCE_DELAY: {
                  actions: ['sendUpdateWidgetMutationEvent'],
                  target: 'editing',
                },
              },
            },
          },
          on: {
            DESCRIPTION_CHANGE: {
              target: '.debouncing',
              actions: ['assignDescription'],
            },
            DESCRIPTION_BLUR: {
              target: '.idle',
              actions: ['sendUpdateWidgetMutationEvent'],
            },
            DESCRIPTION_KEY_DOWN: {
              target: '.idle',
              cond: 'pressedEnter',
              actions: ['sendUpdateWidgetMutationEvent'],
            },
          },
        },
        mutation: {
          initial: 'idle',
          states: {
            idle: {
              id: 'idle',
              on: {
                DELETE_WIDGET: {
                  target: 'deleting',
                },
                UPDATE_WIDGET: {
                  target: 'updatingWidget',
                },
              },
            },
            deleting: {
              invoke: {
                src: 'deleteWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                },
              },
            },
            updatingWidget: {
              invoke: {
                src: 'updateWidgetMutation',
                onDone: {
                  target: 'idle',
                },
                onError: {
                  target: 'idle',
                },
              },
            },
          },
        },
      },
    },
    {
      guards: {
        pressedEnter: (_, e) => e.event.key === 'Enter',
      },
      actions: {
        assignValue: assign({
          widget: (_, e) => {
            return { ...e.widget };
          },
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        assignIsVideoUploadingTrue: assign({
          isVideoUploading: () => true,
        }),
        assignIsVideoUploadingFalse: assign({
          isVideoUploading: () => false,
        }),
        assignDescription: assign({
          widget: (context, e) => {
            if (!context.widget) {
              throw new Error('Widget is undefined');
            }

            return {
              ...context.widget,
              description: e.value,
            };
          },
        }),
        assignErrorMessage: assign({
          errorMessage: (_, event) => event.errorMessage,
        }),
        sendUpdateWidgetMutationEvent: send('UPDATE_WIDGET'),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget?.header,
                  ...{ ...(header as VideoWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
      },
      services: {
        updateWidgetMutation: async (context, evt) => {
          const widget = evt.widget ?? (context.widget as VideoWidget);
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<VideoWidget>(widget!),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidgetMutation: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget!.header.id),
            onSuccess: () => {
              cacheSetter.delete(context.widget!);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the widget`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
      delays: {
        DEBOUNCE_DELAY: 500,
      },
    },
  );
};

import * as React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { useSelectionContext } from 'pages/pages/_id/edit/page/selection-context';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { useSendRichEmailEditorRef } from './send-rich-email-plate-types';
import { insertText, setSelection } from '@udecode/slate';
import { focusEditor } from '@udecode/slate-react';
import { createMergeTag } from 'hooks/use-insert-merge-tag-callback';

// Separate component to get access to `lastSelection`.
export const MergeTagMenuWrapper: React.FC<React.PropsWithChildren<{ templateRevisionId: Muid }>> = ({
  templateRevisionId,
}) => {
  const editor = useSendRichEmailEditorRef();
  const { lastSelection } = useSelectionContext();
  return (
    <MergeTagsMenu
      {...{
        templateRevisionId,
        onSelect: (tag, _, fallback) => {
          // insert node
          insertText(editor, createMergeTag(tag, fallback), { at: lastSelection ?? [] });
          focusEditor(editor);
          if (lastSelection && !editor.selection) {
            setSelection(editor, lastSelection);
          }
        },
        mergeTagTarget: MergeTagsConstants.Target.GENERAL,
        menuButton: <MergeTagsMenuButton size="sm" />,
      }}
    />
  );
};

import * as React from 'react';
import { useDeleteChecklistsMutation, useUpdateChecklistsStatusMutation } from 'features/checklists/query-builder';
import { useChecklistScreenSlice } from '../ChecklistDashboardScreen/checklist-screen-store';
import { match, P } from 'ts-pattern';
import { useUnmount } from 'react-use';
import { SelectionBar } from 'components/selection-bar-actions';
import { Box, Icon, useToast } from 'components/design/next';
import { RowValues } from 'components/dashboard/models/grid';
import { RowNode } from '@ag-grid-community/core';
import { ProgressStatus } from '@process-street/subgrade/dashboard';
import { ChecklistStatus } from '@process-street/subgrade/process';
import pluralize from 'pluralize';

export type ChecklistDashboardSelectionBarActionsWrapperProps = {
  onActionCompleted: () => {};
  /** Archive not enabled for form responses. Copy changes according to mode. */
  mode: 'FormResponse' | 'WorkflowRun';
};

export const ChecklistDashboardSelectionBarActionsWrapper: React.FC<
  React.PropsWithChildren<ChecklistDashboardSelectionBarActionsWrapperProps>
> = ({ onActionCompleted, mode }) => {
  const isWorkflowRunMode = mode === 'WorkflowRun';

  const toast = useToast();
  const { selectedNodes, setSelectedNodes } = useChecklistScreenSlice();
  const count = selectedNodes.length;
  const itemName = isWorkflowRunMode ? 'workflow run' : 'form response';
  const pluralizedItemName = pluralize(itemName, count);

  const deleteChecklistsMutation = useDeleteChecklistsMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: `${count} ${pluralizedItemName} ${pluralize('have', count)} been deleted.`,
      });
      onActionCompleted();
      setSelectedNodes([]);
    },
  });
  const archiveChecklistsMutation = useUpdateChecklistsStatusMutation({
    onSuccess: (_, variables) => {
      const verb = match(variables.status)
        .with(ChecklistStatus.Active, () => 'unarchive')
        .with(ChecklistStatus.Archived, () => 'archive')
        .otherwise(() => 'update');

      toast({
        status: 'success',
        title: `${variables.checklistIds.length} ${pluralizedItemName} ${pluralize(
          'have',
          variables.checklistIds.length,
        )} been ${verb}d.`,
      });
      onActionCompleted();
      setSelectedNodes([]);
    },
  });

  const handleDelete = async (items: RowValues[]) => {
    return deleteChecklistsMutation.mutateAsync({ checklistIds: items.map(item => item.id) });
  };

  const handleArchive = async (items: RowValues[]) => {
    return archiveChecklistsMutation.mutateAsync({
      checklistIds: items.filter(item => item.ProgressStatus !== ChecklistStatus.Archived).map(item => item.id),
      status: ChecklistStatus.Archived,
    });
  };

  const handleUnarchive = async (items: RowValues[]) => {
    return archiveChecklistsMutation.mutateAsync({
      checklistIds: items.filter(item => item.ProgressStatus === ChecklistStatus.Archived).map(item => item.id),
      status: ChecklistStatus.Active,
    });
  };

  const stats = React.useMemo(() => {
    const data = { activeCount: 0, archivedCount: 0 };

    selectedNodes.forEach(item => {
      match(item.data)
        .with({ ProgressStatus: ProgressStatus.Archived }, () => {
          data.archivedCount++;
        })
        .with({ ProgressStatus: P.not(ProgressStatus.Archived) }, () => {
          data.activeCount++;
        })
        .otherwise(() => {});
    });

    return data;
  }, [selectedNodes]);

  const selectedItems = React.useMemo(
    () =>
      selectedNodes
        .filter((node): node is RowNode<RowValues> => Boolean(node.data))
        .map(node => node.data) as RowValues[],
    [selectedNodes],
  );

  useUnmount(() => {
    setSelectedNodes([]);
  });

  return (
    <SelectionBar.Actions selectedItems={selectedItems}>
      {stats.activeCount > 0 && mode !== 'FormResponse' && (
        <SelectionBar.Button
          confirmationBody={`You will still be able to access ${
            stats.activeCount > 1 ? 'them' : 'it'
          } using the filters in Reports.`}
          confirmationTitle={`Archive ${stats.activeCount} ${pluralize(itemName, stats.activeCount)}`}
          confirmationButtonText="Archive"
          isLoading={archiveChecklistsMutation.isLoading}
          onConfirm={handleArchive}
          leftIcon={<Icon icon="archive" size="4" />}
          minW={30}
        >
          Archive{' '}
          <Box as="span" display={{ base: 'none', md: 'block' }} ml="1">
            {stats.archivedCount > 0 ? `(${stats.activeCount})` : null}
          </Box>
        </SelectionBar.Button>
      )}

      {stats.archivedCount > 0 && mode !== 'FormResponse' && (
        <SelectionBar.Button<RowValues>
          confirmationBody={`${stats.archivedCount} ${pluralize(itemName, stats.archivedCount)} will be unarchived.`}
          confirmationTitle={`Unarchive ${stats.archivedCount} ${pluralize(itemName, stats.archivedCount)}`}
          confirmationButtonText="Unarchive"
          isLoading={archiveChecklistsMutation.isLoading}
          onConfirm={handleUnarchive}
          leftIcon={<Icon icon="undo" size="4" />}
          minW={30}
          display={{ base: 'none', md: 'flex' }}
        >
          Unarchive{' '}
          <Box as="span" display={{ base: 'none', md: 'block' }} ml="1">
            {stats.activeCount > 0 ? ` (${stats.archivedCount})` : null}
          </Box>
        </SelectionBar.Button>
      )}

      <SelectionBar.Button
        confirmationBody={`Your workflow runs will be deleted and irrecoverable.`}
        confirmationTitle={`Delete ${count} ${pluralizedItemName}`}
        confirmationButtonText="Delete"
        confirmationButtonProps={{ colorScheme: 'red', variant: 'solid' }}
        isLoading={deleteChecklistsMutation.isLoading}
        onConfirm={handleDelete}
        leftIcon={<Icon icon="trash" size="4" />}
        minW={30}
        color="red.400"
      >
        Delete
      </SelectionBar.Button>
    </SelectionBar.Actions>
  );
};

import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export type UploadUrlCoverImageMutationParams = {
  templateId: Template['id'];
  fileName: string;
  mimeType: string;
};

export type UploadUrlCoverImageMutationResponse = { url: string; key: string };

export const UploadUrlCoverImageMutation = {
  key: ['cover-image', 'presigned-url'],
  mutationFn: (params: UploadUrlCoverImageMutationParams) =>
    axiosService
      .getAxios()
      .post(`/1/templates/${params.templateId}/cover-image/upload-url`, params)
      .then(res => res.data),
};

export const useUploadUrlCoverImageMutation = (
  options: UseMutationOptions<UploadUrlCoverImageMutationResponse, AxiosError, UploadUrlCoverImageMutationParams> = {},
) => {
  return useMutation(UploadUrlCoverImageMutation.mutationFn, options);
};

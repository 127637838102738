import * as React from 'react';
import { useActor } from '@xstate/react';
import { AspectRatio, Box, FormLabel, HStack, IconButton, Image, Input, Spinner, Text, VStack } from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { ImageContentActorRef } from './image-content-machine';
import { match, P } from 'ts-pattern';
import { useDropzone } from 'react-dropzone';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';

export type ImageContentProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: ImageContentActorRef;
};

export const ImageContent: React.FC<React.PropsWithChildren<ImageContentProps>> = ({ actor, isFirst, isLast }) => {
  const [current, send] = useActor(actor);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { widget, recentlyMovedFrom } = current.context;

  const dropzoneState = useDropzone({
    onDragEnter: () => send({ type: 'FOCUS' }),
    onDragLeave: () => send({ type: 'BLUR' }),
    onDrop: files => send({ type: 'CHANGE', value: files[0] }),
    maxFiles: 1,
    accept: ['image/*'],
  });

  const previewUrl = React.useMemo(
    () =>
      match({ file: current.context.file, widget: current.context.widget })
        .with({ file: P.not(undefined) }, ({ file }) => URL.createObjectURL(file))
        .with({ widget: { file: { url: P.not(undefined) } } }, ({ widget }) => widget.file.url)
        .otherwise(() => undefined),
    [current.context.file, current.context.widget],
  );

  const content = (
    <Box
      display="flex"
      cursor="pointer"
      position="relative"
      bgColor={previewUrl ? undefined : 'brand.100'}
      alignItems="center"
      justifyContent="center"
      p={previewUrl ? undefined : '10'}
      borderWidth={dropzoneState.isDragActive ? 'thin' : '0'}
      borderStyle={dropzoneState.isDragActive ? 'dashed' : 'solid'}
      borderColor={dropzoneState.isDragActive ? 'brand.400' : 'gray.200'}
      w="full"
      maxW="175"
      onClick={() => {
        send('FOCUS');
        inputRef.current?.click();
      }}
    >
      {current.matches('mutation.uploading') && (
        <VStack
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          backgroundColor="blackAlpha.500"
        >
          <Spinner color="white" />
          <Text color="white">Uploading image</Text>
        </VStack>
      )}

      {dropzoneState.isDragActive && (
        <VStack
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          backgroundColor="blackAlpha.500"
        >
          <Text color="white">Drop the image here to upload</Text>
        </VStack>
      )}

      {previewUrl ? (
        <Image src={previewUrl} maxW="full" h="auto" />
      ) : (
        <Icon color="white" variant="fas" icon="mountain-sun" size="70%" />
      )}

      {!current.matches('mutation.uploading') && !previewUrl && (
        <IconButton colorScheme="brand" aria-label="Add image" position="absolute" top="5" right="5">
          <Icon icon="plus" color="white" size="5" />
        </IconButton>
      )}

      <Box sx={{ '> input': { visibility: 'hidden', w: 0, h: 0 } }}>
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={e => {
            const [file] = e.target.files ?? [];

            if (file) {
              send({ type: 'CHANGE', value: file });
            } else {
              send('BLUR');
            }
          }}
          {...dropzoneState.getInputProps()}
        />
      </Box>
    </Box>
  );

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack position="relative" w="full" alignItems="flex-start">
          {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          <Box {...dropzoneState.getRootProps()} w="full">
            {previewUrl ? (
              content
            ) : (
              <AspectRatio ratio={16 / 9} w="full" maxW="175">
                {content}
              </AspectRatio>
            )}
          </Box>
          {!current.matches('caption.idle') && previewUrl && (
            <Input
              autoFocus
              value={current.context.widget?.caption ?? ''}
              px="0"
              h="6"
              border="none"
              placeholder="Type label here"
              fontStyle="italic"
              color="gray.600"
              fontWeight="400"
              _focus={{ boxShadow: 'none' }}
              onBlur={() => send({ type: 'CAPTION_BLUR' })}
              onChange={e => send({ type: 'CAPTION_CHANGE', value: e.target.value })}
              onKeyDown={e => send({ type: 'CAPTION_KEY_DOWN', event: e })}
            />
          )}

          {current.matches('caption.idle') && previewUrl && (
            <HStack>
              <FormLabel
                m="0"
                color={current.context.widget?.caption ? 'gray.600' : 'gray.400'}
                fontWeight="400"
                fontStyle="italic"
              >
                {current.context.widget?.caption || 'Add caption (optional)'}
              </FormLabel>

              {current.matches('mutation.updatingWidget') ? (
                <Spinner />
              ) : (
                <>
                  <IconButton
                    minW="unset"
                    h="6"
                    opacity="0"
                    _groupHover={{ opacity: '1' }}
                    variant="ghost"
                    aria-label="Edit"
                    onClick={() => send({ type: 'TOGGLE_CAPTION_EDIT' })}
                  >
                    <Icon icon="edit" size="4" color="gray.500" />
                  </IconButton>
                </>
              )}
            </HStack>
          )}

          <WidgetListItemDragIcon />
          <FormsWidgetMenu>
            <FormsWidgetMenuItems.Duplicate />
            {widget && <FormsWidgetMenuItems.MoveToStep widget={widget} />}
            <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
            <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
            <FormsWidgetMenuItems.Delete />
          </FormsWidgetMenu>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};

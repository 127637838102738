import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule/approval-rule-subject.model';
import { Approval } from '@process-street/subgrade/approval-rule/approval.model';
import { Plan, PlanFeatureSet } from '@process-street/subgrade/billing';
import {
  AuthConnection,
  Group,
  GroupMembership,
  Muid,
  Organization,
  OrganizationDiscount,
  OrganizationDomain,
  OrganizationMembership,
  OrganizationStats,
  User,
} from '@process-street/subgrade/core';
import { ChecklistPermit, FolderPermit, TaskTemplatePermit, TemplatePermit } from '@process-street/subgrade/permission';
import {
  Attachment,
  Checklist,
  ChecklistRevision,
  ChecklistStats,
  ChecklistWidget,
  DueDateRuleDefinition,
  Folder,
  FormFieldValue,
  Task,
  TaskPermissionRule,
  TaskStats,
  TaskTemplate,
  Template,
  TemplateRevision,
  TemplateTaskAssignment,
  Webhook,
  Widget,
} from '@process-street/subgrade/process';
import {
  BaseFormFieldValueLookupState,
  BaseLookupState,
  BaseReduxEntitiesState,
  BaseReduxState,
  BaseSessionState,
  BaseWidgetLookupState,
  EntityMap,
  LookupMap,
  ReferenceMap,
} from '@process-street/subgrade/redux/types';
import { ChecklistAssignment, TaskAssignment, TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { OverdueTaskDto } from 'components/dashboard/models/grid';
import { SavedView } from 'components/dashboard/models/saved-views';
import { FileUploadModuleState } from 'components/file-upload/store/file-upload.types';
import { ChecklistMigrationModuleState } from 'components/migration/store/migration.types';
import { NavigationModuleState } from 'reducers/navigation/navigation.reducer';
import { FeatureFlags } from 'services/features/feature-flags/feature-flag-constants';
import { FormFieldValueModuleState } from 'components/form-field-value/store/form-field-value.reducer';

export interface EntitiesState extends BaseReduxEntitiesState {
  approvalRuleSubject: EntityMap<ApprovalRuleSubject>;
  approval: EntityMap<Approval>;
  attachment: EntityMap<Attachment>;
  authConnection: EntityMap<AuthConnection>;
  checklistAssignments: EntityMap<ChecklistAssignment>;
  checklistPermits: EntityMap<ChecklistPermit>;
  checklistRevision: EntityMap<ChecklistRevision>;
  checklistWidget: EntityMap<ChecklistWidget>;
  checklists: EntityMap<Checklist>;
  checklistTaskAssignments: EntityMap<TaskAssignment>;
  dynamicDueDate: EntityMap<DueDateRuleDefinition>;
  folderPermits: EntityMap<FolderPermit>;
  folders: EntityMap<Folder>;
  formFieldValue: EntityMap<FormFieldValue>;
  groupMemberships: EntityMap<GroupMembership>;
  groups: EntityMap<Group>;
  organizationDomain: EntityMap<OrganizationDomain>;
  organizationMemberships: EntityMap<OrganizationMembership>;
  organizations: EntityMap<Organization>;
  organizationStats: EntityMap<OrganizationStats>;
  planFeatureSet: EntityMap<PlanFeatureSet>;
  plans: EntityMap<Plan>;
  roleAssignmentRules: EntityMap<TaskAssignmentRule>;
  task: EntityMap<Task>;
  taskPermissionRules: EntityMap<TaskPermissionRule>;
  taskStats: EntityMap<TaskStats>;
  taskTemplatePermits: EntityMap<TaskTemplatePermit>;
  taskTemplates: EntityMap<TaskTemplate>;
  templatePermits: EntityMap<TemplatePermit>;
  templateRevision: EntityMap<TemplateRevision>;
  templates: EntityMap<Template>;
  templateTaskAssignments: EntityMap<TemplateTaskAssignment>;
  users: EntityMap<User>;
  webhooks: EntityMap<Webhook>;
  widgets: EntityMap<Widget>;
}

export interface TemplateLookups {
  forSchedule: Muid[];
}

export interface TaskTemplateLookups {
  byChecklistRevisionId: LookupMap;
  byTemplateRevisionId: LookupMap;
}

export interface PaginatedTemplatePermits {
  hasMore: boolean;
  permits: Muid[];
  totalCount: number;
}

export interface PaginatedTemplatePermitsMap {
  [templateId: string]: PaginatedTemplatePermits;
}

export interface TemplatePermitLookups {
  byTemplateId: PaginatedTemplatePermitsMap;
}

export interface FolderPermitLookups {
  byFolderId: LookupMap;
}

export interface WidgetLookups extends BaseWidgetLookupState {
  byChecklistRevisionId: LookupMap;
  byTaskTemplateId: LookupMap;
}

export interface ChecklistWidgetLookups {
  byChecklistRevisionId: LookupMap;
}

export interface TaskStatsLookups {
  byChecklistId: LookupMap;
  byChecklistRevisionId: LookupMap;
}

export interface TaskAssignmentLookups {
  byTaskId: LookupMap;
}

export interface ApprovalLookups {
  byChecklistRevisionId: LookupMap;
}

export interface ApprovalRuleSubjectLookups {
  byTemplateRevisionId: LookupMap;
}

export interface AuthConnectionLookups {
  byOrganizationId: LookupMap;
}

export interface DynamicDueDateRuleDefinitionLookups {
  byTemplateRevisionId: LookupMap;
  byChecklistRevisionId: LookupMap;
}

export interface RoleAssignmentRulesLookups {
  byTemplateRevisionId: LookupMap;
}

export interface TaskPermissionRuleLookups {
  byTaskTemplateGroupId: LookupMap;
  byTemplateRevisionId: LookupMap;
}

export interface TemplateTaskAssignmentLookups {
  byTemplateRevisionId: LookupMap;
}

export interface TaskTemplatePermitLookups {
  byTaskTemplateId: LookupMap;
}

export interface FormFieldValueLookupState extends BaseFormFieldValueLookupState {
  byChecklistRevisionId: LookupMap;
}

export interface UsersLookupState {
  byEmail: ReferenceMap;
}

export interface LookupState extends BaseLookupState {
  approvalRuleSubject: ApprovalRuleSubjectLookups;
  approval: ApprovalLookups;
  authConnection: AuthConnectionLookups;
  checklistWidget: ChecklistWidgetLookups;
  checklistTaskAssignments: TaskAssignmentLookups;
  dynamicDueDate: DynamicDueDateRuleDefinitionLookups;
  folderPermits: FolderPermitLookups;
  roleAssignmentRules: RoleAssignmentRulesLookups;
  taskPermissionRules: TaskPermissionRuleLookups;
  taskStats: TaskStatsLookups;
  taskTemplatePermits: TaskTemplatePermitLookups;
  taskTemplates: TaskTemplateLookups;
  templatePermits: TemplatePermitLookups;
  templates: TemplateLookups;
  templateTaskAssignments: TemplateTaskAssignmentLookups;
  formFieldValue: Readonly<FormFieldValueLookupState>;
  users: Readonly<UsersLookupState>;
  widget: Readonly<WidgetLookups>;
}

export interface OrganizationMembershipLookups {
  byUserId: LookupMap;
}

export interface Status {
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

export interface ApprovalRuleSubjectStatuses {
  byTemplateRevisionId: EntityMap<Status>;
}

export interface WidgetStatuses {
  byTemplateRevisionId: EntityMap<Status>;
}

export interface TaskTemplateStatuses {
  byTemplateRevisionId: EntityMap<Status>;
}

export interface BulkApprovalStatuses {
  byTaskId: EntityMap<Status>;
}

export interface TemplateStatuses {
  byStatus: EntityMap<Status>;
}

export interface OrganizationMembershipStatuses {
  byOrganizationId: EntityMap<Status>;
  byUserId: EntityMap<Status>;
}

export interface TemplatePermitsStatuses {
  byTemplateId: EntityMap<Status>;
}

export interface TaskUpdateStatuses {
  byTaskId: EntityMap<Status>;
}

export interface Statuses {
  approvalRuleSubject: ApprovalRuleSubjectStatuses;
  bulkApprovals: BulkApprovalStatuses;
  organizationMemberships: OrganizationMembershipStatuses;
  taskTemplates: TaskTemplateStatuses;
  templatePermits: TemplatePermitsStatuses;
  templates: TemplateStatuses;
  widgets: WidgetStatuses;
  taskUpdates: TaskUpdateStatuses;
  currentTemplateRevision: Status;
  featureFlags: Status;
}

export interface OrganizationDiscountMap {
  [key: string]: OrganizationDiscount;
}

export interface OrganizationModule {
  discount: OrganizationDiscountMap;
}

export interface PropertyMap<T> {
  [key: string]: T;
}

export interface ChecklistDashboardSettings {
  selectedSavedView: PropertyMap<string>;
}

export type OrganizationSubdomainsMap = { [key: Organization['id']]: string };

export interface SessionState extends BaseSessionState {
  checklistDashboard: ChecklistDashboardSettings;
  checklistEditor: PropertyMap<string>;
  checklistStats: PropertyMap<string>;
  defaultHomepage: string;
  onboarding: PropertyMap<boolean>;
  inbox: PropertyMap<string>;
  organizationUseFrequencyMap: PropertyMap<number>;
  profile: Record<string, unknown>;
  selectedOrganizationId: Muid | null;
  token: string;
  templateEditor: PropertyMap<string>;
  user: User | null;
  organizationMemberships: OrganizationMembership[];
  elevioHash: string;
  intercomHash: string;
  organizationSubdomainsMap: OrganizationSubdomainsMap;
}

export interface ChecklistLookup {
  byTemplate: LookupMap;
  stats: EntityMap<ChecklistStats>;
}

export interface TaskLookup {
  byChecklist: LookupMap;
  // taskId to user id
  toAssignees: LookupMap;
}

export interface TemplateRevisionLookup {
  byTemplateId: LookupMap;
  newestByTemplateId: LookupMap;
}

export const Modules = {
  APPROVAL: 'approval',
  CHECKLIST_MIGRATION: 'checklistMigration',
  EMAIL_INITIATED_APPROVAL: 'emailInitiatedApproval',
  FILE_UPLOAD: 'fileUpload',
  LOCATION: 'location',
  TEMPLATE_MIGRATION: 'templateMigration',
};

export interface MigrationStats {
  countTotal: number;
  countScheduled?: number;
  countMigrating?: number;
  countMigrated?: number;
}

export enum PollingStatus {
  ON = 'on',
  OFF = 'off',
}

export interface TemplateMigrationState {
  stats?: MigrationStats;
  templateRevisionId: Muid;
  pollingStatus: PollingStatus;
}

export type TemplateMigrationModuleState = { [key in Muid]: TemplateMigrationState };

export interface EmailInitiatedApprovalState {
  approvals: Approval[];
}

export type EmailInitiatedApprovalModuleState = { [key in Muid]: EmailInitiatedApprovalState };

export interface ChecklistDashboardModuleState {
  savedViews: SavedView[];
  savedViewsLoaded: boolean;
  overdueTasks: PropertyMap<OverdueTaskDto[]>;
  overdueTasksLoaded: PropertyMap<boolean>;
}

export interface ModulesState {
  checklistDashboard: ChecklistDashboardModuleState;
  checklistMigration: ChecklistMigrationModuleState;
  emailInitiatedApproval: EmailInitiatedApprovalModuleState;
  featureFlag: FeatureFlags;
  fileUpload: FileUploadModuleState;
  location: Record<string, unknown>;
  navigation: NavigationModuleState;
  templateMigration: TemplateMigrationModuleState;
  formFieldValue: FormFieldValueModuleState;
}

export interface ReduxAppState extends BaseReduxState {
  entities: Readonly<EntitiesState>;
  session: Readonly<SessionState>;
  templateRevision: Readonly<TemplateRevisionLookup>;
  checklist: Readonly<ChecklistLookup>;
  task: Readonly<TaskLookup>;
  organization: Readonly<OrganizationModule>;
  lookups: LookupState;
  statuses: Statuses;
  organizationMembership: OrganizationMembershipLookups;
  modules: Readonly<ModulesState>;
}

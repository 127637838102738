import {
  AutomationAppName,
  isTemplateSolutionInstance,
  isTrayAutomationAppName,
  isIncomingWebhookAppName,
  stripAutomationAppNamePrefix,
  getPrefixedAutomationAppName,
} from '@process-street/subgrade/automation';
import { useAutomationAppQuery } from '../automation-app-query';
import { useAutomationSelector } from '../../selector/context';
import { useGetAutomationInstanceQuery } from '../../../utils/use-get-automation-instance';
import { match } from 'ts-pattern';
import { useQueryClient } from 'react-query';
import {
  GetAllIncomingWebhooksByTemplateIdQuery,
  GetAllIncomingWebhooksByTemplateIdQueryResponse,
  useCreateIncomingWebhookMutation,
} from 'features/automations/query-builder';
import { MuidUtils } from '@process-street/subgrade/core';
import { useTemplateId } from '../../../utils/use-template-id';
import { Option } from 'space-monad';

export type Props = {
  automationApp: AutomationAppName;
};

export function useAutomationAppButton({ automationApp }: Props) {
  const {
    data: { enabled: hasAccess, automationIsPremium },
    status,
  } = useAutomationAppQuery(automationApp);

  const [state, send] = useAutomationSelector();
  const { automationInstanceId, automationType, automationAppName: selectedAutomationAppName } = state.context;
  const automationInstanceQuery = useGetAutomationInstanceQuery({
    automationInstanceId,
    automationInstanceType: automationType,
  });
  const automationInstance = automationInstanceQuery.data;

  const isSelected =
    (selectedAutomationAppName ?? Option(automationInstance).map(getPrefixedAutomationAppName).get()) === automationApp;

  const isDisabled = match(automationInstance)
    .when(isTemplateSolutionInstance, () => true)
    .otherwise(() => false);

  const templateId = useTemplateId();
  const queryClient = useQueryClient();
  const createWebhookMutation = useCreateIncomingWebhookMutation({
    onMutate: vars => {
      queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
        GetAllIncomingWebhooksByTemplateIdQuery.getKey({ templateId: vars.templateId }),
        current => {
          return [...(current ?? []), { ...vars, status: 'Disabled' }];
        },
      );
    },
    onSuccess: webhook => {
      if (templateId) {
        queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
          GetAllIncomingWebhooksByTemplateIdQuery.getKey({ templateId }),
          current => {
            return (current ?? []).map(w => (w.id === webhook.id ? webhook : w));
          },
        );

        send({
          type: 'AUTOMATION_CONFIGURED',
          payload: {
            automationType: 'webhook',
            id: webhook.id,
          },
        });
      }
    },
  });

  const setAutomationApp = (automationApp: AutomationAppName) => {
    match<AutomationAppName>(automationApp)
      .when(isTrayAutomationAppName, () => {
        send({
          type: 'AUTOMATION_PLANTED',
          payload: {
            automationAppName: automationApp,
          },
        });
      })
      .when(isIncomingWebhookAppName, automationApp => {
        if (!templateId) return;

        createWebhookMutation.mutate(
          {
            templateId,
            id: MuidUtils.randomMuid(),
            name: '',
            config: {
              checklistProperties: {},
              fields: {},
            },
            automationApp: stripAutomationAppNamePrefix(automationApp),
            status: 'Disabled',
            webhookType: 'Workflow',
          },
          {
            onSuccess: webhook => {
              send({
                type: 'AUTOMATION_CONFIGURED',
                payload: { id: webhook.id, automationType: 'webhook' },
              });
            },
          },
        );
      })
      .run();
  };

  return {
    hasAccess,
    automationIsPremium,
    status,
    setAutomationApp,
    isSelected,
    isDisabled,
  };
}

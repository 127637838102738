import React from 'react';

import { match, P } from 'ts-pattern';
import { useHover } from 'react-use';

import { Template } from '@process-street/subgrade/process';
import { CoverIcon as CoverIconType } from '@process-street/subgrade/core';
import { Flex, FlexProps, HStack, Icon, Text } from 'components/design/next';
import { CDNImage } from 'components/cdn-image';

import { EditIconLayer } from '../common/edit-icon-layer';
import { CoverIconUploadPopover } from '../common/popover';
import { TypographyProps } from '@chakra-ui/react';

type TemplateCoverIconBoxProps = {
  children: (props: { hovered: boolean }) => React.ReactElement;
} & Omit<FlexProps, 'children'>;

const HoverableContainer = ({ children, ...props }: TemplateCoverIconBoxProps) => {
  const [hoverable] = useHover((hovered: boolean) => (
    <Flex
      data-testid="cover-icon-container"
      alignItems="center"
      justifyContent="center"
      height="15"
      width="15"
      position="relative"
      ml="5"
      bg="gray.50"
      borderRadius="50%"
      borderWidth="base"
      borderColor="gray.100"
      borderStyle="solid"
      {...props}
    >
      {children({ hovered })}
    </Flex>
  ));
  return hoverable;
};

type Components = Partial<{
  triggerButton: React.ReactElement;
}>;

export type TemplateCoverIconProps = {
  templateId: Template['id'];
  icon?: CoverIconType;
  editable?: boolean;
  imageHeight?: number;
  imageWidth?: number;
  emojiFontSize?: TypographyProps['fontSize'];
  components?: Components;
} & FlexProps;

export const TemplateCoverIcon: React.FC<React.PropsWithChildren<TemplateCoverIconProps>> = ({
  templateId,
  icon,
  editable,
  imageHeight,
  imageWidth,
  emojiFontSize,
  components,
  ...props
}) => {
  return (
    <>
      {match<[CoverIconType | undefined, boolean | undefined]>([icon, editable])
        .with([undefined, true], () => (
          <CoverIconUploadPopover templateId={templateId}>
            {components?.triggerButton ? (
              components.triggerButton
            ) : (
              <HStack role="button" cursor="pointer" spacing="2">
                <Icon icon="smile" size="4" color="gray.400" />
                <Text aria-label="add icon" color="gray.400">
                  Add icon
                </Text>
              </HStack>
            )}
          </CoverIconUploadPopover>
        ))
        .with([{ emoji: P.not(undefined) }, P.any], ([icon, _]) => {
          return (
            <HoverableContainer zIndex="1" {...props}>
              {({ hovered }) => {
                return (
                  <>
                    <Text fontSize={emojiFontSize ?? '2xl'} align="center">
                      {icon.emoji}
                    </Text>
                    {editable && hovered && <EditIconLayer templateId={templateId} />}
                  </>
                );
              }}
            </HoverableContainer>
          );
        })
        .with([{ s3File: P.not(undefined) }, P.any], ([icon, _]) => {
          return (
            <HoverableContainer zIndex="1" {...props}>
              {({ hovered }) => {
                return (
                  <>
                    <CDNImage
                      alt={icon.s3File.originalName}
                      s3File={icon.s3File}
                      transformation={{
                        height: imageHeight ?? 60,
                        width: imageWidth ?? 60,
                      }}
                      borderRadius="50%"
                    />

                    {editable && hovered && <EditIconLayer templateId={templateId} />}
                  </>
                );
              }}
            </HoverableContainer>
          );
        })
        .otherwise(() => null)}
    </>
  );
};

import { Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import {
  DueDateRuleDefinition,
  TaskTemplate,
  TemplateRevision,
  TaskTemplateTaskType,
  Widget,
  isFormFieldWidget,
} from '@process-street/subgrade/process';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import {
  Box,
  ButtonProps,
  Center,
  HStack,
  Icon,
  Input,
  MotionWrapper,
  Spinner,
  Text,
} from 'app/components/design/next';
import { ReorderUtils } from 'app/pages/forms/_id/edit/components/widgets-list/reorder-utils';
import {
  FormEditorPageActorSelectors,
  FormEditorPageMachine,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { KeyStrings } from 'app/services/key';
import { Reorder } from 'framer-motion';
import * as React from 'react';
import { TaskListItemAutomationIndicator } from '../task-list-item-automation-indicator';
import { TaskListItemConditionalLogicIndicator } from '../task-list-item-conditional-logic-indicator';
import { TaskListItemDynamicDueDateIndicator } from '../task-list-item-dynamic-due-date-indicator';
import { TaskListItemMenu } from '../task-list-item-menu';
import { TaskListItemAiTaskIndicator } from './task-list-item-ai-task-indicator';
import { TaskListItemAvatar } from './task-list-item-avatar';
import { useHighlightedTasksContext } from '../../context';
import { useSelector } from '@xstate/react';
import { StateFrom } from 'xstate';
import _isEqual from 'lodash/isEqual';
import { Option } from 'space-monad';

export type TaskListItemProps = {
  templateRevision: TemplateRevision;
  taskTemplate: TaskTemplate;
  isSelected: boolean;
  isLast: boolean;
  stepNumber: number;
  templateId: Muid;
  organizationMemberships: OrganizationMembershipWithUser[];
  assignmentRules: TaskAssignmentRule[];
  dueDateRuleDefinition?: DueDateRuleDefinition;
  isMultiSelecting?: boolean;
  isGenerating?: boolean; // Indicates whether or not the workflow is being generated
  isAnimating?: boolean; // Indicates whether or not the current task list item is being animated
  isTaskTemplateAssociatedWithRules: (args: { widgets: Array<Widget>; taskTemplate: TaskTemplate }) => boolean;
  onChange: (taskTemplateId: TaskTemplate['id'], taskTemplate: Partial<TaskTemplate>) => void;
  onDelete: (taskTemplateId: TaskTemplate['id']) => void;
  onAddNewTask: (at: number) => void;
  onSelect: (taskTemplate: TaskTemplate, e: React.MouseEvent) => void;
  onNavigateBetweenTasks: (toStepNumber: number, event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const listItemAnimationVariants = {
  initial: {
    opacity: 0,
    y: -10,
  },
  animate: ({ index, skipDelay }: { index: number; skipDelay: boolean }) => {
    return {
      opacity: 1,
      y: 0,
      transition: {
        delay: skipDelay ? 0 : 0.08 * Math.min(index, 10),
      },
    };
  },
};

export const TaskListItem = React.memo((props: TaskListItemProps) => {
  const {
    templateRevision,
    taskTemplate,
    isSelected,
    templateId,
    stepNumber,
    organizationMemberships,
    assignmentRules,
    dueDateRuleDefinition,
    isGenerating,
    isAnimating,
    isMultiSelecting,
    isTaskTemplateAssociatedWithRules,
    onChange,
    onAddNewTask,
    onDelete,
    onSelect,
    onNavigateBetweenTasks,
  } = props;
  const [name, setName] = React.useState(taskTemplate.name);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { highLightedTasksMap } = useHighlightedTasksContext();
  const shouldHighlight = Boolean(highLightedTasksMap && highLightedTasksMap[taskTemplate.group.id]);
  // Don't apply the animation delay when it's a fresh task template
  const skipAnimationDelay = Date.now() - taskTemplate.audit.createdDate < 1000;

  const actor = useFormEditorPageActorRef();

  const dueDateFormFieldSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(dueDateRuleDefinition?.formFieldWidgetGroup?.id)
        .map(widgetGroupId => FormEditorPageActorSelectors.getWidgetByGroupId(widgetGroupId)(state))
        .filter(isFormFieldWidget)
        .get();
    },
    [dueDateRuleDefinition],
  );

  const dueDateTaskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(dueDateRuleDefinition?.formFieldWidgetGroup?.id)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [dueDateRuleDefinition],
  );

  const getChildWidgetsSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return FormEditorPageActorSelectors.getWidgetsForTaskTemplateId(taskTemplate.id)(state);
    },
    [taskTemplate.id],
  );

  const childWidgets = useSelector(actor, getChildWidgetsSelector, _isEqual);
  const dueDateFormField = useSelector(actor, dueDateFormFieldSelector, _isEqual);
  const dueDateTaskTemplate = useSelector(actor, dueDateTaskTemplateSelector, _isEqual);

  const buttonStyles = React.useMemo(() => createWrapperStyles(props, shouldHighlight), [props, shouldHighlight]);

  const hasConditionalLogic = React.useMemo(
    () =>
      isTaskTemplateAssociatedWithRules({
        widgets: childWidgets,
        taskTemplate,
      }),
    [childWidgets, isTaskTemplateAssociatedWithRules, taskTemplate],
  );

  React.useEffect(() => {
    if (isSelected && !isMultiSelecting && !isGenerating && inputRef?.current) {
      setTimeout(() => {
        if (inputRef?.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [isSelected, isMultiSelecting, isGenerating]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    onChange(taskTemplate.id, {
      name: e.currentTarget.value,
    });
  };

  const handleNameBlur = (_: React.FocusEvent<HTMLInputElement>) => {
    if (taskTemplate.name !== name) {
      onChange(taskTemplate.id, { name });
    }
  };

  const handleSelect = (e: React.MouseEvent<HTMLParagraphElement>) => {
    onSelect(taskTemplate, e);
  };

  React.useEffect(
    function syncInternalName() {
      const isFocused = inputRef.current === document.activeElement;
      if (props.taskTemplate.name !== name && !isFocused) {
        setName(props.taskTemplate.name);
      }
    },
    // Removed `name` from the array, so we don't trigger this effect when internal state changes
    // eslint-disable-next-line
    [props.taskTemplate.name],
  );

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KeyStrings.ARROW_UP:
        onNavigateBetweenTasks(stepNumber - 1, e);
        break;
      case KeyStrings.ARROW_DOWN:
        onNavigateBetweenTasks(stepNumber + 1, e);
        break;
      case KeyStrings.ENTER:
        onAddNewTask(stepNumber - 1);
        break;
      case KeyStrings.BACKSPACE:
        if (!name) {
          onDelete(taskTemplate.id);
        }
        break;
      default:
        return;
    }
  };

  return (
    <MotionWrapper
      key={taskTemplate.id}
      variants={listItemAnimationVariants}
      initial="initial"
      animate="animate"
      custom={{ index: stepNumber - 1, skipDelay: skipAnimationDelay }}
    >
      <Box w="full">
        <HStack as={Reorder.Item} value={taskTemplate} w="full">
          <HStack
            w="full"
            onPointerDownCapture={e => {
              const target = e.target as HTMLElement;
              const isDragHandle = ReorderUtils.isTargetDragHandle(target);

              // allow reordering from verified handles (e.g., select dropdown choices)
              if (isDragHandle) return;
              e.stopPropagation();
            }}
            spacing="0"
          >
            <Box
              p={2}
              pl={0}
              pr={3}
              cursor="grab"
              onMouseDown={() => actor.send('REORDER_TASK_TEMPLATES_DRAG_START')}
              onTouchStart={() => actor.send('REORDER_TASK_TEMPLATES_DRAG_START')}
              {...ReorderUtils.HANDLE_PROPS}
            >
              <Center
                minW={5}
                w={5}
                h={5}
                bgColor={isSelected ? 'brand.500' : 'transparent'}
                borderRadius="full"
                role="group"
                _hover={{ bgColor: 'transparent' }}
                position="relative"
              >
                <Text
                  fontSize="sm"
                  fontWeight={isSelected ? '700' : '400'}
                  color={isSelected ? 'white' : 'gray.400'}
                  _groupHover={{ visibility: 'hidden' }}
                  visibility="visible"
                  position="absolute"
                >
                  {taskTemplate.taskType === TaskTemplateTaskType.AI ? 'AI' : stepNumber}
                </Text>

                <Icon
                  visibility="hidden"
                  _groupHover={{ visibility: 'visible' }}
                  color="gray.400"
                  icon="grip-vertical"
                  size="3.5"
                  variant="fas"
                  position="absolute"
                />

                {taskTemplate.stop && (
                  <Box mt={8}>
                    <Icon
                      aria-label="Stop task"
                      icon="hand-paper"
                      size="3"
                      color={isSelected ? 'brand.500' : 'gray.400'}
                    />
                  </Box>
                )}
              </Center>
            </Box>

            <Box {...buttonStyles} role="group">
              <HStack>
                {isSelected && !isMultiSelecting && !isGenerating ? (
                  <Input
                    ref={inputRef}
                    id={`task-template-${taskTemplate.id}`}
                    w="full"
                    flex="1"
                    value={name}
                    _focusVisible={{ borderColor: 'transparent', boxShadow: 'unset' }}
                    border={0}
                    px={3}
                    py={2.5}
                    onKeyUp={handleKeyUp}
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    mt="-1px"
                    placeholder="Type task name here"
                    autoFocus
                  />
                ) : (
                  <Text
                    role="button"
                    aria-label={taskTemplate.name}
                    onClick={handleSelect}
                    flex="1"
                    w="full"
                    h={10}
                    pl={3}
                    lineHeight="40px"
                    mt="-1px"
                    textAlign="left"
                    fontSize="md"
                    noOfLines={1}
                  >
                    {isGenerating ? taskTemplate.name : name}
                  </Text>
                )}

                {isAnimating && <Spinner />}

                <HStack h={10} mt="-1px" spacing={1}>
                  <TaskListItemMenu
                    taskTemplate={taskTemplate}
                    templateRevision={templateRevision}
                    hasConditionalLogic={hasConditionalLogic ?? false}
                    isSelected={isSelected}
                    onDelete={() => onDelete(taskTemplate.id)}
                    onAddStopTask={onChange}
                  />

                  {hasConditionalLogic && <TaskListItemConditionalLogicIndicator />}

                  {dueDateRuleDefinition && (
                    <TaskListItemDynamicDueDateIndicator
                      ruleDefinition={dueDateRuleDefinition}
                      formFieldWidget={dueDateFormField}
                      taskTemplate={dueDateTaskTemplate}
                    />
                  )}

                  {taskTemplate.taskType === TaskTemplateTaskType.AI && <TaskListItemAiTaskIndicator />}

                  <TaskListItemAutomationIndicator
                    isActive
                    templateRevision={templateRevision}
                    taskTemplate={taskTemplate}
                    editable
                    isDisabled={false}
                    templateId={templateId}
                  />

                  <TaskListItemAvatar
                    taskTemplate={taskTemplate}
                    templateRevision={templateRevision}
                    taskAssignmentRules={assignmentRules}
                    organizationMemberships={organizationMemberships}
                  />
                </HStack>
              </HStack>
            </Box>
          </HStack>
        </HStack>
      </Box>
    </MotionWrapper>
  );
});

TaskListItem.displayName = 'TaskListItem';

const createWrapperStyles = (
  { isSelected, taskTemplate, stepNumber, isLast }: TaskListItemProps,
  shouldHighlight: boolean,
): Partial<ButtonProps> => {
  const isFirst = stepNumber === 1;
  return {
    'w': 'full',
    'justifyContent': 'flex-start',
    'fontWeight': isSelected ? '600' : taskTemplate.name?.endsWith(':') ? '700' : '400',
    'noOfLines': 1,
    'size': 'sm',
    'borderStyle': 'solid',
    'borderWidth': 'thin',
    'borderColor': isSelected ? 'brand.500' : 'gray.200',
    'borderRadius': 0,
    'h': 10,
    'pr': 1,
    'color': isSelected ? 'brand.600' : 'gray.600',
    'borderTopLeftRadius': isFirst ? '8px' : undefined,
    'borderTopRightRadius': isFirst ? '8px' : undefined,
    'borderBottomLeftRadius': isLast ? '8px' : undefined,
    'borderBottomRightRadius': isLast ? '8px' : undefined,
    'borderTopColor': isSelected || isFirst ? undefined : 'transparent',
    'isActive': isSelected,
    'aria-selected': isSelected,
    'bgColor': shouldHighlight ? 'brand.200' : 'white',
  };
};

import React from 'react';
import { Text, VStack, Link } from 'components/design/next';
import { CustomNotificationConfigRow } from './custom-notification-config-row';
import groupBy from 'lodash/groupBy';
import { match } from 'ts-pattern';
import { useCustomNotificationsStore } from './custom-notifications-store';
import { CustomNotificationConfigProvider } from './custom-notification-config-provider';
import { CustomNotificationUtils } from './custom-notification-utils';
import { CustomNotificationIcon } from 'features/custom-notifications/components/custom-notification-icon';
import { Muid } from '@process-street/subgrade/core';

type CustomNotificationConfigListProps = {
  taskTemplatesMap: Map<Muid, { name?: string; index: number }>;
  serverDataLength: number;
};

export const CustomNotificationConfigList: React.FC<React.PropsWithChildren<CustomNotificationConfigListProps>> = ({
  taskTemplatesMap,
  serverDataLength,
}) => {
  const store = useCustomNotificationsStore();

  const referenceToGroup = React.useCallback(
    (config?: Partial<CustomNotificationUtils.Config>) => {
      if (taskTemplatesMap.size === 0) return 'Other';

      return match(config)
        .with({ timing: { entity: { type: 'WorkflowRun' } } }, () => 'Workflow run')
        .with(
          { timing: { entity: { type: 'Task' } } },
          ({
            timing: {
              entity: { taskTemplateId },
            },
          }) => {
            const { index, name } = taskTemplatesMap.get(taskTemplateId) || { index: 0, name: 'Loading...' };
            return `${index}. ${name || 'Unnamed task'}`;
          },
        )
        .otherwise(() => 'Other');
    },
    [taskTemplatesMap],
  );

  const customNotificationConfigs = React.useMemo(
    () => groupBy(store.configs, config => referenceToGroup(config)),
    [referenceToGroup, store.configs],
  );

  const isEmpty = store.configs.length === 0 && serverDataLength === 0;
  const hasBecomeEmpty = store.configs.length === 0 && serverDataLength !== 0;

  return (
    <>
      {Object.entries(customNotificationConfigs).map(([groupName, configs]) => (
        <VStack alignItems="stretch" spacing="2" key={groupName}>
          <Text variant="-2u" color="gray.400">
            {groupName}
          </Text>

          {configs.map(config => (
            <CustomNotificationConfigProvider key={config.nativeAutomationId} config={config}>
              <CustomNotificationConfigRow />
            </CustomNotificationConfigProvider>
          ))}
        </VStack>
      ))}
      {isEmpty || hasBecomeEmpty ? (
        <VStack spacing="3">
          <CustomNotificationIcon
            py="2"
            bellProps={{
              size: '12',
              variant: 'fad',
              primaryLayer: { color: 'brand.500' },
              secondaryLayer: { color: 'brand.200', opacity: 1 },
            }}
            clockProps={{
              outline: '2px solid white',
              outlineOffset: '-2px',
              size: '8',
              variant: 'fad',
              primaryLayer: { color: 'brand.500' },
              secondaryLayer: { color: 'brand.200', opacity: 1 },
            }}
          />
          <Text w="75%" textAlign="center" variant="-1" fontWeight="medium" color="gray.500">
            In addition to our system notifications, you can create custom email alerts based on due dates and
            completion.{' '}
            <Link isExternal href="https://www.process.st/help/docs/custom-notifications/" textDecor="underline">
              Learn more
            </Link>
          </Text>
        </VStack>
      ) : null}
    </>
  );
};

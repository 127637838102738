import * as React from 'react';
import { IconButton, IconButtonProps, Text, Tooltip } from 'components/design/next';

export type FocusBarIconButtonProps = {
  tooltipText?: string;
} & IconButtonProps;

export const FocusBarIconButton: React.FC<FocusBarIconButtonProps> = ({ tooltipText, ...props }) => {
  return (
    <Tooltip
      isDisabled={!tooltipText}
      shouldWrapChildren
      bgColor="gray.600"
      hasArrow
      label={<Text fontSize="sm">{tooltipText}</Text>}
    >
      <IconButton
        borderColor="gray.600"
        variant="outline"
        colorScheme="gray"
        _hover={{ bgColor: 'gray.500' }}
        _active={{ bgColor: 'gray.500' }}
        _focus={{ bgColor: 'gray.500' }}
        {...props}
      />
    </Tooltip>
  );
};

import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { TaskButtonStack } from '.';

export const TaskButtonStackModule = angular
  .module('taskButtonStack', [])
  .component(
    'psTaskButtonStack',
    reactToAngular(TaskButtonStack, ['activeStep', 'setHasAutomations', 'setHasConditionalLogic', 'editTaskName']),
  ).name;

import angular from 'angular';
import { InboxItemType } from '@process-street/subgrade/inbox';
import { DateUtils } from '@process-street/subgrade/util';
import templateUrl from './inbox-item-details.component.html';
import './inbox-item-details.scss';
import { SessionSelector } from 'reducers/session/session.selectors';
import { connectController } from 'reducers/util';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { features } from 'services/features/features';
import { queryClient } from 'components/react-root';
import { API } from 'components/paywalls/use-checklist/api';
import { PlanTrack } from '@process-street/subgrade/billing';
import { Key } from 'services/key';
import { trace } from 'components/trace';
import { InboxItemDetailsEvents } from './inbox-item-details.events';
import { OneOffTaskAngularHelper } from 'features/one-off-tasks/components/shared/angular/one-off-task-angular-helper';
import { OneOffTaskHelper } from 'features/one-off-tasks/components/shared/one-off-task-helper';
import { StopTaskEvent } from 'services/stop-task-event';

angular.module('frontStreetApp.directives').component('psInboxItemDetails', {
  bindings: {
    resolve: '<',
  },
  templateUrl,
  controller(
    $rootScope,
    $scope,
    $state,
    $timeout,
    $window,
    ChecklistRevisionService,
    InboxService,
    SessionService,
    TaskTemplateService,
    ToastService,
    $ngRedux,
  ) {
    const logger = trace({ name: 'psInboxItemDetails' });
    const ctrl = this;

    const COMPLETE_KEY_CODES = [Key.KEY_Y, Key.KEY_E];

    ctrl.loaded = false;

    ctrl.notifyChecklistDetailsLoaded = function () {
      $timeout(() => {
        ctrl.loaded = true;
        ctrl.resolve?.onLoad?.();
      });
    };

    ctrl.scrollToComments = () => {
      $timeout(() => {
        const commentsSection = document.querySelector('ps-task-comments');

        if (!commentsSection) return;

        commentsSection.scrollIntoView({ behavior: 'smooth' });
        ctrl.shouldScrollToComments = false;
      }, 1000);
    };

    ctrl.notifyTaskDetailsLoaded = function () {
      $timeout(() => {
        ctrl.loaded = true;
        ctrl.resolve?.onLoad?.();

        if (ctrl.shouldScrollToComments) {
          ctrl.scrollToComments();
        }
      });
    };

    ctrl.$onInit = function () {
      ctrl.user = ctrl.resolve.user;
      ctrl.item = ctrl.resolve.item;
      ctrl.organization = ctrl.resolve.organization;
      ctrl.inboxListCtrl = ctrl.resolve.inboxListCtrl;
      ctrl.hideNavigation = ctrl.resolve.hideNavigation;
      ctrl.isTasksPageModal = ctrl.resolve.isTasksPageModal;
      ctrl.shouldScrollToComments = ctrl.resolve.shouldScrollToComments;

      ctrl.inboxListCtrl.registerInboxItemDetailsController(ctrl);

      ChecklistRevisionService.getActiveByChecklistId(ctrl.item.checklist.id).then(checklistRevision => {
        ctrl.checklistRevision = checklistRevision;
      });

      $window.addEventListener('keyup', ctrl.keyboardHandler);

      ctrl.initOneOffTasks();

      connectController($ngRedux, ctrl.mapStateToThis, null)(ctrl);
    };

    ctrl.mapStateToThis = () => state => {
      const currentPlan = SessionSelector.getCurrentPlan(state);
      const track = features.getPlanTrack(currentPlan?.id);
      const onFreemium = track === PlanTrack.Freemium;

      const { role } =
        OrganizationMembershipSelector.getByOrganizationIdAndUserId(ctrl.organization.id, ctrl.user.id)(state) ?? {};

      $timeout(() => ctrl.fetchCanUse().catch(error => logger.error(error)));

      return { role, onFreemium };
    };

    ctrl.fetchCanUse = async () => {
      try {
        const { id } = ctrl.item.checklist;
        const { onFreemium, role } = ctrl.state;
        const { canUse } = await queryClient.fetchQuery(['checklists', 'can-use', id], () => API.canUseChecklist(id), {
          staleTime: 1000 * 60,
        });

        ctrl.showPaywall = Boolean(onFreemium && role && !canUse);
      } catch (error) {
        logger.error(error);
      }
    };

    ctrl.$onDestroy = function () {
      $window.removeEventListener('keyup', ctrl.keyboardHandler);
    };

    ctrl.checklistItemDetailsCtrl = undefined;
    ctrl.registerChecklistItemDetailsController = function (componentCtrl) {
      ctrl.checklistItemDetailsCtrl = componentCtrl;
    };

    ctrl.taskItemDetailsCtrl = undefined;
    ctrl.registerTaskItemDetailsController = function (componentCtrl) {
      ctrl.taskItemDetailsCtrl = componentCtrl;
    };

    ctrl.keyboardHandler = function (event) {
      // Disable keyboard shortcuts on 'My work' page
      if (ctrl.isTasksPageModal) {
        return;
      }

      if (event.keyCode === Key.LEFT_ARROW && !ctrl.isFirstItem()) {
        ctrl.goToPrevious();
      } else if (event.keyCode === Key.RIGHT_ARROW && !ctrl.isLastItem()) {
        ctrl.goToNext();
      } else if (COMPLETE_KEY_CODES.indexOf(event.keyCode) >= 0 && !ctrl.isActiveElementEditable()) {
        ctrl.completeItem();
      }
    };

    ctrl.isEditableElement = function (el) {
      const tags = ['input', 'textarea'];
      const tag = el.tagName.toLowerCase();
      return tags.indexOf(tag) >= 0 || el.contentEditable === 'true';
    };

    ctrl.isActiveElementEditable = function () {
      const el = $window.document.activeElement;
      return el ? ctrl.isEditableElement(el) : false;
    };

    ctrl.setItem = function (item) {
      ctrl.item = item;
    };

    ctrl.resolveItemTitle = function () {
      const { item } = ctrl;
      switch (item.itemType) {
        case InboxItemType.Checklist: {
          const selectedTaskItem = ctrl.checklistItemDetailsCtrl && ctrl.checklistItemDetailsCtrl.selectedTaskItem;
          if (selectedTaskItem) {
            return selectedTaskItem.task.taskTemplate.name || TaskTemplateService.DEFAULT_NAME;
          } else {
            return item.checklist.name || 'Unnamed';
          }
        }
        case InboxItemType.StandardTask:
        case InboxItemType.ApprovalTask:
        case InboxItemType.OneOffTask:
          return InboxService.resolveTaskName(ctrl.item);
        default:
          return 'Unresolved';
      }
    };

    ctrl.isChecklistTaskSelected = function () {
      return ctrl.isChecklist() && ctrl.checklistItemDetailsCtrl && !!ctrl.checklistItemDetailsCtrl.selectedTaskItem;
    };

    ctrl.shouldShowItemControls = function () {
      return !(
        ctrl.isChecklistTaskSelected() &&
        TaskTemplateService.isHeading(ctrl.checklistItemDetailsCtrl.selectedTaskItem.task.taskTemplate)
      );
    };

    ctrl.resolveTaskItemSubtitle = function () {
      const { item } = ctrl;
      switch (item.itemType) {
        case InboxItemType.StandardTask:
        case InboxItemType.ApprovalTask:
          return item.checklist.name;
        default:
          return 'Unresolved';
      }
    };

    ctrl.returnToChecklist = function () {
      if (ctrl.checklistItemDetailsCtrl) {
        ctrl.checklistItemDetailsCtrl.unselectTaskItem();
      }
    };

    ctrl.isChecklist = function () {
      return ctrl.item.itemType === InboxItemType.Checklist;
    };

    ctrl.isTask = function () {
      return InboxService.isTask(ctrl.item.itemType);
    };

    ctrl.completeItem = function () {
      let updatable = true;

      if (ctrl.isChecklistTaskSelected() || ctrl.isTask()) {
        updatable = ctrl.taskItemDetailsCtrl.validateIfTaskStatusUpdatable(true /*flashErrors*/);
      }

      if (ctrl.isChecklist()) {
        updatable = ctrl.validateRequiredOneOffTasksCompleted();
      }

      if (updatable) {
        ctrl.inboxListCtrl.completeItem(ctrl.resolveVisibleItem());
      }
    };

    ctrl.isFirstItem = function () {
      return ctrl.inboxListCtrl.isFirstItem(ctrl.item);
    };

    ctrl.isLastItem = function () {
      return ctrl.inboxListCtrl.isLastItem(ctrl.item);
    };

    ctrl.goToNext = function () {
      ctrl.loaded = false;
      ctrl.inboxListCtrl.showNextItemDetails();
    };

    ctrl.goToPrevious = function () {
      ctrl.loaded = false;
      ctrl.inboxListCtrl.showPreviousItemDetails();
    };

    ctrl.resolveVisibleItem = () => {
      let item;

      if (ctrl.isTask()) {
        ({ item } = ctrl);
      } else if (ctrl.isChecklist()) {
        item = ctrl.isChecklistTaskSelected() ? ctrl.checklistItemDetailsCtrl.selectedTaskItem : ctrl.item;
      }

      return item;
    };

    ctrl.formatDueDate = function (dueDate) {
      return DateUtils.formatActivityTime({ date: dueDate, timeZone: ctrl.user.timeZone });
    };

    ctrl.updateDueDate = function (dueDate) {
      ctrl.inboxListCtrl.updateDueDate(ctrl.resolveVisibleItem(), dueDate);
    };

    ctrl.removeDueDate = function () {
      ctrl.inboxListCtrl.updateDueDate(ctrl.resolveVisibleItem(), null);
    };

    ctrl.isApprovalTask = function (task) {
      if (!task) {
        return false;
      }
      return TaskTemplateService.isApproval(task.taskTemplate);
    };

    ctrl.onClose = function () {
      if ($state.current.name === 'myWork' || $state.current.name === 'microsoftTeamsInbox') {
        $rootScope.$broadcast(InboxItemDetailsEvents.INBOX_ITEM_DETAILS_CLOSED, ctrl.item);
      } else {
        $state.go('inbox');
      }
    };

    ctrl.initOneOffTasks = () => {
      ctrl.oneOffTasks = [];
      if (ctrl.isChecklist()) {
        OneOffTaskAngularHelper.fetchOneOffTasksForChecklist(queryClient, ctrl.item.checklist.id).then(data => {
          ctrl.oneOffTasks = data;
        });
      }
    };

    ctrl.validateRequiredOneOffTasksCompleted = () => {
      const requiredOneOffTasks = OneOffTaskHelper.getRequiredNotCompletedTasks(ctrl.oneOffTasks);
      if (requiredOneOffTasks.length) {
        $rootScope.$broadcast(StopTaskEvent.CHECKLIST_HAS_NOT_COMPLETED_ATTACHED_TASKS, requiredOneOffTasks);

        const message =
          requiredOneOffTasks.length === 1
            ? '1 task still needs to be completed.'
            : `${requiredOneOffTasks.length} tasks still need to be completed.`;

        ToastService.openToast({
          status: 'warning',
          title: `We couldn't complete the workflow run`,
          description: message,
        });
        return false;
      }
      return true;
    };
  },
});

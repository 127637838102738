import React from 'react';
import {
  Box,
  Icon,
  IconButton,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogFooter,
  AlertDialogCloseButton,
  useDisclosure,
  ButtonGroup,
  Button,
  Tooltip,
} from 'components/design/next';
import {
  AutomationInstance,
  isIncomingWebhookInstance,
  isNativeAutomationWithLink,
  isTemplateSolutionInstance,
} from '@process-street/subgrade/automation';
import * as AutomationSelectorMachine from 'pages/templates/_id/automation/components/selector/context';
import {
  GetAllIncomingWebhooksByTemplateIdQuery,
  GetAllIncomingWebhooksByTemplateIdQueryResponse,
  SolutionInstancesByTemplateIdQuery,
  SolutionInstancesByTemplateIdQueryResponse,
  useDeleteTemplateSolutionInstanceMutation,
  useUpdateIncomingWebhookMutation,
} from 'features/automations/query-builder';
import { useQueryClient } from 'react-query';
import { match, P } from 'ts-pattern';
import { GetAllIncomingWebhooksByDataSetIdQuery } from 'features/automations/query-builder/get-all-incoming-webhooks-by-data-set-id-query';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationStatusMutation,
} from 'features/native-automations/query-builder';

interface AutomationDeleteButtonProps {
  automationInstance?: AutomationInstance;
  onDelete?: () => void;
}

export const AutomationDeleteButton: React.FunctionComponent<React.PropsWithChildren<AutomationDeleteButtonProps>> = ({
  automationInstance,
  onDelete,
}) => {
  const disclosure = useDisclosure();

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [, automationMachineSend] = AutomationSelectorMachine.useAutomationSelector();

  const deleteTemplateSolutionInstanceMutation = useDeleteTemplateSolutionInstanceMutation();
  const deleteWebhookInstanceMutation = useUpdateIncomingWebhookMutation();
  const updateNativeAutomationStatusMutation = UpdateNativeAutomationStatusMutation.useMutation();

  const queryClient = useQueryClient();
  const onInstanceDelete = () => {
    if (!automationInstance) return;

    match(automationInstance)
      .when(isTemplateSolutionInstance, tsi => {
        deleteTemplateSolutionInstanceMutation.mutate(
          {
            templateId: tsi.templateId,
            solutionInstanceId: tsi.solutionInstanceId,
          },
          {
            onSuccess: () => {
              queryClient.setQueryData<SolutionInstancesByTemplateIdQueryResponse>(
                SolutionInstancesByTemplateIdQuery.getKey({ templateId: tsi.templateId }),
                instances => instances?.filter(i => i.id !== tsi.id) ?? [],
              );
              automationMachineSend('AUTOMATION_DELETED');
            },
          },
        );
      })
      .when(isIncomingWebhookInstance, webhook => {
        deleteWebhookInstanceMutation.mutate(
          { ...webhook, status: 'Deleted' },
          {
            onSuccess: updated => {
              match(updated)
                .with({ templateId: P.string }, updated => {
                  queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
                    GetAllIncomingWebhooksByTemplateIdQuery.getKey({ templateId: updated.templateId }),
                    current => current?.map(w => (w.id === webhook.id ? updated : w)) ?? [webhook],
                  );
                })
                .with({ dataSetId: P.string }, updated => {
                  queryClient.setQueryData<GetAllIncomingWebhooksByTemplateIdQueryResponse>(
                    GetAllIncomingWebhooksByDataSetIdQuery.getKey({ dataSetId: updated.dataSetId }),
                    current => current?.map(w => (w.id === webhook.id ? updated : w)) ?? [webhook],
                  );
                })
                .otherwise(() => {});

              automationMachineSend('AUTOMATION_DELETED');
              onDelete?.();
            },
          },
        );
      })
      .when(isNativeAutomationWithLink, ({ automation }) =>
        updateNativeAutomationStatusMutation.mutate(
          { nativeAutomationId: automation.id, status: 'Deleted' },
          {
            onSuccess: updated => {
              const dataSetId = match(updated.trigger.config)
                .with({ dataSetId: P.string }, ({ dataSetId }) => dataSetId)
                .otherwise(() => undefined);

              queryClient.setQueryData<GetAllNativeAutomationsQuery.Response>(
                GetAllNativeAutomationsQuery.getKey({ dataSetId }),
                current => current?.filter(a => a.automation.id !== updated.id) ?? [],
              );

              automationMachineSend('AUTOMATION_DELETED');
              onDelete?.();
            },
          },
        ),
      )
      .run();
  };

  const isDisabled = match(automationInstance)
    .when(isTemplateSolutionInstance, ({ enabled, configured }) => enabled && configured)
    .when(isIncomingWebhookInstance, ({ status }) => status === 'Active')
    .when(isNativeAutomationWithLink, ({ automation: { status } }) => status === 'Active')
    .otherwise(() => true);

  return (
    <Box>
      <Tooltip shouldWrapChildren hasArrow isDisabled={!isDisabled} label="Cannot delete active automation">
        <IconButton
          variant="unstlyed"
          aria-label="Delete Automation"
          icon={<Icon icon="trash-alt" variant="far" size="4" />}
          onClick={disclosure.onOpen}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <AlertDialog leastDestructiveRef={cancelRef} {...disclosure}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete this automation?
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogFooter>
            <ButtonGroup>
              <Button ref={cancelRef} variant="ghost" onClick={disclosure.onClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={onInstanceDelete}>
                Delete
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};

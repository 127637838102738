import angular from 'angular';
import { ChecklistConstants } from '@process-street/subgrade/process/checklist-constants';
import { HttpStatus } from '@process-street/subgrade/util';
import { TemplateStatus } from '@process-street/subgrade/process';
import { trace } from 'components/trace';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'ChecklistRunCtrl',
    function (
      $location,
      $q,
      $scope,
      $state,
      AnonymousAuthService,
      auth,
      ChecklistService,
      OrganizationService,
      PlanService,
      SecurityService,
      TemplateService,
      TemplateRevisionService,
    ) {
      const logger = trace({ name: 'ChecklistRunCtrl' });
      logger.info('Loading checklist run ctrl.');

      // Keep this an undefined and not false, or else the one-time binding doesn't work correctly
      $scope.initialized = undefined;

      const ctrl = this;

      ctrl.$onInit = () => {
        $scope.loading = true;

        if ($location.url().includes('#')) {
          // This will rewrite any # characters with %23
          $location.url($location.url().replace(/#/g, '%23'));
        }
        ctrl.init();
      };

      ctrl.init = () => {
        $scope.loading = true;

        AnonymousAuthService.templateRunAnonymousAuthSharedRunLink($state.params.templateId)
          .then(user => {
            $scope.user = user;
            ctrl.loadTemplateAndValidatePermissions($state.params.templateId).then(tmpl => {
              // FIXME Yes, this is pretty shitty,
              // but life is not fair and we have to suck it up until we refactor this sucker
              // until then, let's hope we won't need to add more functionality here :)
              loadOrganizationAndPlanData(tmpl.organization.id).then(() => {
                $scope.initialized = true;
                createChecklist(tmpl);
              });
            });
          })
          .catch(error => ($scope.message = error.message));
      };

      async function createChecklist(template) {
        logger.info('Creating checklist.');
        const queryParams = $location.search();
        const formFields = ChecklistService.extractFormFieldValueMap(queryParams);

        const templateRevisions = await TemplateRevisionService.getAllNewestByTemplateId(template.id);
        const defaultName = templateRevisions?.[0]?.defaultChecklistName;
        // intentional || in case the user resets the default name to an empty string
        const name = defaultName || ChecklistService.resolveChecklistName($scope.user, queryParams);
        const options = {
          name,
          formFields,
          autoAssign: auth.isLoggedIn(),
          method: ChecklistConstants.Method.RUN_LINK,
        };

        ChecklistService.create(template, options)
          .then(checklist => {
            $state.go('checklist', { id: checklist.id });
          })
          .catch(response => {
            $scope.loading = false;

            if (response.status === HttpStatus.PAYMENT_REQUIRED) {
              const { featureLimit } = response.data;
              ChecklistService.showLimitReachedMessageAndRedirect($scope.user, featureLimit);
            } else if (response.status === HttpStatus.BAD_REQUEST && response.data.message) {
              // The message coming back from API call is a bit too technical and too much information for
              // regular user... So we are cutting it down to only what we want to show
              [$scope.message] = response.data.message.split(',');
            } else {
              $scope.message = 'Failed to create checklist. Please try again later.';
            }
          });
      }

      // FIXME This is a dirty dirty hack to have the data available for the analytics
      function loadOrganizationAndPlanData(organizationId) {
        return OrganizationService.getById(organizationId).then(organization => {
          return PlanService.getById(organization.subscription.plan.id);
        });
      }

      ctrl.loadTemplateAndValidatePermissions = templateId => {
        return TemplateService.get(templateId).then(
          template => {
            if (template.status !== TemplateStatus.Active) {
              $scope.message = 'This workflow is not active.';
              $scope.loading = false;
              return $q.reject();
            }

            if (SecurityService.getSelectedOrganizationIdByUser($scope.user) !== template.organization.id) {
              OrganizationService.switchById(template.organization.id);
              return $q.reject();
            }

            return initializePermissions(template).then(() => {
              if (!$scope.permissionMap.checklistCreate) {
                $scope.message = 'Sorry, you are not allowed to run workflows using this run link.';

                return $q.reject();
              }

              return template;
            });
          },
          () => {
            $scope.message = 'The workflow was not found.';

            return $q.reject();
          },
        );
      };

      $scope.permissionMap = {};

      function initializePermissions(template) {
        return $q
          .all({
            checklistCreate: SecurityService.canCreateChecklistByTemplate(template),
          })
          .then(permissionMap => {
            $scope.permissionMap = permissionMap;
          });
      }

      $scope.$on('$destroy', () => {
        AnonymousAuthService.logoutIfAuthedAnonymously();
      });
    },
  );

import { AuditMetadata, Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { MutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { useMutation as useRQMutation } from 'react-query';

export namespace SnoozeAssignmentMutation {
  export type Params = {
    assignmentId: Muid;
    untilDate: number;
  };

  export type Result = {
    id: Muid;
    assignmentId: Muid;
    organizationId: Muid;
    organizationMembershipId: Muid;
    untilDate: number;
    audit: AuditMetadata;
  };

  export const key = ['assignment', 'snooze'];

  export const mutationFn = ({ assignmentId, ...payload }: Params) =>
    axiosService
      .getAxios()
      .put(`/1/assignments/${assignmentId}/snoozed`, payload)
      .then(res => res.data);

  export const useMutation = (options: MutationOptions<Result, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}

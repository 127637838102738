import * as React from 'react';
import { Box, ButtonProps, Tooltip } from 'components/design/next';
import { Button, Icon } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { CoverIconUploadPopover } from 'features/cover-icon/components/common/popover';
import { useCoverIconByTemplateIdQuery, useDeleteCoverIconMutationWithToast } from 'features/cover-icon/query-builder';
import { useHover } from 'react-use';

export const AddIconButton: React.FC<React.PropsWithChildren<{ templateId: Muid }>> = ({ templateId }) => {
  const coverIconQuery = useCoverIconByTemplateIdQuery({ templateId });

  const hasCoverIcon = coverIconQuery.isSuccess && !!coverIconQuery.data;

  return hasCoverIcon ? <RemoveButton templateId={templateId} /> : <AddButton templateId={templateId} />;
};

export const commonSetupButtonProps: Partial<ButtonProps> = {
  height: 12,
  flexBasis: '50%',
  width: '100%',
  fontWeight: 'normal',
  variant: 'outline',
  borderWidth: '1px',
  colorScheme: 'gray',
};

const AddButton: React.FC<React.PropsWithChildren<{ templateId: Muid }>> = ({ templateId }) => {
  return (
    <CoverIconUploadPopover templateId={templateId}>
      <Box flexBasis="50%">
        <Tooltip hasArrow label="Recommended size 75x75px">
          <Button
            {...commonSetupButtonProps}
            leftIcon={<Icon icon="face-smile" variant="far" size="4" color="gray.600" />}
            rightIcon={<Icon icon="circle-info" variant="far" size="4" color="gray.500" />}
          >
            Add Icon
          </Button>
        </Tooltip>
      </Box>
    </CoverIconUploadPopover>
  );
};

const RemoveButton: React.FC<React.PropsWithChildren<{ templateId: Muid }>> = ({ templateId }) => {
  const removeCoverIconMutation = useDeleteCoverIconMutationWithToast();
  const handleRemoveIcon = () => {
    removeCoverIconMutation.mutate({ templateId });
  };
  const [hoverable] = useHover((hovered: boolean) =>
    hovered ? (
      <Button
        {...commonSetupButtonProps}
        color="red.600"
        borderColor="red.600"
        leftIcon={<Icon icon="trash" variant="far" size="4" color="red.600" />}
        onClick={handleRemoveIcon}
      >
        Delete Icon
      </Button>
    ) : (
      <Button
        {...commonSetupButtonProps}
        color="green.600"
        borderColor="green.600"
        leftIcon={<Icon icon="check" variant="far" size="4" color="green.600" />}
      >
        Icon added
      </Button>
    ),
  );

  return hoverable;
};

import { Muid, Option } from '@process-street/subgrade/core';
import { StateParams, StateService } from '@uirouter/angularjs';
import { EmailInitiatedApprovalSelectors } from 'components/approvals/email-initiated/store/email-initiated.selectors';
import ngRedux from 'ng-redux';
import { connectController } from 'reducers/util';
import { createSelector } from 'reselect';
import { SessionService } from 'services/session-service.interface';
import templateUrl from './email-initiated.component.html';
import jwtDecode from 'jwt-decode';
import angular from 'angular';

export enum ApprovalBulkAction {
  ApproveAll = 'approve-all',
  RejectAll = 'reject-all',
}

export interface TokenPayload {
  approvalTaskGroupId: Muid;
  approvalTaskId: Muid;
  approvalTaskName: string;
  checklistName: string;
  iat: number;
}

interface InternalState {
  requestCompleted: boolean;
}

export class EmailInitiatedApprovalController {
  public state: Option<InternalState>;

  public checklistId: Muid;
  public taskId: Muid;
  public action: ApprovalBulkAction;
  public token: string;
  public tokenPayload: TokenPayload;

  static $inject = ['$scope', '$ngRedux', '$state', '$stateParams', 'SessionService'];
  constructor(
    private $scope: angular.IScope,
    private $ngRedux: ngRedux.INgRedux,
    private $state: StateService,
    private $stateParams: StateParams,
    private sessionService: SessionService,
  ) {
    this.checklistId = this.$stateParams.checklistId;
    this.taskId = this.$stateParams.taskId;
    this.action = this.$stateParams.action;
    this.token = this.$stateParams.token;
    this.tokenPayload = jwtDecode<TokenPayload>(this.$stateParams.token);

    this.$scope.$watch('$ctrl.state.requestCompleted', (newValue, oldValue) => {
      if (newValue !== oldValue && newValue) {
        this.onRequestComplete();
      }
    });

    const mapStateToThis = () =>
      createSelector(
        [EmailInitiatedApprovalSelectors.getRequestCompleteStatusByTaskId(this.taskId)],
        (requestCompleted: boolean) => ({ requestCompleted }),
      );

    connectController(this.$ngRedux, mapStateToThis, null)(this);
  }

  public onRequestComplete() {
    if (this.sessionService.getUser() && this.state) {
      this.$state.go('checklist.task', { id: this.checklistId, groupId: this.taskId });
    }
  }

  public onLoginClicked = () => {
    this.$state.go('login');
  };
}

export const EmailInitiatedApproval: angular.IComponentOptions = {
  controller: EmailInitiatedApprovalController,
  templateUrl,
};

import { InboxItemType } from '@process-street/subgrade/inbox';
import { dayjs } from '@process-street/subgrade/util';
import { match, P } from 'ts-pattern';
import { SnoozeAssignmentMutation } from 'pages/tasks/query-builder/use-snooze-assignment-mutation';
import { AssignmentType } from '@process-street/subgrade/role-assignment';
import invariant from 'tiny-invariant';
import { MutationOptions, useQueryClient } from 'react-query';
import { isFunction } from 'lodash';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { TasksTableUtils } from '../components/tasks-table/tasks-table-utils';
import { useToast } from 'components/design/next';
import { AxiosError } from 'axios';
import { useTimezone } from 'hooks/use-timezone';
import { GetSnoozeAssignmentCountQuery } from '../query-builder/get-snooze-assignment-count-query';
import { DeleteSnoozeAssignmentMutation } from '../query-builder/delete-snooze-assignment-mutation';
import { Muid } from '@process-street/subgrade/core';

export const useSnoozeHandler = (
  data: TasksTableUtils.TasksTableItem,
  options?: MutationOptions<SnoozeAssignmentMutation.Result, AxiosError, any>,
) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const timeZone = useTimezone({ fallback: 'UTC' });

  const assignmentId = match(data)
    .with({ assignmentId: P.string }, ({ assignmentId }) => assignmentId)
    .otherwise(() => null);

  const assignmentType = match(data)
    .with({ itemType: InboxItemType.Checklist }, () => AssignmentType.Checklist)
    .with(
      { itemType: P.union(InboxItemType.OneOffTask, InboxItemType.ApprovalTask, InboxItemType.StandardTask) },
      () => AssignmentType.Task,
    )
    .otherwise(() => null);

  const optimisticallyUpdateInboxItems = ({
    assignmentId,
    countIncrement,
  }: {
    assignmentId: Muid;
    countIncrement: number;
  }) => {
    const rollbackSnoozeCount = GetSnoozeAssignmentCountQuery.incrementCount(queryClient, countIncrement);
    const rollbackInboxItems = GetInboxItemsQuery.removeItem(queryClient, item => item.assignmentId === assignmentId);

    return () => {
      rollbackInboxItems();
      rollbackSnoozeCount();
    };
  };

  const snoozeMutation = SnoozeAssignmentMutation.useMutation({
    ...options,
    onMutate: variables => {
      return optimisticallyUpdateInboxItems({ assignmentId: variables.assignmentId, countIncrement: 1 });
    },
    onSuccess: async (...args) => {
      options?.onSuccess?.(...args);
    },
    onError: (_error, _variables, context) => {
      options?.onError?.(_error, _variables, context);
      if (isFunction(context)) {
        // Revert the optimistic update in case of error
        context();
      }

      toast({ status: 'error', title: `We're having problems snoozing the ${assignmentType}.` });
    },
    onSettled: async () => {
      await GetSnoozeAssignmentCountQuery.invalidate(queryClient);
      await GetInboxItemsQuery.invalidate(queryClient);
    },
  });

  const deleteSnoozeMutation = DeleteSnoozeAssignmentMutation.useMutation({
    ...options,
    onMutate: variables => {
      return optimisticallyUpdateInboxItems({ assignmentId: variables.assignmentId, countIncrement: -1 });
    },
    onSuccess: async (...args) => {
      options?.onSuccess?.(...args);
    },
    onError: (_error, _variables, context) => {
      options?.onError?.(_error, _variables, context);
      if (isFunction(context)) {
        // Revert the optimistic update in case of error
        context();
      }

      toast({ status: 'error', title: `We're having problems removing the snooze from the ${assignmentType}.` });
    },
    onSettled: async (...args) => {
      options?.onSettled?.(...args);
      await GetSnoozeAssignmentCountQuery.invalidate(queryClient);
      await GetInboxItemsQuery.invalidate(queryClient);
    },
  });

  const handleSnooze = (untilDate: number | null) => {
    invariant(assignmentId, '`assignmentId` is missing');

    if (untilDate === null) {
      deleteSnoozeMutation.mutate({
        assignmentId,
      });
    } else {
      snoozeMutation.mutate({
        untilDate: dayjs(untilDate).tz(timeZone).toDate().getTime(),
        assignmentId,
      });
    }
  };

  return handleSnooze;
};

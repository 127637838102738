import { isAi, isHeading, TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { OrganizationMembershipRole, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import * as React from 'react';
import { AssignmentPicker } from 'pages/templates/_id/components/assignment-picker/component';
import { useInjector } from 'components/injection-provider';
import { AssignmentPickerService } from 'pages/templates/services/assignment-picker-service';
import { DynamicAssignment } from 'pages/templates/_id/models/assignments';
import { ButtonProps, useToast } from 'components/design/next';
import { InvitationResource } from 'services/invitation-service.interface';
import { useQueryClient } from 'react-query';
import {
  GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery,
  GetTemplateTaskAssignmentsByTemplateRevisionIdResult,
} from 'features/task-template-assignment/query-builder';
import { PreassigningFreeMembersWrapper } from 'components/paywalls/preassigning-free-members/wrapper';
import { useDisclosure } from 'components/design/next';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  CreateAllTaskAssignmentRulesMutation,
  CreateTaskAssignmentRuleMutation,
  useCreateAllTaskAssignmentRulesMutation,
  useCreateTaskAssignmentRuleMutation,
  GetTaskAssignmentRulesByTemplateRevisionIdQuery,
} from 'features/task-assignment-rules/query-builder';
import {
  AssignTaskTemplateMutation,
  useAssignTaskTemplateMutation,
} from 'features/task-template-assignment/query-builder/assign-task-template-mutation';
import {
  AssignAllTaskTemplatesMutation,
  useAssignAllTaskTemplatesMutation,
} from 'features/task-template-assignment/query-builder/assign-all-task-templates-mutation';
import { useAssignmentsQuery } from './use-assignments-query';
import { useFeatureFlag } from 'features/feature-flags';
import { isT5KPlan } from 'services/plans';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';

type AssignmentPickerWrapperProps = {
  templateRevision: TemplateRevision;
  taskTemplates: TaskTemplate | TaskTemplate[];
  disabled?: boolean;
  hasAssignments?: boolean;
  buttonProps?: Partial<ButtonProps>;
};

export const AssignmentPickerWrapper = ({
  templateRevision,
  taskTemplates,
  disabled,
  hasAssignments = false,
  buttonProps,
}: AssignmentPickerWrapperProps) => {
  const normalizedTaskTemplates = Array.isArray(taskTemplates) ? taskTemplates : [taskTemplates];

  const { allMembers, allRules, availableDynamicAssignments, availableMemberships } = useAssignmentsQuery(
    templateRevision.id,
    normalizedTaskTemplates,
  );

  const { InvitationService } = useInjector('InvitationService');

  const toast = useToast();
  const paywallDisclosure = useDisclosure();
  const [paywallUserName, setPaywallUserName] = React.useState('');
  const [paywallUserRole, setPaywallUserRole] = React.useState(OrganizationMembershipRole.FreeMember);

  const queryClient = useQueryClient();

  const assignTaskTemplateMutation = useAssignTaskTemplateMutation({
    mutationKey: AssignTaskTemplateMutation.key,
    onSuccess: assignment => {
      queryClient.setQueryData<GetTemplateTaskAssignmentsByTemplateRevisionIdResult>(
        GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey({ templateRevisionId: templateRevision.id }),
        current => [...(current ?? []), assignment],
      );
    },
  });

  const assignAllTaskTemplatesMutation = useAssignAllTaskTemplatesMutation({
    mutationKey: AssignAllTaskTemplatesMutation.key,
    onSuccess: results => {
      queryClient.setQueryData<GetTemplateTaskAssignmentsByTemplateRevisionIdResult>(
        GetAllTaskTemplateAssignmentsByTemplateRevisionIdQuery.getKey({ templateRevisionId: templateRevision.id }),
        current => [...(current ?? []), ...results.map(a => a.taskTemplateAssignment)],
      );
    },
  });

  const plan = useSelector(SessionSelector.getCurrentPlan);
  const freeMemberPreAssignmentsEnabled = useFeatureFlag('freeMemberPreAssignments') && plan && isT5KPlan(plan.id);

  const createAssignment = (membership: OrganizationMembershipWithUser) => {
    const disallowedRoles = freeMemberPreAssignmentsEnabled
      ? [OrganizationMembershipRole.Guest]
      : [OrganizationMembershipRole.FreeMember, OrganizationMembershipRole.Guest];

    if (disallowedRoles.includes(membership.role)) {
      setPaywallUserName(membership.user.username);
      setPaywallUserRole(membership.role);
      paywallDisclosure.onOpen();

      return;
    }

    const { email } = membership.user;

    if (normalizedTaskTemplates.length === 1) {
      const [taskTemplate] = normalizedTaskTemplates;
      assignTaskTemplateMutation.mutate({ email, taskTemplateId: taskTemplate.id });
    } else {
      const taskTemplates = normalizedTaskTemplates.filter(
        taskTemplate => !isHeading(taskTemplate) && !isAi(taskTemplate),
      );
      assignAllTaskTemplatesMutation.mutate({
        email,
        taskTemplatesIds: taskTemplates.map(t => t.id),
      });
    }
  };

  const createTaskAssignmentRuleMutation = useCreateTaskAssignmentRuleMutation({
    mutationKey: CreateTaskAssignmentRuleMutation.key,
    onSuccess: rule => {
      queryClient.setQueryData<GetTaskAssignmentRulesByTemplateRevisionIdQuery.Response>(
        GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId: templateRevision.id }),
        current => [...(current ?? []), rule],
      );
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems creating the role assignment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        variant: 'subtle',
      });
    },
  });

  const createAllTaskAssignmentRulesMutation = useCreateAllTaskAssignmentRulesMutation({
    mutationKey: CreateAllTaskAssignmentRulesMutation.key,
    onSuccess: rules => {
      queryClient.setQueryData<GetTaskAssignmentRulesByTemplateRevisionIdQuery.Response>(
        GetTaskAssignmentRulesByTemplateRevisionIdQuery.getKey({ templateRevisionId: templateRevision.id }),
        current => [...(current ?? []), ...rules],
      );
    },
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems creating the role assignment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        variant: 'subtle',
      });
    },
  });

  const createRule = (assignment: DynamicAssignment) => {
    if (normalizedTaskTemplates.length === 1) {
      const [taskTemplate] = normalizedTaskTemplates;
      const rule = AssignmentPickerService.getRuleFromDynamicAssignment(assignment, templateRevision, taskTemplate);

      return createTaskAssignmentRuleMutation.mutate({ templateRevisionId: templateRevision.id, ...rule });
    } else {
      const rules = AssignmentPickerService.getRulesFromDynamicAssignment(
        assignment,
        templateRevision,
        normalizedTaskTemplates,
        allRules,
      );
      return createAllTaskAssignmentRulesMutation.mutate({ templateRevisionId: templateRevision.id, rules });
    }
  };

  const inviteAndAssign = async (email: string) => {
    const resource: InvitationResource = { type: 'Template', id: templateRevision.template.id };
    const options = {
      role: OrganizationMembershipRole.FullMember,
    };

    const { organizationMembership } = await InvitationService.invite(email, resource, options);
    createAssignment(organizationMembership as OrganizationMembershipWithUser);
  };

  return (
    <>
      <AssignmentPicker
        allMembers={allMembers}
        availableDynamicAssignments={availableDynamicAssignments}
        availableMemberships={availableMemberships}
        disabled={disabled}
        onAssignmentCreate={createAssignment}
        onRuleCreate={createRule}
        onInviteAndAssign={inviteAndAssign}
        taskTemplates={normalizedTaskTemplates}
        hasAssignments={hasAssignments}
        buttonProps={buttonProps}
      />
      {paywallDisclosure.isOpen ? (
        <PreassigningFreeMembersWrapper
          isOpen={paywallDisclosure.isOpen}
          onGoBack={paywallDisclosure.onClose}
          userName={paywallUserName}
          userRole={paywallUserRole}
        />
      ) : null}
    </>
  );
};

import angular from 'angular';
import { connectController } from 'reducers/util';
import { createSelector } from 'reselect';
import { ApprovalSelector } from 'components/approvals/store/approval.selectors';
import templateUrl from './checklist-widgets-list.component.html';
import './checklist-widgets-list.scss';
import { WidgetUtils } from '@process-street/subgrade/process';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psChecklistWidgetsList', {
  bindings: {
    checklistRevision: '<',
    formFieldValueMap: '<',
    organization: '<',
    task: '<',
    user: '<',
    widgets: '<',
    invalidFormFieldMap: '<',
    widgetsListContainerClass: '<',
    readOnly: '<',
  },
  templateUrl,
  controller($ngRedux, $scope, $q, ChecklistService, FormFieldValueEvent, RequiredFieldEvent) {
    'ngInject';
    const ctrl = this;
    this.$q = $q;
    this.$scope = $scope;

    ctrl.$onInit = function () {
      ctrl.invalidFormFieldMap = ctrl.invalidFormFieldMap ?? {};

      const mapStateToThis = () =>
        createSelector(
          [ApprovalSelector.isTaskLocked(this.task && this.task.checklistRevision.id, this.task && this.task.id)],
          taskLocked => ({ taskLocked }),
        );

      connectController($ngRedux, mapStateToThis, null, ctrl.shouldChange)(ctrl);
    };

    ctrl.shouldChange = changes => changes.task && changes.task.currentValue;

    ctrl.isChecklistActionable = ChecklistService.isChecklistActionable;

    ctrl.countNonHiddenWidgets = function (widgets) {
      let widgetsCount = 0;

      if (widgets) {
        widgetsCount = widgets.filter(widget => !widget._hidden).length;
      }

      return widgetsCount;
    };

    ctrl.updateInvalidFormFieldMap = function (invalidFormFields) {
      invalidFormFields.forEach(formFieldWidget => {
        ctrl.invalidFormFieldMap[formFieldWidget.id] = formFieldWidget;
      });
    };

    $scope.$on(RequiredFieldEvent.CHECKLIST_HAS_INVALID_FORM_FIELDS, (__event, data) => {
      if (data && data.invalidFormFields && data.invalidFormFields.length) {
        ctrl.updateInvalidFormFieldMap(data.invalidFormFields);
      }
    });

    $scope.$on(FormFieldValueEvent.CHECKLIST_HAS_FAILED_CONSTRAINTS_FORM_FIELDS, (__event, data) => {
      if (data && data.invalidFormFields && data.invalidFormFields.length) {
        ctrl.updateInvalidFormFieldMap(data.invalidFormFields);
      }
    });

    $scope.$on(RequiredFieldEvent.TASK_HAS_INVALID_FORM_FIELDS, (__event, data) => {
      if (data && data.invalidFormFields && data.invalidFormFields.length) {
        ctrl.updateInvalidFormFieldMap(data.invalidFormFields);
      }
    });
    $scope.$on(FormFieldValueEvent.TASK_HAS_FAILED_CONSTRAINTS_FORM_FIELDS, (__event, data) => {
      if (data && data.invalidFormFields && data.invalidFormFields.length) {
        ctrl.updateInvalidFormFieldMap(data.invalidFormFields);
      }
    });

    const formFieldValueUpdateListener = (__event, formFieldValue) => {
      ctrl._removeInvalidStateByFormFieldValue(formFieldValue);
    };

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, formFieldValueUpdateListener);
    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_LIVE_UPDATED, formFieldValueUpdateListener);
    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_LIVE_DELETED, formFieldValueUpdateListener);

    ctrl._removeInvalidStateByFormFieldValue = function (formFieldValue) {
      const { formFieldWidget } = formFieldValue;
      if (ctrl.invalidFormFieldMap[formFieldWidget.id]) {
        const valueIsEmpty = WidgetUtils.isEmpty(formFieldWidget, formFieldValue);
        if (!valueIsEmpty) {
          delete ctrl.invalidFormFieldMap[formFieldWidget.id];
        }
      }
    };
  },
});

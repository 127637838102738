import * as React from 'react';
import {
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  IconButton,
  useToast,
  Portal,
} from 'components/design/next';
import { WidgetHeader, WidgetLike } from '@process-street/subgrade/process';
import { MutationStatus } from 'react-query';
import { useGenerateWidgetContentMutation, WidgetContentAction } from 'features/ai-generation/query-builder';
import { trace } from 'components/trace';

export type AiGenerateWidgetContentMenuProps = {
  onContentGenerated: (widget: WidgetLike) => void;
  onGenerationStatusChange?: (status: MutationStatus) => void;
  headerId: WidgetHeader['id'];
  getWidgetContent: () => string;
  getWidgetSelection: () => string;
  isDisabled: boolean;
  buttonSize: 'sm' | 'md';
};

export const AiGenerateWidgetContentMenu: React.FC<React.PropsWithChildren<AiGenerateWidgetContentMenuProps>> = ({
  getWidgetContent,
  getWidgetSelection,
  headerId,
  onContentGenerated,
  onGenerationStatusChange,
  isDisabled,
  buttonSize,
}) => {
  const toast = useToast();
  const logger = trace({ name: 'AiGenerateWidgetContentMenu' });
  const generateWidgetContentMutation = useGenerateWidgetContentMutation({
    onSuccess: res => {
      onContentGenerated(res);
    },
    onError: error => {
      toast({
        status: 'error',
        title: `Content couldn't be generated. Try again in a moment.`,
      });

      logger.warn('Error generating AI content', error.message);
    },
  });

  const handleItemClick = (action: WidgetContentAction) => {
    const content = getWidgetContent();
    const selection = getWidgetSelection();

    generateWidgetContentMutation.mutate({
      action,
      headerId,
      content,
      selection,
    });
  };

  React.useEffect(() => {
    onGenerationStatusChange?.(generateWidgetContentMutation.status);
  }, [generateWidgetContentMutation.status, onGenerationStatusChange]);

  const [hasContent, setHasContent] = React.useState(false);

  const handleMenuOpen = () => {
    const content = getWidgetContent();
    const isEmpty = content.trim().length === 0 || content.trim() === '<p></p>';
    setHasContent(!isEmpty);
  };

  return (
    <Menu onOpen={handleMenuOpen} placement="bottom-start">
      <MenuButton
        as={IconButton}
        variant="ghost"
        size={buttonSize}
        aria-label="Generate content with AI"
        icon={<Icon icon="process-pete" variant="far" size="4" color="gray.500" />}
        isLoading={generateWidgetContentMutation.isLoading}
        isDisabled={isDisabled}
        isFocusable={!hasContent}
      />
      <Portal>
        <MenuList color="gray.600" zIndex="popover">
          <Tooltip
            label="You need to clear the existing content to generate new one"
            isDisabled={!hasContent}
            shouldWrapChildren
          >
            <MenuItem
              onClick={() => handleItemClick('Generate')}
              icon={<Icon icon="rotate-left" variant="far" size="4" color="gray.500" />}
              isDisabled={hasContent}
            >
              Generate
            </MenuItem>
          </Tooltip>

          <MenuDivider />

          <MenuGroup title="length" mt={4} mb={1}>
            <MenuItem
              onClick={() => handleItemClick('MakeShorter')}
              icon={<Icon icon="make-short" variant="far" size="4" color="gray.500" />}
              isDisabled={!hasContent}
              isFocusable={hasContent}
            >
              Make shorter
            </MenuItem>
            <MenuItem
              onClick={() => handleItemClick('MakeLonger')}
              icon={<Icon icon="align-left" variant="far" size="4" color="gray.500" />}
              isDisabled={!hasContent}
              isFocusable={hasContent}
            >
              Make longer
            </MenuItem>
          </MenuGroup>
          <MenuDivider />

          <MenuGroup title="tone" mt={4} mb={1}>
            <MenuItem
              onClick={() => handleItemClick('ChangeToneFormal')}
              icon={<Icon icon="comment" variant="far" size="4" color="gray.500" />}
              isDisabled={!hasContent}
              isFocusable={hasContent}
            >
              More formal
            </MenuItem>
            <MenuItem
              onClick={() => handleItemClick('ChangeToneCasual')}
              icon={<Icon icon="comment" variant="far" size="4" color="gray.500" />}
              isDisabled={!hasContent}
              isFocusable={hasContent}
            >
              More casual
            </MenuItem>
            <MenuItem
              onClick={() => handleItemClick('ChangeToneFriendly')}
              icon={<Icon icon="comment" variant="far" size="4" color="gray.500" />}
              isDisabled={!hasContent}
              isFocusable={hasContent}
            >
              More friendly
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuItem
            onClick={() => handleItemClick('ContinueWriting')}
            icon={<Icon icon="pencil" variant="far" size="4" color="gray.500" />}
            isDisabled={!hasContent}
            isFocusable={hasContent}
          >
            Continue writing
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};

import * as React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  PopoverProps,
  Button,
  useDisclosure,
  HStack,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormLabel,
  Text,
  VStack,
  ButtonGroup,
  Tooltip,
  forwardRef,
  NumberInputProps,
  ButtonProps,
} from 'components/design/next';
import { useCustomNotificationConfigContext } from '../../custom-notification-config-provider';
import { useCustomNotificationsStore } from '../../custom-notifications-store';
import { Period } from '@process-street/subgrade/core';
import { SelectInstance } from 'components/design/BlvdSelect';
import { EntityOption, EntitySelect } from './entity-select';
import { OffsetDirectionOption, OffsetDirectionSelect } from './offset-direction-select';
import { PropertyOption, PropertySelect } from './property-select';
import { getTimingLabel, getTimingLabelColor } from './rule-trees';
import { CustomNotificationUtils } from '../../custom-notification-utils';

export interface CustomNotificationTimingConfigProps extends Partial<PopoverProps> {
  buttonProps?: Omit<Partial<ButtonProps>, 'onClick'>;
}

export const CustomNotificationTimingConfig: React.FC<React.PropsWithChildren<CustomNotificationTimingConfigProps>> = ({
  isOpen,
  onClose,
  buttonProps,
  ...props
}) => {
  const { config } = useCustomNotificationConfigContext();
  const updateTiming = useCustomNotificationsStore(state => state.updateTiming);

  const offsetDirectionsRef = React.useRef<SelectInstance<OffsetDirectionOption>>(null);
  const entityRef = React.useRef<SelectInstance<EntityOption>>(null);
  const propertyRef = React.useRef<SelectInstance<PropertyOption>>(null);
  const periodRef = React.useRef<HTMLInputElement>(null);

  const [timing, setTiming] = React.useState(config.timing ?? {});
  const disclosure = useDisclosure({ isOpen, onClose });

  React.useEffect(() => {
    if (!timing?.offsetDirection) {
      offsetDirectionsRef.current?.openMenu?.('first');
      return;
    }
    if (!timing?.entity) {
      entityRef.current?.openMenu?.('first');
      return;
    }
    if (!timing?.property) {
      propertyRef.current?.openMenu?.('first');
      return;
    }

    if (!timing?.offset) {
      periodRef.current?.select();
    }
  });

  const handleSave = () => {
    updateTiming(config, timing);
    disclosure.onClose();
  };

  // Make sure to use persisted config.timing, not the stateful "draft"
  const timingLabel = getTimingLabel(config.timing ?? {});
  const timingLabelColor = getTimingLabelColor(timingLabel);

  const needsOffset = timing.offsetDirection === 'After' || timing.offsetDirection === 'Before';

  const isDisabled = !CustomNotificationUtils.isTimingValid(timing);

  return (
    <Popover {...disclosure} {...props}>
      <PopoverTrigger>
        <Button
          variant="ghost"
          colorScheme="gray"
          w="100%"
          textAlign="left"
          fontWeight="normal"
          bgColor={disclosure.isOpen ? 'gray.100' : 'inherit'}
          {...buttonProps}
        >
          <Tooltip label={timingLabel} openDelay={1000}>
            <Text noOfLines={1} w="full" color={timingLabelColor} variant="1">
              {timingLabel}
            </Text>
          </Tooltip>
        </Button>
      </PopoverTrigger>

      <PopoverContent w="xl">
        <PopoverHeader>
          <Text variant="1" fontWeight="medium" color="gray.600">
            Timing for notification
          </Text>
        </PopoverHeader>
        <PopoverCloseButton />

        <PopoverBody as={VStack} spacing="4">
          <HStack>
            {/* Ejecting the first one so we can focus with a ref  */}
            <PeriodInput
              ref={periodRef}
              value={timing.offset?.[PERIOD_UNITS[0]] ?? 0}
              onChange={val => setTiming(t => ({ ...t, offset: { ...t?.offset, [PERIOD_UNITS[0]]: Number(val) } }))}
              isDisabled={!needsOffset}
              min={0}
            >
              {PERIOD_UNITS[0]}
            </PeriodInput>

            {PERIOD_UNITS.slice(1).map(unit => (
              <PeriodInput
                key={unit}
                value={timing.offset?.[unit] ?? 0}
                onChange={val => setTiming(t => ({ ...t, offset: { ...t?.offset, [unit]: Number(val) } }))}
                isDisabled={!needsOffset}
                min={0}
              >
                {unit}
              </PeriodInput>
            ))}
          </HStack>

          <HStack w="full">
            <FormControl flex="1">
              <OffsetDirectionSelect selectRef={offsetDirectionsRef} timing={timing} onChange={setTiming} />
            </FormControl>

            <FormControl flex="2">
              <EntitySelect
                selectRef={entityRef}
                timing={timing}
                isDisabled={!timing.offsetDirection}
                onChange={setTiming}
              />
            </FormControl>

            <FormControl flex="1">
              <PropertySelect
                selectRef={propertyRef}
                timing={timing}
                onChange={setTiming}
                isDisabled={!timing.entity}
              />
            </FormControl>
          </HStack>

          <ButtonGroup justifyContent="flex-end" w="full">
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={() => {
                setTiming(config.timing ?? {});
                disclosure.onClose();
              }}
            >
              Cancel
            </Button>
            <Button isDisabled={isDisabled} onClick={handleSave}>
              Save
            </Button>
          </ButtonGroup>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const PERIOD_UNITS: readonly (keyof Period)[] = ['months', 'days', 'hours', 'minutes'] as const;

const PeriodInput = forwardRef<NumberInputProps, 'input'>(({ children, ...props }, ref) => {
  return (
    <FormControl isDisabled={props.isDisabled} as={VStack} alignItems="flex-start" spacing="1">
      <NumberInput {...props}>
        <NumberInputField ref={ref} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      <FormLabel>{children}</FormLabel>
    </FormControl>
  );
});

import { AutomationAppType } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';
import { useInjector } from 'components/injection-provider';
import {
  useGetAllIncomingWebhooksByDataSetIdQuery,
  useGetAllIncomingWebhooksByTemplateIdQuery,
  useSolutionInstancesByTemplateIdQuery,
} from 'features/automations/query-builder';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { match, P } from 'ts-pattern';

export function useGetAutomationInstanceQuery({
  automationInstanceId,
  automationInstanceType,
}: {
  automationInstanceId?: Muid;
  automationInstanceType?: AutomationAppType;
}) {
  const { $stateParams } = useInjector('$stateParams');
  const templateId: Muid | undefined = $stateParams.id;
  const { dataSetId } = $stateParams;

  const selectedTemplateSolutionInstanceQuery = useSolutionInstancesByTemplateIdQuery(
    { templateId: templateId ?? '' },
    {
      enabled: Boolean(templateId) && automationInstanceType === 'tray',
      select: instances => instances.find(instance => instance.id === automationInstanceId),
    },
  );

  const templateWebhooksQuery = useGetAllIncomingWebhooksByTemplateIdQuery(
    { templateId: templateId ?? '' },
    {
      enabled: Boolean(templateId) && automationInstanceType === 'webhook',
      select: webhooks => webhooks.find(webhook => webhook.id === automationInstanceId),
    },
  );

  const dataSetWebhooksQuery = useGetAllIncomingWebhooksByDataSetIdQuery(
    { dataSetId: dataSetId ?? '' },
    {
      enabled: Boolean(dataSetId) && automationInstanceType === 'webhook',
      select: webhooks => webhooks.find(webhook => webhook.id === automationInstanceId),
    },
  );

  const nativeAutomationsQuery = GetAllNativeAutomationsQuery.useQuery(
    {
      dataSetId,
    },
    {
      enabled: Boolean(dataSetId),
      select: automations => automations.find(automation => automation.automation.id === automationInstanceId),
    },
  );

  return match({ automationInstanceType, templateId, dataSetId })
    .with({ automationInstanceType: 'webhook', templateId: P.string }, () => templateWebhooksQuery)
    .with({ automationInstanceType: 'webhook', dataSetId: P.string }, () => dataSetWebhooksQuery)
    .with({ automationInstanceType: 'native', dataSetId: P.string }, () => nativeAutomationsQuery)
    .otherwise(() => selectedTemplateSolutionInstanceQuery);
}

import { SendRichEmailFormFieldWidget } from '@process-street/subgrade/process';
import { Box, Switch, Text, Tooltip, useToast } from 'components/design/next';
import { useQueryClient } from 'react-query';
import {
  CreateNativeAutomationMutation,
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationStatusMutation,
} from 'features/native-automations/query-builder';
import { match, P } from 'ts-pattern';
import { Muid } from '@process-street/subgrade/core';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { EventName } from 'services/event-name';

interface AutoSendEmailToggleProps {
  widget: SendRichEmailFormFieldWidget;
  isEnabled: boolean;
  onWidgetUpdate: (options: { widget: SendRichEmailFormFieldWidget }) => void;
}

export interface AutoSendEmailToggleEvent {
  widgetId: Muid;
  value: boolean;
}

export const AutoSendEmailToggle: React.FC<React.PropsWithChildren<AutoSendEmailToggleProps>> = ({
  widget,
  isEnabled,
  onWidgetUpdate,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const richEmailAutomationQuery = GetAllNativeAutomationsQuery.useFormFieldWidgetAutomationQuery({
    templateRevisionId: widget?.templateRevision?.id,
    formFieldWidgetId: widget?.id,
  });

  const createNativeAutomationMutation = CreateNativeAutomationMutation.useMutation();
  const updateNativeAutomationStatusMutation = UpdateNativeAutomationStatusMutation.useMutation();

  /** When enabling auto-send, disable widget's required status, otherwise the task would be impossible to complete. */
  const disableWidgetRequired = () => {
    onWidgetUpdate({ widget: { ...widget, required: false } });
  };

  const [isCreating, setCreating] = React.useState(false);

  const handleAutomationToggle = async () => {
    if (isCreating) return;
    match(richEmailAutomationQuery)
      .with({ status: 'success', data: undefined }, () => {
        // Create the automation if one does not already exist.
        setCreating(true);
        createNativeAutomationMutation
          .mutateAsync({
            automationType: 'AutoSendRichEmailWidget',
            linkedEntity: {
              formFieldWidgetId: widget?.id,
            },
          })
          .then(() => queryClient.invalidateQueries(GetAllNativeAutomationsQuery.key))
          .finally(() => setCreating(false));

        disableWidgetRequired();
      })
      .with({ status: 'success', data: { id: P.select(), status: 'Active' } }, (nativeAutomationId: Muid) => {
        // Toggle the automation status if one does already exist.
        updateNativeAutomationStatusMutation.mutateAsync({ nativeAutomationId, status: 'Disabled' }).then(() => {
          queryClient.invalidateQueries(GetAllNativeAutomationsQuery.key);
        });
      })
      .with({ status: 'success', data: { id: P.select(), status: 'Disabled' } }, (nativeAutomationId: Muid) => {
        // Toggle the automation status if one does already exist.
        updateNativeAutomationStatusMutation.mutateAsync({ nativeAutomationId, status: 'Active' }).then(() => {
          queryClient.invalidateQueries(GetAllNativeAutomationsQuery.key);
        });
        disableWidgetRequired();
      })
      .with({ status: 'error' }, () => {
        toast({
          status: 'error',
          title: "We're having problems updating the rich email widget.",
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      })
      .run();
  };

  const isToggleChecked = richEmailAutomationQuery.isSuccess && richEmailAutomationQuery.data?.status === 'Active';

  React.useEffect(() => {
    if (!isEnabled && isToggleChecked) {
      handleAutomationToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore local method handleAutomationToggle
  }, [isToggleChecked, isEnabled]);

  const { $rootScope } = useInjector('$rootScope');
  React.useEffect(() => {
    // Event is consumed in Angular.js land, to show or hide "required" checkbox
    const event: AutoSendEmailToggleEvent = { widgetId: widget.id, value: isToggleChecked };
    $rootScope.$broadcast(EventName.AUTO_SEND_RICH_EMAIL_TOGGLE, event);
  }, [$rootScope, isToggleChecked, widget.id]);

  const isSwitchEnabled =
    isEnabled &&
    !richEmailAutomationQuery.isLoading &&
    !isCreating &&
    !createNativeAutomationMutation.isLoading &&
    !updateNativeAutomationStatusMutation.isLoading;

  return (
    <Tooltip label="Email recipient, subject & body are required." isDisabled={isEnabled} hasArrow>
      {/* wrap children in Box because popover hover doesn't work with disabled buttons */}
      <Box w="fit-content">
        <Switch
          isChecked={isToggleChecked}
          onChange={handleAutomationToggle}
          size="lg"
          display="flex"
          alignItems="center"
          isDisabled={!isSwitchEnabled}
        >
          <Text variant="-1" color="gray.400" as="span" ml="1">
            Auto-send on task completion
          </Text>
        </Switch>
      </Box>
    </Tooltip>
  );
};

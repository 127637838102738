import * as React from 'react';
import { Button, Text, VStack, Icon } from 'components/design/next';
import { KeyStrings } from 'services/key';
import { MultiSelectFieldValue, MultiSelectItemValueStatus, TemplateRevision } from '@process-street/subgrade/process';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { FormikErrors, FormikTouched } from 'formik';
import { Subtask } from './subtask';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UseDropOnSubtaskListItemValue } from './use-drop-on-subtask-list-item';

type Value = NonNullable<MultiSelectFieldValue>;

export type SubtasksFormFieldProps = {
  value: Value[];
  onChange: (value: Value[]) => void;
  onBlur: (index: number) => void;
  touched?: FormikTouched<{ name: boolean }>[];
  errors?: FormikErrors<{ name: string }>[];
  editorType?: SubtaskEditor;
  templateRevisionId?: TemplateRevision['id'];
  groupId?: Muid;
};
export enum SubtaskEditor {
  OneOffTask = 'OneOffTask',
  WFEditor = 'WFEditor',
}

export const SubtasksFormField = ({
  value,
  errors,
  touched,
  onBlur,
  onChange,
  editorType = SubtaskEditor.OneOffTask,
  templateRevisionId,
  groupId,
}: SubtasksFormFieldProps) => {
  const createEmptyValue = React.useCallback(
    (name = ''): Value => {
      const itemType = editorType === SubtaskEditor.OneOffTask ? 'Dynamic' : 'Static';
      return {
        status: MultiSelectItemValueStatus.NotCompleted,
        itemType,
        id: MuidUtils.randomMuid(),
        name,
      };
    },
    [editorType],
  );

  const setFocus = (index: number) => {
    requestAnimationFrame(() => {
      const input = document.querySelector(`[name="subtasks.${index}${groupId ? '-' + groupId : ''}"`);

      if (!input) return;

      (input as HTMLInputElement).focus();
    });
  };

  const handleAddSubtask = () => {
    const newValue: Value[] = value.length === 0 ? [createEmptyValue()] : [...value, createEmptyValue()];
    onChange(newValue);

    if (value.length === 0) {
      setFocus(0);
    } else {
      setFocus(newValue.length - 1);
    }
  };

  const createHandleSubtaskChange = (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = getNewValue(e.target.value, index);
    onChange(newValue);
  };

  const createHandleSubtaskChangeFromMergeTags = (index: number) => (mergedValue: string) => {
    const newValue = getNewValue(mergedValue, index);
    onChange(newValue);
  };

  const getNewValue = (eventValue: string, index: number) => {
    const [name, ...newItems] = eventValue.split('\n');
    return value.flatMap((value, i) => {
      if (i === index) {
        return [
          {
            ...value,
            name,
          },
          ...newItems.map(newItem => createEmptyValue(newItem)),
        ] as Value[];
      }
      return value;
    });
  };

  const createHandleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const subtask = value[index];
    // Out of bounds
    if (!subtask) return;

    if (e.key === KeyStrings.BACKSPACE && (subtask.name ?? '').length === 0) {
      e.preventDefault();
      e.stopPropagation();

      const newValue = value.filter((_, i) => i !== index);
      onChange(newValue);

      setFocus(index - 1);

      return;
    }

    if (e.key === KeyStrings.ENTER) {
      e.preventDefault();
      e.stopPropagation();

      const newValue = value.reduce((acc, value, i) => {
        if (i === index) {
          // Add new subtask after focused input
          return [...acc, value, createEmptyValue()];
        }

        return [...acc, value];
      }, [] as Value[]);

      onChange(newValue);

      setFocus(index + 1);

      return;
    }

    if (e.key === KeyStrings.ARROW_UP && index > 0) {
      e.preventDefault();
      e.stopPropagation();
      setFocus(index - 1);
      return;
    }

    if (e.key === KeyStrings.ARROW_DOWN && index < value.length - 1) {
      e.preventDefault();
      e.stopPropagation();
      setFocus(index + 1);
      return;
    }
  };

  const createHandleDeleteSubtask = (index: number) => () => {
    const newValue = value.filter((_, i) => i !== index);
    onChange(newValue);
  };

  const handleDrop = React.useCallback(
    ({ before, after, item }: UseDropOnSubtaskListItemValue) => {
      if (!before && !after) return value;

      const newValue = value.reduce((acc, current) => {
        if (current.id === before?.id) {
          acc.push(item, current);
        } else if (current.id === after?.id) {
          acc.push(current, item);
        } else if (current.id !== item.id) {
          acc.push(current);
        }

        return acc;
      }, [] as MultiSelectFieldValue[]);

      onChange(newValue.flat());
    },
    // Remove onChange from the dependencies array as we can not be sure its reference is safe
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <VStack w="full" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
        {value.length > 0 && (
          <>
            {editorType === SubtaskEditor.OneOffTask && (
              <Text variant="-2u" color="gray.600">
                Subtasks
              </Text>
            )}

            <VStack w="full" justifyContent="flex-start" alignItems="flex-start" spacing={1} listStyleType="none">
              {value.map((subtask, index) => {
                return (
                  <Subtask
                    key={`subtask-${index}`}
                    index={index}
                    subtask={subtask}
                    error={errors?.[index]}
                    editorType={editorType}
                    touched={touched?.[index]}
                    onChange={createHandleSubtaskChange(index)}
                    onKeyDown={createHandleKeyDown(index)}
                    onBlur={() => onBlur(index)}
                    onDelete={createHandleDeleteSubtask(index)}
                    onDrop={handleDrop}
                    onMergeTagChange={createHandleSubtaskChangeFromMergeTags(index)}
                    templateRevisionId={templateRevisionId}
                    groupId={groupId}
                  />
                );
              })}
            </VStack>
          </>
        )}
        <Button
          variant="outline"
          leftIcon={<Icon icon="plus" size="3" />}
          fontSize="sm"
          colorScheme="gray"
          color="gray.600"
          fontWeight="normal"
          borderColor="gray.300"
          borderWidth="thin"
          type="button"
          onClick={handleAddSubtask}
        >
          Add subtask
        </Button>
      </VStack>
    </DndProvider>
  );
};

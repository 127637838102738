import React, { lazy } from 'react';
import { useAddEmojiCoverIconMutation } from 'features/cover-icon/query-builder';
import { Template } from '@process-street/subgrade/process';
import { Box, useToast } from 'components/design/next';
import { Spinner } from 'components/common/spinner/Spinner';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type EmojiPickerProps = { templateId: Template['id']; onFinish: (templateId: Template['id']) => void };

export const EmojiPicker = ({ templateId, onFinish }: EmojiPickerProps) => {
  const toast = useToast();
  const Picker = lazy(() => import('emoji-picker-react'));

  const addEmojiMutation = useAddEmojiCoverIconMutation({
    onSuccess: () => onFinish(templateId),
    onError: () => {
      toast({
        status: 'error',
        title: "We're having problems saving the emoji",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const spinner = (
    <Box pt={4}>
      <Spinner></Spinner>
    </Box>
  );

  return (
    <Box pt="5" minHeight={20} sx={{ '.emoji-picker-react .emoji-categories': { display: 'none' } }}>
      <React.Suspense fallback={spinner}>
        <Picker
          onEmojiClick={(_, { emoji }) => addEmojiMutation.mutate({ templateId, emoji })}
          searchPlaceholder="Filter"
          native
          pickerStyle={{ width: '100%', border: 'none', boxShadow: 'none' }}
        />
      </React.Suspense>
    </Box>
  );
};

export enum MergeTagMode {
  PLAINTEXT = 'Plaintext',
  HTML = 'Html',
}

export enum MergeTagTarget {
  GENERAL = 'General',
  SNIPPET = 'Snippet',
  EMAIL = 'Email',
  CHECKLIST = 'Checklist',
  AI_TASK_PROMPT = 'AiTaskPrompt',
}

export const MergeTagsConstants = {
  Mode: MergeTagMode,
  Target: MergeTagTarget,
};

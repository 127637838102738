import { Approval } from '@process-street/subgrade/approval-rule/approval.model';
import { Muid } from '@process-street/subgrade/core';
import { APPROVAL_UPSERT_ALL_BY_TOKEN } from 'components/approvals/email-initiated/store/email-initiated.actions';
import { getStatusReducer } from 'reducers/statuses/statuses-utils';
import { BulkApprovalStatuses, EmailInitiatedApprovalModuleState } from 'reducers/types';
import { toSuccess } from 'reducers/util';
import { handleActionsOnSuccess } from 'reducers/util/handle-actions-on-success';
import { combineReducers } from 'redux';
import { ActionMeta } from 'redux-actions';

export const emailInitiatedReducers = handleActionsOnSuccess(
  {
    [toSuccess(APPROVAL_UPSERT_ALL_BY_TOKEN)]: (
      _state: EmailInitiatedApprovalModuleState | undefined,
      { payload, meta: { taskId } }: ActionMeta<Approval[], { taskId: Muid }>,
    ): EmailInitiatedApprovalModuleState => {
      return { [taskId]: { approvals: payload } };
    },
  },
  {},
);

export const bulkApprovalStatusesReducer = combineReducers<BulkApprovalStatuses>({
  byTaskId: getStatusReducer(APPROVAL_UPSERT_ALL_BY_TOKEN, 'taskId'),
});

import { ButtonProps, useDisclosure } from 'components/design/next';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { TagsModal } from 'features/tags/components/tags-modal';
import * as React from 'react';
import { useTemplateMenuContext } from '..';

interface ManageTemplateTagsButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const ManageTemplateTagsButton: React.FC<React.PropsWithChildren<ManageTemplateTagsButtonProps>> = React.memo(
  ({ children }) => {
    const { setCloseOnBlur, templateId } = useTemplateMenuContext();
    const disclosure = useDisclosure({
      id: 'manage-tags',
      onOpen: () => {
        setCloseOnBlur(false);
      },
      onClose: () => {
        setCloseOnBlur(true);
      },
    });

    // UI
    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('ManageTemplateTagsButton child must be a single clickable element');
    }
    const btn = React.cloneElement(children, {
      onClick: () => {
        disclosure.onToggle();
      },
    });

    const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId, {
      enabled: !!templateId,
    });
    const canManageTemplateTags = permissionMap?.templateUpdate;

    return canManageTemplateTags ? (
      <>
        {btn}
        <TagsModal onClose={disclosure.onClose} isOpen={disclosure.isOpen} templateId={templateId} />
      </>
    ) : null;
  },
);

import { Button, Icon, Text, PopoverTrigger } from 'components/design/next';
import * as React from 'react';
import { Option } from '@process-street/subgrade/core';
import { DueDateRuleDefinition } from '@process-street/subgrade/process';
import { useFeatureFlag } from 'app/features/feature-flags';

export type TaskTriggerButtonProps = {
  buttonTitle: string | null;
  disabled: boolean;
  rule: Option<DueDateRuleDefinition>;
};

export const TaskTriggerButton: React.FC<React.PropsWithChildren<TaskTriggerButtonProps>> = ({
  disabled,
  rule,
  buttonTitle,
}) => {
  const isReactWFEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  return (
    <PopoverTrigger>
      <Button
        display="flex"
        color="gray.500"
        justifyContent={rule ? 'flex-start' : 'center'}
        isDisabled={disabled}
        variant={disabled ? 'ghost' : 'outline'}
        size={'sm'}
        borderWidth="px"
        borderColor="gray.300"
        px={'6px'}
        leftIcon={
          <Icon
            icon={isReactWFEditorEnabled ? 'calendar-days' : 'clock'}
            variant="far"
            size="4"
            color={rule && !disabled ? 'purple.500' : 'gray.400'}
          />
        }
        iconSpacing={rule ? 1 : 0}
        aria-label="Due date"
      >
        <Text
          variant={disabled ? '1' : '-1'}
          maxW={'116px'}
          color="gray.400"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontWeight="normal"
        >
          {buttonTitle}
        </Text>
      </Button>
    </PopoverTrigger>
  );
};

import * as React from 'react';
import { Box, HStack, Text, ListItem } from 'components/design/next';
import { isNotIdRef, OrganizationMembershipWithUser, UserType } from '@process-street/subgrade/core';
import { Avatar, AvatarSize } from 'components/common/Avatar';

type MembershipItemProps = {
  item: OrganizationMembershipWithUser;
  onClick: (membership: OrganizationMembershipWithUser) => void;
};

export const MembershipItem: React.FC<React.PropsWithChildren<MembershipItemProps>> = ({ item, onClick }) => {
  const { user } = item;
  if (!isNotIdRef(user)) return null;

  const subtitle = user.userType === UserType.Group ? 'Group' : user.email;

  const handleClick = () => {
    onClick(item);
  };

  return (
    <ListItem
      key={item.id}
      px={4}
      py={2}
      _hover={{
        backgroundColor: 'gray.100',
      }}
      cursor="pointer"
      onClick={handleClick}
    >
      <HStack>
        <Box w={6} mr={1}>
          <Avatar user={user} size={AvatarSize.Small} showUnconfirmedBadge={true} />
        </Box>

        <Box flex={1} role="cell" maxW={78}>
          <Text variant="-1" fontWeight="medium" color="gray.600" noOfLines={1}>
            {user.username}
          </Text>

          <Text variant="-2" color="gray.500" noOfLines={1}>
            {subtitle}
          </Text>
        </Box>
      </HStack>
    </ListItem>
  );
};

import { useToast } from 'components/design/next';
import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';
import { trace } from 'components/trace';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder/cover-image-by-template-id';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type DeleteCoverImageMutationParams = { templateId: Template['id'] };

export type DeleteCoverImageMutationResponse = {};

export const DeleteCoverImageMutation = {
  key: ['delete', 'cover-image'],
  mutationFn: (params: DeleteCoverImageMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteCoverImageMutationResponse>(`/1/templates/${params.templateId}/cover-image`)
      .then(res => res.data),
};

export const useDeleteCoverImageMutation = (
  options: UseMutationOptions<DeleteCoverImageMutationResponse, AxiosError, DeleteCoverImageMutationParams> = {},
) => {
  return useMutation(DeleteCoverImageMutation.mutationFn, options);
};

export const useDeleteCoverImageMutationWithToast = (
  options: UseMutationOptions<DeleteCoverImageMutationResponse, AxiosError, DeleteCoverImageMutationParams> = {},
) => {
  const toast = useToast();
  const logger = trace({ name: 'RemoveIcon' });
  const queryClient = useQueryClient();

  return useMutation(DeleteCoverImageMutation.mutationFn, {
    onSuccess: (_, variables) => {
      toast({
        status: 'success',
        title: 'Cover image deleted',
      });
      queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId: variables.templateId }));
    },
    onError: e => {
      logger.error(e.message);
      toast({
        status: 'error',
        title: "We're having problems deleting the cover image",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
    ...options,
  });
};

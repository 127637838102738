import * as React from 'react';
import { Flex, Text, VStack } from 'components/design/next';
import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';
import { Dictionary } from 'lodash';
import {
  DueDateRuleDefinition,
  TaskTemplate,
  TemplateRevision,
  TemplateTaskAssignment,
} from '@process-street/subgrade/process';
import { ApprovalTaskListItem } from './approval-task-list-item';

export type ApprovalTaskListProps = {
  tasksFromApprovalRule: ApprovalRuleSubject[];
  tasksMap: Dictionary<TaskTemplate>;
  dueDateRulesMap: Dictionary<DueDateRuleDefinition>;
  taskAssignmentsMap: Dictionary<TemplateTaskAssignment[]>;
  templateRevisionId: TemplateRevision['id'];
};

export const ApprovalTaskList: React.FC<ApprovalTaskListProps> = ({
  dueDateRulesMap,
  tasksFromApprovalRule,
  tasksMap,
  templateRevisionId,
  taskAssignmentsMap,
}) => (
  <VStack alignItems="flex-start" w="full" mt={8} gap={4}>
    <Text
      as="h2"
      fontWeight="bold"
      color="gray.500"
    >{`Will be submitted for approval (${tasksFromApprovalRule.length})`}</Text>
    <Flex
      flexDir="column"
      w="full"
      borderLeft={{ base: 'none', md: '1px solid var(--ps-colors-gray-100)' }}
      px={{ base: 0, md: 8 }}
      gap={4}
      as="ul"
    >
      {tasksFromApprovalRule.map(task => {
        const taskTemplate = tasksMap[task.subjectTaskTemplateGroupId];

        if (!taskTemplate) return null;

        return (
          <ApprovalTaskListItem
            key={`approval-task-list-item-${task.id}`}
            task={taskTemplate}
            approvalRuleId={task.id}
            templateRevisionId={templateRevisionId}
            dueDateRules={dueDateRulesMap[taskTemplate.group.id]}
            taskAssignments={taskAssignmentsMap[taskTemplate.id]}
          />
        );
      })}
    </Flex>
  </VStack>
);

import { useSelector } from '@xstate/react';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';

export const useTaskTemplateListActorRef = () => {
  const editorActor = useFormEditorPageActorRef();
  return useSelector(editorActor, FormEditorPageActorSelectors.getTaskTemplateListActorRef);
};

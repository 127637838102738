import { SolutionTypeTag, TemplateSolutionInstance, AutomationInstance } from '@process-street/subgrade/automation';
import { Template } from '@process-street/subgrade/process';
import {
  useGetAllIncomingWebhooksByTemplateIdQuery,
  useSolutionInstancesByTemplateIdQuery,
  useSolutionsByTemplateIdQuery,
} from 'features/automations/query-builder';
import * as React from 'react';

type GroupedSolutions = {
  [SolutionTypeTag.CreateChecklistWhen]: AutomationInstance[];
  [SolutionTypeTag.WhenTaskCheckedThen]: TemplateSolutionInstance[];
  [SolutionTypeTag.WhenChecklistCompletedThen]: TemplateSolutionInstance[];
};

export function useSolutionTypeTagInstances(
  { templateId }: { templateId?: Template['id'] },
  { enabled }: { enabled: boolean } = { enabled: true },
): { instances: GroupedSolutions; isLoading: boolean } {
  const solutionsQuery = useSolutionsByTemplateIdQuery({ templateId }, { enabled });
  const solutions = solutionsQuery.data ?? [];

  const templateSolutionInstancesQuery = useSolutionInstancesByTemplateIdQuery({ templateId }, { enabled });
  const templateSolutionInstances = React.useMemo(
    () => templateSolutionInstancesQuery.data ?? [],
    [templateSolutionInstancesQuery.data],
  );

  const createChecklistWhenSolutionIds = solutions
    .filter(solution => solution.solutionTypeTag === SolutionTypeTag.CreateChecklistWhen)
    .map(s => s.id);
  const whenTaskCheckedSolutionIds = solutions
    .filter(solution => solution.solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen)
    .map(s => s.id);
  const whenChecklistCompletedThenSolutionIds = solutions
    .filter(solution => solution.solutionTypeTag === SolutionTypeTag.WhenChecklistCompletedThen)
    .map(s => s.id);

  const incomingWebhooksQuery = useGetAllIncomingWebhooksByTemplateIdQuery(
    { templateId },
    {
      enabled,
      select: webhooks => webhooks.filter(({ status }) => status !== 'Deleted'),
    },
  );

  const isLoading =
    incomingWebhooksQuery.isLoading || templateSolutionInstancesQuery.isLoading || solutionsQuery.isLoading;

  const incomingWebhooks = React.useMemo(() => incomingWebhooksQuery.data ?? [], [incomingWebhooksQuery.data]);
  const createChecklistWhenInstances: AutomationInstance[] = React.useMemo(
    () => [
      ...templateSolutionInstances.filter(
        tsi =>
          createChecklistWhenSolutionIds.includes(tsi.solutionId) ||
          tsi.solutionTypeTag === SolutionTypeTag.CreateChecklistWhen,
      ),
      ...incomingWebhooks,
    ],
    [createChecklistWhenSolutionIds, incomingWebhooks, templateSolutionInstances],
  );

  const whenTaskCheckedInstances: TemplateSolutionInstance[] = templateSolutionInstances.filter(
    tsi =>
      whenTaskCheckedSolutionIds.includes(tsi.solutionId) ||
      tsi.solutionTypeTag === SolutionTypeTag.WhenTaskCheckedThen,
  );

  const whenChecklistCompletedThenSolutionInstances: TemplateSolutionInstance[] = templateSolutionInstances.filter(
    tsi =>
      whenChecklistCompletedThenSolutionIds.includes(tsi.solutionId) ||
      tsi.solutionTypeTag === SolutionTypeTag.WhenChecklistCompletedThen,
  );

  const instances = React.useMemo<GroupedSolutions>(
    () => ({
      [SolutionTypeTag.CreateChecklistWhen]: createChecklistWhenInstances,
      [SolutionTypeTag.WhenTaskCheckedThen]: whenTaskCheckedInstances,
      [SolutionTypeTag.WhenChecklistCompletedThen]: whenChecklistCompletedThenSolutionInstances,
    }),
    [createChecklistWhenInstances, whenChecklistCompletedThenSolutionInstances, whenTaskCheckedInstances],
  );
  return { instances, isLoading };
}

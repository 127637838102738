import { Center, Icon, Tooltip } from 'components/design/next';
import * as React from 'react';

export const TaskListItemConditionalLogicIndicator = () => {
  return (
    <Tooltip label="Conditional logic applied">
      <Center aria-label="Conditional logic applied" w={6} h={8}>
        <Icon icon="shuffle" color="purple.500" size="3.5" />
      </Center>
    </Tooltip>
  );
};

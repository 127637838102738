import * as React from 'react';
import { StackProps, VStack } from 'components/design/next';
import { FormEmptyState, WorkflowEmptyState } from '../empty-state';
import { useSelector } from '@xstate/react';
import { TaskTemplate, TemplateType, Widget } from '@process-street/subgrade/process';
import {
  FormEditorPageActorSelectors,
  FormEditorPageMachine,
  useFormEditorPageActorRef,
} from '../../form-editor-page-machine';
import { MotionConfig, Reorder } from 'framer-motion';
import { useEvent } from 'react-use';
import { WidgetListItem } from './widget-list-item';
import { makeGetActor } from './make-get-actor';
import { useStateParam } from 'hooks/use-state-param';
import { useTaskTemplateGroupIdNavSync } from './use-task-template-group-id-nav-sync';
import _isEqual from 'lodash/isEqual';
import { match, P } from 'ts-pattern';
import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';
import { Option } from 'space-monad';
import { StateFrom } from 'xstate';

type WidgetsListProps = StackProps;

const isMeaningfulTaskTemplatePropertiesEqual = (a: TaskTemplate | undefined, b: TaskTemplate | undefined) => {
  const meaningfulProperties = ['id', 'templateType'];
  if (!a || !b) return a === b;

  return _isEqual(_pick(a, meaningfulProperties), _pick(b, meaningfulProperties));
};

export const WidgetsList = React.memo((props: WidgetsListProps) => {
  const actor = useFormEditorPageActorRef();
  const { send } = actor;

  useTaskTemplateGroupIdNavSync();
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });
  const taskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(taskTemplateGroupId)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [taskTemplateGroupId],
  );

  const taskTemplate = useSelector(actor, taskTemplateSelector, isMeaningfulTaskTemplatePropertiesEqual);

  const widgetsSelector = React.useMemo(
    () => FormEditorPageActorSelectors.getWidgetsForTaskTemplateGroupId(taskTemplateGroupId),
    [taskTemplateGroupId],
  );
  const widgets = useSelector(actor, widgetsSelector, _isEqual);

  const publishedWidgetsSelector = React.useMemo(
    () => FormEditorPageActorSelectors.getPublishedWidgetsForTaskTemplateGroupId(taskTemplateGroupId),
    [taskTemplateGroupId],
  );
  const publishedWidgets = useSelector(actor, publishedWidgetsSelector, _isEqual);
  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);

  const widgetActorMap = useSelector(actor, FormEditorPageActorSelectors.getWidgetActorMap);
  const getActor = React.useMemo(() => makeGetActor(widgetActorMap ?? {}), [widgetActorMap]);

  const visibleWidgets = React.useMemo(() => {
    const ws = widgets.length > 0 ? widgets : publishedWidgets ?? [];
    // extra step here to make sure the actor exists,
    // we look it up again within the `match` branches for better type checking
    return ws.filter(w => Boolean(getActor(w)));
  }, [getActor, publishedWidgets, widgets]);

  const lastWidgetIndex = visibleWidgets.length - 1;

  const shouldShowEmptyState = visibleWidgets.length === 0;

  const isPublishing = useSelector(actor, FormEditorPageActorSelectors.isPublishing);
  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading);
  const isReordering = useSelector(actor, FormEditorPageActorSelectors.isReordering);

  const handleReorder = React.useCallback(
    (widgets: Widget[]) => {
      send({ type: 'REORDER_WIDGETS', widgets });
    },
    [send],
  );

  // we send MOUSE_UP on these two window events because you can move the mouse off the drag icon while the mouse is clicked
  // and still be dragging. The machine will only respond to this event while in the dragging state.
  useEvent('mouseup', () => {
    if (isReordering) {
      send('MOUSE_UP');
    }
  });

  useEvent('touchend', () => {
    if (isReordering) {
      send('MOUSE_UP');
    }
  });

  if (!template) return null;

  return (
    <MotionConfig reducedMotion={'always'}>
      <VStack
        as={Reorder.Group}
        axis="y"
        values={visibleWidgets}
        // we are modifying the stack spacing to use padding instead of margin.
        // this allows a better hover experience when for drag'n'drop to insert a widget
        // because margin is not included in element.getBoundingClientRect()
        spacing="0"
        sx={{
          'listStyle': 'none',
          '[data-component="FormFieldLabel"]': { width: 'auto' },
          '& > *:first-of-type': {
            pb: 4,
          },
          '& > *:last-of-type': {
            pb: 0,
          },
          '& > *:not(style)~*:not(style)': {
            py: '4',
          },
        }}
        onReorder={handleReorder}
        alignItems="stretch"
        w="full"
        py="8"
        px="0"
        {...(isPublishing ? { pointerEvents: 'none' } : {})}
        {...props}
      >
        {shouldShowEmptyState
          ? match({ templateType: template.templateType, taskTemplate })
              .with({ templateType: TemplateType.Form }, () => <FormEmptyState isLoading={isLoading} />)
              .with({ templateType: TemplateType.Playbook, taskTemplate: P.not(P.nullish) }, ({ taskTemplate }) => (
                <WorkflowEmptyState taskTemplate={taskTemplate} />
              ))
              .otherwise(() => null)
          : visibleWidgets.map((widget, index) => (
              <WidgetListItem
                key={widget.header.id}
                widget={widget}
                isFirst={index === 0}
                isLast={index === lastWidgetIndex}
                getActor={getActor}
              />
            ))}
      </VStack>
    </MotionConfig>
  );
});

WidgetsList.displayName = 'WidgetsList';

import * as React from 'react';
import { Box, BoxProps } from 'components/design/next';
import { FormatBlockType } from 'directives/text-widget-toolbar/toolbar-blocks';
import 'tinymce-6/tinymce';
import 'tinymce-6/icons/default';
import 'tinymce-6/models/dom';
import 'tinymce-6/themes/silver';
import 'tinymce-6/plugins/autolink';
import 'tinymce-6/plugins/link';
import 'tinymce-6/plugins/lists';
import 'tinymce-6/skins/ui/oxide/skin.min.css';
import 'tinymce-6/skins/ui/oxide/content.min.css';
import 'tinymce-6/skins/content/default/content.min.css';
import { Editor, IAllProps } from '@tinymce/tinymce-react';

export interface RichTextEditorProps {
  editorProps?: Omit<IAllProps, 'init'>;
  init?: IAllProps['init'];
  editorWrapperProps?: Partial<BoxProps>;
}

export type TinyMCEEditor = NonNullable<InstanceType<typeof Editor>['editor']>;

export const RichTextEditor: React.FC<React.PropsWithChildren<RichTextEditorProps>> = ({
  editorProps,
  init,
  editorWrapperProps,
}) => {
  return (
    <Box
      {...editorWrapperProps}
      sx={{
        '.mce-content-body': {
          border: 'none',
          outline: 'none',
          // a little trick to make sure the cursor is visible at the beginning of the line
          mx: -1,
          px: 1,
          py: 2,
        },
        '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before': {
          fontStyle: 'italic',
          pl: '1',
        },
        ...editorWrapperProps?.sx,
      }}
    >
      <Editor inline={true} init={{ ...INIT, ...init }} {...editorProps} />
    </Box>
  );
};

const INIT: IAllProps['init'] = {
  menubar: false,
  toolbar: [
    ['bold', 'underline', 'italic'],
    ['blocks'],
    ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
    ['bullist', 'numlist'],
  ]
    .map(section => section.join(' '))
    .join(' '),
  browser_spellcheck: true,
  entity_encoding: 'raw',
  plugins: 'autolink link lists',
  default_link_target: '_blank',
  valid_elements:
    'a[href|rel|target|title],strong/b,em/i,u,p[class|style],ul,ol,li[style],' +
    'br,hr,h1[style],h2[style],h3[style],h4[style],h5[style],h6[style],pre,code,span[style]',
  valid_classes: Object.values(FormatBlockType).join(' '),
  valid_styles: {
    li: 'text-align',
    p: 'padding-left,text-align',
    span: 'background-color,color,text-decoration',
    h1: 'text-align',
    h2: 'text-align',
    h3: 'text-align',
    h4: 'text-align',
    h5: 'text-align',
    h6: 'text-align',
  },
  paste_auto_cleanup_on_paste: true,
  fix_list_elements: true,
  convert_urls: false,
  link_assume_external_targets: true,
  placeholder: 'Write something here...',
  toolbar_mode: 'scrolling', // https://www.tiny.cloud/docs/tinymce/6/toolbar-configuration-options/#toolbar
};

import { Checklist, ChecklistMode } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Muid } from '@process-street/subgrade/core';

export type CreateChecklistMutationParams = {
  templateId: Muid;
  name?: string;
  dueDate?: number;
  formFieldsObject?: any;
  mode?: ChecklistMode;
};

export type CreateChecklistMutationResponse = Checklist;

export const CreateChecklistMutation = {
  mutationFn: async (params: CreateChecklistMutationParams) => {
    return axiosService
      .getAxios()
      .post<CreateChecklistMutationResponse>(`/1/checklists`, params)
      .then(res => res.data);
  },
};

export const useCreateChecklistMutation = (
  options: UseMutationOptions<CreateChecklistMutationResponse, AxiosError, CreateChecklistMutationParams>,
) => {
  return useMutation(CreateChecklistMutation.mutationFn, options);
};

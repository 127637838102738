import { Link } from '@process-street/adapters/navigation';
import { Template } from '@process-street/subgrade/process';
import { abbreviateForTitle, queryString } from '@process-street/subgrade/util';
import { Icon } from 'components/design/next';
import * as React from 'react';
import { MobileActions } from '../../mobile-actions';

type MobilePageActionsProps = React.PropsWithChildren<{
  template: Template;
}>;

export const MobilePageActions = ({ template, children: otherActions }: MobilePageActionsProps) => {
  return (
    <MobileActions.Wrapper>
      <MobileActions.Item
        as={Link}
        // @ts-expect-error The props aren't being propagated by the `as` prop
        to={{
          pathname: 'pageView',
          search: queryString.stringify({ id: template.id, title: `${abbreviateForTitle(template?.name)}-` }),
        }}
        leftIcon={<Icon icon="eye" size="4" />}
      >
        Open page
      </MobileActions.Item>

      {otherActions}
    </MobileActions.Wrapper>
  );
};

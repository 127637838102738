import { Template } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';
import { trace } from 'components/trace';
import { useToast } from 'components/design/next';
import { CoverIconByTemplateIdQuery } from 'features/cover-icon/query-builder/cover-icon-by-template-id';
import { DefaultErrorMessages } from 'components/utils/error-messages';

export type DeleteCoverIconMutationParams = { templateId: Template['id'] };

export type DeleteCoverIconMutationResponse = {};

export const DeleteCoverIconMutation = {
  key: ['delete', 'icon'],
  mutationFn: (params: DeleteCoverIconMutationParams) =>
    axiosService
      .getAxios()
      .delete<DeleteCoverIconMutationResponse>(`/1/templates/${params.templateId}/cover-icon`)
      .then(res => res.data),
};

export const useDeleteCoverIconMutation = (
  options: UseMutationOptions<DeleteCoverIconMutationResponse, AxiosError, DeleteCoverIconMutationParams> = {},
) => {
  return useMutation(DeleteCoverIconMutation.mutationFn, options);
};

export const useDeleteCoverIconMutationWithToast = (
  options: UseMutationOptions<DeleteCoverIconMutationResponse, AxiosError, DeleteCoverIconMutationParams> = {},
) => {
  const toast = useToast();
  const logger = trace({ name: 'RemoveIcon' });
  const queryClient = useQueryClient();

  return useMutation(DeleteCoverIconMutation.mutationFn, {
    onSuccess: (_, variables) => {
      toast({
        status: 'success',
        title: 'Icon deleted',
      });
      queryClient.invalidateQueries(CoverIconByTemplateIdQuery.getKey({ templateId: variables.templateId }));
    },
    onError: e => {
      logger.error(e.message);
      toast({
        status: 'error',
        title: "We're having problems deleting the icon",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
    ...options,
  });
};

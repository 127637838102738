import * as React from 'react';
import { Center, CenterProps, Icon } from 'components/design/next';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine/form-editor-page-machine-context';
import { ReorderUtils } from './reorder-utils';

export const WidgetListItemDragIcon: React.FC<React.PropsWithChildren<CenterProps>> = props => {
  const actor = useFormEditorPageActorRef();

  return (
    <Center
      position="absolute"
      h="full"
      color="gray.400"
      w={{ base: 10, lg: 11 }}
      top="0"
      zIndex="1"
      transition="opacity 0.2s"
      transform="translateX(-100%)"
      opacity={0}
      sx={{ '*:hover > &': { opacity: 1 } }}
      onMouseDown={() => actor.send('REORDER_WIDGETS_DRAG_START')}
      onTouchStart={() => actor.send('REORDER_WIDGETS_DRAG_START')}
      {...props}
    >
      <Icon cursor="grab" icon="grip-vertical" size="3.5" variant="fas" {...ReorderUtils.HANDLE_PROPS} />
    </Center>
  );
};

export function nl2br(text: string): string {
  return text && text.replace(/(?:\n|\r\n)/g, '<br/>');
}

/** add an extra break tag for trailing line breaks within a structure of paragraph siblings (e.g., tinymce or slate)
  ```html
  <p>a</p>
  <p>
    <br>
    b
    <br> <!-- this is the trailing line break, it doesn't add space before the next content in <p> -->
  </p>
  <p>c</p>
  <!-- results in -->
  a

  b
  c

  <p>a</p>
  <p>
    <br>
    b
    <br>
    <br> <!-- with an extra <br> this is the trailing line break, it adds space before the next content in <p> -->
  </p>
  <p>c</p>
  <!-- results in -->
  a

  b

  c
  ```
*/
export function padTrailingBr(htmlString: string): string {
  return htmlString.replace(/<br ?\/?><\/p>/, '<br><br></p>');
}

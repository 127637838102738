import { TaskConstants, TaskTemplate } from '@process-street/subgrade/process';
import { Box, Icon, Tooltip } from 'components/design/next';
import * as React from 'react';

export interface FormFieldRecentlyMovedIndicatorProps {
  from: TaskTemplate;
}

export const FormFieldRecentlyMovedIndicator: React.FC<
  React.PropsWithChildren<FormFieldRecentlyMovedIndicatorProps>
> = ({ from }) => {
  return (
    <Tooltip label={`Form field moved from ${from.name ?? TaskConstants.DefaultStepName}`}>
      <Box position="absolute" color="brand.500" top="-2" left="-2">
        <Icon icon="circle" variant="fas" size="2" />
      </Box>
    </Tooltip>
  );
};

import * as React from 'react';
import {
  Box,
  Button,
  Icon,
  Input,
  PopoverTrigger,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Tooltip,
  Link,
  ButtonProps,
} from 'components/design/next';
import { DynamicAssignmentItem } from 'pages/templates/_id/components/assignment-picker/DynamicAssignmentItem';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { MembershipItem } from 'pages/templates/_id/components/assignment-picker/MembershipItem';
import { DynamicAssignment } from 'pages/templates/_id/models/assignments';
import { Invite } from 'pages/templates/_id/components/assignment-picker/Invite';
import { LimitedList } from 'pages/templates/_id/components/assignment-picker/LimitedList';
import { TaskTemplate } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';

export type AssignmentPickerProps = {
  allMembers: OrganizationMembershipWithUser[];
  availableDynamicAssignments: DynamicAssignment[];
  availableMemberships: OrganizationMembershipWithUser[];
  onAssignmentCreate: (membership: OrganizationMembershipWithUser) => void;
  onInviteAndAssign: (email: string) => Promise<void>;
  onRuleCreate: (assignment: DynamicAssignment) => void;
  taskTemplates: TaskTemplate[];
  disabled?: boolean;
  hasAssignments?: boolean;
  buttonProps?: Partial<ButtonProps>;
};

export const AssignmentPicker = ({
  allMembers,
  availableDynamicAssignments,
  availableMemberships,
  onAssignmentCreate,
  onInviteAndAssign,
  onRuleCreate,
  taskTemplates,
  disabled = false,
  hasAssignments = false,
  buttonProps,
}: AssignmentPickerProps) => {
  const [filteredDynamicAssignments, setFilteredDynamicAssignments] = React.useState<DynamicAssignment[]>([]);
  const [filteredMemberships, setFilteredMemberships] = React.useState<OrganizationMembershipWithUser[]>([]);
  const [search, setSearch] = React.useState<string>('');

  // clear search on task selection change
  React.useEffect(() => {
    setSearch('');
  }, [taskTemplates]);

  // filter dynamic assignments
  React.useEffect(() => {
    const pattern = search.trim().toLowerCase();

    if (pattern === '') {
      setFilteredDynamicAssignments(availableDynamicAssignments);
    } else {
      setFilteredDynamicAssignments(
        availableDynamicAssignments.filter(
          item => item.title.toLowerCase().includes(pattern) || item.description.toLowerCase().includes(pattern),
        ),
      );
    }
  }, [availableDynamicAssignments, search]);

  // filter memberships
  React.useEffect(() => {
    const pattern = search.trim().toLowerCase();

    if (pattern === '') {
      setFilteredMemberships(availableMemberships);
    } else {
      setFilteredMemberships(
        availableMemberships.filter(({ user }) => {
          return user.username.toLowerCase().includes(pattern) || user.email.toLowerCase().includes(pattern);
        }),
      );
    }
  }, [availableMemberships, search]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const noResults = filteredMemberships.length === 0 && filteredDynamicAssignments.length === 0;
  const bulk = taskTemplates.length > 1;

  if (disabled && !bulk) return null;

  return (
    <Popover placement="bottom-start" closeOnBlur={true} isLazy>
      <Tooltip
        label={
          <Text variant="-2" textAlign="center">
            Assign task
          </Text>
        }
        hasArrow
        shouldWrapChildren
      >
        <PopoverTrigger>
          <Button
            display="flex"
            color="gray.500"
            justifyContent="flex-start"
            isDisabled={disabled}
            {...match({ bulk })
              .with({ bulk: true }, () => ({
                variant: 'tertiary',
                iconSpacing: '3',
                leftIcon: <Icon icon="user" variant="far" size="4" />,
                children: (
                  <Text color="gray.600" fontWeight="normal">
                    Assign
                  </Text>
                ),
              }))
              .otherwise(() => ({
                'iconSpacing': '0',
                'variant': 'outline',
                'size': 'sm',
                'borderWidth': 'px',
                'borderColor': 'gray.300',
                'px': '6px',
                'justifyContent': 'center',
                'leftIcon': (
                  <Icon
                    icon="user"
                    variant="far"
                    size="4"
                    color={hasAssignments && !disabled ? 'purple.500' : 'gray.400'}
                  />
                ),
                ...(hasAssignments
                  ? {
                      borderRightRadius: '0',
                    }
                  : {}),
                'aria-label': 'Assign',
              }))}
            {...buttonProps}
          />
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent py={3} w="sm" zIndex={1600} textAlign="left">
        <PopoverHeader p={0} mb={3} mx={3}>
          <Text variant="-1" color="gray.700" fontWeight="medium">
            Assign on Run
          </Text>

          <PopoverCloseButton
            padding="0"
            textTransform="none"
            borderStyle="none"
            outline="initial"
            margin="0"
            marginTop={1}
            cursor="pointer"
            backgroundColor="transparent"
            fontSize="xs"
            fontWeight="md"
            color="gray.500"
            w="24px!important"
          />
        </PopoverHeader>

        <Box mx={3}>
          <Input
            placeholder="Enter name or email"
            border="1px solid"
            borderColor="gray.300"
            backgroundColor="white"
            value={search}
            onChange={handleSearchChange}
          />
        </Box>

        <PopoverBody maxH="400px" overflowY="scroll" mt={2} p={0}>
          {filteredDynamicAssignments.length > 0 && (
            <LimitedList
              items={filteredDynamicAssignments}
              onSelect={onRuleCreate}
              Item={DynamicAssignmentItem}
              title="dynamic assignees"
              boxProps={{ mt: '4' }}
            >
              {search === '' && (
                <Text variant="-2" color="gray.500" mt={3} px={4}>
                  Dynamically assign tasks based on information unique to each workflow run.&nbsp;
                  <Link href="https://www.process.st/help/docs/role-assignments/" target="_blank">
                    <Text as="span" variant="-2" color="brand.500">
                      Learn more
                    </Text>
                  </Link>
                </Text>
              )}
            </LimitedList>
          )}

          {filteredMemberships.length > 0 && (
            <LimitedList
              items={filteredMemberships}
              onSelect={onAssignmentCreate}
              Item={MembershipItem}
              title="users and groups"
              boxProps={{ mt: '4' }}
            />
          )}

          {noResults && <Invite mx={4} mt={4} email={search} allMembers={allMembers} onInvite={onInviteAndAssign} />}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

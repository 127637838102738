import * as React from 'react';
import { Button, Tooltip, Icon, HStack, Text } from 'components/design/next';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useSolutionTypeTagInstances } from '../../automation/components/selector/use-solution-type-tag-instances';
import { AutomationAppIcon } from 'features/automations/components/automation-app-icon';
import { usePrevious } from 'react-use';
import { StringUtils } from '@process-street/subgrade/util';
import { useAutomationsModalEvents } from 'features/automations/components/workflow-automations/components/common/context';
import { withTrayPrefix } from '@process-street/subgrade/automation';

export interface TaskTemplateAutomationsButtonProps {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  setHasAutomations?: (hasAutomations: boolean) => void;
}

export const TaskTemplateAutomationsButton: React.FC<React.PropsWithChildren<TaskTemplateAutomationsButtonProps>> = ({
  setHasAutomations: setHasAutomationsProp,
  templateRevision,
  taskTemplate,
}) => {
  const {
    instances: { ps_when_task_checked_then },
  } = useSolutionTypeTagInstances({ templateId: templateRevision.template.id });

  const taskSolutionInstances = ps_when_task_checked_then.filter(
    i => i.taskTemplateGroupId === taskTemplate.group.id && i.configured && i.enabled,
  );
  const [instanceA, instanceB, ...rest] = taskSolutionInstances;
  const displayedInstances = [instanceA, instanceB].filter(Boolean);
  const additionalInstanceCount = rest.length;

  const automationsCount = taskSolutionInstances.length;
  const previousCount = usePrevious(automationsCount);
  const hasAutomations = automationsCount > 0;
  if (previousCount !== automationsCount) {
    setHasAutomationsProp?.(hasAutomations);
  }

  const isEditable = useWorkflowState() === 'edit';
  const { openTaskAutomations } = useAutomationsModalEvents();

  const tooltipParts = {
    prefix: 'When',
    taskName: taskTemplate.name,
    suffix: 'is checked, then',
    automations: StringUtils.getSentenceList({
      items: taskSolutionInstances.map(i => i.description ?? '').filter(Boolean),
    }),
  };

  return (
    <Tooltip
      hasArrow
      shouldWrapChildren
      placement="bottom"
      {...(instanceA
        ? {
            'aria-label': Object.values(tooltipParts).join(' '),
            'label': (
              <Text textAlign="center" variant="-2">
                {tooltipParts.prefix}{' '}
                <Text as="span" fontWeight="bold" variant="-2">
                  {tooltipParts.taskName}
                </Text>{' '}
                {tooltipParts.suffix} {tooltipParts.automations}
              </Text>
            ),
          }
        : {
            'aria-label': 'Automations',
            'label': 'Automations',
          })}
    >
      <Button
        {...{
          'aria-label': 'Automations',
          'borderColor': 'gray.300',
          'borderWidth': 'px',
          'colorScheme': 'gray',
          'iconSpacing': instanceA ? '2' : 0,
          'onClick': () => openTaskAutomations(),
          'px': hasAutomations ? '1' : '0',
          'size': 'sm',

          ...(isEditable
            ? {
                variant: 'outline',
                leftIcon: (
                  <Icon icon="bolt" variant="far" size="4" color={hasAutomations ? 'purple.500' : 'gray.400'} />
                ),
              }
            : {
                variant: 'ghost',
                isDisabled: true,
                pointerEvents: 'none',
              }),
        }}
      >
        <HStack spacing="2">
          {displayedInstances.map(displayedInstance => (
            <AutomationAppIcon
              key={displayedInstance.id}
              automationApp={withTrayPrefix(displayedInstance.automationApp)}
              w="4"
            />
          ))}
          {additionalInstanceCount ? <Text variant="-2">+{additionalInstanceCount}</Text> : null}
        </HStack>
      </Button>
    </Tooltip>
  );
};

import { MultiSelectFieldValue } from '@process-street/subgrade/process';
import { FormikTouched } from 'formik';
import { useCallback } from 'react';

type Touched = FormikTouched<{ subtasks: { id: boolean; itemType: boolean; status: boolean; name: boolean }[] }>;

export type UseSubtasksOnBlurParams = {
  touched: Touched | undefined;
  setTouched: (v: Touched | undefined) => void;
  subtasks: MultiSelectFieldValue[];
};

export const useSubtasksOnBlur = ({ touched, setTouched, subtasks }: UseSubtasksOnBlurParams) => {
  return useCallback(
    (index: number) => {
      setTouched({
        ...touched,
        subtasks: subtasks.map((_, i) => {
          if (i === index) {
            return {
              id: false,
              itemType: false,
              status: false,
              name: true,
            };
          }
          return (
            touched?.subtasks?.[i] ?? {
              id: false,
              itemType: false,
              status: false,
              name: false,
            }
          );
        }),
      });
    },
    [touched, subtasks, setTouched],
  );
};
